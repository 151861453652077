import React, { useState, useContext, useEffect } from "react";
import BoutonActionPrincipale from "../../../../Design/Boutons/BoutonActionPrincipale";
import SurfacePopUp from "../../../../Design/Surfaces/SurfacePopUp";
import BoutonAnnuler from "../../../../Design/Boutons/BoutonAnnuler";
import MessageErreur from "../../../../Design/Messages/MessageErreur";
import { CotationsContext } from "../../../../Data/CotationsContext";
import LoaderHorizontal from "../../../../Design/Progression/LoaderHorizontal";
import ObjetReglement from "../../../../Design/Objets/ObjetReglement";
import SurfaceBloc from "../../../../Design/Surfaces/SurfaceBloc";

export default function PopupAnnulationFacture({
  facture_id,
  open,
  onClose,
  onReglementsRendus,
}) {
  //NES gestion du contexte
  const { getReglementsByFacture, deleteReglement } =
    useContext(CotationsContext);

  //NES Gestion du state
  const [listeReglements, setListeReglements] = useState([]);
  const [error, setError] = useState("");
  const [messageErreur, setMessageErreur] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  //USEEFFECT récupération des règlements
  useEffect(() => {
    if (facture_id && open) {
      setIsLoading(true);
      setError();
      setMessageErreur();
      getReglementsByFacture(facture_id)
        .then((reponse) => {
          setIsLoading(false);
          if (reponse.code === "OK") {
            console.log(reponse);
            let tempData = reponse.data;
            //NES pour ne pas rendre les règlements déjà rendu et les rendus
            tempData = tempData.filter(
              (item) =>
                item.is_rendu !== true &&
                item.code_type_reglement === "REGLEMENT"
            );
            setListeReglements(tempData);
          } else {
            setMessageErreur("Erreur lors de l'enregistrement");
          }
        })
        .catch((error) => {
          setIsLoading(false);
          setMessageErreur("Erreur lors de l'enregistrement");
          setError(error);
        });
    }
    // eslint-disable-next-line
  }, [facture_id, open]);

  //NES RENDRE TOUS LES REGLEMENTS
  const rendreReglements = async () => {
    for (const reglement of listeReglements) {
      const reponse = await deleteReglement(reglement._id);
      console.log({ reponse });
    }
    if (onReglementsRendus) {
      onReglementsRendus();
    }
    if (onClose) {
      onClose();
    }
  };

  return (
    <SurfacePopUp open={open} couleur="gris" onClose={onClose}>
      <div style={{ maxWidth: "500px" }}>
        <div className="TitrePop">Annulation du règlement</div>
        <p>
          La facture, que vous souhaitez annuler, est déjà réglée. Vous devez
          rendre le(s) règlement(s) suivant(s) pour l'annuler.
        </p>
        <LoaderHorizontal isLoading={isLoading} />
        <h4>{listeReglements.length > 1 ? "Règlements" : "Règlement"}</h4>

        <div>
          {listeReglements.map((reglement) => (
            <SurfaceBloc style={{ padding: "10px" }} key={reglement._id}>
              <ObjetReglement reglement={reglement} isAfficherDate={true} />
            </SurfaceBloc>
          ))}
        </div>
        <MessageErreur
          message={messageErreur}
          style={{ marginTop: "20px" }}
          error={error}
        />
        <BoutonActionPrincipale
          texte={
            listeReglements.length > 1
              ? "Rendre les règlements et annuler la facture"
              : "Rendre le règlement et annuler la facture"
          }
          taille="L"
          fullWidth
          style={{ margin: "20px 0px" }}
          onClick={rendreReglements}
        />
        <BoutonAnnuler fullWidth onClick={onClose} />
      </div>
    </SurfacePopUp>
  );
}
