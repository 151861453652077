import React from "react";
import MonStyle from "./SyntheseConsultation.module.css";
import SyntheseFacturation from "./SyntheseFacturation";
import SyntheseDocuments from "./SyntheseDocuments";
import SyntheseConseils from "./SyntheseConseils";
import SyntheseCotations from "./SyntheseCotations";

export default function SyntheseConsultation({ maConsultation }) {
  return (
    <div className={MonStyle.SyntheseConsultation}>
      <SyntheseDocuments consultation={maConsultation} />
      <SyntheseConseils />
      <SyntheseFacturation />
      <SyntheseCotations consultation_id={maConsultation._id} />
    </div>
  );
}
