import React from "react";
import SurfacePage from "../../Design/Surfaces/SurfacePage";
import ConditionsGenerales from "./Components/ConditionsGenerales";

export default function PageCgv() {
  return (
    <SurfacePage>
      <ConditionsGenerales />
    </SurfacePage>
  );
}
