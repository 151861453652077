import React, { useContext, useEffect, useState } from "react";
import InputTexte from "../../../../../Design/Formulaires/InputTexte";
import InputDate from "../../../../../Design/Formulaires/InputDate";
import SelecteurSexe from "../../../../../Design/Formulaires/SelecteurSexe";
import MonStyle from "./EditionPatient.module.css";
import BoutonActionPrincipale from "../../../../../Design/Boutons/BoutonActionPrincipale";
import { calculObjetAge } from "../../../../../functions/AfficherAgeAnneeMoisJour";
import { PatientsContext } from "../../../../../Data/PatientsContext";
import LoaderHorizontal from "../../../../../Design/Progression/LoaderHorizontal";
import MessageErreur from "../../../../../Design/Messages/MessageErreur";
import { capitalize } from "../../../../../functions/Formatter";

export default function EditionPatient({
  patient,
  onEnregistrer,
  texteRecherche,
}) {
  //NES Gestion du contexte
  const { patchPatient, postPatient } = useContext(PatientsContext);

  //NES gestion de mon state
  const [isLoading, setIsLoading] = useState(false);
  const [messageErreur, setMessageErreur] = useState();
  const [error, setError] = useState();
  const [monPatient, setMonPatient] = useState({
    patient_id: null,
    patient_sexe: "",
    patient_nom: "",
    patient_prenom: "",
    patient_date_naissance: "",
  });

  const handleChange = (key, value) => {
    setMonPatient({ ...monPatient, [key]: value });
  };

  const handleChangeDateNaissance = (date_de_naissance) => {
    let age = calculObjetAge(date_de_naissance);
    setMonPatient({
      ...monPatient,
      patient_age: age,
      patient_date_naissance: date_de_naissance,
    });
  };

  //USEEFFECT
  useEffect(() => {
    if (patient) {
      setMonPatient(patient);
    } else {
      setMonPatient({
        patient_id: null,
        patient_sexe: "",
        patient_nom: "",
        patient_prenom: "",
      });
    }
  }, [patient]);

  //NES enregsitrement du choix sexe
  const handleChangeSexe = (sexe) => {
    //NES j'en profite pour découper le texte de recherche
    if (monPatient.patient_nom === "" && texteRecherche !== "") {
      //NES je récupère le texte de recherche pour initialiser le patient
      let premierEspace = texteRecherche.indexOf(" ");
      let nom = "";
      let prenom = "";
      if (premierEspace > 0) {
        nom = texteRecherche.substring(0, premierEspace);
        prenom = texteRecherche.substring(premierEspace);
      } else {
        nom = texteRecherche;
      }

      prenom = prenom.trim();
      nom = nom.toUpperCase();
      prenom = capitalize(prenom);
      setMonPatient({
        ...monPatient,
        patient_sexe: sexe,
        patient_nom: nom,
        patient_prenom: prenom,
      });
    } else {
      setMonPatient({
        ...monPatient,
        patient_sexe: sexe,
      });
    }
  };

  //NES enregistrement des modifications
  const sauvegardePatient = async () => {
    let data = {
      sexe: monPatient.patient_sexe,
      nom: monPatient.patient_nom,
      prenom: monPatient.patient_prenom,
      date_de_naissance: monPatient.patient_date_naissance,
    };

    console.log({ data });

    if (monPatient.patient_id) {
      //PATCH
      let reponse = await patchPatient(monPatient.patient_id, data);
      return reponse;
    } else {
      //POST sans mettre à jour le context patient
      let reponse = await postPatient(data, { ignoreContext: true });
      if (reponse.code === "OK") {
        handleChange("patient_id", reponse.data._id);
      }
      return reponse;
    }
  };

  const handleEnregistrer = () => {
    setIsLoading(true);
    sauvegardePatient()
      .then((reponse) => {
        console.log(reponse);
        setIsLoading(false);
        if (reponse.code === "OK") {
          if (onEnregistrer) {
            let objetPatientRdv = {
              patient_id: reponse.data._id,
              patient_nom: reponse.data.nom,
              patient_prenom: reponse.data.prenom,
              patient_date_naissance: reponse.data.date_de_naissance,
              patient_age: reponse.data.age,
              patient_sexe: reponse.data.sexe,
            };

            console.log({ objetPatientRdv });
            onEnregistrer(objetPatientRdv);
          }
        } else {
          setMessageErreur("Erreur lors de l'enregistrement");
        }
      })
      .catch((error) => {
        setIsLoading(false);
        setMessageErreur("Erreur lors de l'enregistrement");
        setError(error);
      });
  };

  return (
    <div className={MonStyle.EditionPatient}>
      <div>
        <SelecteurSexe
          sexe={monPatient.patient_sexe}
          onChange={handleChangeSexe}
        />
        <InputTexte
          label="Nom"
          value={monPatient.patient_nom}
          onChange={(event) => handleChange("patient_nom", event.target.value)}
          textTransform="uppercase"
        />
        <InputTexte
          label="Prénom"
          value={monPatient.patient_prenom}
          textTransform="capitalize"
          onChange={(event) =>
            handleChange("patient_prenom", event.target.value)
          }
        />
        <InputDate
          label="Date de naissance"
          value={monPatient.patient_date_naissance}
          onChange={(event) => handleChangeDateNaissance(event.target.value)}
        />
        <MessageErreur
          message={messageErreur}
          error={error}
          style={{ marginTop: "20px" }}
        />
        <BoutonActionPrincipale
          style={{ marginTop: "20px" }}
          texte="Enregistrer"
          fullWidth
          couleur="bleu"
          onClick={handleEnregistrer}
          disabled={!monPatient.patient_nom}
        />
        <div
          className="InformationSecondaire"
          style={{ marginTop: "10px", textAlign: "center" }}
        >
          <LoaderHorizontal isLoading={isLoading} />
          On met à jour le dossier du patient quand vous le modifiez dans le
          rendez-vous
        </div>
      </div>
      <div></div>
    </div>
  );
}
