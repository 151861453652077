import React from "react";
import SurfacePage from "../../Design/Surfaces/SurfacePage";
import Style from "./PageOptionsParametres.module.css";
import PageUtilisateurs from "./Pages/Utilisateurs/PageUtilisateurs";
import PageEditionUtilisateur from "./Pages/Utilisateurs/PageEditionUtilisateur";
import { Route } from "react-router-dom";
import { Redirect } from "react-router-dom";
import PageTypesConsultations from "./Pages/TypesConsultations/PageTypesConsultations";
import PageEditionTypeConsultation from "./Pages/TypesConsultations/PageEditionTypeConsultation";
import PageCabinets from "./Pages/Cabinets/PageCabinets";
import PageEditionCabinet from "./Pages/Cabinets/PageEditionCabinet";
import PageLaboratoire from "./Pages/Laboratoires/PageLaboratoires";
import PageEditionLaboratoire from "./Pages/Laboratoires/PageEditionLaboratoire";
import PageMenuOptions from "./Pages/MenuOptions/PageMenuOptions";
import { isMobile } from "../../functions/Navigateur";
import PageOptionsConstantes from "./Pages/Constantes/PageOptionsConstantes";
import PageEditionConstante from "./Pages/Constantes/PageEditionConstante";

export default function PageOptionsParametres({ match }) {
  return (
    <SurfacePage couleur="gris" style={{ border: "0px solid orange" }}>
      <div className={Style.PageOptionsParametres}>
        <div className={Style.TitreSoustitre}>
          <h1>Options et paramètres</h1>
          <div className="SousTitre">
            Disponible uniquement pour les profils "Admin"
          </div>
        </div>

        <div className={Style.ContentPage}>
          <div className={Style.MenuOptions}>
            <Route path="/options" component={PageMenuOptions} />
          </div>
          <div className={Style.PartieDroite}>
            <div className={Style.FenetreOptions}>
              {/*************************ROUTES OPTIONS***********************************/}

              {!isMobile() && (
                <Route path="/options" exact>
                  <Redirect to="/options/utilisateurs" />
                </Route>
              )}
              {isMobile() && (
                <Route path="/options/" exact component={PageMenuOptions} />
              )}
              <Route
                path="/options/utilisateurs"
                exact
                component={PageUtilisateurs}
              />

              <Route
                path="/options/utilisateurs/:id"
                component={PageEditionUtilisateur}
              />
              {/*************************ROUTES TYPES RENDEZ- VOUS***********************************/}
              <Route
                path="/options/typesconsultations/"
                exact
                component={PageTypesConsultations}
              />
              <Route
                path="/options/typesconsultations/:id"
                component={PageEditionTypeConsultation}
              />
              {/*************************ROUTES CABINETS***********************************/}
              <Route path="/options/cabinets/" exact component={PageCabinets} />
              <Route
                path="/options/cabinets/:id"
                component={PageEditionCabinet}
              />
              {/*************************ROUTES LABORATOIRES***********************************/}
              <Route
                path="/options/laboratoires/"
                exact
                component={PageLaboratoire}
              />
              <Route
                path="/options/laboratoires/:id"
                component={PageEditionLaboratoire}
              />
              {/*************************ROUTES LABORATOIRES***********************************/}
              <Route
                path="/options/constantes/"
                exact
                component={PageOptionsConstantes}
              />
              <Route
                path="/options/constantes/:id"
                exact
                component={PageEditionConstante}
              />
            </div>
          </div>
        </div>
      </div>
    </SurfacePage>
  );
}
