import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import BoutonActionPrincipale from "../../../Design/Boutons/BoutonActionPrincipale";
import BoutonAnnuler from "../../../Design/Boutons/BoutonAnnuler";
import CheckBox from "../../../Design/Formulaires/CheckBox";
import SurfacePopUp from "../../../Design/Surfaces/SurfacePopUp";
import ConditionsGenerales from "./ConditionsGenerales";

export default function InputConditionGenerales({ value, onChange, style }) {
  //NES gestion du state
  const [accepter, setAccepter] = useState(false);
  const [open, setOpen] = useState(false);

  ///Quand je coche la case
  const handleChange = (event) => {
    setAccepter(event.target.checked);
  };

  ///Quand je clique sur le bouton accepter les CGV
  const handleAccepter = () => {
    setAccepter(true);
    setOpen(false);
  };

  //USEEFFECT Value
  useEffect(() => {
    setAccepter(value);
    // eslint-disable-next-line
  }, [value]);

  //USEEFFECT onChange
  useEffect(() => {
    if (onChange) {
      onChange(accepter);
    }
    // eslint-disable-next-line
  }, [accepter]);

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        backgroundColor: accepter ? "#208ab020" : "#DDDDDD50",
        padding: "10px",
        fontSize: "14px",
        borderRadius: "6px",
        border: accepter ? "1px solid #208ab0" : "1px solid #DDDDDD",
        ...style,
      }}
    >
      <CheckBox checked={accepter} onChange={handleChange} name="cgv" />
      <div>
        <div> J'accepte les conditions générales d'utilisation</div>
        <div className="Lien" onClick={() => setOpen(true)}>
          Afficher les conditions générales
        </div>
      </div>
      <SurfacePopUp
        open={open}
        onClose={() => setOpen(false)}
        style={{ width: "60%", maxHeight: "90vh", overflow: "scroll" }}
      >
        <div>
          <ConditionsGenerales />
          <section
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              gap: "10px",
            }}
          >
            <BoutonAnnuler texte="Fermer" onClick={() => setOpen(false)} />
            <BoutonActionPrincipale
              texte="Accepter les conditions générales"
              onClick={handleAccepter}
            />
          </section>
        </div>
      </SurfacePopUp>
    </div>
  );
}
