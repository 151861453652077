import React, { useState, useContext, useEffect } from "react";
import MonStyle from "./PageContacts.module.css";
import BoutonActionPrincipale from "../../../../Design/Boutons/BoutonActionPrincipale";
import MessageErreur from "../../../../Design/Messages/MessageErreur";
import LoaderHorizontal from "../../../../Design/Progression/LoaderHorizontal";
import AffichageMedecinTraitant from "../../Components/AffichageContacts/AffichageMedecinTraitant";
import AffichageContacts from "../../Components/AffichageContacts/AffichageContacts";
import { ContactsContext } from "../../../../Data/ContactsContext";
import PanneauContacts from "../../Components/AffichageContacts/PanneauContacts";
import BoutonActionSecondaire from "../../../../Design/Boutons/BoutonActionSecondaire";
import AffichageConditionnel from "../../../../Design/DataDisplay/AffichageConditionnel";

export default function PageContacts({ match }) {
  const patient_id = match.params.patient_id;

  //NES gestion du contexte
  const { medecinTraitant, getContacts, postContact } =
    useContext(ContactsContext);

  //NES Gestion du state
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [messageErreur, setMessageErreur] = useState();
  const [error, setError] = useState();
  const [isMedecinTraitant, setIsMedecinTraitant] = useState(false);
  const [contactIdAModifier, setContactIdAModifier] = useState();

  //USEEFFECT
  useEffect(() => {
    if (patient_id) {
      getContacts(patient_id)
        .then((reponse) => {
          setIsLoading(false);
          if (reponse.code === "OK") {
            setMessageErreur("");
          } else {
            setMessageErreur("Erreur lors de la récupération des contacts");
          }
        })
        .catch((error) => {
          setIsLoading(false);
          setMessageErreur("Erreur lors de la récupération des contacts");
          setError(error);
        });
    }
    // eslint-disable-next-line
  }, [patient_id]);

  //NES Ajout du médecin traitant
  const handleAjouterMedecinTraitant = () => {
    setIsMedecinTraitant(true);
    setOpen(true);
  };

  //NES ajout d'un contact
  const handleAjouterContact = () => {
    setIsMedecinTraitant(false);
    setOpen(true);
  };

  const handleSelectContact = (contact) => {
    //NES je renseigne l'annuraire_id
    let newContact = { ...contact, annuaire_id: contact._id };
    //NES je détruis ._id de mon objet
    Reflect.deleteProperty(newContact, "_id");
    //NES je détruis .owned_by_organisation de mon objet
    Reflect.deleteProperty(newContact, "owned_by_organisation");
    //NES si mon contact est médecin traitant
    newContact = { ...contact, is_medecin_traitant: isMedecinTraitant };
    postContact(patient_id, newContact);
    setOpen(false);
  };

  const handleModifier = (contact_id) => {
    setContactIdAModifier(contact_id);
    setOpen(true);
  };

  const handleClose = () => {
    setContactIdAModifier();
    setIsMedecinTraitant();
    setOpen(false);
  };

  return (
    <div className={MonStyle.PageContacts}>
      <LoaderHorizontal isLoading={isLoading} />
      <MessageErreur
        message={messageErreur}
        error={error}
        style={{ margin: "20px 0px" }}
      />
      <section>
        <h3 style={{ color: "#107C82" }}>Médecin traitant</h3>
        <div
          style={{
            //backgroundColor: "yellow",
            minHeight: "80px",
            display: "flex",
            alignItems: "center",
            marginTop: "10px",
          }}
        >
          <AffichageMedecinTraitant patient_id={patient_id} />
          <AffichageConditionnel open={!medecinTraitant}>
            <BoutonActionPrincipale
              texte="Ajouter le médecin traitant"
              onClick={handleAjouterMedecinTraitant}
            />
          </AffichageConditionnel>
        </div>
      </section>
      {/* <BoutonActionSecondaire texte="Je suis le médecin traitant" /> */}
      <section>
        <h3>Autres contacts</h3>
        <p className="SousTitre">Spécialistes, infirmieres, pharmacies...</p>
        <AffichageContacts
          patient_id={patient_id}
          style={{ marginTop: "20px" }}
          onClickModifier={handleModifier}
        />
        <BoutonActionSecondaire
          texte="Ajouter un contact"
          style={{ marginTop: "20px" }}
          onClick={handleAjouterContact}
        />
      </section>
      <PanneauContacts
        is_medecin_traitant={isMedecinTraitant}
        open={open}
        onOpen={() => setOpen(true)}
        onClose={handleClose}
        onSelect={handleSelectContact}
        patient_id={patient_id}
        contact_id={contactIdAModifier}
      />
    </div>
  );
}
