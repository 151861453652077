import React, { useEffect, useContext, useState } from "react";
import MonStyle from "./SuggestionSaisieCodePraticien.module.css";
import MessageAide from "../../../../Design/Messages/MessageAide";
import BoutonActionSecondaire from "../../../../Design/Boutons/BoutonActionSecondaire";
import { ProduitsContext } from "../../../../Data/ProduitsContext";
import EditionCodePraticien from "./EditionCodePraticien";

export default function SuggestionSaisieCodePraticien({ conseil }) {
  //NES gestion du contexte
  const { laboratoires, getLaboratoires } = useContext(ProduitsContext);

  //NES gestion du state
  const [laboratoiresAffiches, setLaboratoiresAffiches] = useState([]);
  const [editionLaboratoire, setEditionLaboratoire] = useState();

  //USEEFFECT controle des laboratoires du conseils
  useEffect(() => {
    let listeLaboSansCode = [];

    //NES récupération des laboratoires
    if (laboratoires.length === 0) {
      getLaboratoires();
    }

    conseil?.lignes_conseils.forEach((ligne) => {
      //NES est-ce que j'ai un labo sur la ligne ex: menthe?
      if (ligne?.produit?.laboratoire?._id) {
        let labo = laboratoires.find(
          (item) => item._id === ligne?.produit?.laboratoire?._id
        );

        //NES est ce que j'ai un code de commande?
        if (labo && !labo?.code_praticien) {
          if (!listeLaboSansCode.includes((item) => item._id === labo._id)) {
            listeLaboSansCode.push(labo);
          }
        }
      }
    });

    setLaboratoiresAffiches(listeLaboSansCode);
    // eslint-disable-next-line
  }, [conseil, laboratoires]);

  //NES gestion du contexte

  return (
    <div>
      <div className={MonStyle.ListeMessages}>
        {laboratoiresAffiches.map((labo) => (
          <MessageAide
            onClick={() => setEditionLaboratoire(labo)}
            key={labo._id}
            message={"Avez vous un code praticien pour " + labo.nom + " ?"}
            action={
              <BoutonActionSecondaire
                taille="S"
                couleur="bleu"
                texte="Saisir"
              />
            }
          />
        ))}
      </div>
      <EditionCodePraticien
        laboratoire={editionLaboratoire}
        onClose={() => setEditionLaboratoire()}
      />
    </div>
  );
}
