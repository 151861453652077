import React, { useContext, useState, useEffect } from "react";
import { AgendasContext } from "../../../../Data/AgendasContext";

export default function BarreHeureCourante({ date, agenda_id, hauteurHeure }) {
  //NES gestion du context
  const { calculPositionTop } = useContext(AgendasContext);

  //NES gestion du state
  const [top, setTop] = useState(0);
  const [heure, setHeure] = useState("");
  let aujourdhui = new Date().toLocaleDateString("fr-FR");

  useEffect(() => {
    if (date.toLocaleDateString("fr-FR") === aujourdhui) {
      const heureInterval = setInterval(() => {
        let maintenant = new Date();
        //let maintenant = new Date(2022, 3, 20, 12, 0, 0);
        let positionCalcule = calculPositionTop(
          agenda_id,
          maintenant,
          hauteurHeure
        );
        setTop(positionCalcule - 2);
        let heureCourante = maintenant.toLocaleTimeString("fr-FR");
        setHeure(heureCourante);
      }, 1000);
      return () => clearInterval(heureInterval);
    }
    // eslint-disable-next-line
  }, [hauteurHeure, agenda_id]);

  if (date.toLocaleDateString("fr-FR") === aujourdhui) {
    return (
      <div
        id="maintenant"
        style={{
          height: "2px",
          backgroundColor: "red",
          content: "",
          position: "absolute",
          top: top,
          right: "0px",
          width: "100%",
          zIndex: 10,
          fontSize: "12px",
          color: "red",
          fontWeight: "bold",
        }}
      >
        <div
          style={{
            backgroundColor: "red",
            width: "fit-content",
            color: "white",
            padding: "2px 10px 2px 5px",
            marginTop: "0px",
            borderRadius: "0px 0px 10px 0px",
          }}
        >
          {heure}
        </div>
      </div>
    );
  } else {
    return "";
  }
}
