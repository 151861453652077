import React, { useContext, useEffect, useState } from "react";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import IconesProgression from "../../../../Design/Icones/IconesProgression";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import { Divider } from "@material-ui/core";
import BoutonRond from "../../../../Design/Boutons/BoutonRond";
import StepProgressionPicker from "../../../ADPX/Components/StepProgressionPicker";
import Popover from "@mui/material/Popover";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import SwipeableViews from "react-swipeable-views";
import DateRangeIcon from "@material-ui/icons/DateRange";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import BoutonRetour from "../../../../Design/Boutons/BoutonRetour";
import { DateToString } from "../../../../functions/FonctionsDateHeure";
import { SupportContext } from "../../../../Data/SupportContext";
import MessageErreur from "../../../../Design/Messages/MessageErreur";
import MessageAlerte from "../../../../Design/Messages/MessageAlerte";
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";
import JourPlanningPicker from "../../../ADPX/Components/JourPlanningPicker";
import { AdpxContext } from "../../../../Data/AdpxContext";
import { AuthentificationContext } from "../../../../Data/AuthentificationContext";

const MonConnecteur = ({ onAjouterEtape, ...props }) => {
  //NES gestion du contexte
  const auth = useContext(AuthentificationContext);

  //NES gestion du state
  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  let styleLigne = "3px solid #DDD";

  if (props?.active) {
    styleLigne = "3px solid #205A71";
  }

  if (props?.completed) {
    styleLigne = "3px solid #0FA600";
  }

  //NES quand on veut ajouter une étape
  const handleSelectEtape = (etape) => {
    let newStep = {
      index: props.index,
      etape: etape,
    };
    console.log(newStep);

    if (onAjouterEtape) {
      onAjouterEtape(newStep);
    }
    //NES fermeture de la popup
    handleClose();
  };

  return (
    <div
      style={{
        position: "absolute",
        borderBottom: styleLigne,
        left: "calc(-50% + 30px)",
        right: "calc(50% + 30px)",
        top: "25px",
      }}
    >
      {!props?.completed && auth.payload.code_role === "ADPX" && (
        <div
          style={{
            position: "absolute",
            top: "-20px",
            left: "calc(50% - 20px)",
            right: "calc(50%)",
            backgroundColor: "white",
            width: "40px",
          }}
        >
          <BoutonRond
            iconeComposant={<AddCircleOutlineIcon />}
            onClick={handleOpenMenu}
            couleurIcone="gris"
            tooltip="Ajouter une étape"
          />
          <Popover
            open={anchorEl ? true : false}
            anchorEl={anchorEl}
            onClose={handleClose}
            // onClick={handleClose}
            anchorOrigin={{
              vertical: "center",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "center",
              horizontal: "center",
            }}
          >
            <StepProgressionPicker onSelect={handleSelectEtape} />
          </Popover>
        </div>
      )}
    </div>
  );
};

//NES Style de l'étape
function ProgressionStepIcon({ onClickTerminer, onClickEnCours, ...props }) {
  const { active, completed } = props;

  let style = {
    borderRadius: "30px",
    height: "50px",
    width: "50px",
    backgroundColor: "#F2F1F5",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "18px",
    color: "#97989D",
    fontWeight: "bold",
    boxSizing: "border-box",
  };

  if (active) {
    style = {
      ...style,
      backgroundColor: "white",
      color: "#205A71",
      border: "2px solid #205A71",
    };
  }

  if (completed) {
    style = { ...style, backgroundColor: "#0FA600", color: "white" };
  }

  return (
    <div style={style}>
      <IconesProgression code={props.icon} style={{ fontSize: "32px" }} />
    </div>
  );
}

export default function ProgressionDemande({ demande }) {
  //NES gestion du contexte
  const { patchDemandes } = useContext(SupportContext);
  const { plannifierDemande, deplanniferDemande } = useContext(AdpxContext);
  const auth = useContext(AuthentificationContext);

  //NES Gestion du state
  const [etapeActive, setEtapeActive] = useState(0);
  const [steps, setSteps] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [index, setIndex] = useState(0);
  const [editionEtape, setEditionEtape] = useState();
  const [editionIndex, setEditionIndex] = useState();
  const [message, setMessage] = useState("");
  const [messageErreur, setMessageErreur] = useState("");
  const [error, setError] = useState("");

  const handleOpenMenu = (event, etape, index) => {
    //NES on ne peut pas ouvrir le menu si on n'est pas ADPX
    if (auth.payload.code_role === "ADPX") {
      setIndex(0);
      setAnchorEl(event.currentTarget);
      setEditionEtape(etape);
      setEditionIndex(index);
    }
  };

  const handleClose = () => {
    setEditionIndex();
    setAnchorEl(null);
  };

  //USEEFFECT
  useEffect(() => {
    if (demande?.progression?.etapes?.length > 0) {
      let indexEtapeActive = demande.progression.etape_active;
      let etapes = demande.progression.etapes;
      let etapeActive = etapes[indexEtapeActive];

      //NES si j'ai une date prévisionnelle au niveau de la demande je gère dynamiquement l'affichage
      if (demande.date_previsionnelle && etapeActive) {
        //NES la date prévisionnelle est pour l'étape active mais on affiche "en cours"
        etapeActive.texte_secondaire =
          "prévue le " +
          DateToString(demande.date_previsionnelle.date, {
            month: "long",
            day: "numeric",
          });
        etapes.splice(indexEtapeActive, 1, etapeActive);
      }
      setEtapeActive(indexEtapeActive);
      setSteps(etapes);
    }
  }, [demande]);

  //NES si je n'ai pas de progession ne n'affiche rien
  if (!demande?.progression) {
    return "";
  }

  //PATCH demandes/:id
  const enregistrerProgression = (newEtapeActive, newSteps, etat) => {
    let newData = {
      ...demande,
      progression: { etape_active: newEtapeActive, etapes: newSteps },
    };

    //NES si je dois changer l'état
    if (etat) {
      newData = { ...newData, etat: etat };
    }

    setMessage("");
    setMessageErreur("");
    setError();
    console.log({ newData });
    patchDemandes(newData).then((reponse) => {
      console.log(reponse);
      if (reponse.code === "OK") {
        setMessage("Mise à jour le la progression OK");
      } else {
        setMessageErreur(
          reponse.erreur
            ? reponse.erreur
            : "Erreur lors de la mise à  jour  de la progression"
        );
        setError(reponse.error);
      }
    });
  };

  /// ajouter une étape
  const handleAjouterEtape = (indexEtetape) => {
    console.log("handleAjouterEtape", indexEtetape);
    let newStep = indexEtetape.etape;
    let index = indexEtetape.index;
    console.log({ index, newStep });
    let tempArray = [...steps];
    tempArray.splice(index, 0, newStep);
    setSteps(tempArray);
    //NES enregistrer
    enregistrerProgression(etapeActive, tempArray);
  };

  /// terminer une étape
  const onClickTerminer = () => {
    let aujourdhui = new Date();
    let dateDuJour = DateToString(aujourdhui, {
      month: "long",
      day: "numeric",
    });

    //NES pour chaque étape où la date n'est pas renseignée je la renseigne
    let tempArray = [...steps];

    console.log({ editionIndex });

    for (let index = 0; index <= editionIndex; index++) {
      const etape = tempArray[index];
      if (!etape.date) {
        let newData = {
          ...etape,
          date: aujourdhui,
          texte_secondaire: "le " + dateDuJour,
        };
        tempArray.splice(index, 1, newData);
      }
    }

    console.log({ tempArray });

    setSteps(tempArray);

    //NEs je passe à l'étape d'après
    let newIndex = editionIndex + 1;
    setEtapeActive(newIndex);

    //NES changement d'état
    let newEtat = "EN_COURS";
    if (newIndex === steps.length) {
      newEtat = "CORRIGE";
    }

    //NES fermeture de la popup
    handleClose();
    //NES enregsitrer
    enregistrerProgression(newIndex, tempArray, newEtat);
  };

  /// etape active
  const onToujoursActive = () => {
    //NES je passe à l'étape d'avant
    setEtapeActive(editionIndex);
    //NES j'enlève la date et le texte pour toutes les étapes suivantes
    let tempArray = [...steps];

    for (let index = tempArray.length - 1; index >= editionIndex; index--) {
      const etape = tempArray[index];
      let newData = {
        ...etape,
        date: "",
        texte_secondaire: "",
      };
      tempArray.splice(index, 1, newData);
    }

    setSteps(tempArray);
    //NES enregsitrer la progression
    enregistrerProgression(editionIndex, tempArray, "EN_COURS");

    //NES fermeture de la popup
    handleClose();
  };

  /// changer de type d'étape
  const handleChangeEtape = (etape) => {
    console.log(etape);
    let newData = { ...editionEtape, texte: etape.texte, code: etape.code };
    console.log(newData);
    let tempArray = [...steps];
    tempArray.splice(editionIndex, 1, newData);
    setSteps(tempArray);
    //NES fermeture de la popup
    handleClose();
    //NES enregsitrer
    enregistrerProgression(etapeActive, tempArray);
  };

  /// changement de la date prévisionnelle
  const handleDateSelect = (jour) => {
    console.log({ jour });
    plannifierDemande(demande, jour._id);

    //NES fermeture de la popup
    handleClose();
  };

  /// suppression de la date prévisionnelle
  const handleSupprimerDatePrevisionnnelle = () => {
    deplanniferDemande(demande);

    /*
    let newData = {
      ...editionEtape,
      date_previsionnelle: "",
      texte_secondaire: "",
    };
    console.log(newData);
    let tempArray = [...steps];
    tempArray.splice(editionIndex, 1, newData);
    setSteps(tempArray);
    */
    //NES fermeture de la popup
    handleClose();
  };

  //NES Supprimer l'étape
  const handleSupprimerEtape = () => {
    let tempArray = [...steps];
    tempArray.splice(editionIndex, 1);
    setSteps(tempArray);
    //NES fermeture de la popup
    handleClose();
    //NES enregsitrer
    enregistrerProgression(etapeActive, tempArray);
  };

  return (
    <div style={{ width: "100%" }}>
      <Stepper
        alternativeLabel
        activeStep={etapeActive}
        connector={<MonConnecteur onAjouterEtape={handleAjouterEtape} />}
      >
        {steps.map((etape, index) => (
          <Step key={index}>
            <StepLabel
              onClick={(e) => handleOpenMenu(e, etape, index)}
              StepIconComponent={ProgressionStepIcon}
              icon={etape.code}
            >
              <div style={{ color: index === etapeActive ? "#205A71" : "" }}>
                {etape.texte}

                <div
                  className="InformationSecondaire "
                  style={{
                    color: index === etapeActive ? "#205A71" : "",
                    fontWeight: index === etapeActive ? "bold" : "",
                  }}
                >
                  {etape?.texte_secondaire}
                </div>
              </div>
            </StepLabel>
          </Step>
        ))}
      </Stepper>
      <MessageErreur message={messageErreur} error={error} />
      <MessageAlerte message={message} />
      <Popover
        open={anchorEl ? true : false}
        anchorEl={anchorEl}
        onClose={handleClose}
        // onClick={handleClose}
        anchorOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
        TransitionProps={{
          onExited: () => setEditionEtape(),
        }}
      >
        <div style={{ padding: "20px", width: "360px" }}>
          <SwipeableViews index={index} animateHeight>
            <div>
              <ListItem button onClick={onClickTerminer}>
                <CheckCircleIcon
                  className="IconeMenu"
                  style={{ color: "#0FA600" }}
                />
                <ListItemText primary={"Terminer " + editionEtape?.code} />
              </ListItem>
              <ListItem button onClick={onToujoursActive}>
                <PlayCircleOutlineIcon
                  className="IconeMenu"
                  style={{ color: "#205A71" }}
                />
                <ListItemText primary="Etape active" />
              </ListItem>
              <ListItem button onClick={() => setIndex(1)}>
                <IconesProgression
                  className="IconeMenu"
                  //code={etape.code}
                  style={{ color: "#666" }}
                />
                <ListItemText primary="Modifier le type d'étape" />
                <ArrowForwardIosIcon style={{ color: "#DDD" }} />
              </ListItem>
              <ListItem button onClick={() => setIndex(2)}>
                <DateRangeIcon className="IconeMenu" />
                <ListItemText primary="Date prévisionnelle" />
                <ArrowForwardIosIcon style={{ color: "#DDD" }} />
              </ListItem>
              <Divider style={{ margin: "10px 0px" }} />
              <ListItem button onClick={handleSupprimerDatePrevisionnnelle}>
                <DateRangeIcon className="IconeMenu" style={{ color: "red" }} />
                <ListItemText
                  primary="Supprimer la date prévisionnelle"
                  style={{ color: "red" }}
                />
              </ListItem>
              <ListItem
                button
                style={{ color: "red" }}
                onClick={handleSupprimerEtape}
              >
                <HighlightOffIcon
                  className="IconeMenu"
                  style={{ color: "red" }}
                />
                Supprimer l'étape
              </ListItem>
            </div>
            <div>
              <BoutonRetour
                texte="retour"
                onClick={() => setIndex(0)}
                style={{ marginBottom: "10px" }}
              />
              <StepProgressionPicker onSelect={handleChangeEtape} />
            </div>
            <div>
              <BoutonRetour
                texte="retour"
                onClick={() => setIndex(0)}
                style={{ marginBottom: "10px" }}
              />
              <JourPlanningPicker onSelect={handleDateSelect} />
            </div>
          </SwipeableViews>
        </div>
      </Popover>
    </div>
  );
}
