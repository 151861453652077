import React, { useState, createContext, useContext } from "react";
import { AuthentificationContext } from "./AuthentificationContext";
import { POST, PATCH, DELETE, GET } from "../functions/FonctionsApi";

//NES Création du contexte
export const MedicalContext = createContext();

export const MedicalProvider = (props) => {
  //NES gestion du contexte
  const authContext = useContext(AuthentificationContext);

  //NES gestion du state
  const [antecedentsEtAllergies, setAntecedentsEtAllergies] = useState([]);

  //NES Permet de retourner les allergies d'un patient
  const getAllergies = (patient_id) => {
    let retour = [];
    retour = antecedentsEtAllergies.filter(
      (item) =>
        item.code_type_antecedent === "allergie" &&
        item.patient_id === patient_id
    );
    return retour;
  };

  //NES permet de retourner les antecedents d'un patient
  const getAntecedents = (patient_id, code_type_antecedent) => {
    let retour = [];
    if (code_type_antecedent) {
      retour = antecedentsEtAllergies.filter(
        (item) =>
          item.code_type_antecedent === code_type_antecedent &&
          patient_id === item.patient_id
      );
    } else {
      retour = antecedentsEtAllergies.filter(
        (item) =>
          (item.code_type_antecedent === "antecedent_patient" ||
            item.code_type_antecedent === "antecedent_chirurgical" ||
            item.code_type_antecedent === "antecedent_familial") &&
          patient_id === item.patient_id
      );
    }
    return retour;
  };

  //NES permet de retourner les habitus d'un patient
  const getHabitus = (patient_id, code_type_antecedent) => {
    let retour = [];
    if (code_type_antecedent) {
      retour = antecedentsEtAllergies.filter(
        (item) =>
          item.code_type_antecedent === code_type_antecedent &&
          item.patient_id === patient_id
      );
    } else {
      retour = antecedentsEtAllergies.filter(
        (item) =>
          (item.code_type_antecedent === "habitus_tabac" ||
            item.code_type_antecedent === "habitus_alcool" ||
            item.code_type_antecedent === "habitus_sport" ||
            item.code_type_antecedent === "habitus_travail" ||
            item.code_type_antecedent === "habitus_drogue") &&
          item.patient_id === patient_id
      );
    }

    return retour;
  };

  //POST /patients/${patient_id}/antecedents/
  const postAntecedentsEtAllergiesContext = async (patient_id, data) => {
    let MonUrl = `${process.env.REACT_APP_URL_API_MEDICAL}/patients/${patient_id}/antecedents/`;

    let reponseApi = await POST(MonUrl, authContext.token, data);

    if (reponseApi.code === "OK") {
      let tempArray = [...antecedentsEtAllergies];
      tempArray = tempArray.concat(reponseApi.data);
      setAntecedentsEtAllergies(tempArray);
    }
    return reponseApi;
  };

  //PATCH /patients/${patient_id}/antecedents/$antecedent_id
  // Permet de mettre à jour un antécédent allergie ou habitus dans le context
  const patchAntecedentEtAllergiesContext = async (data) => {
    let MonUrl = `${process.env.REACT_APP_URL_API_MEDICAL}/patients/${data.patient_id}/antecedents/${data._id}`;
    let reponseApi = await PATCH(MonUrl, authContext.token, data);
    console.log({ reponseApi });

    if (reponseApi.code === "OK") {
      //NES si c'est bon je mets à jour le state
      let index = antecedentsEtAllergies.findIndex(
        (item) => item._id === reponseApi.data._id
      );
      if (index > -1) {
        setAntecedentsEtAllergies((previous) => {
          let tempArray = Array.from(previous);
          tempArray.splice(index, 1, reponseApi.data);
          return tempArray;
        });
      }
    }
    return reponseApi;
  };

  //DELETE /patients/${patient_id}/antecedents/$antecedent_id
  //NES Permet de supprimer un antécédent, allergies, habitus
  const deleteAntecedentEtAllergiesContext = async (data) => {
    let MonUrl = `${process.env.REACT_APP_URL_API_MEDICAL}/patients/${data.patient_id}/antecedents/${data._id}`;
    let reponseApi = await DELETE(MonUrl, authContext.token);
    console.log({ reponseApi });

    //NES Mise à jour du contexte
    if (reponseApi.code === "OK") {
      let tempArray = Array.from(antecedentsEtAllergies);
      let index = tempArray.findIndex((item) => item._id === data._id);
      tempArray.splice(index, 1);
      setAntecedentsEtAllergies(tempArray);
    }

    return reponseApi;
  };

  //GET Permet de supprimer un antécédent, allergies, habitus depuis le contexte
  const getAntecedentEtAllergiesContext = async (patient_id) => {
    let MonUrl = `${process.env.REACT_APP_URL_API_MEDICAL}/patients/${patient_id}/antecedents/`;
    let reponseApi = await GET(MonUrl, authContext.token);

    //NES Mise à jour du contexte
    if (reponseApi.code === "OK") {
      setAntecedentsEtAllergies(reponseApi.data);
    }

    return reponseApi;
  };

  return (
    <MedicalContext.Provider
      value={{
        antecedentsEtAllergies,
        setAntecedentsEtAllergies,
        getAllergies,
        getAntecedents,
        getHabitus,
        postAntecedentsEtAllergiesContext,
        patchAntecedentEtAllergiesContext,
        deleteAntecedentEtAllergiesContext,
        getAntecedentEtAllergiesContext,
      }}
    >
      {props.children}
    </MedicalContext.Provider>
  );
};
