import React, { useContext, useState } from "react";
import MonStyle from "./PageListeAgendas.module.css";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import EventIcon from "@material-ui/icons/Event";
import ListItemText from "@material-ui/core/ListItemText";
import { Link } from "react-router-dom";
import { AgendasContext } from "../../../Data/AgendasContext";
import PeopleAltIcon from "@material-ui/icons/PeopleAlt";
import BoutonTexte from "../../../Design/Boutons/BoutonTexte";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import LoaderHorizontal from "../../../Design/Progression/LoaderHorizontal";
import MessageErreur from "../../../Design/Messages/MessageErreur";
import { CabinetsContext } from "../../../Data/CabinetContext";
import { AuthentificationContext } from "../../../Data/AuthentificationContext";

export default function PageListeAgendas({ selectedId, style }) {
  //NES gestion du contexte
  const { agendas, getAgendas } = useContext(AgendasContext);
  const { cabinets } = useContext(CabinetsContext);
  const auth = useContext(AuthentificationContext);

  //NES gestion du routage
  const history = useHistory();

  //NES gestion du state
  const [isLoading, setIsLoading] = useState(false);
  const [messageErreur, setMessageErreur] = useState();
  const [error, setError] = useState();
  const [liste, setListe] = useState([]);

  //USEEFFECT récupération des agendas
  useEffect(() => {
    let actif = true;

    setIsLoading(true);
    getAgendas().then((reponse) => {
      if (actif) setIsLoading(false);

      //NES cas d'erreur
      if (reponse.code !== "OK") {
        setMessageErreur("Erreur lors de la récupération des agendas");
        setError(reponse);
      }

      console.log(reponse);

      //NES si j'ai un agenda pour mon utilisateur je reroute
      let monAgenda = reponse.data.find(
        (item) => item.utilisateur_id === auth.payload.utilisateur_id
      );

      if (monAgenda && !selectedId) {
        history.push(`/agendas/${monAgenda._id}`);
      }

      //NES si il n'y a aucun agenda je propose la création d'un agenda
      if (reponse.data.length === 0) {
        history.push(`/agendas/new`);
      }
    });
    return () => (actif = false);
    // eslint-disable-next-line
  }, []);

  //USEEFFECT Affichage des agendas par cabinet
  useEffect(() => {
    let listeAgendas = agendas;
    let listeGroupes = [];
    listeAgendas.forEach((agenda) => {
      let index = listeGroupes.findIndex(
        (item) => item.cabinet_id === agenda.cabinet_id
      );

      if (index > -1) {
        //NES j'ajoute l'agenda au groupe
        let tempGroupe = listeGroupes[index];
        let tempGroupeAgenda = tempGroupe.agendas;
        tempGroupeAgenda.push(agenda);
        tempGroupe = { ...tempGroupe, agendas: tempGroupeAgenda };
        listeGroupes.splice(index, 1, tempGroupe);
      } else {
        //NES j'ajoute un nouveau groupe
        let monCabinet = cabinets.find(
          (item) => item._id === agenda.cabinet_id
        );
        let tempGroupe = {
          cabinet_id: agenda.cabinet_id,
          nom: monCabinet?.nom,
          agendas: [agenda],
        };
        listeGroupes.push(tempGroupe);
      }
      setListe(listeGroupes);
    });

    // eslint-disable-next-line
  }, [cabinets, agendas]);

  return (
    <div className={MonStyle.PageListeAgendas} style={style}>
      <div>
        {liste.map((groupe, index) => (
          <div key={index}>
            <h3 className={MonStyle.Titre} style={{ marginTop: "20px" }}>
              {groupe.nom}
            </h3>

            <List component="nav">
              {groupe.agendas.map((item) => (
                <ListItem
                  key={item._id}
                  button
                  selected={selectedId === item._id}
                  component={Link}
                  style={{ marginBottom: "5px" }}
                  to={"/agendas/" + item._id}
                >
                  <EventIcon
                    style={{ marginRight: "15px", color: item.couleur_agenda }}
                  />
                  <ListItemText primary={item.nom_agenda} />
                </ListItem>
              ))}
            </List>
          </div>
        ))}

        <LoaderHorizontal isLoading={isLoading} />
        <MessageErreur
          message={messageErreur}
          error={error}
          style={{ marginTop: "20px" }}
        />

        <BoutonTexte
          texte="Ajouter un agenda"
          fullWidth
          style={{ margin: "20px 0px" }}
          link="/agendas/new"
        />
      </div>
      <div className={MonStyle.TousAgendas}>
        <ListItem
          key="all"
          button
          selected={selectedId === "all"}
          component={Link}
          style={{ marginBottom: "5px" }}
          to={"/agendas/all"}
        >
          <div style={{ marginRight: "15px" }}>
            <PeopleAltIcon />
          </div>
          <ListItemText primary=" Tous les agendas" />
        </ListItem>
      </div>
    </div>
  );
}
