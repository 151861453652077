import React from "react";
import TextField from "@material-ui/core/TextField";
import { withStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Error from "@material-ui/icons/Error";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import Menu from "../Navigation/BoutonMenu";
import Tooltip from "../DataDisplay/ToolTip";

//NES Style de composant NORMAL
const CssTextField = withStyles({
  root: {
    width: "100%",
    borderRadius: (props) => props.montheme.borderRadius,
    backgroundColor: (props) => props.montheme.backgroundColor,

    "& .Mui-disabled ": {
      backgroundColor: (props) => props.montheme.disabled.backgroundColor,
    },

    "& .MuiInputBase-input": {
      textAlign: (props) => (props.textalign ? props.textalign : ""),
      color: (props) =>
        props.couleur === "erreur" ? "#F44336" : props.montheme.color,
      textTransform: (props) =>
        props.texttransform ? props.texttransform : "none",
      borderRadius: (props) => props.montheme.borderRadius,
      paddingRight: (props) => (props.paddingright ? props.paddingright : ""),
      "&.Mui-disabled ": {
        color: (props) => props.montheme.disabled.color,
        backgroundColor: (props) => props.montheme.disabled.backgroundColor,
      },
    },
    "& .MuiInputBase-input::placeholder": {
      color: (props) => props.montheme.placeholderColor,
      opacity: 1,
    },

    "& label": {
      color: (props) => (props.couleur === "erreur" ? "#F44336" : "#00000087"),
    },
    "& label.Mui-focused": {
      color: (props) => (props.couleur === "erreur" ? "#F44336" : "#208AB0"),
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "DDD",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: (props) =>
          props.couleur === "erreur" ? "#F44336" : props.montheme.borderColor,
      },
      borderRadius: (props) => props.montheme.borderRadius,

      "&:hover fieldset": {
        borderColor: (props) =>
          props.couleur === "erreur" ? "#F44336" : props.montheme.borderColor,
      },
      "&.Mui-focused fieldset": {
        borderColor: (props) =>
          props.couleur === "erreur" ? "#F44336" : "#208AB0",
      },
      "&.Mui-disabled fieldset": {
        borderColor: (props) => props.montheme.borderColor,
      },
    },
  },
})(TextField);

export default function InputTexte({
  label,
  value,
  textTransform,
  onChange,
  onBlur,
  onClick,
  onKeyDown,
  isErreur,
  isWarning,
  autoFocus,
  id,
  placeholder,
  autoComplete,
  theme,
  disabled,
  multiline,
  onFocus,
  inputRef,
  taille,
  menu,
  style,
  borderRadius,
  paddingRight,
  disablepadding,
  endAdornment,
  startAdornment,
  iconeComposantMenu,
  variant,
  ...props
}) {
  //NES Gestion de l'icone de fin dans le champs
  let inputProps = {};
  let iconeMenu =
    menu && !disabled ? (
      <Menu
        iconeComposant={iconeComposantMenu}
        tooltip="actions"
        edge="end"
        id={"menu_" + id}
        couleurIcone={theme === "texteblanc" ? "white" : ""}
      >
        {menu}
      </Menu>
    ) : undefined;
  let iconeWarning = isWarning ? (
    <Tooltip texte={isWarning}>
      <ErrorOutlineIcon style={{ color: "#FF9800" }} />
    </Tooltip>
  ) : undefined;
  let iconeErreur = isErreur ? (
    <Tooltip texte={isErreur}>
      <Error style={{ color: "#F44336" }} />
    </Tooltip>
  ) : undefined;

  if (iconeWarning || iconeErreur || iconeMenu) {
    inputProps = {
      endAdornment: (
        <InputAdornment position="end">
          {iconeWarning}
          {iconeErreur}
          {iconeMenu}
        </InputAdornment>
      ),
    };
  }

  if (endAdornment) {
    inputProps = {
      endAdornment: (
        <InputAdornment position="end">{endAdornment}</InputAdornment>
      ),
    };
  }

  if (startAdornment) {
    inputProps = {
      ...inputProps,
      startAdornment: (
        <InputAdornment position="start">{startAdornment}</InputAdornment>
      ),
    };
  }

  //NES themes selon les variantes d'affichage

  const themeModeLecture = {
    color: "#208AB0",
    borderColor: "#FFFFFF00",
    marginTop: "0px",
    disabled: {
      color: "black",
      backgroundColor: "#FFFFFF00",
    },
    borderRadius: "4px",
    placeholderColor: "#00000050",
  };

  const themeModeTexteBlanc = {
    color: "white",
    borderColor: "#FFFFFF00",
    marginTop: "0px",
    disabled: {
      color: "white",
      backgroundColor: "#FFFFFF00",
    },
    borderRadius: "4px",
    placeholderColor: "#00000050",
  };

  const themeModeFormulaire = {
    color: "#208AB0",
    borderColor: "#DDDDDD",
    marginTop: disablepadding ? "" : "20px",
    backgroundColor: "white",
    disabled: {
      color: "black",
      backgroundColor: "#EDEDED",
    },
    borderRadius: "4px",
    placeholderColor: "#00000050",
  };

  const themeFondGris = {
    color: "#208AB0",
    borderColor: "#F6F6F6",
    backgroundColor: "#F6F6F6",
    marginTop: "0px",
    disabled: {
      color: "#97989D",
      backgroundColor: "#EDEDED",
    },
    borderRadius: "4px",
    placeholderColor: "#00000050",
  };

  const themeConnexion = {
    color: "#208AB0",
    borderColor: "#F6F6F6",
    backgroundColor: "#F6F6F6",
    marginTop: "0px",
    disabled: {
      color: "#97989D",
      backgroundColor: "#EDEDED",
    },
    borderRadius: "8px 8px 0px 0px",
    placeholderColor: "#00000090",
  };

  let montheme = themeModeFormulaire;
  switch (theme) {
    case "lecture":
      montheme = themeModeLecture;
      break;
    case "fondgris":
      montheme = themeFondGris;
      break;
    case "texteblanc":
      montheme = themeModeTexteBlanc;
      break;
    case "connexion":
      montheme = themeConnexion;
      break;
    default:
      montheme = themeModeFormulaire;
      break;
  }

  let maTaille = "small";
  switch (taille) {
    case "L":
      maTaille = "medium";
      break;
    case "XL":
      maTaille = "large";
      break;
    default:
      maTaille = "small";

      break;
  }

  return (
    <CssTextField
      montheme={montheme}
      id={id}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      label={label}
      variant="outlined"
      onClick={onClick}
      texttransform={textTransform}
      size={maTaille}
      autoFocus={autoFocus}
      couleur={isErreur ? "erreur" : ""}
      placeholder={placeholder}
      style={{ marginTop: montheme.marginTop, ...style }}
      disabled={disabled}
      multiline={multiline}
      name={label ? label : placeholder}
      onFocus={onFocus}
      onKeyDown={onKeyDown}
      inputRef={inputRef}
      paddingright={paddingRight}
      InputProps={inputProps}
      {...props}
    />
  );
}
