import React from "react";
import MonStyle from "./AffichageDates.module.css";
import BoutonRond from "../../../../../Design/Boutons/BoutonRond";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";

export default function AffichageDates({ datesSemaine, onNext, onPrevious }) {
  let listeJours = datesSemaine;
  let premierJour = undefined;
  let dernierJour = undefined;
  let optionsPremierJour = { day: "numeric" };
  let optionsDernierJour = {
    month: "long",
    day: "numeric",
  };

  let titre = "";

  if (listeJours.length > 0) {
    //NES quelle est la 1ère date
    premierJour = listeJours[0];

    //NES quelle est la dernière date
    let lastIndex = listeJours.length - 1;
    dernierJour = listeJours[lastIndex];

    //NES affichage du mois de la date de début  si ≠ de la date de fin
    if (premierJour.getMonth() !== dernierJour.getMonth()) {
      optionsPremierJour = { month: "short", day: "numeric" };
    }

    //NES construction des options d'affichage de la 1ère et dernière date
    if (premierJour.getYear() !== dernierJour.getYear()) {
      optionsDernierJour = { year: "numeric", month: "long", day: "numeric" };
    }

    //NES Construction du titre
    titre =
      premierJour.toLocaleDateString("fr-FR", optionsPremierJour) +
      " au " +
      dernierJour.toLocaleDateString("fr-FR", optionsDernierJour);
  }

  return (
    <div className={MonStyle.AffichageDates}>
      <div className={MonStyle.BoutonRond}>
        <BoutonRond
          iconeComposant={<NavigateBeforeIcon />}
          tooltip="Semaine précédente"
          onClick={onPrevious}
          couleurIcone="gris"
        />
        <BoutonRond
          iconeComposant={<NavigateNextIcon />}
          tooltip="Semaine suivante"
          onClick={onNext}
          couleurIcone="gris"
        />
      </div>

      <div className={MonStyle.Titre1}>{titre}</div>
    </div>
  );
}
