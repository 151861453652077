import React, { useState, useContext } from "react";
import SentimentVeryDissatisfiedIcon from "@material-ui/icons/SentimentVeryDissatisfied";
import SentimentSatisfiedIcon from "@material-ui/icons/SentimentSatisfied";
import SentimentSatisfiedAltIcon from "@material-ui/icons/SentimentSatisfiedAlt";
import SentimentVerySatisfiedIcon from "@material-ui/icons/SentimentVerySatisfied";
import BoutonRond from "../../../Design/Boutons/BoutonRond";
import MessageAide from "../../../Design/Messages/MessageAide";
import { SupportContext } from "../../../Data/SupportContext";

export default function EvalutionCorrection({ demande }) {
  //NES Gestion du contexte
  const { patchDemandes } = useContext(SupportContext);

  //NES gestion du state
  const [message, setMessage] = useState("");

  //NES CONSTANTE
  const couleurIcone = {
    MAUVAIS: "red",
    MOYEN: "#208AB0",
    BIEN: "#0FA600",
    EXCELLENT: "#0FA600",
  };

  ///Gestion de la couleur de l'icone
  const getColorIcone = (note) => {
    if (demande.evaluation_resolution) {
      //NES je retourne la couleur pour l'icone active uniquement
      if (demande.evaluation_resolution === note) {
        return couleurIcone[note];
      } else {
        return "#00000030";
      }
    } else {
      //NES je retourne la couleur de l'icone
      return couleurIcone[note];
    }
  };

  ///click sur une note
  const handleClickNote = (note) => {
    console.log({ note });
    switch (note) {
      case "MAUVAIS":
        setMessage(
          "Nous en sommes désolé. Pouvez-vous nous laisser un message pour nous expliquer?"
        );
        break;
      case "EXCELLENT":
        setMessage("Merci beaucoup ! Ca nous fait plaisir 😄");
        break;

      default:
        setMessage("Merci pour votre retour sur notre intervention.");
        break;
    }

    let newData = { ...demande, evaluation_resolution: note };

    console.log(newData);
    patchDemandes(newData).then((reponse) => {
      console.log(reponse);
    });
  };

  //NES je n'affiche pas si la demande n'est pas corrigée
  if (demande?.etat !== "CORRIGE") {
    return "";
  }

  return (
    <div
      style={{
        border: "1px solid #DDD",
        padding: "20px",
        borderRadius: "10px",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          justifyContent: "space-between",
        }}
      >
        <div>
          <h3>Le problème est-il corrigé?</h3>
          <p className="SousTitre">
            Dites-nous si la correction vous a apporté satisfaction
          </p>
        </div>
        <div style={{ display: "flex" }}>
          <BoutonRond
            tooltip="NON, pas du tout"
            onClick={() => handleClickNote("MAUVAIS")}
            iconeComposant={
              <SentimentVeryDissatisfiedIcon
                style={{ fontSize: "30px", color: getColorIcone("MAUVAIS") }}
              />
            }
          />
          <BoutonRond
            tooltip="OUI, mais c'est moyen comme solution"
            onClick={() => handleClickNote("MOYEN")}
            iconeComposant={
              <SentimentSatisfiedIcon
                style={{ fontSize: "30px", color: getColorIcone("MOYEN") }}
              />
            }
          />
          <BoutonRond
            tooltip="OUI, mon problème est bien corrigé"
            onClick={() => handleClickNote("BIEN")}
            iconeComposant={
              <SentimentSatisfiedAltIcon
                style={{ fontSize: "30px", color: getColorIcone("BIEN") }}
              />
            }
          />
          <BoutonRond
            tooltip="OUI, c'est top ! 👍"
            onClick={() => handleClickNote("EXCELLENT")}
            iconeComposant={
              <SentimentVerySatisfiedIcon
                style={{ fontSize: "30px", color: getColorIcone("EXCELLENT") }}
              />
            }
          />
        </div>
      </div>
      <MessageAide
        message={message}
        style={{ marginTop: "10px" }}
        disableClose
      />
    </div>
  );
}
