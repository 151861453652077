import React, { useContext, useEffect, useState } from "react";
import MonStyle from "./AffichagePatient.module.css";
import { CommentairesPatientsContext } from "../../../../Data/CommentairesPatientsContext";
import { HashLink as Link } from "react-router-hash-link";
import ObjetTelephone from "../../../../Design/Objets/ObjetTelephone";
import { PatientsContext } from "../../../../Data/PatientsContext";
import ObjetCommentaire from "../../../../Design/Objets/ObjetCommentaire";
import BoutonTexte from "../../../../Design/Boutons/BoutonTexte";
import { pluriel } from "../../../../functions/Formatter";
import SyntheseFamille from "../AffichageFamille/SyntheseFamille";
import AffichageMedecinTraitant from "../AffichageContacts/AffichageMedecinTraitant";

export default function AffichagePatient({ patient_id }) {
  //NES gestion du contexte
  const { commentairesPatients, getCommentairesContext } = useContext(
    CommentairesPatientsContext
  );
  const { ongletsPatients } = useContext(PatientsContext);

  //NES gestion du state
  const [listeCommentaires, setListeCommentaires] = useState([]);
  const [age, setAge] = useState("");
  const [dateNaissance, setDateNaissance] = useState("");
  const [patient, setPatient] = useState();
  const [nbCommentaires, setNbCommentaires] = useState(0);
  const [titreCommentaires, setTitreCommentaires] = useState("");

  //USEEFFECT je vais chercher le patient dans les onglets
  useEffect(() => {
    if (patient_id) {
      let monPatient = ongletsPatients.find((item) => item._id === patient_id);
      setPatient(monPatient);
    }
  }, [ongletsPatients, patient_id]);

  //USEEFFECT construction de l'affiche de la date de naissance et de l'age
  useEffect(() => {
    if (patient) {
      //NES construction de l'age
      setDateNaissance("");
      if (patient.date_de_naissance) {
        let affichageDateNaissance = "Né le ";
        if (patient.sexe === "Femme") {
          affichageDateNaissance = "Née le ";
        }
        affichageDateNaissance =
          affichageDateNaissance + patient.date_de_naissance;

        setDateNaissance(affichageDateNaissance);
      }
      //NES contruction de l'âge
      setAge("");
      if (patient?.age?.nombre) {
        let affichageAge = "";
        affichageAge = patient?.age?.nombre + " " + patient?.age?.unite;
        setAge(affichageAge);
      }
    }
  }, [patient]);

  //GET COMMENTAIRES
  useEffect(() => {
    if (patient_id) {
      getCommentairesContext(patient_id);
    }
    //eslint-disable-next-line
  }, [patient_id]);

  //USEEFFECT affichage des commentaires
  useEffect(() => {
    setTitreCommentaires("");

    //NES nb de commentaires à afficher

    if (commentairesPatients) {
      let listeCommentairesRecents = [...commentairesPatients];
      //NES je ne garde que les commentaires de mon patient
      listeCommentairesRecents = listeCommentairesRecents.filter(
        (item) => item.patient_id === patient_id
      );

      //NES je les trie par date de création
      listeCommentairesRecents.sort(
        (a, b) => a?.tracabilite_creation?.date > b?.tracabilite_creation?.date
      );

      let commentairesAffiches = [];

      //NES je garde les commentaires "bookmarkées"
      commentairesAffiches = listeCommentairesRecents.filter(
        (item) => item.is_bookmark
      );
      if (commentairesAffiches.length > 0) {
        setTitreCommentaires("Commentaires épinglés ");
      }

      if (
        commentairesAffiches.length === 0 &&
        listeCommentairesRecents.length > 0
      ) {
        //NES je n'ai pas de commentaires bookmarké alors j'affiche le dernier commentaire
        commentairesAffiches.push(
          listeCommentairesRecents[listeCommentairesRecents.length - 1]
        );
        setTitreCommentaires("Dernier commentaire");
      }

      setListeCommentaires(commentairesAffiches);
      setNbCommentaires(
        listeCommentairesRecents?.length - commentairesAffiches?.length
      );
    }
    //eslint-disable-next-line
  }, [commentairesPatients, patient_id]);

  //NES si je n'ai pas d'id je retourne vide
  if (!patient) {
    return "";
  }

  return (
    <div className={MonStyle.AffichagePatient}>
      <Link to={`/patients/${patient._id}/dossier`}>
        <div className={MonStyle.NomPrenom}>
          <span className={MonStyle.Nom}>{patient.nom} </span>
          <span className={MonStyle.NomJeuneFille}>
            {patient.nom_jeune_fille ? "(" + patient.nom_jeune_fille + ")" : ""}
          </span>
          <span className={MonStyle.Prenom}>{patient?.prenom}</span>
        </div>
        <div className={MonStyle.InformationsSecondaires}>
          {age} - {patient.sexe}
        </div>
        <div className={MonStyle.InformationsSecondaires}>{dateNaissance}</div>
        <div className={MonStyle.InformationsSecondaires}>
          {patient.lieu_naissance
            ? " à " + patient?.lieu_naissance?.toUpperCase()
            : ""}
        </div>
      </Link>

      <Link to={`/patients/${patient._id}/contacts`}>
        <AffichageMedecinTraitant
          patient_id={patient_id}
          taille="S"
          style={{ marginTop: "30px" }}
        />
      </Link>

      <Link
        to={`/patients/${patient._id}/dossier`}
        className={MonStyle.ListeTelephones}
      >
        {patient?.contact?.telephones &&
          patient.contact.telephones.map((telephone, index) => (
            <div className={MonStyle.ObjetTelephone} key={index}>
              <ObjetTelephone telephone={telephone} key={index} />
            </div>
          ))}
      </Link>

      {/* FAMILLE*/}

      <div className={MonStyle.Famille}>
        <Link to={`/patients/${patient._id}/dossier#famille`}>
          <SyntheseFamille patient={patient} />
        </Link>
      </div>

      {/* DERNIERS COMMENTAIRES */}
      <div className={MonStyle.BlocCommentaires}>
        <h4 style={{ paddingLeft: "5px" }}>{titreCommentaires}</h4>

        <Link to={`/patients/${patient._id}/dossier#commentaires`}>
          <div className={MonStyle.ListeCommentaires}>
            {listeCommentaires.map((commentaire) => (
              <ObjetCommentaire
                commentaire={commentaire}
                taille="S"
                disabled
                key={commentaire._id}
              />
            ))}
          </div>
        </Link>
        {nbCommentaires > 0 && (
          <BoutonTexte
            texte={pluriel(
              nbCommentaires,
              "autre commentaire",
              "autres commentaires"
            )}
            link={`/patients/${patient._id}/dossier#commentaires`}
            fullWidth
          />
        )}
      </div>
    </div>
  );
}
