import React from "react";
import MonStyle from "./SurfaceLigne.module.css";

export default function SurfaceLigne({ id, ...props }) {
  return (
    <div className={MonStyle.SurfaceLigne} id={id}>
      {props.children}
    </div>
  );
}
