import React from "react";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import { HashLink as Link } from "react-router-hash-link";

const useStyles = makeStyles({
  root: {
    color: (props) => props.color,
    border: (props) => props.border,
    padding: "0 20px",
    fontFamily: "Lato",
    fontWeight: "700",
    textTransform: "none",
    borderRadius: (props) => props.radius,
    height: (props) => props.height,
    "&:hover": {
      backgroundColor: "#208AB020",
      border: `1px dashed #208AB0`,
      color: "#208AB0",
    },
  },
  disabled: {
    backgroundColor: "red",
  },
});

export default function BoutonAjoutBloc({
  texte,
  taille,
  couleur,
  onClick,
  href,
  fullWidth,
  link,
  style,
  iconeComposant,
  theme,
  id,
}) {
  //NES gestion des taille S,M,L
  let height = "";
  switch (taille) {
    case "S":
      height = "30px";
      break;
    case "M":
      height = "40px";
      break;
    case "L":
      height = "50px";
      break;
    case "XL":
      height = "60px";
      break;

    default:
      height = "40px";
  }

  //NES Gestion du radius
  let radius = "";
  switch (theme) {
    case "rond":
      radius = "30px";
      break;
    default:
      radius = "4px";
  }

  //NES gestion de la couleur
  let color = "";
  let border = "";

  switch (couleur) {
    case "bleu":
      color = "#208AB0";
      border = `1px dashed #208AB0`;
      break;

    default:
      color = "#97989D";
      border = `1px dashed #DDD`;
      break;
  }

  //NES création du style
  let monTheme = {
    height: height,
    radius: radius,
    color: color,
    border: border,
  };
  const classes = useStyles(monTheme);

  return (
    <Button
      id={id}
      className={classes.root}
      variant="outlined"
      size="large"
      onClick={onClick}
      href={href}
      fullWidth={fullWidth}
      component={link ? Link : Button}
      to={link}
      style={style}
      name={texte}
    >
      {iconeComposant && <div className="Icone">{iconeComposant}</div>}
      {texte}
    </Button>
  );
}
