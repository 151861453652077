import * as React from "react";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";

const BeTextField = styled((props) => (
  <TextField
    InputProps={{ ...props.InputProps, disableUnderline: true }}
    {...props}
  />
))(({ theme, ...props }) => ({
  "& .MuiInputBase-input": {
    backgroundColor: "white",
    "&:hover": { backgroundColor: "white" },
    "&.Mui-disabled": {
      color: "black",
      backgroundColor: "var(--gris)",
      WebkitTextFillColor: "unset",
    },
  },

  "& .MuiInputBase-root-MuiFilledInput-root": {
    // backgroundColor: "none",
    backgroundColor: "white",
    "&:hover": { backgroundColor: "white" },
  },

  "& .MuiInputBase-root-MuiFilledInput-root:focus": {
    backgroundColor: "white",

    //backgroundColor: "none",
  },
  "& .MuiFilledInput-root": {
    overflow: "hidden",
    borderRadius: 6,
    backgroundColor: "white",
    //paddingTop: "0px",
    border: "1px solid",
    borderColor: props.error ? "red" : "var(--border)",
    margin: "1px 0px",
    //paddingLeft: "1px",
    color: props.error ? "red" : "var(--bleu)",

    "&:hover": {
      backgroundColor: "white",
    },

    "&.Mui-focused": {
      //paddingLeft: "0px",
      margin: "0px 0px",
      backgroundColor: "white",
      border: "2px solid ",
      borderColor: props.error ? "red" : "var(--bleu)",
    },
  },

  "& .MuiInputLabel-root": {
    color: props.error ? "red" : "var(--noir80)",
    lineHeight: "2.4",
    fontSize: "16px",
    marginTop: "-4px",
    paddingLeft: "3px",
    "&.Mui-focused": {
      color: props.error ? "red" : "black",
    },
  },

  "& .MuiFormHelperText-root": {
    color: "red",
    opacity: 0.8,
  },
}));

export default function InputTexteFilled({ ...props }) {
  return <BeTextField name={props.label} {...props} variant="filled" />;
}
