import React, { useContext, useEffect, useState } from "react";
import MonStyle from "./EditionMesuresConsultation.module.css";
import BoutonAjoutBloc from "../../../../Design/Boutons/BoutonAjoutBloc";
import EditionMesure from "../AffichageMesure/EditionMesure";
import PanneauListeMesures from "./PanneauListeMesures";
import { ConstantesContext } from "../../../../Data/ConstantesContext";

export default function EditionMesuresConsultation({
  consultation,
  consultation_id,

  patient_id,
}) {
  //NES Gestion du state
  const [mesuresAffichees, setMesuresAffichees] = useState([]);
  const [open, setOpen] = useState(false);

  //NES gestion du contexte
  const { getMesuresPatientBookmarked } = useContext(ConstantesContext);

  //NES Initialisation des mesures de la consultation
  //USEEFFECT
  useEffect(() => {
    let actif = true;

    if (consultation) {
      let mesuresConsultation = [];

      if (consultation.mesures) {
        mesuresConsultation = consultation.mesures;
      }

      getMesuresPatientBookmarked(patient_id).then((reponse) => {
        let mesuresSuivies = reponse.data;
        mesuresSuivies.forEach((mesureSuivie) => {
          let index = mesuresConsultation.findIndex(
            (item) => item.mesure_id === mesureSuivie.mesure_id
          );
          if (index === -1) {
            mesuresConsultation.push(mesureSuivie);
          }
        });
        if (actif) setMesuresAffichees(mesuresConsultation);
      });
    }

    return () => (actif = false);
    // eslint-disable-next-line
  }, [consultation_id]);

  ///OpenPanneau
  const openPanneau = () => {
    setOpen(true);
  };

  ///ClosePanneau
  const closePanneau = () => {
    setOpen(false);
  };

  return (
    <div>
      <div className="TitreBloc">Constantes & mesures</div>
      <div className={MonStyle.Mesures}>
        {mesuresAffichees.map((mesure) => (
          <EditionMesure
            value={mesure.valeur}
            alerte={mesure.alerte}
            mesure={mesure}
            key={mesure._id ? mesure._id : mesure.mesure_id}
            consultation_id={consultation_id}
            patient_id={patient_id}
            style={{ marginRight: "10px", marginBottom: "15px" }}
          />
        ))}
        <BoutonAjoutBloc
          texte="Ajouter"
          theme="rond"
          onClick={openPanneau}
          taille="XL"
          style={{ width: "120px" }}
        />
      </div>
      <PanneauListeMesures
        open={open}
        onOpen={openPanneau}
        onClose={closePanneau}
        consultation_id={consultation_id}
        patient_id={patient_id}
      />
    </div>
  );
}
