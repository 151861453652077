import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

export default function IconesHabitus(props) {
  return (
    <SvgIcon {...props}>
      <path d="M9.99771399,0 C10.5208949,0.721330368 10.892668,1.26057194 11.1130331,1.61772473 C11.1794558,1.72537811 11.2442138,1.83288967 11.3073072,1.9402594 C11.3696099,2.04628354 11.4302893,2.15216939 11.4893456,2.25791694 C12.563649,4.1815909 13.1008007,6.05949947 13.1008007,7.89164265 C13.1008007,8.57222886 13.0565536,9.22981815 12.9741114,9.85094298 C13.1952665,9.64698281 13.4279561,9.44380726 13.6702754,9.24291835 C15.3948245,7.8132234 17.5047327,6.81281803 20,6.24170224 C19.3143399,8.79811747 18.1092352,10.7911726 16.3846862,12.2208675 C15.9506441,12.5806994 15.5132841,12.9103316 15.0823985,13.2058835 C16.7201892,13.2184597 18.3594727,13.6888825 20,14.6149305 C18.3198551,15.6187491 16.6230195,16.1349217 14.9094931,16.1634481 C13.2782962,16.1906001 11.838224,15.9477106 10.9998492,15.5577477 L11,19 C11,19.5522847 10.5522847,20 10,20 C9.44771525,20 9,19.5522847 9,19 L9.00131698,15.6858069 C8.16279904,16.0717697 6.79143312,16.3366742 5.23783587,16.3625394 C3.52430937,16.3910605 1.77836408,15.8651429 5.5067062e-14,14.7847865 C1.61781273,13.9562651 3.22323782,13.5001159 4.81627527,13.4163389 L4.936,13.412 L4.53868383,13.1435411 C4.13705761,12.8641995 3.73041976,12.5556003 3.32665314,12.2208675 C1.60210406,10.7911726 0.493219681,8.79811747 2.62012634e-13,6.24170224 C2.30282683,6.81281803 4.31651478,7.8132234 6.04106386,9.24291835 C6.40570795,9.54521755 6.74854575,9.85269475 7.0663799,10.1601867 C6.95492675,9.44931507 6.89462731,8.68614882 6.89462731,7.89164265 C6.89462731,5.34922288 7.92898954,2.71867533 9.99771399,0 Z"></path>
    </SvgIcon>
  );
}

export function IconeHabitusDrogue(props) {
  return (
    <SvgIcon {...props}>
      <path d="M11.997714,2 C12.5208949,2.72133037 12.892668,3.26057194 13.1130331,3.61772473 C13.1794558,3.72537811 13.2442138,3.83288967 13.3073072,3.9402594 C13.3696099,4.04628354 13.4302893,4.15216939 13.4893456,4.25791694 C14.563649,6.1815909 15.1008007,8.05949947 15.1008007,9.89164265 C15.1008007,10.5722289 15.0565536,11.2298182 14.9741114,11.850943 C15.1952665,11.6469828 15.4279561,11.4438073 15.6702754,11.2429184 C17.3948245,9.8132234 19.5047327,8.81281803 22,8.24170224 C21.3143399,10.7981175 20.1092352,12.7911726 18.3846862,14.2208675 C17.9506441,14.5806994 17.5132841,14.9103316 17.0823985,15.2058835 C18.7201892,15.2184597 20.3594727,15.6888825 22,16.6149305 C20.3198551,17.6187491 18.6230195,18.1349217 16.9094931,18.1634481 C15.2782962,18.1906001 13.838224,17.9477106 12.9998492,17.5577477 L13,21 C13,21.5522847 12.5522847,22 12,22 C11.4477153,22 11,21.5522847 11,21 L11.001317,17.6858069 C10.162799,18.0717697 8.79143312,18.3366742 7.23783587,18.3625394 C5.52430937,18.3910605 3.77836408,17.8651429 2,16.7847865 C3.61781273,15.9562651 5.22323782,15.5001159 6.81627527,15.4163389 L6.936,15.412 L6.53868383,15.1435411 C6.13705761,14.8641995 5.73041976,14.5556003 5.32665314,14.2208675 C3.60210406,12.7911726 2.49321968,10.7981175 2,8.24170224 C4.30282683,8.81281803 6.31651478,9.8132234 8.04106386,11.2429184 C8.40570795,11.5452176 8.74854575,11.8526947 9.0663799,12.1601867 C8.95492675,11.4493151 8.89462731,10.6861488 8.89462731,9.89164265 C8.89462731,7.34922288 9.92898954,4.71867533 11.997714,2 Z"></path>
    </SvgIcon>
  );
}

export function IconeHabitusDrogueFree(props) {
  return (
    <SvgIcon {...props}>
      <path
        d="M2,8.24170224 C3.33311325,8.57232286 4.56932779,9.04681071 5.70864361,9.66516578 L13.1087594,17.6061471 C13.0714126,17.5902801 13.0350991,17.5741439 12.9998492,17.5577477 L13,21 C13,21.5522847 12.5522847,22 12,22 C11.4477153,22 11,21.5522847 11,21 L11.001317,17.6858069 C10.162799,18.0717697 8.79143312,18.3366742 7.23783587,18.3625394 C5.52430937,18.3910605 3.77836408,17.8651429 2,16.7847865 C3.61781273,15.9562651 5.22323782,15.5001159 6.81627527,15.4163389 L6.936,15.412 L6.53868383,15.1435411 C6.13705761,14.8641995 5.73041976,14.5556003 5.32665314,14.2208675 C3.60210406,12.7911726 2.49321968,10.7981175 2,8.24170224 Z M4.82670412,3.4627074 L19.1486697,18.8211351 L17.6859623,20.1851319 L3.36399672,4.82670412 L4.82670412,3.4627074 Z M17.7373938,15.2353108 C19.1572201,15.3519625 20.5781429,15.8123179 22,16.6149305 C21.3160472,17.0235646 20.6293286,17.3513891 19.939844,17.5984038 L17.7373938,15.2353108 Z M11.997714,2 C12.5208949,2.72133037 12.892668,3.26057194 13.1130331,3.61772473 C13.1794558,3.72537811 13.2442138,3.83288967 13.3073072,3.9402594 C13.3696099,4.04628354 13.4302893,4.15216939 13.4893456,4.25791694 C14.563649,6.1815909 15.1008007,8.05949947 15.1008007,9.89164265 C15.1008007,10.5722289 15.0565536,11.2298182 14.9741114,11.850943 C15.1952665,11.6469828 15.4279561,11.4438073 15.6702754,11.2429184 C17.3948245,9.8132234 19.5047327,8.81281803 22,8.24170224 C21.3143399,10.7981175 20.1092352,12.7911726 18.3846862,14.2208675 C18.0767959,14.4761163 17.7672361,14.7161692 17.459502,14.9396408 L9.51708711,6.42253545 C10.0286479,4.97522183 10.8555235,3.50104335 11.997714,2 Z"
        id="Combined-Shape"
      ></path>
    </SvgIcon>
  );
}

export function IconeHabitusAlcool(props) {
  return (
    <SvgIcon {...props}>
      <path d="M9.11111111,10.8888889 L9.11111111,16.2222222 L4.66666667,16.2222222 L4.66666667,18 L15.3333333,18 L15.3333333,16.2222222 L10.8888889,16.2222222 L10.8888889,10.8888889 L18,3.77777778 L18,2 L2,2 L2,3.77777778 L9.11111111,10.8888889 Z M6,5.55555556 L4.22222222,3.77777778 L15.7777778,3.77777778 L14,5.55555556 L6,5.55555556 Z"></path>
    </SvgIcon>
  );
}

export function IconeHabitusAlcoolFree(props) {
  return (
    <SvgIcon {...props}>
      <path
        d="M2.82670412,1.4627074 L17.1486697,16.8211351 L15.6859623,18.1851319 L1.36399672,2.82670412 L2.82670412,1.4627074 Z M9.11066667,13.4597074 L10.8886667,15.3667074 L10.8888889,16.2222222 L11.6856667,16.2217074 L13.3436667,17.9997074 L4.66666667,18 L4.66666667,16.2222222 L9.11111111,16.2222222 L9.11066667,13.4597074 Z M18,2 L18,3.77777778 L12.2656667,9.5117074 L8.57566667,5.5547074 L14,5.55555556 L15.7777778,3.77777778 L6.91766667,3.7777074 L5.25966667,1.9997074 L18,2 Z"
        id="Combined-Shape"
      ></path>
    </SvgIcon>
  );
}

export function IconeHabitusSport(props) {
  return (
    <SvgIcon {...props}>
      <path d="M12.4471288,4 C12.6589948,4 12.8443776,4.04637681 13.0032771,4.13913043 L13.0032771,4.13913043 L17.081698,6.3884058 L18.0086119,4.85797101 C18.2734444,4.3942029 18.9090425,4.23188406 19.4387075,4.46376812 C19.9683726,4.71884058 20.1537553,5.27536232 19.8624396,5.71594203 L19.8624396,5.71594203 L18.4058607,8.15072464 C18.2734444,8.3826087 18.0350951,8.54492754 17.7437793,8.61449275 C17.4524636,8.68405797 17.1346645,8.63768116 16.8963152,8.49855072 L16.8963152,8.49855072 L13.8242579,6.8057971 L11.1229661,11.2115942 L13.6918417,13.2985507 C13.9301909,13.484058 14.0626072,13.7391304 14.0361239,14.0173913 L14.0361239,14.0173913 L13.7712914,19.1188406 C13.7448082,19.6057971 13.2681096,20 12.7119613,20 L12.7119613,20 L12.6589948,20 C12.0763632,19.9768116 11.626148,19.5362319 11.6791145,19.026087 L11.6791145,19.026087 L11.9174637,14.3652174 L8.87188967,11.884058 L7.01806197,14.8753623 C6.83267921,15.1768116 6.48839692,15.3623188 6.09114813,15.3623188 L6.09114813,15.3623188 L1.05933011,15.3623188 C0.476698549,15.3623188 0,14.9449275 0,14.4347826 C0,13.9246377 0.476698549,13.5072464 1.05933011,13.5072464 L1.05933011,13.5072464 L5.45555006,13.5072464 L10.1430858,5.85507246 L8.58057389,5.85507246 L7.15047824,8.15072464 C6.96509547,8.45217391 6.59432993,8.63768116 6.22356439,8.63768116 C6.06466488,8.63768116 5.87928211,8.59130435 5.72038259,8.52173913 C5.21720079,8.28985507 5.00533477,7.71014493 5.29665055,7.26956522 L5.29665055,7.26956522 L7.01806197,4.48695652 C7.20344474,4.18550725 7.54772703,4 7.94497582,4 L7.94497582,4 Z M13.6842105,0 C14.8469153,0 15.7894737,0.8954305 15.7894737,2 C15.7894737,3.1045695 14.8469153,4 13.6842105,4 C12.5215058,4 11.5789474,3.1045695 11.5789474,2 C11.5789474,0.8954305 12.5215058,0 13.6842105,0 Z"></path>
    </SvgIcon>
  );
}

export function IconeHabitusSportFree(props) {
  return (
    <SvgIcon {...props}>
      <path d="M11.815,16.361 L13.803,18.494 L13.7712914,19.1188406 C13.7466998,19.5710145 13.3339124,19.9432121 12.8297848,19.9940761 L12.7119613,20 L12.6589948,20 C12.1152054,19.9783575 11.6867652,19.5931208 11.6751204,19.1270853 L11.6791145,19.026087 L11.815,16.361 Z M2.82670412,1.4627074 L17.1486697,16.8211351 L15.6859623,18.1851319 L1.36399672,2.82670412 L2.82670412,1.4627074 Z M6.921,11.113 L8.38,12.677 L7.01806197,14.8753623 C6.85327729,15.1433172 6.56294237,15.3196636 6.22145736,15.3555436 L6.09114813,15.3623188 L1.05933011,15.3623188 C0.476698549,15.3623188 0,14.9449275 0,14.4347826 C0,13.9610766 0.411030892,13.5673469 0.936237032,13.5135167 L1.05933011,13.5072464 L5.45555006,13.5072464 L6.921,11.113 Z M12.4471288,4 C12.6166216,4 12.7691651,4.02968116 12.9047594,4.08904348 L13.0032771,4.13913043 L17.081698,6.3884058 L18.0086119,4.85797101 C18.2734444,4.3942029 18.9090425,4.23188406 19.4387075,4.46376812 C19.9305393,4.70062112 20.1255155,5.19739722 19.9176162,5.61990958 L19.8624396,5.71594203 L18.4058607,8.15072464 C18.2734444,8.3826087 18.0350951,8.54492754 17.7437793,8.61449275 C17.4940801,8.67412008 17.2249238,8.64856551 17.0030204,8.55243166 L16.8963152,8.49855072 L13.8242579,6.8057971 L12.204,9.447 L9.632,6.689 L10.1430858,5.85507246 L8.855,5.855 L7.30219956,4.19059695 C7.44827597,4.09099939 7.62345241,4.02686618 7.81466659,4.00677521 L7.94497582,4 L12.4471288,4 Z M13.6842105,0 C14.8469153,0 15.7894737,0.8954305 15.7894737,2 C15.7894737,3.1045695 14.8469153,4 13.6842105,4 C12.5215058,4 11.5789474,3.1045695 11.5789474,2 C11.5789474,0.8954305 12.5215058,0 13.6842105,0 Z"></path>
    </SvgIcon>
  );
}
