import React from "react";
import SurfacePopUp from "../Surfaces/SurfacePopUp";
import MessageErreur from "../Messages/MessageErreur";

export default function PopupMessageErreur({ messageErreur, error, onClose }) {
  return (
    <SurfacePopUp open={messageErreur ? true : false} onClose={onClose}>
      <MessageErreur message={messageErreur} error={error} taille="XL" />
    </SurfacePopUp>
  );
}
