import React, { useContext, useEffect, useState } from "react";
import { Document, Page, Text, View, StyleSheet } from "@react-pdf/renderer";
import { StylesDocuments } from "../StylesDocumentsPdf";
import { DateToString } from "../../functions/FonctionsDateHeure";
import DocumentPdf from "../DocumentPdf";
import LoaderGlobal from "../../Design/Progression/LoaderGlobal";
import PopupMessageErreur from "../../Design/Messages/PopupMessageErreur";
import { CabinetsContext } from "../../Data/CabinetContext";
import { formatterTelephone, pluriel } from "../../functions/Formatter";

export default function DocumentJournalDesReglements({
  listeReglements,
  filtreModeReglement,
  dateDebut,
  dateFin,
  open,
  onClose,
  autoPrint,
}) {
  //NES Gestion du style
  const Styles = StyleSheet.create(StylesDocuments);

  //NES gestion du contexte
  const { getObjetCabinet } = useContext(CabinetsContext);

  //NES gestion du state
  const [data, setData] = useState();
  const [messageErreur, setMessageErreur] = useState();
  const [cabinet, setCabinet] = useState();
  const [totalDebit, setTotalDebit] = useState(0);
  const [totalCredit, setTotalCredit] = useState(0);
  const [total, setTotal] = useState(0);

  //USEEFFECT chargement des données du cabinet
  useEffect(() => {
    if (open) {
      let monCabinet = getObjetCabinet();
      setCabinet(monCabinet);
    }
    // eslint-disable-next-line
  }, [open]);

  //USEEFFECT chargement des règlements à afficher
  useEffect(() => {
    if (listeReglements) {
      //NES je vais calculer les totaux
      let tempTotalDebit = 0;
      let tempTotalCredit = 0;
      listeReglements.forEach((reglement) => {
        if (reglement.montant >= 0) {
          tempTotalCredit = tempTotalCredit + reglement.montant;
        } else {
          tempTotalDebit = tempTotalDebit + reglement.montant;
        }
      });
      setTotalCredit(tempTotalCredit);
      setTotalDebit(tempTotalDebit);
      //NES attention c'est bien un + car débit est négatif
      setTotal(tempTotalCredit + tempTotalDebit);
      setData(listeReglements);
    }
  }, [listeReglements]);

  //NES fermeture de l'impression
  const handleClose = () => {
    //NES pour ne pas garder les donnée dans le cache sinon
    setCabinet();
    if (onClose) {
      onClose();
    }
  };

  const handlePopupMessageErreurClose = () => {
    setMessageErreur();
    handleClose();
  };

  if (messageErreur && open) {
    return (
      <PopupMessageErreur
        messageErreur={messageErreur}
        onClose={handlePopupMessageErreurClose}
      />
    );
  }

  if (open && (data === undefined || cabinet === undefined)) {
    return <LoaderGlobal isLoading={true} />;
  }

  return (
    <DocumentPdf
      openDocumentPdf={open}
      onClose={handleClose}
      autoPrint={autoPrint}
    >
      <Document>
        <Page size="A4" style={Styles.page}>
          <View>
            <div style={{ display: "flex", flexDirection: "row" }}>
              {/* BLOC CABINET*/}

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  flex: 1,
                  marginRight: "30px",
                }}
              >
                <Text style={Styles.titreBloc}>{cabinet?.nom}</Text>

                <Text>{cabinet?.adresse?.rue}</Text>

                <Text>
                  {cabinet?.adresse?.code_postal +
                    " " +
                    cabinet?.adresse?.ville}
                </Text>

                {cabinet?.telephone?.numero && (
                  <Text>
                    Tél : {formatterTelephone(cabinet?.telephone?.numero)}
                  </Text>
                )}
                {cabinet?.fax?.numero && (
                  <Text>Fax : {formatterTelephone(cabinet?.fax?.numero)}</Text>
                )}

                <Text>{cabinet?.email}</Text>

                <Text>{cabinet?.texte_horaire}</Text>
                <Text>{cabinet?.texte_rdv}</Text>

                <Text
                  style={[
                    Styles.informationPrincipale,
                    {
                      borderTop: "1px solid #D8D8D8",
                      marginTop: "10px",
                      paddingTop: "10px",
                      textAlign: "center",
                    },
                  ]}
                >
                  {cabinet?.texte_libre}
                </Text>
              </div>

              {/* BLOC TITRE*/}

              <div
                style={{
                  flex: 1,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    minHeight: "60px",
                  }}
                >
                  <Text style={Styles.titre}>JOURNAL </Text>
                  <Text style={Styles.titre}>DES REGLEMENTS</Text>
                  <Text style={Styles.titreBloc}>{filtreModeReglement}</Text>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <Text>
                      {DateToString(dateDebut, {
                        year: "numeric",
                        month: "numeric",
                        day: "numeric",
                      }) + " "}
                    </Text>
                    {
                      <Text>
                        {dateFin !== dateDebut &&
                          " au " +
                            DateToString(dateFin, {
                              year: "numeric",
                              month: "numeric",
                              day: "numeric",
                            })}
                      </Text>
                    }
                  </div>
                </div>
              </div>
            </div>

            {/* BLOC ENTETES LIGNES*/}

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                height: "20px",
                backgroundColor: "#EEEEEE",
                padding: "0px 10px",
                marginTop: "20px",
              }}
            >
              <Text style={[Styles.titreColonneLeft, { width: "60px" }]}>
                Date{" "}
              </Text>
              <Text style={[Styles.titreColonneLeft, { width: "330px" }]}>
                Règlements
              </Text>
              <Text style={[Styles.titreColonneRight, { width: "60px" }]}>
                Débit
              </Text>
              <Text style={[Styles.titreColonneRight, { width: "60px" }]}>
                Crédit
              </Text>
            </div>

            {/* BLOC LIGNES*/}

            {data?.map((ligne) => (
              <View
                key={ligne._id}
                wrap={false}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  minHeight: "30px",
                  borderTop: "1px solid #D8D8D8",
                  padding: "5px 10px",
                }}
              >
                <Text style={[Styles.informationSecondaire, { width: "60px" }]}>
                  {DateToString(ligne.date, {
                    day: "numeric",
                    month: "numeric",
                    year: "numeric",
                  })}
                </Text>

                <div>
                  <Text style={[Styles.normal, { width: "330px" }]}>
                    {ligne?.libelle_type_reglement +
                      " " +
                      ligne?.libelle_mode_reglement +
                      " du " +
                      DateToString(ligne?.date, {
                        year: "numeric",
                        month: "numeric",
                        day: "numeric",
                      })}
                  </Text>
                  {ligne?.cotations?.map((cotation) => (
                    <Text
                      key={cotation._id}
                      style={[Styles.informationSecondaire, { width: "330px" }]}
                    >
                      Consultation du {DateToString(cotation.cotation_date)} de{" "}
                      {cotation?.cotation_patient?.nom +
                        " " +
                        cotation?.cotation_patient?.prenom +
                        " "}
                      ({cotation?.cotation_montant} €)
                    </Text>
                  ))}
                </div>

                <Text style={[Styles.normalRight, { width: "60px" }]}>
                  {ligne.montant < 0 ? ligne.montant + " €" : "-"}
                </Text>
                <Text style={[Styles.normalRight, { width: "60px" }]}>
                  {ligne.montant > 0 ? ligne.montant + " €" : "-"}
                </Text>
              </View>
            ))}

            {/* BLOC TOTAUX*/}
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                borderTop: "1px solid black",
                borderBottom: "1px solid black",
                minHeight: "20px",
                padding: "0px 10px",
              }}
            >
              <Text style={[Styles.informationPrincipale, { width: "400px" }]}>
                {pluriel(data?.length, "règlement", "règlements") +
                  " pour un total de : " +
                  total +
                  " €"}
              </Text>
              <Text
                style={[
                  Styles.informationPrincipale,
                  { width: "60px", textAlign: "right" },
                ]}
              >
                {totalDebit} €
              </Text>
              <Text
                style={[
                  Styles.informationPrincipale,
                  { width: "60px", textAlign: "right" },
                ]}
              >
                {totalCredit} €
              </Text>
            </div>
          </View>

          <View
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              fontSize: "8px",
              position: "absolute",
              bottom: "10mm",
              borderTop: "1px solid black",
              left: "10mm",
              paddingTop: "5px",
            }}
            fixed
          >
            <Text>
              {cabinet?.informations_juridiques?.nom_entite_juridique +
                ", " +
                cabinet?.informations_juridiques?.adresse_siege +
                ". Capital : " +
                cabinet?.informations_juridiques?.capital_social +
                " - N° SIRET : " +
                cabinet?.informations_juridiques?.numero_siret}
            </Text>
            <Text
              style={{
                fontSize: "8px",
                fontWeight: "bold",
              }}
              render={({ pageNumber, totalPages }) =>
                `Page ${pageNumber} / ${totalPages}`
              }
            />
          </View>
        </Page>
      </Document>
    </DocumentPdf>
  );
}
