import React, { useState, useEffect } from "react";
import InputTexte from "./InputTexte";
import { getEmoticonPays } from "../../functions/Formatter";
import Autocomplete from "@material-ui/lab/Autocomplete";
import InputAdornment from "@material-ui/core/InputAdornment";

export default function InputPays({ value, onTexteChange, onBlur }) {
  const PAYS = [
    "ALLEMAGNE",
    "BELGIQUE",
    "ESPAGNE",
    "FRANCE",
    "LUXEMBOURG",
    "ITALIE",
    "ROYAUME-UNI",
    "SUISSE",
    "EUROPE",
  ];

  //NES GESTION DU STATE
  const [texte, setTexte] = useState("");

  useEffect(() => {
    setTexte(value);
  }, [value]);

  const handleTexteChange = (newInputValue) => {
    setTexte(newInputValue);
    if (onTexteChange) {
      onTexteChange(newInputValue);
    }
  };

  return (
    <Autocomplete
      onBlur={onBlur}
      getOptionLabel={(option) => option}
      freeSolo
      openOnFocus
      disableClearable
      options={PAYS}
      value={texte}
      filterOptions={(x) => x}
      onInputChange={(event, newInputValue) => {
        handleTexteChange(newInputValue);
      }}
      renderOption={(option) => (
        <React.Fragment>
          <span style={{ marginRight: "10px" }}>{getEmoticonPays(option)}</span>
          {option}
        </React.Fragment>
      )}
      renderInput={(params) => (
        <InputTexte
          {...params}
          label="Pays"
          size="small"
          textTransform="uppercase"
          InputProps={{
            ...params.InputProps,
            startAdornment: texte ? (
              <InputAdornment position="start">
                <div style={{ marginLeft: "10px" }}>
                  {getEmoticonPays(texte)}
                </div>
              </InputAdornment>
            ) : (
              ""
            ),
          }}
        />
      )}
    />
  );
}
