import React from "react";
import Checkbox from "@material-ui/core/Checkbox";
import { withStyles } from "@material-ui/core/styles";

//NES style dynamique de la checkbox
const BeCheckbox = withStyles({
  root: {
    color: "#208AB0",
    "&$checked": {
      color: "#208AB0",
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

export default function CheckBox({ checked, onChange, ...props }) {
  return <BeCheckbox checked={checked} onChange={onChange} {...props} />;
}
