import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { withStyles } from "@material-ui/core/styles";

const BeTabs = withStyles({
  root: {
    borderBottom: "none",
    paddingLeft: "0px",
    padding: 0,
    marginLeft: "0px",
    margin: 0,
  },
  indicator: {
    backgroundColor: "#205A71",
  },
})(Tabs);

const BeTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    color: "#666",
    minWidth: 72,
    fontSize: "14px",
    fontWeight: 500,
    padding: "0px 0px ",
    //fontWeight: theme.typography.fontWeightRegular,
    marginRight: "20px",
    marginLeft: 0,
    fontFamily: ["Raleway"].join(","),
    "&:hover": {
      color: "#205A71",
      opacity: 1,
    },
    "&$selected": {
      color: "#205A71",
      border: "none",
      fontWeight: 700,
    },
    "&:focus": {
      color: "#205A71",
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);

export default function OngletFacturation({ style }) {
  //NES récupération de l'URl de la page
  let URL = document.location.href;

  //NES gestion du state
  const [selected, setSelected] = useState(0);

  useEffect(() => {
    setSelected(0);

    if (URL.indexOf("carte-bancaire") > -1) {
      setSelected(1);
    }
    if (URL.indexOf("mes-factures") > -1) {
      setSelected(2);
    }
    if (URL.indexOf("mes-informations-facturation") > -1) {
      setSelected(3);
    }
  }, [URL]);

  return (
    <BeTabs
      value={selected}
      indicatorColor="primary"
      textColor="primary"
      scrollButtons="on"
      style={style}
    >
      <BeTab selected label="Abonnement" component={Link} to="/facturation" />
      <BeTab
        selected
        label="Carte bancaire"
        component={Link}
        to="/facturation/carte-bancaire"
      />
      <BeTab
        selected
        label="Mes factures"
        component={Link}
        to="/facturation/mes-factures"
      />
      <BeTab
        selected
        label="Mes informations de facturation"
        component={Link}
        to="/facturation/mes-informations-facturation"
      />
    </BeTabs>
  );
}
