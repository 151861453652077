import React, { useContext, useEffect, useState } from "react";
import MonStyle from "./PageGraphiqueConstante.module.css";
import { ConstantesContext } from "../../../../Data/ConstantesContext";
import { PatientsContext } from "../../../../Data/PatientsContext";
import { ModeleTailleBebeGarcon } from "../../Components/AffichageGraphiques/ModelesCourbes";
import SurfacePapier from "../../../../Design/Surfaces/SurfacePapier";
import {
  DateToString,
  DateShortOuHeureToString,
  getMoisSinceDate,
  dateToMoisAnnee,
} from "../../../../functions/FonctionsDateHeure";
import { calculAgeMois } from "../../../../functions/AfficherAgeAnneeMoisJour";
import BoutonRetour from "../../../../Design/Boutons/BoutonRetour";
import BoutonRond from "../../../../Design/Boutons/BoutonRond";
import ZoomOutMapIcon from "@material-ui/icons/ZoomOutMap";
import SurfacePopUp from "../../../../Design/Surfaces/SurfacePopUp";
import GraphiqueCourbe from "../../Components/AffichageGraphiques/GraphiqueCourbe";
import OngletsBoutons from "../../../../Design/DataDisplay/OngletsBoutons";
import BoutonTexte from "../../../../Design/Boutons/BoutonTexte";
import BoutonConfirmerSuppression from "../../../../Design/Boutons/BoutonConfirmerSuppression";
import MessageErreur from "../../../../Design/Messages/MessageErreur";
import { useHistory } from "react-router-dom";

//NES mes constantes
const labelMoisBebe = [
  0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
  22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36,
];

export default function PageGraphiqueConstante({ match }) {
  //NES paramètres de l'URL
  let mesure_id = match.params.mesure_id;
  let patient_id = match.params.patient_id;

  //NES gestion du routage
  const history = useHistory();

  //NES Gestion du contexte
  const { getMesuresPatientById, mesuresPatient, deleteMesuresPatient } =
    useContext(ConstantesContext);
  const { getPatientContext } = useContext(PatientsContext);

  //NES gestion du state
  const [patient, setPatient] = useState({});
  const [mesure, setMesure] = useState({});
  const [afficherPopup, setAfficherPopup] = useState(false);
  const [mesures, setMesures] = useState([]);
  const [onglets, setOnglets] = useState(["Mois", "Toutes"]);
  const [labelsX, setLabelsX] = useState([]);
  const [titreX, setTitreX] = useState("truc");
  const [affichage, setAffichage] = useState("Mois");
  const [valeurs, setValeurs] = useState([]);
  const [modele, setModele] = useState({});
  const [afficherSuppression, setAfficherSuppression] = useState(false);
  const [messageErreur, setMessageErreur] = useState("");

  useEffect(() => {
    recuperationMesures(mesure_id);
    // eslint-disable-next-line
  }, [mesuresPatient, mesure_id]);

  //NES récupération des informations du patients
  useEffect(() => {
    if (patient_id) {
      getPatientContext(patient_id).then((data) => {
        setPatient(data);
        //NES affichage des modèles selon l'age du patient
        if (data.age.unite === "mois" || data.age.nombre < 3) {
          setOnglets(["Mois", "Toutes", "Taille 36 mois"]);
        }
      });
    }
    // eslint-disable-next-line
  }, [patient_id]);

  const recuperationMesures = (mesure_id) => {
    if (mesure_id) {
      let maMesure = getMesuresPatientById(mesure_id, patient_id);
      if (maMesure) {
        setMesure(maMesure);
        //NES je trie mes mesures par date décroissante pour que la plus récente soit en haut
        let allMesures = maMesure.mesures;
        allMesures.sort((a, b) => {
          return new Date(a.date) - new Date(b.date);
        });
        //NES je ne garde pas les mesures vides
        allMesures = allMesures.filter((item) => item.valeur !== "");
        setMesures(allMesures);
      }
    }
  };

  //NES je stoke dans le state l'affichage attendu
  const handleChangeEchelle = (onglet) => {
    setAffichage(onglet);
  };

  //NES gestion de l'échelle des abscisses
  const constructionAffichage = () => {
    setModele({});
    switch (affichage) {
      case "Mois":
        //NES Construction des titres
        setTitreX("Mois");
        //NES j'inverse les mesures pour garder toujours la plus récente par mois
        let mesuresInverses = [...mesures];
        mesuresInverses = mesuresInverses.sort((b, a) => {
          return new Date(a.date) - new Date(b.date);
        });
        //NES je pars de la 1ere valeur et je calcule tous les mois
        let premiereMesure = mesuresInverses[mesuresInverses.length - 1];
        let allMois = [];
        allMois = getMoisSinceDate(premiereMesure.date);
        setLabelsX(allMois);
        //NES pour chaque mois je vais voir si j'ai une valeur
        let listeMesures = [];
        allMois.forEach((mois) => {
          let maValeur = mesuresInverses.find(
            (item) => dateToMoisAnnee(item.date) === mois
          );
          listeMesures.push(
            maValeur ? maValeur.valeur.replace(",", ".") : null
          );
        });
        setValeurs(listeMesures);
        break;

      case "Taille 36 mois":
        //NES Construction des titres
        setTitreX("Age en mois");
        //NES construction des labelsX
        setLabelsX(labelMoisBebe);
        //NES récupération des valeurs
        let dateDeNaissance = patient.date_de_naissance;
        let valeursMois = [];
        labelMoisBebe.forEach((ageMois) => {
          let laValeur = mesures.find(
            (item) => calculAgeMois(dateDeNaissance, item.date) === ageMois
          );
          valeursMois.push(laValeur ? laValeur.valeur.replace(",", ".") : null);
        });
        setValeurs(valeursMois);
        setModele(ModeleTailleBebeGarcon);
        break;

      default:
        let allDates = [];
        //NES constrution  des titres
        setTitreX("Date des mesures");
        //NES je cherche toutes les dates
        mesures.forEach((item) => {
          allDates.push(DateShortOuHeureToString(item.date));
        });
        setLabelsX(allDates);
        //NES je cherche toutes les mesures
        let allMesures = [];
        mesures.forEach((item) => {
          allMesures.push(item.valeur.replace(",", "."));
        });
        setValeurs(allMesures);
        break;
    }
  };

  //NES
  useEffect(() => {
    if (mesures.length > 0) {
      constructionAffichage();
    }
    // eslint-disable-next-line
  }, [mesures, affichage]);

  //DELETE suppression de l'historique
  const supprimerHistorique = () => {
    console.log("supprimerHistorique");
    console.log(patient_id, mesure_id);
    setAfficherSuppression(false);

    deleteMesuresPatient(patient_id, mesure_id).then((reponseApi) => {
      console.log({ reponseApi });
      if (!reponseApi || !reponseApi.code === "OK") {
        setMessageErreur("Erreur lors de la suppression de l'historique");
      } else {
        setMessageErreur("");
        //NES redirection sur la page des constantes
        history.push(`/patients/${patient_id}/constantes`);
      }
    });
  };

  return (
    <div className={MonStyle.PageGraphiqueConstante}>
      <SurfacePapier>
        <div className={MonStyle.PageGraphiqueConstante}>
          <div className={MonStyle.TitreEtBouton}>
            <div className="Titre1">{mesure.libelle}</div>
            <BoutonRond
              iconeComposant={<ZoomOutMapIcon />}
              onClick={() => setAfficherPopup(true)}
              tooltip="Agrandir"
            />
          </div>
          <div className={MonStyle.BoutonRetour}>
            <BoutonRetour
              texte="Liste des constantes"
              link={"/patients/" + patient_id + "/constantes"}
            />
          </div>
          <div className={MonStyle.Onglets}>
            <OngletsBoutons
              onglets={onglets}
              onSelect={(onglet) => handleChangeEchelle(onglet)}
              ongletSelectionne={affichage}
            />
          </div>
          <div className={MonStyle.Graphique}>
            <GraphiqueCourbe
              labelsX={labelsX}
              titreX={titreX}
              valeurs={valeurs}
              modele={modele}
            />
          </div>
          <div className={MonStyle.BlocValeurs}>
            <div className="Titre2" style={{ marginBottom: "20px" }}>
              Valeurs
            </div>
            {mesures.map((item, index) => (
              <div className={MonStyle.LigneValeurs} key={index}>
                <div className={MonStyle.DateValeurs}>
                  {DateToString(item.date, {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                  })}
                </div>
                <div className={MonStyle.Valeur}>
                  {item.valeur}
                  <span className={MonStyle.Unite}>
                    {item.unite ? item.unite : mesure.unite}
                  </span>
                </div>
              </div>
            ))}
          </div>
        </div>
        <SurfacePopUp
          open={afficherPopup}
          onClose={() => setAfficherPopup(false)}
          style={{ width: "90%", height: "80vh" }}
        >
          <div className={MonStyle.Popup}>
            <GraphiqueCourbe
              labelsX={labelsX}
              titreX={titreX}
              valeurs={valeurs}
              modele={modele}
            />
          </div>
        </SurfacePopUp>
      </SurfacePapier>
      <div className={MonStyle.BlocSuppression}>
        {!afficherSuppression ? (
          <BoutonTexte
            texte="Supprimer l'historique"
            couleur="rouge"
            onClick={() => setAfficherSuppression(true)}
          />
        ) : (
          <BoutonConfirmerSuppression
            onAnnuler={() => setAfficherSuppression(false)}
            onSupprimer={supprimerHistorique}
          />
        )}
        <MessageErreur
          message={messageErreur}
          style={{ width: "100%", marginTop: "20px" }}
        />
      </div>
    </div>
  );
}
