import React, { useState, useEffect, useContext } from "react";
import MonStyle from "./EditionRappel.module.css";
import SurfaceBloc from "../../../../../Design/Surfaces/SurfaceBloc";
import InputSwitch from "../../../../../Design/Formulaires/Switch";
import SmsIcon from "@material-ui/icons/Sms";
import EmailIcon from "@material-ui/icons/Email";
import { Divider } from "@material-ui/core";
import MessageAide from "../../../../../Design/Messages/MessageAide";
import BlocRappel from "./BlocRappel";
import { MessagesContext } from "../../../../../Data/MessagesContext";
import ObjetSms from "../../../../../Design/Objets/ObjetSms";
import AffichageConditionnel from "../../../../../Design/DataDisplay/AffichageConditionnel";
import { pluriel } from "../../../../../functions/Formatter";
import { AgendasContext } from "../../../../../Data/AgendasContext";
export default function EditionRappel({
  agenda_id,
  monRdv,
  onMailConfirmationChange,
  onTelephoneConfirmationChange,
  onClick,
  emailConfirmation,
  telephoneConfirmation,
  mode,
  isSuppression,
  sousTitre,
  titre,
  codeSuppression,
}) {
  //NES gestion du state
  const [isMail, setIsMail] = useState(false);
  const [isSms, setIsSms] = useState(false);
  const [numeroPortable, setNumeroPortable] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [listeSms, setListeSms] = useState([]);

  //NES gestion du contexte
  const { getRdvSms } = useContext(MessagesContext);
  const { agendaById } = useContext(AgendasContext);

  //USEEFFECT récupération des sms envoyés
  useEffect(() => {
    if (monRdv?._id) {
      getRdvSms(monRdv?._id).then((reponse) => {
        if (reponse.code === "OK") {
          setListeSms(reponse.data);
        }
      });
    }
    // eslint-disable-next-line
  }, [monRdv?._id]);

  //USEEFFECT
  useEffect(() => {
    //NES est ce que j'ai une adresse email?
    if (monRdv?.patient?.patient_email) {
      setEmail(monRdv?.patient?.patient_email);
      setIsMail(true);
    } else {
      setEmail("");
      setIsMail(false);
    }

    //NES est-ce que j'ai un numéro de téléphone portable?
    let liste = monRdv?.patient?.patient_contact?.telephones;
    let tempNumeroPortable = "";
    if (liste) {
      liste = liste.filter((item) => item.is_portable === true);

      if (liste.length >= 1) {
        tempNumeroPortable = liste[0].numero;
        setNumeroPortable(tempNumeroPortable);
        setIsSms(true);
      } else {
        setNumeroPortable("");
        setIsSms(false);
      }
    }

    //NES si le rdv est inférieur à la date du jour quand je le créé
    if (!isSuppression && !monRdv._id) {
      const aujourdhui = new Date();
      const dateRdv = new Date(monRdv.date_heure);
      if (dateRdv < aujourdhui) {
        setMessage(
          "La date du rendez vous est inférieure à la date et heure du jour, par défaut aucune confirmation n'est envoyée."
        );
        setIsSms(false);
        setIsMail(false);
      }
    }

    //NES est que j'ai coché le rappel dans l'agenda?
    let monAgenda = agendaById(agenda_id);
    if (monAgenda?.sms?.is_sms_rappel_rdv === false) {
      setIsSms(false);
    }
    if (monAgenda?.email?.is_email_rappel_rdv === false) {
      setIsMail(false);
    }

    // eslint-disable-next-line
  }, [monRdv]);

  //USEEFFECT
  useEffect(() => {
    if (codeSuppression) {
      setIsSms(true);
      setIsMail(true);
    }

    if (codeSuppression === "DELETE") {
      setIsSms(false);
      setIsMail(false);
    }
  }, [codeSuppression]);

  ///handleSwitchMail
  const handleSwitchMail = () => {
    let newValue = !isMail;
    setIsMail(newValue);
  };

  ///handleSwitchSms
  const handleSwitchSms = () => {
    let newValue = !isSms;
    setIsSms(newValue);
  };

  //USEEFFECT Remonté du mail si actif
  useEffect(() => {
    if (onMailConfirmationChange) {
      if (isMail) {
        onMailConfirmationChange(email);
      } else {
        onMailConfirmationChange("");
      }
    }

    // eslint-disable-next-line
  }, [isMail]);

  //USEEFFECT Remonté du téléphone portable si actif
  useEffect(() => {
    if (onTelephoneConfirmationChange) {
      if (isSms) {
        onTelephoneConfirmationChange(numeroPortable);
      } else {
        onTelephoneConfirmationChange("");
      }
    }

    // eslint-disable-next-line
  }, [isSms]);

  //NES mes useEffect ne se déclenchait pas car le composant n'était pas affiche du coup pour gérer mes règles 1x je fais 2 retours différents
  if (mode === "AFFICHAGE") {
    return (
      <BlocRappel
        onClick={onClick}
        emailConfirmation={emailConfirmation}
        telephoneConfirmation={telephoneConfirmation}
        badgeContent={listeSms.length}
      />
    );
  }

  return (
    <div className={MonStyle.EditionRappel}>
      <MessageAide
        message={message}
        disableClose
        style={{ marginBottom: "30px" }}
      />
      <h3>{titre}</h3>
      <p>{sousTitre}</p>

      <SurfaceBloc>
        <div className={MonStyle.Bloc}>
          <EmailIcon
            value={isMail}
            className="IconeObjet"
            style={{ color: isMail ? "#0FA600" : "#DDD" }}
          />
          <InputSwitch
            onClick={handleSwitchMail}
            label={email ? "Email de confirmation" : "Email non renseigné"}
            description={email}
            value={isMail}
            onChange={handleSwitchMail}
            style={{ margin: 0, width: "100%" }}
            disabled={email ? false : true}
          />
        </div>
        <Divider />
        <div className={MonStyle.Bloc}>
          <SmsIcon
            value={isMail}
            className="IconeObjet"
            style={{ color: isSms ? "#0FA600" : "#DDD" }}
          />
          <InputSwitch
            value={isSms}
            onClick={handleSwitchSms}
            label={
              numeroPortable
                ? "Sms de confirmation"
                : "Aucun numéro de portable"
            }
            description={numeroPortable}
            onChange={handleSwitchSms}
            style={{ margin: 0, width: "100%" }}
            disabled={numeroPortable ? false : true}
          />
        </div>
      </SurfaceBloc>

      <AffichageConditionnel open={!isSuppression}>
        <div className={MonStyle.SmsEnvoyes}>
          <h3>{pluriel(listeSms.length, "sms envoyé", "sms envoyés")}</h3>
          <div className={MonStyle.ListeSmsEnvoyes}>
            {listeSms.map((sms) => (
              <ObjetSms sms={sms} />
            ))}
          </div>
        </div>
      </AffichageConditionnel>
    </div>
  );
}
