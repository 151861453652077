import React, { useState } from "react";
import SurfacePopup from "../../Surfaces/SurfacePopUp";
import InputTexte from "../../Formulaires/InputTexte";
import BoutonAnnuler from "../../Boutons/BoutonAnnuler";
import BoutonActionPrincipale from "../../Boutons/BoutonActionPrincipale";

export default function PopupRenommerDocuments({
  nomDocument,
  open,
  onClose,
  onClickValider,
}) {
  //NES gestion du state
  const [nom, setNom] = useState(nomDocument);

  const handleChange = (event) => {
    let texte = event.target.value;
    //NES j'enlève les "/"
    texte = texte.replace("\\", "");
    texte = texte.replace("/", "");
    texte = texte.replace(":", "");
    texte = texte.replace("*", "");
    texte = texte.replace("?", "");
    texte = texte.replace(">", "");
    texte = texte.replace("<", "");

    setNom(texte);
  };

  return (
    <SurfacePopup
      open={open ? true : false}
      onClose={onClose}
      titre="Renommer le document"
      //style={{ width: "300px" }}
    >
      <div style={{ margin: "10px 0px" }}>
        <InputTexte
          value={nom}
          autoFocus
          onChange={handleChange}
          placeholder="Nom du document"
        />
      </div>
      <p className="SousTitre">
        Les caractères spéciaux :{" "}
        <span style={{ color: "black", fontWeight: "bolder" }}>
          \ / : * ? \ &lt; &gt;
        </span>{" "}
        <br />
        ne sont pas autorisés dans le nom du fichier
      </p>
      <BoutonActionPrincipale
        texte="Renommer"
        fullWidth
        style={{ margin: "15px 0px" }}
        taille="L"
        onClick={() => onClickValider(nom)}
        disabled={!nom}
      />
      <BoutonAnnuler fullWidth onClick={onClose} />
    </SurfacePopup>
  );
}
