import React from "react";
import MonStyle from "./SurfaceBloc.module.css";

export default function SurfaceBloc({
  illustration,
  couleur,
  contentWidth,
  onClick,
  style,
  isBouton,
  theme,
  id,
  className,
  ...props
}) {
  //NES si je ne souhaite pas que la largeur soit de 100%

  let StylePapier = {};

  switch (theme) {
    case "rond":
      StylePapier = {
        background: couleur === "gris" ? "#F6F6F6" : "white",
        boxShadow: " 0 1px 2px 0 rgba(0,0,0,0.15)",
        borderRadius: "50px ",
        padding: "0px",
        position: "relative",
        width: "100%",
        boxSizing: "border-box",
        ...style,
      };
      break;

    default:
      StylePapier = {
        background: couleur === "gris" ? "#F6F6F6" : "white",
        boxShadow: " 0 2px 2px 0 rgba(0,0,0,0.15)",
        borderRadius: "5px ",
        padding: "Opx",
        position: "relative",
        width: "100%",
        boxSizing: "border-box",
        height: "fit-content",
        ...style,
      };
      break;
  }

  return (
    <div
      id={id}
      style={StylePapier}
      onClick={onClick}
      className={isBouton ? MonStyle.SurfaceBlocButton : className}
    >
      {props.children}
    </div>
  );
}
