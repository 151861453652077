import React, { useEffect, useState } from "react";
import InputTelephone from "../Design/Formulaires/InputTelephone";
import BoutonAjoutBloc from "../Design/Boutons/BoutonAjoutBloc";

const modeleTelephone = {
  numero: "",
  type: "Téléphone",
  is_favori: false,
};

export default function ListeTelephones({
  ListeTelephones,
  onChange,
  onBlur,
  onDelete,
  autoAjout,
}) {
  //NES Gestion du state
  const [telephones, setTelephones] = useState([modeleTelephone]);

  useEffect(() => {
    if (ListeTelephones) {
      if (autoAjout && ListeTelephones.length === 0) {
        setTelephones([modeleTelephone]);
      } else {
        setTelephones(ListeTelephones);
      }
    }
    // eslint-disable-next-line
  }, [ListeTelephones]);

  const handleChange = (index, telephone) => {
    //NES je mets à jour le tableau
    let tempArray = [...telephones];
    tempArray.splice(index, 1, telephone);
    setTelephones(tempArray);
    if (onChange) {
      onChange(tempArray);
    }
  };

  //NES ajoute un numero
  const ajouterNumero = () => {
    let tempArray = [...telephones];
    tempArray.push(modeleTelephone);
    setTelephones(tempArray);
    if (onChange) {
      onChange(tempArray);
    }
  };

  //NES efface un numero
  const supprimerNumero = (index) => {
    let tempArray = [...telephones];
    tempArray.splice(index, 1);
    setTelephones(tempArray);
    if (onChange) {
      onChange(tempArray);
    }
    if (onDelete) {
      onDelete(tempArray);
    }
  };

  return (
    <div style={{ width: "100%" }}>
      {telephones.map((telephone, index) => (
        <div
          style={{
            width: "100%",
            position: "relative",
          }}
          key={index}
        >
          <InputTelephone
            objTelephone={telephone}
            id={index.toString()}
            key={index}
            onTelephoneChange={(telephone) => handleChange(index, telephone)}
            onBlur={onBlur}
            autoComplete="qsdjqzdlqjdiz"
            autoFocus={telephone.numero ? false : true}
            onClickSuppression={() => supprimerNumero(index)}
          />
        </div>
      ))}
      <div style={{ margin: "20px 0px" }}>
        <BoutonAjoutBloc
          texte="Ajouter un téléphone"
          fullWidth
          onClick={ajouterNumero}
        />
      </div>
    </div>
  );
}
