import React, { useContext, useEffect, useState } from "react";
import MonStyle from "./PageAdpxErreurs.module.css";
import MessageAucuneDonnee from "../../../Design/Messages/MessageAucuneDonnee";
import MessageErreur from "../../../Design/Messages/MessageErreur";
import LoaderHorizontal from "../../../Design/Progression/LoaderHorizontal";
import BoutonFiltreDate from "../../../Design/Recherche/Filtres/FiltreDate";
import { AdpxContext } from "../../../Data/AdpxContext";
import ObjetMessageErreur from "../Components/ObjetMessageErreur";
import { pluriel } from "../../../functions/Formatter";
import GraphiqueBarre from "../../../Design/Graphiques/GraphiqueBarre";
import {
  DateToString,
  getAllDateBetweenDates,
} from "../../../functions/FonctionsDateHeure";

export default function PageApdxErreurs() {
  let dateDuJour = new Date();
  let devdateDebut = new Date();
  devdateDebut.setMonth(devdateDebut.getMonth() - 1);

  //NES Gestion du contexte
  const { getMessagesErreurs } = useContext(AdpxContext);

  //NES Gestion du state
  const [dateDebut, setDateDebut] = useState(devdateDebut);
  const [dateFin, setDateFin] = useState(dateDuJour);
  const [liste, setListe] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [messageErreur, setMessageErreur] = useState();
  const [message, setMessage] = useState("");
  const [error, setError] = useState();

  const [labels, setLabels] = useState([]);
  const [valeurs, setValeurs] = useState([]);

  //USEEFFECT Liste
  useEffect(() => {
    console.log({ liste });

    if (liste.length > 0) {
      //NES je groupe le nombre d'erreur par jour
      let tempLabels = [];

      //NES je commence par crée les jours
      let listeDates = getAllDateBetweenDates(dateDebut, dateFin);
      let listeNbErreur = [];

      listeDates.forEach((date) => {
        let dateString = DateToString(date);
        tempLabels.push(dateString);
        //console.log(dateString);

        //NES pour chaque date je compte le nb d'erreurs

        let erreurs = liste.filter(
          (item) => DateToString(item.date) === dateString
        );

        listeNbErreur.push(erreurs.length);
      });

      setLabels(tempLabels);
      setValeurs(listeNbErreur);
    }
    // eslint-disable-next-line
  }, [liste]);

  //USEEFFECT
  useEffect(() => {
    getMessagesErreurs(dateDebut, dateFin).then((reponse) => {
      console.log({ reponse });
      setIsLoading(false);
      if (reponse.code === "OK") {
        setListe(reponse.data);
        if (reponse.data.length === 0) {
          setMessage("Aucun message d'erreur trouvé");
        } else {
          setMessage("");
        }
      } else {
        setMessageErreur(
          reponse.message
            ? reponse.message
            : "Erreur lors de la récupération des messages d'erreurs"
        );
        setError(reponse.error);
      }
    });
    // eslint-disable-next-line
  }, [dateDebut, dateFin]);

  ///handleGraphiqueClick
  const handleGraphiqueClick = (e) => {
    console.log(e);
  };

  return (
    <div className={MonStyle.PageApdxErreurs}>
      <h1>Messages erreurs</h1>
      <p className="SousTitre">
        {pluriel(liste.length, "message d'erreur", "messages d'erreur")}
      </p>
      {messageErreur ? (
        <MessageErreur message={messageErreur} error={error} taille="XL" />
      ) : (
        <main>
          <div className={MonStyle.Filtres}>
            <BoutonFiltreDate
              label="du : "
              value={dateDebut}
              onChange={(date) => setDateDebut(date)}
            />
            <BoutonFiltreDate
              label="au : "
              value={dateFin}
              onChange={(date) => setDateFin(date)}
            />
          </div>
          <div className={MonStyle.GraphiqueBarre}>
            <GraphiqueBarre
              valeurs={valeurs}
              labelsX={labels}
              onClick={handleGraphiqueClick}
            />
          </div>
          <LoaderHorizontal isLoading={isLoading} />
          <MessageAucuneDonnee
            message={message}
            conseil="Essayez de modifier les filtres"
          />
          <div>
            {liste.map((erreur) => (
              <div className={MonStyle.Ligne} key={erreur._id}>
                <ObjetMessageErreur messageErreur={erreur} />
              </div>
            ))}
          </div>
        </main>
      )}
    </div>
  );
}
