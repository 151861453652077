import React, { useState, useContext, useEffect } from "react";
import MuiAlert from "@material-ui/lab/Alert";
import Style from "./MessageErreur.module.css";
import { Collapse } from "@material-ui/core";
import BoutonRond from "../Boutons/BoutonRond";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import { AdpxContext } from "../../Data/AdpxContext";
import { getCodeMateriel } from "../../functions/Navigateur";
import IllustrationErreur from "../../Images/Illustrations/Erreur.png";
import BoutonActionPrincipale from "../Boutons/BoutonActionPrincipale";
import { AuthentificationContext } from "../../Data/AuthentificationContext";
import ObjetItem from "../Objets/ObjetItem";
import ErrorIcon from "@material-ui/icons/Error";
import BoutonTexte from "../Boutons/BoutonTexte";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function MessageErreur({
  message,
  error,
  severity,
  style,
  taille,
}) {
  //NES Gestion du contexte
  const { postMessagesErreurs } = useContext(AdpxContext);
  const authentification = useContext(AuthentificationContext);

  //POST je remonte le message d'erreur à l'API support
  useEffect(() => {
    if (message) {
      //NES beaucoup d'error sont en string si c'est le cas je créé un objet

      let objetError = error;
      if (typeof error === "string") {
        objetError = { error: error };
      }
      let data = {
        message: message,
        error: objetError,
        url: window.location.href,
        user_agent: window.navigator.userAgent,
        materiel: getCodeMateriel(),
      };

      postMessagesErreurs(data);
    }
    // eslint-disable-next-line
  }, [message]);

  //NES Gestion du state
  const [afficherError, setAfficherError] = useState(false);

  //NES si je n'ai pas de message je n'affiche rien
  if (!message) {
    return "";
  }

  if (message && taille === "XL") {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          ...style,
        }}
      >
        <img src={IllustrationErreur} alt="" />
        <div style={{ display: "flex", flexDirection: "column" }}>
          <h3>
            Oups ! <br />
            Il semblerait que cela soit hors service.
          </h3>{" "}
          <h4 style={{ color: "red", margin: "10px 0px" }}>{message}</h4>
          <p>
            Le support a été prévenu du problème.
            <br />
            Nous allons vite trouver une solution.
            <br />
            Merci pour votre patience.
          </p>
          <BoutonActionPrincipale
            texte="Se reconnecter"
            onClick={() => authentification.Deconnexion()}
            style={{ margin: "20px 0px" }}
          />
          <BoutonTexte
            texte={afficherError ? "Masquer l'erreur" : "Afficher l'erreur"}
            onClick={() => setAfficherError(!afficherError)}
          />
        </div>

        <Collapse in={afficherError} unmountOnExit style={{ maxWidth: "100%" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              marginTop: "40px",
              gap: "10px",
              backgroundColor: "#DDDDDD50",
              padding: "10px",
              boxSizing: "border-box",
            }}
          >
            <ErrorIcon />
            <ObjetItem
              principale={window.location.href}
              secondaire={error?.technical_stack}
              ligne3={error?.error}
            />
          </div>
        </Collapse>
      </div>
    );
  }

  if (message && taille === "L") {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          ...style,
        }}
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          <h3>
            Oups ! <br />
            Il semblerait que cela soit hors service.
          </h3>{" "}
          <h4 style={{ margin: "10px 0px" }}>{message}</h4>
          <p>
            Le support a été prévenu du problème.
            <br />
            Nous allons vite trouver une solution.
            <br />
            Merci pour votre patience.
          </p>
          <BoutonTexte
            texte={afficherError ? "Masquer l'erreur" : "Afficher l'erreur"}
            onClick={() => setAfficherError(!afficherError)}
          />
        </div>

        <Collapse in={afficherError} unmountOnExit style={{ maxWidth: "100%" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              marginTop: "40px",
              gap: "10px",
              backgroundColor: "#DDDDDD50",
              padding: "10px",
              boxSizing: "border-box",
            }}
          >
            <ErrorIcon />
            <ObjetItem
              principale={window.location.href}
              secondaire={error?.technical_stack}
              ligne3={error?.error}
            />
          </div>
        </Collapse>
      </div>
    );
  }

  if (message) {
    return (
      <div className={Style.MessageErreur} style={style}>
        <Alert
          severity={severity ? severity : "error"}
          action={
            error && (
              <BoutonRond
                couleurIcone="blanc"
                iconeComposant={
                  afficherError ? <ExpandLessIcon /> : <ExpandMoreIcon />
                }
                tooltip="Afficher l'erreur"
                onClick={() => setAfficherError(!afficherError)}
              />
            )
          }
        >
          {message}
          <Collapse
            in={afficherError}
            unmountOnExit
            style={{ boxSizing: "border-box" }}
          >
            <div
              className={Style.DetailError}
              onClick={() => setAfficherError(false)}
            >
              <div className={Style.MessageRemonteAuto}>
                Cette erreur nous est remonté automatiquement, nous allons la
                corriger le plus rapidement possible
              </div>
              <div className={Style.TexteError}>{error?.technical_stack}</div>
            </div>
          </Collapse>
        </Alert>
      </div>
    );
  }
}
