import React, { useContext, useState, useEffect } from "react";
//import Backdrop from "@material-ui/core/Backdrop";
import { makeStyles } from "@material-ui/core/styles";
import { CabinetsContext } from "../../../../Data/CabinetContext";
import ObjetCabinet from "../../../../Design/Objets/ObjetCabinet";
import MonStyle from "./AffichageChoixCabinet.module.css";
import LoaderHorizontal from "../../../../Design/Progression/LoaderHorizontal";
import MessageErreur from "../../../../Design/Messages/MessageErreur";
import { AuthentificationContext } from "../../../../Data/AuthentificationContext";
import Backdrop from "@mui/material/Backdrop";

export default function AffichageChoixCabinet({
  open,
  onClose,
  onCabinetSelet,
}) {
  //NES STYLE
  const useStyles = makeStyles((theme) => ({
    backdrop: {
      backgroundColor: "rgba(0, 0, 0, 0.8)",
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
  }));
  const classes = useStyles();

  //NES Gestion du contexte
  const authContext = useContext(AuthentificationContext);
  const { getCabinetByUtilisateur } = useContext(CabinetsContext);

  //NES Gestion du state
  const [listeCabinets, setListeCabinets] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [messageErreur, setMessageErreur] = useState();
  const [error, setError] = useState();

  //USEEFFECT Chargement des cabinets de l'utilisateur
  useEffect(() => {
    setIsLoading(true);
    getCabinetByUtilisateur()
      .then((reponse) => {
        setIsLoading(false);
        setListeCabinets(reponse);
      })
      .catch((error) => {
        setIsLoading(false);
        setMessageErreur("Erreur lors de la récupération des cabinets");
        setError(error);
      });
    // eslint-disable-next-line
  }, []);

  //NES quand je sélectionne un cabinet
  const handleSelectCabinet = (cabinet) => {
    if (onCabinetSelet) {
      onCabinetSelet(cabinet);
    }

    if (onClose) {
      onClose();
    }
  };

  return (
    <Backdrop open={open} className={classes.backdrop}>
      <div>
        <h1 style={{ color: "white", marginBottom: "10px" }}>
          Bonjour, {authContext.payload.prenom}
        </h1>
        <div>Sélectionnez le cabinet sur lequel vous travaillez</div>
        <LoaderHorizontal isLoading={isLoading} style={{ marginTop: "10px" }} />
        <MessageErreur message={messageErreur} error={error} />
        <div className={MonStyle.ListeCabinets}>
          {listeCabinets.map((cabinet) => (
            <div
              className={MonStyle.Cabinet}
              onClick={() => handleSelectCabinet(cabinet)}
              key={cabinet._id}
            >
              <ObjetCabinet cabinet={cabinet} />
            </div>
          ))}
        </div>
      </div>
    </Backdrop>
  );
}
