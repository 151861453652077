import React, { useContext, useState, useEffect } from "react";
import MonStyle from "./SuggestionAjouterUtilisateur.module.css";
import SurfacePapier from "../../../Design/Surfaces/SurfacePapier";
import BoutonActionSecondaire from "../../../Design/Boutons/BoutonActionSecondaire";
import IllustrationEquipe from "../../../Images/Illustrations/Equipe.png";
import SettingsIcon from "@material-ui/icons/Settings";
import { UtilisateursContext } from "../../../Data/UtilisateursContext";
import { AuthentificationContext } from "../../../Data/AuthentificationContext";

export default function SuggestionAjouterUtilisateur({ style }) {
  //NES Gestion du contexte
  const { getUtilisateurs } = useContext(UtilisateursContext);
  const auth = useContext(AuthentificationContext);

  //NES gestion du state
  const [afficher, setAfficher] = useState(false);

  //USEEFFECT
  useEffect(() => {
    //NES seulement pour les admins
    if (auth.payload.is_admin) {
      getUtilisateurs().then((reponse) => {
        //NES je ne vais afficher la suggestion que si il n'y a qu'un seul utilisateur
        if (reponse.code === "OK") {
          let utilisateurs = reponse.data;

          if (utilisateurs.length === 1) {
            setAfficher(true);
          } else {
            setAfficher(false);
          }
        }
      });
    }
    // eslint-disable-next-line
  }, []);

  if (!afficher) {
    return "";
  }

  return (
    <SurfacePapier style={style}>
      <div className={MonStyle.SuggestionAjouterUtilisateur}>
        <div className={MonStyle.Illustration}>
          <img
            src={IllustrationEquipe}
            alt="Illustration travail en équipe"
            className="Illustration"
          />
        </div>
        <div className={MonStyle.Textes}>
          <h4>Gestion des utilisateurs</h4>
          <h3>Ne travaillez pas tout seul !</h3>
          <p>
            Mais, vous savez, moi je ne crois pas qu’il y ait de bonne ou de
            mauvaise situation. Moi, si je devais résumer ma vie aujourd’hui
            avec vous, je dirais que c’est d’abord des rencontres, des gens qui
            m’ont tendu la main, peut-être à un moment où je ne pouvais pas, où
            j’étais seul chez moi.
          </p>
        </div>
        <div>
          <BoutonActionSecondaire
            texte="Accéder aux utiisateurs"
            taille="L"
            startIcon={<SettingsIcon />}
            link="/options/utilisateurs/"
          />
        </div>
      </div>
    </SurfacePapier>
  );
}
