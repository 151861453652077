import React, { useEffect, useState, useContext } from "react";
import BoutonActionSecondaire from "../../../../Design/Boutons/BoutonActionSecondaire";
import MonStyle from "./EditionCotation.module.css";
import RecherchePrestation from "./RecherchePrestation";
import CheckBox from "../../../../Design/Formulaires/CheckBox";
import MenuAction from "../../../../Design/Navigation/BoutonMenu";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import DeleteIcon from "@material-ui/icons/Delete";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ObjetPrix from "../../../../Design/Objets/ObjetPrix";
import { DateToString } from "../../../../functions/FonctionsDateHeure";
import ObjetPrestation from "../../../../Design/Objets/ObjetPrestation";
import { CotationsContext } from "../../../../Data/CotationsContext";
import MessageErreur from "../../../../Design/Messages/MessageErreur";
import BoutonMenu from "../../../../Design/Navigation/BoutonMenu";
import EuroSymbolIcon from "@material-ui/icons/EuroSymbol";
import EditionRemise from "./EditionRemise";

export default function CotationConsultation({
  onSelect,
  selected,
  cotation,
  consultation,
  onSupprimer,
}) {
  //NES GEstion du context
  const { patchCotation, deleteCotation } = useContext(CotationsContext);

  //NES Gestion du state
  const [isSelected, setIsSelected] = useState(false);
  const [openRecherche, setOpenRecherche] = useState(false);
  const [messageErreur, setMessageErreur] = useState();
  const [error, setError] = useState();
  const [afficherRemise, setAfficherRemise] = useState();
  const [afficherColonneRemise, setAfficherColonneRemise] = useState(true);

  //USEEFFECT pour afficher ou non la colonne remise
  useEffect(() => {
    let listePrestations = cotation.prestations;
    let isRemise = false;
    listePrestations.forEach((item) => {
      if (item.montant_remise || item.pourcentage_remise) {
        isRemise = true;
      }
    });
    setAfficherColonneRemise(isRemise);
  }, [cotation]);

  //USEEFFECT au montage pour cocher par défaut les cotations liées à la consultation
  useEffect(() => {
    if (cotation.consultation_id === consultation._id) {
      if (onSelect) {
        onSelect();
      }
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (selected !== undefined) {
      setIsSelected(selected);
    }
  }, [selected]);

  const ajouterPrestations = (listePrestations) => {
    let newDataPrestations = [];
    //NES je récupère toujours un tableau de prestations même si je n'en ai sélectionné un seul
    listePrestations.forEach((element) => {
      let data = {
        prestation_id: element._id,
        nom: element.nom,
        description: element.description,
        code_remboursement: element.code_remboursement,
        type_code_remboursement: element.type_code_remboursement,
        prix_vente_ttc_unitaire_brut: element.prix_vente_ttc,
        prix_vente_ht_unitaire_brut: element.prix_vente_ht,
        taux_tva: element.taux_tva,
        quantite: 1,
        montant_remise: 0.0,
        pourcentage_remise: 0.0,
        prestation_total_ht_net: element.prix_vente_ht,
        prestation_total_tva_net:
          element.prix_vente_ttc - element.prix_vente_ht,
        prestation_total_ttc_net: element.prix_vente_ttc,
        prestation_base_remboursement: 0.0,
        prestation_plafond_remboursement: 0.0,
        prestation_part_patient: element.prix_vente_ttc,
        prestation_part_securite_sociale: 0.0,
        prestation_part_complementaire: 0.0,
        prestation_part_surcomplementaire: 0.0,
      };
      newDataPrestations.push(data);
    });

    let tempArray = [...cotation.prestations];
    //NES j'ajoute les nouvelles prestations à celles existante
    tempArray = tempArray.concat(newDataPrestations);

    //PATCH données à patcher à l'API
    let newData = { prestations: tempArray };
    patchCotation(
      cotation.patient_id,
      cotation.consultation_id,
      cotation._id,
      newData
    )
      .then((reponse) => {
        if (reponse.code === "OK") {
        } else {
          setMessageErreur("Erreur lors de l'enregistrement");
        }
      })
      .catch((error) => {
        setMessageErreur("Erreur lors de l'enregistrement");
        setError(error);
      });

    setOpenRecherche(false);

    //NES On sélectionne par défaut la cotation que je viens de modifier
    if (onSelect && !selected) {
      onSelect();
    }
  };

  //DELETE
  const supprimerCotation = () => {
    deleteCotation(
      cotation.patient_id,
      cotation.consultation_id,
      cotation._id
    ).then((reponse) => {
      if (reponse.code === "OK") {
        if (onSupprimer) {
          onSupprimer();
        }
      }
    });
  };

  //NES Supprimer une prestation
  const handleSupprimer = (index) => {
    //NES attention on ne se base pas sur l'ID car souvent je vais devois mettre 2x la même prestation et je ne souhaite pas supprimer les 2
    let tempArray = [...cotation.prestations];
    tempArray.splice(index, 1);
    let newData = { prestations: tempArray };
    patchCotation(
      cotation.patient_id,
      cotation.consultation_id,
      cotation._id,
      newData
    )
      .then((reponse) => {
        if (reponse.code === "OK") {
        } else {
          setMessageErreur("Erreur lors de la suppression");
        }
      })
      .catch((error) => {
        setMessageErreur("Erreur lors de la suppression");
        setError(error);
      });
  };

  //NES afficher remise on stoke l'index de la ligne
  const handleAfficherRemise = (index) => {
    setAfficherRemise(index);
  };

  return (
    <div>
      <div className={selected ? MonStyle.CotationSelected : MonStyle.Cotation}>
        <div
          className={
            selected ? MonStyle.EnteteCotationSelected : MonStyle.EnteteCotation
          }
        >
          <div className={MonStyle.TitreConsultation} onClick={onSelect}>
            <CheckBox checked={isSelected} />
            <div>
              Consultation du{" "}
              {DateToString(cotation?.consultation?.date_consultation)}
            </div>
          </div>

          <BoutonMenu tooltip="Plus d'actions">
            <ListItem button onClick={() => setOpenRecherche(true)}>
              <AddCircleOutlineIcon className="IconeMenu" />
              <ListItemText primary="Ajouter une prestation" />
            </ListItem>
            <ListItem
              button
              style={{ color: "red" }}
              onClick={supprimerCotation}
            >
              <DeleteIcon className="IconeMenu" style={{ color: "red" }} />
              Supprimer la cotation
            </ListItem>
          </BoutonMenu>
        </div>
        <MessageErreur message={messageErreur} error={error} />
        {cotation?.prestations?.length === 0 ? (
          <div className={MonStyle.AucunePrestation}>
            <div className="TitreBloc">
              Aucune prestation n'est à facturer pour la consultation
            </div>
            <BoutonActionSecondaire
              texte="Ajouter une prestation"
              couleur="bleu"
              style={{ width: "240px", marginTop: "30px" }}
              onClick={() => setOpenRecherche(true)}
            />
          </div>
        ) : (
          <div className={MonStyle.ListePrestations}>
            <div className={MonStyle.EnteteColonnes}>
              <div className={MonStyle.ColonnePrestation}></div>

              {afficherColonneRemise && (
                <div className={MonStyle.ColonneMontant}>Prix brut</div>
              )}
              {afficherColonneRemise && (
                <div className={MonStyle.ColonneMontant}>Remise</div>
              )}

              <div className={MonStyle.ColonneMontant}>Montant</div>
            </div>

            {cotation?.prestations?.map((prestation, index) => (
              <div className={MonStyle.LignePrestation} key={index}>
                <div className={MonStyle.ColonnePrestation}>
                  <ObjetPrestation prestation={prestation} />
                </div>

                {afficherColonneRemise && (
                  <div className={MonStyle.ColonneMontant}>
                    <ObjetPrix prix={prestation.prix_vente_ttc_unitaire_brut} />
                  </div>
                )}

                {afficherColonneRemise && (
                  <div
                    className={MonStyle.ColonneRemise}
                    onClick={() => handleAfficherRemise(index)}
                  >
                    {prestation.pourcentage_remise > 0
                      ? prestation.pourcentage_remise + " %"
                      : ""}
                    {prestation.montant_remise > 0
                      ? prestation.montant_remise + " €"
                      : ""}
                  </div>
                )}

                <div className={MonStyle.ColonneMontant}>
                  <ObjetPrix prix={prestation.prestation_total_ttc_net} />
                </div>
                <MenuAction tooltip="Supprimer">
                  <List>
                    <ListItem
                      button
                      onClick={() => handleAfficherRemise(index)}
                    >
                      <EuroSymbolIcon className="IconeMenu" />
                      <ListItemText primary="Effectuer une remise" />
                    </ListItem>
                    <ListItem button style={{ color: "red" }}>
                      <DeleteIcon
                        className="IconeMenu"
                        style={{ color: "red" }}
                      />
                      <ListItemText
                        primary="Supprimer"
                        onClick={() => handleSupprimer(index)}
                      />
                    </ListItem>
                  </List>
                </MenuAction>
              </div>
            ))}

            <div className={MonStyle.TotalPrestations}>
              <div className={MonStyle.ColonnePrestation}></div>
              <div className={MonStyle.ColonneMontant}>
                {cotation?.part_patient?.montant_a_regler} €
              </div>
            </div>
          </div>
        )}

        <EditionRemise
          cotation={cotation}
          index={afficherRemise}
          onClose={() => setAfficherRemise()}
        />
        <RecherchePrestation
          open={openRecherche}
          onClose={() => setOpenRecherche(false)}
          onAjouterPrestations={ajouterPrestations}
        />
      </div>
    </div>
  );
}
