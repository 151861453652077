import React, { useState, useEffect } from "react";
import MonStyle from "./PageMotdepassePerdu.module.css";
//import SurfacePapier from "../../../Design/Surfaces/SurfacePapier";
//import BoutonRond from "../../../Design/Boutons/BoutonRond";
//import CloseIcon from "@material-ui/icons/Close";
//import Logo from "../../../Design/Logo";
import queryString from "query-string";
import ZoneConnexion from "../Components/ZoneConnexion";
//import DemanderAdresseEmail from "../../MotdepassePerdu/Components/DemanderAdresseEmail";
//import DemanderNouveauPassword from "../../MotdepassePerdu/Components/DemanderNouveauPassword";
import SurfacePage from "../../../Design/Surfaces/SurfacePage";
import InputEmail from "../../../Design/Formulaires/InputEmail";
import BoutonActionPrincipale from "../../../Design/Boutons/BoutonActionPrincipale";
import BoutonAnnuler from "../../../Design/Boutons/BoutonAnnuler";
import AffichageConditionnel from "../../../Design/DataDisplay/AffichageConditionnel";
import InputPassword from "../../../Design/Formulaires/InputPassword";
import IllustrationProbleme from "../../../Images/Illustrations/Probleme.png";
import IllustrationSucces from "../../../Images/Illustrations/Succes.png";

import MessageAide from "../../../Design/Messages/MessageAide";
import MessageErreur from "../../../Design/Messages/MessageErreur";
import { PATCH, POST } from "../../../functions/FonctionsApi";
import { useContext } from "react";
import { AuthentificationContext } from "../../../Data/AuthentificationContext";

export default function PageMotdepassePerdu({ match }) {
  //NES récupération des paramètres de l'URL
  let url = window.location.search;
  let params = queryString.parse(url);
  let HASH = params.hash;

  //USEEFFECT
  useEffect(() => {
    console.log({ HASH });
  }, [HASH]);

  //NES GEstion du contexte
  const auth = useContext(AuthentificationContext);

  //NES gestion du state
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");
  const [messageErreur, setMessageErreur] = useState("");
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isSucces, setIsSucces] = useState(false);

  ///Envoi de l'email de récupération du mot de passe
  const handleRecuperer = (e) => {
    e.preventDefault();

    const MonUrl = `${process.env.REACT_APP_URL_API_UTILISATEURS}/recoverpassword`;

    const data = {
      utilisateur: {
        email: email,
      },
    };
    setIsLoading(true);

    POST(MonUrl, auth.token, data).then((reponseApi) => {
      console.log(reponseApi);
      setIsLoading(false);
      if (reponseApi.code === "OK") {
        setMessage(reponseApi.data.message);
        setMessageErreur();
        setError();
      } else {
        setMessageErreur(reponseApi.message);
        setError(reponseApi);
      }
    });
  };

  ///Envoi du nouveau mot de passe
  const handleEnvoyerMotDePasse = (e) => {
    e.preventDefault();

    const MonUrl = `${process.env.REACT_APP_URL_API_UTILISATEURS}/recoverpassword`;

    const data = {
      hash: HASH,
      password: password,
    };
    setIsLoading(true);
    PATCH(MonUrl, "", data).then((reponse) => {
      setIsLoading(false);
      console.log(reponse);
      if (reponse.code === "OK") {
        setIsSucces(true);
      } else {
        setMessageErreur(reponse.message);
      }
    });
  };

  return (
    <div className={MonStyle.PageMotdepassePerdu}>
      <ZoneConnexion
        texteBoutonSecondaire="Connexion"
        lienBoutonSecondaire="/"
        hideMobile
      />

      <SurfacePage>
        <div className={MonStyle.Content}>
          <h1>Mot de passe perdu</h1>
          <p></p>

          <img
            src={isSucces ? IllustrationSucces : IllustrationProbleme}
            alt=""
            className={MonStyle.Illustration}
          />

          <AffichageConditionnel open={!HASH}>
            <form className={MonStyle.Formulaire} onSubmit={handleRecuperer}>
              <h3>Entrez votre email de connexion</h3>
              <InputEmail
                label="Email de connexion"
                placeholder="Entrez votre email de connexion"
                autoFocus
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <MessageAide
                message={message}
                disableClose
                style={{ marginTop: "20px" }}
              />
              <MessageErreur
                message={messageErreur}
                error={error}
                style={{ marginTop: "20px" }}
              />
              <BoutonActionPrincipale
                texte="Récupérer mon mot de passe"
                fullWidth
                style={{ margin: "40px 0px " }}
                taille="L"
                onClick={handleRecuperer}
                isLoading={isLoading}
                disabled={!email}
              />
              <BoutonAnnuler fullWidth link="/" />
            </form>
          </AffichageConditionnel>

          <AffichageConditionnel open={HASH && !isSucces}>
            <form
              className={MonStyle.Formulaire}
              onSubmit={handleEnvoyerMotDePasse}
            >
              <h3>Entrez votre nouveau mot de passe</h3>
              <InputPassword
                placeholder="Entrez votre nouveau mot de passe"
                afficherControle
                autoFocus
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />

              <MessageAide
                message={messageErreur}
                severity={"error"}
                style={{ marginTop: "20px" }}
              />
              <BoutonActionPrincipale
                texte="Modifier mon mot de passe"
                fullWidth
                style={{ margin: "40px 0px " }}
                taille="L"
                onClick={handleEnvoyerMotDePasse}
                disabled={!password}
                isLoading={isLoading}
              />
              <BoutonAnnuler fullWidth link="/" />
            </form>
          </AffichageConditionnel>
          <AffichageConditionnel
            open={isSucces}
            className={MonStyle.Formulaire}
          >
            <h3>Mot de passe modifié !</h3>

            <BoutonActionPrincipale
              texte="Se connecter"
              fullWidth
              taille="L"
              style={{ margin: "40px 0px " }}
              link="/"
            />
          </AffichageConditionnel>
        </div>
      </SurfacePage>
    </div>
  );
}
