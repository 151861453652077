import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import Popover from "@mui/material/Popover";
import BoutonTexte from "../../Design/Boutons/BoutonTexte";
import { withStyles } from "@material-ui/core/styles";
import FilterListIcon from "@material-ui/icons/FilterList";
import SurfaceBloc from "../Surfaces/SurfaceBloc";
import SvgIcon from "@material-ui/core/SvgIcon";

const ColorButton = withStyles({
  root: {
    color: (props) => props.monstyle.color,
    border: "1px solid #DDD",
    fontFamily: "Lato",
    textTransform: "none",
    fontSize: "16px",
    height: "40px",
    padding: "0px 20px",
    //backgroundColor: purple[500],
    backgroundImage: (props) => props.monstyle.backgroundImage,
    "&:hover": {
      backgroundColor: "#F6F6F6",
    },
  },
})(Button);

export default function BoutonFiltre({
  texte,
  name,
  id,
  style,
  startIcon,
  color,
  libelleFiltre,
  onEffacer,
  onClick,
  ...props
}) {
  //NES gestion dynamique du style
  const monTheme = {
    color: "#97989D",
    backgroundImage: "",
  };

  const monThemeFiltre = {
    backgroundImage: "linear-gradient(153deg, #205A71 5%, #4393AA 100%)",
    color: "white",
  };

  //NES Gestion du state
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [monStyle, setMonStyle] = useState(monTheme);

  //NES POPOVER

  const boutonActionClick = (event) => {
    setAnchorEl(event.currentTarget);
    if (onClick) {
      onClick();
    }
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  //USEEFFECT changement du style d'affichage
  useEffect(() => {
    if (libelleFiltre && libelleFiltre !== "") {
      setMonStyle(monThemeFiltre);
    } else {
      setMonStyle(monTheme);
    }
    // eslint-disable-next-line
  }, [libelleFiltre]);

  return (
    <div style={style}>
      <ColorButton
        name={name}
        id={id}
        startIcon={
          startIcon ? (
            <SvgIcon
              component={startIcon}
              style={{ color: color ? color : "" }}
            />
          ) : (
            <FilterListIcon />
          )
        }
        fullWidth
        onClick={boutonActionClick}
        monstyle={{ ...monStyle }}
      >
        {libelleFiltre ? libelleFiltre : texte}
      </ColorButton>
      {props.children && (
        <Popover
          open={anchorEl ? true : false}
          anchorEl={anchorEl}
          onClose={handleClose}
          onClick={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "center",
            horizontal: "center",
          }}
        >
          <SurfaceBloc style={{ width: "fit-content", padding: "10px 20px" }}>
            <div>{props.children}</div>
            <BoutonTexte
              texte={libelleFiltre ? "Effacer" : "Annuler"}
              fullWidth
              style={{ marginBottom: "10px" }}
              onClick={onEffacer}
            />
          </SurfaceBloc>
        </Popover>
      )}
    </div>
  );
}
