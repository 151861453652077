import React, { useState, useContext, useEffect } from "react";
import MonStyle from "./PageLaboratoires.module.css";
import SurfacePapier from "../../../../Design/Surfaces/SurfacePapier";
import MessageErreur from "../../../../Design/Messages/MessageErreur";
import EntetePageOptions from "../../Components/EntetePageOptions";
import { ProduitsContext } from "../../../../Data/ProduitsContext";
import ObjetLaboratoire from "../../../../Design/Objets/ObjetLaboratoire";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import MenuAction from "../../../../Design/Navigation/BoutonMenu";
import { Link } from "react-router-dom";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import BoutonConfirmerSuppression from "../../../../Design/Boutons/BoutonConfirmerSuppression";
import { AuthentificationContext } from "../../../../Data/AuthentificationContext";
import ObjetItem from "../../../../Design/Objets/ObjetItem";
import { TagProduit } from "../../../../Design/DataDisplay/Tags";
import EditionCodePraticien from "../../../Patients/Components/AffichageConseil/EditionCodePraticien";

export default function PageLaboratoire() {
  //NES Gestion du contexte
  const { laboratoires, getLaboratoires, deleteLaboratoire } =
    useContext(ProduitsContext);
  const auth = useContext(AuthentificationContext);

  //NES Gestion du state
  const [erreur, setErreur] = useState("");
  const [suppressionId, setSuppressionId] = useState("");
  const [error, setError] = useState("");
  const [editionCodeLabo, setEditionCodeLabo] = useState();

  //USEEFFECT
  useEffect(() => {
    getLaboratoires()
      .then((reponse) => {
        if (reponse.code !== "OK") {
          setErreur(reponse.erreur.message);
        }
      })
      .catch((error) => {
        setErreur("Erreur lors de la récupération des laboratoires");
        setError(error);
      });
    // eslint-disable-next-line
  }, []);

  //NES Suppression d'un laboratoire
  const handleSupprimer = (laboratoire_id) => {
    console.log("handleSupprimer", laboratoire_id);
    deleteLaboratoire(laboratoire_id)
      .then((reponse) => {
        if (reponse.code !== "OK") {
          setErreur(reponse.erreur.message);
        }
      })
      .catch((error) =>
        setErreur("Erreur lors de la suppression du laboratoire")
      );
  };

  return (
    <SurfacePapier>
      <EntetePageOptions
        titre="Laboratoires"
        sousTitre={
          laboratoires.length > 0
            ? laboratoires.length + " laboratoires"
            : laboratoires.length + " laboratoire"
        }
        linkAjouter="/options/laboratoires/new"
      />
      <MessageErreur message={erreur} error={error} />
      <EditionCodePraticien
        laboratoire={editionCodeLabo}
        onClose={() => setEditionCodeLabo()}
      />
      <List>
        {laboratoires.map((laboratoire) => (
          <div key={laboratoire._id} className="ListeLigne">
            <div className={MonStyle.ColonneLaboratoire}>
              <ObjetLaboratoire laboratoire={laboratoire} />{" "}
              <TagProduit
                prefixe="Générique Sinobi"
                hide={
                  auth.payload.owned_by_organisation ===
                  laboratoire.owned_by_organisation
                }
              />
            </div>
            <div className={MonStyle.ColonneCode}>
              <ObjetItem
                principale={laboratoire?.code_praticien}
                secondaire="code praticien"
                onClick={() => setEditionCodeLabo(laboratoire)}
              />
            </div>
            <BoutonConfirmerSuppression
              style={{
                position: "absolute",
                right: "0px",
                zIndex: "3",
                backgroundColor: "white",
              }}
              visible={laboratoire._id === suppressionId}
              onSupprimer={() => handleSupprimer(laboratoire._id)}
              onAnnuler={() => setSuppressionId("")}
            />

            <MenuAction
              tooltip="Modifier,supprimer"
              disabled={
                auth.payload.owned_by_organisation !==
                laboratoire.owned_by_organisation
              }
            >
              <ListItem
                button
                component={Link}
                to={"/options/laboratoires/" + laboratoire._id}
              >
                <EditIcon className="IconeMenu" />
                <ListItemText primary="Modifier" />
              </ListItem>
              <ListItem
                button
                style={{ color: "red" }}
                onClick={() => setSuppressionId(laboratoire._id)}
              >
                <DeleteIcon className="IconeMenu" style={{ color: "red" }} />
                Supprimer
              </ListItem>
            </MenuAction>
          </div>
        ))}
      </List>
    </SurfacePapier>
  );
}
