import React, { useEffect, useState } from "react";
import MonStyle from "./ZoneRecherche.module.css";
import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@material-ui/icons/Clear";
import BoutonRond from "../../../Design/Boutons/BoutonRond";
import InputBase from "@material-ui/core/InputBase";
import Select from "@material-ui/core/Select";
import Divider from "@material-ui/core/Divider";
import MenuItem from "@material-ui/core/MenuItem";

import { withStyles } from "@material-ui/core/styles";

const CssTextField = withStyles({
  root: {
    "& .MuiInputBase-input": {
      fontSize: "20px",
    },
  },
})(InputBase);

const StyledInput = withStyles({
  root: {
    color: "black",
    border: "none",
  },
  input: {
    fontSize: "15px",
    textAlign: "center",
    "&:focus": {
      backgroundColor: "white",
    },
  },
})(InputBase);

export default function ZoneRecherche({
  value,
  onTexteChange,
  onPressEnter,
  onSelectTypeDonnee,
  onDelete,
  texteUrl,
}) {
  //NES gestion du state
  const [isFocus, setIsFocus] = useState(true);
  const [dataRecherche, setDataRecherche] = useState("nom");
  const [texte, setTexte] = useState("");

  //NES handleTexteChange
  const handleTexteChange = (event) => {
    let texteRecherche = event.target.value;
    setTexte(texteRecherche);
    //NES je swith le type de données selon le début de la rechercher
    if (texteRecherche.length >= 2) {
      let strDebut = texteRecherche.substr(0, 2);
      if (strDebut === "06" || strDebut === "07") {
        setDataRecherche("telephone");
        if (onSelectTypeDonnee) {
          onSelectTypeDonnee("telephone");
        }
      }
    }
    if (texteRecherche.length >= 3) {
      let isSlash = texteRecherche.indexOf("/");
      if (isSlash >= 0) {
        setDataRecherche("date_de_naissance");
        if (onSelectTypeDonnee) {
          onSelectTypeDonnee("date_de_naissance");
        }
      }
    }
    if (onTexteChange) {
      onTexteChange(event);
    }
  };

  //NES gestion des refs
  const inputRef = React.createRef();

  //NES Effacer la recherche
  const effacerRecherche = () => {
    //NES effacer
    setTexte("");
    //NES placer le focus dans le champs de recherche
    inputRef.current.focus();
  };

  //NES Changer la data recherchée
  const handleDataChange = (event) => {
    setDataRecherche(event.target.value);
    if (onSelectTypeDonnee) {
      onSelectTypeDonnee(event.target.value);
    }
  };

  //NES Lancer la recherche avec ENTER
  const rechercher = () => {
    if (onPressEnter) {
      onPressEnter();
    }
  };

  useEffect(() => {
    if (texteUrl) {
      setTexte(texteUrl);
    }
  }, [texteUrl]);

  return (
    <div
      className={isFocus ? MonStyle.ZoneRechercheFocus : MonStyle.ZoneRecherche}
    >
      <div className={MonStyle.PartieGauche}>
        <SearchIcon style={{ color: "#208ab0", width: "30px" }} />
        <CssTextField
          onKeyPress={() => (window.event.keyCode === 13 ? rechercher() : "")}
          id="ChampPrincipalDeRecherche"
          placeholder="Rechercher un patient"
          name="Rechercher un patient"
          value={texte}
          onChange={handleTexteChange}
          autoFocus
          inputRef={inputRef}
          onFocus={() => setIsFocus(true)}
          onBlur={() => setIsFocus(false)}
          style={{
            width: "100%",
            margin: "0px 10px",
          }}
        />
        {value && (
          <BoutonRond
            iconeComposant={<ClearIcon />}
            tooltip="Effacer la recherche"
            onClick={effacerRecherche}
            id="bouton_effacer_recherche"
          />
        )}
      </div>

      <div className={MonStyle.PartieDroite}>
        <Divider
          orientation="vertical"
          style={{ height: "30px", margin: "15px 0px" }}
        />
        <Select
          variant="outlined"
          input={<StyledInput />}
          style={{ width: "100%", margin: "0px 10px" }}
          value={dataRecherche}
          onChange={handleDataChange}
          id="select_type_recherche"
        >
          <MenuItem value={"nom"}>Nom & prénom</MenuItem>
          <MenuItem value={"telephone"}>Téléphone</MenuItem>
          <MenuItem value={"adresse"}>Adresse</MenuItem>
          <MenuItem value={"date_de_naissance"}>Date de naissance</MenuItem>
          <MenuItem value={"numero_secu"}>N° de sécu. sociale</MenuItem>
        </Select>
      </div>
    </div>
  );
}
