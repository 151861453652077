import React, { useContext, useEffect, useState } from "react";
import MonStyle from "./AfficherRdv.module.css";
import { AgendasContext } from "../../../../../Data/AgendasContext";
import { timeToMinutes } from "../../../../../functions/FonctionsDateHeure";
//import Popover from '@mui/material/Popover';
import Popover from "@mui/material/Popover";

import EditionRdv from "../RendezVous/EditionRdv";
import { isMobile } from "../../../../../functions/Navigateur";
import { capitalize } from "../../../../../functions/Formatter";
import { getFontColor } from "../../../../../functions/FonctionsCouleurs";

export default function AfficherRdv({ rdv, agenda_id, hauteurHeure }) {
  //NES gestion du contexte
  const { getDureeConsultation, tailleRdvDefaut, calculPositionTop } =
    useContext(AgendasContext);

  //NES gestion du state
  const [informationSecondaire, setInformationSecondaire] = useState("");
  const [top, setTop] = useState();
  const [hauteur, setHauteur] = useState(0);

  //NES POPOVER
  const [anchorEl, setAnchorEl] = useState(null);
  const afficherRdv = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    let hauteurCalcule = calculHauteur();
    setHauteur(hauteurCalcule);
    let topCalcule = calculPositionTop(agenda_id, rdv.date_heure, hauteurHeure);
    setTop(topCalcule);
    // eslint-disable-next-line
  }, [hauteurHeure, rdv, agenda_id]);

  //NES Fonction calculant la hauteur à partir de la durée
  const calculHauteur = () => {
    if (rdv.duree) {
      let nbMinutes = timeToMinutes(rdv.duree);
      let hauteur = (nbMinutes * hauteurHeure) / 60;
      hauteur = hauteur - 1;
      return hauteur;
    } else {
      let dureeParDefaut = getDureeConsultation(agenda_id);
      let nbMinutesDefaut = timeToMinutes(dureeParDefaut);
      let hauteurDefaut = (nbMinutesDefaut * hauteurHeure) / 60;
      return hauteurDefaut;
    }
  };

  //NES fonction pour la ligne secondaire du rdv
  const constructionLigneSecondaire = () => {
    let informationLigne2 = "";

    let heure = new Date(rdv.date_heure).toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    });

    informationLigne2 = heure;
    setInformationSecondaire(informationLigne2);

    if (rdv.type_rendez_vous) {
      switch (rdv.type_rendez_vous.type_code) {
        case "domicile":
          //NES en cas de visite à domicile on affiche l'adresse
          informationLigne2 = rdv.patient.adresse
            ? rdv.patient.adresse.rue +
              " - " +
              rdv.patient.adresse.ville.toUpperCase()
            : "";
          break;
        default:
          //NES si il y a un commentaire j'affiche le commentaire sinon l'heure
          informationLigne2 = heure;
          break;
      }
    }
  };

  useEffect(() => {
    constructionLigneSecondaire();
    // eslint-disable-next-line
  }, [tailleRdvDefaut, rdv]);

  const calculFontColor = () => {
    let color = getFontColor(rdv?.type_rendez_vous?.couleur);
    if (rdv.annulation_rdv) {
      color = "#97989D";
    }
    return color;
  };

  if (!top) {
    return "";
  }

  return (
    <div>
      <div
        id={rdv.patient.patient_nom + "_" + rdv.patient.patient_prenom}
        style={{
          position: "absolute",
          top: top,
          height: hauteur,
          minHeight: "20px",
          backgroundColor: rdv.type_rendez_vous
            ? rdv.type_rendez_vous.couleur
            : "white",
          left: (rdv.position - 1) * rdv.coefWidth * 85 + "%",
          width: rdv.coefWidth * 85 + "%",
          color: calculFontColor(),
        }}
        className={
          rdv.annulation_rdv ? MonStyle.AfficherRdvAnnule : MonStyle.AfficherRdv
        }
        onClick={afficherRdv}
      >
        <div className={MonStyle.NomPrenom}>
          <div className={MonStyle.Nom}>
            {rdv.patient.patient_nom.toUpperCase()}
          </div>
          <div className={MonStyle.Prenom}>
            {capitalize(rdv.patient.patient_prenom)}
          </div>
        </div>

        <div
          style={{
            color: "inherit",
            fontSize: "12px",
            marginTop: "2px",
            opacity: 0.6,
          }}
        >
          <span>{informationSecondaire}</span>
          <div
            style={{
              color: "inherit",
              opacity: 0.8,
              fontSize: "12px",
            }}
          >
            {rdv.commentaire}
          </div>
        </div>
      </div>
      <Popover
        open={anchorEl ? true : false}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: isMobile() ? "bottom" : "top",
          horizontal: "center",
        }}
      >
        <EditionRdv agenda_id={agenda_id} onClose={handleClose} rdv={rdv} />
      </Popover>
    </div>
  );
}
