import React, { useEffect, useState } from "react";
import MonStyle from "./DayPicker.module.css";

export default function DayPicker({ joursSelectionnes, onChange }) {
  const jours = [
    { nom: "L", numero: 1 },
    { nom: "M", numero: 2 },
    { nom: "M", numero: 3 },
    { nom: "J", numero: 4 },
    { nom: "V", numero: 5 },
    { nom: "S", numero: 6 },
    { nom: "D", numero: 0 },
  ];

  //NES Gestion du state
  const [listeSelectionne, setListeSelectionne] = useState([]);

  //NES gestion du clic
  const handleClick = (jour) => {
    let tempArray = [...listeSelectionne];
    let index = tempArray.findIndex((item) => item === jour);
    if (index > -1) {
      tempArray.splice(index, 1);
    } else {
      tempArray.push(jour);
    }
    setListeSelectionne(tempArray);
    if (onChange) {
      onChange(tempArray);
    }
  };

  useEffect(() => {
    if (joursSelectionnes) {
      setListeSelectionne(joursSelectionnes);
    }
  }, [joursSelectionnes]);

  return (
    <div className={MonStyle.DayPicker}>
      {jours.map((jour) => (
        <div
          onClick={() => handleClick(jour.numero)}
          className={
            listeSelectionne.includes(jour.numero)
              ? MonStyle.JourSelectionne
              : MonStyle.Jour
          }
          key={jour.numero}
        >
          {jour.nom}
        </div>
      ))}
    </div>
  );
}
