import React, { useState, useEffect, useContext, Fragment } from "react";
import EditableChip from "../../../../Design/DataDisplay/EditableChip";
import InputBase from "@material-ui/core/InputBase";
import { withStyles } from "@material-ui/core/styles";
import { couleurAction } from "../../../../functions/FonctionsCouleurs";
import { ConsultationsContext } from "../../../../Data/ConsultationsContext";
import AffichageConditionnel from "../../../../Design/DataDisplay/AffichageConditionnel";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import HistoryIcon from "@mui/icons-material/History";
import SurfaceBloc from "../../../../Design/Surfaces/SurfaceBloc";

const StyledInput = withStyles({
  root: {
    backgroundColor: "#FFFFFF00",
    border: "0px solid black",
    borderRadius: "16px",
    padding: "0px",
    fontFamily: "Lato",
    color: couleurAction(),
    fontSize: "16px",
    margin: "0px",
    maxWidth: "100%",
    display: "flex",
    height: "fit-content",
    minWidth: "100px",
    overflowWrap: "normal",
    overflow: "auto",
    whiteSpace: "normal",
    boxSizing: "border-box",
    flex: 1,
    "& .MuiInputBase-input::placeholder": {
      color: "black",
      opacity: 0.5,
    },
  },
  input: {
    minWidth: "90px",
    padding: "0px",
    margin: 0,
    minHeight: "24px",
    paddingTop: "4px",
    paddingLeft: "3px",
  },
})(InputBase);

export default function ListeMotifsConsultation({
  values,
  onChange,
  disableSuggestions,
}) {
  //NES gestion du contexte
  const { getMotifsConsultationsUsuels } = useContext(ConsultationsContext);

  //NES gestion du state
  const [liste, setListe] = useState([]);
  const [newLabel, setNewLabel] = useState("");
  const [haveFocus, setHaveFocus] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [afficherSuggestions, setAfficherSuggestions] = useState(false);

  //USEEFFECT
  useEffect(() => {
    let actif = true;
    getMotifsConsultationsUsuels().then((reponse) => {
      if (actif) setSuggestions(reponse);
    });
    return () => (actif = false);
    // eslint-disable-next-line
  }, []);

  //USEEFFECT
  useEffect(() => {
    if (values) {
      setListe(values);
    }
  }, [values]);

  //USEEFFECT
  useEffect(() => {
    //NES pour avoir un delai
    if (!disableSuggestions) {
      setTimeout(() => {
        setAfficherSuggestions(haveFocus);
      }, 200);
    }
    // eslint-disable-next-line
  }, [haveFocus]);

  ///handleDelete
  const handleDelete = (index) => {
    let tempArray = [...liste];
    tempArray.splice(index, 1);
    setListe(tempArray);
    //NES je remonte l'état
    if (onChange) {
      onChange(tempArray);
    }
  };

  ///handleChangeChip
  const handleChangeChip = (label, index) => {
    let tempArray = [...liste];
    let tempItem = tempArray[index];
    tempItem = { ...tempItem, libelle: label };
    tempArray.splice(index, 1, tempItem);
    setListe(tempArray);
    //NES je remonte l'état
    if (onChange) {
      onChange(tempArray);
    }
  };

  ///handleKeyDown
  const handleKeyDown = (key) => {
    if (key.code === "Enter") {
      ajouterLabel();
    }
  };

  ///handleBlur sur l'ajout
  const handleBlur = (e) => {
    setHaveFocus(false);
    ajouterLabel();
  };

  ///Ajouter une valeur
  const ajouterLabel = () => {
    let tempArray = [...liste];
    if (newLabel !== "") {
      let newItem = { libelle: newLabel };
      tempArray.push(newItem);
      setListe(tempArray);
      setNewLabel("");
      //NES je remonte l'état
      if (onChange) {
        onChange(tempArray);
      }
    }
  };

  ///handleChangeInput
  const handleChangeInput = (e) => {
    let newValue = e.target.value;
    newValue = newValue.replace(/\n/g, "");
    setNewLabel(newValue);
  };

  ///handleSelect
  const handleSelect = (item) => {
    let tempArray = [...liste];
    if (item) {
      let newItem = item;
      tempArray.push(newItem);
      setListe(tempArray);
      setNewLabel("");
      //NES je remonte l'état
      if (onChange) {
        onChange(tempArray);
      }
    }
  };

  return (
    <Fragment>
      <div
        style={{
          border: haveFocus ? "2px solid #208AB0" : "2px solid #208AB000",
          display: "flex",
          flexDirection: "row",
          gap: "6px",
          flexWrap: "wrap",
          padding: "10px",
          borderRadius: "4px",
          backgroundColor: "#F6F6F6",
        }}
      >
        {liste.map((item, index) => (
          <EditableChip
            key={index}
            label={item.libelle}
            onDelete={() => handleDelete(index)}
            onBlur={(label) => handleChangeChip(label, index)}
          />
        ))}
        <div style={{ border: "0px solid red", minWidth: "100px" }}>
          <div style={{ height: 0, overflow: "hidden" }}>
            <EditableChip label={newLabel} />
          </div>
          <StyledInput
            value={newLabel}
            onChange={handleChangeInput}
            multiline
            placeholder="Ajouter un motif"
            onKeyDown={handleKeyDown}
            onBlur={handleBlur}
            onFocus={() => setHaveFocus(true)}
            id="input_motif"
          />
        </div>
      </div>
      <AffichageConditionnel open={afficherSuggestions}>
        <SurfaceBloc>
          <MenuList>
            {suggestions.map((item, index) => (
              <MenuItem onClick={() => handleSelect(item)} key={index}>
                <HistoryIcon className="IconeMenu" />
                <div
                  style={{
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    color: "#666",
                  }}
                >
                  {" "}
                  {item.libelle}
                </div>
              </MenuItem>
            ))}
          </MenuList>
        </SurfaceBloc>
      </AffichageConditionnel>
    </Fragment>
  );
}
