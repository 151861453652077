import React, { useEffect, useState } from "react";
import InputTexte from "./InputTexte";
import {
  formatterOnlyChiffres,
  formatterDecimal,
} from "../../functions/Formatter";

export default function InputNombres({
  value,
  label,
  nbChiffresError,
  onChange,
  isDecimal,
  style,
  disabled,
  endAdornment,
  isErreur,
  ...props
}) {
  const [texte, setTexte] = useState("");
  const [erreur, setErreur] = useState("");

  useEffect(() => {
    if (value !== undefined) {
      setTexte(value);
    }
  }, [value]);

  const handleChange = (event) => {
    let monTexte = "";
    monTexte = event.target.value;

    //NES je formatte pour éviter les lettres et caractères bizarres
    if (isDecimal) {
      console.log("isDecimal");
      monTexte = formatterDecimal(monTexte);
    } else {
      monTexte = formatterOnlyChiffres(monTexte);
    }
    //NES Mise à jour du contexte
    setTexte(monTexte);
    if (onChange) {
      event.target.value = monTexte;
      onChange(event);
    }

    //NES gestion de l'erreur en nombre de chiffres
    if (nbChiffresError && monTexte.length > nbChiffresError) {
      setErreur(`Le ${label} est limité à ${nbChiffresError} chiffres`);
    } else {
      setErreur("");
    }
  };

  return (
    <InputTexte
      disabled={disabled}
      value={texte}
      label={erreur ? erreur : label}
      onChange={handleChange}
      isErreur={erreur || isErreur}
      style={style}
      endAdornment={endAdornment}
      {...props}
    />
  );
}
