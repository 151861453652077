import React, { useContext } from "react";
import MonStyle from "./BarreMenuAgenda.module.css";
import AffichageDates from "../AffichageDates/AffichageDates";
import BoutonRond from "../../../../../Design/Boutons/BoutonRond";
import TuneIcon from "@material-ui/icons/Tune";
import { AgendasContext } from "../../../../../Data/AgendasContext";
import BoutonActionSecondaire from "../../../../../Design/Boutons/BoutonActionSecondaire";
import BoutonsAffichage from "../BoutonsAffichage";

export default function BarreMenuAgenda({ agenda_id, style }) {
  const { datesSemaine, selectedDate, setSelectedDate, calculJoursAffiches } =
    useContext(AgendasContext);

  const handleNext = () => {
    let premierJour = new Date(datesSemaine[0]);
    premierJour.setDate(premierJour.getDate() + 7);
    //NES je charge les dates de la semaine correspondante
    calculJoursAffiches(premierJour);
  };

  const handlePrevious = () => {
    //NES quel est le 1er jour de la semaine courante?
    let premierJour = new Date(datesSemaine[0]);
    premierJour.setDate(premierJour.getDate() - 7);
    //NES je charge les dates de la semaine correspondante
    calculJoursAffiches(premierJour);
  };

  const handleAujoudhui = () => {
    let aujourdhui = new Date();
    setSelectedDate(aujourdhui);
  };

  return (
    <div className={MonStyle.BarreMenuAgenda} style={style}>
      <div className={MonStyle.AffichageDates}>
        <div className="HideMobile">
          <BoutonActionSecondaire
            couleur="gris"
            texte="Aujourd'hui"
            onClick={handleAujoudhui}
            style={{ marginRight: "20px" }}
          />
        </div>

        <AffichageDates
          datesSemaine={datesSemaine}
          onNext={handleNext}
          onPrevious={handlePrevious}
          dateSelected={selectedDate}
        />
      </div>
      <div className={MonStyle.BoutonsActions}>
        <BoutonsAffichage style={{ marginRight: "20px", height: "40px" }} />
        <BoutonRond
          iconeComposant={<TuneIcon />}
          tooltip="Personnaliser l'affichage"
          style={{ marginRight: "0px" }}
          link={"/agendas/" + agenda_id + "/edition/"}
          disabled={agenda_id === "all"}
          couleurIcone="noir"
        />
      </div>
    </div>
  );
}
