import React, { useEffect, useState, useContext } from "react";
import BoutonRond from "../../../Design/Boutons/BoutonRond";
import MonStyle from "./PageDemande.module.css";
import PageNouvelleDemande from "./PageNouvelleDemande";
import DeleteIcon from "@material-ui/icons/Delete";
import BoutonConfirmerSuppression from "../../../Design/Boutons/BoutonConfirmerSuppression";
import { SupportContext } from "../../../Data/SupportContext";
import PopupRenommerDemande from "../Components/PopupRenommerDemande/PopupRenommerDemande";
import EditIcon from "@material-ui/icons/Edit";
import MessageErreur from "../../../Design/Messages/MessageErreur";
import { useHistory } from "react-router-dom";
import ProgressionDemande from "../Components/ProgressionDemande/ProgressionDemande";
import AffichageMessagesDemande from "../Components/MessagesDemande/AffichageMessagesDemande";
import EditionMessage from "../Components/MessagesDemande/EditionMessage";
import SelectEtatDemande from "../Components/EtatDemande/SelectEtatDemande";
import { AuthentificationContext } from "../../../Data/AuthentificationContext";
import MessageAucuneDonnee from "../../../Design/Messages/MessageAucuneDonnee";
import EvalutionCorrection from "../../ADPX/Components/EvalutionCorrection";

export default function PageDemande({ demande_id, style, onClose }) {
  //NES gestion du routage
  const history = useHistory();

  //NES gestion du contexte
  const {
    demandes,
    libelleTypeDemande,
    libellePrioriteDemande,
    patchDemandes,
    deleteDemandes,
    getDemandes,
  } = useContext(SupportContext);
  const auth = useContext(AuthentificationContext);

  //NES gestion du state
  const [demande, setDemande] = useState({});
  const [afficherPopupRenommer, setAfficherPopupRenommer] = useState(false);
  const [afficherSuppression, setAfficherSuppression] = useState(false);
  const [messageErreur, setMessageErreur] = useState();
  const [error, setError] = useState();

  //USEEFFECT récupération des données de la demande
  useEffect(() => {
    console.log({ demande_id });

    if (demande_id !== "new" && demande_id) {
      if (demandes.length === 0) {
        getDemandes();
      }

      let data = demandes.find((item) => item._id === demande_id);
      if (data) {
        setDemande(data);
      }
    }
    // eslint-disable-next-line
  }, [demande_id, demandes]);

  //NES Nouvelle demande
  if (demande_id === "new") {
    return <PageNouvelleDemande />;
  }

  //PATCH demande
  const enregistrer = (key, value) => {
    let newData = { ...demande, [key]: value };

    patchDemandes(newData)
      .then((reponse) => {
        if (reponse.code !== "OK") {
          setMessageErreur("Erreur lors de la modification");
          setError(reponse.error);
        }
      })
      .catch((error) => {
        setMessageErreur("Erreur lors de la modification");
        setError(error);
      });
  };

  //DELETE demande
  const supprimer = () => {
    console.log("Supprimer");
    deleteDemandes(demande._id)
      .then((reponse) => {
        console.log(reponse);
        if (reponse.code === "OK") {
          history.push("/support/");
        } else {
          setError(reponse.error);
          setMessageErreur(
            reponse.message ? reponse.message : "Erreur lors de la suppression"
          );
        }
      })
      .catch((error) => {
        setMessageErreur("Erreur lors de la suppression");
        setError(error);
      });
  };

  if (!demande_id) {
    return (
      <div
        style={{
          height: "80%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <MessageAucuneDonnee
          message="Aucune demande sélectionnée"
          hideIllustration
        />
      </div>
    );
  }

  return (
    <div className={MonStyle.PageDemande} style={{ style }}>
      <div className={MonStyle.Entete} style={{ alignItems: "flex-start" }}>
        <div className={MonStyle.TitreEtBoutons}>
          <h2>
            {demande.objet}
            <p className="SousTitre">
              {libelleTypeDemande[demande.type]} -{" "}
              {libellePrioriteDemande[demande.priorite]}
            </p>
            <SelectEtatDemande
              demande={demande}
              style={{ marginTop: "10px" }}
            />
          </h2>

          {auth.payload.owned_by_organisation ===
            demande.owned_by_organisation && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "10px",
                alignItems: "center",
              }}
            >
              <BoutonRond
                iconeComposant={<EditIcon />}
                couleur="gris"
                couleurIcone="noir"
                tooltip="Modifier le titre de la demande"
                onClick={() => setAfficherPopupRenommer(true)}
              />
              <BoutonRond
                iconeComposant={<DeleteIcon />}
                couleur="gris"
                couleurIcone="rouge"
                tooltip="Supprimer la demande"
                onClick={() => setAfficherSuppression(true)}
              />
            </div>
          )}
          {onClose && <BoutonRond tooltip="Fermer" onClick={onClose} />}
        </div>
        <BoutonConfirmerSuppression
          visible={afficherSuppression}
          variant="bloc"
          texte="Voulez-vous supprimer cette demande?"
          style={{ margin: "20px 0px" }}
          onAnnuler={() => setAfficherSuppression(false)}
          onSupprimer={supprimer}
          fullWidth
        />
        <ProgressionDemande demande={demande} />
      </div>

      <PopupRenommerDemande
        objetDemande={demande.objet}
        open={afficherPopupRenommer}
        onClose={() => setAfficherPopupRenommer(false)}
        onEnregistrer={(value) => enregistrer("objet", value)}
      />
      <MessageErreur
        message={messageErreur}
        error={error}
        style={{ margin: "20px 0px" }}
      />

      <AffichageMessagesDemande
        demande_id={demande_id}
        style={{ paddingBottom: "10px" }}
      />
      <EvalutionCorrection demande={demande} />
      <div className={MonStyle.EditionDemande}>
        <EditionMessage demande_id={demande._id} />
      </div>
    </div>
  );
}
