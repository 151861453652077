import React from "react";
import BoutonActionPrincipale from "../Boutons/BoutonActionPrincipale";
import BoutonActionSecondaire from "../Boutons/BoutonActionSecondaire";
import Alert from "@material-ui/lab/Alert";
import Collapse from "@material-ui/core/Collapse";

export default function MessageOuiNon({
  message,
  open,
  style,
  onClickOui,
  onClickNon,
}) {
  return (
    <Collapse in={open}>
      <Alert
        style={style}
        severity="info"
        action={
          <div style={{ display: "flex", flexDirection: "row" }}>
            <BoutonActionPrincipale
              texte="Oui"
              style={{ marginRight: "10px" }}
              couleur="bleu"
              taille="S"
              onClick={onClickOui}
            />
            <BoutonActionSecondaire
              texte="Non"
              couleur="bleu"
              taille="S"
              onClick={onClickNon}
            />
          </div>
        }
      >
        {message}
      </Alert>
    </Collapse>
  );
}
