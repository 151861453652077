export function couleurPrincipale() {
  if (process.env.REACT_APP_NOM === "SINOBI") {
    return "#D2215F";
  } else {
    return "#0FA600";
  }
}

export function couleurAction() {
  if (process.env.REACT_APP_NOM === "SINOBI") {
    return "#D2215F";
  } else {
    return "#208AB0";
  }
}

//NES gestion de la couleur du type de contact
export function getColorByTypeContact(type) {
  if (type) {
    switch (type.toUpperCase()) {
      case "MEDECIN":
        return "#107C82";
      case "INFIRMIERE":
        return "#CE1F9F";
      case "PHARMACIE":
        return "#0FA600";
      default:
        return "#205A71";
    }
  } else {
    return "#205A71";
  }
}

export function getFontColor(backgroundColor) {
  let fontColor = "black";

  let couleusFoncees = [
    //ANCIENNES COULEURS RDV
    // "#D3E8F0",
    //"#CFEECC",
    "#F44336",
    "#205A71",
    "#F79860",
    "#0FA600",
    "#E9587F",
    "#0091BA",
    "#B370E1",
    //NES Ligne 1
    "#AD1357",
    "#F4511E",
    "#E4C442",
    "#0A8043",
    "#1F5A71",
    "#8E24AA",
    //NES Ligne2
    "#D2215F",
    "#EF6C00",
    "#C0CA33",
    "#009688",
    "#7886CB",
    "#795548",
    //NES Ligne3
    "#D50001",
    "#F09300",
    "#7CB342",
    "#049BE5",
    "#B29DDB",
    "#616161",
    //NES Ligne4
    "#E67B73",
    "#F5BF25",
    "#10A600",
    "#4284F4",
    "#B370E1",
    "#A79B8D",
  ];

  if (couleusFoncees.includes(backgroundColor)) {
    fontColor = "white";
  }

  return fontColor;
}
