import React, { useContext, useState, useEffect } from "react";
import BoutonFiltre from "../BoutonFiltre";
import { ConsultationsContext } from "../../../Data/ConsultationsContext";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";

export default function FiltreTypeConsultation({ style, onChange }) {
  //NES Gestion du contexte
  const { typesConsultations, getTypesConsultations } =
    useContext(ConsultationsContext);

  //NES gestion du state
  const [filtre, setFiltre] = useState({});

  //NES Chargement des types de consultations
  useEffect(() => {
    // eslint-disable-next-line
  }, []);

  //NES quand je clique sur un type de consultation
  const handleClicTypeConsultation = (type) => {
    let monFiltre = {};
    if (type._id === filtre._id) {
      //NES c'est le même donc j'enlève le filtre
      monFiltre = {};
    } else {
      monFiltre = type;
    }
    setFiltre(monFiltre);
    if (onChange) {
      onChange(monFiltre);
    }
  };

  //NES Quand je clique sur effacer
  const handleEffacer = () => {
    setFiltre({});
    if (onChange) {
      onChange({});
    }
  };

  return (
    <BoutonFiltre
      texte="Type de consultation"
      libelleFiltre={filtre.libelle}
      onEffacer={handleEffacer}
      style={style}
      onClick={getTypesConsultations}
    >
      <List>
        {typesConsultations.map((type) => (
          <ListItem
            button
            disableGutters
            divider
            key={type._id}
            selected={filtre._id === type._id}
            //onClick={() => setConsultationSelectionne(consultation._id)}
            onClick={() => handleClicTypeConsultation(type)}
          >
            <div
              style={{
                height: "30px",
                borderLeft: "2px solid " + type.couleur,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                paddingLeft: "15px",
              }}
            >
              {type.libelle}
            </div>
          </ListItem>
        ))}
      </List>
    </BoutonFiltre>
  );
}
