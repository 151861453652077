import React, { useContext, useEffect, useState } from "react";
import InputSwitch from "../../../../Design/Formulaires/Switch";
import InputSelect from "../../../../Design/Formulaires/InputSelect";
import { AgendasContext } from "../../../../Data/AgendasContext";
import ColorPicker from "../../../../Design/Pickers/ColorPicker";
import AffichageConditionnel from "../../../../Design/DataDisplay/AffichageConditionnel";

export default function AgendaBlocExterne({ agenda, onChange }) {
  //NES gestion du contexte
  const { getAcademiesVacances } = useContext(AgendasContext);

  //NES gestion du state
  const [academies, setAcademies] = useState();
  const [data, setData] = useState({
    vacances_scolaire_academie: "",
    vacances_scolaire_couleur: "#10A600",
    sinobi_intersaisons_is_affiche: false,
    sinobi_intersaisons_couleur: "#F09300",
  });

  useEffect(() => {
    if (agenda.donnees_externes) {
      setData(agenda.donnees_externes);
    }
  }, [agenda]);

  useEffect(() => {
    getAcademiesVacances().then((liste) => {
      setAcademies(liste);
    });
    // eslint-disable-next-line
  }, []);

  const handleChange = (key, value) => {
    let newBlocExterne = { ...data, [key]: value };
    setData(newBlocExterne);
    if (onChange) {
      let newdata = { ...agenda, donnees_externes: newBlocExterne };
      onChange(newdata);
    }
  };

  //! DEBUG USEEFFECT
  useEffect(() => {
    console.log({ data });
  }, [data]);

  return (
    <div>
      <section>
        <h3>Vacances scolaires</h3>
        <InputSelect
          value={data.vacances_scolaire_academie}
          options={academies}
          enabledAucuneValeur
          onChange={(e) =>
            handleChange("vacances_scolaire_academie", e.target.value)
          }
          label="Sélectionnez l'académie"
        />
        <AffichageConditionnel open={data.vacances_scolaire_academie}>
          <ColorPicker
            couleurSelectionne={data.vacances_scolaire_couleur}
            onCouleurChange={(couleur) =>
              handleChange("vacances_scolaire_couleur", couleur)
            }
          />
        </AffichageConditionnel>
      </section>
      <section>
        <h3>Inter-saisons</h3>
        <InputSwitch
          value={data.sinobi_intersaisons_is_affiche}
          label="Afficher  les intersaisons chinoises"
          description="9 jours avant et après le début de la saison"
          onChange={(value) =>
            handleChange("sinobi_intersaisons_is_affiche", value)
          }
        />
        <AffichageConditionnel open={data.sinobi_intersaisons_is_affiche}>
          <ColorPicker
            couleurSelectionne={data.sinobi_intersaisons_couleur}
            onCouleurChange={(couleur) =>
              handleChange("sinobi_intersaisons_couleur", couleur)
            }
          />
        </AffichageConditionnel>
      </section>
    </div>
  );
}
