import React, { useContext, useEffect, useState } from "react";
import MessageErreur from "../../../Design/Messages/MessageErreur";
import LigneDemande from "./LigneDemande";
import { AdpxContext } from "../../../Data/AdpxContext";
import { pluriel } from "../../../functions/Formatter";

export default function ListeDemandesEnRetard({ style, onClickDemande }) {
  //NES gestion du contexte
  const { getAdpxDemandesEnretard, jours } = useContext(AdpxContext);

  //NES gestion du state
  const [liste, setListe] = useState([]);
  const [messageErreur, setMessageErreur] = useState();
  const [error, setError] = useState();

  useEffect(() => {
    getAdpxDemandesEnretard().then((reponse) => {
      if (reponse.code === "OK") {
        let tempData = reponse.data;
        //NES Enlever les demandes CORRIGES
        tempData = tempData.filter((item) => item.etat !== "CORRIGE");

        setListe(tempData);
      } else {
        setError(reponse.error);
        setMessageErreur(reponse.messageErreur);
      }
    });
    // eslint-disable-next-line
  }, [jours]);

  return (
    <div style={style}>
      <h3 style={{ color: "red" }}>
        Demandes avec date d'intervention dépassée
      </h3>
      <p className="SousTitre">
        {pluriel(liste.length, "demande", "demandes", "F")}
      </p>
      <div style={{ marginTop: "10px" }}>
        <MessageErreur message={messageErreur} error={error} />
        {liste.map((demande) => (
          <LigneDemande
            demande={demande}
            onClickDemande={onClickDemande}
            afficherDate
          />
        ))}
      </div>
    </div>
  );
}
