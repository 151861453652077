import React, { useState } from "react";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import { useEffect } from "react";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

export default function LoaderGlobal({ isLoading }) {
  //NES GEstion des styles
  const classes = useStyles();

  //NES Gestion du state
  const [open, setOpen] = useState(false);

  //USEEFFECT
  useEffect(() => {
    setOpen(isLoading);
  }, [isLoading]);

  return (
    <Backdrop className={classes.backdrop} open={open}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
}
