import React, { useContext } from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import Style from "./MenuUtilisateur.module.css";
import { AuthentificationContext } from "../../../Data/AuthentificationContext";
import { Link } from "react-router-dom";
import SecurityIcon from "@material-ui/icons/Security";
import CreditCardIcon from "@material-ui/icons/CreditCard";
import EmojiEventsIcon from "@material-ui/icons/EmojiEvents";
import {
  EtiquetteAdmin,
  EtiquettePartenaire,
} from "../../../Design/Objets/ObjetUtilisateur";
import { Fragment } from "react";
import { POST } from "../../../functions/FonctionsApi";
import SurfacePapier from "../../../Design/Surfaces/SurfacePapier";
import SettingsIcon from "@material-ui/icons/Settings";
import BoutonActionSecondaire from "../../../Design/Boutons/BoutonActionSecondaire";
import BoutonTexte from "../../../Design/Boutons/BoutonTexte";
import EditionAvatar from "../../../Pages/MonCompte/Components/EditionAvatar";

export default function MenuUtilisateur({ onClick }) {
  //NES Contexte d'authentification
  const authContext = useContext(AuthentificationContext);

  //NES déconnexion
  const deconnexion = async () => {
    //NES Ajouter l'appel à l'API
    const MonUrl = `${process.env.REACT_APP_URL_API_UTILISATEURS}/authentification/deconnexion/`;
    POST(MonUrl, authContext.token);

    //NES vider le contexte
    authContext.Deconnexion();
  };

  return (
    <SurfacePapier
      //onClick={onClick}
      style={{
        // padding: "20px",
        display: "flex",
        flexDirection: "column",
        gap: "5px",
        boxSizing: "border-box",
      }}
    >
      <div className={Style.InfoUtilisateur}>
        <EditionAvatar />
        <div className="Titre2" style={{ marginTop: "10px" }}>
          {authContext.payload.nom} {authContext.payload.prenom}
        </div>
        <div className="SousTitre">{authContext.payload.specialite}</div>
      </div>

      <div onClick={onClick} className={Style.Menu}>
        <ListItem button component={Link} to="/moncompte">
          <AccountCircleIcon className="IconeMenu" />
          <ListItemText primary="Mon compte" />
        </ListItem>

        <Divider />
        <ListItem
          button
          component={Link}
          to="/options"
          disabled={authContext.payload?.is_admin ? false : true}
        >
          <SettingsIcon className="IconeMenu" />
          <ListItemText
            primary="Options et paramètres"
            secondary="Utilisateurs, cabinets..."
          />
          <EtiquetteAdmin />
        </ListItem>
        <Divider />

        <ListItem
          button
          component={Link}
          to="/facturation"
          disabled={authContext.payload?.is_admin ? false : true}
        >
          <CreditCardIcon className="IconeMenu" />
          <ListItemText
            primary="Informations de paiement"
            secondary="Carte bancaire, factures"
          />
          <EtiquetteAdmin />
        </ListItem>
        {/****** PARRAINAGE **********/}

        {(authContext.payload?.code_role === "PARTENAIRE" ||
          authContext.payload?.code_role === "ADPX") && (
          <Fragment>
            <Divider />
            <ListItem
              button
              component={Link}
              to="/parrainage"
              // disabled={authContext.payload?.is_admin ? false : true}
            >
              <EmojiEventsIcon className="IconeMenu" />
              <ListItemText
                primary="Parrainage"
                secondary="Inviter un nouveau cabinet"
              />
              <EtiquettePartenaire />
            </ListItem>
          </Fragment>
        )}

        <Divider />

        <ListItem button component={Link} to="/caissesecurise">
          <SecurityIcon className="IconeMenu" />
          <ListItemText primary="Caisse Sécurisé" />
        </ListItem>

        <Divider />

        <BoutonActionSecondaire
          fullWidth
          texte="Déconnexion"
          startIcon={<ExitToAppIcon />}
          onClick={deconnexion}
          link="/"
          taille="L"
          style={{ margin: "20px 0px" }}
        />

        <div className={Style.BoutonsLegaux}>
          <BoutonTexte
            texte="Conditions générales"
            taille="S"
            couleur="gris"
            link="/conditionsgenerales"
          />
          <BoutonTexte
            texte="A propos"
            taille="S"
            couleur="gris"
            link="/Apropos"
          />
          <BoutonTexte texte="RGPD" taille="S" couleur="gris" link="/rgpd" />
        </div>
      </div>
    </SurfacePapier>
  );
}
