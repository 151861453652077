import React, { useContext, useEffect } from "react";
import ObjetContact from "../../../../Design/Objets/ObjetContact";
import { ContactsContext } from "../../../../Data/ContactsContext";
import { IconeMedecin } from "../../../../Design/Icones/IconesContacts";
import AppTooltip from "../../../../Design/DataDisplay/ToolTip";
import BoutonRond from "../../../../Design/Boutons/BoutonRond";
import { capitalize } from "../../../../functions/Formatter";

export default function AffichageMedecinTraitant({
  patient_id,
  taille,
  style,
}) {
  //NES gestion du contexte
  const { medecinTraitant, deleteMedecinTraitant, getContacts } =
    useContext(ContactsContext);

  //USEEFFECT
  useEffect(() => {
    if (patient_id) {
      getContacts(patient_id).then((reponse) => {});
    }
    // eslint-disable-next-line
  }, [patient_id]);

  //NES je n'affiche rien si je n'ai pas de médecin traitant
  if (!medecinTraitant) {
    return "";
  }

  //NES supprimer le médecin traitant
  const supprimerMedecinTraitant = () => {
    deleteMedecinTraitant(patient_id);
  };

  //NES retour de l'objet en taille S
  if (taille === "S") {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          ...style,
        }}
      >
        <AppTooltip texte="Médecin traitant">
          <div
            style={{
              border: "1px solid #107C82",
              backgroundColor: "#107C8220",
              borderRadius: "20px",
              width: "fit-content",
              height: "20px",
              position: "relative",
              padding: "0px 10px 0px 30px",
              color: "#107C82",
            }}
          >
            <div style={{ position: "absolute", bottom: "-8px", left: "0px" }}>
              <IconeMedecin taille="30px" />
            </div>
            {"Dr " +
              medecinTraitant.nom.toUpperCase() +
              " " +
              capitalize(medecinTraitant.prenom)}
          </div>
        </AppTooltip>
      </div>
    );
  }

  return (
    <div
      style={{
        border: "0px solid #107C82",
        backgroundColor: "#107C8210",
        borderRadius: "10px",
        width: "100%",
        boxSizing: "border-box",
        //height: "20px",
        position: "relative",
        padding: "10px",
        color: "#107C82",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <ObjetContact contact={medecinTraitant} taille="L" />
      <BoutonRond
        tooltip="Supprimer le médecin traitant"
        couleurIcone="noir"
        onClick={supprimerMedecinTraitant}
        id="bt_supprimer_medecin_traitant"
      />
    </div>
  );
}
