import React from "react";
import MonStyle from "./SurfacePopUp.module.css";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import SurfacePapier from "./SurfacePapier";
import BoutonRond from "../../Design/Boutons/BoutonRond";
import CloseIcon from "@material-ui/icons/Close";

export default function SurfacePopUp({
  open,
  titre,
  sousTitre,
  style,
  className,
  couleur,
  disableBackgroundClose,
  onClose,
  disablePadding,
  disableCloseIcone,
  ...props
}) {
  return (
    <Modal
      disableEnforceFocus
      className={MonStyle.Modal}
      open={open}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}
    >
      <Fade in={open}>
        <SurfacePapier
          couleur={couleur}
          contentWidth
          disablePadding={disablePadding}
          style={style}
          {...props}
        >
          <div className={MonStyle.Content}>
            <div
              className={
                disablePadding ? MonStyle.TitreDisabledPadding : MonStyle.Titre
              }
            >
              <h5>
                {titre}
                <p className="SousTitre">{sousTitre}</p>
              </h5>
              {!disableCloseIcone && (
                <BoutonRond
                  iconeComposant={<CloseIcon />}
                  tooltip="Fermer"
                  couleurIcone="noir"
                  onClick={onClose}
                  id="bouton_close_popup"
                />
              )}
            </div>

            {props.children}
          </div>
        </SurfacePapier>
      </Fade>
    </Modal>
  );
}
