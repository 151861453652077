import React from "react";
import MonStyle from "./PageAllergies.module.css";
import SurfaceZoneSecondaire from "../../../../Design/Surfaces/SurfaceZoneSecondaire";
import ListeAntecedentsAllergies from "./Components/ListeAntecedentsAllergies";
import EditionHabitus from "./Components/EditionHabitus";

export default function PageAllergies({ match }) {
  //NES récupération de l'ID dans l'URL
  let idUrl = match ? match.params.id : "";

  return (
    <div className={MonStyle.PageAllergies} id="top_allergies">
      <div className={MonStyle.AllergiesEtAntecedentsEtHabitus}>
        <div className={MonStyle.AllergiesEtAntecedents}>
          <ListeAntecedentsAllergies
            titre="Allergies"
            codeTypeAntecedent="allergie"
            patient_id={idUrl}
            titreAjouter="Ajouter une allergie"
            titreEdition="Allergie"
            id="id_allergies"
            className={MonStyle.Antecedents}
          />
          <ListeAntecedentsAllergies
            titre="Antécédents patient"
            codeTypeAntecedent="antecedent_patient"
            patient_id={idUrl}
            titreAjouter="Ajouter un antécédent"
            titreEdition="Antécédent patient"
            id="id_antecedents"
            className={MonStyle.Antecedents}
          />
          <ListeAntecedentsAllergies
            titre="Antécédents chirurgicaux"
            codeTypeAntecedent="antecedent_chirurgical"
            patient_id={idUrl}
            titreAjouter="Ajouter un antécédent chirurgical"
            titreEdition="Antécédent chirurgical"
            className={MonStyle.Antecedents}
            id="id_antecedents_chirurgical"
          />
          <ListeAntecedentsAllergies
            titre="Antécédents familiaux"
            codeTypeAntecedent="antecedent_familial"
            patient_id={idUrl}
            titreAjouter="Ajouter un antécédent familial"
            titreEdition="Antécédent familial"
            className={MonStyle.Antecedents}
            id="id_antecedents_familial"
          />
          <ListeAntecedentsAllergies
            titre="Traitements en cours"
            codeTypeAntecedent="traitement_en_cours"
            patient_id={idUrl}
            titreAjouter="Ajouter un traitement"
            titreEdition="Traitement en cours"
            className={MonStyle.Antecedents}
            id={"id_traitement"}
          />
        </div>
        <SurfaceZoneSecondaire>
          <h5
            style={{
              marginBottom: "30px",
              textAlign: "center",
              width: "100%",
            }}
          >
            Habitus
          </h5>
          <div className={MonStyle.ListeHabitus}>
            <EditionHabitus
              code_type_antecedent={"habitus_tabac"}
              patient_id={idUrl}
            />
            <EditionHabitus
              code_type_antecedent={"habitus_alcool"}
              patient_id={idUrl}
            />
            <EditionHabitus
              code_type_antecedent={"habitus_drogue"}
              patient_id={idUrl}
            />
            <EditionHabitus
              code_type_antecedent={"habitus_sport"}
              patient_id={idUrl}
            />{" "}
            <EditionHabitus
              code_type_antecedent={"habitus_travail"}
              patient_id={idUrl}
            />
          </div>
        </SurfaceZoneSecondaire>
      </div>
    </div>
  );
}
