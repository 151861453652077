import React, { useContext, useEffect } from "react";
import MonStyle from "./PageSuiviReglements.module.css";
import SurfacePage from "../../Design/Surfaces/SurfacePage";
import { Route, Switch } from "react-router-dom";
import PageJournalDesReglements from "./Pages/JournalDesReglements/PageJournalDesReglements";
import { Redirect } from "react-router-dom";
import PageCotationsARegler from "./Pages/CotationsAFacturer/PageCotationsAFacturer";
import { isMobile } from "../../functions/Navigateur";
import PageMenuSuiviReglements from "./Pages/MenuSuiviReglements/PageMenuSuiviReglements";
import PageJournalDesFactures from "./Pages/JournalDesFactures/PageJournalDesFactures";
import PageFacturesARegler from "./Pages/FacturesARegler/PageFacturesARegler";
import { CotationsContext } from "../../Data/CotationsContext";

export default function PageSuiviReglement({ match }) {
  //NES Gestion du contexte
  const { getFacturesARegler, getCotationsAFacturer } =
    useContext(CotationsContext);

  //USEEFFECT Chargement des factures à régler
  //NES je le fais dans cette page pour pouvoir afficher le nombre de facture dans le menu, sans avoir à cliquer dans le menu pour "voir"
  useEffect(() => {
    getFacturesARegler();
    getCotationsAFacturer();
    // eslint-disable-next-line
  }, []);

  return (
    <SurfacePage couleur="gris">
      <div className={MonStyle.Content}>
        <div className={MonStyle.PartieGauche}>
          {/* Astuce pour ne pas afficher la partie droite ou gauche sur mobile  */}
          <Switch>
            {!isMobile() ? (
              <Route
                path="/suivireglements/"
                component={PageMenuSuiviReglements}
              />
            ) : (
              <Route
                exact
                path="/suivireglements/"
                component={PageMenuSuiviReglements}
              />
            )}
          </Switch>
        </div>

        <div className={MonStyle.PartieDroite}>
          <Switch>
            {!isMobile() && (
              <Redirect
                exact
                from="/suivireglements"
                to="/suivireglements/journalreglements"
              />
            )}
            <Route
              exact
              path="/suivireglements/cotationsafacturer"
              component={PageCotationsARegler}
            />
            <Route
              exact
              path="/suivireglements/journalreglements"
              component={PageJournalDesReglements}
            />
            <Route
              path="/suivireglements/journalfactures"
              component={PageJournalDesFactures}
            />
            <Route
              path="/suivireglements/journalfactures"
              component={PageJournalDesFactures}
            />
            <Route
              path="/suivireglements/facturesaregler"
              component={PageFacturesARegler}
            />
          </Switch>
        </div>
      </div>
    </SurfacePage>
  );
}
