import React from "react";

export default function ColonneHeures({ hauteurCase, cases }) {
  const afficherHeurePile = (heure) => {
    let tempArray = heure.split(":");

    if (tempArray[1] === "00") {
      return heure;
    } else {
      return "";
    }
  };

  return (
    <div
      style={{
        fontSize: "14px",
        color: "#97989D",
        marginRight: "0px",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-end",
        justifyContent: "flex-end",
        paddingRight: "10px",
      }}
    >
      {cases.map((heure, i) => (
        <div
          style={{
            //height: i === listeHeure.length - 1 ? "20px" : tailleHeure + "px",
            height: hauteurCase,
          }}
          key={heure}
        >
          {afficherHeurePile(heure)}
        </div>
      ))}
    </div>
  );
}
