import React, { useContext, useState } from "react";
import SurfacePopUp from "../../../Design/Surfaces/SurfacePopUp";
import InputEmail from "../../../Design/Formulaires/InputEmail";
import InputPassword from "../../../Design/Formulaires/InputPassword";
import MessageErreur from "../../../Design/Messages/MessageErreur";
import BoutonActionPrincipale from "../../../Design/Boutons/BoutonActionPrincipale";
import BoutonAnnuler from "../../../Design/Boutons/BoutonAnnuler";
import { AdpxContext } from "../../../Data/AdpxContext";
import { AuthentificationContext } from "../../../Data/AuthentificationContext";
import { useHistory } from "react-router-dom";

export default function PopupConnexionAdpx({ open, onClose, organisation }) {
  console.log(organisation);
  //NES Gestion du routage pour faire des redirections
  let history = useHistory();
  //NES gestion du contexte
  const { postAdpxAuthentification } = useContext(AdpxContext);
  const auth = useContext(AuthentificationContext);

  //NES Gestion du state
  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  if (!organisation) {
    return "";
  }

  ///handleConnexion
  const handleConnexion = () => {
    setIsLoading(true);
    postAdpxAuthentification(login, password, organisation._id).then(
      (reponse) => {
        console.log(reponse);
        setIsLoading(false);
        if (reponse.code === "OK") {
          console.log(reponse);

          auth
            .Connexion(reponse.connexion.token, reponse.connexion)
            .then((reponse) => {
              //NES rediriger sur la page d'acceuil
              history.push("/");
            });
        } else {
          setMessage("Erreur de connexion");
        }
      }
    );
  };

  return (
    <SurfacePopUp
      open={open}
      onClose={onClose}
      titre={"Connexion " + organisation.nom}
      sousTitre={"_id : " + organisation._id}
    >
      <div style={{ display: "flex", flexDirection: "column" }}>
        <InputEmail
          label="Login"
          value={login}
          onChange={(e) => setLogin(e.target.value)}
        />
        <InputPassword
          label="Mot de passe"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <MessageErreur message={message} style={{ marginTop: "20px" }} />
        <BoutonActionPrincipale
          texte="Connexion"
          style={{ margin: "20px 0px" }}
          taille="L"
          isLoading={isLoading}
          onClick={handleConnexion}
        />
        <BoutonAnnuler onClick={onClose} />
      </div>
    </SurfacePopUp>
  );
}
