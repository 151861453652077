import React, { useContext, useState } from "react";
import Backdrop from "@material-ui/core/Backdrop";
import { makeStyles } from "@material-ui/core/styles";
import MonStyle from "./MenuMobile.module.css";
import BoutonRond from "../../Design/Boutons/BoutonRond";
import CloseIcon from "@material-ui/icons/Close";
import SettingsIcon from "@material-ui/icons/Settings";
import { Link } from "react-router-dom";
import HistoryIcon from "@material-ui/icons/History";
import { AuthentificationContext } from "../../Data/AuthentificationContext";
import {
  IconeMenuAgendaDate,
  IconeMenuProduit,
  IconeMenuConsultation,
  IconeMenuPrestations,
} from "../../Design/Icones/IconesMenu";
import AllInboxIcon from "@material-ui/icons/AllInbox";
import { IconeAdpx } from "../../Design/Icones/IconesBe";
import { couleurAction } from "../../functions/FonctionsCouleurs";
import AppsIcon from "@material-ui/icons/Apps";
import { isMobile } from "../../functions/Navigateur";

function BoutonMenu({ icone, link, texte, ...props }) {
  return (
    <Link to={link}>
      <div className={MonStyle.BoutonMenu}>
        <div className={MonStyle.BoutonMenuIcone}>{props.children}</div>
        <div className={MonStyle.BoutonMenuLabel}>{texte}</div>
      </div>
    </Link>
  );
}

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
    backgroundColor: "rgb(0, 0, 0, 0.5)",
  },
}));

export default function MenuMobile({ open, onClose }) {
  const classes = useStyles();

  //NES gestion du contexte
  const authContext = useContext(AuthentificationContext);

  //NES gestion du state
  const [afficherMenuMobile, setAfficherMenuMobile] = useState(false);

  if (!isMobile()) {
    return "";
  }

  return (
    <>
      <BoutonRond
        iconeComposant={<AppsIcon />}
        couleurIcone="blanc"
        tooltip="Menu Mobile"
        onClick={() => setAfficherMenuMobile(true)}
      />
      <Backdrop
        className={classes.backdrop}
        open={afficherMenuMobile}
        onClick={() => setAfficherMenuMobile(false)}
      >
        <div className={MonStyle.MenuMobile}>
          <div className={MonStyle.BoutonFermer}>
            <BoutonRond
              iconeComposant={<CloseIcon />}
              onClick={onClose}
              couleurIcone="blanc"
              tooltip="Fermer le menu"
            />
          </div>

          <div className={MonStyle.Contenu}>
            <div className={MonStyle.ItemsMenu}>
              <BoutonMenu
                icone={HistoryIcon}
                link="/patientsrecents"
                texte="Patients"
              >
                <IconeMenuConsultation style={{ fontSize: "32px" }} />
              </BoutonMenu>
              <BoutonMenu link="/agendas/" texte="Agenda">
                <IconeMenuAgendaDate color="black" />
              </BoutonMenu>
              <BoutonMenu link="/produits/" texte="Produits">
                <IconeMenuProduit color="black" />
              </BoutonMenu>

              <BoutonMenu link="/prestations/" texte="Prestations">
                <IconeMenuPrestations color="black" />
              </BoutonMenu>

              <BoutonMenu link="/suivireglements/" texte="Suivi des règlements">
                <AllInboxIcon style={{ fontSize: "36px" }} />
              </BoutonMenu>

              {authContext.payload.is_admin && (
                <BoutonMenu
                  icone={SettingsIcon}
                  link="/options/"
                  texte="Options"
                >
                  <SettingsIcon style={{ fontSize: "36px" }} />
                </BoutonMenu>
              )}
              {authContext.payload.code_role === "ADPX" && (
                <BoutonMenu icone={SettingsIcon} link="/adpx/" texte="ADPX">
                  <IconeAdpx width="50px" color={couleurAction()} />
                </BoutonMenu>
              )}
            </div>
          </div>
        </div>
      </Backdrop>
    </>
  );
}
