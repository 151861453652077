import React from "react";
import MonStyle from "./ActionWelcome.module.css";
import SurfacePapier from "../../../Design/Surfaces/SurfacePapier";

export default function ActionWelcome({ style, open }) {
  if (!open) {
    return "";
  }

  return (
    <SurfacePapier style={style}>
      <div className={MonStyle.ActionWelcome}>
        <h2>Quelques mots pour bien démarrer</h2>
        <div>
          Bonjour et <b>merci d'avoir choisi Sinobi</b>, votre outil de gestion
          de patientèle en ligne totalement sécurisé.
        </div>
        <div>
          Pour bien commencer, soyez sûrs de{" "}
          <strong>compléter les informations relatives à votre cabinet</strong>{" "}
          pour établir vos factures avec toutes les informations légales (Menu
          Cabinet dans les paramètres accessibles depuis la roue dentée en haut
          à droite).
        </div>
        <div>
          Puis <strong>renseignez vos différentes prestations</strong> en vous
          laissant guider dans le menu "Prestations et Actes" sur votre gauche.
        </div>
        <div>
          Vous pouvez également <strong>ajouter des collaborateurs</strong> à
          votre cabinet, vos propres types de consultations, personnaliser si
          vous en avez besoin des produits que vous pourrez ajouter sur les
          fiches remises à vos patients en fin de consultation...
        </div>
        <div>
          La section{" "}
          <strong>agenda vous permettra d'organiser votre semaine</strong> de
          travail, et également de pouvoir envoyer des confirmations et rappels
          à vos patients, si vous le désirez, sous forme d'emails et de sms.
        </div>
        <div>
          Simple d'utilisation et très intuitif, nous ne doutons pas que qu'il
          vous faudra seulement quelques instants pour prendre en main Sinobi!
        </div>
        <div>
          Dans le cas contraire, <strong>nous sommes là pour vous aider</strong>
          , envoyez nous une demande d'assistance pour convenir d'un entretien
          de formation au cours duquel nous vous aiderons à paramétrer votre
          cabinet.
        </div>
        <strong>En vous souhaitant une bonne utilisation,</strong>
        <p className="Signature"> Nicolas & Jérémy</p>
      </div>
    </SurfacePapier>
  );
}
