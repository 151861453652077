import React from "react";
import IllustrationMedicaleS from "../../Images/Illustrations/DonneesMedicales_S.svg";
import IllustrationMedicale from "../../Images/Illustrations/DonneesMedicales.svg";

export default function MessageDonneesMedicales({ taille, titre }) {
  if (taille === "L") {
    return (
      <div
        style={{
          textAlign: "center",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          border: "0px solid orange",
        }}
      >
        <img src={IllustrationMedicale} alt="" style={{ opacity: 0.3 }} />
        <h3 style={{ color: "#005093" }}>
          {titre ? titre : "Données de santé"}
        </h3>
        <p className="InformationSecondaire" style={{ textAlign: "center" }}>
          Les données de santé ne sont pas affichées pour les utilisateurs
          n'ayant pas un rôle praticien.
        </p>
      </div>
    );
  }

  return (
    <div style={{ textAlign: "center" }}>
      <img src={IllustrationMedicaleS} alt="" style={{ opacity: 0.3 }} />
      <h4 style={{ color: "#005093" }}>{titre ? titre : "Données de santé"}</h4>
      <p className="InformationSecondaire" style={{ textAlign: "center" }}>
        Les données de santé ne sont pas affichées pour les utilisateurs n'ayant
        pas un rôle praticien.
      </p>
    </div>
  );
}
