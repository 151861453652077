export function CalculHt(montantTtc, tauxTva) {
  let prixHt = 0;

  if (montantTtc > 0) {
    prixHt = montantTtc / (1 + tauxTva / 100);
    prixHt = prixHt.toFixed(2);
  }

  return prixHt;
}
