import React from "react";

export default function EnteteResultat({ style, ...props }) {
  return (
    <div
      style={{
        ...style,
        height: "60px",
        borderBottom: "1px solid #DDD",
        borderTop: "1px solid #DDD",
        backgroundColor: "white",
        fontSize: "12px",
        fontWeight: "bold",
        color: "#999999",
        fontFamily: "Raleway",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
      }}
    >
      {props.children}
    </div>
  );
}
