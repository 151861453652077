import React, { useState } from "react";
import InputTexteFilled from "./InputTexteFilled";
import InputAdornment from "@mui/material/InputAdornment";
import BoutonRond from "../Boutons/BoutonRond";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

export default function InputPasswordFilled({ value, label, ...props }) {
  //NES gestion du state
  const [visible, setVisible] = useState(false);

  return (
    <InputTexteFilled
      label={label ? label : "Mot de passe"}
      type={visible ? "text" : "password"}
      value={value}
      InputProps={{
        disableUnderline: true,
        endAdornment: (
          <InputAdornment position="end">
            <BoutonRond
              couleurIcone="gris"
              iconeComposant={
                visible ? <VisibilityOffIcon /> : <VisibilityIcon />
              }
              onClick={() => setVisible(!visible)}
            />
          </InputAdornment>
        ),
      }}
      {...props}
    />
  );
}
