import React from "react";
import SurfacePapier from "../../../Design/Surfaces/SurfacePapier";
import MonStyle from "./SuggestionAjouterTarif.module.css";
import IllustrationConsultation from "../../../Images/Illustrations/Consultation.png";
import BoutonActionSecondaire from "../../../Design/Boutons/BoutonActionSecondaire";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import { useContext } from "react";
import { PrestationsContext } from "../../../Data/PrestationsContext";
import { useState, useEffect } from "react";

export default function SuggestionAjouterTarif({ style }) {
  //NES gestion du contexte
  const { getPrestations } = useContext(PrestationsContext);

  //NES Gestion du state
  const [afficher, setAfficher] = useState(false);

  //USEEFFECT
  useEffect(() => {
    getPrestations().then((reponse) => {
      if (reponse.data.length === 0) {
        setAfficher(true);
      }
    });
    // eslint-disable-next-line
  }, []);

  if (!afficher) {
    return "";
  }

  return (
    <SurfacePapier style={style}>
      <div className={MonStyle.SuggestionAjouterTarif}>
        <div className={MonStyle.TexteEtBouton}>
          <h4>Prestations et tarifs</h4>
          <h3>Renseignez vos tarifs de consultations</h3>
          <p style={{ textAlign: "center" }}>
            Commencez à renseigner vos tarifs de consultations. Ils peuvent être
            différents selon le type de consultation : consultation classique,
            acuponcture, visite à domicile...
          </p>
          <BoutonActionSecondaire
            startIcon={<LocalOfferIcon />}
            texte="Renseigner mes tarifs"
            style={{ marginTop: "20px " }}
            link="/prestations/new-consultation"
            taille="L"
          />
        </div>
        <div className={MonStyle.Illustration}>
          <img
            src={IllustrationConsultation}
            alt="Consultation"
            className="Illustration"
          />
        </div>
      </div>
    </SurfacePapier>
  );
}
