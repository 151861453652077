import React from "react";
import SurfacePapier from "../../../../Design/Surfaces/SurfacePapier";
import MonStyle from "./PageEditionConstante.module.css";
import SurfaceZoneSecondaire from "../../../../Design/Surfaces/SurfaceZoneSecondaire";
import EditionConstante from "./Components/EditionConstante";

export default function PageEditionConstante({ match }) {
  return (
    <SurfacePapier>
      <div className={MonStyle.Content}>
        <div className={MonStyle.EditionConstante}>
          <EditionConstante constante_id={match.params.id} />
        </div>
        <SurfaceZoneSecondaire>
          <section>
            <h3>Comment renseigner une constante?</h3>
            <p>
              Vous allez retrouver dans les consutlations de type SANTE, un bloc
              permettant de renseigner les constantes du dossier. Ce n'est pas
              disponible pour les consultations de type GENERIQUE ou
              ENERGETIQUE.
            </p>
          </section>
          <section>
            <h3>Comment suivre une constante?</h3>
            <p>
              Si vous avez besoin de suivre une valeur plus particulièrement
              pour un patient, n'hésitez pas à l'épingler pour qu'elle
              apparaissent dans la synthèse du dossier patient à gauche et
              qu'elle vous soit proposé directement en saisie à chaque
              consultation.
            </p>
          </section>
        </SurfaceZoneSecondaire>
      </div>
    </SurfacePapier>
  );
}
