import React from "react";
import MonStyle from "./AffichageMesure.module.css";
import IconesMesures from "../../../../Design/Icones/IconesMesures";

//NES Test de couleur
const getColor = (mesure) => {
  if (mesure.code) {
    switch (mesure.code) {
      case "poids":
        return "#2FAD63";
      case "temperature":
        return "#B63730";
      case "glycemie":
        return "#D05E9C";
      case "taille":
        return "#3081B8";
      case "tension":
        return "#3B98D9";
      case "frequence_cardiaque":
        return "#5B4CA1";
      case "sao2":
        return "#FCA728";
      default:
        return "#205A71";
    }
  }
  if (mesure.couleur) {
    return mesure.couleur;
  } else {
    return "#205A71";
  }
};

export default function AffichageMesure({ mesure, color }) {
  if (mesure.valeur) {
    return (
      <div
        className={MonStyle.AffichageMesure}
        style={{
          backgroundColor: color ? color + "20" : getColor(mesure) + "20",
          color: color ? color : getColor(mesure),
        }}
      >
        <IconesMesures
          nomIcone={mesure.icone_mesure}
          style={{
            width: "24px",
            height: "16px",
            color: color ? color : getColor(mesure),
          }}
        />
        <div className={MonStyle.Mesure}>{mesure.valeur}</div>
        <div className={MonStyle.Unite}> {mesure.unite}</div>
      </div>
    );
  } else {
    return "";
  }
}
