import React, { useState, useEffect } from "react";
import MonStyle from "./BlocTelephone.module.css";
import PhoneIcon from "@material-ui/icons/Phone";
import BoutonRond from "../../../../../Design/Boutons/BoutonRond";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import ObjetTelephone from "../../../../../Design/Objets/ObjetTelephone";

export default function EditionTelephone({ patient, onClick }) {
  const [liste, setListe] = useState([]);

  //USEEFFECT
  useEffect(() => {
    if (patient?.patient_contact?.telephones) {
      let tempListe = patient?.patient_contact?.telephones;
      //NES il y a des patients avec un téléphone vide :(
      tempListe = tempListe.filter((item) => item.numero !== "");
      setListe(tempListe);
    }
  }, [patient?.patient_contact?.telephones]);

  if (liste.length >= 1) {
    return (
      <div
        onClick={onClick}
        id="bloc_telephone"
        className={MonStyle.EditionTelephone}
      >
        <ObjetTelephone telephone={liste[0]} badgeContent={liste.length} />
        <BoutonRond
          iconeComposant={<NavigateNextIcon />}
          tooltip="Modifier les téléphones du patient"
        />
      </div>
    );
  } else {
    return (
      <div onClick={onClick} id="bloc_telephone">
        <div className={MonStyle.EditionTelephone}>
          <div className="Icone" style={{ marginRight: "10px" }}>
            <PhoneIcon />
          </div>
          <div className={MonStyle.PasDeTelephone}>Téléphone</div>
          <BoutonRond
            iconeComposant={<NavigateNextIcon />}
            tooltip="Modifier les téléphones du patient"
          />
        </div>
      </div>
    );
  }
}
