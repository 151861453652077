import React, { useContext } from "react";
import MonStyle from "./SelectTypeDemande.module.css";
import ForumIcon from "@material-ui/icons/Forum";
import BugReportIcon from "@material-ui/icons/BugReport";
import ExploreIcon from "@material-ui/icons/Explore";
import ObjetItem from "../../../../Design/Objets/ObjetItem";
import { SupportContext } from "../../../../Data/SupportContext";

export default function SelectTypeDemande({ value, onChange, style }) {
  //NES gestion du contexte
  const { libelleTypeDemande } = useContext(SupportContext);

  const onSelectType = (type) => {
    if (onChange) {
      onChange(type);
    }
  };

  return (
    <div className={MonStyle.ListeType} style={style}>
      <div
        className={MonStyle.TypeDemande}
        style={{
          border: value === "AIDE" ? "2px solid #208AB0" : "",
        }}
        onClick={() => onSelectType("AIDE")}
      >
        <ForumIcon style={{ fontSize: 30, color: "#205A71" }} />
        <ObjetItem
          principale={libelleTypeDemande.AIDE}
          secondaire="Vous avez une demande, sur l'utilisation d'une fonctionnalité, un problème d'utilisation..."
        />
      </div>
      <div
        className={MonStyle.TypeDemande}
        style={{ border: value === "BUG" ? "2px solid #208AB0" : "" }}
        onClick={() => onSelectType("BUG")}
      >
        <BugReportIcon style={{ fontSize: 30, color: "#F44336" }} />
        <ObjetItem
          principale={libelleTypeDemande.BUG}
          secondaire="L'application affiche un message d'erreur, elle ne fonctionne pas correctement..."
        />
      </div>
      <div
        className={MonStyle.TypeDemande}
        style={{
          border: value === "EVOLUTION" ? "2px solid #208AB0" : "",
        }}
        onClick={() => onSelectType("EVOLUTION")}
      >
        <ExploreIcon style={{ fontSize: 30, color: "#0FA600" }} />
        <ObjetItem
          principale={libelleTypeDemande.EVOLUTION}
          secondaire="Vous avez une idée pour améliorer l'application. N'hésitez pas à nous en faire part."
        />
      </div>
    </div>
  );
}
