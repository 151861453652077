import React, { useEffect, useState } from "react";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import { withStyles } from "@material-ui/core/styles";

export default function InputRadio({ label, values, valueChecked, onChange }) {
  const [value, setValue] = useState("");
  const [liste, setListe] = useState([]);

  useEffect(() => {
    if (values) {
      setListe(values);
    }
  }, [values]);

  useEffect(() => {
    if (valueChecked) {
      setValue(valueChecked);
    }
  }, [valueChecked]);

  const handleChange = (event) => {
    setValue(event.target.value);
    if (onChange) {
      onChange(event.target.value);
    }
  };

  //NES style dynamique de la checkbox
  const BeRadio = withStyles({
    root: {
      color: "#208AB0",
      "&$checked": {
        color: "#208AB0",
      },
    },
    checked: {},
  })((props) => <Radio color="default" {...props} />);

  //NES style dynamique du label de la checkbox
  const BeFormControlLabel = withStyles({
    root: {
      "& .MuiTypography-body1": {
        color: (props) => (props.checked ? "#208AB0" : "#666"),
      },
    },
  })(FormControlLabel);

  return (
    <div
      style={{
        paddingLeft: "15px",
        marginTop: "20px",
      }}
    >
      <FormLabel component="legend" style={{ fontSize: "12px" }}>
        {label}
      </FormLabel>
      <RadioGroup name="inputRadio" value={value} onChange={handleChange}>
        {liste.map((item) => (
          <BeFormControlLabel
            value={item.code}
            control={<BeRadio />}
            label={item.libelle}
            key={item.code}
            checked={item.code === value ? true : false}
          />
        ))}
      </RadioGroup>
    </div>
  );
}
