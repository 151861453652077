import React, { useContext, useState, useEffect } from "react";
import Adresse from "../../../../../Components/Adresse";
import BoutonActionPrincipale from "../../../../../Design/Boutons/BoutonActionPrincipale";
import LoaderHorizontal from "../../../../../Design/Progression/LoaderHorizontal";
import MessageErreur from "../../../../../Design/Messages/MessageErreur";
import { PatientsContext } from "../../../../../Data/PatientsContext";

export default function EditionAdresse({ patient_id, adresse, onChange }) {
  //NES Gestion du contexte
  const { patchPatient } = useContext(PatientsContext);

  //NES gestion du state
  const [monAdresse, setMonAdresse] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [messageErreur, setMessageErreur] = useState();
  const [error, setError] = useState();

  useEffect(() => {
    setMonAdresse(adresse);
  }, [adresse]);

  //NES quand mon adresse change
  const handleAdresseChange = (adresse) => {
    setMonAdresse(adresse);
  };

  //NES quand je clique sur enregistrer
  const hangleEnregistrer = () => {
    //NES mise à jour de l'adresse du patient
    let data = { adresse: monAdresse };
    console.log({ data });

    if (patient_id) {
      setIsLoading(true);
      patchPatient(patient_id, data)
        .then((reponse) => {
          setIsLoading(false);
          if (reponse.code === "OK") {
            if (onChange) {
              onChange(monAdresse);
            }
          } else {
            setMessageErreur("Erreur lors de l'enregistrement");
          }
        })
        .catch((error) => {
          setIsLoading(false);
          setMessageErreur("Erreur lors de l'enregistrement");
          setError(error);
        });
    }
  };

  return (
    <div>
      <Adresse
        onChange={handleAdresseChange}
        onSelect={handleAdresseChange}
        value={monAdresse}
      />
      <MessageErreur
        message={messageErreur}
        error={error}
        style={{ marginTop: "20px" }}
      />
      <BoutonActionPrincipale
        couleur="bleu"
        texte="Enregistrer"
        fullWidth
        style={{ marginTop: "20px" }}
        onClick={hangleEnregistrer}
      />
      <div
        className="InformationSecondaire"
        style={{ marginTop: "10px", textAlign: "center" }}
      >
        <LoaderHorizontal isLoading={isLoading} />
        On met à jour les numéros de téléphones du patient quand vous les
        modifiez dans le rendez-vous
      </div>
    </div>
  );
}
