import React from "react";
import MonStyle from "./EditionPosologie.module.css";
import IconesPosologie from "../../../../Design/Icones/IconesPosologie";
import NumberPicker from "../../../../Design/Pickers/NumberPicker";
import { useState, useEffect } from "react";

function Posologie({ qte, moment, onChange }) {
  //NES Gestion du state
  const [maQte, setMaQte] = useState();

  useEffect(() => {
    if (qte) {
      setMaQte(qte);
    }
  }, [qte]);

  const handleChange = (valeur) => {
    setMaQte(valeur);
    if (onChange) {
      onChange(valeur);
    }
  };

  return (
    <NumberPicker
      value={maQte}
      onClick={(nombre) => handleChange(nombre)}
      onEffacer={() => handleChange("")}
    >
      <div className={MonStyle.Posologie}>
        <div className={MonStyle.Qte}>
          {qte ? qte : <div className={MonStyle.Moment}>{moment}</div>}
        </div>
        <div className={MonStyle.IconeMoment}>
          <IconesPosologie nom={moment} style={{ color: "#97989D" }} />
        </div>
      </div>
    </NumberPicker>
  );
}

export default function EditionPosologie({ posologie, onChange }) {
  //NES gestion du state
  const [maPosologie, setMaPosologie] = useState({});

  useEffect(() => {
    if (posologie) {
      setMaPosologie(posologie);
    }
  }, [posologie]);

  const handleChange = (moment, qte) => {
    let newPosologie = { ...maPosologie, [moment]: qte };
    setMaPosologie(newPosologie);
    if (onChange) {
      onChange(newPosologie);
    }
  };

  return (
    <div style={{ display: "flex" }}>
      <Posologie
        qte={maPosologie?.matin}
        moment="matin"
        onChange={(qte) => handleChange("matin", qte)}
      />
      <Posologie
        qte={maPosologie?.midi}
        moment="midi"
        onChange={(qte) => handleChange("midi", qte)}
      />
      <Posologie
        qte={maPosologie?.soir}
        moment="soir"
        onChange={(qte) => handleChange("soir", qte)}
      />
      <Posologie
        qte={maPosologie?.coucher}
        moment="coucher"
        onChange={(qte) => handleChange("coucher", qte)}
      />
    </div>
  );
}
