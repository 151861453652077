import React from "react";
import MonStyle from "./SurfacePage.module.css";

export default function SurfacePage({
  couleur,
  style,
  minHeight,
  disableMinHeight,
  disablePadding,
  ...props
}) {
  const StylePage = {
    background: couleur === "gris" ? "#f2f1f5" : "#FFFFFF",
    boxShadow: " -4px -4px 16px 0 rgba(0,0,0,0.50)",
    borderRadius: "4px 0 0 0",
    padding: disablePadding ? "0px" : "",
    boxSizing: "border-box",
    //minHeight: disableMinHeight ? "none" : "calc(100vh - 70px )",
    overflow: "scroll",
    maxHeight: window.innerHeight - 70 + "px",
    minHeight: window.innerHeight - 70 + "px",
    border: "0px solid blue",
    paddingBottom: "0px",
    ...style,
  };

  return (
    <div
      className={MonStyle.SurfacePage}
      couleur={props.couleur}
      style={StylePage}
    >
      {props.children}
    </div>
  );
}
