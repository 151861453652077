import React, { useContext, useEffect, useState } from "react";
import { Document, Page, Text, View, StyleSheet } from "@react-pdf/renderer";
import { StylesDocuments } from "../StylesDocumentsPdf";
import { DateToString } from "../../functions/FonctionsDateHeure";
import DocumentPdf from "../DocumentPdf";
import LoaderGlobal from "../../Design/Progression/LoaderGlobal";
import PopupMessageErreur from "../../Design/Messages/PopupMessageErreur";
import { CotationsContext } from "../../Data/CotationsContext";
import {
  capitalize,
  numberToEuro,
  truncateHead,
} from "../../functions/Formatter";

export default function DocumentFacture({
  facture_id,
  open,
  onClose,
  autoPrint,
}) {
  //NES Gestion du style
  const Styles = StyleSheet.create(StylesDocuments);

  //NES gestion du contexte
  const { getFactureById, getReglementsByFacture } =
    useContext(CotationsContext);

  //NES gestion du state
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [messageErreur, setMessageErreur] = useState();
  const [error, setError] = useState();
  const [afficherRemise, setAfficherRemise] = useState(false);
  const [reglements, setReglements] = useState();

  //NES fermeture de l'impression
  const handleClose = () => {
    //NES pour ne pas garder les donnée dans le cache sinon
    setData();
    if (onClose) {
      onClose();
    }
  };

  //USEEFFECT
  useEffect(() => {
    if (open === true && facture_id) {
      //NES je vide mes données en cache sinon c'est les données précédentes qui s'affichent
      setIsLoading(true);

      getReglementsByFacture(facture_id)
        .then((reponse) => {
          setIsLoading(false);
          if (reponse.code === "OK") {
            setReglements(reponse.data);
          } else {
            setMessageErreur("Erreur lors de l'enregistrement");
          }
        })
        .catch((error) => {
          setIsLoading(false);
          setMessageErreur("Erreur lors de l'enregistrement");
          setError(error);
        });

      getFactureById(facture_id)
        .then((reponse) => {
          setIsLoading(false);
          if (reponse.code === "OK") {
            console.log({ reponse });

            //NES est ce que j'affiche la colonne remise ?
            let cotations = reponse.data.cotations;
            cotations.forEach((maCotation) => {
              let listePrestations = maCotation.prestations;
              listePrestations.forEach((maLigne) => {
                if (
                  maLigne.montant_remise > 0 ||
                  maLigne.pourcentage_remise > 0
                ) {
                  setAfficherRemise(true);
                }
              });
            });

            setData(reponse.data);
          } else {
            setMessageErreur(
              "Erreur lors la récupération des données de la facture"
            );
          }
        })
        .catch((error) => {
          setIsLoading(false);
          setMessageErreur(
            "Erreur lors la récupération des données de la facture"
          );
          setError(error);
        });
    }

    // eslint-disable-next-line
  }, [facture_id, open]);

  const handlePopupMessageErreurClose = () => {
    setMessageErreur();
    handleClose();
  };

  if (messageErreur && open) {
    return (
      <PopupMessageErreur
        messageErreur={messageErreur}
        error={error}
        onClose={handlePopupMessageErreurClose}
      />
    );
  }

  if (data === undefined || reglements === undefined) {
    return <LoaderGlobal isLoading={isLoading} />;
  }

  return (
    <DocumentPdf
      openDocumentPdf={open}
      onClose={handleClose}
      autoPrint={autoPrint}
    >
      <Document>
        <Page size="A4" style={Styles.page}>
          <View>
            {/* CABINET */}

            <div style={{ display: "flex", flexDirection: "row" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  flex: 1,
                  marginRight: "30px",
                }}
              >
                <Text style={Styles.titreBloc}>{data?.cabinet?.nom}</Text>

                <Text>{data?.cabinet?.adresse?.rue}</Text>
                <Text>
                  {data?.cabinet?.adresse?.code_postal +
                    " " +
                    data?.cabinet?.adresse?.ville}
                </Text>
                {data?.cabinet?.telephone?.numero && (
                  <Text>Tél : {data?.cabinet?.telephone?.numero}</Text>
                )}
                {data?.cabinet?.fax?.numero && (
                  <Text>Fax : {data?.cabinet?.fax?.numero}</Text>
                )}

                {<Text>{data?.cabinet?.email}</Text>}

                <Text>{data?.cabinet?.texte_horaire}</Text>
                <Text>{data?.cabinet?.texte_rdv}</Text>

                <Text
                  style={[
                    Styles.informationPrincipale,
                    {
                      borderTop: "1px solid #D8D8D8",
                      marginTop: "10px",
                      paddingTop: "10px",
                      textAlign: "center",
                    },
                  ]}
                >
                  {data?.cabinet?.texte_libre}
                </Text>
              </div>

              {/* TITRE */}

              <div
                style={{
                  flex: 1,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    minHeight: "60px",
                  }}
                >
                  <Text style={Styles.titre}>HONORAIRES</Text>
                  <Text>
                    {data.is_avoir
                      ? "Avoir n°"
                      : "Facture n°" + data.numero_facture}
                  </Text>
                  <Text>
                    {DateToString(data?.date_facture, {
                      weekday: "long",
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                    })}
                  </Text>
                </div>
                <div
                  style={{
                    backgroundColor: "#EEEEEE",
                    borderRadius: "8px",
                    minHeight: "40px",
                    display: "flex",
                    flexDirection: "column",
                    flexWrap: "wrap",
                    alignItems: "flex-start",
                    justifyContent: "center",
                    minWidth: "200px",
                    padding: "10px",
                  }}
                >
                  <Text style={Styles.titreBloc}>
                    {data?.patient?.nom.toUpperCase() +
                      " " +
                      capitalize(data?.patient?.prenom)}
                  </Text>
                  <Text>{data?.patient?.adresse?.rue}</Text>
                  <Text>{data?.patient?.adresse?.code_postal}</Text>
                  <Text>{data?.patient?.adresse?.ville}</Text>
                  <Text>{data?.patient?.adresse?.complement}</Text>
                  <Text>{data?.patient?.adresse?.pays}</Text>
                </div>
              </div>
            </div>

            {/* COTATIONS */}

            <div style={{ marginTop: "20px" }}>
              {data?.cotations?.map((cotation) => (
                <View key={cotation._id}>
                  <div
                    style={{
                      height: "20px",
                      color: "white",
                      backgroundColor: "#205A71",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      paddingLeft: "10px",
                      fontWeight: "bold",
                    }}
                  >
                    <Text>
                      Consultation du{" "}
                      {DateToString(cotation.date_cotation, {
                        day: "numeric",
                        month: "long",
                        year: "numeric",
                      })}
                    </Text>
                  </div>
                  {/* ENTETES DE COLONNES */}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                      height: "30px",
                      borderBottom: "1px solid #EEEEEE",
                      paddingLeft: "10px",
                      paddingRight: "10px",
                    }}
                  >
                    <Text style={[Styles.titreColonneLeft, { width: "230px" }]}>
                      Prestations
                    </Text>
                    <Text style={[Styles.titreColonneRight, { width: "40px" }]}>
                      Qte
                    </Text>
                    <Text style={[Styles.titreColonneRight, { width: "60px" }]}>
                      HT unitaire
                    </Text>
                    <Text style={[Styles.titreColonneRight, { width: "60px" }]}>
                      HT total
                    </Text>
                    <Text style={[Styles.titreColonneRight, { width: "40px" }]}>
                      TVA
                    </Text>
                    {afficherRemise && (
                      <Text
                        style={[Styles.titreColonneRight, { width: "40px" }]}
                      >
                        Remise
                      </Text>
                    )}
                    <Text style={[Styles.titreColonneRight, { width: "60px" }]}>
                      Montant TTC
                    </Text>
                  </div>
                  {/* LIGNES DE PRESTATIONS */}

                  {cotation?.prestations?.map((ligne) => (
                    <View wrap={false} key={ligne._id}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "space-between",
                          height: "30px",
                          borderBottom: "1px solid #EEEEEE",
                          paddingLeft: "10px",
                          paddingRight: "10px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            width: "230px",
                          }}
                        >
                          <Text style={[Styles.informationPrincipale]}>
                            {ligne.nom}
                          </Text>
                          <Text style={[Styles.informationSecondaire]}>
                            {ligne.description}
                          </Text>
                        </div>

                        <Text
                          style={[
                            Styles.informationSecondaireRight,
                            { width: "40px" },
                          ]}
                        >
                          {ligne.quantite}
                        </Text>
                        <Text
                          style={[
                            Styles.informationSecondaireRight,
                            { width: "60px" },
                          ]}
                        >
                          {numberToEuro(ligne.prix_vente_ht_unitaire_brut)}
                        </Text>
                        <Text
                          style={[
                            Styles.informationSecondaireRight,
                            { width: "60px" },
                          ]}
                        >
                          {numberToEuro(ligne.prestation_total_ht_net)}
                        </Text>
                        <Text
                          style={[
                            Styles.informationSecondaireRight,
                            { width: "40px" },
                          ]}
                        >
                          {ligne.taux_tva + "%"}
                        </Text>
                        {afficherRemise && (
                          <Text
                            style={[
                              Styles.informationSecondaireRight,
                              { width: "40px" },
                            ]}
                          >
                            {ligne.pourcentage_remise
                              ? ligne.pourcentage_remise + " %"
                              : ""}
                            {ligne.montant_remise
                              ? ligne.montant_remise + " €"
                              : ""}
                          </Text>
                        )}

                        <Text
                          style={[
                            Styles.informationPrincipaleRight,
                            { width: "60px" },
                          ]}
                        >
                          {numberToEuro(ligne.prestation_total_ttc_net, " €")}
                        </Text>
                      </div>
                    </View>
                  ))}
                </View>
              ))}
            </div>
          </View>

          {/* TOTAL */}
          <View
            wrap={false}
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              marginTop: "20px",
            }}
          >
            <div
              style={{
                width: "160px",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <div>
                <Text
                  style={[
                    Styles.informationSecondaireRight,
                    { width: "80px", padding: "5px" },
                  ]}
                >
                  Total HT
                </Text>
                {data?.total_montant_tva?.map((tva) => (
                  <Text
                    key={tva.taux}
                    style={[
                      Styles.informationSecondaireRight,
                      { width: "80px", padding: "5px" },
                    ]}
                  >
                    Total TVA {tva.taux}%
                  </Text>
                ))}

                <Text
                  style={[
                    Styles.informationPrincipaleRight,
                    {
                      width: "80px",
                      padding: "5px",
                      backgroundColor: "#EEEEEE",
                    },
                  ]}
                >
                  TOTAL TTC
                </Text>
              </div>
              <div>
                <Text
                  style={[
                    Styles.informationSecondaireRight,
                    { width: "80px", padding: "5px" },
                  ]}
                >
                  {numberToEuro(data?.total_montant_ht, " €")}
                </Text>
                {data?.total_montant_tva?.map((tva) => (
                  <Text
                    key={tva.taux}
                    style={[
                      Styles.informationSecondaireRight,
                      { width: "80px", padding: "5px" },
                    ]}
                  >
                    {numberToEuro(tva?.montant, " €")}
                  </Text>
                ))}
                <Text
                  style={[
                    Styles.informationPrincipaleRight,
                    {
                      width: "80px",
                      padding: "5px",
                      backgroundColor: "#EEEEEE",
                    },
                  ]}
                >
                  {numberToEuro(data.total_montant_ttc, " €")}
                </Text>
              </div>
            </div>
          </View>

          {/* FACTURE SOLDEE */}
          {data.part_patient.montant_is_regle && (
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                marginTop: "20px",
              }}
            >
              <div
                style={{
                  height: "30px",
                  border: "1px solid black",
                  borderRadius: "15px",
                  width: "160px",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Text style={Styles.informationPrincipale}>Facture soldée</Text>
              </div>
            </View>
          )}

          {/* TEXTE LIBRE */}
          <View
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "20px",
            }}
          >
            <Text style={{ textAlign: "center" }}>
              {data?.mentions?.texte_libre}
            </Text>
          </View>

          {/* CLE FACTURE SECURISEE */}
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              marginTop: "20px",
            }}
          >
            <Text
              style={[
                Styles.informationSecondaireRight,
                { marginRight: "10px" },
              ]}
            >
              Clée {truncateHead(data?.securisation?.cle_calcule)}
            </Text>
            <Text style={Styles.informationSecondaireRight}>
              Version : {data?.securisation?.version}
            </Text>
          </View>

          {/* REGLEMENTS */}

          {reglements.length >= 1 ? (
            <View
              style={{
                display: "flex",
                flexDirection: "column",
                marginTop: "20px",
              }}
            >
              <Text
                style={[
                  Styles.informationPrincipale,
                  { borderBottom: "1px solid black", paddingBottom: "5px" },
                ]}
              >
                REGLEMENTS
              </Text>
              {reglements.map((reglement) => (
                <View
                  key={reglement._id}
                  style={{
                    padding: "5px 0px",
                    borderBottom: "1px solid #EEEEEE",
                  }}
                >
                  <Text style={Styles.normal}>
                    {reglement.libelle_type_reglement +
                      " " +
                      reglement.libelle_mode_reglement +
                      " de " +
                      reglement.montant +
                      " €"}
                  </Text>
                  <Text style={Styles.informationSecondaire}>
                    {DateToString(reglement?.date, {
                      weekday: "long",
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                    })}
                  </Text>
                </View>
              ))}
            </View>
          ) : null}

          {/* PIED DE PAGE */}

          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              alignItems: "center",
              fontSize: "8px",
              position: "absolute",
              bottom: "20mm",
              borderTop: "1px solid #EEEEEE",
              left: "10mm",
              paddingTop: "5px",
            }}
          >
            <Text>{data.mentions.rgpd}</Text>
            <Text>{data.mentions.informatique_et_liberte}</Text>
          </div>

          <View
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              fontSize: "8px",
              position: "absolute",
              bottom: "10mm",
              borderTop: "1px solid black",
              left: "10mm",
              paddingTop: "5px",
            }}
            fixed
          >
            <View
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
                border: "0px solid red",
              }}
            >
              <Text>
                {data?.cabinet?.informations_juridiques?.nom_entite_juridique +
                  ", " +
                  data?.cabinet?.informations_juridiques?.adresse_siege}
              </Text>
              <Text>
                {" - Capital : " +
                  data?.cabinet?.informations_juridiques?.capital_social}
              </Text>
              <Text>
                {" - N° SIRET : " +
                  data?.cabinet?.informations_juridiques?.numero_siret}
              </Text>

              {data?.cabinet?.informations_juridiques?.numero_tva && (
                <Text>
                  {" - N° TVA intra. : " +
                    data?.cabinet?.informations_juridiques?.numero_tva}
                </Text>
              )}
            </View>

            <Text
              style={{
                fontSize: "8px",
                fontWeight: "bold",
              }}
              render={({ pageNumber, totalPages }) =>
                `Page ${pageNumber} / ${totalPages}`
              }
            />
          </View>
        </Page>
      </Document>
    </DocumentPdf>
  );
}
