// eslint-disable-next-line
import React, { useContext, useState } from "react";
import SurfacePage from "../../Design/Surfaces/SurfacePage";
import { AuthentificationContext } from "../../Data/AuthentificationContext";
//import { DebugProvider } from "./DebugContext";
// import ComposantAvecContexte from "./ComponsantAvecContexte";
// import ComposantSansContexte from "./ComposantSansContexte";
// import ComposantUpdateContexte from "./ComposantUpdateContexte";
// import ComposantUtilisantAussiLeContexte from "./ComposantUtilisantAussiLeContexte";
// import ComposantUtilisantContexte from "./ComposantUtilisantContexte";
import InputTexte from "../../Design/Formulaires/InputTexte";
import InputTexteFilled from "../../Design/Formulaires/InputTexteFilled";
import InputEmailFilled from "../../Design/Formulaires/InputEmailFilled";
import InputPasswordFilled from "../../Design/Formulaires/InputPasswordFilled";

export default function PageDeveloppement({ match }) {
  const auth = useContext(AuthentificationContext);

  //NES Gesiton du st-ate
  const [texte, setTexte] = useState("");

  return (
    <SurfacePage //couleur="gris"
    >
      <h1>Développement</h1>
      {"organisation: " + auth.payload.owned_by_organisation}

      {JSON.stringify(auth.payload, null, "  ")}

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          maxWidth: "500px",
          border: "1px solid green",
          padding: "30px",
          gap: "30px",
        }}
      >
        {/* <DebugProvider>
          <ComposantSansContexte />
          <ComposantAvecContexte />
          <ComposantUpdateContexte />
          <ComposantUtilisantAussiLeContexte />
          <ComposantUtilisantContexte />
        </DebugProvider> */}

        <data>
          <p>Nicolas</p>
          <small>ESSE</small>
        </data>

        <form>
          <InputTexte label="Nom" placeholder="Entrez le nom" />
          <InputTexteFilled label="Nom" placeholder="Entrez le nom" />
          <InputTexteFilled
            label="Nom"
            placeholder="Entrez le nom"
            value="Nicolas ESSE"
          />
          <InputTexteFilled label="Nom" placeholder="Entrez le nom" disabled />
          <InputTexteFilled
            label="Nom"
            placeholder="Entrez le nom"
            value="Texte en lecture"
            disabled
          />

          <InputTexteFilled
            label="Nom"
            placeholder="Entrez le nom"
            value="En erreur"
            helperText="Message d'erreur"
            error
          />

          <InputEmailFilled
            value={texte}
            onChange={(e) => setTexte(e.target.value)}
            // onBlur={() => setTexte("onBLur")}
          />
          <InputPasswordFilled />
          {texte}
        </form>
      </div>
    </SurfacePage>
  );
}
