import React, { useContext, useEffect, useState, useRef } from "react";
import AffichageMessage from "./AffichageMessage";
import { SupportContext } from "../../../../Data/SupportContext";
import MessageErreur from "../../../../Design/Messages/MessageErreur";
import LoaderHorizontal from "../../../../Design/Progression/LoaderHorizontal";
import { Divider } from "@material-ui/core";

export default function AffichageMessagesDemande({ demande_id, style }) {
  //NES gestion du contexte
  const { getDemandesMessages, messages } = useContext(SupportContext);

  //NES gestion du state
  const [isLoading, setIsLoading] = useState(true);
  const [messageErreur, setMessageErreur] = useState();
  const [error, setError] = useState();

  //NES useref
  const myRef = useRef(null);

  useEffect(() => {
    if (demande_id) {
      getDemandesMessages(demande_id).then((reponse) => {
        setIsLoading(false);
        if (reponse.code !== "OK") {
          setError(reponse.error);
          setMessageErreur(
            "Erreur lors de la récupération des messages de la demande"
          );
        }
      });
    }
    // eslint-disable-next-line
  }, [demande_id]);

  //USEEFFECT scroll en bas
  useEffect(() => {
    myRef.current.scrollIntoView();
  });

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "10px",
        //backgroundColor: "yellow",
        flex: 1,
        boxSizing: "border-box",
        ...style,
      }}
    >
      <LoaderHorizontal isLoading={isLoading} />
      <MessageErreur message={messageErreur} error={error} />
      {messages.map((message) => (
        <AffichageMessage message={message} key={message._id} />
      ))}
      <Divider ref={myRef} style={{ marginTop: "40px" }} />
    </div>
  );
}
