import React, { useState, useContext, useEffect } from "react";
import MonStyle from "./PageMessages.module.css";
import MessageAucuneDonnee from "../../../../Design/Messages/MessageAucuneDonnee";
import InputTexte from "../../../../Design/Formulaires/InputTexte";
import BoutonActionPrincipale from "../../../../Design/Boutons/BoutonActionPrincipale";
import { PatientsContext } from "../../../../Data/PatientsContext";
import LoaderHorizontal from "../../../../Design/Progression/LoaderHorizontal";
import MessageErreur from "../../../../Design/Messages/MessageErreur";
import { isTelephonePortable } from "../../../../functions/FonctionsTelephone";
import ObjetTelephone from "../../../../Design/Objets/ObjetTelephone";
import MobileOffIcon from "@material-ui/icons/MobileOff";
import { MessagesContext } from "../../../../Data/MessagesContext";
import ObjetSms from "../../../../Design/Objets/ObjetSms";

export default function PageMessages({ match }) {
  //NES Récupération des paramètres de l'URL
  let patient_id = match.params.patient_id;

  //NES Gestion du contexte
  const { getPatientContext } = useContext(PatientsContext);
  const { postSmsPatient, getSmsPatient, sms } = useContext(MessagesContext);

  //NES Gestion du state
  const [listeMessages, setListeMessage] = useState([]);
  const [message, setMessage] = useState("");
  const [patient, setPatient] = useState({});
  const [messageErreur, setMessageErreur] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [listePortables, setListePortables] = useState([]);
  const [numeroTelephone, setNumeroTelephone] = useState();

  //USEEFFECT récupération forcé des sms dans l'API
  useEffect(() => {
    setIsLoading(true);
    //GET patient
    getPatientContext(patient_id)
      .then((reponse) => {
        if (reponse.code === "OK") {
          setPatient(reponse.data);
          //NES est-ce qu'il y a un numéro de téléphone portable?
          let contact = reponse.data.contact;
          let listeTelephones = contact.telephones;
          let liste = [];
          listeTelephones.forEach((telephone) => {
            if (isTelephonePortable(telephone.numero)) {
              liste.push(telephone);
            }
          });
          //NES je selectionne le 1er numéro par défaut
          setNumeroTelephone(liste[0]?.numero);
          setListePortables(liste);
        }
      })
      .catch((error) => {
        setMessageErreur("Erreur lors de l'enregistrement");
        setError(error);
      });

    //GET patients/:id/sms

    getSmsPatient(patient_id)
      .then((reponse) => {
        setIsLoading(false);
        if (reponse.code === "OK") {
          setListeMessage(reponse.data);
        } else {
          setMessageErreur("Erreur lors de la récupération des messages");
        }
      })
      .catch((error) => {
        setIsLoading(false);
        setMessageErreur("Erreur lors de la récupération des messages");
        setError(error);
      });
    // eslint-disable-next-line
  }, [patient_id, sms]);

  //NES Envoyer message
  const envoyerMessage = () => {
    let data = {
      patient_destinataire: {
        patient_id: patient_id,
        nom: patient.nom,
        nom_jeune_fille: patient.nom_jeune_fille,
        prenom: patient.prenom,
        sexe: patient.sexe,
        date_de_naissance: patient.date_de_naissance,
      },
      numero_telephone: numeroTelephone,
      nom_expediteur_sms: "Be",
      code_type: "sms_manuel",
      message: message,
      is_automatique: false,
    };
    //POST sms
    postSmsPatient(patient_id, data)
      .then((reponse) => {
        if (reponse.code !== "OK") {
          setMessageErreur("Erreur lors de l'envoi du sms");
        }
      })
      .catch((error) => {
        setMessageErreur("Erreur lors de l'envoi du sms");
        setError(error);
      });

    //NES on efface le texte du sms envoyé
    setMessage("");
  };

  return (
    <div className={MonStyle.PageMessages}>
      <div className="TitreMobile">Messages</div>
      <div className="SousTitre">
        {listeMessages.length > 1
          ? listeMessages.length + " messages"
          : listeMessages.length + " message"}
      </div>

      <div className={MonStyle.Content}>
        <LoaderHorizontal isLoading={isLoading} />
        <MessageErreur message={messageErreur} error={error} />

        {listeMessages.length === 0 ? (
          <MessageAucuneDonnee
            message="Aucun message envoyé à ce patient"
            conseil="Vous retrouverez ici les sms envoyés au patient"
            hideIllustration
          />
        ) : (
          <div className={MonStyle.ListeMessages}>
            {listeMessages.map((sms) => (
              <ObjetSms sms={sms} key={sms._id} />
            ))}
          </div>
        )}
      </div>

      {listePortables.length === 0 ? (
        <div className={MonStyle.AucunNumero}>
          <MobileOffIcon className="Icone" />
          Aucun numéro de téléphone portable
        </div>
      ) : (
        <div>
          {listePortables.length > 1 && (
            <div className={MonStyle.ListeTelephones}>
              {listePortables.map((telephone) => (
                <div
                  key={telephone._id}
                  className={
                    telephone.numero === numeroTelephone
                      ? MonStyle.ObjetTelephoneSelected
                      : MonStyle.ObjetTelephone
                  }
                  onClick={() => setNumeroTelephone(telephone.numero)}
                >
                  <ObjetTelephone telephone={telephone} />
                </div>
              ))}
            </div>
          )}

          <div className={MonStyle.Formulaire}>
            <InputTexte
              placeholder={"Votre sms au " + numeroTelephone}
              multiline
              theme="fondgris"
              onChange={(e) => setMessage(e.target.value)}
            />
            <BoutonActionPrincipale
              texte="Envoyer"
              taille="S"
              disabled={!message}
              onClick={envoyerMessage}
            />
          </div>
        </div>
      )}
    </div>
  );
}
