import React, { useEffect, useState } from "react";
import MonStyle from "./BoutonChoixOuiNon.module.css";
import SvgIcon from "@material-ui/core/SvgIcon";

export default function BoutonChoixOuiNon({
  iconeOui,
  iconeNon,
  onChange,
  value,
  style,
}) {
  //NES Gestion du state
  const [valeur, setValeur] = useState();

  //NES Gestion du clic
  const handleClic = (choix) => {
    let monChoix = null;
    //NES quand je clique sur OUI
    if (choix === "oui") {
      if (valeur === true) {
        monChoix = null;
      } else {
        monChoix = choix;
      }
    }
    //NES quand je clique sur NON
    if (choix === "non") {
      if (valeur === false) {
        monChoix = null;
      } else {
        monChoix = choix;
      }
    }
    //NES je stocke et je retourne la valeur
    setValeur(monChoix);
    if (onChange) {
      onChange(monChoix);
    }
  };

  useEffect(() => {
    setValeur(value);
  }, [value]);

  return (
    <div className={MonStyle.BoutonOuiNon} style={style}>
      <div
        className={
          valeur === "oui"
            ? MonStyle.BoutonSelectionne
            : MonStyle.BoutonNonSelectionne
        }
        onClick={(event) => handleClic("oui")}
      >
        <SvgIcon
          component={iconeOui}
          className="Icone"
          style={{
            height: "30px",
          }}
        />
        <div>Oui</div>
      </div>

      <div
        className={
          valeur === "non"
            ? MonStyle.BoutonSelectionne
            : MonStyle.BoutonNonSelectionne
        }
        onClick={(event) => handleClic("non")}
      >
        <SvgIcon
          component={iconeNon}
          className="Icone"
          style={{
            height: "30px",
          }}
        />
        <div>Non</div>
      </div>
    </div>
  );
}
