import React, { useState, createContext, useContext } from "react";
import {
  addHeuresMinutes,
  DateToHeure,
  setHeureFromString,
  toApiDate,
  soustraireJours,
} from "../functions/FonctionsDateHeure";
import { AuthentificationContext } from "./AuthentificationContext";
import { AgendasContext } from "./AgendasContext";
import { DELETE, PATCH, POST } from "../functions/FonctionsApi";
import { PerformancesContext } from "./PerformancesContext";

export const RdvContext = createContext();

export const RdvProvider = (props) => {
  //NES gestion du contexte
  const authContext = useContext(AuthentificationContext);
  const { datesSemaine, agendaSelected } = useContext(AgendasContext);
  const { GET } = useContext(PerformancesContext);

  //NES gestion du state
  const [rdv, setRdv] = useState([]);
  const [evenements, setEvenements] = useState([]);

  //GET RDV
  const getRdv = async (agenda_id, date_debut, date_fin) => {
    //console.time(" API getRdv");

    //NES je commence par effacer les rdv
    setRdv([]);

    //NES mes paramètres d'appel
    let parametres = "";

    if (date_debut && date_fin) {
      let paramDateDebut = toApiDate(date_debut);
      let paramDateFin = toApiDate(date_fin);
      parametres = `?datedebut="${paramDateDebut}"&datefin="${paramDateFin}"`;
    }

    //NES j'appelle l'API
    const MonUrl = `${process.env.REACT_APP_URL_API_AGENDA}/agendas/${agenda_id}/rdv${parametres}`;

    let reponseJson = await GET(MonUrl, authContext.token);

    //NES Je stocke les rdv dans le contexte
    if (reponseJson.code === "OK") {
      setRdv(reponseJson.data);
    }
    //console.timeEnd(" API getRdv");

    //NES je retourne à ma fonction appelante le résultat
    return reponseJson;
  };

  //NES charge les rdv d'un agenda
  const postListeRdvContext = (listeRdv) => {
    let tempArray = [...rdv];
    tempArray = tempArray.concat(listeRdv);
    setRdv(tempArray);
    //setRdv(listeRdv);
  };

  //NES récupère les rdv
  const getRdvContext = (agenda_id, dateDebut, dateFin) => {
    let maDateDebut = new Date(dateDebut);
    maDateDebut.setHours(0);
    maDateDebut.setMinutes(0);
    maDateDebut.setSeconds(0);
    let maDateFin = new Date(dateFin);
    maDateFin.setHours(23);
    maDateFin.setMinutes(59);
    maDateFin.setSeconds(59);

    let tempArray = [...rdv];

    tempArray = tempArray.filter((item) => {
      let dateRdv = new Date(item.date_heure);
      if (
        dateRdv > maDateDebut &&
        dateRdv < maDateFin &&
        item.agenda_id === agenda_id
      ) {
        return true;
      } else {
        return false;
      }
    });
    return tempArray;
  };

  //NES récupère les rdv
  const getEvementsContext = (dateDebut, dateFin) => {
    let maDateDebut = new Date(dateDebut);
    maDateDebut.setHours(0);
    maDateDebut.setMinutes(0);
    maDateDebut.setSeconds(1);
    let maDateFin = new Date(dateFin);
    maDateFin.setHours(23);
    maDateFin.setMinutes(59);
    maDateFin.setSeconds(59);

    let tempArray = [...evenements];
    tempArray = tempArray.filter((item) => {
      let dateEvenementDebut = new Date(item.date_heure_debut);
      let dateEvenementFin = new Date(item.date_heure_fin);

      if (
        (dateEvenementDebut > maDateDebut && dateEvenementDebut < maDateFin) ||
        (dateEvenementFin > maDateDebut && dateEvenementFin < maDateFin)
      ) {
        return true;
      } else {
        return false;
      }
    });
    return tempArray;
  };

  //POST ajouter un rdv
  const postRdv = async (agenda_id, data) => {
    //NES j'appelle l'API
    const MonUrl = `${process.env.REACT_APP_URL_API_AGENDA}/agendas/${agenda_id}/rdv`;
    //NES pour détecter les appels en boucle
    console.log({ POST: MonUrl });
    console.log({ POST: data });

    const reponseApi = await fetch(MonUrl, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        token: authContext.token,
      },
      body: JSON.stringify(data),
    });
    //NES je convertis ce que je récupère en JSON
    const reponseJson = await reponseApi.json();
    //NES je le garde dans le contexte
    console.log(reponseJson);
    if (reponseJson.code === "OK") {
      let tempArray = [...rdv];
      tempArray.push(reponseJson.data);
      setRdv(tempArray);
    }
    //NES je retourne à ma fonction appelante le résultat
    return reponseJson;
  };

  //PATCH patch un rdv
  const patchRdv = async (agenda_id, rdv_id, data) => {
    //NES j'appelle l'API
    const MonUrl = `${process.env.REACT_APP_URL_API_AGENDA}/agendas/${agenda_id}/rdv/${rdv_id}`;
    const reponseJson = await PATCH(MonUrl, authContext.token, data);
    //NES je le garde dans le contexte
    if (reponseJson.code === "OK") {
      let tempArray = [...rdv];
      let index = tempArray.findIndex((item) => item._id === rdv_id);
      tempArray.splice(index, 1, reponseJson.data);
      setRdv(tempArray);
    }
    //NES je retourne à ma fonction appelante le résultat
    return reponseJson;
  };

  //DELETE agenda/:id/rdv/:id
  const deleteRdv = async (agenda_id, rdv_id) => {
    const MonUrl = `${process.env.REACT_APP_URL_API_AGENDA}/agendas/${agenda_id}/rdv/${rdv_id}`;
    let reponseApi = await DELETE(MonUrl, authContext.token);
    console.log(reponseApi);
    if (reponseApi.code === "OK") {
      deleteRdvContext(reponseApi.data);
    }
    return reponseApi;
  };

  //NES suppression d'un rdv du context
  const deleteRdvContext = (data) => {
    setRdv((previousRdv) => {
      let tempArray = Array.from(previousRdv);
      let index = tempArray.findIndex((item) => item._id === data._id);
      if (index > -1) {
        tempArray.splice(index, 1);
        return tempArray;
      } else {
        return tempArray;
      }
    });
  };

  //NES ajout ou modification du rdv
  const upsertRdvContext = (data) => {
    if (data._id) {
      setRdv((previousRdv) => {
        let index = previousRdv.findIndex((item) => item._id === data._id);
        if (index > -1) {
          let tempArray = Array.from(previousRdv);
          tempArray.splice(index, 1, data);
          return tempArray;
        } else {
          return [...previousRdv, data];
        }
      });
    }
  };

  //NES Suppression d'un évènement dans le contexte
  const deleteEvenementContext = (data) => {
    setEvenements((previousEvenements) => {
      let tempArray = Array.from(previousEvenements);
      let index = tempArray.findIndex((item) => item._id === data._id);
      if (index > -1) {
        let monEvenement = tempArray[index];
        console.log({ monEvenement });
        if (monEvenement.recurrence) {
          //NES Si l'évènement est récurrent alors je force le refresh
          setEvenements(monEvenement.agenda_id);
          tempArray.splice(index, 1);
          return tempArray;
        } else {
          tempArray.splice(index, 1);
          return tempArray;
        }
      } else {
        return tempArray;
      }
    });
  };

  //NES Ajout ou modification d'un événement
  const upsertEvenementContext = (data) => {
    if (data.recurrence) {
    } else {
      if (data._id) {
        setEvenements((previousEvenement) => {
          let index = previousEvenement.findIndex(
            (item) => item._id === data._id
          );
          if (index > -1) {
            let tempArray = Array.from(previousEvenement);
            tempArray.splice(index, 1, data);
            return tempArray;
          } else {
            return [...previousEvenement, data];
          }
        });
      }
    }
  };

  //GET récupere les evènements
  const getEvenements = async (agenda_id, dateDebut, dateFin) => {
    //NES je commence par effacer les évenements
    setEvenements([]);

    //NES mes paramètres d'appel
    let parametres = "";
    if (dateDebut && dateFin) {
      let paramDateDebut = toApiDate(dateDebut);
      let paramDateFin = toApiDate(dateFin);
      parametres = `?datedebut="${paramDateDebut}"&datefin="${paramDateFin}"`;
    }
    const MonUrl = `${process.env.REACT_APP_URL_API_AGENDA}/agendas/${agenda_id}/evenements${parametres}`;
    //NES je récupère les data depuis l'API
    const reponseApi = await GET(MonUrl, authContext.token);
    //NES je stocke les evenements dans le contexte
    if (reponseApi.code === "OK") {
      setEvenements(reponseApi.data);
    }
    return reponseApi;
  };

  //POST agendas/:id/evenements/
  const postEvenement = async (agenda_id, data) => {
    const MonUrl = `${process.env.REACT_APP_URL_API_AGENDA}/agendas/${agenda_id}/evenements`;

    const reponseApi = await POST(MonUrl, authContext.token, data);
    //NES je mets à jour le contexte
    if (reponseApi.code === "OK") {
      let tempData = [...evenements];
      tempData.push(reponseApi.data);
      setEvenements(tempData);
      //NES si l'évenement est récurent tous les jours je dois refresh l'affichage
      if (reponseApi.data?.recurrence?.code === "jour") {
        refreshEvenements(agenda_id);
      }
    }
    //NES je retourne la réponse
    return reponseApi;
  };

  //PATCH agendas/:id/evenements/:id
  const patchEvenement = async (agenda_id, evenement_id, data, paramTous) => {
    //NES pour modifier tous les évènements à venir
    let param = "";
    if (paramTous) {
      param = "?tous=true";
    }
    const MonUrl = `${process.env.REACT_APP_URL_API_AGENDA}/agendas/${agenda_id}/evenements/${evenement_id}${param}`;
    //NES je récupère les data depuis l'API
    const reponseApi = await PATCH(MonUrl, authContext.token, data);
    //NES mise à jour du contexte
    if (reponseApi.code === "OK") {
      let tempData = [...evenements];
      let index = tempData.findIndex((item) => item._id === evenement_id);
      tempData.splice(index, 1, reponseApi.data);
      console.log({ tempData });
      setEvenements(tempData);
      //NES si l'évenement est récurent tous les jours je dois refresh l'affichage
      if (reponseApi.data?.recurrence?.code === "jour") {
        console.log("refreshEvenements");
        //  refreshEvenements(agenda_id);
      }
    }
    //NES je retourne à ma fonction appelante le résultat
    return reponseApi;
  };

  //DELETE agendas/:id/evenements/:id
  const deleteEvenement = async (agenda_id, evenement_id, paramTous) => {
    //NES indique si je dois aussi supprimer les évènements futurs
    let param = "";
    if (paramTous) {
      param = "?tous=true";
    }
    const MonUrl = `${process.env.REACT_APP_URL_API_AGENDA}/agendas/${agenda_id}/evenements/${evenement_id}${param}`;
    //NES pour détecter les appels en boucle
    console.log({ DELETE: MonUrl });

    //NES je récupère les data depuis l'API
    const reponseApi = await fetch(MonUrl, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        token: authContext.token,
      },
    });
    //NES je convertis ce que je récupère en JSON
    const reponseJson = await reponseApi.json();
    //NES je mets à jour le contexte
    console.log(reponseJson);
    if (reponseJson.code === "OK") {
      let tempArray = [...evenements];
      let index = tempArray.findIndex((item) => item._id === evenement_id);
      let evenementSupprime = tempArray[index];
      tempArray.splice(index, 1);
      setEvenements(tempArray);
      //NES si l'évenement est récurent tous les jours je dois refresh l'affichage
      if (evenementSupprime?.recurrence?.code === "jour") {
        refreshEvenements(agenda_id);
      }
    }

    //NES je retourne à ma fonction appelante le résultat
    return reponseJson;
  };

  const refreshEvenements = (agenda_id) => {
    console.log(datesSemaine);

    getEvenements(
      agenda_id,
      datesSemaine[0],
      datesSemaine[datesSemaine.length - 1]
    );

    /*
Avec la gestion des périodicité des évenmenets, il n'est pas possible de calculer du coté du front
sans risquer d'avoir des ≠ entre nos 2 codes, je ne fait donc pas de gestion de contexte de mon coté en cas de création ou modification.
*/
  };

  const calculDateHeureFinRdv = (rdv) => {
    let nbHeures = parseInt(rdv.duree.split(":")[0], 10);
    let nbMinutes = parseInt(rdv.duree.split(":")[1], 10);
    let heureFinRdv = addHeuresMinutes(
      DateToHeure(rdv.date_heure),
      nbHeures,
      nbMinutes
    );
    //let dateDebutRdv = new Date(rdv.date_heure);
    let dateFinRdv = setHeureFromString(rdv.date_heure, heureFinRdv);
    return dateFinRdv;
  };

  //GET /patients/:id/rdv
  const getRdvPatient = async (patient_id) => {
    //NES j'appelle l'API
    const MonUrl = `${process.env.REACT_APP_URL_API_AGENDA}/patients/${patient_id}/rdv`;
    //NES pour détecter les appels en boucle
    console.log({ GET: MonUrl });
    const reponseApi = await fetch(MonUrl, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        token: authContext.token,
      },
    });
    //NES je convertis ce que je récupère en JSON
    const reponseJson = await reponseApi.json();
    //NES aucune raison de le conserver dans le contexte

    //NES je retourne à ma fonction appelante le résultat
    return reponseJson;
  };

  //GET Vacances scolaires
  const getVacancesScolaires = async (joursAffiches, academie) => {
    //NES j'appelle l'API
    //const MonUrl = `${process.env.REACT_APP_URL_API_VACANCES}/records/1.0/search/?dataset=fr-en-calendrier-scolaire&facet=description&facet=start_date&facet=end_date&facet=zones&facet=annee_scolaire&refine.zones=Zone+B&refine.end_date=2022&refine.location=${academie}&exclude.population=Enseignants`;
    const aujourdhui = new Date();
    const anneeCourante = aujourdhui.getFullYear();

    const MonUrl = `https://data.education.gouv.fr/api/records/1.0/search/?dataset=fr-en-calendrier-scolaire&q=&facet=description&facet=population&facet=start_date&facet=end_date&facet=location&facet=zones&facet=annee_scolaire&refine.location=${academie}&refine.start_date=${anneeCourante}&exclude.population=Enseignants`;

    console.log("getVacancesScolaires", MonUrl);

    //NES pour détecter les appels en boucle
    const reponseApi = await fetch(MonUrl, {
      method: "GET",
      headers: {
        Accept: "application/json",
        // "Content-Type": "application/json",
        //token: authContext.token,
      },
    });
    //NES je convertis ce que je récupère en JSON
    const reponseJson = await reponseApi.json();
    //NES je converti mon résultat en évenements
    let vacancesFromApi = reponseJson.records;
    let tempArray = [];

    vacancesFromApi.forEach((element) => {
      if (joursAffiches) {
        let dateAfficheDebut = joursAffiches[0];
        let dateAfficheFin = joursAffiches[joursAffiches.length - 1];
        let dateVacancesDebut = new Date(element.fields.start_date);
        let dateVacancesFin = new Date(element.fields.end_date);

        if (
          //NES debut dans la plage
          (dateVacancesDebut >= dateAfficheDebut &&
            dateVacancesDebut <= dateAfficheFin) ||
          //NES fin dans la plage
          (dateVacancesFin >= dateAfficheDebut &&
            dateVacancesFin <= dateAfficheFin) ||
          //NES debut avant et fin apres
          (dateVacancesDebut <= dateAfficheDebut &&
            dateVacancesFin >= dateAfficheFin)
        ) {
          //NES la date de fin est la date de rentrée
          let bonneDateFinVacances = soustraireJours(
            element.fields.end_date,
            1
          );

          let newEvenement = {
            _id: element.fields.description + " " + element.fields.zones,
            nom_evenement:
              element.fields.description + " - " + element.fields.zones,
            date_heure_debut: element.fields.start_date,
            date_heure_fin: bonneDateFinVacances,
            is_toute_la_journee: true,
            couleur:
              agendaSelected?.donnees_externes?.vacances_scolaire_couleur,
            disabled: true,
          };

          tempArray.push(newEvenement);
        }
      }
    });

    //NES je retourne à ma fonction appelante le résultat
    return tempArray;
  };

  //NES INTERSAISONS je ne sais pas si ca doit être dynamique
  const getInterSaisons = async (joursAffiches) => {
    let aujourdhui = new Date();
    let anneeAffichee = aujourdhui.getFullYear();

    if (joursAffiches?.length > 0) {
      let premierJour = new Date(joursAffiches[0]);
      anneeAffichee = premierJour.getFullYear();
    }

    //5ème Chinoise =  intersaison chinoise, intersaison de la rate jaune ou ocre 9 jours à partir de la saison.

    let interSaisons = [
      {
        _id: "interSaison1",
        nom_evenement: "Intersaison de la rate (Printemps 🌺)",
        date_heure_debut: new Date(anneeAffichee + "-03-11T00:00:00"),
        date_heure_fin: new Date(anneeAffichee + "-03-29T00:00:00"),
        is_toute_la_journee: true,
        couleur: agendaSelected?.donnees_externes?.sinobi_intersaisons_couleur,
        disabled: true,
      },
      {
        _id: "interSaison2",
        nom_evenement: "Intersaison de la rate (Eté ☀️🏖)",
        date_heure_debut: new Date(anneeAffichee + "-06-12T00:00:00"),
        date_heure_fin: new Date(anneeAffichee + "-06-30T00:00:00"),
        is_toute_la_journee: true,
        couleur: agendaSelected?.donnees_externes?.sinobi_intersaisons_couleur,
        disabled: true,
      },
      {
        _id: "interSaison3",
        nom_evenement: "Intersaison de la rate (Automne 🍁🍂)",
        date_heure_debut: new Date(anneeAffichee + "-09-14T00:00:00"),
        date_heure_fin: new Date(anneeAffichee + "-10-02T00:00:00"),
        is_toute_la_journee: true,
        couleur: agendaSelected?.donnees_externes?.sinobi_intersaisons_couleur,
        disabled: true,
      },
      {
        _id: "interSaison4",
        nom_evenement: "Intersaison de la rate  (Hiver ❄️⛄️)",
        date_heure_debut: new Date(anneeAffichee + "-12-12T00:00:00"),
        date_heure_fin: new Date(anneeAffichee + "-12-30T00:00:00"),
        is_toute_la_journee: true,
        couleur: agendaSelected?.donnees_externes?.sinobi_intersaisons_couleur,
        disabled: true,
      },
    ];

    return interSaisons;
  };

  return (
    <RdvContext.Provider
      value={{
        rdv,
        setRdv,
        getRdv,
        postListeRdvContext,
        getRdvContext,
        postRdv,
        patchRdv,
        deleteRdv,
        deleteRdvContext,
        upsertRdvContext,
        getEvenements,
        evenements,
        postEvenement,
        patchEvenement,
        deleteEvenement,
        deleteEvenementContext,
        upsertEvenementContext,
        calculDateHeureFinRdv,
        getRdvPatient,
        setEvenements,
        getEvementsContext,
        getVacancesScolaires,
        getInterSaisons,
      }}
    >
      {props.children}
    </RdvContext.Provider>
  );
};
