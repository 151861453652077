import React, { useEffect, useState, useContext } from "react";
import MonStyle from "./LigneJours.module.css";
import { AgendasContext } from "../../../../Data/AgendasContext";

export default function LigneJours({ joursAffiches }) {
  //NES gestion du contexte
  const { selectedDate, setSelectedDate, datesSemaine } =
    useContext(AgendasContext);

  //NES gestion du state
  const [listeJours, setListeJours] = useState([]);

  //USEEFFECT
  useEffect(() => {
    if (joursAffiches) {
      setListeJours(joursAffiches);
    } else {
      setListeJours(datesSemaine);
    }
    // eslint-disable-next-line
  }, [joursAffiches, selectedDate, datesSemaine]);

  let aujourdhui = new Date();

  const styleNumeroJour = (jour, selectedDate) => {
    let retour = MonStyle.NumeroJour;
    //NES gestion de l'affichage aujourd'hui et date sélectionnée
    if (aujourdhui.toDateString() === jour.toDateString()) {
      retour = MonStyle.NumeroJourAujourdhui;
    }
    if (selectedDate) {
      if (selectedDate.toDateString() === jour.toDateString()) {
        retour = MonStyle.NumeroJourSelectionne;
      }
    }
    return retour;
  };

  //NES Changement de la date sélectionnée
  const handleClick = (date) => {
    setSelectedDate(date);
  };

  return (
    <div className={MonStyle.ListeJours}>
      {listeJours.map((jour) => (
        <div
          className={MonStyle.ColonneEnteteAgenda}
          key={jour}
          onClick={() => handleClick(jour)}
        >
          <div
            className={styleNumeroJour(jour, selectedDate)}
            id={"jour_" + jour.toLocaleDateString("fr-FR", { day: "numeric" })}
          >
            {jour.toLocaleDateString("fr-FR", { day: "numeric" })}
          </div>
          <div
            className={
              aujourdhui.toDateString() === jour.toDateString()
                ? MonStyle.NomJourAujourdhui
                : MonStyle.NomJour
            }
          >
            {jour.toLocaleDateString("fr-FR", { weekday: "short" })}
          </div>
        </div>
      ))}
    </div>
  );
}
