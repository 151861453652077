import React, { useState, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Check from "@material-ui/icons/Check";
import StepConnector from "@material-ui/core/StepConnector";

const BeConnector = withStyles({
  alternativeLabel: {
    top: 16,
    left: "calc(-50% + 20px)",
    right: "calc(50% + 20px)",
  },
  active: {
    "& $line": {
      borderColor: "#205A71",
    },
  },
  completed: {
    "& $line": {
      borderColor: "#205A71",
      backgroundColor: "orange",
    },
  },
  line: {
    borderColor: "#DDDDDD",
    borderTopWidth: 2,
    borderRadius: 1,
  },
})(StepConnector);

function BeStepIcon(props) {
  const { active, completed } = props;

  const style = {
    borderRadius: "30px",
    height: "32px",
    width: "32px",
    backgroundColor: "#F2F1F5",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "14px",
    color: "#97989D",
    fontWeight: "bold",
    boxSizing: "border-box",
  };

  const styleActive = {
    ...style,
    backgroundColor: "white",
    color: "#205A71",
    border: "2px solid #205A71",
  };

  if (active) {
    return <div style={styleActive}>{props.icon}</div>;
  }

  if (completed) {
    return (
      <div style={{ ...style, backgroundColor: "#205A71", color: "white" }}>
        <Check />
      </div>
    );
  } else {
    return <div style={style}>{props.icon}</div>;
  }
}

export default function Etapes({ etapes, etapeActive, style }) {
  const [steps, setSteps] = useState([]);

  useEffect(() => {
    if (etapes) {
      setSteps(etapes);
    }
  }, [etapes]);

  return (
    <div style={style}>
      <Stepper
        alternativeLabel
        activeStep={etapeActive}
        connector={<BeConnector />}
        style={{
          backgroundColor: "unset",
          paddingLeft: "0px",
          paddingRight: "0px",
        }}
      >
        {steps.map((label, index) => (
          <Step key={label}>
            <StepLabel StepIconComponent={BeStepIcon}>
              <div style={{ color: index === etapeActive ? "#205A71" : "" }}>
                {label}
              </div>
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </div>
  );
}
