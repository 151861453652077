import React, { useState, useContext, useEffect } from "react";
import MonStyle from "./EditionConstante.module.css";
import ColorPicker from "../../../../../Design/Pickers/ColorPicker";
import IconeMesurePicker from "../../../../../Design/Pickers/IconeMesuresPicker";
import InputTexte from "../../../../../Design/Formulaires/InputTexte";
import InputSwitch from "../../../../../Design/Formulaires/Switch";
import InputNombres from "../../../../../Design/Formulaires/InputNombres";
import BoutonActionPrincipale from "../../../../../Design/Boutons/BoutonActionPrincipale";
import BoutonAnnuler from "../../../../../Design/Boutons/BoutonAnnuler";
import AffichageConditionnel from "../../../../../Design/DataDisplay/AffichageConditionnel";
import MessageErreur from "../../../../../Design/Messages/MessageErreur";
import { ConstantesContext } from "../../../../../Data/ConstantesContext";
import { useHistory } from "react-router-dom";

export default function EditionConstante({ constante_id }) {
  //NES Gestion du routage pour faire des redirections
  let history = useHistory();

  //NES Gestion du contexte
  const { mesures, postMesure, patchMesure } = useContext(ConstantesContext);

  //NES gestion du state
  const [data, setData] = useState({
    libelle: "",
    libelle_court: "",
    unite: "",
    code: "",
    code_hprim: "",
    valeur_min: undefined,
    valeur_max: undefined,
    icone_mesure: "",
    is_number: false,
    couleur: "",
  });
  const [erreur, setErreur] = useState("");
  const [error, setError] = useState("");

  //USEEFFECT
  useEffect(() => {
    if (constante_id && mesures) {
      let maConstante = undefined;

      maConstante = mesures.find((item) => item._id === constante_id);

      if (maConstante) {
        setData(maConstante);
      }
    }
  }, [constante_id, mesures]);

  ///handleChange
  const handleChange = (key, value) => {
    let tempData = data;
    tempData = { ...tempData, [key]: value };
    console.log(tempData);
    setData(tempData);
  };

  ///Enregistrer
  const handleEnregistrer = async () => {
    enregistrer().then((reponse) => {
      console.log(reponse);
      if (reponse.code === "OK") {
        history.push("/options/constantes/");
      } else {
        setErreur("Erreur lors de l'enregistrement");
        setError(reponse);
      }
    });
  };

  const enregistrer = async () => {
    console.log({ data });

    if (data._id) {
      return await patchMesure(data);
    } else {
      return await postMesure(data);
    }
  };

  return (
    <div className={MonStyle.Content}>
      <h2 style={{ marginBottom: "30px" }}>
        {data._id ? data.libelle : "Nouvelle constante ou mesure"}
      </h2>

      <h4>Quelle icone souhaitez vous utiliser ?</h4>
      <IconeMesurePicker
        onClick={(nom) => handleChange("icone_mesure", nom)}
        value={data.icone_mesure}
      />
      <h4 style={{ marginTop: "30px" }}>Sélectionnez la couleur</h4>
      <ColorPicker
        onCouleurChange={(couleur) => handleChange("couleur", couleur)}
        couleurSelectionne={data.couleur}
      />
      <InputTexte
        value={data.libelle}
        label="Nom de la constante (obligatoire)"
        autoComplete="no"
        onChange={(e) => handleChange("libelle", e.target.value)}
      />
      <InputTexte
        value={data.unite}
        label="Unité"
        autoComplete="no"
        onChange={(e) => handleChange("unite", e.target.value)}
      />
      <InputTexte
        value={data.code}
        label="Code (ADPX only)"
        autoComplete="no"
        onChange={(e) => handleChange("code", e.target.value)}
      />
      <InputSwitch
        variant="formulaire"
        value={data.is_number}
        label="La valeur est numérique"
        onChange={(value) => handleChange("is_number", value)}
      />
      <AffichageConditionnel open={data.is_number} className={MonStyle.MinMax}>
        <InputNombres
          label="Alerte min"
          autoComplete="no"
          isDecimal
          value={data.valeur_min}
          onChange={(e) => handleChange("valeur_min", e.target.value)}
        />
        <InputNombres
          label="Alerte max"
          autoComplete="no"
          isDecimal
          value={data.valeur_max}
          onChange={(e) => handleChange("valeur_max", e.target.value)}
        />
      </AffichageConditionnel>
      <MessageErreur
        message={erreur}
        style={{ marginTop: "20px" }}
        error={error}
      />
      <BoutonActionPrincipale
        disabled={!data.libelle}
        texte="Enregistrer"
        fullWidth
        taille="L"
        style={{ margin: "20px 0px" }}
        onClick={handleEnregistrer}
      />
      <BoutonAnnuler fullWidth />
    </div>
  );
}
