import React, { useContext, useEffect, useState } from "react";
import MessageAucuneDonnee from "../../../Design/Messages/MessageAucuneDonnee";
import ObjetDemande from "../../../Design/Objets/ObjetDemande";
import LoaderHorizontal from "../../../Design/Progression/LoaderHorizontal";
import InputRecherche from "../../../Design/Recherche/InputRecherche";
import MonStyle from "./PageListeDemandes.module.css";
import ListItem from "@material-ui/core/ListItem";
import { SupportContext } from "../../../Data/SupportContext";
import { HashLink as Link } from "react-router-hash-link";
import SurfaceBloc from "../../../Design/Surfaces/SurfaceBloc";
import BoutonActionSecondaire from "../../../Design/Boutons/BoutonActionSecondaire";
import { capitalize } from "../../../functions/Formatter";
import MessageErreur from "../../../Design/Messages/MessageErreur";

export default function PageListeDemandes({ match, urlDemande }) {
  const URL_DEMANDE_ID = match?.params?.id;

  //NES gestion du context
  const { demandes, getDemandes } = useContext(SupportContext);

  //NES gestion du state
  const [liste, setListe] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [messageErreur, setMessageErreur] = useState("");
  const [error, setError] = useState();
  const [texteRecherche, setTexteRecherche] = useState("");

  //USEEFFECT chargement des demandes à l'ouverture de la page
  useEffect(() => {
    setIsLoading(true);
    //GET demandes
    getDemandes().then((reponse) => {
      //console.log({ reponse });
      setIsLoading(false);
      if (reponse.code !== "OK") {
        setMessageErreur(
          reponse.message
            ? reponse.message
            : "Erreur lors de la récupération des demandes du support"
        );
        setError(reponse.error);
      }
    });
    // eslint-disable-next-line
  }, []);

  //USEEFFECT quand la liste des demandes change ou quand on change le texte de recherche
  useEffect(() => {
    if (demandes) {
      let tempArray = demandes;
      // NES trie des demandes par date décroissante
      tempArray.sort((b, a) => {
        return (
          new Date(a?.tracabilite_creation?.date_tracabilite) -
          new Date(b?.tracabilite_creation?.date_tracabilite)
        );
      });
      //NES je filtre avec le texte de recherche
      if (texteRecherche !== "") {
        tempArray = tempArray.filter((item) => {
          //NES construction dynamique de la chaine de recherche on cherche sur tous les éléments affichées
          let chaineRecherche = item.objet;
          chaineRecherche = chaineRecherche + item.description;

          let regExp = new RegExp(
            `${texteRecherche.replace(" ", "(.*)")}`,
            "i"
          );
          let index = chaineRecherche.search(regExp);
          return index > -1 ? true : false;
        });
      }

      setListe(tempArray);
    }
  }, [demandes, texteRecherche]);

  //NES Si j'ai une erreur on affiche la page ERREUR
  if (messageErreur) {
    return (
      <MessageErreur
        message={messageErreur}
        error={error}
        taille="XL"
        style={{ marginTop: "50px" }}
      />
    );
  }

  return (
    <div className={MonStyle.PageListeDemandes}>
      <header>
        <h1>Support </h1>

        {demandes.length > 0 && (
          <BoutonActionSecondaire
            texte="Nouvelle Demande"
            link="/support/new"
          />
        )}
      </header>

      {demandes.length === 0 && !isLoading ? (
        <div className={MonStyle.AucuneDemande}>
          <MessageAucuneDonnee
            hideIllustration
            message={
              "Contactez nous directement depuis " +
              capitalize(process.env.REACT_APP_NOM)
            }
            conseil="Vous retrouverez l'avancement de la demande, nos échanges directement dans votre application."
          />
          <BoutonActionSecondaire
            texte="Nouvelle Demande"
            link="/support/new"
          />
        </div>
      ) : (
        <main>
          <InputRecherche
            placeholder="Rechercher"
            style={{ marginBottom: "30px" }}
            value={texteRecherche}
            onChange={(e) => setTexteRecherche(e.target.value)}
            onEffacer={() => setTexteRecherche("")}
          />
          <LoaderHorizontal isLoading={isLoading} />

          {texteRecherche && liste.length === 0 && (
            <MessageAucuneDonnee
              hideIllustration={true}
              message="Aucune demande trouvée"
              conseil="Essayez de modifier la recherche"
            />
          )}

          <SurfaceBloc>
            {liste.map((demande) => (
              <ListItem
                key={demande._id}
                divider
                button
                component={Link}
                selected={URL_DEMANDE_ID === demande._id ? true : false}
                to={
                  urlDemande
                    ? urlDemande + demande._id
                    : "/support/" + demande._id
                }
                style={{
                  paddingLeft: "5px",
                }}
              >
                <ObjetDemande demande={demande} />
              </ListItem>
            ))}
          </SurfaceBloc>
        </main>
      )}
    </div>
  );
}
