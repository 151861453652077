import React, { useState, useEffect, useContext } from "react";
import InputRecherche from "../../../../Design/Recherche/InputRecherche";
import ObjetContact from "../../../../Design/Objets/ObjetContact";
import LoaderHorizontal from "../../../../Design/Progression/LoaderHorizontal";
import MessageErreur from "../../../../Design/Messages/MessageErreur";
import LigneResultat from "../../../../Design/Recherche/LigneResultat";
import MessageAucuneDonnee from "../../../../Design/Messages/MessageAucuneDonnee";
import { ContactsContext } from "../../../../Data/ContactsContext";
import { pluriel } from "../../../../functions/Formatter";
import SpecialitePicker from "./SpecialitePicker";
import AffichageConditionnel from "../../../../Design/DataDisplay/AffichageConditionnel";
import BoutonRond from "../../../../Design/Boutons/BoutonRond";
import { AuthentificationContext } from "../../../../Data/AuthentificationContext";
import DeleteIcon from "@material-ui/icons/Delete";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import { List } from "@material-ui/core";

export default function RechercheContacts({ onSelect, is_medecin_traitant }) {
  //NES Gestion du context
  const { getAnnuaireSuggestions, getAnnuaire, deleteFromAnnuaire } =
    useContext(ContactsContext);
  const auth = useContext(AuthentificationContext);

  //NES Gestion du state
  const [liste, setListe] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [messageErreur, setMessageErreur] = useState();
  const [error, setError] = useState();
  const [texteRecherche, setTexteRecherche] = useState("");
  const [message, setMessage] = useState("");
  const [conseil, setConseil] = useState("");
  const [codeSpecialite, setCodeSpecialite] = useState("10");

  //USEEFFECT is_medecin_traitant
  useEffect(() => {
    if (is_medecin_traitant) {
      setCodeSpecialite("10");
    }
  }, [is_medecin_traitant]);

  // //USEEFFECT recherche
  useEffect(() => {
    let codeRechercheSpecialite = codeSpecialite;

    if (is_medecin_traitant) {
      setCodeSpecialite("10");
      codeRechercheSpecialite = "10";
    }

    if (texteRecherche === "") {
      getAnnuaireSuggestions(codeRechercheSpecialite)
        .then((reponse) => {
          setIsLoading(false);
          if (reponse.code === "OK") {
            setMessageErreur("");
            setListe(reponse.data);
            if (reponse.data.length === 0) {
              setMessage("Aucun contact récent");
              setConseil("");
            } else {
              setMessage("");
              setConseil("");
            }
          } else {
            setMessageErreur(
              "Erreur lors de la recherche des contacts fréquents"
            );
          }
        })
        .catch((error) => {
          setIsLoading(false);
          setMessageErreur(
            "Erreur lors de la recherche des contacts fréquents"
          );
          setError(error);
        });
    }

    if (texteRecherche) {
      //NES Je recherche pas à chaque lettre sinon c'est trop souvent
      const timer = setTimeout(() => {
        //NES si j'ai au moins 3 caractères on lance la recherche
        if (texteRecherche.length >= 3) {
          getAnnuaire(texteRecherche, codeRechercheSpecialite)
            .then((reponse) => {
              setIsLoading(false);
              if (reponse.code === "OK") {
                setMessageErreur("");
                setListe(reponse.data);
                if (reponse.data.length === 0) {
                  setMessage("Aucun contact trouvé");
                  setConseil("Essayez de modifier votre recherche");
                } else {
                  setMessage("");
                  setConseil("");
                }
              } else {
                setMessageErreur("Erreur lors de la recherche dans l'annuaire");
              }
            })
            .catch((error) => {
              setIsLoading(false);
              setMessageErreur("Erreur lors de la recherche dans l'annuaire");
              setError(error);
            });
        }
      }, 2000);
      return () => clearTimeout(timer);
    }

    // eslint-disable-next-line
  }, [codeSpecialite, texteRecherche, is_medecin_traitant]);

  //NES quand on click sur un concact
  const handleClick = (contact) => {
    if (onSelect) {
      onSelect(contact);
    }
  };

  ///handleTexteRechercheChange
  const handleTexteRechercheChange = (e) => {
    setTexteRecherche(e.target.value);
  };

  /// handleTexteRechercheEffacer
  const handleTexteRechercheEffacer = () => {
    setTexteRecherche("");
  };

  /// handle filtrer specialité
  const handleSelectSpecialite = (objetSpecialite) => {
    setCodeSpecialite(objetSpecialite?.code);
  };

  /// handle supprimer contact
  const handleSupprimerContact = (contact_id) => {
    deleteFromAnnuaire(contact_id).then((reponse) => {
      if (reponse.code === "OK") {
        let tempArray = [...liste];
        tempArray = tempArray.filter((item) => item._id !== contact_id);
        setListe(tempArray);
      } else {
        setMessageErreur("Erreur lors de la suppresssion du contact");
        setError(reponse);
      }
    });
  };

  return (
    <div>
      <div style={{ margin: "20px 0px" }}>
        <InputRecherche
          placeholder="Nom prénom, N°RPPS, établissement"
          onChange={handleTexteRechercheChange}
          onEffacer={handleTexteRechercheEffacer}
          autoFocus
          id="input_recherche"
        />

        <LoaderHorizontal isLoading={isLoading} />
        <MessageErreur message={messageErreur} error={error} />
        <AffichageConditionnel open={!is_medecin_traitant}>
          <SpecialitePicker
            style={{ marginTop: "20px" }}
            onSelect={handleSelectSpecialite}
            valueCode={codeSpecialite}
          />
        </AffichageConditionnel>
      </div>

      <div>
        <h4>
          {texteRecherche
            ? pluriel(liste.length, "contact trouvé", "contacts trouvés")
            : "Utilisés fréquemment"}
        </h4>

        <div id="resultats">
          <MessageAucuneDonnee message={message} conseil={conseil} />

          {liste.length >= 1 && (
            <List>
              {liste.map((contact) => (
                <LigneResultat
                  key={contact._id}
                  onClick={() => handleClick(contact)}
                >
                  <ObjetContact contact={contact} />

                  {auth.payload.owned_by_organisation ===
                  contact.owned_by_organisation ? (
                    <ListItemSecondaryAction>
                      <BoutonRond
                        iconeComposant={<DeleteIcon />}
                        couleurIcone="rouge"
                        tooltip="Supprimer le contact"
                        id={"bouton_supprimer_" + contact.nom}
                        onClick={() => handleSupprimerContact(contact._id)}
                      />
                    </ListItemSecondaryAction>
                  ) : (
                    ""
                  )}
                </LigneResultat>
              ))}
            </List>
          )}
        </div>
      </div>
    </div>
  );
}
