import React from "react";
import MonStyle from "./ObjetTypeRdv.module.css";

export default function ObjetTypeRdv({ couleur, libelle, duree, id, style }) {
  return (
    <div className={MonStyle.ObjetTypeRdv} style={style}>
      <div className={MonStyle.Icone}>
        <div
          style={{
            width: "24px",
            height: "24px",
            backgroundColor: couleur,
            borderRadius: "4px",
          }}
        ></div>
      </div>
      <div className={MonStyle.Libelle}>{libelle}</div>
      <div className={MonStyle.Duree}>{duree}</div>
    </div>
  );
}
