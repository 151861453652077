import React, { useContext, useEffect, useState } from "react";
import { Document, Page, Text, View, StyleSheet } from "@react-pdf/renderer";
import { StylesDocuments } from "../StylesDocumentsPdf";
import {
  DateToString,
  getAllDateBetweenDates,
} from "../../functions/FonctionsDateHeure";
import DocumentPdf from "../DocumentPdf";
import LoaderGlobal from "../../Design/Progression/LoaderGlobal";
import PopupMessageErreur from "../../Design/Messages/PopupMessageErreur";
import { CabinetsContext } from "../../Data/CabinetContext";
import { formatterTelephone } from "../../functions/Formatter";

export default function DocumentJournalDeCaisse({
  listeReglements,
  filtreModeReglement,
  dateDebut,
  dateFin,
  open,
  onClose,
  autoPrint,
}) {
  //NES Gestion du style
  const Styles = StyleSheet.create(StylesDocuments);

  //NES gestion du contexte
  const { getObjetCabinet } = useContext(CabinetsContext);

  //NES gestion du state
  const [data, setData] = useState();
  const [messageErreur, setMessageErreur] = useState();
  const [cabinet, setCabinet] = useState();
  const [listeModesReglements, setListeModesReglements] = useState([]);
  const [totaux, setTotaux] = useState({});

  //USEEFFECT chargement des données du cabinet
  useEffect(() => {
    if (open) {
      let monCabinet = getObjetCabinet();
      setCabinet(monCabinet);
    }
    // eslint-disable-next-line
  }, [open]);

  //USEEFFECT chargement des règlements à afficher
  useEffect(() => {
    //NES récupération des dates entre la date de début et la date de fin
    if (dateDebut && dateFin && listeReglements) {
      //NES Récupération de toutes les dates entre la date de début et la date de fin
      let datesAffiches = getAllDateBetweenDates(dateDebut, dateFin);
      //NES construction des colonnes de mode de reglements
      let modesReglements = [
        { code: "CARTE_BANCAIRE", libelle: "Carte bancaire" },
        { code: "CHEQUE", libelle: "Chèque" },
        { code: "ESPECES", libelle: "Espèces" },
        { code: "VIREMENT", libelle: "Virement" },
      ];
      setListeModesReglements(modesReglements);
      let colonnes = {};
      modesReglements.forEach((mode) => {
        colonnes = { ...colonnes, [mode.code]: 0 };
      });
      //NES construction de la grille d'affichage
      let tempData = [];
      datesAffiches.forEach((element) => {
        let newLigne = {
          jour: DateToString(element, {
            weekday: "narrow",
          }),
          date: DateToString(element, {
            year: "numeric",
            month: "numeric",
            day: "numeric",
          }),
          ...colonnes,
        };
        tempData.push(newLigne);
      });

      //NES construction des totaux
      let tempTotaux = colonnes;

      //NES pour chaque reglement je vais l'ajouter à la bonne colonne du bon jour, tout un programme
      listeReglements.forEach((reglement) => {
        let dateReglement = DateToString(reglement.date, {
          year: "numeric",
          month: "numeric",
          day: "numeric",
        });

        //NES le mmontant du reglement
        let montantReglement = reglement.montant;

        //NES mise à jour de la ligne
        let index = tempData.findIndex((item) => item.date === dateReglement);
        if (index > -1) {
          let tempLigne = tempData[index];
          let montantPrecedent = tempLigne[reglement.code_mode_reglement];
          let montantRecalcule = montantPrecedent + montantReglement;
          tempLigne = {
            ...tempLigne,
            [reglement.code_mode_reglement]: montantRecalcule,
          };
          tempData.splice(index, 1, tempLigne);
        }

        //NES Mise à jour des totaux
        let totalPrecedent = tempTotaux[reglement.code_mode_reglement];
        let totalRecalcule = totalPrecedent + montantReglement;
        tempTotaux = {
          ...tempTotaux,
          [reglement.code_mode_reglement]: totalRecalcule,
        };
      });
      setTotaux(tempTotaux);

      setData(tempData);
    }
    // eslint-disable-next-line
  }, [listeReglements]);

  //NES fermeture de l'impression
  const handleClose = () => {
    //NES pour ne pas garder les donnée dans le cache sinon
    setCabinet();
    if (onClose) {
      onClose();
    }
  };

  const handlePopupMessageErreurClose = () => {
    setMessageErreur();
    handleClose();
  };

  if (messageErreur && open) {
    return (
      <PopupMessageErreur
        messageErreur={messageErreur}
        onClose={handlePopupMessageErreurClose}
      />
    );
  }

  if (open && (data === undefined || cabinet === undefined)) {
    return <LoaderGlobal isLoading={true} />;
  }

  return (
    <DocumentPdf
      openDocumentPdf={open}
      onClose={handleClose}
      autoPrint={autoPrint}
    >
      <Document>
        <Page size="A4" style={Styles.page}>
          <View>
            <div style={{ display: "flex", flexDirection: "row" }}>
              {/* BLOC CABINET*/}

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  flex: 1,
                  marginRight: "30px",
                }}
              >
                <Text style={Styles.titreBloc}>{cabinet?.nom}</Text>

                <Text>{cabinet?.adresse?.rue}</Text>

                <Text>
                  {cabinet?.adresse?.code_postal +
                    " " +
                    cabinet?.adresse?.ville}
                </Text>

                {cabinet?.telephone?.numero && (
                  <Text>
                    Tél : {formatterTelephone(cabinet?.telephone?.numero)}
                  </Text>
                )}
                {cabinet?.fax?.numero && (
                  <Text>Fax : {formatterTelephone(cabinet?.fax?.numero)}</Text>
                )}

                <Text>{cabinet?.email}</Text>

                <Text>{cabinet?.texte_horaire}</Text>
                <Text>{cabinet?.texte_rdv}</Text>
              </div>

              {/* BLOC TITRE*/}

              <div
                style={{
                  flex: 1,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    minHeight: "60px",
                  }}
                >
                  <Text style={Styles.titre}>JOURNAL </Text>
                  <Text style={Styles.titre}>DE CAISSE</Text>
                  <Text style={Styles.titreBloc}>{filtreModeReglement}</Text>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <Text>
                      {DateToString(dateDebut, {
                        year: "numeric",
                        month: "numeric",
                        day: "numeric",
                      }) + " "}
                    </Text>
                    {
                      <Text>
                        {dateFin !== dateDebut &&
                          " au " +
                            DateToString(dateFin, {
                              year: "numeric",
                              month: "numeric",
                              day: "numeric",
                            })}
                      </Text>
                    }
                  </div>
                </div>
              </div>
            </div>

            {/* BLOC ENTETES LIGNES*/}

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                height: "20px",
                backgroundColor: "#EEEEEE",
                padding: "0px 10px",
                marginTop: "20px",
              }}
            >
              <Text style={[Styles.titreColonneLeft, { width: "80px" }]}>
                DATE
              </Text>

              {listeModesReglements?.map((mode, index) => (
                <Text
                  style={[Styles.titreColonneRight, { flex: "1" }]}
                  key={index}
                >
                  {mode?.libelle?.toUpperCase()}
                </Text>
              ))}
            </div>

            {/* BLOC LIGNES*/}

            {data?.map((ligne) => (
              <View
                key={ligne.date}
                wrap={false}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-@between",
                  minHeight: "20px",
                  borderTop: "1px solid #D8D8D8",
                  padding: "0px 10px",
                  backgroundColor:
                    ligne.jour.toUpperCase() === "D" ||
                    ligne.jour.toUpperCase() === "S"
                      ? "#EEEEEE"
                      : "white",
                }}
              >
                <Text style={[Styles.normal, { minWidth: "20px" }]}>
                  {ligne.jour.toUpperCase() + " "}
                </Text>
                <Text
                  style={[Styles.informationSecondaire, { minWidth: "60px" }]}
                >
                  {ligne.date}
                </Text>

                {listeModesReglements.map((mode) => (
                  <Text
                    style={[Styles.normalRight, { flex: "1" }]}
                    key={mode.code}
                  >
                    {ligne[mode.code] !== 0 ? ligne[mode.code] + " €" : "-"}
                  </Text>
                ))}
              </View>
            ))}
          </View>

          {/* BLOC TOTAUX*/}

          <View
            wrap={false}
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              minHeight: "20px",
              borderTop: "1px solid black",
              borderBottom: "1px solid black",

              padding: "0px 10px",
            }}
          >
            <Text style={[Styles.informationPrincipale, { width: "80px" }]}>
              TOTAL
            </Text>
            {listeModesReglements.map((mode) => (
              <Text
                style={[Styles.informationPrincipaleRight, { flex: "1" }]}
                key={mode.code}
              >
                {totaux[mode.code]} €
              </Text>
            ))}
          </View>

          <View
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              fontSize: "8px",
              position: "absolute",
              bottom: "10mm",
              borderTop: "1px solid black",
              left: "10mm",
              paddingTop: "5px",
            }}
            fixed
          >
            <Text>
              {cabinet?.informations_juridiques?.nom_entite_juridique +
                ", " +
                cabinet?.informations_juridiques?.adresse_siege +
                ". Capital : " +
                cabinet?.informations_juridiques?.capital_social +
                " - N° SIRET : " +
                cabinet?.informations_juridiques?.numero_siret}
            </Text>
            <Text
              style={{
                fontSize: "8px",
                fontWeight: "bold",
              }}
              render={({ pageNumber, totalPages }) =>
                `Page ${pageNumber} / ${totalPages}`
              }
            />
          </View>
        </Page>
      </Document>
    </DocumentPdf>
  );
}
