import React, { useState } from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";
import EditIcon from "@material-ui/icons/Edit";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import Switch from "../../../../../Design/Formulaires/Switch";
import SchoolIcon from "@material-ui/icons/School";
import { Link } from "react-router-dom";
import { useContext } from "react";
import { UtilisateursContext } from "../../../../../Data/UtilisateursContext";
import { AuthentificationContext } from "../../../../../Data/AuthentificationContext";

export default function ActionsUtilisateur({
  isAdmin,
  onSupprimer,
  onProfil,
  id,
}) {
  //NES STYLE
  const useStyles = makeStyles((theme) => ({
    root: {
      width: "200px",
      padding: "0px 20px",
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
    },
  }));
  const classes = useStyles();

  //NES gestion du contexte
  const { patchUtilisateurs } = useContext(UtilisateursContext);
  const auth = useContext(AuthentificationContext);

  //NES gestion du state
  const [valueAdmin, setValueAdmin] = useState(isAdmin);

  const HandleChangeAdmin = (value, id) => {
    setValueAdmin(value);
    let newData = { is_admin: value };
    patchUtilisateurs(id, newData);
  };

  //NES lien modifier
  const linkEdtionUtilisateur = `/options/utilisateurs/${id}`;

  return (
    <div className={classes.root}>
      <List component="nav">
        <ListItem button component={Link} to={linkEdtionUtilisateur}>
          <ListItemIcon>
            <EditIcon />
          </ListItemIcon>
          <ListItemText primary="Modifier" />
        </ListItem>

        <ListItem button onClick={() => onProfil(id)}>
          <ListItemIcon>
            <SchoolIcon />
          </ListItemIcon>
          <ListItemText primary="Sélectionner profil" />
        </ListItem>

        <ListItem
          button
          onClick={() => onSupprimer(id)}
          disabled={id === auth?.payload?.utilisateur_id ? true : false}
          style={{
            color: id === auth?.payload?.utilisateur_id ? "#97989D" : "red",
          }}
        >
          <ListItemIcon>
            <DeleteForeverIcon
              style={{
                color: id === auth?.payload?.utilisateur_id ? "#97989D" : "red",
              }}
            />
          </ListItemIcon>
          <ListItemText primary="Supprimer" />
        </ListItem>
      </List>
      <Divider />
      <div style={{ margin: "10px 0px 20px 0px" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div>Administrateur</div>
          <Switch
            value={valueAdmin}
            onChange={(event) => HandleChangeAdmin(event, id)}
            id="switch_admin"
          />
        </div>
        <div className="InformationsObligatoires">
          Un administrateur, peut accéder au paramétrage, aux options, et à la
          facturation
        </div>
      </div>
    </div>
  );
}
