import React from "react";
import MonStyle from "./BlocRdvSupprime.module.css";
import { DateToString } from "../../../../../functions/FonctionsDateHeure";
import ThumbUpAltIcon from "@material-ui/icons/ThumbUpAlt";
import ThumbDownAltIcon from "@material-ui/icons/ThumbDownAlt";
import ObjetItem from "../../../../../Design/Objets/ObjetItem";
import { SvgIcon } from "@material-ui/core";

export default function BlocRdvSupprime({ annulation_rdv, onClick }) {
  let texte = "";
  let couleur = "";
  let icone = "";

  switch (annulation_rdv?.mode) {
    case "EXCUSE":
      texte = "Rendez-vous excusé";
      icone = ThumbUpAltIcon;
      couleur = "#0FA600";
      break;
    case "NON_HONORE":
      texte = "Rendez-vous non excusé";
      icone = ThumbDownAltIcon;
      couleur = "#F44336";

      break;
    case "EXCUSE_DERNIERE_MINUTE":
      texte = "Rdv excusé à la dernière minute";
      icone = ThumbDownAltIcon;
      couleur = "#FF9800";

      break;
    default:
      texte = "Rdv annulé";
      icone = undefined;
      couleur = "#FF9800";
  }

  if (!annulation_rdv?.mode) {
    return "";
  }

  return (
    <div
      className={MonStyle.BlocRdvSupprime}
      style={{ backgroundColor: couleur }}
      onClick={onClick}
    >
      <SvgIcon
        component={icone}
        style={{ color: "white" }}
        className="IconeObjet"
      />
      <ObjetItem
        principale={texte}
        couleurPrincipale="white"
        couleurTexte="white"
        isBold
        secondaire={"le " + DateToString(annulation_rdv?.date)}
      />
    </div>
  );
}
