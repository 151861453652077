import React from "react";
import AfficherPlus from "../../../../Design/DataDisplay/AfficherPlus";
import EditionDate from "../AffichageDate/EditionDate";

export default function EnteteBloc({
  titre,
  sousTitre,
  date,
  onDateChange,
  labelOpen,
  labelClose,
  id,
  ...props
}) {
  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div>
          <div className="Titre2">{titre}</div>
          <div className="SousTitre">{sousTitre}</div>
        </div>
        <div>
          <EditionDate date={date} onChange={onDateChange} />
        </div>
      </div>
      <AfficherPlus
        labelOpen={labelOpen ? labelOpen : "Afficher l'entête"}
        labelClose={labelClose ? labelClose : "Masquer l'entête"}
      >
        {props.children}
      </AfficherPlus>
    </div>
  );
}
