import React from "react";
//import Tooltip from "@material-ui/core/Tooltip";
//import { withStyles } from "@material-ui/core/styles";
import { styled } from "@mui/material/styles";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";

/*
const BlackTooltip = withStyles((theme) => ({
  tooltip: {
    background: "rgba(0, 0, 0, 0.95)",

    color: "white",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
  arrow: {
    color: "#000000",
  },
}))(Tooltip);
*/

const BlackTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));

export default function AppTooltip({ texte, placement, ...props }) {
  return (
    <BlackTooltip
      title={texte}
      placement={placement ? placement : "bottom"}
      arrow
    >
      {props.children}
    </BlackTooltip>
  );
}
