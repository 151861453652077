import React, { useState, useEffect } from "react";
import PeopleAltIcon from "@material-ui/icons/PeopleAlt";
import LockIcon from "@material-ui/icons/Lock";
import ThumbUpAltIcon from "@material-ui/icons/ThumbUpAlt";
import ThumbDownAltIcon from "@material-ui/icons/ThumbDownAlt";
import SvgIcon from "@material-ui/core/SvgIcon";

export function Tag({ texte, color, style }) {
  if (texte) {
    return (
      <div
        style={{
          borderRadius: "10px",
          border: color ? "1px solid " + color : "1 px solid #666",
          fontFamily: "Lato",
          fontSize: "12px",
          fontWeight: "500",
          color: color ? color : "#666",
          height: "16px",
          padding: "0px 10px 0px 6px",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          width: "fit-content",
          ...style,
        }}
      >
        {texte}
      </div>
    );
  } else {
    return "";
  }
}

export function TagRole({ code_role, onClick }) {
  let Style = {
    textAlign: "center",
    width: "100px",
    fontSize: "13px",
    fontWeight: 700,
    height: "24px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "4px",
  };

  let Texte = "";

  switch (code_role?.toUpperCase()) {
    case "MEDECIN":
      Style = { color: "#0FA600", backgroundColor: "#0FA60020", ...Style };
      Texte = "Médecin";
      break;
    case "SECRETAIRE":
      Style = { color: "#97989D", backgroundColor: "#97989D20", ...Style };
      Texte = "Secrétaire";
      break;
    case "PARTENAIRE":
      Style = { color: "white", backgroundColor: "#205A71", ...Style };
      Texte = "Partenaire";
      break;

    default:
      Style = { color: "white", backgroundColor: "black", ...Style };
      Texte = code_role;
  }

  if (!code_role) {
    return "";
  }

  return (
    <div style={Style} onClick={onClick} id={code_role}>
      {Texte}
    </div>
  );
}

export function TagPrive({ style }) {
  return (
    <div
      style={{
        ...style,
        background: "#FF9800",
        borderRadius: "10px",
        fontFamily: "Lato",
        fontSize: "12px",
        fontWeight: "700",
        color: "#FFFFFF",
        height: "18px",
        padding: "0px 10px 0px 6px",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
      }}
    >
      <LockIcon style={{ fontSize: 12, width: "16px", marginRight: "2px" }} />
      Privé
    </div>
  );
}

export function TagPartage({ style, listePartage }) {
  const [affichage, setAffichage] = useState("partagé");
  const [monStyle, setMonStyle] = useState({});

  useEffect(() => {
    if (listePartage && listePartage.length > 0) {
      setMonStyle({
        ...style,
        background: "#205A71",
        borderRadius: "10px",
        fontFamily: "Lato",
        fontSize: "12px",
        fontWeight: "700",
        color: "#FFFFFF",
        height: "18px",
        padding: "0px 10px 0px 6px",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        minWidth: "30px",
        justifyContent: "space-evenly",
      });
      setAffichage(listePartage.length);
    } else {
      setMonStyle({ ...style, display: "none" });
    }
  }, [listePartage, style]);

  return (
    <div style={monStyle}>
      <PeopleAltIcon
        style={{ fontSize: 14, width: "16px", marginRight: "4px" }}
      />
      {affichage}
    </div>
  );
}

export function TagProduit({ code_produit, prefixe, hide }) {
  const monStyle = {
    minWidth: "90px",
    background: "white",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "25px",
    borderRadius: "4px",
    color: "#213945",
    border: "1px solid",
    borderColor: "#213945",
    fontSize: "12px",
    padding: "0px 10px",
    width: "fit-content",
  };

  if (hide) {
    return "";
  }

  return (
    <div style={monStyle}>
      {prefixe} {code_produit}
    </div>
  );
}

export function TagRdv({ annulationRdv, style }) {
  let monTag = undefined;

  switch (annulationRdv?.mode) {
    case "NON_HONORE":
      monTag = {
        texte: "Non honoré",
        color: "#F44336",
        icone: ThumbDownAltIcon,
      };
      break;
    case "EXCUSE":
      monTag = {
        texte: "Excusé",
        color: "#19A400",
        icone: ThumbUpAltIcon,
      };
      break;
    case "EXCUSE_DERNIERE_MINUTE":
      monTag = {
        texte: "Excusé à la dernière minute",
        color: "#FF9800",
        icone: ThumbDownAltIcon,
      };
      break;

    default:
      monTag = undefined;
  }

  if (monTag) {
    return (
      <div
        style={{
          height: "20px",
          backgroundColor: monTag.color + "20",
          borderRadius: "20px",
          fontSize: "12px",
          color: monTag.color,
          display: "flex",
          alignItems: "center",
          width: "fit-content",
          padding: "0px 8px",
          ...style,
        }}
      >
        <SvgIcon
          component={monTag.icone}
          style={{ fontSize: "16px", marginRight: "5px" }}
        />
        {monTag.texte}
      </div>
    );
  } else {
    return "";
  }
}

export function TagPartPatient({ facture }) {
  if (facture?.is_avoir) {
    return "";
  }

  if (facture?.avoir_facture) {
    return (
      <div
        style={{
          backgroundColor: "#333",
          height: "25px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          color: "white",
          borderRadius: "20px",
          minWidth: "60px",
          fontSize: "13px",
          padding: "0px 10px ",
        }}
      >
        Annulée
      </div>
    );
  }

  if (facture?.part_patient?.montant_is_regle) {
    return (
      <div
        style={{
          backgroundColor: "#0FA600",
          height: "25px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          color: "white",
          borderRadius: "20px",
          minWidth: "60px",
          fontSize: "13px",
        }}
      >
        Payé
      </div>
    );
  } else {
    if (facture?.part_patient?.montant_regle) {
      return (
        <div
          style={{
            backgroundColor: "white",
            border: "1px solid #FF9800",
            height: "25px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "#FF9800",
            borderRadius: "20px",
            minWidth: "60px",
            padding: "0px 10px",
            fontSize: "13px",
          }}
        >
          {facture?.part_patient?.montant_regle +
            " / " +
            facture?.part_patient?.montant_a_regler +
            " €"}
        </div>
      );
    } else {
      return (
        <div
          style={{
            backgroundColor: "white",
            border: "1px solid #FF9800",
            height: "25px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "#FF9800",
            borderRadius: "20px",
            minWidth: "60px",
            padding: "0px 10px",
            fontSize: "13px",
          }}
        >
          A régler
        </div>
      );
    }
  }
}
