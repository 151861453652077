import React from "react";
import MonStyle from "./DataPicker.module.css";
import { useState } from "react";

export function DataPickerItem({ id, libelle, selected, onClick, ...props }) {
  return (
    <div
      onClick={onClick}
      className={
        selected ? MonStyle.DataPickerItemSelected : MonStyle.DataPickerItem
      }
      id={id}
    >
      {libelle}
    </div>
  );
}

export default function DataPicker({
  listeObjets,
  objetLibelle,
  objetIdSelected,
  objetId,
  onClick,
  ...props
}) {
  //NES gestion du state
  const [selected, setSelected] = useState(1);

  const handleClick = (objet, index) => {
    setSelected(index);
    if (onClick) {
      onClick(objet);
    }

    if (selected?.toString() === index?.toString()) {
      setSelected();
      if (onClick) {
        onClick();
      }
    } else {
      setSelected(index);
      if (onClick) {
        onClick(objet);
      }
    }
  };

  return (
    <div className={MonStyle.DataPicker}>
      {listeObjets?.map((objet, index) => (
        <DataPickerItem
          libelle={objet[objetLibelle]}
          id={index}
          onClick={() => handleClick(objet, index)}
          selected={index?.toString() === selected?.toString() ? true : false}
        />
      ))}
    </div>
  );
}
