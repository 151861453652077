import React, { useContext } from "react";
import MonStyle from "./SelectPrioriteDemande.module.css";
import ObjetItem from "../../../../Design/Objets/ObjetItem";
import { SupportContext } from "../../../../Data/SupportContext";

export default function SelectPrioriteDemande({ value, onChange, style }) {
  //NES gestion du contexte
  const { libellePrioriteDemande } = useContext(SupportContext);

  const onSelectPriorite = (type) => {
    if (onChange) {
      onChange(type);
    }
  };

  return (
    <div className={MonStyle.ListePriorite} style={style}>
      <div
        className={MonStyle.PrioriteDemande}
        style={{
          border: value === "FAIBLE" ? "2px solid #208AB0" : "",
        }}
        onClick={() => onSelectPriorite("FAIBLE")}
      >
        <ObjetItem
          isBold={true}
          couleurPrincipale={"#0FA600"}
          principale={libellePrioriteDemande.FAIBLE}
          secondaire="Vous pouvez utiliser l'application. La correction peut attendre un peu."
        />
      </div>
      <div
        className={MonStyle.PrioriteDemande}
        style={{
          border: value === "RAPIDE" ? "2px solid #208AB0" : "",
        }}
        onClick={() => onSelectPriorite("RAPIDE")}
      >
        <ObjetItem
          isBold={true}
          couleurPrincipale={"#FF9800"}
          principale={libellePrioriteDemande.RAPIDE}
          secondaire="Je peux utiliser l'application, mais j'ai besoin rapidement de la correction."
        />
      </div>
      <div
        className={MonStyle.PrioriteDemande}
        style={{
          border: value === "URGENT" ? "2px solid #208AB0" : "",
        }}
        onClick={() => onSelectPriorite("URGENT")}
      >
        <ObjetItem
          isBold={true}
          couleurPrincipale={"#F44336"}
          principale={libellePrioriteDemande.URGENT}
          secondaire="Je suis bloqué dans l'application, j'ai besoin de la correction en urgence."
        />
      </div>
    </div>
  );
}
