import React, { useState, useEffect } from "react";
import SurfaceBloc from "../../../../../Design/Surfaces/SurfaceBloc";
import MonStyle from "./BlocRappel.module.css";
import NotificationsIcon from "@material-ui/icons/Notifications";
import BoutonRond from "../../../../../Design/Boutons/BoutonRond";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import ObjetItem from "../../../../../Design/Objets/ObjetItem";
import NotificationsOffIcon from "@material-ui/icons/NotificationsOff";
import BadgeNombre from "../../../../../Design/DataDisplay/BadgeNombre";

export default function BlocRappel({
  onClick,
  emailConfirmation,
  telephoneConfirmation,
  badgeContent,
}) {
  //NES gestion du state
  const [texte, setTexte] = useState("");

  //USEEFFECT
  useEffect(() => {
    setTexte("");

    if (emailConfirmation) {
      setTexte("Email ");
    }

    if (telephoneConfirmation) {
      setTexte("Sms");
    }
    if (telephoneConfirmation !== "" && emailConfirmation !== "") {
      setTexte("Email + sms");
    }
  }, [emailConfirmation, telephoneConfirmation]);

  return (
    <SurfaceBloc className={MonStyle.BlocRappel} onClick={onClick}>
      <div className={MonStyle.BlocTexte}>
        <div className="IconeObjet">
          <BadgeNombre badgeContent={badgeContent}>
            {texte ? (
              <NotificationsIcon style={{ color: "black" }} />
            ) : (
              <NotificationsOffIcon style={{ color: "gray" }} />
            )}
          </BadgeNombre>
        </div>

        <ObjetItem
          principale={texte ? texte : "Pas de confirmation du rdv"}
          couleurPrincipale={texte ? "#000000" : "#AAA"}
          secondaire={texte ? "Confirmation du rendez-vous" : ""}
        />
      </div>

      <BoutonRond
        tooltip="Modifier le rappel"
        iconeComposant={<NavigateNextIcon />}
      />
    </SurfaceBloc>
  );
}
