import React, { useState } from "react";
import MonStyle from "./PopupEssaiTermine.module.css";
import SurfacePopUp from "../../../Design/Surfaces/SurfacePopUp";
import BoutonActionSecondaire from "../../../Design/Boutons/BoutonActionSecondaire";
import { capitalize } from "../../../functions/Formatter";
import BoutonActionPrincipale from "../../../Design/Boutons/BoutonActionPrincipale";
import AffichageConditionnel from "../../../Design/DataDisplay/AffichageConditionnel";
import InputRadio from "../../../Design/Formulaires/InputRadio";
import InputTexte from "../../../Design/Formulaires/InputTexte";
import EmailIcon from "@material-ui/icons/Email";
import { Button } from "@material-ui/core";
import PhoneIphoneIcon from "@material-ui/icons/PhoneIphone";
import IllustrationMerci from "../../../Images/Illustrations/Merci.png";
import { useContext } from "react";
import { AuthentificationContext } from "../../../Data/AuthentificationContext";
import BoutonAnnuler from "../../../Design/Boutons/BoutonAnnuler";
import PageCarteBancaire from "../../Facturation/Pages/PageCarteBancaire";

export default function PopupEssaiTermine({ dateFinPeriode, open }) {
  //NES gestion du contexte
  const auth = useContext(AuthentificationContext);

  //NES gestion du state
  const [etape, setEtape] = useState(0);
  const [codeChoix, setCodeChoix] = useState("");
  const [autreRaison, setAutreRaison] = useState("");

  ///Choix d'un motif
  const handleChoixMotif = (code) => {
    console.log(code);
    setCodeChoix(code);
    setEtape(2);
  };

  ///Quand jai saisie une autre raison
  const handleAutreRaison = () => {
    console.log(autreRaison);
    setEtape(2);
  };

  ///Quand on clique sur le bouton retour à l'accueil
  const handleRetourAccueil = () => {
    auth.Deconnexion();
  };

  /// Quand on clique sur inscription
  const handleInscription = () => {
    setEtape(3);
  };

  return (
    <SurfacePopUp
      open={open}
      disableCloseIcone
      titre="Période d'essai terminée"
      sousTitre={"depuis le " + dateFinPeriode}
    >
      <AffichageConditionnel open={etape === 0} className={MonStyle.Bloc}>
        <p>
          Bonjour, <br />
          La période d'essai est terminée. Pour continuer à utiliser{" "}
          {capitalize(process.env.REACT_APP_NOM)} vous devez renseigner vos
          informations de règlements.
        </p>

        <p>Si vous avez des questions, n'hésitez pas à nous contacter :</p>

        <Button
          startIcon={<EmailIcon />}
          href={"mailto:latelierdupixel@gmail.com"}
          style={{ textTransform: "lowercase" }}
        >
          latelierdupixel@gmail.com
        </Button>
        <Button
          startIcon={<PhoneIphoneIcon />}
          href={"tel:0768125725"}
          style={{ textTransform: "lowercase" }}
        >
          07 68 12 57 25
        </Button>

        <div className={MonStyle.BoutonsAction}>
          <BoutonActionSecondaire
            texte={"Je ne souhaite pas m'inscrire "}
            fullWidth
            taille="L"
            onClick={() => setEtape(1)}
          />
          <BoutonActionPrincipale
            texte={"Inscription " + capitalize(process.env.REACT_APP_NOM)}
            fullWidth
            taille="L"
            onClick={handleInscription}
          />
        </div>
      </AffichageConditionnel>
      <AffichageConditionnel open={etape === 1}>
        <h3>Nous sommes désolés, que vous nous quittiez.</h3>
        <p className="SousTitre">Pouvez vous nous précisez pourquoi ?</p>
        <InputRadio
          value={codeChoix}
          onChange={handleChoixMotif}
          values={[
            { code: "TEST", libelle: "Je voulais juste l'essayer" },
            {
              code: "FONCTIONNALITE",
              libelle: "Il me manque des fonctionnalités essentielles",
            },
            { code: "PASADAPTE", libelle: "Il n'est pas adapté à mon usage" },
            { code: "TARIF", libelle: "Il est trop cher" },
          ]}
        />
        <InputTexte
          multiline
          label="Autre raison"
          value={autreRaison}
          onChange={(e) => setAutreRaison(e.target.value)}
        />
        {autreRaison && (
          <BoutonActionPrincipale
            texte="Envoyer"
            fullWidth
            style={{ margin: "20px 0px" }}
            onClick={handleAutreRaison}
          />
        )}
      </AffichageConditionnel>
      <AffichageConditionnel open={etape === 2} className={MonStyle.Centre}>
        <img src={IllustrationMerci} alt="Merci" className="Illustration" />
        <h3>Merci pour votre retour !</h3>

        <p className="Signature">
          L'équipe {capitalize(process.env.REACT_APP_NOM)}{" "}
        </p>
        <BoutonActionPrincipale
          texte={"Quitter " + process.env.REACT_APP_NOM}
          fullWidth
          style={{ margin: "20px 0px" }}
          onClick={handleRetourAccueil}
        />
      </AffichageConditionnel>
      <AffichageConditionnel open={etape === 3}>
        <h3>Entrez votre carte bancaire</h3>
        <PageCarteBancaire />
        <BoutonAnnuler
          texte="Déconnexion"
          onClick={handleRetourAccueil}
          fullWidth
        />
      </AffichageConditionnel>
    </SurfacePopUp>
  );
}
