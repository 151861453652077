import React, { useContext, useEffect, useState } from "react";
import { Popover } from "@mui/material";
import EditionAllergieAntecedent from "./EditionAllergieAntecedent";
import { MedicalContext } from "../../../../../Data/MedicalContext";
import ObjetAntecedent from "../../../../../Design/Objets/ObjetAntecedent";
import SurfaceLigne from "../../../../../Design/Surfaces/SurfaceLigne";
import SurfaceTableau from "../../../../../Design/Surfaces/SurfaceTableau";
import BoutonTexte from "../../../../../Design/Boutons/BoutonTexte";

export default function ListeAntecedentsAllergies({
  patient_id,
  codeTypeAntecedent,
  style,
  titre,
  titreAjouter,
  titreEdition,
  className,
  id,
}) {
  //NES gestion du contexte
  const { antecedentsEtAllergies, getAntecedents } = useContext(MedicalContext);

  //NES POPOVER
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [antecedents, setAntecedents] = useState([]);
  const [dataEdited, setDataEdited] = useState();

  //NES récupération des allergies du patient
  useEffect(() => {
    setAntecedents(getAntecedents(patient_id, codeTypeAntecedent));
    // eslint-disable-next-line
  }, [antecedentsEtAllergies]);

  //NES Modification
  const handleClick = (e, data) => {
    setDataEdited(data);
    setAnchorEl(e.currentTarget);
  };

  //NES handleNouveau
  const handleNouveau = (event) => {
    setDataEdited();
    setAnchorEl(event.currentTarget);
  };

  //NES FERMETURE POPOVER
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div
      id={id}
      className={className}
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "20px",
        ...style,
      }}
    >
      <h5>{titre}</h5>
      <SurfaceTableau>
        {antecedents.map((data) => (
          <SurfaceLigne id={data._id}>
            <ObjetAntecedent
              antecedent={data}
              onClick={(e) => handleClick(e, data)}
              taille="XL"
            />
          </SurfaceLigne>
        ))}
      </SurfaceTableau>
      <BoutonTexte texte={titreAjouter} taille="L" onClick={handleNouveau} />
      <Popover
        open={anchorEl ? true : false}
        anchorEl={anchorEl}
        onClose={handleClose}
        //onClick={handleClose}
        anchorOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
      >
        <EditionAllergieAntecedent
          titre={titreEdition}
          onAnnuler={handleClose}
          patient_id={patient_id}
          onEnregistrer={handleClose}
          codeTypeAntecedent={codeTypeAntecedent}
          dataEdited={dataEdited}
        />
      </Popover>
    </div>
  );
}
