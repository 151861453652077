export function isTelephonePortable(numeroTelephone) {
  //NES je commence par nettoyer le n° de téléphone
  let telephone = numeroTelephone.replace(/[^0-9+]/gi, "");
  telephone = telephone.replace("+", "00");

  //NES cela va dépendre du pays
  let pays = getPaysTelephone(telephone);

  //NES découpage du numéro
  let deuxPremierChiffres = telephone.substring(0, 2);
  let cinqPremierChiffres = telephone.substring(0, 5);
  let sixPremierChiffres = telephone.substring(0, 6);
  let huitPremierChiffres = telephone.substring(0, 8);

  switch (pays) {
    case "FRANCE":
      if (
        deuxPremierChiffres === "06" ||
        deuxPremierChiffres === "07" ||
        cinqPremierChiffres === "00336" ||
        cinqPremierChiffres === "00337"
      ) {
        return true;
      }
      break;
    case "ALLEMAGNE":
      if (cinqPremierChiffres === "00491") return true;
      break;
    case "LUXEMBOURG":
      if (
        huitPremierChiffres === "00352621" ||
        huitPremierChiffres === "00352661" ||
        huitPremierChiffres === "00352691" ||
        huitPremierChiffres === "00352671" ||
        huitPremierChiffres === "00352651"
      )
        return true;
      break;
    case "BELGIQUE":
      if (sixPremierChiffres === "003245" || sixPremierChiffres === "003249")
        return true;
      break;
    case "ESPAGNE":
      if (cinqPremierChiffres === "00346") return true;
      break;
    case "ITALIE":
      if (cinqPremierChiffres === "00393") return true;
      break;
    case "SUISSE":
      if (
        sixPremierChiffres === "004175" ||
        sixPremierChiffres === "004176" ||
        sixPremierChiffres === "004177" ||
        sixPremierChiffres === "004178" ||
        sixPremierChiffres === "004179"
      )
        return true;
      break;
    case "ROYAUME-UNI":
      if (cinqPremierChiffres === "00447") return true;
      break;
    default:
      if (
        deuxPremierChiffres === "06" ||
        deuxPremierChiffres === "07" ||
        cinqPremierChiffres === "00336" ||
        cinqPremierChiffres === "00337"
      ) {
        return true;
      }
      break;
  }

  return false;
}

export function getPaysTelephone(numeroTelephone) {
  let pays = "";

  if (numeroTelephone === undefined) return "";

  let telephone = numeroTelephone.replace(/[^0-9+]/gi, "");
  telephone = telephone.replace("+", "00");
  let cindPremiersCaractere = telephone.substring(0, 5);
  let quatrePremiersCaractere = telephone.substring(0, 4);

  switch (cindPremiersCaractere) {
    case "00352":
      pays = "LUXEMBOURG";
      break;

    default:
      break;
  }

  switch (quatrePremiersCaractere) {
    case "0049":
      pays = "ALLEMAGNE";
      break;
    case "0032":
      pays = "BELGIQUE";
      break;
    case "0033":
      pays = "FRANCE";
      break;
    case "0034":
      pays = "ESPAGNE";
      break;
    case "0039":
      pays = "ITALIE";
      break;
    case "0041":
      pays = "SUISSE";
      break;
    case "0044":
      pays = "ROYAUME-UNI";
      break;

    default:
      break;
  }

  return pays;
}

export function isTelephone(numeroTelephone) {
  //NES je commence par nettoyer le n° de téléphone
  let telephone = numeroTelephone.replace(/[^0-9+]/gi, "");
  telephone = telephone.replace("+", "00");

  //NES cela va dépendre du pays
  let pays = getPaysTelephone(telephone);

  switch (pays) {
    case "FRANCE":
      if (telephone.length === 13) return true;
      break;
    case "ALLEMAGNE":
      if (telephone.length === 15) return true;
      break;
    case "LUXEMBOURG":
      if (telephone.length >= 10) return true;
      break;
    case "BELGIQUE":
      if (telephone.length === 13) return true;
      break;
    case "ESPAGNE":
      if (telephone.length === 13) return true;
      break;
    case "ITALIE":
      if (telephone.length === 14) return true;
      break;
    case "SUISSE":
      if (telephone.length === 13) return true;
      break;
    case "ROYAUME-UNI":
      if (telephone.length >= 5) return true;
      break;
    default:
      if (telephone.length === 10) return true;
      break;
  }

  return false;
}
