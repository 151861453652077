import React from "react";

export default function ObjetItem({
  principale,
  isBold,
  secondaire,
  ligne3,
  couleurTexte,
  couleurPrincipale,
  style,
  onClick,
  nbLigneSecondaires,
}) {
  let styleObjet = {
    minHeight: "37px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    paddingBottom: "3px",
    width: "100%",
    maxWidth: "100%",
    overFlow: "hidden",
    overflowWrap: "break-word",
    boxSizing: "border-box",
    ...style,
  };

  let styleLigneSecondaire = {};

  if (nbLigneSecondaires) {
    styleLigneSecondaire = {
      ...styleLigneSecondaire,
      display: "-webkit-box",
      WebkitLineClamp: nbLigneSecondaires,
      WebkitBoxOrient: "vertical",
      overflow: "hidden",
      textOverflow: "ellipsis",
    };
  }

  return (
    <div style={styleObjet} onClick={onClick}>
      <p
        style={{
          color: couleurPrincipale ? couleurPrincipale : couleurTexte,
          fontWeight: isBold ? "bolder" : "",
        }}
      >
        {principale}
      </p>
      {secondaire && (
        <small
          style={{
            color: couleurTexte,
            ...styleLigneSecondaire,
          }}
        >
          {secondaire}
        </small>
      )}
      {ligne3 && (
        <small
          className="InformationSecondaire"
          style={{ color: couleurPrincipale ? "" : couleurTexte }}
        >
          {ligne3}
        </small>
      )}
    </div>
  );
}
