import React, { useState } from "react";
import MonStyle from "./CarteBancaire.module.css";
import PuceCarteBancaire from "../../../../Images/PuceCarteBancaire";
import BoutonActionSecondaire from "../../../../Design/Boutons/BoutonActionSecondaire";
import BoutonConfirmerSuppression from "../../../../Design/Boutons/BoutonConfirmerSuppression";
import LoaderCirculaire from "../../../../Design/Progression/LoaderCirculaire";
import { useContext } from "react";
import { UtilisateursContext } from "../../../../Data/UtilisateursContext";
import MessageErreur from "../../../../Design/Messages/MessageErreur";

export default function CarteBancaire() {
  //NES Gestion du contexte
  const { paymentMethod, deletePaymentmethod } =
    useContext(UtilisateursContext);

  //NES Gestion du state
  const [afficherSuppression, setAfficherSuppression] = useState(false);
  const [messageErreur, setMessageErreur] = useState("");
  const [error, setError] = useState();

  ///HandleDelete mode de paiement
  const handleDeletePaiementMethod = () => {
    setAfficherSuppression(false);
    deletePaymentmethod().then((reponse) => {
      if (reponse.code !== "OK") {
        setMessageErreur("Erreur lors de la suppression de la carte bancaire");
        setError(reponse);
      }
    });
  };

  if (!paymentMethod) {
    return <LoaderCirculaire />;
  }

  return (
    <div className={MonStyle.CarteBancaire}>
      <div className={MonStyle.Recto}>
        <div className={MonStyle.PuceCarteBancaire}>
          <PuceCarteBancaire />
          <h4 style={{ color: "white", fontSize: "18px" }}>
            CARTE BANCAIRE DE PAIEMENT
          </h4>
        </div>
        <div className={MonStyle.Formulaire}>
          <div className={MonStyle.Numero}>
            <div>••••</div>
            <div>••••</div>
            <div>••••</div>
            <div>{paymentMethod?.card?.last4}</div>
          </div>

          <div className={MonStyle.DateExpiration}>
            <div className={MonStyle.LabelDate}>EXPIRE A FIN :</div>
            <div> {paymentMethod?.card?.exp_month}</div>/
            <div> {paymentMethod?.card?.exp_year}</div>
          </div>
          <div className={MonStyle.Nom}>
            {paymentMethod?.billing_details?.name}
          </div>
        </div>
      </div>

      <MessageErreur message={messageErreur} error={error} />
      {!afficherSuppression && (
        <BoutonActionSecondaire
          texte="Suppression de la carte bancaire"
          couleur="rouge"
          onClick={() => setAfficherSuppression(true)}
        />
      )}
      <BoutonConfirmerSuppression
        visible={afficherSuppression}
        variant="bloc"
        texte="Confirmez-vous la suppression de la carte bancaire ?"
        onAnnuler={() => setAfficherSuppression(false)}
        onSupprimer={handleDeletePaiementMethod}
      />
    </div>
  );
}
