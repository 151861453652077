import React, { useContext } from "react";
import { DateToString } from "../../../functions/FonctionsDateHeure";
import CapaciteJours from "./CapaciteJours";
import NumberPicker from "../../../Design/Pickers/NumberPicker";
import { AdpxContext } from "../../../Data/AdpxContext";
import LignesDemandes from "./LignesDemande";

export default function JourPlanning({ jour, style, onClickDemande }) {
  //NES mise à jour du state
  const { patchAdpxPlanningJours } = useContext(AdpxContext);

  const handleCapaciteChange = (qte) => {
    let newData = { capacite: qte };

    patchAdpxPlanningJours(jour._id, newData).then((reponse) => {
      console.log(reponse);
    });
  };

  return (
    <div style={style}>
      <header
        style={{
          justifyContent: "flex-start",
          gap: "20px",
          marginBottom: "10px",
        }}
      >
        <h3>{DateToString(jour.date)}</h3>
        <div>
          <NumberPicker onClick={handleCapaciteChange} disableEffacer>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "10px",
                alignItems: "center",
              }}
            >
              <CapaciteJours jour={jour} />
              <div
                style={{
                  paddingBottom: "4px",
                }}
              >
                {jour.capacite}
              </div>
            </div>
          </NumberPicker>
        </div>
      </header>

      <LignesDemandes jour={jour} />
    </div>
  );
}
