import React, { useEffect, useState, useContext, Fragment } from "react";
import SurfacePopUp from "../../Design/Surfaces/SurfacePopUp";
import BoutonActionPrincipale from "../../Design/Boutons/BoutonActionPrincipale";
import BoutonAnnuler from "../../Design/Boutons/BoutonAnnuler";
import { AuthentificationContext } from "../../Data/AuthentificationContext";
import ObjetUtilisateur from "../../Design/Objets/ObjetUtilisateur";
import { Divider } from "@material-ui/core";
import CheckBox from "../../Design/Formulaires/CheckBox";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { UtilisateursContext } from "../../Data/UtilisateursContext";

export default function PopupPartage({
  open,
  onClose,
  listePartage,
  onClickPartage,
}) {
  //NES gestion du state
  const [utilisateurs, setUtilisateurs] = useState([]);
  const [erreur, setErreur] = useState(false);
  const [partages, setPartage] = useState([]);

  //NES gestion du contexte
  const authContext = useContext(AuthentificationContext);
  const { getUtilisateurs } = useContext(UtilisateursContext);

  //NES récupération des utilisateurs
  useEffect(() => {
    if (open) {
      //NES récuparation des utilisateurs
      getUtilisateurs().then((reponseApi) => {
        console.log(reponseApi);
        if (reponseApi.code === "OK") {
          setErreur(false);
          //NES j'enlève l'utilisateur de la liste pour ne pas se retirer du partage ;)
          let listeFiltre = reponseApi.data.filter(
            (item) => item.utilisateur_id !== authContext.payload.utilisateur_id
          );
          setUtilisateurs(listeFiltre);
        } else {
          setErreur(true);
        }
      });
    }
    // eslint-disable-next-line
  }, [open]);

  useEffect(() => {
    if (listePartage && utilisateurs) {
      //NES si je n'ai pas d'utilisateur dans le partage c'est qu'il est partage avec tout le monde
      let tempListe = [];
      if (listePartage.length === 0) {
        tempListe = utilisateurs.map((item) => item.utilisateur_id);
      } else {
        tempListe = listePartage;
      }
      //NES j'enlève mon utilisateur de la liste
      tempListe = tempListe.filter(
        (item) => item.utilisateur_id !== authContext.payload.utilisateur_id
      );
      setPartage(tempListe);
    }
    // eslint-disable-next-line
  }, [listePartage, utilisateurs]);

  //NES quand on clique sur un utilisateur
  const handleClickUtilisateur = (utilisateur_id) => {
    console.log(utilisateur_id);
    let tempArray = [...partages];
    let index = tempArray.findIndex((item) => item === utilisateur_id);
    if (index > -1) {
      //NES je l'enlève du tableau
      tempArray.splice(index, 1);
    } else {
      //NES je l'ajoute au tableau
      tempArray.push(utilisateur_id);
    }
    setPartage(tempArray);
  };

  //NES quand on souhaite enregistrer
  const handlePartager = () => {
    let liste = [...partages];
    if (partages.length === utilisateurs.length) {
      //NES je partage à tout le monde
      liste = [];
    } else {
      //NES je pense à ajouter mon utilisateur
      liste.push(authContext.payload.utilisateur_id);
    }
    //NES on retourne la liste
    if (onClickPartage) {
      onClickPartage(liste);
    }
    //NES on ferme la popup
    onClose();
  };

  return (
    <SurfacePopUp open={open} onClose={onClose} titre="Partage">
      {erreur ? (
        <div
          style={{
            margin: "50px 0px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <p
            style={{
              color: "red",
              textAlign: "center",
              fontWeight: "700",
              fontFamily: "Lato",
              width: "80%",
            }}
          >
            Erreur lors de la récupération des utilisateurs
          </p>
          <p style={{ color: "#666" }}>Réessayez dans quelques minutes</p>
        </div>
      ) : (
        <div style={{ marginTop: "20px" }}>
          <List style={{ maxHeight: "430px", overflow: "scroll" }}>
            {utilisateurs.map((item) => (
              <Fragment>
                <ListItem
                  button
                  onClick={() => handleClickUtilisateur(item.utilisateur_id)}
                >
                  <ObjetUtilisateur
                    utilisateur={item}
                    style={{ width: "90%" }}
                  />
                  <CheckBox checked={partages.includes(item.utilisateur_id)} />
                </ListItem>
                <Divider variant="inset" component="li" />
              </Fragment>
            ))}
          </List>
          <BoutonActionPrincipale
            texte="Partager"
            fullWidth
            taille="L"
            style={{ margin: "10px 0px" }}
            onClick={handlePartager}
          />
        </div>
      )}
      <BoutonAnnuler fullWidth onClick={onClose} />
    </SurfacePopUp>
  );
}
