import React, { useContext, useState } from "react";
import BoutonFiltre from "../BoutonFiltre";
import { DocumentsContext } from "../../../Data/DocumentsContext";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import IconesDocuments from "../../Icones/IconesDocuments";

export default function FiltreTypeDocument({ style, onChange }) {
  //NES Gestion du contexte
  const { typesDocuments } = useContext(DocumentsContext);

  //NES gestion du state
  const [filtre, setFiltre] = useState("");

  //NES quand je clique sur un type de consultation
  const handleClicTypeDocument = (type) => {
    let monFiltre = "";
    if (type === filtre) {
      //NES c'est le même donc j'enlève le filtre
      monFiltre = "";
    } else {
      monFiltre = type;
    }
    setFiltre(monFiltre);
    if (onChange) {
      onChange(monFiltre);
    }
  };

  //NES Quand je clique sur effacer
  const handleEffacer = () => {
    setFiltre("");
    if (onChange) {
      onChange("");
    }
  };

  return (
    <BoutonFiltre
      texte="Type de document"
      libelleFiltre={filtre}
      onEffacer={handleEffacer}
      style={style}
    >
      <List>
        {typesDocuments.map((type) => (
          <ListItem
            button
            disableGutters
            divider
            key={type}
            selected={filtre === type}
            onClick={() => handleClicTypeDocument(type)}
          >
            <div
              style={{
                height: "30px",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <IconesDocuments className="Icone" nomIcone={type} />
              {type}
            </div>
          </ListItem>
        ))}
      </List>
    </BoutonFiltre>
  );
}
