import React, { useEffect, useContext, useState } from "react";
import MonStyle from "./PagePrestations.module.css";
import SurfacePage from "../../Design/Surfaces/SurfacePage";
import InputRecherche from "../../Design/Recherche/InputRecherche";
import { PrestationsContext } from "../../Data/PrestationsContext";
import MessageAucuneDonnee from "../../Design/Messages/MessageAucuneDonnee";
import LoaderHorizontal from "../../Design/Progression/LoaderHorizontal";
import MessageErreur from "../../Design/Messages/MessageErreur";
import PanneauPrestation from "./Components/PanneauPrestation";
import LigneResultat from "../../Design/Recherche/LigneResultat";
import ObjetItem from "../../Design/Objets/ObjetItem";
import ObjetPrix from "../../Design/Objets/ObjetPrix";
import { pluriel } from "../../functions/Formatter";
import BoutonActionSecondaire from "../../Design/Boutons/BoutonActionSecondaire";
import AffichageConditionnel from "../../Design/DataDisplay/AffichageConditionnel";
import { Link, useHistory } from "react-router-dom";
import MessageAucunePrestation from "./Components/MessageAucunePrestation";
import BoutonMenu from "../../Design/Navigation/BoutonMenu";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import List from "@material-ui/core/List";
import { ListItem } from "@material-ui/core";
import Edit from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import BoutonConfirmerSuppression from "../../Design/Boutons/BoutonConfirmerSuppression";

export default function PagePrestation({ match }) {
  const URL_ID = match.params.id;

  //NES Gestion du context
  const { prestations, getPrestations, deletePrestation } =
    useContext(PrestationsContext);

  //NES gestion du routage
  let history = useHistory();

  //NES Gestion du state
  const [messageErreur, setMessageErreur] = useState("");
  const [error, setError] = useState("");
  const [listePrestations, setListePrestations] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [texteRecherche, setTexteRecherche] = useState("");
  const [afficherPanneau, setAfficherPanneau] = useState(false);
  const [afficherSuppression, setAfficherSuppression] = useState();

  /// Fermeture du panneau
  const handlePanneauClose = () => {
    history.push("/prestations/");
  };

  //NES Nouvelle prestation
  const handleNewPrestation = () => {
    history.push("/prestations/new");
  };

  //USEEFFECT récupération des prestations à l'ouverture de la page
  useEffect(() => {
    getPrestations().then((reponse) => {
      setIsLoading(false);
      if (reponse.code === "OK") {
        setListePrestations(reponse.data);
      } else {
        setMessageErreur("Erreur lors de la récupération des prestations");
        setError(reponse);
      }
    });
    // eslint-disable-next-line
  }, []);

  //USEEFFECT quand on passe un id en paramètre
  useEffect(() => {
    if (URL_ID) {
      setAfficherPanneau(true);
    } else {
      setAfficherPanneau(false);
    }
  }, [URL_ID]);

  //USEEFFECT
  useEffect(() => {
    let liste = prestations;
    //NES je trie par ordre alphabtique
    liste.sort((a, b) => (a.nom > b.nom ? 1 : -1));
    //NES si j'ai une recherche je filtre les résultats
    if (texteRecherche) {
      liste = liste.filter((item) => {
        let chaineRecherche = item.nom + " " + item.description;
        let regExp = new RegExp(`${texteRecherche.replace(" ", "(.*)")}`, "i");
        let index = chaineRecherche.search(regExp);
        return index > -1 ? true : false;
      });
    }
    setListePrestations(liste);
  }, [prestations, texteRecherche]);

  ///Suppression
  const handleSuppression = (id) => {
    deletePrestation(id).then((reponse) => {
      if (reponse.code === "OK") {
        setAfficherSuppression(false);
      } else {
        setMessageErreur("Erreur lors de la suppression");
        setError(reponse);
      }
    });
  };

  return (
    <SurfacePage couleur="gris">
      <header className={MonStyle.TitreEtBouton}>
        <div>
          <h1>Prestations</h1>
          <p className="SousTitre">
            {pluriel(listePrestations.length, "prestation", "prestations", "F")}
          </p>
        </div>

        <div className={MonStyle.BoutonActionSecondaire}>
          <BoutonActionSecondaire
            texte="Nouvelle prestation"
            onClick={handleNewPrestation}
            fullWidth
            taille="L"
          />
        </div>
      </header>

      <div className={MonStyle.ZoneRecherche}>
        <div className={MonStyle.InputRecherche}>
          <InputRecherche
            withBorder
            placeholder="Rechercher"
            value={texteRecherche}
            onChange={(e) => setTexteRecherche(e.target.value)}
          />
        </div>
      </div>

      <div className={MonStyle.Content}>
        <LoaderHorizontal isLoading={isLoading} />
        <MessageErreur message={messageErreur} error={error} />
        <AffichageConditionnel
          open={listePrestations.length === 0 && !texteRecherche ? true : false}
        >
          <MessageAucunePrestation />
        </AffichageConditionnel>
        <AffichageConditionnel
          open={listePrestations.length === 0 && texteRecherche ? true : false}
        >
          <MessageAucuneDonnee
            message="Aucune prestation trouvée"
            conseil={
              texteRecherche ? "Essayer de modifier votre recherche" : ""
            }
          />
        </AffichageConditionnel>
        <List>
          {listePrestations.map((prestation) => (
            <LigneResultat
              key={prestation._id}
              link={"/prestations/" + prestation._id}
            >
              <div className={MonStyle.ColonneNom}>
                <ObjetItem
                  principale={prestation.nom}
                  secondaire={prestation.description}
                />
              </div>
              <div className={MonStyle.ColonnePrix}>
                <ObjetPrix prix={prestation.prix_vente_ttc} />
              </div>
              <ListItemSecondaryAction>
                {afficherSuppression === prestation._id ? (
                  <BoutonConfirmerSuppression
                    style={{ backgroundColor: "white" }}
                    onAnnuler={() => setAfficherSuppression()}
                    onSupprimer={() => handleSuppression(prestation._id)}
                  />
                ) : (
                  <BoutonMenu>
                    <ListItem
                      component={Link}
                      to={"/prestations/" + prestation._id}
                      style={{ color: "#666" }}
                    >
                      <Edit className="IconeMenu" /> Modifier
                    </ListItem>
                    <ListItem
                      style={{ color: "red" }}
                      onClick={() => setAfficherSuppression(prestation._id)}
                    >
                      <DeleteIcon
                        className="IconeMenu"
                        style={{ color: "red" }}
                      />
                      Supprimer
                    </ListItem>
                  </BoutonMenu>
                )}
              </ListItemSecondaryAction>
            </LigneResultat>
          ))}
        </List>
      </div>

      <PanneauPrestation
        open={afficherPanneau}
        prestationId={URL_ID}
        onClose={handlePanneauClose}
        onOpen={() => setAfficherPanneau(true)}
      />
    </SurfacePage>
  );
}
