import React, { useEffect, useState } from "react";
import MonStyle from "./PageAbonnement.module.css";
import LigneAbonnementSms from "../Components/LignesAbonnement/LigneAbonnementSms";
import LigneAbonnementUtilisateur from "../Components/LignesAbonnement/LigneAbonnementUtilisateur";
import PanneauDetailSms from "../Components/PanneauDetailSms/PanneauDetailSms";
import { useContext } from "react";
import { UtilisateursContext } from "../../../Data/UtilisateursContext";
import MessageErreur from "../../../Design/Messages/MessageErreur";
import { pluriel } from "../../../functions/Formatter";
import AffichageConditionnel from "../../../Design/DataDisplay/AffichageConditionnel";
import { DateToString } from "../../../functions/FonctionsDateHeure";
import BoutonTexte from "../../../Design/Boutons/BoutonTexte";

export default function PageAbonnement({ hideSms }) {
  //NES gestion du contexte
  const { getfacturationAbonnement } = useContext(UtilisateursContext);

  //NES Gestion du state
  const [utilisateurs, setUtilisateurs] = useState([]);
  const [sms, setSms] = useState([]);
  const [facture, setFacture] = useState({});
  const [open, setOpen] = useState(false);
  const [messageErreur, setMessageErreur] = useState("");
  const [error, setError] = useState();

  //USEEFFECT récupération des informations de l'abonnement
  useEffect(() => {
    getfacturationAbonnement().then((reponse) => {
      if (reponse.code === "OK") {
        setFacture(reponse.data);
        let tempListeUtilisateurs = [];
        let tempListeSms = [];
        let lignes = reponse.data.lignes;
        lignes.forEach((element) => {
          if (element.tarif.type_tarif === "UTILISATEUR") {
            tempListeUtilisateurs.push(element);
          }
          if (element.tarif.type_tarif === "SMS") {
            tempListeSms.push(element);
          }
        });
        setUtilisateurs(tempListeUtilisateurs);
        setSms(tempListeSms);
      } else {
        setError(reponse);
        setMessageErreur("Erreur lors de la récupération de l'abonnement");
      }
    });
    // eslint-disable-next-line
  }, []);

  if (messageErreur) {
    return <MessageErreur taille="XL" message={messageErreur} error={error} />;
  }

  return (
    <div>
      <section>
        <h3 style={{ marginBottom: "10px", marginTop: "40px" }}>
          {pluriel(utilisateurs.length, "utilisateur", "utilisateurs")}
        </h3>
        {utilisateurs.map((ligne, index) => (
          <LigneAbonnementUtilisateur ligne={ligne} key={index} />
        ))}
      </section>

      <AffichageConditionnel open={!hideSms}>
        <section>
          <h3 style={{ marginBottom: "10px" }}>
            Sms
            <span className="SousTitre" style={{ margin: "0px 10px" }}>
              du{" "}
              {DateToString(facture.periode_sms_date_debut, {
                year: "numeric",
                month: "numeric",
                day: "numeric",
              }) +
                " au " +
                DateToString(facture.periode_sms_date_fin, {
                  year: "numeric",
                  month: "numeric",
                  day: "numeric",
                })}
            </span>
            <BoutonTexte
              texte="Afficher le détail"
              onClick={() => setOpen(true)}
            />
          </h3>
          {sms.map((ligne, index) => (
            <LigneAbonnementSms
              onClickDetail={() => setOpen(true)}
              ligne={ligne}
              key={"sms" + index}
            />
          ))}
        </section>
        <section>
          <div className={MonStyle.Total}>
            <h3>Total</h3>
            <h3>{facture.total_montant_ttc} €</h3>
          </div>
        </section>
      </AffichageConditionnel>
      <PanneauDetailSms
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        dateDebut={facture.periode_sms_date_debut}
        dateFin={facture.periode_sms_date_fin}
      />
    </div>
  );
}
