import React from "react";
import PhoneIphoneIcon from "@material-ui/icons/PhoneIphone";
import DesktopWindowsIcon from "@material-ui/icons/DesktopWindows";
import TabletIcon from "@material-ui/icons/Tablet";
import HdIcon from "@material-ui/icons/Hd";
export default function IconesMateriel({ code, ...props }) {
  switch (code) {
    case "DESKTOP":
      return <DesktopWindowsIcon {...props} />;

    case "MOBILE":
      return <PhoneIphoneIcon {...props} />;

    case "TABLETTE":
      return <TabletIcon {...props} />;
    case "HD":
      return <HdIcon {...props} />;
    default:
      return <DesktopWindowsIcon {...props} />;
  }
}
