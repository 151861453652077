import React, { useState, createContext, useContext } from "react";
import { GET, PATCH, POST } from "../functions/FonctionsApi";
import { toApiDate } from "../functions/FonctionsDateHeure";
import { AuthentificationContext } from "./AuthentificationContext";

//NES Création du contexte
export const CotationsContext = createContext();

export const CotationsProvider = (props) => {
  //NES gestion du contexte
  const authContext = useContext(AuthentificationContext);

  //NES Gestion du state
  const [factures, setFactures] = useState([]);
  const [facturesARegler, setFacturesARegler] = useState([]);
  const [cotations, setCotations] = useState([]);
  const [cotationsAFacturer, setCotationsAFacturer] = useState([]);

  //POST patients/:id/consultations/:id/cotations
  const postCotation = async (patient_id, consultation_id, data) => {
    //NES j'appelle l'API
    const MonUrl = `${process.env.REACT_APP_URL_API_FACTURE}/patients/${patient_id}/consultations/${consultation_id}/cotations`;

    const reponseJson = await POST(MonUrl, authContext.token, data);

    if (reponseJson.code === "OK") {
      //NES je stocke dans le contexte
      let tempArray = [...cotations];
      tempArray.push(reponseJson.data);
      setCotations(tempArray);
    }

    //NES je retourne à ma fonction appelante le résultat
    return reponseJson;
  };

  //GET patients/:id/consultations/:id/cotations
  const getCotationsConsultation = async (patient_id, consultation_id) => {
    //NES j'appelle l'API
    const MonUrl = `${process.env.REACT_APP_URL_API_FACTURE}/patients/${patient_id}/consultations/${consultation_id}/cotations`;
    //NES pour détecter les appels en boucle
    console.log({ GET: MonUrl });
    const reponseApi = await fetch(MonUrl, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        token: authContext.token,
      },
    });
    //NES je convertis ce que je récupère en JSON
    const reponseJson = await reponseApi.json();
    //NES je retourne à ma fonction appelante le résultat
    return reponseJson;
  };

  //GET patients/:id/cotations?is_facture=0
  const getCotationsPatientNonFacture = async (patient_id) => {
    //NES j'appelle l'API
    const MonUrl = `${process.env.REACT_APP_URL_API_FACTURE}/patients/${patient_id}/cotations?is_facture=0`;

    let reponseJson = await GET(MonUrl, authContext.token);

    if (reponseJson.code === "OK") {
      //NES je récupère les cotations
      setCotations(reponseJson.data);
    }

    //NES je retourne à ma fonction appelante le résultat
    return reponseJson;
  };

  //PATCH patients/:id/consultations/:id/cotations/:id
  const patchCotation = async (
    patient_id,
    consultation_id,
    cotation_id,
    data
  ) => {
    //NES j'appelle l'API
    const MonUrl = `${process.env.REACT_APP_URL_API_FACTURE}/patients/${patient_id}/consultations/${consultation_id}/cotations/${cotation_id}`;

    let reponseJson = await PATCH(MonUrl, authContext.token, data);

    if (reponseJson.code === "OK") {
      //NES mise à jour du contexte
      let tempArray = [...cotations];
      let index = tempArray.findIndex((item) => item._id === cotation_id);
      tempArray.splice(index, 1, reponseJson.data);
      setCotations(tempArray);
    }

    //NES je retourne à ma fonction appelante le résultat
    return reponseJson;
  };

  //DELETE Cotation/:id
  const deleteCotation = async (patient_id, consultation_id, cotation_id) => {
    //NES j'appelle l'API
    const MonUrl = `${process.env.REACT_APP_URL_API_FACTURE}/patients/${patient_id}/consultations/${consultation_id}/cotations/${cotation_id}`;
    //NES pour détecter les appels en boucle
    console.log({ DELETE: MonUrl });
    const reponseApi = await fetch(MonUrl, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        token: authContext.token,
      },
    });
    //NES je convertis ce que je récupère en JSON
    const reponseJson = await reponseApi.json();
    //NES je mets à jour le contexte
    let tempArray = [...cotations];
    let index = tempArray.findIndex((item) => item._id === cotation_id);
    tempArray.splice(index, 1);
    setCotations(tempArray);

    //NES je retourne à ma fonction appelante le résultat
    return reponseJson;
  };

  //POST reglements
  const postReglement = async (arrayData) => {
    //NES j'appelle l'API
    const MonUrl = `${process.env.REACT_APP_URL_API_FACTURE}/reglements/`;
    //NES pour détecter les appels en boucle
    console.log({ POST: MonUrl });
    const reponseApi = await fetch(MonUrl, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        token: authContext.token,
      },
      body: JSON.stringify(arrayData),
    });
    //NES je convertis ce que je récupère en JSON
    const reponseJson = await reponseApi.json();
    //NES je mets à jour le contexte
    if (reponseJson.code === "OK") {
      //NES mise à jour de la facture pour avoir le nouveau montant à régler
      let tempArray = [...factures];
      let index = tempArray.findIndex(
        (item) => item._id === reponseJson.facture._id
      );
      tempArray.splice(index, 1, reponseJson.facture);
      setFactures(tempArray);
    }
    //NES je retourne à ma fonction appelante le résultat
    return reponseJson;
  };

  //GET reglements
  const getReglements = async (date_debut, date_fin) => {
    //NES j'appelle l'API
    let MonUrl = `${process.env.REACT_APP_URL_API_FACTURE}/reglements/`;
    //NES Si j'ai des paramètres de date
    if (date_debut && date_fin) {
      let paramDateDebut = toApiDate(date_debut);
      let paramDateFin = toApiDate(date_fin);
      let parametres = `?datedebut="${paramDateDebut}"&datefin="${paramDateFin}"`;
      MonUrl = `${process.env.REACT_APP_URL_API_FACTURE}/reglements${parametres}`;
    }
    //NES pour détecter les appels en boucle
    console.log({ GET: MonUrl });
    const reponseApi = await fetch(MonUrl, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        token: authContext.token,
      },
    });
    //NES je convertis ce que je récupère en JSON
    const reponseJson = await reponseApi.json();
    //NES je retourne à ma fonction appelante le résultat
    return reponseJson;
  };

  //GET factures/:id/reglements/
  const getReglementsByFacture = async (facture_id) => {
    //NES j'appelle l'API
    const MonUrl = `${process.env.REACT_APP_URL_API_FACTURE}/factures/${facture_id}/reglements`;
    //NES pour détecter les appels en boucle
    console.log({ GET: MonUrl });
    const reponseApi = await fetch(MonUrl, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        token: authContext.token,
      },
    });
    //NES je convertis ce que je récupère en JSON
    const reponseJson = await reponseApi.json();
    //NES je retourne à ma fonction appelante le résultat
    return reponseJson;
  };

  //DELETE reglements/:id
  const deleteReglement = async (reglement_id) => {
    //NES j'appelle l'API
    const MonUrl = `${process.env.REACT_APP_URL_API_FACTURE}/reglements/${reglement_id}`;
    //NES pour détecter les appels en boucle
    console.log({ DELETE: MonUrl });
    const reponseApi = await fetch(MonUrl, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        token: authContext.token,
      },
    });
    //NES je convertis ce que je récupère en JSON
    const reponseJson = await reponseApi.json();
    //NES Mise à jour du contexte
    if (reponseJson.code === "OK") {
      //NES je mets à jour les factures
      let tempArray = [...factures];
      let index = tempArray.findIndex(
        (item) => item._id === reponseJson.facture._id
      );
      tempArray.splice(index, 1, reponseJson.facture);
      setFactures(tempArray);
    }
    //NES je retourne à ma fonction appelante le résultat
    return reponseJson;
  };

  //GET /cotations?is_facture=0
  const getCotationsAFacturer = async () => {
    //NES j'appelle l'API
    const MonUrl = `${process.env.REACT_APP_URL_API_FACTURE}/cotations?is_facture=0`;
    //NES pour détecter les appels en boucle
    console.log({ GET: MonUrl });
    const reponseApi = await fetch(MonUrl, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        token: authContext.token,
      },
    });
    //NES je convertis ce que je récupère en JSON
    const reponseJson = await reponseApi.json();
    //NES Je stocke dans le contexte
    if (reponseJson.code === "OK") {
      setCotationsAFacturer(reponseJson.data);
    }

    //NES je retourne à ma fonction appelante le résultat
    return reponseJson;
  };

  //POST patients/:id/consultation/:id/facture
  const postFacture = async (patient_id, consultation_id, data) => {
    //NES j'appelle l'API
    const MonUrl = `${process.env.REACT_APP_URL_API_FACTURE}/patients/${patient_id}/consultations/${consultation_id}/factures`;
    //NES pour détecter les appels en boucle
    console.log({ POST: MonUrl });
    const reponseApi = await fetch(MonUrl, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        token: authContext.token,
      },
      body: JSON.stringify(data),
    });
    //NES je convertis ce que je récupère en JSON
    const reponseJson = await reponseApi.json();
    //NES je mets à jour le contexte
    if (reponseJson.code === "OK") {
      //NES Insertion de la facture dans le contexte
      let tempArray = [...factures];
      tempArray.push(reponseJson.data);
      setFactures(tempArray);
      //NES suppression des cotations
      let tempArrayCotations = [...cotations];
      let cotationsFactures = reponseJson.data.cotations;
      cotationsFactures.forEach((cotation) => {
        let index = tempArrayCotations.findIndex(
          (item) => item._id === cotation._id
        );
        tempArrayCotations.splice(index, 1);
      });
      setCotations(tempArrayCotations);
    }
    //NES je retourne à ma fonction appelante le résultat
    return reponseJson;
  };

  //GET /patients/:id/consultations/:id/factures
  const getFacturesByConsultation = async (patient_id, consultation_id) => {
    setFactures([]);

    //NES j'appelle l'API
    const MonUrl = `${process.env.REACT_APP_URL_API_FACTURE}/patients/${patient_id}/consultations/${consultation_id}/factures`;

    let reponseJson = await GET(MonUrl, authContext.token);

    //NES Je stocke dans le contexte
    if (reponseJson.code === "OK") {
      setFactures(reponseJson.data);
    }
    //NES je retourne à ma fonction appelante le résultat
    return reponseJson;
  };

  //GET factures/:id
  const getFactureById = async (facture_id) => {
    //NES j'appelle l'API
    const MonUrl = `${process.env.REACT_APP_URL_API_FACTURE}/factures/${facture_id}`;
    //NES pour détecter les appels en boucle
    console.log({ GET: MonUrl });
    const reponseApi = await fetch(MonUrl, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        token: authContext.token,
      },
    });
    //NES je convertis ce que je récupère en JSON
    const reponseJson = await reponseApi.json();

    //NES je retourne à ma fonction appelante le résultat
    return reponseJson;
  };

  //DELETE factures/:id
  const deleteFacture = async (facture_id) => {
    //NES j'appelle l'API
    const MonUrl = `${process.env.REACT_APP_URL_API_FACTURE}/factures/${facture_id}`;
    //NES pour détecter les appels en boucle
    console.log({ DELETE: MonUrl });
    const reponseApi = await fetch(MonUrl, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        token: authContext.token,
      },
    });
    //NES je convertis ce que je récupère en JSON
    const reponseJson = await reponseApi.json();
    //NES Mise à jour du contexte

    if (reponseJson.code === "OK") {
      console.log(reponseJson);

      let reponseFactures = reponseJson.factures;
      let reponseCotations = reponseJson.cotations;

      //NES mise à jour
      let tempArray = [...factures];
      reponseFactures.forEach((facture) => {
        let index = tempArray.findIndex((item) => item._id === facture._id);

        if (index > -1) {
          //NES à jour
          tempArray.splice(index, 1, facture);
        } else {
          //NES on insère
          tempArray.push(facture);
        }
      });
      setFactures(tempArray);

      //NES mise à cotations
      let tempArrayCotations = [...cotations];

      reponseCotations.forEach((cotation) => {
        let index = tempArrayCotations.findIndex(
          (item) => item._id === cotation._id
        );

        //NES On mets à jour
        tempArrayCotations.splice(index, 1, cotation);
      });
      setCotations(tempArrayCotations);
    }

    //NES je retourne à ma fonction appelante le résultat
    return reponseJson;
  };

  //GET factures/
  const getFactures = async (date_debut, date_fin) => {
    //NES j'appelle l'API
    let MonUrl = `${process.env.REACT_APP_URL_API_FACTURE}/factures`;
    //NES Si j'ai des paramètres de date
    if (date_debut && date_fin) {
      let paramDateDebut = toApiDate(date_debut);
      let paramDateFin = toApiDate(date_fin);
      let parametres = `?datedebut="${paramDateDebut}"&datefin="${paramDateFin}"`;
      MonUrl = MonUrl + parametres;
    }
    //NES pour détecter les appels en boucle
    console.log({ GET: MonUrl });
    const reponseApi = await fetch(MonUrl, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        token: authContext.token,
      },
    });
    //NES je convertis ce que je récupère en JSON
    const reponseJson = await reponseApi.json();
    //NES je retourne à ma fonction appelante le résultat
    return reponseJson;
  };

  //GET factures/
  const getFacturesARegler = async () => {
    //NES j'appelle l'API
    let MonUrl = `${process.env.REACT_APP_URL_API_FACTURE}/factures?is_regle=0`;
    //NES pour détecter les appels en boucle
    console.log({ GET: MonUrl });
    const reponseApi = await fetch(MonUrl, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        token: authContext.token,
      },
    });
    //NES je convertis ce que je récupère en JSON
    const reponseJson = await reponseApi.json();
    //NES je le stocke dans le state
    if (reponseJson.code === "OK") {
      setFacturesARegler(reponseJson.data);
    }
    //NES je retourne à ma fonction appelante le résultat
    return reponseJson;
  };

  //GET periodesControleFacturation/
  const getPeriodes = async () => {
    //NES j'appelle l'API
    let MonUrl = `${process.env.REACT_APP_URL_API_FACTURE}/periodesControleFacturation`;

    //NES pour détecter les appels en boucle
    console.log({ GET: MonUrl });
    const reponseApi = await fetch(MonUrl, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        token: authContext.token,
      },
    });
    //NES je convertis ce que je récupère en JSON
    const reponseJson = await reponseApi.json();
    //NES je retourne à ma fonction appelante le résultat
    return reponseJson;
  };

  //POST periodesControleFacturation?

  const postPeriode = async (date_debut, date_fin) => {
    //NES j'appelle l'API
    let MonUrl = `${process.env.REACT_APP_URL_API_FACTURE}/periodesControleFacturation`;
    //NES Si j'ai des paramètres de date
    if (date_debut && date_fin) {
      let paramDateDebut = toApiDate(date_debut);
      let paramDateFin = toApiDate(date_fin);
      let parametres = `?datedebut="${paramDateDebut}"&datefin="${paramDateFin}"`;
      MonUrl = MonUrl + parametres;
    }
    //NES pour détecter les appels en boucle
    console.log({ POST: MonUrl });
    const reponseApi = await fetch(MonUrl, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        token: authContext.token,
      },
    });
    //NES je convertis ce que je récupère en JSON
    const reponseJson = await reponseApi.json();
    //NES je mets à jour le contexte
    console.log({ reponseJson });
    //NES je retourne à ma fonction appelante le résultat
    return reponseJson;
  };

  return (
    <CotationsContext.Provider
      value={{
        cotations,
        postCotation,
        getCotationsConsultation,
        patchCotation,
        deleteCotation,
        postReglement,
        getReglements,
        getCotationsPatientNonFacture,
        getReglementsByFacture,
        deleteReglement,
        getCotationsAFacturer,
        factures,
        postFacture,
        getFacturesByConsultation,
        getFactureById,
        deleteFacture,
        getFactures,
        getPeriodes,
        postPeriode,
        getFacturesARegler,
        facturesARegler,
        cotationsAFacturer,
      }}
    >
      {props.children}
    </CotationsContext.Provider>
  );
};
