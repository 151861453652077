import React, { useEffect, useState } from "react";
import DatePicker from "../../Pickers/DatePicker";
import { DateToString } from "../../../functions/FonctionsDateHeure";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Popover from "@mui/material/Popover";
import SurfaceBloc from "../../Surfaces/SurfaceBloc";
import TodayIcon from "@material-ui/icons/Today";

const ColorButton = withStyles({
  root: {
    color: (props) => props.monstyle.color,
    border: "1px solid #DDD",
    fontFamily: "Lato",
    textTransform: "none",
    fontSize: "16px",
    height: "40px",
    padding: "0px 20px",
    //backgroundColor: purple[500],
    backgroundImage: (props) => props.monstyle.backgroundImage,
    "&:hover": {
      backgroundColor: "#F6F6F6",
    },
  },
})(Button);

export default function BoutonFiltreDate({
  name,
  id,
  label,
  style,
  value,
  onChange,
  onClick,
}) {
  //NES gestion dynamique du style
  const monTheme = {
    color: "#97989D",
    backgroundImage: "",
  };

  const monThemeFiltre = {
    backgroundImage: "linear-gradient(153deg, #205A71 5%, #4393AA 100%)",
    color: "white",
  };

  //NES gestion du state
  const [filtre, setFiltre] = useState();
  const [libelleFiltre, setLibelleFiltre] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [monStyle, setMonStyle] = useState(monTheme);

  //NES POPOVER
  const boutonActionClick = (event) => {
    setAnchorEl(event.currentTarget);
    if (onClick) {
      onClick();
    }
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  //USEEFFECT value
  useEffect(() => {
    if (value) {
      setFiltre(value);
    }
  }, [value]);

  //USEEFFECT libellé du filtre
  useEffect(() => {
    if (filtre) {
      let affichageDate = DateToString(value, {
        weekday: "short",
        day: "numeric",
        month: "short",
      });
      //NES par défaut on va afficher la date
      let affichage = affichageDate;
      //NES si j'ai un label on l'ajoute
      if (label) {
        affichage = label + " " + affichage;
      }
      setLibelleFiltre(affichage);
    }
    // eslint-disable-next-line
  }, [filtre]);

  //USEEFFECT theme
  useEffect(() => {
    if (filtre) {
      setMonStyle(monThemeFiltre);
    } else {
      setMonStyle(monTheme);
    }
    // eslint-disable-next-line
  }, [filtre]);

  //NES quand je change la date
  const handleDateChange = (date) => {
    setFiltre(date);
    setLibelleFiltre(DateToString(date));
    //NES je ferme la popover
    setAnchorEl();
    if (onChange) {
      onChange(date);
    }
  };

  return (
    <div style={style}>
      <ColorButton
        name={name}
        id={id}
        startIcon={<TodayIcon />}
        fullWidth
        onClick={boutonActionClick}
        monstyle={{ ...monStyle }}
      >
        {libelleFiltre}
      </ColorButton>
      <Popover
        open={anchorEl ? true : false}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
      >
        <SurfaceBloc style={{ width: "fit-content", padding: "10px 20px" }}>
          <DatePicker date={filtre} onDateSelect={handleDateChange} />
        </SurfaceBloc>
      </Popover>
    </div>
  );
}
