import React, { useContext, useEffect, useState } from "react";
import MonStyle from "./PanneauListeMesures.module.css";
import EditionMesure from "../AffichageMesure/EditionMesure";
import MessageErreur from "../../../../Design/Messages/MessageErreur";
import { ConstantesContext } from "../../../../Data/ConstantesContext";
import SurfacePanneau from "../../../../Design/Surfaces/SurfacePanneau";
import LoaderHorizontal from "../../../../Design/Progression/LoaderHorizontal";
import BoutonTexte from "../../../../Design/Boutons/BoutonTexte";

export default function PanneauListeMesures({
  consultation_id,
  patient_id,
  open,
  onClose,
  onOpen,
}) {
  //NES Gestion du contexte
  const { getMesures, isBookmarked } = useContext(ConstantesContext);

  //NES Gestion du state
  const [listeMesures, setListeMesures] = useState([]);
  const [erreur, setErreur] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  //USEEFFECT
  useEffect(() => {
    let active = true;

    if (open) {
      setIsLoading(true);
      getMesures().then((reponse) => {
        setIsLoading(false);
        if (reponse.code === "OK" && active) {
          let tempArray = reponse.data;
          setListeMesures(tempArray);
        } else {
          setErreur("Erreur lors de la récupération des mesures");
          setError(reponse);
        }
      });
    }

    return () => (active = false);
    // eslint-disable-next-line
  }, [open]);

  return (
    <SurfacePanneau
      open={open}
      titre={"Liste des Mesures"}
      onClose={onClose}
      onOpen={onOpen}
    >
      <div className={MonStyle.PanneauListeMesures}>
        <MessageErreur message={erreur} error={error} />
        <LoaderHorizontal isLoading={isLoading} />

        <div className={MonStyle.ListeMesures}>
          {listeMesures.map((mesure) => (
            <EditionMesure
              key={mesure._id}
              isBookmarked={isBookmarked(mesure._id)}
              mesure={mesure}
              consultation_id={consultation_id}
              patient_id={patient_id}
              fullWidth
            />
          ))}
        </div>
        <BoutonTexte
          texte="Ajouter une mesure"
          fullWidth
          taille="L"
          style={{ marginTop: "20px" }}
          link="/options/constantes/new"
        />
      </div>
    </SurfacePanneau>
  );
}
