import React, { useEffect, useState } from "react";
import BoutonActionPrincipale from "../../../../Design/Boutons/BoutonActionPrincipale";
import BoutonAnnuler from "../../../../Design/Boutons/BoutonAnnuler";
import SurfacePopUp from "../../../../Design/Surfaces/SurfacePopUp";
import InputTexte from "../../../../Design/Formulaires/InputTexte";

export default function PopupRenommerDemande({
  open,
  onClose,
  objetDemande,
  onEnregistrer,
}) {
  //NES gestion du state
  const [data, setData] = useState();

  useEffect(() => {
    if (objetDemande) {
      setData(objetDemande);
    }
  }, [objetDemande]);

  const handleEnregistrer = () => {
    if (onEnregistrer) {
      onEnregistrer(data);
    }
    if (onClose) {
      onClose();
    }
  };

  return (
    <SurfacePopUp open={open} onClose={onClose}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "400px",
          maxWidth: "90vw",
        }}
      >
        <h3>Modification</h3>
        <p className="SousTitre">
          L'objet de la demande, permet de savoir quel est le but de la demande
        </p>

        <InputTexte
          label="Objet de la demande"
          placeholder="ex: Problème de recherche d'un dossier"
          multiline
          value={data}
          onChange={(e) => setData(e.target.value)}
        />
        <BoutonActionPrincipale
          texte="Enregistrer"
          taille="L"
          style={{ marginTop: "20px" }}
          onClick={handleEnregistrer}
        />
        <BoutonAnnuler style={{ marginTop: "20px" }} />
      </div>
    </SurfacePopUp>
  );
}
