import React from "react";
import MonStyle from "./Actualites.module.css";
import IllustrationSite from "../../../Images/Actualites/IllustrationSite.png";
import IllustrationConsultationArchive from "../../../Images/Actualites/IllustrationConsultationArchive.png";
import IllustrationNouveauTypeConsultation4 from "../../../Images/Actualites/IllustrationNouveauTypeConsultation4.png";
import IllustrationAllergies from "../../../Images/Actualites/IllustrationAllergies.png";
import BoutonTexte from "../../../Design/Boutons/BoutonTexte";

export default function Actualites() {
  return (
    <div className={MonStyle.Actualite}>
      <div className={MonStyle.Liens}>
        <a
          className={MonStyle.Lien}
          href="https://shop.sinobi.fr"
          target="_blank"
          rel="noreferrer"
        >
          Découvrir Sinobi
        </a>
        <a
          className={MonStyle.Lien}
          href="https://shop.sinobi.fr/support"
          target="_blank"
          rel="noreferrer"
        >
          Support
        </a>
        <a
          className={MonStyle.Lien}
          href="https://shop.sinobi.fr/tarif"
          target="_blank"
          rel="noreferrer"
        >
          Tarifs
        </a>
        <a
          className={MonStyle.Lien}
          href="https://shop.sinobi.fr/contact"
          target="_blank"
          rel="noreferrer"
        >
          Nous contacter
        </a>
      </div>

      <section className={MonStyle.Article}>
        <h1>Redesign des allergies et antécédents</h1>
        <p>
          Il est maintenant possible de choisir la couleur pour les allergies et
          antécédents quand ils sont important. Nous avons profité de l'occasion
          pour améliorer la saisie des antécédents et allergies.
        </p>
        <p>
          De la même manière, ils sont regroupé par type (allergie, antécédent,
          antécédent familial...) dans la synthèse patient.
        </p>
        <img
          src={IllustrationAllergies}
          alt="Site sinobi"
          className={MonStyle.Illustration}
        />
      </section>

      <section>
        <a
          href="https://shop.sinobi.fr/support/ajouter-type-consultation"
          target="_blank"
          rel="noreferrer"
          className={MonStyle.Article}
        >
          {" "}
          <h1>Gestion des types de consultations</h1>
          <p>
            Il est maintenant possible de choisir comment afficher les
            consultations dans Sinobi. Pour chaque type de consultation vous
            pouvez choisir entre une consultation Energétique, une consultation
            de Santé ou Générique (saisie du motif et de texte)
          </p>
          <BoutonTexte>En savoir plus</BoutonTexte>
          <img
            src={IllustrationNouveauTypeConsultation4}
            alt="Site sinobi"
            className={MonStyle.Illustration}
          />
        </a>
      </section>

      <section>
        <a
          href="https://shop.sinobi.fr/support/afficher-consultation-archive"
          target="_blank"
          rel="noreferrer"
          className={MonStyle.Article}
        >
          <h1>Affichage des consultations archivées</h1>
          <p>
            Il est maintenant possible d'afficher les consultations archivées
            grâce un filtre dans l'historique des consultations. Il est même
            possible d'annuler l'archivage d'une consultation en cas d'erreur.
          </p>
          <BoutonTexte>En savoir plus</BoutonTexte>
          <img
            src={IllustrationConsultationArchive}
            alt="Site sinobi"
            className={MonStyle.Illustration}
          />
        </a>
      </section>

      <section>
        <a
          href="https://shop.sinobi.fr"
          target="_blank"
          rel="noreferrer"
          className={MonStyle.Article}
        >
          <h1>Site web Sinobi</h1>
          <h4>Ca y est le site officiel de Sinobi est en ligne!</h4>
          <h3>shop.sinobi.fr</h3>
          <img
            src={IllustrationSite}
            alt="Site sinobi"
            className={MonStyle.Illustration}
          />
        </a>
      </section>
    </div>
  );
}
