import React from "react";
import ObjetItem from "./ObjetItem";
import IconesCodeConsultation from "../Icones/IconesCodeConsultation";

export default function ObjetAffichagaCodeConsultation({
  code,
  couleur,
  onClick,
}) {
  let texte = "";
  let description = "";

  switch (code) {
    case "consultation":
      texte = "Santé";
      description =
        "Affichage des consultations générique pour la médecine classique";
      break;
    case "consultation_energetique":
      texte = "Energétique";
      description =
        "Affichage des consultations orienté pour la médecine énergétique";

      break;
    case "consultation_generique":
      texte = "Générique";
      description = "Affichage des consultations multi-métier";

      break;

    default:
      texte = "Affichage par défaut";
      description = "";
  }

  return (
    <div onClick={onClick} style={{ display: "flex", gap: "10px" }}>
      <IconesCodeConsultation code={code} style={{ width: "60px" }} />
      <ObjetItem
        principale={texte}
        secondaire={description}
        couleurPrincipale={couleur}
      />
    </div>
  );
}
