import React, { useState, useEffect } from "react";
import BoutonRond from "../Boutons/BoutonRond";
import DeleteIcon from "@material-ui/icons/Delete";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import MenuAction from "../Navigation/BoutonMenu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import LocalHospitalIcon from "@material-ui/icons/LocalHospital";
import { DateOuHeureToString } from "../../functions/FonctionsDateHeure";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import { isMobile } from "../../functions/Navigateur";
import { useContext } from "react";
import { PostitsPatientsContext } from "../../Data/PostitsPatientsContext";
import MessageErreur from "../Messages/MessageErreur";
import Fade from "@material-ui/core/Fade";

const monStyleTexte = {
  fontFamily: "Caveat",
  fontSize: "30px",
  fontWeight: 700,
  resize: "none",
  outline: "none",
  border: "none",
  backgroundColor: "#00000001",
  width: "80%",
  marginBottom: "10px",
  marginTop: "50px",
};

export default function Postit({ postit, id, name, onMasquer, index }) {
  //NES pour la position de la souris au clic
  let sourisStartLeft = 0;
  let sourisStartTop = 0;
  let postitStartLeft = 0;
  let postitStartTop = 0;
  let monPostIt = undefined;

  //NES Gestion du contexte
  const { calculMaxZindex, deletePostit, patchPostitContext } = useContext(
    PostitsPatientsContext
  );

  //NES Gestion du state
  const [data, setData] = useState({});
  const [prenomUtilisateur, setPrenomUtilisateur] = useState("");
  const [dateModification, setDateModification] = useState("");
  const [erreur, setErreur] = useState("");

  //USEEFFECT
  useEffect(() => {
    if (postit) {
      setData(postit);
      //NES gestion du prénom de l'utilisateur
      if (postit.tracabilite_modification) {
        setPrenomUtilisateur(
          postit.tracabilite_modification.prenom_utilisateur
        );
      } else {
        setPrenomUtilisateur(postit.tracabilite_creation.prenom_utilisateur);
      }
      //NES gestion de la date de modification
      if (postit.tracabilite_modification) {
        setDateModification(
          DateOuHeureToString(postit.tracabilite_modification.date)
        );
      } else {
        setDateModification(
          DateOuHeureToString(postit.tracabilite_creation.date)
        );
      }
    }
  }, [postit]);

  const handleChange = (key, value) => {
    let newData = { ...data, [key]: value };
    setData(newData);
    //NES je ne sauvegarde par le texte à chaque lettre mais sur le onBlur
    if (key !== "texte") {
      sauvegarder(newData);
    }
  };

  const handleBlur = (event) => {
    //NES attention au conflit et re-render si je le déplace tout de suite sans quitter le champ
    if (monPostIt === undefined) {
      sauvegarder(data);
    }
  };

  //PATCH Postit
  const sauvegarder = (newData) => {
    patchPostitContext(newData)
      .then((reponse) => {
        if (reponse.code !== "OK") {
          setErreur(reponse.erreur.message);
        }
      })
      .catch((error) => {
        setErreur("Erreur lors de l'enregistrement");
        console.log(error);
      });
  };

  //NES Sortie du post it
  const handleLeavePostit = () => {
    postitStopMove();
  };

  //NES déplacer le post-it
  const deplacerPostIt = (event) => {
    //NES calcul du déplacement
    let leftPx = postitStartLeft + event.clientX - sourisStartLeft;
    let topPx = postitStartTop + event.clientY - sourisStartTop;
    //NES mise à jour du style
    monPostIt.style.left = `${leftPx}px`;
    monPostIt.style.top = `${topPx}px`;
  };

  const postitMove = (event) => {
    //NES si je clique sur le post it
    let targetName = event.target.getAttribute("name");

    if (targetName === "postit") {
      monPostIt = event.target;
    }
    if (monPostIt !== undefined) {
      //NES changement de style pour bien voir que j'ai le postit
      monPostIt.style.border = `1px solid #208AB0`;
      monPostIt.style.boxShadow = "0 2px 20px 0 rgba(0, 0, 0, 0.5)";
      monPostIt.style.zIndex = calculMaxZindex(data.patient_id);
      //NES position de la souris quand j'ai cliqué
      sourisStartLeft = event.clientX;
      sourisStartTop = event.clientY;
      //NES position du postit au clic
      postitStartLeft = parseInt(monPostIt.style.left + 0);
      postitStartTop = parseInt(monPostIt.style.top + 0);
      //NES listener sur le déplacement de la souris
      window.addEventListener("mousemove", deplacerPostIt);
    }
  };

  const postitStopMove = (event) => {
    //NES arret du listener sur la souris
    window.removeEventListener("mousemove", deplacerPostIt);
    if (monPostIt) {
      //NES Mise à jour du style comme avant
      monPostIt.style.border = `none`;
      monPostIt.style.boxShadow = "0 2px 4px 0 rgba(0, 0, 0, 0.5)";
      //NES je retourne ma nouvelle position
      handleChange("position", {
        top: monPostIt.style.top,
        left: monPostIt.style.left,
        zindex: monPostIt.style.zIndex,
      });
      //NES on relache le postit
      monPostIt = undefined;
    }
  };

  const monStyle = {
    backgroundColor: data.is_medical ? "#CFF3CB" : "yellow",
    width: "300px",
    minHeight: "300px",
    position: "absolute",
    border: "none",
    zIndex: data.position ? data.position.zindex : 1,
    top: data.position ? data.position.top : 0,
    left: data.position && !isMobile() ? data.position.left : 40,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    transform: "rotate(-3deg)",
    boxShadow: "2px 4px 8px 0 rgba(0, 0, 0, 0.20)",
    cursor: "move",
  };

  //DELETE
  const handleDelete = () => {
    deletePostit(data.patient_id, data._id)
      .then((reponse) => {
        if (reponse.code === "OK") {
          //NES je ne fais rien ca se voit
        } else {
          setErreur("Erreur lors de la suppression");
        }
      })
      .catch((error) => {
        console.log(error);
        setErreur("Erreur lors de la suppression");
      });
  };

  return (
    <Fade in={true}>
      <div
        name="postit"
        style={{ ...monStyle }}
        onMouseLeave={handleLeavePostit}
        onMouseDown={postitMove}
        onMouseUp={postitStopMove}
        id={"postit_" + index}
      >
        <div
          style={{
            width: "40px",
            position: "absolute",
            top: "5px",
            left: "5px",
          }}
        >
          <BoutonRond
            iconeComposant={<VisibilityOffIcon />}
            tooltip="Masquer les notes"
            onClick={onMasquer}
            id={"masquer_" + name}
            couleurIcone="noir"
          />
        </div>
        <div
          style={{
            width: "40px",
            position: "absolute",
            top: "5px",
            right: "5px",
          }}
        >
          <div>
            <MenuAction
              tooltip="plus d'actions"
              id={"menu_postit_" + index}
              couleurIcone="noir"
            >
              <MenuItem
                onClick={() => handleChange("is_medical", !data.is_medical)}
              >
                <LocalHospitalIcon
                  style={{ marginRight: "15px", color: "green" }}
                />
                <ListItemText primary="Note médicale" />
              </MenuItem>
              <MenuItem style={{ color: "red" }} onClick={handleDelete}>
                <DeleteIcon style={{ marginRight: "15px" }} />
                <ListItemText primary="Supprimer" />
              </MenuItem>
            </MenuAction>
          </div>
        </div>
        {data.is_medical && (
          <div
            style={{
              position: "absolute",
              fontSize: "40px",
              top: "140px",
              transform: "rotate(-40deg)",
              color: "#34936120",
              cursor: "default",
            }}
          >
            MEDICAL
          </div>
        )}
        <TextareaAutosize
          id={"postit_" + index}
          style={monStyleTexte}
          placeholder="Saisir votre texte"
          onChange={(event) => handleChange("texte", event.target.value)}
          value={data.texte}
          onBlur={handleBlur}

          //NES PAS d'autofocus sinon renreder et c'est la merde dans un déplacement
          //autoFocus={!data.texte ? true : false}
        />
        <MessageErreur message={erreur} />
        <div
          style={{
            position: "absolute",
            bottom: "8px",
            fontSize: "11px",
            color: "#97989D",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            flexWrap: "wrap",
            width: "100%",
          }}
        >
          {data.is_medical && (
            <div
              style={{
                fontWeight: "700",
                fontSize: "13px",
                color: "#34936180",
              }}
            >
              (visible que par les praticiens)
            </div>
          )}
          par {prenomUtilisateur}, {dateModification}
        </div>
      </div>
    </Fade>
  );
}
