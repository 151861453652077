import React from "react";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";

const useStyles = makeStyles({
  root: {
    color: "#666666",
    border: "none",
    //boxShadow: "0px 2px 2px 0px rgba(0, 0, 0, 0.3)",
    //color: "white",
    padding: "0 20px",
    fontFamily: "Lato",
    fontWeight: "700",
    textTransform: "none",

    fontSize: (props) => props.fontSize,
    minHeight: (props) => props.height,
    "&:hover": {
      backgroundColor: (props) => props.backgroundOver,
    },
  },
  disabled: {
    backgroundColor: "red",
  },
});

export default function BoutonAnnuler({
  texte,
  couleur,
  taille,
  onClick,
  fullWidth,
  link,
  style,
}) {
  //NES gestion des taille S,M,L
  let height = "";
  let fontSize = "16px";
  switch (taille) {
    case "S":
      height = "30px";
      break;
    case "M":
      height = "40px";
      break;
    case "L":
      height = "50px";
      fontSize = "18px";
      break;
    default:
      height = "40px";
  }

  //NES Customisation des couleurs
  let background = "";
  let backgroundOver = "";
  switch (couleur) {
    case "blanc":
      background = "white";
      break;
    case "rouge":
      background = "#F44336";
      backgroundOver = "#CE382D";
      break;
    case "bleu":
      background = "#208AB0";
      backgroundOver = "#205A71";
      break;
    default:
      background = "none";
      backgroundOver = "#DDDDDD50";
  }

  //NES création du style
  let theme = {
    background: background,
    height: height,
    backgroundOver: backgroundOver,
    fontSize: fontSize,
  };
  const classes = useStyles(theme);
  const texteDefault = "Annuler";

  return (
    <Button
      style={style}
      className={classes.root}
      variant="outlined"
      size="large"
      onClick={onClick}
      fullWidth={fullWidth}
      name={texte ? texte : texteDefault}
      component={link ? Link : Button}
      to={link}
    >
      {texte ? texte : texteDefault}
    </Button>
  );
}
