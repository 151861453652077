import React, { useEffect } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import ObjetItem from "../Objets/ObjetItem";
import InputTexte from "../Formulaires/InputTexte";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import { useContext } from "react";
import { UtilisateursContext } from "../../Data/UtilisateursContext";

export default function InputAutocompleteAdresse({
  libelle,
  autoFocus,
  onBlur,
  label,
  placeholder,
  onInputChange,
  onAdresseSelect,
}) {
  //NES gestion du state
  const [value, setValue] = React.useState("");
  const [inputValue, setInputValue] = React.useState("");
  const [options, setOptions] = React.useState([]);

  //NES gestion du contexte
  const { monOrganisation } = useContext(UtilisateursContext);

  //NES initialisation de la valeur
  useEffect(() => {
    if (libelle) {
      setValue(libelle);
      setInputValue(libelle);
    }
  }, [libelle]);

  useEffect(() => {
    //NES Je recherche pas à chaque lettre sinon c'est trop souvent
    const timer = setTimeout(() => {
      if (inputValue !== "" && inputValue.length >= 5) {
        //NES Nettoyage des caractères
        let chaineRecheche = inputValue.replace("&", "");

        //NES Calcul de la latitude et de la longitude
        let lat = 49.3390242;
        let lon = 6.2137578;

        if (monOrganisation?.adresse?.coordinates?.length === 2) {
          lat = monOrganisation?.adresse?.coordinates[1];
          lon = monOrganisation?.adresse?.coordinates[0];
        }

        //const MonUrl = `${process.env.REACT_APP_URL_API_ADRESSE}/search/?q=${chaineRecheche}&limit=5&lat= 49.3390242&lon=6.2137578&type=housenumber&autocomplete=1`;
        const MonUrl = `${process.env.REACT_APP_URL_API_ADRESSE}/search/?q=${chaineRecheche}&limit=50&lat= ${lat}&lon=${lon}&type=housenumber&autocomplete=1`;

        fetch(MonUrl)
          .then((data) => data.json())

          .then((dataJson) => {
            let listeAdresse = dataJson.features;
            //NES je trie par distance
            listeAdresse.sort((a, b) => {
              return a.properties.distance - b.properties.distance;
            });
            //NES je limite à 5 car dans l'appel API cela ne marche pas bien
            listeAdresse = listeAdresse.slice(0, 5);
            setOptions((...options) => listeAdresse);
          });
      }
    }, 300);
    return () => clearTimeout(timer);
    // eslint-disable-next-line
  }, [inputValue]);

  const handleSelect = (option) => {
    console.log({ option });

    if (onAdresseSelect) {
      onAdresseSelect(option);
    }
  };

  const handleTexteChange = (event, texte) => {
    if (event) {
      event.target.value = texte;
      if (onInputChange) {
        onInputChange(event);
      }
    }
  };

  /// Afficher la distance ou pas
  const afficherDistance = (option) => {
    if (monOrganisation?.adresse?.coordinates?.length === 2) {
      return " -  " + Math.round(option.properties.distance / 1000) + "km";
    } else {
      return "";
    }
  };
  return (
    <Autocomplete
      getOptionLabel={(option) => {
        return option.properties ? option.properties.name : option;
      }}
      options={options}
      freeSolo
      filterOptions={(x) => x}
      disableClearable
      //autoHighlight
      value={value}
      inputValue={inputValue}
      onBlur={onBlur}
      onChange={(event, newValue) => {
        //setOptions(newValue ? [newValue, ...options] : options);
        handleSelect(newValue);
        setValue(newValue);
      }}
      onInputChange={(event, newInputValue) => {
        handleTexteChange(event, newInputValue);
        setInputValue(newInputValue);
      }}
      noOptionsText="Aucun résultat"
      renderInput={(params) => (
        <InputTexte
          {...params}
          label={label}
          placeholder={placeholder}
          fullWidth
          size="small"
          autoFocus={autoFocus}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon style={{ color: "#97989D", marginRight: "10px" }} />
              </InputAdornment>
            ),
          }}
        />
      )}
      renderOption={(option) => {
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <LocationOnIcon className="Icone" style={{ color: "#97989D50" }} />
            <ObjetItem
              principale={option.properties.name}
              secondaire={
                option.properties.postcode +
                " " +
                option.properties.city +
                afficherDistance(option)
              }
            />
          </div>
        );
      }}
    />
  );
}
