import React, { useContext, useState, useEffect } from "react";
import MonStyle from "./PanneauPrestation.module.css";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import BoutonRond from "../../../Design/Boutons/BoutonRond";
import CloseIcon from "@material-ui/icons/Close";
import InputTexte from "../../../Design/Formulaires/InputTexte";
import InputSelect from "../../../Design/Formulaires/InputSelect";
import InputNombres from "../../../Design/Formulaires/InputNombres";
import MessageErreur from "../../../Design/Messages/MessageErreur";
import BoutonActionPrincipale from "../../../Design/Boutons/BoutonActionPrincipale";
import BoutonAnnuler from "../../../Design/Boutons/BoutonAnnuler";
import { stringToDecimal } from "../../../functions/Formatter";
import { PrestationsContext } from "../../../Data/PrestationsContext";
import ObjetTracabilite from "../../../Design/Objets/ObjetTracabilite";
import LoaderHorizontal from "../../../Design/Progression/LoaderHorizontal";
import AfficherPlus from "../../../Design/DataDisplay/AfficherPlus";

const ModelePrestation = {
  nom: "",
  description: "",
  code_remboursement: "",
  taux_tva: 20.0,
  prix_vente_ht: 0.0,
  prix_vente_ttc: undefined,
};

export default function PanneauPrestation({
  open,
  onClose,
  onOpen,
  prestationId,
}) {
  //NES Gestion du contexte
  const {
    postPrestation,
    patchPrestation,
    deletePrestation,
    getPrestationByID,
  } = useContext(PrestationsContext);

  //NES Gestion du state
  const [data, setData] = useState(ModelePrestation);
  const [messageErreur, setMessageErreur] = useState();
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState(false);

  //USEEFFECT
  useEffect(() => {
    if (prestationId) {
      switch (prestationId) {
        case "new":
          setData(ModelePrestation);
          break;
        case "new-consultation":
          setData({ ...ModelePrestation, nom: "Consultation" });
          break;

        default:
          //NES récupération de la prestation
          setIsLoading(true);

          getPrestationByID(prestationId).then((reponse) => {
            setIsLoading(false);
            if (reponse.code === "OK") {
              setData(reponse.data);
            } else {
              setMessageErreur(
                "Erreur lors de la récupération de la prestation"
              );
              setError(reponse);
            }
          });
      }
    }
    // eslint-disable-next-line
  }, [prestationId]);

  ///handleChange
  const handleChange = (key, value) => {
    setData({ ...data, [key]: value });
  };

  /// cas du taux de TVA
  const handleTvaChange = (e) => {
    let stringTaux = e.target.value.replace(/[^0-9]/gi, "");
    let numberTaux = stringToDecimal(stringTaux);
    let prixHt = 0;
    if (data.prix_vente_ttc > 0) {
      prixHt = data.prix_vente_ttc / (1 + numberTaux / 100);
      prixHt = stringToDecimal(prixHt);
    }
    setData({ ...data, taux_tva: numberTaux, prix_vente_ht: prixHt });
  };

  /// cas du prix de vente
  const handleTarifChange = (e) => {
    let newTarif = stringToDecimal(e.target.value);
    let prixHt = 0;
    if (newTarif > 0) {
      prixHt = newTarif / (1 + data.taux_tva / 100);
      prixHt = stringToDecimal(prixHt);
    }
    setData({ ...data, prix_vente_ht: prixHt, prix_vente_ttc: newTarif });
  };

  /// Je séparer l'enregistrement pour ne gérer qu'une seule fois then catch
  const sauvegarder = async () => {
    if (data._id) {
      let reponse = await patchPrestation(data);
      return reponse;
    } else {
      let reponse = await postPrestation(data);
      return reponse;
    }
  };

  ///Enregistrer
  const handleEnregistrer = () => {
    sauvegarder()
      .then((reponse) => {
        if (reponse.code === "OK") {
          setMessageErreur();
          setError();
          //NES Je ferme le panneau
          if (onClose) {
            onClose();
          }
        } else {
          setMessageErreur(reponse.message);
        }
      })
      .catch((error) => {
        setMessageErreur("Erreur lors de l'enregistrement");
        setError(error);
      });
  };

  //DELETE
  const handleDelete = () => {
    deletePrestation(data._id)
      .then((reponse) => {
        if (reponse.code === "OK") {
          //NES Je ferme le panneau
          if (onClose) {
            onClose();
          }
        } else {
          setMessageErreur("Erreur lors de la suppression");
        }
      })
      .catch((error) => {
        setMessageErreur("Erreur lors de la suppression");
        setError(error);
      });
  };

  return (
    <SwipeableDrawer
      anchor="right"
      open={open}
      onClose={onClose}
      onOpen={onOpen}
    >
      <div className={MonStyle.Panneau}>
        <div className={MonStyle.BoutonTitre}>
          <BoutonRond
            iconeComposant={<CloseIcon />}
            style={{ marginRight: "10px" }}
            tooltip="Fermer"
            onClick={onClose}
            couleurIcone="noir"
          />
          <span className="Titre1">
            {data.nom ? data.nom : "Nouvelle prestation"}
          </span>
        </div>

        <LoaderHorizontal isLoading={isLoading} style={{ marginTop: "10px" }} />

        <div className={MonStyle.Formulaire}>
          <InputTexte
            label="Nom (Obligatoire)"
            value={data.nom}
            autoFocus={data.nom ? false : true}
            placeholder="ex : Consultation adulte"
            onChange={(e) => handleChange("nom", e.target.value)}
          />
          <InputTexte
            value={data.description}
            label="Description"
            onChange={(e) => handleChange("description", e.target.value)}
            multiline
          />

          <div className="Titre2" style={{ marginTop: "40px" }}>
            Tarif
          </div>

          <InputNombres
            value={data.prix_vente_ttc}
            label="Tarif TTC (obligatoire)"
            endAdornment="€"
            onChange={handleTarifChange}
            autoFocus={prestationId === "new-consultation" ? true : false}
          />
          <AfficherPlus labelClose="Masquer" labelOpen="Taux de TVA, prix HT">
            <InputSelect
              label="Taux de TVA "
              value={data.taux_tva + " %"}
              options={["20 %", "5 %", "0 %"]}
              onChange={handleTvaChange}
              style={{ marginTop: "0px" }}
            />
            <InputNombres
              label="Tarif HT"
              value={data.prix_vente_ht}
              disabled
              endAdornment="€"
            />
          </AfficherPlus>
          <MessageErreur
            message={messageErreur}
            error={error}
            style={{ marginTop: "40px" }}
          />
          <BoutonActionPrincipale
            texte="Enregistrer"
            fullWidth
            taille="L"
            style={{ margin: "40px 0px 20px" }}
            onClick={handleEnregistrer}
            disabled={data.nom === "" || data.prix_vente_ttc === ""}
          />
          <BoutonAnnuler
            fullWidth
            onClick={onClose}
            taille="L"
            style={{ marginBottom: "40px" }}
          />
        </div>

        <ObjetTracabilite
          tracabiliteCreation={data.tracabilite_creation}
          tracabiliteModification={data.tracabilite_modification}
          onDelete={handleDelete}
        />
      </div>
    </SwipeableDrawer>
  );
}
