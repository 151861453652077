import React from "react";
import ForumIcon from "@material-ui/icons/Forum";
import BugReportIcon from "@material-ui/icons/BugReport";
import ExploreIcon from "@material-ui/icons/Explore";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import PhoneIcon from "@material-ui/icons/Phone";
import EmojiObjectsIcon from "@material-ui/icons/EmojiObjects";
import ChatIcon from "@material-ui/icons/Chat";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import ThumbDownIcon from "@material-ui/icons/ThumbDown";

export default function IconesProgression({ code, ...props }) {
  switch (code) {
    case "SAISIE":
      return <ChatIcon style={{ color: "#205A71" }} {...props} />;
    case "AIDE":
      return <ForumIcon {...props} />;
    case "ETUDE":
      return <EmojiObjectsIcon {...props} />;
    case "CORRECTION":
      return <BugReportIcon style={{ color: "red" }} {...props} />;
    case "EVOLUTION":
      return <ExploreIcon {...props} />;
    case "LIVRAISON":
      return <LocalShippingIcon {...props} />;
    case "RDV":
      return <PhoneIcon {...props} />;
    case "CLOTURE":
      return <ThumbUpIcon {...props} />;
    case "IMPOSSIBLE":
      return <ThumbDownIcon {...props} />;
    default:
      return <ForumIcon {...props} />;
  }
}
