import React, { useContext } from "react";
import IconesCodeConsultation from "../Icones/IconesCodeConsultation";
import ObjetItem from "./ObjetItem";
import { AuthentificationContext } from "../../Data/AuthentificationContext";

export default function ObjetTypeConsultation({
  typeConsultation,
  taille,
  style,
}) {
  //NES Gestion du contexte
  const authentification = useContext(AuthentificationContext);

  if (taille === "S") {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          //borderLeft: "3px solid " + typeConsultation?.couleur,
        }}
      >
        <div
          id={"couleur_" + typeConsultation?.libelle}
          style={{
            display: "flex",
            borderLeft: "3px solid " + typeConsultation?.couleur,
            paddingLeft: "10px",
            paddingRight: "10px",
            width: "100%",
            marginLeft: "10px",
          }}
        >
          <ObjetItem principale={typeConsultation?.libelle} />
        </div>
        <IconesCodeConsultation
          code={typeConsultation?.code}
          style={{ width: "30px", height: "30px", marginRight: "10px" }}
        />
      </div>
    );
  }

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        //border: "1px solid red",
        ...style,
        //borderLeft: "3px solid " + typeConsultation?.couleur,
      }}
    >
      <div
        id={"couleur_" + typeConsultation?.libelle}
        style={{
          display: "flex",
          borderLeft: "3px solid " + typeConsultation?.couleur,
          paddingLeft: "10px",
        }}
      >
        <ObjetItem
          principale={typeConsultation?.libelle}
          secondaire={
            typeConsultation.owned_by_organisation !==
            authentification.payload.owned_by_organisation
              ? "Type de consultation générique " + process.env.REACT_APP_NOM
              : ""
          }
        />
      </div>
      <IconesCodeConsultation
        code={typeConsultation?.code}
        style={{ width: "50px", marginRight: "10px" }}
      />
    </div>
  );
}
