import React, { useState } from "react";
import MonStyle from "./CheckoutForm.module.css";
import {
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";
import BoutonActionPrincipale from "../../../../Design/Boutons/BoutonActionPrincipale";
import MessageErreur from "../../../../Design/Messages/MessageErreur";

const CheckoutForm = () => {
  const stripe = useStripe();
  const elements = useElements();

  const [errorMessage, setErrorMessage] = useState(null);
  const [error, setError] = useState();

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const { error } = await stripe.confirmSetup({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: document.location.href + "?action=confirmation",
      },
    });

    if (error) {
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Show error to your customer (for example, payment
      // details incomplete)
      console.log({ error });
      setErrorMessage(error.message);

      if (!error.code) {
        setError(error);
      }
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };

  return (
    <form onSubmit={handleSubmit} className={MonStyle.Form} id="formulaire">
      <PaymentElement />
      <MessageErreur
        message={errorMessage}
        error={error}
        style={{ marginTop: "30px" }}
      />
      <BoutonActionPrincipale
        texte="Enregistrer ma carte"
        fullWidth
        style={{ margin: "20px 0px" }}
        taille="L"
      />
    </form>
  );
};

export default CheckoutForm;
