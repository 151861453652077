import React from "react";
//import Badge from "@material-ui/core/Badge";
//import { withStyles } from "@material-ui/core/styles";
import Badge from "@mui/material/Badge";
import { styled } from "@mui/material/styles";

export default function BadgeConnected({ isConnected, ...props }) {
  /* const StyledBadge = withStyles((theme) => ({
    badge: {
      //right: -3,
      //top: 13,
      border: `1px solid #22343E`,
      minWidth: "8px",
      height: "8px",
      padding: "0px",
      width: "8px",
      backgroundColor: isConnected ? "#0FA600" : "red",
      fontWeight: 700,
      color: "white",
    },
  }))(Badge);
  */

  const StyledBadge = styled(Badge)(({ theme }) => ({
    "& .MuiBadge-badge": {
      border: `1px solid #22343E`,
      minWidth: "8px",
      height: "8px",
      padding: "0px",
      width: "8px",
      backgroundColor: isConnected ? "#0FA600" : "red",
      fontWeight: 700,
      color: "white",
    },
  }));

  return (
    <StyledBadge variant="dot" overlap="circular" {...props}>
      {props.children}
    </StyledBadge>
  );
}
