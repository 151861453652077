import React from "react";
import MonStyle from "./PageAdpxCloud.module.css";
import HelloApi from "../Components/HelloApi";

export default function PageAdpxCloud(params) {
  const LISTE_API = [
    {
      nom: "Utilisateurs",
      url: `${process.env.REACT_APP_URL_API_UTILISATEURS}`,
    },
    {
      nom: "Agenda",
      url: `${process.env.REACT_APP_URL_API_AGENDA}`,
    },
    {
      nom: "Patient",
      url: `${process.env.REACT_APP_URL_API_PATIENTS}`,
    },
    {
      nom: "Socket IO",
      url: `${process.env.REACT_APP_URL_SOCKETIO}`,
    },
    {
      nom: "Médical",
      url: `${process.env.REACT_APP_URL_API_MEDICAL}`,
    },
    {
      nom: "Drc",
      url: `${process.env.REACT_APP_URL_API_DRC}`,
    },
    {
      nom: "Documents",
      url: `${process.env.REACT_APP_URL_API_DOCUMENTS}`,
    },
    {
      nom: "Energetique",
      url: `${process.env.REACT_APP_URL_API_ENERGETIQUE}`,
    },
    {
      nom: "Messages",
      url: `${process.env.REACT_APP_URL_API_MESSAGES}`,
    },
    {
      nom: "Factures",
      url: `${process.env.REACT_APP_URL_API_FACTURE}`,
    },
    {
      nom: "Sesam Vitale",
      url: `${process.env.REACT_APP_URL_API_SESAMVITAL}`,
    },
  ];

  return (
    <div className={MonStyle.PageAdpxCloud}>
      <h1>Cloud</h1>
      <main className={MonStyle.ListeApi}>
        {LISTE_API.map((api) => (
          <HelloApi nom={api.nom} url={api.url} key={api.nom}/>
        ))}
      </main>
    </div>
  );
}
