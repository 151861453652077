import React, { useEffect, useState } from "react";
import InputTexte from "./InputTexte";

function formaterDate(date) {
  //NES date du jour
  let aujourdhui = new Date();

  //NES effacer les lettres
  date = date.replace(/[^0-9/]/gi, "");

  //NES découpage de la date
  let firstSlash = date.indexOf("/");
  let secondSlash = date.indexOf("/", firstSlash + 1);
  let jour = "";
  let mois = "";
  let annee = "";

  //NES recherche du jour
  if (firstSlash >= 0) {
    jour = date.substring(0, firstSlash);
    if (jour.length === 1) {
      jour = "0" + jour;
    }
  } else {
    if (date.length >= 2) {
      jour = date.substring(0, 2);
    }
  }

  //NES recherche du mois
  if (firstSlash >= 0 && secondSlash >= 0) {
    mois = date.substring(firstSlash + 1, secondSlash);
    if (mois.length === 1) {
      mois = "0" + mois;
    }
  } else {
    if (date.length >= 5) {
      mois = date.substring(firstSlash + 1, firstSlash + 1 + 2);
    }
  }

  //NES recherche de l'année
  if (secondSlash >= 0) {
    annee = date.substring(secondSlash + 1);
    if (annee.length === 2) {
      if (annee > aujourdhui.toLocaleString("fr-FR", { year: "2-digit" })) {
        annee = "19" + annee;
      } else {
        annee = "20" + annee;
      }
    }
  }
  //console.log({ firstSlash, secondSlash, jour, mois, annee });
  date = jour + "/" + mois + "/" + annee;
  return date;
}

const aideSaisieDate = (date) => {
  //NES je remplace les chiffres du haut pour les portables APPLE

  let texte = date;

  texte = texte.replace(/&/, "1");
  texte = texte.replace(/é/, "2");
  texte = texte.replace(/"/, "3");
  texte = texte.replace(/'/, "4");
  texte = texte.replace(/\(/, "5");
  texte = texte.replace(/§/, "6");
  texte = texte.replace(/è/, "7");
  texte = texte.replace(/!/, "8");
  texte = texte.replace(/ç/, "9");
  texte = texte.replace(/à/, "0");
  texte = texte.replace(/:/, "/");
  date = texte;

  //NES effacer les lettres
  date = date.replace(/[^0-9/]/gi, "");

  //NES découpage de la date
  let firstSlash = date.indexOf("/");
  let secondSlash = date.indexOf("/", firstSlash + 1);
  let jour = "";
  let mois = "";
  let annee = "";

  //NES ajout automatique du 1er /
  if (date.length === 3 && firstSlash === -1) {
    jour = date.substring(0, 2);
    mois = date.substring(2);
    date = jour + "/" + mois;
  }

  //NES ajout automatique du 2ème /
  if (date.length === 6 && secondSlash === -1) {
    jour = date.substring(0, 2);
    mois = date.substring(firstSlash + 1, firstSlash + 1 + 2);
    annee = date.substring(5);
    date = jour + "/" + mois + "/" + annee;
  }

  return date;
};

const verifierDate = (date) => {
  let message = "";
  let anneecourante = new Date();
  anneecourante = anneecourante.getFullYear();
  //NES découpage de la date
  let firstSlash = date.indexOf("/");
  let secondSlash = date.indexOf("/", firstSlash + 1);
  let jour = date.substring(0, firstSlash);
  let mois = date.substring(firstSlash + 1, secondSlash);
  let annee = date.substring(secondSlash + 1);
  //console.log({ firstSlash, secondSlash, jour, mois, annee });
  if (jour >= 31) {
    message = "Le jour semble faux";
  }
  if (mois > 12) {
    message = "Le mois semble faux";
  }
  if (annee > anneecourante) {
    message = "L'année semble fausse";
  }
  return message;
};

export default function InputDate({ value, label, onChange, onBlur }) {
  //NES gestion du state
  const [texte, setTexte] = useState("");
  const [isWarning, setIsWarning] = useState("");

  const handleChange = (event) => {
    let texte = event.target.value;
    texte = aideSaisieDate(texte);
    setTexte(texte);
    if (onChange) {
      event.target.value = texte;
      onChange(event);
    }
  };

  const handleBlur = (event) => {
    let texte = event.target.value;
    //NES je formate la date
    if (texte) {
      texte = formaterDate(texte);
    }
    event.target.value = texte;
    //NES je vérifie la date
    let message = verifierDate(texte);
    setIsWarning(message);
    setTexte(texte);

    console.log({ texte });
    console.log({ event: event.target.value });

    //NES Je retourne l'information
    if (onBlur) {
      onBlur(event);
    }
    if (onChange) {
      onChange(event);
    }
  };

  useEffect(() => {
    setTexte(value);
  }, [value]);

  return (
    <InputTexte
      isWarning={isWarning}
      value={texte}
      label={isWarning ? isWarning : label}
      onChange={handleChange}
      onBlur={handleBlur}
    />
  );
}
