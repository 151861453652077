import React, { useEffect, useState } from "react";
import MonStyle from "./ObjetTracabilite.module.css";
import BoutonConfirmerSuppression from "../../Design/Boutons/BoutonConfirmerSuppression";
import BoutonTexte from "../Boutons/BoutonTexte";
import AppTooltip from "../DataDisplay/ToolTip";
import BoutonRond from "../Boutons/BoutonRond";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";

const optionsDate = {
  weekday: "long",
  month: "long",
  day: "numeric",
  year: "numeric",
};

export default function ObjetTracabilite({
  tracabiliteCreation,
  tracabiliteModification,
  onDelete,
  taille,
  style,
  texteSupprimer,
  disableDelete,
  tooltip,
  idBoutonDelete,
}) {
  //NES Gestion du state
  const [libelle, setLibelle] = useState("");
  const [afficherSuppression, setAfficherSuppression] = useState(false);

  const handleSupprimer = () => {
    setAfficherSuppression(true);
  };

  const handleAnnuler = () => {
    setAfficherSuppression(false);
  };

  const handleDelete = () => {
    setAfficherSuppression(false);
    if (onDelete) {
      onDelete();
    }
  };

  useEffect(() => {
    if (tracabiliteModification) {
      //NES on renseigne l'utilisateur
      let strUtilisateur =
        tracabiliteModification.prenom_utilisateur +
        " " +
        tracabiliteModification.nom_utilisateur;
      //NES on renseigne la date
      let strDate = new Date(tracabiliteModification.date_tracabilite);
      strDate = strDate.toLocaleDateString("fr-FR", optionsDate);
      //NES on renseigne le libellé
      setLibelle("Modifié le " + strDate + " par " + strUtilisateur);
    } else {
      if (tracabiliteCreation) {
        //NES on renseigne l'utilisateur
        let strUtilisateur =
          tracabiliteCreation.prenom_utilisateur +
          " " +
          tracabiliteCreation.nom_utilisateur;
        //NES on renseigne la date
        let strDate = new Date(tracabiliteCreation.date_tracabilite);
        strDate = strDate.toLocaleDateString("fr-FR", optionsDate);
        //NES on renseigne le libellé
        setLibelle("Créé le " + strDate + " par " + strUtilisateur);
      } else {
        setLibelle("");
      }
    }
  }, [tracabiliteCreation, tracabiliteModification]);

  if (tracabiliteCreation || tracabiliteModification) {
    switch (taille) {
      case "S":
        return <cite style={{ style }}>{libelle} </cite>;
      case "M":
        return (
          <div>
            {afficherSuppression ? (
              <BoutonConfirmerSuppression
                onAnnuler={handleAnnuler}
                onSupprimer={handleDelete}
              />
            ) : (
              <div style={{ display: "flex", alignItems: "center" }}>
                <cite style={{ style }}>{libelle} </cite>
                <BoutonRond
                  id={idBoutonDelete}
                  onClick={handleSupprimer}
                  couleurIcone={"gris"}
                  iconeComposant={<DeleteForeverOutlinedIcon />}
                />
              </div>
            )}
          </div>
        );

      default:
        return (
          <div className={MonStyle.ObjetTracabilite} style={style}>
            {afficherSuppression ? (
              <BoutonConfirmerSuppression
                onAnnuler={handleAnnuler}
                onSupprimer={onDelete}
              />
            ) : (
              <div className={MonStyle.TexteEtSupprimer}>
                <cite style={{ style }}>{libelle} </cite>

                <AppTooltip texte={tooltip}>
                  <div>
                    <BoutonTexte
                      texte={texteSupprimer ? texteSupprimer : "Supprimer"}
                      couleur="rouge"
                      onClick={handleSupprimer}
                      disabled={disableDelete}
                    />{" "}
                  </div>
                </AppTooltip>
              </div>
            )}
          </div>
        );
    }
  } else {
    return "";
  }
}
