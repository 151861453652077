import React, { useContext } from "react";
import IconesProgression from "../../../Design/Icones/IconesProgression";
import { SupportContext } from "../../../Data/SupportContext";
import SurfaceObjetSelectionnable from "../../../Design/Surfaces/SurfaceObjetSelectionnable";
import { capitalize } from "../../../functions/Formatter";

export default function StepProgressionPicker({ onSelect, ...props }) {
  //NES Contexte
  const { ETAPES_SUPPORT } = useContext(SupportContext);

  const handleClick = (etape) => {
    console.log("handleClick");
    if (onSelect) {
      onSelect(etape);
    }
  };

  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "1fr 1fr 1fr",
        flexDirection: "row",
        flexWrap: "wrap",
        boxSizing: "border-box",
        gap: "10px",
        width: "100%",
      }}
    >
      {ETAPES_SUPPORT.map((etape) => (
        <SurfaceObjetSelectionnable
          style={{ maxWidth: "100px", flexDirection: "column" }}
          onClick={() => handleClick(etape)}
        >
          <IconesProgression code={etape.code} style={{ fontSize: "32px" }} />
          <div style={{ fontSize: "14px" }}> {capitalize(etape.texte)}</div>
        </SurfaceObjetSelectionnable>
      ))}
    </div>
  );
}
