import React, { useEffect, useState } from "react";
import InputTexte from "../Design/Formulaires/InputTexte";
import InputAutocompleteAdresseGov from "../Design/Recherche/InputAutocompleteAdressGov";
import InputPays from "../Design/Formulaires/InputPays";

export default function Adresse({ value, onChange, onBlur, onSelect }) {
  const objetAdresse = {
    rue: "",
    code_postal: "",
    ville: "",
    complement: "",
    pays: "",
    coordinates: {},
  };

  //NES gestion du state
  const [adresse, setAdresse] = useState(objetAdresse);

  useEffect(() => {
    if (value) {
      setAdresse(value);
    } else {
      setAdresse(objetAdresse);
    }
    // eslint-disable-next-line
  }, [value]);

  const handleChange = (prop) => (event) => {
    if (event) {
      let data = { ...adresse, [prop]: event.target.value };
      setAdresse(data);
      if (onChange) {
        onChange(data);
      }
    }
  };

  const handlePaysChange = (pays) => {
    let data = { ...adresse, pays: pays };
    setAdresse(data);
    if (onChange) {
      onChange(data);
    }
  };

  const handleSelectSuggestion = (objetAdresse) => {
    let tempadresse = {
      rue: objetAdresse.properties.name,
      code_postal: objetAdresse.properties.postcode,
      ville: objetAdresse.properties.city,
      complement: "",
      pays: "",
      coordinates: objetAdresse.geometry.coordinates,
    };

    setAdresse(tempadresse);
    if (onSelect) {
      onSelect(tempadresse);
    }
    if (onChange) {
      onChange(tempadresse);
    }
  };

  const handleBlur = (event) => {
    if (onBlur) {
      onBlur(adresse);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <InputAutocompleteAdresseGov
        label="Rue"
        onInputChange={handleChange("rue")}
        onAdresseSelect={handleSelectSuggestion}
        onBlur={handleBlur}
        libelle={adresse.rue}
      />
      <InputTexte
        label="Code postal"
        value={adresse.code_postal}
        onChange={handleChange("code_postal")}
        onBlur={handleBlur}
      />

      <InputTexte
        label="Ville"
        value={adresse.ville}
        onChange={handleChange("ville")}
        onBlur={handleBlur}
        textTransform="uppercase"
      />
      <InputTexte
        label="Complément"
        value={adresse.complement}
        onChange={handleChange("complement")}
        onBlur={handleBlur}
      />

      <InputPays
        value={adresse.pays}
        onTexteChange={handlePaysChange}
        onBlur={handleBlur}
      />
    </div>
  );
}
