import React, { useState, createContext, useContext } from "react";
import { AuthentificationContext } from "./AuthentificationContext";
import { AgendasContext } from "./AgendasContext";
import { NotificationsContext } from "./NotificationsContext";
import {
  DateToString,
  DateToHeure,
  toApiDate,
} from "../functions/FonctionsDateHeure";
import { CabinetsContext } from "./CabinetContext";
import { GET } from "../functions/FonctionsApi";

//NES Création du contexte
export const MessagesContext = createContext();

export const MessagesProvider = (props) => {
  //NES gestion du contexte
  const authContext = useContext(AuthentificationContext);
  const { getCabinetById, getObjetCabinet } = useContext(CabinetsContext);
  const { agendaById } = useContext(AgendasContext);
  const { ajouterNotification } = useContext(NotificationsContext);

  //NES gestion du state
  const [sms, setSms] = useState([]);

  //POST /patients/:id/sms
  const postSmsPatient = async (patient_id, data) => {
    //NES j'appelle l'API
    const MonUrl = `${process.env.REACT_APP_URL_API_MESSAGES}/patients/${patient_id}/sms`;
    //NES pour détecter les appels en boucle
    console.log({ POST: MonUrl });
    const reponseApi = await fetch(MonUrl, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        token: authContext.token,
      },
      body: JSON.stringify(data),
    });
    //NES je convertis ce que je récupère en JSON
    const reponseJson = await reponseApi.json();
    //NES je le garde dans le contexte
    if (reponseJson.code === "OK") {
      let tempArray = [...sms];
      tempArray.push(reponseJson.data);
      setSms(tempArray);
    }
    //NES je retourne à ma fonction appelante le résultat
    return reponseJson;
  };

  //GET /patients/:id/sms
  const getSmsPatient = async (patient_id) => {
    //NES est ce que j'ai déjà des sms du patient?
    let listeSmsPatient = sms.filter(
      (item) => item?.patient_destinataire.patient_id === patient_id
    );

    if (listeSmsPatient.length > 0) {
      return { code: "OK", data: listeSmsPatient };
    } else {
      //NES j'appelle l'API
      const MonUrl = `${process.env.REACT_APP_URL_API_MESSAGES}/patients/${patient_id}/sms`;
      //NES pour détecter les appels en boucle
      console.log({ GET: MonUrl });
      const reponseApi = await fetch(MonUrl, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          token: authContext.token,
        },
      });
      //NES je convertis ce que je récupère en JSON
      const reponseJson = await reponseApi.json();
      //NES Je conserve dans le contexte
      if (reponseJson.code === "OK" && reponseJson.data.length > 0) {
        setSms(reponseJson.data);
      }
      //NES je retourne à ma fonction appelante le résultat
      return reponseJson;
    }
  };

  //NES envoyer la confirmation du rdv par sms et email si nécessaire
  const envoyerEmailConfirmationRdv = async (email, type, rdv) => {
    let monAgenda = agendaById(rdv.agenda_id);

    postEmailRdv(type, rdv, monAgenda)
      .then((reponse) => {
        if (reponse.code === "OK") {
          if (type === "CONFIRMATION") {
            //NES j'affiche une notification pour confirmer l'envoi du mail.
            ajouterNotification({
              type: "EMAIL",
              titre: "Email de confirmation",
              sousTitre: email,
              texte: "Le rdv à été confirmé par email au patient",
            });
          }

          if (type === "ANNULATION") {
            //NES j'affiche une notification pour confirmer l'annulation du mail.
            ajouterNotification({
              type: "EMAIL",
              titre: "Email d'annulation",
              sousTitre: rdv?.patient?.patient_email,
              texte: "l'annulation du rdv à été confirmé par email au patient",
            });
          }
        }
      })

      .catch((error) => {
        console.log(error);
      });
  };

  //POST /email-rdv
  const postEmailRdv = async (type, rdv, agenda) => {
    let cabinetAgenda = await getCabinetById(agenda.cabinet_id);
    let texteLibre = "";

    //NES Le type de mail va changer le texte personnalisé email_texte_personnalise

    if (type === "CONFIRMATION") {
      texteLibre = agenda?.email?.email_texte_personnalise;
    } else {
      switch (rdv?.annulation_rdv?.mode) {
        case "EXCUSE":
          texteLibre = agenda?.email?.email_texte_personnalise_excuse;
          break;
        case "NON_HONORE":
          texteLibre = agenda?.email?.email_texte_personnalise_non_honore;
          break;
        case "EXCUSE_DERNIERE_MINUTE":
          texteLibre =
            agenda?.email?.email_texte_personnalise_excuse_derniere_minute;
          break;
        default:
          break;
      }
    }

    //NES Objet Data
    let data = {
      type: type,
      app_nom: process.env.REACT_APP_NOM,
      patient_destinataire: rdv.patient,
      rdv: {
        date_heure: rdv.date_heure,
        nom_medecin: agenda.nom_agenda,
      },
      adresse_email: rdv?.patient?.patient_email,
      email_texte_personnalise: texteLibre,
      cabinet: cabinetAgenda,
      pieces_jointes: agenda?.email?.pieces_jointes,
      code_annulation_rdv: rdv?.annulation_rdv?.mode,
    };
    //NES j'appelle l'API
    const MonUrl = `${process.env.REACT_APP_URL_API_MESSAGES}/email-rdv`;
    //NES pour détecter les appels en boucle
    console.log({ POST: MonUrl });
    const reponseApi = await fetch(MonUrl, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        token: authContext.token,
      },
      body: JSON.stringify(data),
    });
    //NES je convertis ce que je récupère en JSON
    const reponseJson = await reponseApi.json();
    //NES je retourne à ma fonction appelante le résultat
    return reponseJson;
  };

  //NES envoyer un sms de confirmation au patient
  const envoyerSmsConfirmationRdv = async (numeroPortable, type, rdv) => {
    let agenda = agendaById(rdv.agenda_id);

    let message = construireMessageSmsRdv(rdv, type, agenda);
    let code_type = "confirmation_rdv";

    let numeroPortableSansEspace = numeroPortable.replace(" ", "");

    if (type === "ANNULATION") {
      code_type = "annulation_rdv";
    }

    //NES Construction de l'objet pour envoyer le sms
    let data = {
      patient_destinataire: {
        patient_id: rdv.patient.patient_id,
        nom: rdv.patient.patient_nom,
        prenom: rdv.patient.patient_prenom,
        sexe: rdv.patient.patient_sexe,
        date_de_naissance: rdv.patient.patient_date_naissance,
      },
      numero_telephone: numeroPortableSansEspace,
      nom_expediteur_sms: "Sinobi",
      code_type: code_type,
      message: message,
      is_automatique: true,
      rdv_id: rdv._id,
    };

    //NES j'envoie le sms
    postSmsPatient(rdv.patient.patient_id, data)
      .then((reponse) => {
        if (reponse.code === "OK") {
          let titre_notification = "Sms confirmation rdv";
          if (type === "ANNULATION") {
            titre_notification = "Sms annulation rdv";
          }
          ajouterNotification({
            type: "SMS",
            titre: titre_notification,
            sousTitre:
              data?.patient_destinataire?.nom?.toUpperCase() +
              " " +
              data.patient_destinataire.prenom,
            texte: data.message,
          });
        } else {
          ajouterNotification({
            type: "ERREUR_SMS",
            titre: "Erreur d'envoi de sms",
            texte: "Le sms n'a pas pu être envoyé",
          });
        }
      })
      .catch((error) => {
        ajouterNotification({
          type: "ERREUR_SMS",
          titre: "Erreur d'envoi de sms",
          texte: "Le sms n'a pas pu être envoyé",
        });
      });
  };

  //NES permet de construire le message d'un sms
  const construireMessageSmsRdv = (rdv, type, agenda) => {
    let cabinet = getObjetCabinet();
    let adresse = "";
    if (cabinet.adresse) {
      adresse =
        cabinet.adresse.rue +
        " " +
        cabinet.adresse.code_postal +
        " " +
        cabinet.adresse.ville +
        ".";
    }
    //NES construction du message
    let dateRdv = DateToString(rdv.date_heure);
    let heureRdv = DateToHeure(rdv.date_heure);
    let message = `Bonjour, vous avez rdv le ${dateRdv} à ${heureRdv} avec ${agenda.nom_agenda}. ${adresse} ${agenda?.sms?.sms_texte_personnalise} NE PAS REPONDRE `;

    if (type === "ANNULATION") {
      switch (rdv?.annulation_rdv?.mode) {
        case "EXCUSE":
          message = `Bonjour, votre rdv du ${dateRdv} à ${heureRdv} avec ${agenda.nom_agenda} est annulé. ${agenda?.sms?.sms_texte_personnalise_excuse} NE PAS REPONDRE `;
          break;
        case "NON_HONORE":
          message = `Bonjour, votre rdv du ${dateRdv} à ${heureRdv} avec ${agenda.nom_agenda} a été manqué. ${agenda?.sms?.sms_texte_personnalise_non_honore} NE PAS REPONDRE `;

          break;
        case "EXCUSE_DERNIERE_MINUTE":
          message = `Bonjour, votre rdv du ${dateRdv} à ${heureRdv} avec ${agenda.nom_agenda} a été annulé. ${agenda?.sms?.sms_texte_personnalise_excuse_derniere_minute} NE PAS REPONDRE `;

          break;
        default:
          message = `Bonjour, votre rdv du ${dateRdv} à ${heureRdv} avec ${agenda.nom_agenda} est annulé. NE PAS REPONDRE `;

          break;
      }
    }
    return message;
  };

  //GET /sms?datedebut="2020-12-28"&datefin="2020-12-28"
  const getSms = async (dateDebut, dateFin) => {
    //NES mes paramètres d'appel
    let parametres = "";
    if (dateDebut && dateFin) {
      let paramDateDebut = toApiDate(dateDebut);
      let paramDateFin = toApiDate(dateFin);
      parametres = `?datedebut="${paramDateDebut}"&datefin="${paramDateFin}"`;
    }

    const MonUrl = `${process.env.REACT_APP_URL_API_MESSAGES}/sms${parametres}`;
    let reponseApi = GET(MonUrl, authContext.token);
    return reponseApi;
  };

  //GET rdv/:id/sms
  const getRdvSms = async (rdv_id) => {
    const MonUrl = `${process.env.REACT_APP_URL_API_MESSAGES}/rdv/${rdv_id}/sms`;
    let reponseApi = await GET(MonUrl, authContext.token);
    return reponseApi;
  };

  //GET sms/:id/statut
  const getSmsStatut = async (sms_id) => {
    const MonUrl = `${process.env.REACT_APP_URL_API_MESSAGES}/sms/${sms_id}/statut`;
    let reponseApi = await GET(MonUrl, authContext.token);
    return reponseApi;
  };
  return (
    <MessagesContext.Provider
      value={{
        sms,
        postSmsPatient,
        getSmsPatient,
        envoyerEmailConfirmationRdv,
        construireMessageSmsRdv,
        envoyerSmsConfirmationRdv,
        getSms,
        getRdvSms,
        getSmsStatut,
      }}
    >
      {props.children}
    </MessagesContext.Provider>
  );
};
