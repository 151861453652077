import React from "react";
import monStyle from "./PageJournalDesFactures.module.css";
import PageListePeriodes from "./PageListePeriodes";
import PageListeDesFactures from "./PageListeDesFactures";

export default function PageJournalDesFactures() {
  return (
    <div className={monStyle.PageJournalDesFactures}>
      <div className={monStyle.Periodes}>
        <PageListePeriodes />
      </div>
      <div className={monStyle.ListeFactures}>
        <PageListeDesFactures />
      </div>
    </div>
  );
}
