import React from "react";
import SurfacePage from "../../Design/Surfaces/SurfacePage";
import MonStyle from "./PageSupport.module.css";
import PageDemande from "./Pages/PageDemande";
import PageListeDemandes from "./Pages/PageListeDemandes";

export default function PageSupport({ match }) {
  const URL_DEMANDE_ID = match.params.id;

  return (
    <SurfacePage couleur="gris" disablePadding={true}>
      <div className={MonStyle.PageSupport}>
        <div className={MonStyle.PartieGauche}>
          <PageListeDemandes match={match} />
        </div>
        <main className={MonStyle.PartieDroite}>
          <PageDemande demande_id={URL_DEMANDE_ID} />
        </main>
      </div>
    </SurfacePage>
  );
}
