import React, { useState, useEffect, useContext } from "react";
import SurfacePopUp from "../../../../Design/Surfaces/SurfacePopUp";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { UtilisateursContext } from "../../../../Data/UtilisateursContext";
import CheckoutForm from "../Stripe/CheckoutForm";

export default function CorrectionProblementPaiement({ open, onClose }) {
  const stripePromise = loadStripe(process.env.REACT_APP_KEY_STRIPE);

  //NES gestion du state
  const [options, setOptions] = useState();

  //NES gestion du contexte
  const { monOrganisation } = useContext(UtilisateursContext);

  //USEEFFECT
  useEffect(() => {
    setOptions({
      // passing the client secret obtained in step 2
      clientSecret: monOrganisation?.stripe?.setup_intent_client_secret,
      // Fully customizable with appearance API.
      appearance: {
        /*...*/
      },
    });
    // eslint-disable-next-line
  }, [open]);

  return (
    <SurfacePopUp
      open={open}
      onClose={onClose}
      titre="Mise à jour de la carte de paiement"
    >
      <Elements stripe={stripePromise} options={options}>
        <CheckoutForm />
      </Elements>
    </SurfacePopUp>
  );
}
