import React, { useContext, useState, useEffect } from "react";
import SurfacePapier from "../../../../Design/Surfaces/SurfacePapier";
import MonStyle from "./EditionConsultation.module.css";
import EditionTypeConsultation from "./EditionTypeConsultation";
import { ConsultationsContext } from "../../../../Data/ConsultationsContext";
import EditionDateConsultation from "../AffichageDate/EditionDate";
import MessageErreur from "../../../../Design/Messages/MessageErreur";
import EditionMotifConsultation from "./EditionMotifConsultation";
import InputTexte from "../../../../Design/Formulaires/InputTexte";
import EditionMesuresConsultation from "./EditionMesuresConsultation";
import EditionDocuments from "./EditionDocuments";
import EditionHuitRegles from "./EditionHuitRegles";
import EditionLanguePouls from "./EditionLanguePouls";
import { PatientsContext } from "../../../../Data/PatientsContext";
import ObjetPatient from "../../../../Design/Objets/ObjetPatients";
import AffichageConditionnel from "../../../../Design/DataDisplay/AffichageConditionnel";
import MessageAlerte from "../../../../Design/Messages/MessageAlerte";

export default function EditionConsultation({ consultation }) {
  //NES gestion du context
  const { patchConsultationContext } = useContext(ConsultationsContext);
  const { getPatientContext } = useContext(PatientsContext);

  //NES Gestion du state
  const [message, setMessage] = useState("");
  const [messageErreur, setMessageErreur] = useState("");
  const [error, setError] = useState("");
  const [patient, setPatient] = useState();
  const [maConsultation, setMaConsultation] = useState({});

  //USEEFFECT
  useEffect(() => {
    if (consultation) {
      setMaConsultation(consultation);
    }
  }, [consultation]);

  //USEEFFECT
  useEffect(() => {
    let actif = true;

    if (consultation.patient_id) {
      getPatientContext(consultation.patient_id).then((reponse) => {
        if (actif) setPatient(reponse.data);
      });
    }

    return () => (actif = false);
    // eslint-disable-next-line
  }, [consultation.patient_id]);

  ///fonction pour déterminer si affiche une consultation énergétique
  const isConsultationEnergetique = () => {
    if (
      maConsultation?.type_consultation?.code === "consultation_energetique"
    ) {
      return true;
    }
    if (
      maConsultation?.type_consultation?.code === "" &&
      process.env.REACT_APP_NOM === "SINOBI"
    ) {
      return true;
    }
    return false;
  };

  ///fonction pour déterminer si affiche une consultation classique

  const isConsultationBe = () => {
    if (maConsultation?.type_consultation?.code === "consultation") {
      return true;
    }

    if (maConsultation?.type_consultation?.code === "therapie_breve") {
      return true;
    }

    if (
      maConsultation?.type_consultation?.code === "" &&
      process.env.REACT_APP_NOM === "BE"
    ) {
      return true;
    }
    return false;
  };

  /// modification de la consultation
  const handleChange = (key, value) => {
    let newData = { ...maConsultation, [key]: value };
    setMaConsultation(newData);
  };

  //NES quand je souhaite mettre à jour la consultation je ne met à jour que ma donnée
  const handleSauvegarder = (key, value) => {
    let newData = { [key]: value };
    setMessageErreur("");
    setMessage("");
    patchConsultationContext(
      maConsultation.patient_id,
      maConsultation._id,
      newData
    )
      .then((reponse) => {
        if (reponse.code !== "OK") {
          setMessageErreur(
            "Erreur lors de l'enregistrement de la consultation"
          );
          setError(reponse);
        } else {
          setMaConsultation(reponse.data);
          setMessage("Consultation enregistrée");
        }
      })
      .catch((error) => {
        console.log(error);
        setMessageErreur("Erreur lors de l'enregistrement de la consultation");
      });
  };

  return (
    <SurfacePapier>
      <MessageErreur
        message={messageErreur}
        style={{ marginBottom: "20px" }}
        error={error}
      />

      <div className={MonStyle.Entete}>
        <EditionTypeConsultation
          typeConsultation={maConsultation.type_consultation}
          tracabiliteCreation={maConsultation.tracabilite_creation}
          onChange={(type) => handleSauvegarder("type_consultation", type)}
        />
        <EditionDateConsultation
          date={maConsultation.date_consultation}
          onChange={(date) => handleSauvegarder("date_consultation", date)}
        />
      </div>
      {/************************ RAPPEL DU PATIENT ***********************/}
      <div className={MonStyle.RappelPatient}>
        <span className="TitreBloc"> </span>
        <ObjetPatient patient={patient} taille="S" isAfficherSexe />
      </div>

      {/************************ CONSULTATION SANTE ***********************/}
      {isConsultationBe() && (
        <div>
          <div className={MonStyle.Bloc}>
            <EditionMotifConsultation
              consultation={maConsultation}
              onChange={(motifs) => handleSauvegarder("motifs", motifs)}
            />
          </div>
          <div className={MonStyle.Examen}>
            <div className="TitreBloc">Interrogatoire & examen clinique</div>
            <InputTexte
              multiline
              placeholder="Saisir"
              theme="fondgris"
              taille="L"
              minRows={4}
              value={maConsultation.description}
              onChange={(e) => handleChange("description", e.target.value)}
              onBlur={(e) => handleSauvegarder("description", e.target.value)}
            />
          </div>
          <div className={MonStyle.Mesures}>
            <EditionMesuresConsultation
              consultation_id={maConsultation._id}
              patient_id={maConsultation.patient_id}
              consultation={maConsultation}
            />
          </div>
          <div className={MonStyle.Diagnostic}>
            <div className="TitreBloc">Diagnostic</div>
            <InputTexte
              multiline
              placeholder="Saisir"
              theme="fondgris"
              taille="L"
              value={maConsultation.diagnostic}
              onChange={(e) => handleChange("diagnostic", e.target.value)}
              onBlur={(e) => handleSauvegarder("diagnostic", e.target.value)}
            />
          </div>
        </div>
      )}
      {/************************ CONSULTATION ANAMNESE SINOBI ***********************/}

      {maConsultation?.type_consultation?.code === "anamnese" && (
        <div>
          <div className={MonStyle.Bloc}>
            <EditionMotifConsultation
              titre="Motif de consultation énergétique"
              consultation={maConsultation}
              onChange={(motifs) => handleSauvegarder("motifs", motifs)}
              disableSuggestions
            />
          </div>
          <div className={MonStyle.Bloc}>
            <div className="TitreBloc">Anamnèse</div>
            <InputTexte
              id="input_anamnese"
              multiline
              placeholder="Saisir"
              theme="fondgris"
              taille="L"
              minRows={4}
              value={maConsultation.description}
              onChange={(e) => handleChange("description", e.target.value)}
              onBlur={(e) => handleSauvegarder("description", e.target.value)}
            />
          </div>
          <div className={MonStyle.BlocPoulLangue}>
            <div className={MonStyle.BlocPouls}>
              <div className="TitreBloc">Pouls</div>
              <EditionLanguePouls
                id="input_pouls"
                type="pouls"
                value={maConsultation.pouls}
                onChange={(e) => handleChange("pouls", e.target.value)}
                onBlur={(e) => handleSauvegarder("pouls", e.target.value)}
              />
            </div>
            <div className={MonStyle.BlocLangue}>
              <div className="TitreBloc">Langue</div>
              <EditionLanguePouls
                id="input_langue"
                type="langue"
                value={maConsultation.langue}
                onChange={(e) => handleChange("langue", e.target.value)}
                onBlur={(e) => handleSauvegarder("langue", e.target.value)}
              />
            </div>
          </div>
          <div className={MonStyle.Bloc}>
            <div className="TitreBloc">Les 8 règles</div>
            <EditionHuitRegles
              value={maConsultation.huit_regles}
              onChange={(regles) => handleSauvegarder("huit_regles", regles)}
            />
          </div>
          <div className={MonStyle.Bloc}>
            <div className="TitreBloc">Bilan énergétique</div>
            <InputTexte
              id="input_bilan"
              multiline
              placeholder="Saisir"
              theme="fondgris"
              taille="L"
              value={maConsultation.bilan_energetique}
              onChange={(e) =>
                handleChange("bilan_energetique", e.target.value)
              }
              onBlur={(e) =>
                handleSauvegarder("bilan_energetique", e.target.value)
              }
            />
          </div>
          <div className={MonStyle.Bloc}>
            <div className="TitreBloc">Traitement</div>
            <InputTexte
              id="input_traitement"
              multiline
              placeholder="Saisir"
              theme="fondgris"
              taille="L"
              minRows={4}
              value={maConsultation.traitement_energetique}
              onChange={(e) =>
                handleChange("traitement_energetique", e.target.value)
              }
              onBlur={(e) =>
                handleSauvegarder("traitement_energetique", e.target.value)
              }
            />
          </div>
        </div>
      )}

      {/************************ CONSULTATION ENERGETIQUE SINOBI ***********************/}
      {isConsultationEnergetique() && (
        <div>
          <div className={MonStyle.BlocPoulLangue}>
            <div className={MonStyle.BlocPouls}>
              <div className="TitreBloc">Pouls</div>
              <EditionLanguePouls
                id="input_pouls"
                type="pouls"
                placeholder="Saisir"
                theme="fondgris"
                value={maConsultation.pouls}
                onChange={(e) => handleChange("pouls", e.target.value)}
                onBlur={(e) => handleSauvegarder("pouls", e.target.value)}
              />
            </div>
            <div className={MonStyle.BlocLangue}>
              <div className="TitreBloc">Langue</div>
              <EditionLanguePouls
                id="input_langue"
                type="langue"
                placeholder="Saisir"
                value={maConsultation.langue}
                onChange={(e) => handleChange("langue", e.target.value)}
                onBlur={(e) => handleSauvegarder("langue", e.target.value)}
              />
            </div>
          </div>
          <div className={MonStyle.Bloc}>
            <div className="TitreBloc">Description</div>
            <InputTexte
              id="input_description"
              multiline
              placeholder="Saisir"
              theme="fondgris"
              taille="L"
              minRows={4}
              value={maConsultation.description}
              onChange={(e) => handleChange("description", e.target.value)}
              onBlur={(e) => handleSauvegarder("description", e.target.value)}
            />
          </div>
          <div className={MonStyle.Bloc}>
            <div className="TitreBloc">Traitement</div>
            <InputTexte
              id="input_traitement"
              multiline
              placeholder="Saisir"
              theme="fondgris"
              taille="L"
              minRows={4}
              value={maConsultation.traitement_energetique}
              onChange={(e) =>
                handleChange("traitement_energetique", e.target.value)
              }
              onBlur={(e) =>
                handleSauvegarder("traitement_energetique", e.target.value)
              }
            />
          </div>
        </div>
      )}

      {/************************ CONSULTATION GENERIQUE ***********************/}
      <AffichageConditionnel
        open={
          maConsultation?.type_consultation?.code === "consultation_generique"
        }
      >
        <div className={MonStyle.Bloc}>
          <EditionMotifConsultation
            consultation={maConsultation}
            onChange={(motifs) => handleSauvegarder("motifs", motifs)}
          />
        </div>
        <div className={MonStyle.Examen}>
          <div className="TitreBloc">Texte</div>
          <InputTexte
            multiline
            placeholder="Saisir"
            theme="fondgris"
            taille="L"
            minRows={8}
            value={maConsultation.description}
            onChange={(e) => handleChange("description", e.target.value)}
            onBlur={(e) => handleSauvegarder("description", e.target.value)}
          />
        </div>
      </AffichageConditionnel>

      {/************************ DOCUMENTS LIES ***********************/}
      <div className={MonStyle.Documents} id="PiecesJointes">
        <EditionDocuments
          patient_id={maConsultation.patient_id}
          consultation_id={maConsultation._id}
        />
      </div>

      <MessageAlerte message={message} />
    </SurfacePapier>
  );
}
