import React from "react";
import Style from "./Menu.module.css";
import IconButton from "@material-ui/core/IconButton";
import { withStyles } from "@material-ui/core/styles";
import Tooltip from "../../Design/DataDisplay/ToolTip";
import { Link } from "react-router-dom";
import {
  IconeMenuAgendaDate,
  IconeMenuProduit,
  IconeMenuConsultation,
  IconeMenuPrestations,
} from "../../Design/Icones/IconesMenu";
import { useContext } from "react";
import { PatientsContext } from "../../Data/PatientsContext";
import BadgeNombre from "../../Design/DataDisplay/BadgeNombre";
import AllInboxIcon from "@material-ui/icons/AllInbox";
import { AgendasContext } from "../../Data/AgendasContext";
import GitHubIcon from "@material-ui/icons/GitHub";
import CommentIcon from "@material-ui/icons/Comment";
import { IconeAdpx } from "../../Design/Icones/IconesBe";
import { AuthentificationContext } from "../../Data/AuthentificationContext";
import { SupportContext } from "../../Data/SupportContext";

const BoutonMenuTheme = withStyles((theme) => ({
  root: {
    color: "white",
    backgroundColor: "none",
    fontWeight: "700",
    height: "60px",
    width: "60px",
    display: "flex",
    flexDirection: "colunm",
    alignItems: "center",
    justifyContent: "center",
    padding: "0px",
    "&:hover": {
      backgroundColor: "#EDEDED40",
    },
  },
}))(IconButton);

export default function Menu({ style }) {
  const HOSTNAME = window.location.hostname;

  //NES Gestion du contexte
  const { ongletsPatients } = useContext(PatientsContext);
  const { agendaSelected } = useContext(AgendasContext);
  const auth = useContext(AuthentificationContext);
  const { nbDemandesNonLues } = useContext(SupportContext);

  return (
    <div className={Style.Menu}>
      <div className={Style.IconeMenu}>
        <Tooltip texte="Patients et consultations" placement="right">
          <BoutonMenuTheme
            component={Link}
            to="/patientsrecents"
            id="menu_patient"
          >
            <BadgeNombre
              invisible={ongletsPatients.length === 0}
              badgeContent={ongletsPatients.length}
              color="secondary"
              id="badge_nb_patients"
            >
              <IconeMenuConsultation
                style={{ width: "40px", height: "40px" }}
              />
            </BadgeNombre>
          </BoutonMenuTheme>
        </Tooltip>
      </div>

      <div className={Style.IconeMenu}>
        <Tooltip texte="Agenda et rendez-vous" placement="right">
          <BoutonMenuTheme
            component={Link}
            to={
              agendaSelected?._id
                ? "/agendas/" + agendaSelected?._id
                : "/agendas/"
            }
            id="menu_agenda"
          >
            <IconeMenuAgendaDate />
          </BoutonMenuTheme>
        </Tooltip>
      </div>

      <div className={Style.IconeMenu}>
        <Tooltip texte="Produits" placement="right">
          <BoutonMenuTheme component={Link} to="/produits/" id="menu_produits">
            <IconeMenuProduit />
          </BoutonMenuTheme>
        </Tooltip>
      </div>

      <div className={Style.IconeMenu}>
        <Tooltip texte="Prestations & actes" placement="right">
          <BoutonMenuTheme component={Link} to="/prestations/">
            <IconeMenuPrestations />
          </BoutonMenuTheme>
        </Tooltip>
      </div>
      <div className={Style.IconeMenu}>
        <Tooltip texte="Suivi des règlements" placement="right">
          <BoutonMenuTheme component={Link} to="/suivireglements/">
            <AllInboxIcon style={{ fontSize: 36 }} />
          </BoutonMenuTheme>
        </Tooltip>
      </div>

      <div className={Style.IconeMenu}>
        <Tooltip texte="Support & aide" placement="right">
          <BoutonMenuTheme component={Link} to="/support/">
            <BadgeNombre
              invisible={nbDemandesNonLues === 0}
              badgeContent={nbDemandesNonLues}
              color="secondary"
              id="badge_nb_demandes"
            >
              <CommentIcon style={{ fontSize: 36 }} />
            </BadgeNombre>
          </BoutonMenuTheme>
        </Tooltip>
      </div>

      {HOSTNAME === "localhost" && (
        <div
          className={Style.IconeMenu}
          style={{ backgroundColor: "orange", borderRadius: "30px" }}
        >
          <Tooltip texte="Développement" placement="right">
            <BoutonMenuTheme component={Link} to="/dev/">
              <GitHubIcon style={{ fontSize: 36 }} />
            </BoutonMenuTheme>
          </Tooltip>
        </div>
      )}

      {auth.payload.code_role === "ADPX" && (
        <div className={Style.IconeMenu}>
          <Tooltip texte="ADPX" placement="right">
            <BoutonMenuTheme component={Link} to="/adpx/">
              <IconeAdpx width="60" color="white" />
            </BoutonMenuTheme>
          </Tooltip>
        </div>
      )}
    </div>
  );
}
