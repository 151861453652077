import React, { useContext, useEffect, useState } from "react";
import { SupportContext } from "../../../../Data/SupportContext";

export default function EtatDemande({ etat, disabled, onClick, style }) {
  //NES gestion du contexte
  const { ETAT_DEMANDE } = useContext(SupportContext);

  //NES gestion du state
  const [data, setData] = useState({});

  //USEEFFECT
  useEffect(() => {
    if (etat) {
      let objetEtat = ETAT_DEMANDE.find((item) => item.code === etat);
      setData(objetEtat);
    }
    // eslint-disable-next-line
  }, [etat]);

  let monStyle = {
    backgroundColor: data?.color,
    fontSize: "12px",
    padding: "0px 5px",
    borderRadius: "4px",
    color: "white",
    fontWeight: "bolder",
    minWidth: "65px",
    width: "65px",
    textAlign: "center",
    height: "20px",
    boxSizing: "border-box",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    ...style,
  };

  if (disabled) {
    monStyle = { ...monStyle, backgroundColor: "#DDDDDD50", color: "#97989D" };
  }

  return (
    <div style={monStyle} onClick={onClick}>
      {data?.texte}
    </div>
  );
}
