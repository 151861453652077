import React from "react";
import SurfacePage from "../../Design/Surfaces/SurfacePage";
import OngletFacturation from "./Components/OngletsFacturation/OngletsFacturation";
import { Switch, Route } from "react-router-dom";
import PageAbonnement from "./Pages/PageAbonnement";
import PageCarteBancaire from "./Pages/PageCarteBancaire";
import PageListeFactures from "./Pages/PageListeFactures";
import PageInformationFacturation from "./Pages/PageInformationsFacturation";

export default function PageFacturation() {
  return (
    <SurfacePage>
      <h1>Informations de règlement</h1>
      <OngletFacturation style={{ margin: "20px 0px" }} />
      <Switch>
        <Route path="/facturation/" exact component={PageAbonnement} />
        <Route
          path="/facturation/carte-bancaire"
          component={PageCarteBancaire}
        />
        <Route
          path="/facturation/mes-factures/:id"
          component={PageListeFactures}
        />
        <Route path="/facturation/mes-factures" component={PageListeFactures} />
        <Route
          path="/facturation/mes-informations-facturation"
          component={PageInformationFacturation}
        />
      </Switch>
    </SurfacePage>
  );
}
