import React from "react";
//import Badge from "@material-ui/core/Badge";
//import { withStyles } from "@material-ui/core/styles";
import Badge from "@mui/material/Badge";
import { styled } from "@mui/material/styles";

export default function BadgeNombre(props) {
  /*
  const StyledBadge = withStyles((theme) => ({
    badge: {
      //right: -3,
      //top: 13,
      //border: `2px solid white`,
      padding: "0 4px",
      backgroundColor: "#D2215F",
      fontWeight: 700,
      color: "white",
    },
  }))(Badge);
  */

  const StyledBadge = styled(Badge)(({ theme }) => ({
    "& .MuiBadge-badge": {
      padding: "0 4px",
      backgroundColor: "#D2215F",
      fontWeight: 700,
      color: "white",
    },
  }));

  return <StyledBadge {...props}>{props.children}</StyledBadge>;
}
