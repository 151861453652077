import React, { useContext, useEffect, useState } from "react";
import { MedicalContext } from "../../../../Data/MedicalContext";
import SurfaceBloc from "../../../../Design/Surfaces/SurfaceBloc";
//import { Link } from "react-router-dom";
import MonStyle from "./AffichageSyntheseHabitus.module.css";
import BoutonAjoutBloc from "../../../../Design/Boutons/BoutonAjoutBloc";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import ObjetHabitus from "../../../../Design/Objets/ObjetHabitus";
import { HashLink as Link } from "react-router-hash-link";

export default function AffichageSyntheseHabitus({ link, patient_id }) {
  //Nes utilisation du contexte
  const { antecedentsEtAllergies, getHabitus } = useContext(MedicalContext);

  //NES gestion du state
  const [habitus, setHabitus] = useState([]);
  const [tabac, setTabac] = useState([]);
  const [alcool, setAlcool] = useState([]);
  const [drogue, setDrogue] = useState([]);
  const [sport, setSport] = useState([]);
  const [travail, setTravail] = useState([]);
  const [famille, setFamille] = useState([]);

  useEffect(() => {
    setHabitus(getHabitus(patient_id));
    setTabac(getHabitus(patient_id, "habitus_tabac"));
    setAlcool(getHabitus(patient_id, "habitus_alcool"));
    setDrogue(getHabitus(patient_id, "habitus_drogue"));
    setSport(getHabitus(patient_id, "habitus_sport"));
    setTravail(getHabitus(patient_id, "habitus_travail"));
    setFamille(getHabitus(patient_id, "habitus_famille"));

    // eslint-disable-next-line
  }, [antecedentsEtAllergies, patient_id]);

  return (
    <div>
      <div
        className="TitreBloc"
        style={{ marginBottom: "5px", marginLeft: "10px" }}
      >
        Habitus
      </div>

      {habitus.length === 0 ? (
        <BoutonAjoutBloc
          iconeComposant={<AddCircleOutlineIcon />}
          texte="HABITUS"
          link={link + "#habitus"}
          taille="L"
          fullWidth
          style={{
            justifyContent: "left",
            paddingLeft: "80px",
          }}
        />
      ) : (
        <SurfaceBloc
          style={{ display: "flex", flexDirection: "column" }}
          isBouton={true}
        >
          <Link to={link + "#habitus"}>
            {" "}
            {tabac.map((item) => (
              <div className={MonStyle.Habitus} key={item._id}>
                <ObjetHabitus habitus={item} />
              </div>
            ))}
            {alcool.map((item) => (
              <div className={MonStyle.Habitus} key={item._id}>
                <ObjetHabitus habitus={item} />
              </div>
            ))}
            {drogue.map((item) => (
              <div className={MonStyle.Habitus} key={item._id}>
                <ObjetHabitus habitus={item} />
              </div>
            ))}
            {sport.map((item) => (
              <div className={MonStyle.Habitus} key={item._id}>
                <ObjetHabitus habitus={item} />
              </div>
            ))}
            {travail.map((item) => (
              <div className={MonStyle.Habitus} key={item._id}>
                <ObjetHabitus habitus={item} />
              </div>
            ))}
            {famille.map((item) => (
              <div className={MonStyle.Habitus} key={item._id}>
                <ObjetHabitus habitus={item} />
              </div>
            ))}
          </Link>
        </SurfaceBloc>
      )}
    </div>
  );
}
