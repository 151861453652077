import React, { useState, useEffect } from "react";
import MonStyle from "./InputSelectObjet.module.css";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import Popover from "@mui/material/Popover";
import MenuItem from "@material-ui/core/MenuItem";

export default function InputSelectObjet({
  options,
  renderObjets,
  value,
  onChange,
  placeholder,
  enabledAucuneValeur,
  disabled,
  name,
  id,
  theme,
  style,
}) {
  //NES Gestion du state
  const [objet, setObjet] = useState();

  useEffect(() => {
    setObjet(value);
  }, [value]);

  const handleSelect = (objet) => {
    setObjet(objet);
    if (onChange) {
      onChange(objet);
    }
  };

  //NES POPOVER
  const [anchorEl, setAnchorEl] = React.useState(null);

  const boutonActionClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  if (disabled) {
    return (
      <div
        className={MonStyle.InputSelectObjetDisabled}
        name={name}
        id={id}
        style={style}
      >
        {objet ? renderObjets(objet) : placeholder}
      </div>
    );
  } else {
    return (
      <div
        className={MonStyle.InputSelectObjet}
        name={name}
        id={id}
        style={style}
      >
        <div
          className={
            objet ? MonStyle.ObjetSelectionne : MonStyle.ObjetNonSelectionne
          }
          onClick={boutonActionClick}
        >
          {objet ? renderObjets(objet) : placeholder}
        </div>
        {anchorEl ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        <Popover
          open={anchorEl ? true : false}
          anchorEl={anchorEl}
          onClose={handleClose}
          onClick={handleClose}
          anchorOrigin={{
            vertical: "center",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "center",
            horizontal: "left",
          }}
        >
          <div className={MonStyle.Liste} id={"menu_" + id}>
            {enabledAucuneValeur && (
              <MenuItem
                key=""
                value=""
                style={{ color: "#97989D", fontStyle: "italic" }}
                onClick={() => handleSelect("")}
              >
                Aucune valeur
              </MenuItem>
            )}
            {options?.map((item, index) => (
              <MenuItem
                className={MonStyle.Objet}
                onClick={() => handleSelect(item)}
                key={index}
              >
                {renderObjets(item)}
              </MenuItem>
            ))}
          </div>
        </Popover>
      </div>
    );
  }
}
