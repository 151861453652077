import React from "react";
import MonStyle from "./NumberPicker.module.css";
import Popover from "@mui/material/Popover";

export default function NumberPicker({
  value,
  onClick,
  onEffacer,
  disableEffacer,
  variant,
  ...props
}) {
  const valeurs = [1, 2, 3, 4, 5, 6, 7, 8, 9, "0"];

  //NES POPOVER
  const [anchorEl, setAnchorEl] = React.useState(null);

  const openPopover = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClic = (valeur) => {
    if (onClick) {
      onClick(valeur);
    }
  };

  const handleEffacer = () => {
    if (onEffacer) {
      onEffacer("");
    }
  };

  return (
    <div>
      <div onClick={openPopover} className={MonStyle.NumberPicker}>
        {props.children}
      </div>
      <Popover
        open={anchorEl ? true : false}
        anchorEl={anchorEl}
        onClose={handleClose}
        onClick={handleClose}
        anchorOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
      >
        <div className={MonStyle.Grid}>
          {valeurs.map((valeur) => (
            <div
              className={
                value === valeur ? MonStyle.UniteSelectionne : MonStyle.Unite
              }
              style={{ borderRadius: variant === "rond" ? "30px" : "" }}
              onClick={() => handleClic(valeur)}
              key={valeur}
            >
              {valeur}
            </div>
          ))}

          {!disableEffacer && (
            <div className={MonStyle.Effacer} onClick={handleEffacer}>
              Effacer
            </div>
          )}
        </div>
      </Popover>
    </div>
  );
}
