import React, { useEffect, useState, useContext } from "react";
import BoutonFiltre from "../BoutonFiltre";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { ProduitsContext } from "../../../Data/ProduitsContext";
import ObjetLaboratoire from "../../Objets/ObjetLaboratoire";
import DataPicker from "../../Pickers/DataPicker";

export default function FiltreLaboratoires({ style, onChange }) {
  //NES Gestion du contexte
  const { getLaboratoires } = useContext(ProduitsContext);

  //NES gestion du state
  const [data, setData] = useState([]);
  const [filtre, setFiltre] = useState({});
  const [libelleFiltre, setLibelleFiltre] = useState("");

  //NES quand je clique sur un labo
  const handleClicLabo = (laboratoire) => {
    let monFiltre = {};
    if (filtre._id === laboratoire._id) {
      //NES c'est le même donc j'enlève le filtre
      monFiltre = {};
      setLibelleFiltre("");
    } else {
      monFiltre = laboratoire;
      setLibelleFiltre(laboratoire.nom);
    }
    setFiltre(monFiltre);
    if (onChange) {
      onChange(monFiltre);
    }
  };

  //NES Quand je clique sur effacer
  const handleEffacer = () => {
    setFiltre({});
    setLibelleFiltre("");
    if (onChange) {
      onChange({});
    }
  };

  useEffect(() => {
    getLaboratoires()
      .then((reponse) => {
        setData(reponse.data);
      })
      .catch((error) => {
        console.log(error);
      });
    // eslint-disable-next-line
  }, []);

  return (
    <DataPicker
      listeObjets={data}
      objetLibelle={"nom"}
      objetId="_id"
      onClick={handleClicLabo}
    />
  );

  return (
    <BoutonFiltre
      texte="Laboratoire"
      libelleFiltre={libelleFiltre}
      onEffacer={handleEffacer}
      style={style}
      name="Laboratoire"
      id="bouton_filtre_laboratoire"
    >
      <List id="filtres_laboratoire">
        {data.map((item) => (
          <ListItem
            button
            disableGutters
            divider
            key={item._id}
            selected={filtre._id === item._id}
            onClick={() => handleClicLabo(item)}
          >
            <div
              style={{
                height: "30px",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                paddingLeft: "15px",
              }}
            >
              <ObjetLaboratoire laboratoire={item} />
            </div>
          </ListItem>
        ))}
      </List>
    </BoutonFiltre>
  );
}
