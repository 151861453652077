import React from "react";
import BoutonActionSecondaire from "./BoutonActionSecondaire";
import BoutonActionPrincipale from "./BoutonActionPrincipale";

export default function BoutonConfirmerSuppression({
  onAnnuler,
  onSupprimer,
  id,
  style,
  taille,
  flexDirection,
  disabled,
  visible,
  fullWidth,
  texte,
  variant,
}) {
  //NES constantes pour gérer l'affichage
  const styleSimple = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: fullWidth ? "100%" : "fit-content",
    ...style,
  };

  const styleBloc = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: fullWidth ? "100%" : "fit-content",
    //backgroundColor: "#F4433610",
    border: "2px solid #F44336",
    padding: "20px",
    boxSizing: "border-box",
    maxWidth: "100%",
    textAlign: "center",
    ...style,
  };

  let MonStyle = styleSimple;

  switch (variant) {
    case "bloc":
      MonStyle = styleBloc;
      break;

    default:
      MonStyle = styleSimple;

      break;
  }

  const handleSupprimer = (id) => {
    if (onSupprimer && id) {
      onSupprimer(id);
    }
  };

  if (visible === false) {
    return "";
  }

  //NES si pas de texte
  if (!texte) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          gap: "20px",
          width: fullWidth ? "100%" : "fit-content",
          height: "auto",
          ...style,
        }}
      >
        <BoutonActionPrincipale
          texte="OUI, supprimer"
          couleur="rouge"
          onClick={handleSupprimer}
          taille={taille ? taille : "M"}
          disabled={disabled}
        />
        <BoutonActionSecondaire
          id={id}
          texte="NON, annuler"
          couleur="gris"
          onClick={onAnnuler}
          taille={taille ? taille : "M"}
        />
      </div>
    );
  }

  return (
    <div style={MonStyle}>
      {texte && (
        <div
          style={{
            color: "#F44336",
            fontSize: "18px",
            fontWeight: "700",
            marginBottom: "20px",
            width: "100%",
          }}
        >
          {texte}
        </div>
      )}
      <div
        style={{
          display: "flex",
          flexDirection: flexDirection ? flexDirection : "",
          justifyContent: "center",
          gap: "20px",
          flexWrap: "wrap",
          //height: "100%",
          ...style,
        }}
      >
        <BoutonActionPrincipale
          texte="OUI, supprimer"
          couleur="rouge"
          onClick={handleSupprimer}
          taille={taille ? taille : "M"}
          disabled={disabled}
        />
        <BoutonActionSecondaire
          id={id}
          texte="NON, annuler"
          couleur="gris"
          onClick={onAnnuler}
          taille={taille ? taille : "M"}
        />
      </div>
    </div>
  );
}
