import React, { useEffect, useContext, useState } from "react";
import MonStyle from "./PagePatient.module.css";
import SurfacePage from "../../Design/Surfaces/SurfacePage";
import BarreOngletPatient from "../../Components/BarreOngletsPatients/BarreOngletsPatients";
import { PatientsContext } from "../../Data/PatientsContext";
import PageSynthese from "./Pages/Synthese/PageSynthese";
import PageHistoriqueConsultations from "./Pages/HistoriqueConsultations/PageHistoriqueConsultations";
import PageConsultation from "./Pages/Consultation/PageConsultation";
import PageAllergies from "./Pages/Allergies/PageAllergies";
import { Route } from "react-router-dom";
import PageDossier from "./Pages/Dossier/PageDossier";
import { BarreOutilsPatient } from "../../Components/BarreOutilsMobile/BarreOutilsMobile";
import { isPetitEcran, isMobile } from "../../functions/Navigateur";
import PageConstantes from "./Pages/Constantes/PageConstantes";
import PageGraphiqueConstante from "./Pages/Constantes/PageGraphiqueConstante";
import PageDocuments from "./Pages/Documents/PageDocuments";
import MessageErreur from "../../Design/Messages/MessageErreur";
import OngletDossier from "./Components/OngletDossier/OngletDossier";
import PageMessages from "./Pages/Messages/PageMessages";
import PageRendezVous from "./Pages/RendezVous/PageRendezVous";
import PageContacts from "./Pages/Contacts/PageContacts";

export default function PageAppPatients({ match }) {
  //NES gestion du contexte
  const { getPatientContext } = useContext(PatientsContext);

  //NES récupération de l'ID dans l'URL
  let idUrl = match.params.id;

  //NES pour changer ma redirection si on est en mobile :)
  var constIsPetitEcran = isPetitEcran();
  var constIsMobile = isMobile();

  //NES gestion du State
  const [erreur, setErreur] = useState("");
  const [messageErreur, setMessageErreur] = useState("");
  const [monPatient, setMonPatient] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  //USEEFFECT
  useEffect(() => {
    if (idUrl && idUrl !== "nouveau") {
      setIsLoading(true);
      //GET Patient
      getPatientContext(idUrl).then((reponse) => {
        setIsLoading(false);
        if (reponse.code === "OK") {
          setMonPatient(reponse.data);
        } else {
          setErreur(reponse);
          setMessageErreur("Erreur lors du chargement du patient");
        }
      });
    }
    // eslint-disable-next-line
  }, [idUrl]);

  if (messageErreur) {
    return (
      <div className={MonStyle.PagePatient}>
        <BarreOngletPatient idUrl={idUrl} />
        <SurfacePage couleur="blanc" disablePadding disableMinHeight>
          <div className={MonStyle.Content}>
            <MessageErreur
              message={messageErreur}
              style={{ margin: "80px 0px", width: "100%" }}
              error={erreur}
              taille="XL"
            />
          </div>
        </SurfacePage>
      </div>
    );
  }

  return (
    <div
      className={MonStyle.PagePatient}
      style={{
        maxHeight: window.innerHeight - 70 + "px",
        minHeight: window.innerHeight - 70 + "px",
      }}
    >
      <BarreOngletPatient idUrl={idUrl} isLoading={isLoading} />

      <div
        style={{
          minHeight: "100%",
          maxHeight: window.innerHeight - 120 + "px",
          background: "white",
          border: "0px solid blue",
          borderRadius: "10px 0px 0px 10px",
        }}
      >
        <div className={MonStyle.Content}>
          <div className={MonStyle.PageSynthese}>
            <PageSynthese patient={monPatient} match={match} />
          </div>
          <div className={MonStyle.PartieDroite}>
            <div className={MonStyle.OngletsDuDossier}>
              {idUrl !== "nouveau" && <OngletDossier patient_id={idUrl} />}
            </div>
            {/******************ROUTAGE DE LA PARTIE DROITE DE L'ECRAN*****************************/}
            <div className={MonStyle.ContenuPartieDroite}>
              {constIsMobile ? (
                <Route path="/patients/:id/" exact component={PageSynthese} />
              ) : (
                <Route
                  path="/patients/:idpatient/"
                  exact
                  component={PageHistoriqueConsultations}
                />
              )}
              {/* <Redirect to={`/patients/${idUrl}/consultations/`} /> */}

              <Route
                path="/patients/:idpatient/consultations/"
                exact
                component={PageHistoriqueConsultations}
              />
              {!constIsPetitEcran && (
                <Route
                  path="/patients/:idpatient/consultations/:idconsultation"
                  component={PageHistoriqueConsultations}
                />
              )}
              <Route
                path="/patients/:idpatient/consultations/:idconsultation"
                component={PageConsultation}
              />

              <Route
                path="/patients/:id/synthese"
                exact
                component={PageSynthese}
              />

              <Route
                path="/patients/:id/dossier/"
                exact
                component={PageDossier}
              />

              <Route path="/patients/:id/allergies" component={PageAllergies} />
              <Route
                path="/patients/:patient_id/constantes/:mesure_id"
                component={
                  constIsMobile ? PageGraphiqueConstante : PageConstantes
                }
              />
              <Route
                path="/patients/:patient_id/constantes"
                component={PageConstantes}
                exact={constIsPetitEcran ? true : false}
              />
              <Route
                path="/patients/:patient_id/documents"
                component={PageDocuments}
              />
              <Route
                path="/patients/:patient_id/contacts"
                component={PageContacts}
              />
              <Route
                path="/patients/:patient_id/messages"
                component={PageMessages}
              />
              <Route
                path="/patients/:patient_id/rendezvous"
                component={PageRendezVous}
              />
            </div>
          </div>
        </div>
        <BarreOutilsPatient monPatientId={idUrl} />
      </div>
    </div>
  );
}
