import React, { useState } from "react";
import MonStyle from "./AfficherPlus.module.css";
import Collapse from "@material-ui/core/Collapse";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Divider from "@material-ui/core/Divider";
import { Fragment } from "react";

export default function AfficherPlus({
  labelOpen,
  labelClose,
  style,
  ...props
}) {
  //NES gestion du state
  const [open, setOpen] = useState(false);

  return (
    <div style={style}>
      <Collapse in={open}>
        <Fragment>
          <Divider style={{ margin: "20px 0px" }} />
          {props.children}
        </Fragment>
      </Collapse>
      <div className={MonStyle.SeparateurAfficherPlus}>
        <div
          style={{
            borderBottom: "1px solid #DDD",
            height: "0px",
            //width: "50%",
            flex: 1,
          }}
        ></div>

        <div className={MonStyle.BoutonAfficher} onClick={() => setOpen(!open)}>
          <div>{!open ? labelOpen : labelClose}</div>
          <div
            style={{
              width: "40px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </div>
        </div>
        <div
          style={{
            borderBottom: "1px solid #DDD",
            height: "0px",
            //width: "50%",
            flex: 1,
          }}
        ></div>
      </div>
    </div>
  );
}
