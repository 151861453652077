import React, { useRef, useContext } from "react";
import { DocumentsContext } from "../../Data/DocumentsContext";
import BoutonTexte from "./BoutonTexte";

export default function BoutonAjouterDocuments({
  patient_id,
  consultation_id,
  style,
}) {
  //NES Gestion du contexte
  const { ajouterDocumentsUploadContext } = useContext(DocumentsContext);

  //NES Gestion des ref
  const fileInput = useRef(null);

  //POST Documents
  const ajouterDocument = (event) => {
    let fichiers = Array.from(event.target.files);
    //NES j'ajoute le fichier à uploader dans le context, c'est l'objet fichier qui se charge de l'upload pour gérer sa progression
    ajouterDocumentsUploadContext(fichiers, patient_id, consultation_id);
    //NES redirection le l'ancre pour voir le document
    //document.getElementById("PiecesJointes").scrollIntoView();
  };

  return (
    <div style={style}>
      <BoutonTexte
        texte="Ajouter un document"
        onClick={() => fileInput.current.click()}
      />
      <input
        type="file"
        style={{ display: "none" }}
        ref={fileInput}
        onChange={ajouterDocument}
        multiple
      />
    </div>
  );
}
