import React, { useContext, useEffect, useState } from "react";
import MonStyle from "./InfoRdvPatient.module.css";
import { RdvContext } from "../../../../../Data/RdvContext";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import BoutonRond from "../../../../../Design/Boutons/BoutonRond";
import CloseIcon from "@material-ui/icons/Close";
import PageRendezVous from "../../../../Patients/Pages/RendezVous/PageRendezVous";
import SurfaceBloc from "../../../../../Design/Surfaces/SurfaceBloc";
import ObjetRdv from "../../../../../Design/Objets/ObjetRdv";
import { Divider } from "@material-ui/core";
import ThumbUpAltIcon from "@material-ui/icons/ThumbUpAlt";
import ThumbDownAltIcon from "@material-ui/icons/ThumbDownAlt";
import { pluriel } from "../../../../../functions/Formatter";
import AffichageConditionnel from "../../../../../Design/DataDisplay/AffichageConditionnel";
import BlocCommentaireRdv from "./BlocCommentaireRdv";

export default function InfoRdvPatient({ patient_id, monRdv }) {
  //NES Gestion du contexte
  const { getRdvPatient } = useContext(RdvContext);

  //NES Gestion du state
  const [listeRdv, setListeRdv] = useState([]);
  const [open, setOpen] = useState(false);
  const [dernierRdv, setDernierRdv] = useState("");
  const [prochainsRdv, setProchainsRdv] = useState([]);
  const [nbRdvExcuses, setNbRdvExcuses] = useState(0);
  const [nbRdvExcuseDerniereMinute, setNbRdvExcuseDerniereMinute] = useState(0);
  const [nbRdvNonHonores, setNbRdvNonHonores] = useState(0);
  const [filtreAnnulation, setFiltreAnnulation] = useState("");

  //USEEFFECT Chargement des rdv patient
  useEffect(() => {
    if (patient_id) {
      getRdvPatient(patient_id).then((reponse) => {
        if (reponse.code === "OK") {
          setListeRdv(reponse.data);
        }
      });
    }
    // eslint-disable-next-line
  }, [patient_id]);

  //USEEFFECT Recherche des deniers , non honorés etc ... rdv du patient
  useEffect(() => {
    if (listeRdv.length > 0) {
      let liste = listeRdv;

      //NES j'enlève mon rdv courant
      liste = liste.filter(
        (item) => item._id !== monRdv._id && item.is_archive === false
      );

      //NES je trie par date
      liste.sort((a, b) => {
        let dateA = new Date(a.date_heure);
        let dateB = new Date(b.date_heure);
        if (dateA > dateB) {
          return -1;
        } else {
          return 1;
        }
      });

      //NES je fais la liste des rdv futurs
      let listeRdvFuturs = [];
      let listeRdvPasses = [];
      liste.forEach((rdv) => {
        //NES est-ce que la date est une date future ?
        let aujourdhui = Date.now();
        let dateRdv = new Date(rdv.date_heure);
        //NES si le rdv n'est pas annulé
        if (dateRdv > aujourdhui && rdv.annulation_rdv === undefined) {
          listeRdvFuturs.push(rdv);
        } else {
          listeRdvPasses.push(rdv);
        }
      });
      setProchainsRdv(listeRdvFuturs);

      //NES je cherche le dernier RDV
      let listesRdvPassesHonores = listeRdvPasses.filter(
        (rdv) => rdv.annulation_rdv === undefined
      );
      setDernierRdv(listesRdvPassesHonores[0]);
      //NES je cherches les RDV Non honores
      let listeRdvNonHonores = liste.filter(
        (item) => item.annulation_rdv?.mode === "NON_HONORE"
      );
      setNbRdvNonHonores(listeRdvNonHonores.length);
      //NES je cherches les RDV excusés
      let listeRdvExcuses = liste.filter(
        (item) => item.annulation_rdv?.mode === "EXCUSE"
      );
      setNbRdvExcuses(listeRdvExcuses.length);
      //NES je cherches les RDV excusés à la dernière minute
      let listeRdvExcuseDerniereMinute = liste.filter(
        (item) => item.annulation_rdv?.mode === "EXCUSE_DERNIERE_MINUTE"
      );
      setNbRdvExcuseDerniereMinute(listeRdvExcuseDerniereMinute.length);
    }
    // eslint-disable-next-line
  }, [listeRdv]);

  //NES afficher les rdv annulés, non honorés, etc...
  const handleClickTypeAnnulation = (type) => {
    console.log("handleClickTypeAnnulation", type);
    setFiltreAnnulation(type);
    setOpen(true);
  };

  //NES je ne retourne rien tant que je n'ai pas de patient_id
  if (!patient_id)
    return <div className={MonStyle.NouveauRdv}>Nouveau Rendez-vous</div>;

  return (
    <div>
      <section className={MonStyle.Bloc}>
        <BlocCommentaireRdv patient_id={patient_id} />
      </section>
      <section className={MonStyle.Bloc}>
        <h4 style={{ textTransform: "uppercase" }}>Dernier RDV </h4>
        {dernierRdv ? (
          <SurfaceBloc>
            <ObjetRdv rdv={dernierRdv} taille="S" />
          </SurfaceBloc>
        ) : (
          <div className={MonStyle.Aucun}>Aucun rdv précédent</div>
        )}
      </section>

      <section className={MonStyle.Bloc}>
        <h4 style={{ textTransform: "uppercase" }}>Prochain RDV </h4>
        {prochainsRdv.length === 0 && (
          <div className={MonStyle.Aucun}>Aucun rdv à venir</div>
        )}
        <SurfaceBloc>
          {prochainsRdv.map((rdv) => (
            <div key={rdv._id}>
              <ObjetRdv rdv={rdv} taille="S" />
              <Divider />
            </div>
          ))}
        </SurfaceBloc>
      </section>

      <section className={MonStyle.Bloc}>
        <h4 style={{ textTransform: "uppercase" }}>Suivi des rdv annulés</h4>
        {nbRdvExcuses + nbRdvExcuseDerniereMinute + nbRdvNonHonores === 0 ? (
          <div className={MonStyle.Aucun}>Aucun rdv annulé</div>
        ) : (
          <SurfaceBloc>
            <AffichageConditionnel open={true}>
              <div
                className={MonStyle.ItemBloc}
                onClick={() => handleClickTypeAnnulation("EXCUSE")}
              >
                <ThumbUpAltIcon
                  className="IconeObjet"
                  style={{ color: "#19A400" }}
                />
                {pluriel(nbRdvExcuses, "excusé", "excusés")}
              </div>
            </AffichageConditionnel>
            <AffichageConditionnel open={true}>
              <div
                className={MonStyle.ItemBloc}
                onClick={() =>
                  handleClickTypeAnnulation("EXCUSE_DERNIERE_MINUTE")
                }
              >
                <ThumbDownAltIcon
                  className="IconeObjet"
                  style={{ color: "#FF9800" }}
                />
                {pluriel(
                  nbRdvExcuseDerniereMinute,
                  " à la dernière minute",
                  " à la dernière minute"
                )}
              </div>
            </AffichageConditionnel>
            <AffichageConditionnel open={true}>
              <div
                className={MonStyle.ItemBloc}
                onClick={() => handleClickTypeAnnulation("NON_HONORE")}
              >
                <ThumbDownAltIcon
                  className="IconeObjet"
                  style={{ color: "#F44336" }}
                />
                {pluriel(nbRdvNonHonores, "non excusé", "non excusés")}
              </div>
            </AffichageConditionnel>
          </SurfaceBloc>
        )}
      </section>

      <SwipeableDrawer
        anchor="right"
        open={open}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
      >
        <div className={MonStyle.Panneau}>
          <div className={MonStyle.BoutonTitre}>
            <BoutonRond
              iconeComposant={<CloseIcon />}
              style={{ marginRight: "10px" }}
              tooltip="Fermer"
              onClick={() => setOpen(false)}
              couleurIcone="noir"
              id="bouton_fermer_panneau"
            />
            <span className="TitrePop">Liste des RDV</span>
          </div>
          <div>
            <PageRendezVous
              patient_id={patient_id}
              filtreAnnulation={filtreAnnulation}
            />
          </div>
        </div>
      </SwipeableDrawer>
    </div>
  );
}
