import React from "react";
import MonStyle from "./MessageAucunePrestation.module.css";
import IllustrationConsultation from "../../../Images/Illustrations/Consultation.png";
import BoutonActionPrincipale from "../../../Design/Boutons/BoutonActionPrincipale";

export default function MessageAucunePrestation() {
  return (
    <div className={MonStyle.MessageOnboarding}>
      <div className={MonStyle.Illustration}>
        <img
          src={IllustrationConsultation}
          className="Illustration"
          alt="Illsutration consultation"
        />
      </div>
      <h4>Tarifs et prestations</h4>
      <h3 style={{ textAlign: "center" }}>
        Commençons par renseigner le tarif d'une consultation
      </h3>
      <p style={{ textAlign: "center" }}>
        Vous devez commencer par renseigner le tarif de votre consultation.{" "}
        <br />
        Vous pouvez créer autant de prestations que vous avez de tarifs
        différents : consultation, consultation à domicile, acuponcture...
      </p>
      <BoutonActionPrincipale
        texte="Ajouter le tarif d'une consultation"
        taille="L"
        link="/prestations/new-consultation"
        style={{ margin: "20px 0px" }}
      />
    </div>
  );
}
