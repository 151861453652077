import React from "react";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import LoarderCirculaire from "../Progression/LoaderCirculaire";
import { HashLink as Link } from "react-router-hash-link";

export default function BoutonActionPrincipale({
  texte,
  couleur,
  taille,
  fullWidth,
  onClick,
  disabled,
  href,
  style,
  isLoading,
  link,
  ...props
}) {
  const useStyles = makeStyles({
    root: {
      background: (props) => props.background,
      border: 0,
      boxShadow: "0px 2px 2px 0px rgba(0, 0, 0, 0.3)",
      color: "white",
      padding: "0 20px",
      fontFamily: "Lato",
      fontWeight: "700",
      textTransform: "none",
      borderRadius: "4px",
      textAlign: "center",
      fontSize: (props) => props.fontSize,
      minHeight: (props) => props.height,
      "&:hover": {
        backgroundColor: (props) => props.backgroundOver,
        boxShadow: "2px 4px 8px 0 rgba(0,0,0,0.3)",
      },
      "&$disabled": {
        backgroundColor: "#DDDDDD",
        color: "white",

        boxShadow: "none",
      },
    },
    disabled: {},
  });

  //NES gestion des taille S,M,L
  let height = "";
  let fontSize = "16px";
  switch (taille) {
    case "S":
      height = "30px";
      break;
    case "M":
      height = "40px";
      break;
    case "L":
      height = "50px";
      fontSize = "18px";
      break;
    default:
      height = "50px";
  }

  //NES Customisation des couleurs
  let background = "#0FA600";
  let backgroundOver = "#0D9500";
  if (process.env.REACT_APP_NOM === "SINOBI") {
    background = "#D2215F";
    backgroundOver = "#850C36";
  }

  if (couleur) {
    switch (couleur) {
      case "blanc":
        background = "white";
        break;
      case "rouge":
        background = "#F44336";
        backgroundOver = "#CE382D";
        break;
      case "bleu":
        background = "#208AB0";
        backgroundOver = "#205A71";
        break;
      case "rose":
        background = "#D2215F";
        backgroundOver = "#850C36";
        break;

      case "vert":
        background = "#0FA600";
        backgroundOver = "#0D9500";
        break;
      default:
        background = couleur;
        backgroundOver = couleur;
    }
  }

  //NES création du style
  let theme = {
    background: background,
    height: height,
    backgroundOver: backgroundOver,
    fontSize: fontSize,
  };
  const classes = useStyles(theme);

  //NES gestion du click pour le bloquer si is Loading
  const handleClick = (e) => {
    if (!isLoading && onClick) {
      onClick(e);
    }
  };

  return (
    <Button
      classes={{
        root: classes.root, // class name, e.g. `root-x`
        disabled: classes.disabled, // class name, e.g. `disabled-x`
      }}
      type="submit"
      disabled={disabled}
      fullWidth={fullWidth}
      onClick={handleClick}
      href={href}
      style={style}
      name={texte}
      component={link ? Link : "button"}
      to={link}
      {...props}
    >
      {isLoading ? (
        <LoarderCirculaire isLoading={isLoading} color="#FFFFFF90" size={30} />
      ) : (
        texte
      )}
    </Button>
  );
}
