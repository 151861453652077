import React, { useContext, useEffect } from "react";
import MonStyle from "./PopupModificationPassword.module.css";
import { useState } from "react";
import { AuthentificationContext } from "../../../Data/AuthentificationContext";
import BoutonActionPrincipale from "../../../Design/Boutons/BoutonActionPrincipale";
import InputPassword from "../../../Design/Formulaires/InputPassword";
import SurfacePopUp from "../../../Design/Surfaces/SurfacePopUp";
import { UtilisateursContext } from "../../../Data/UtilisateursContext";
import MessageErreur from "../../../Design/Messages/MessageErreur";
import AffichageConditionnel from "../../../Design/DataDisplay/AffichageConditionnel";
import IllustrationSucces from "../../../Images/Illustrations/Succes.png";
import queryString from "query-string";
import { useHistory } from "react-router-dom";

export default function PopupModificationPassword() {
  //NES gestion du contexte
  const auth = useContext(AuthentificationContext);
  const { patchUtilisateurs } = useContext(UtilisateursContext);

  //NES Gestion du routage pour faire des redirections
  let history = useHistory();

  //NES gestion du state
  const [password, setPassword] = useState("");
  const [open, setOpen] = useState(false);
  const [messageErreur, setMessageErreur] = useState("");
  const [message, setMessage] = useState("");
  const [titre, setTitre] = useState("Modification du mot de passe");
  const [disableClose, setDisableClose] = useState(false);
  const [texteBouton, setTexteBouton] = useState("Modifier mon mot de passe");

  //NES récupération des paramètres de l'URL pour savoir si on doit crééer le mot de passe
  let url = window.location.search;
  let params = queryString.parse(url);

  ///Modification du password
  const handleModifierPassword = () => {
    let newData = { password: password, is_demander_nouveau_password: false };
    patchUtilisateurs(auth.payload.utilisateur_id, newData).then((reponse) => {
      if (reponse.code === "OK") {
        //NES
        if (texteBouton === "Enregistrer") {
          setMessage("Mot de passe enregistré !");
          //NES je reroute pour afficher le message de bienvenue
          history.push("/home?action=welcome");
        } else {
          setMessage("Mot de passe modifié !");
        }
      } else {
        setMessageErreur(reponse.message);
      }
    });
  };

  ///handleChange
  const handleChange = (e) => {
    setMessageErreur("");
    setPassword(e.target.value);
  };

  //USEEFFECT affichage de la pop up
  useEffect(() => {
    //NES vérification de la date d'expiration du password
    if (auth?.dataConnexion?.date_expiration_password) {
      const AUJOURDHUI = new Date();
      const dateExpiration = new Date(
        auth?.dataConnexion?.date_expiration_password
      );

      if (dateExpiration < AUJOURDHUI) {
        setOpen(true);
      }
    }
    //NES Si le mot de passe doit être modifié is_demander_nouveau_password
    if (auth?.dataConnexion?.is_demander_nouveau_password) {
      setOpen(true);
    }
  }, [auth]);

  //USEEFFECT sur l'action passée dans l'url
  useEffect(() => {
    if (params?.action === "setpassword") {
      setOpen(true);
      setTitre("Votre mot de passe");
      setDisableClose(true);
      setTexteBouton("Enregistrer");
    }
  }, [params]);

  return (
    <SurfacePopUp
      open={open}
      titre={titre}
      sousTitre={
        message
          ? ""
          : "Pour des raison de sécurité votre mot de passe doit être modifié tous les 3 mois."
      }
      onClose={() => setOpen(false)}
      disableBackgroundClose={disableClose}
      disableCloseIcone={disableClose}
    >
      <AffichageConditionnel open={!message} className={MonStyle.Content}>
        <h3 className="HideMobile" style={{ width: "100%" }}>
          Nouveau mot de passe
        </h3>
        <InputPassword
          afficherControle
          afficherConseil={!disableClose}
          value={password}
          autoComplete="new-password"
          autoFocus
          placeholder="Nouveau mot de passe"
          onChange={handleChange}
        />
        <MessageErreur
          message={messageErreur}
          style={{ marginBottom: "20px" }}
        />
        <BoutonActionPrincipale
          texte={texteBouton}
          fullWidth
          taille="L"
          onClick={handleModifierPassword}
          disabled={!password}
        />
      </AffichageConditionnel>
      <AffichageConditionnel
        open={message ? true : false}
        className={MonStyle.Content}
      >
        <img src={IllustrationSucces} alt="" className="Illustration" />
        <h3>{message}</h3>
        <BoutonActionPrincipale
          texte="OK"
          fullWidth
          taille="L"
          style={{ marginTop: "40px" }}
          onClick={() => setOpen(false)}
        />
      </AffichageConditionnel>
    </SurfacePopUp>
  );
}
