import React, { useState } from "react";
import InputSelect from "../../../../Design/Formulaires/InputSelect";
import MonStyle from "./EditionConseilPrise.module.css";
import InputCheckbox from "../../../../Design/Formulaires/InputCheckbox";
import { useEffect } from "react";

export default function EditionConseilPrise({ conseilPrise, onChange }) {
  //NES Gestion du state
  const [data, setData] = useState({
    duree: "30 minutes",
    temperature: "fraiche",
  });

  useEffect(() => {
    if (conseilPrise) {
      setData(conseilPrise);
    }
  }, [conseilPrise]);

  const handleChange = (key, value) => {
    let newData = { ...data, [key]: value };
    setData(newData);
    if (onChange) {
      onChange(newData);
    }
  };

  const checkTemperature = (event) => {
    if (event.target.checked) {
      handleChange("temperature", event.target.name);
    } else {
      handleChange("temperature", "");
    }
  };

  return (
    <div className={MonStyle.EditionConseilPrise}>
      <div className={MonStyle.Phrase}>
        <span className={MonStyle.Texte}>Minimum</span>
        <div className={MonStyle.Select}>
          <InputSelect
            value={data.duree}
            theme="lecture"
            onChange={(e) => handleChange("duree", e.target.value)}
            options={[
              "10 minutes",
              "15 minutes",
              "30 minutes",
              "45 minutes",
              "1 heure",
            ]}
          />
        </div>
        <span className={MonStyle.Texte}> avant les repas avec de l'eau :</span>
      </div>

      <div>
        <InputCheckbox
          style={{ marginTop: "0px" }}
          valuesChecked={[data.temperature]}
          values={[
            { libelle: "fraiche", code: "FRAICHE" },
            { libelle: "tiède", code: "TIEDE" },
            { libelle: "chaude", code: "CHAUDE" },
            { libelle: "tempérée", code: "TEMPEREE" },
          ]}
          column="4"
          onClick={checkTemperature}
        />
      </div>
    </div>
  );
}
