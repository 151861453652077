export function DateToHeure(date) {
  let retour = "";
  if (date) {
    let EnDate = new Date(date);
    const options = {
      hour: "numeric",
      minute: "numeric",
    };
    retour = EnDate.toLocaleTimeString("fr-FR", options);
  }
  return retour;
}

export function AujourdhuiToString() {
  //exemple : mardi 8 décembre
  //mais avec la date du jour ;)
  let retour = "";
  const optionsDate = {
    weekday: "long",
    month: "long",
    day: "numeric",
  };
  retour = new Date(Date.now()).toLocaleDateString("fr-FR", optionsDate);
  return retour;
}

export function DateToString(dateMongo, format) {
  //exemple : mardi 8 décembre
  let retour = "";
  let optionsDate = {
    weekday: "long",
    // year: "numeric",
    month: "long",
    day: "numeric",
  };
  if (dateMongo) {
    retour = new Date(dateMongo).toLocaleDateString(
      "fr-FR",
      format ? format : optionsDate
    );
  }
  return retour;
}

//NES retourne au format date JJ/MM/YYYY finalement je l'utilise souvent
export function DateToDateString(date) {
  return DateToString(date, {
    year: "numeric",
    month: "numeric",
    day: "numeric",
  });
}

export function DateOuHeureToString(dateMongo, options) {
  //exemple : mardi 8 décembre ou 11:00 si c'est aujourd'hui
  let retour = "";

  const optionsDate = options
    ? options
    : {
        weekday: "long",
        month: "long",
        day: "numeric",
      };

  if (dateMongo) {
    let strDateMongo = new Date(dateMongo).toLocaleDateString(
      "fr-FR",
      optionsDate
    );

    let strDateFormelle = new Date(dateMongo).toLocaleDateString("fr-FR", {
      weekday: "long",
      month: "long",
      day: "numeric",
    });
    let strAujourdhui = AujourdhuiToString();
    if (strDateFormelle === strAujourdhui) {
      //NES je retourne l'heure
      retour = DateToHeure(dateMongo);
    } else {
      //NES je retourne la date
      retour = strDateMongo;
    }
  }
  return retour;
}

export function DateShortOuHeureToString(dateMongo, optionDateFormat) {
  //exemples
  // • aujourd'hui : 14:23
  // • cette année : 29/04
  // • sinon : 20/03/18

  let retour = "";
  const optionsDate = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  };
  const optionsDateShort = {
    month: "2-digit",
    day: "2-digit",
  };

  if (dateMongo) {
    dateMongo = new Date(dateMongo);
    let strDateMongo = dateMongo.toLocaleDateString("fr-FR", optionsDate);
    let strDateMongoShort = dateMongo.toLocaleDateString(
      "fr-FR",
      optionDateFormat ? optionDateFormat : optionsDateShort
    );
    let strAnneeMongo = dateMongo.getFullYear();

    let aujourdhui = new Date();
    let strAujourdhui = aujourdhui.toLocaleDateString("fr-FR", optionsDate);
    let strAnnee = aujourdhui.getFullYear();
    if (strDateMongo === strAujourdhui) {
      //NES je retourne l'heure
      retour = DateToHeure(dateMongo);
    } else {
      if (strAnneeMongo === strAnnee) {
        //NES je retourne la date
        retour = strDateMongoShort;
      } else {
        //NES je retourne la date
        retour = strDateMongo;
      }
    }
  }
  return retour;
}

export function DateEtHeureToString(dateMongo) {
  //exemple : mardi 8 décembre 2021, 11:00
  let retour = "";
  const optionsDate = {
    weekday: "long",
    month: "long",
    year: "numeric",

    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  };
  if (dateMongo) {
    let strDateMongo = new Date(dateMongo).toLocaleDateString(
      "fr-FR",
      optionsDate
    );
    let strAujourdhui = AujourdhuiToString();
    if (strDateMongo === strAujourdhui) {
      //NES je retourne l'heure
      retour = DateToHeure(dateMongo);
    } else {
      //NES je retourne la date
      retour = strDateMongo;
    }
  }
  return retour;
}

export function getJoursSemaine(dateSource) {
  //retourne toutes les dates d'une semaine contenant la date source
  let jours = [];
  if (dateSource) {
    let numeroJour = dateSource.getDay();
    //NES cas du dimanche qui donne 0
    if (numeroJour === 0) {
      numeroJour = 7;
    }
    //NES je calcule le lundi à partir de la date du jour
    let lundi = new Date(dateSource);
    lundi.setDate(lundi.getDate() - (numeroJour - 1));
    lundi.setHours(0);
    lundi.setMinutes(0);
    lundi.setSeconds(0);
    lundi.setMilliseconds(0);

    //NES je commence par ajouter le lundi
    jours.push(lundi);
    //NES puis tous les autres jours de la semaine
    for (let i = 1; i < 7; i++) {
      let jour = new Date(lundi);
      jour.setDate(jour.getDate() + i);
      jours.push(jour);
    }
  }
  return jours;
}

export function getHeuresJour(strHeureDebut, strHeureFin, intervalMinutes) {
  //retourne toutes les heures de la journée selon l'interval
  let retour = [];

  if (strHeureDebut && strHeureFin && intervalMinutes) {
    //NES calcul à partir du format "09:10"
    let arrayHeureDebut = strHeureDebut.split(":");
    let intHeureDebut = arrayHeureDebut[0];
    let intMinuteDebut = arrayHeureDebut[1];

    let arrayHeureFin = strHeureFin.split(":");
    let intHeureFin = arrayHeureFin[0];
    let intMinuteFin = arrayHeureFin[1];

    let heureDebut = new Date();
    heureDebut.setHours(intHeureDebut);
    heureDebut.setMinutes(intMinuteDebut);
    heureDebut.setSeconds(0);

    let heureFin = new Date();
    heureFin.setHours(intHeureFin);
    heureFin.setMinutes(intMinuteFin);
    heureFin.setSeconds(0);

    let heureSuivante = new Date(heureDebut);
    let listeHeures = [];

    while (heureSuivante <= heureFin) {
      let nouvelleHeure = new Date(heureSuivante);
      let strHeure = nouvelleHeure.toLocaleTimeString("fr-FR", {
        hour: "2-digit",
        minute: "2-digit",
      });
      listeHeures.push(strHeure);
      nouvelleHeure.setMinutes(nouvelleHeure.getMinutes() + intervalMinutes);
      heureSuivante = nouvelleHeure;
    }
    return listeHeures;
  }
  return retour;
}

export function getAllDaysFromMonth(madate) {
  //retourne toutes les dates de toutes les semaines du mois
  //on retrouve donc des dates du mois précédent dans la 1ère semaine
  //et des dates du mois suivant dans la dernirère semaine
  let retour = [];
  if (madate) {
    let date = new Date(madate);

    let mois = date.getMonth();

    //NES récupération du 1er jour du mois
    let premierJourDuMois = new Date(date);
    premierJourDuMois.setDate(1);

    //NES récupération des jours de la 1ère semaine
    let semaine = getJoursSemaine(premierJourDuMois);
    retour = retour.concat(semaine);

    //NES calcul du prochain lundi
    let prochainLundi = new Date(semaine[0]);
    prochainLundi.setDate(prochainLundi.getDate() + 7);

    //NES tant que le prochain lundi est dans le mois (attention jan=0)
    while (prochainLundi.getMonth() === mois) {
      //NES récupération des jours de la semaine
      let nouveauLundi = new Date(prochainLundi);
      semaine = getJoursSemaine(nouveauLundi);
      retour = retour.concat(semaine);
      //NES calcul du prochain lundi
      prochainLundi.setDate(prochainLundi.getDate() + 7);
    }
  }
  return retour;
}

export function getFirstDayOfMonth(madate) {
  //retourne la date du 1er jour du mois passé en paramètre
  let retour = "";
  //NES récupération du 1er jour du mois
  let premierJourDuMois = new Date(madate);
  premierJourDuMois.setDate(1);
  retour = premierJourDuMois;
  return retour;
}

export function getLastDayOfMonth(madate) {
  //retourne le dernier jour du mois passé en paramètre
  let retour = "";

  let monMois = madate.getMonth();
  let monAnnee = madate.getFullYear();
  let dernierJourDuMois = new Date(monAnnee, monMois + 1, 0);

  retour = dernierJourDuMois;

  return retour;
}

export function numeroJourToNomJour(numeroJour) {
  let retour = "";
  switch (numeroJour) {
    case 0:
      retour = "D";
      break;
    case 1:
      retour = "L";
      break;
    case 2:
      retour = "M";
      break;
    case 3:
      retour = "M";
      break;
    case 4:
      retour = "J";
      break;
    case 5:
      retour = "V";
      break;
    case 6:
      retour = "S";
      break;
    default:
      retour = "";

      break;
  }

  return retour;
}

export function setHeureFromString(date, strHeure) {
  //renseigne l'heure à partir d'un string 08:00 ou 08:00:00 sur une date
  //console.log({ strHeure });
  let retour = new Date(date);
  if (strHeure) {
    let arrayHeureMinute = strHeure.split(":");
    if (arrayHeureMinute.length >= 2) {
      let heure = arrayHeureMinute[0];
      let minutes = arrayHeureMinute[1];
      let secondes = arrayHeureMinute.length === 3 ? arrayHeureMinute[2] : 0;
      retour.setHours(heure);
      retour.setMinutes(minutes);
      retour.setSeconds(secondes);
    }
  }
  return retour;
}

export function addHeuresMinutes(strHeure, intHeure, intMinutes) {
  //Ajoute des heures ou minutes à une heure en string
  let retour = "";
  if (strHeure !== "" && intHeure !== "" && intMinutes !== "") {
    //NES calcul à partir du format "09:10"
    let arrayHeure = strHeure.split(":");
    let heure = parseInt(arrayHeure[0], 10);
    let minute = parseInt(arrayHeure[1], 10);
    let nouvelleHeure = new Date();
    nouvelleHeure.setHours(heure + intHeure);
    nouvelleHeure.setMinutes(minute + intMinutes);
    nouvelleHeure.setSeconds(0);
    retour = nouvelleHeure.toLocaleTimeString("fr-FR", {
      hour: "2-digit",
      minute: "2-digit",
    });
  }
  return retour;
}

export function timeToMinutes(strTime) {
  let retour = 0;
  let array = strTime.split(":");
  let heures = parseInt(array[0], 10);
  let minutes = parseInt(array[1], 10);
  retour = heures * 60 + minutes;
  return retour;
}

export function toApiDate(date) {
  let retour = "";
  if (date) {
    let annee = date.toLocaleDateString("fr-FR", { year: "numeric" });
    let mois = date.toLocaleDateString("fr-FR", { month: "2-digit" });
    let jour = date.toLocaleDateString("fr-FR", { day: "2-digit" });
    retour = annee + "-" + mois + "-" + jour;
  }
  return retour;
}

export function setDateFromString(strDate) {
  //Construit une date à partir de la date en string
  let retour = new Date();
  if (strDate) {
    let maDate = new Date();
    console.log({ strDate, maDate });
  }
  return retour;
}

export function getMoisSinceDate(date) {
  let startDate = new Date(date);
  //NES je démarre au début du mois
  startDate.setDate(1);
  let aujourdhui = new Date();
  let retour = [];

  while (startDate <= aujourdhui) {
    let prochainMois = new Date(startDate);
    prochainMois = dateToMoisAnnee(prochainMois);
    retour.push(prochainMois);
    //NES calcul du prochain mois
    startDate.setMonth(startDate.getMonth() + 1);
  }

  return retour;
}

export function dateToMoisAnnee(date) {
  if (date) {
    let maDate = new Date(date);
    const optionsDate = {
      year: "numeric",
      month: "short",
    };
    return maDate.toLocaleDateString("fr-FR", optionsDate);
  } else {
    return "";
  }
}

//NES Retourne toutes les dates entre les dates de début et de fin (comprises)
export function getAllDateBetweenDates(dateDebut, dateFin) {
  let listeDates = [];
  if (dateDebut && dateFin) {
    //NES Je commence par mettre les heures minutes secondes à 00:00:00 pour éviter les pb de merde
    dateDebut = setHeureFromString(dateDebut, "00:00:00");
    dateFin = setHeureFromString(dateFin, "00:00:00");
    //NES je commence avec la date de début
    let maDate = new Date(dateDebut);
    while (maDate <= dateFin) {
      //NES pour éviter la modification en mémoire
      let dateToPush = new Date(maDate);
      //NES j'ajoute la date à la liste
      listeDates.push(dateToPush);
      maDate.setDate(maDate.getDate() + 1);
    }
  }
  return listeDates;
}

//NES Retourne toutes les dates entre les dates de début et de fin (comprises)
export function nombreJoursBetweenDates(dateDebut, dateFin) {
  let listeDates = [];
  let retour = 0;
  if (dateDebut && dateFin) {
    listeDates = getAllDateBetweenDates(dateDebut, dateFin);
    retour = listeDates.length;
  }

  return retour;
}

//NES retourne les numéros de semaines du mois
export function getNumerosSemainesFromMonth(date) {
  let retour = [];
  let joursDuMois = getAllDaysFromMonth(date);

  let i = 0;

  while (i < joursDuMois.length) {
    let lundi = joursDuMois[i];

    retour.push(lundi.getWeek());
    i = i + 7;
  }

  return retour;
}

//NES retourne le numéro de la semaine pour une date
// eslint-disable-next-line
Date.prototype.getWeek = function () {
  var date = new Date(this.getTime());
  date.setHours(0, 0, 0, 0);
  // Thursday in current week decides the year.
  date.setDate(date.getDate() + 3 - ((date.getDay() + 6) % 7));
  // January 4 is always in week 1.
  var week1 = new Date(date.getFullYear(), 0, 4);
  // Adjust to Thursday in week 1 and count number of weeks from date to week1.
  return (
    1 +
    Math.round(
      ((date.getTime() - week1.getTime()) / 86400000 -
        3 +
        ((week1.getDay() + 6) % 7)) /
        7
    )
  );
};

//NES soustraction nb jours à une date
export function soustraireJours(date, nbjours) {
  let dateRetour = new Date(date);
  if (nbjours) {
    dateRetour.setDate(dateRetour.getDate() - nbjours);
  }

  return dateRetour;
}
