import React, { useEffect, useState } from "react";
import IconesContacts from "../Icones/IconesContacts";
import MonStyle from "./IconeContactsPicker.module.css";

export default function IconeContactsPicker({ value, onChange }) {
  const ICONES = [
    { icone: "IconeMedecin", label: "Médecin" },
    { icone: "IconeInfirmiere", label: "Infirmier(e)" },
    { icone: "IconePharmacie", label: "Pharmacie" },
    { icone: "IconeAutreContact", label: "Autre" },
  ];

  //NES gestion du state
  const [selected, setSelected] = useState();

  //USEEFFECT value
  useEffect(() => {
    setSelected(value);
  }, [value]);

  //NES handleClick
  const handleClick = (nomIcone) => {
    if (nomIcone === selected) {
      setSelected("");
      if (onChange) {
        onChange("");
      }
    } else {
      setSelected(nomIcone);
      if (onChange) {
        onChange(nomIcone);
      }
    }
  };

  return (
    <div className={MonStyle.IconeContactsPicker}>
      {ICONES.map((icone) => (
        <div
          className={
            selected === icone.icone ? MonStyle.BoutonSelected : MonStyle.Bouton
          }
          onClick={() => handleClick(icone.icone)}
        >
          <IconesContacts nomIcone={icone.icone} />
          <div
            className="Label"
            style={{ color: selected === icone.icone ? "#208AB0" : "" }}
          >
            {icone.label}
          </div>
        </div>
      ))}
    </div>
  );
}
