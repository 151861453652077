import React, { useContext, useEffect } from "react";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import { AgendasContext } from "../../../../Data/AgendasContext";
import DnsIcon from "@material-ui/icons/Dns";
import ReorderIcon from "@material-ui/icons/Reorder";
import StorageIcon from "@material-ui/icons/Storage";
import AppTooltip from "../../../../Design/DataDisplay/ToolTip";

export default function BoutonsAffichage({ style }) {
  //NES Gestion du context
  const {
    hauteurMiniHeure,
    setHauteurMiniHeure,
    getPreferences,
    patchPreferences,
  } = useContext(AgendasContext);

  const handleChangeTaille = (event, taille) => {
    if (taille) {
      //NES appel de l'API pour stocker la valeur
      let data = { affichage_taille_rdv: taille };
      setHauteurMiniHeure(taille);
      patchPreferences(data);
    }
  };

  //USEEFFECT
  useEffect(() => {
    getPreferences();
    // eslint-disable-next-line
  }, []);

  return (
    <ToggleButtonGroup
      value={hauteurMiniHeure.toString()}
      exclusive
      onChange={handleChangeTaille}
      aria-label="text alignment"
      style={style}
    >
      <ToggleButton value="20" aria-label="left aligned">
        <AppTooltip texte="Affichage petite taille">
          <ReorderIcon />
        </AppTooltip>
      </ToggleButton>

      <ToggleButton value="40" aria-label="centered">
        <AppTooltip texte="Affichage taille moyenne">
          <StorageIcon />
        </AppTooltip>
      </ToggleButton>
      <ToggleButton value="60" aria-label="right aligned">
        <AppTooltip texte="Affichage grande taille">
          <DnsIcon />
        </AppTooltip>
      </ToggleButton>
    </ToggleButtonGroup>
  );
}
