import React, { useState, createContext } from "react";
import Notification from "../Components/Notification/Notification";
//NES Création du contexte
export const NotificationsContext = createContext();

export const NotificationsProvider = (props) => {
  //NES Gestion du state
  const [notifications, setNotifications] = useState([]);

  const ajouterNotification = (data) => {
    let id = Date.now();
    let newData = { _id: id, ...data };
    setNotifications((prev) => [...prev, newData]);
  };

  const supprimerNotification = (notification_id) => {
    setNotifications((prev) =>
      prev.filter((item) => item._id !== notification_id)
    );
  };

  return (
    <NotificationsContext.Provider value={{ ajouterNotification }}>
      <div>
        {notifications.map((item, index) => (
          <Notification
            index={index}
            key={item._id}
            onClose={() => supprimerNotification(item._id)}
            type={item.type}
            titre={item.titre}
            sousTitre={item.sousTitre}
            texte={item.texte}
            link={item.link}
            avatar={item.avatar}
          />
        ))}
      </div>
      {props.children}
    </NotificationsContext.Provider>
  );
};
