import React, { useContext, useEffect, useState } from "react";
import ObjetCommentaire from "../../../../../Design/Objets/ObjetCommentaire";
import BoutonAjoutBloc from "../../../../../Design/Boutons/BoutonAjoutBloc";
import { CommentairesPatientsContext } from "../../../../../Data/CommentairesPatientsContext";
import { TextareaAutosize } from "@material-ui/core";
import BoutonActionPrincipale from "../../../../../Design/Boutons/BoutonActionPrincipale";
import BoutonAnnuler from "../../../../../Design/Boutons/BoutonAnnuler";
import MessageErreur from "../../../../../Design/Messages/MessageErreur";
import SurfaceZoneSecondaire from "../../../../../Design/Surfaces/SurfaceZoneSecondaire";

function AjouterCommentaire({ onBlur, onEnregistrer, onAnnuler }) {
  //NES Gestion du state
  const [commentaire, setCommentaire] = useState("");
  //NES Inline style
  let styleTextArea = {
    padding: "10px",
    fontFamily: "Lato",
    fontSize: "16px",
    color: "#208ab0",
    resize: "none",
    width: "calc(100% - 20px)",
    outline: "none",
    border: "1px solid #187B9F",
    backgroundColor: "white",
    borderRadius: "4px",
    WebkitAppearance: "none",
  };

  //NES enregistrer le commentaire
  const handleEnregistrer = () => {
    if (onEnregistrer) {
      onEnregistrer(commentaire);
    }
  };

  return (
    <div>
      <TextareaAutosize
        style={styleTextArea}
        placeholder="Saisissez votre commentaire"
        name="Saisissez votre commentaire"
        autoFocus
        minRows={3}
        onBlur={onBlur}
        onChange={(e) => setCommentaire(e.target.value)}
      />
      <BoutonActionPrincipale
        texte="Enregistrer le commentaire"
        fullWidth
        style={{ marginTop: "15px" }}
        disabled={commentaire === "" ? true : false}
        onClick={handleEnregistrer}
      />
      <BoutonAnnuler
        fullWidth
        style={{ marginTop: "10px" }}
        onClick={onAnnuler}
      />
    </div>
  );
}

export default function Commentaires({ patient_id }) {
  //NES Gestion du state
  const [afficherNouveau, setAfficherNouveau] = useState(false);
  const [listeCommentaires, setListeCommentaire] = useState([]);
  const [erreur, setErreur] = useState("");

  //NES gestion du contexte
  const {
    commentairesPatients,
    getCommentairesContext,
    postCommentairesContext,
    patchCommentairesContext,
    deleteCommentairesContext,
  } = useContext(CommentairesPatientsContext);

  //USEEFFECT
  useEffect(() => {
    if (commentairesPatients) {
      let liste = commentairesPatients.filter(
        (item) => item.patient_id === patient_id
      );

      setListeCommentaire(liste);
    }
  }, [commentairesPatients, patient_id]);

  //GET Commentaires
  useEffect(() => {
    if (patient_id && patient_id !== "nouveau") {
      getCommentairesContext(patient_id);
    }
    // eslint-disable-next-line
  }, [patient_id]);

  //POST ajouter un commentaire
  const ajouterCommentaire = (texte) => {
    if (texte) {
      //NES construction de l'objet d'appel
      let nouveauCommentaire = {
        texte: texte,
        is_personnel: false,
      };
      //NES je mets à jour le contexte
      postCommentairesContext(patient_id, nouveauCommentaire).then(
        (reponseApi) => {
          reponseApi.code === "OK"
            ? setErreur("")
            : setErreur("Erreur lors de l'enregistrement du commentaire");
        }
      );
      //NES masquer la zone ajout commentaire
      setAfficherNouveau(false);
    }
  };

  //DELETE du commentaire
  const supprimerCommentaire = (id) => {
    if (id) {
      //NES je mets à jour le contexte
      deleteCommentairesContext(patient_id, id).then((reponseApi) => {
        reponseApi.code === "OK"
          ? setErreur("")
          : setErreur("Erreur lors de la suppression du commentaire");
      });
    }
  };

  //PATCH commentaire
  const sauvegarderCommentaire = (data) => {
    if (data._id) {
      patchCommentairesContext(patient_id, data).then((reponseApi) => {
        reponseApi.code === "OK"
          ? setErreur("")
          : setErreur("Erreur lors de l'enregistrement du commentaire");
      });
    }
  };

  return (
    <SurfaceZoneSecondaire id="commentaires">
      <h5 style={{ marginBottom: "30px", textAlign: "center" }}>
        Commentaires
      </h5>
      <div style={{ display: "flex", flexDirection: "column", gap: "30px" }}>
        {listeCommentaires.map((item, index) => (
          <ObjetCommentaire
            key={index}
            onClickSupprimer={() => supprimerCommentaire(item._id)}
            onClickPersonnel={sauvegarderCommentaire}
            commentaire={item}
            onChange={sauvegarderCommentaire}
            onBlur={sauvegarderCommentaire}
            id={"commentaire_" + index}
          />
        ))}
      </div>

      <div style={{ marginTop: "30px" }} id="DernierCommentaire">
        {afficherNouveau ? (
          <AjouterCommentaire
            onAnnuler={() => setAfficherNouveau(false)}
            onEnregistrer={ajouterCommentaire}
          />
        ) : (
          <BoutonAjoutBloc
            texte="Ajouter un commentaire"
            fullWidth
            onClick={() => setAfficherNouveau(true)}
          />
        )}
        <MessageErreur message={erreur} style={{ marginTop: "20px" }} />
      </div>
    </SurfaceZoneSecondaire>
  );
}
