import React from "react";
import TextField from "@material-ui/core/TextField";
import { withStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";

const CssTextField = withStyles({
  root: {
    width: "100%",
    backgroundColor: (props) => props.montheme.backgroundColor,
    "& .MuiInputBase-input": {
      color: (props) => (props.couleur === "erreur" ? "#F44336" : "#208AB0"),
      textTransform: (props) =>
        props.texttransform ? props.texttransform : "none",
      "&.Mui-disabled ": {
        color: (props) => props.montheme.disabled.color,
        backgroundColor: (props) => props.montheme.disabled.backgroundColor,
        borderRadius: "4px",
      },
    },
    "& .MuiInputBase-input::placeholder": {
      color: "#666666",
    },

    "& label": {
      color: (props) => (props.couleur === "erreur" ? "#F44336" : "#00000087"),
    },
    "& label.Mui-focused": {
      color: (props) => (props.couleur === "erreur" ? "#F44336" : "#208AB0"),
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "DDD",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: (props) =>
          props.couleur === "erreur" ? "#F44336" : props.montheme.borderColor,
      },
      "&:hover fieldset": {
        borderColor: (props) =>
          props.couleur === "erreur" ? "#F44336" : props.montheme.borderColor,
      },
      "&.Mui-focused fieldset": {
        borderColor: (props) =>
          props.couleur === "erreur" ? "#F44336" : "#208AB0",
      },
      "&.Mui-disabled fieldset": {
        borderColor: (props) => props.montheme.borderColor,
      },
    },
  },
})(TextField);

export default function InputSelect({
  label,
  value,
  textTransform,
  onChange,
  onBlur,
  autoFocus,
  id,
  theme,
  values,
  options,
  enabledAucuneValeur,
  placeholder,
  style,
  ...props
}) {
  //NES themes selon les variantes d'affichage
  const themeModeLecture = {
    color: "#208AB0",
    borderColor: "#FFFFFF00",
    marginTop: "0px",
    backgroundColor: "none",

    disabled: {
      color: "black",
      backgroundColor: "#FFFFFF00",
    },
  };

  const themeModeFormulaire = {
    color: "#208AB0",
    borderColor: "#DDDDDD",
    marginTop: "20px",
    backgroundColor: "white",
    disabled: {
      color: "#97989D",
      backgroundColor: "#EDEDED",
    },
  };

  let montheme = themeModeFormulaire;
  switch (theme) {
    case "lecture":
      montheme = themeModeLecture;
      break;
    default:
      montheme = themeModeFormulaire;
      break;
  }

  return (
    <CssTextField
      montheme={montheme}
      select
      id={id}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      label={label}
      variant="outlined"
      texttransform={textTransform}
      size="small"
      autoFocus={autoFocus}
      style={{ marginTop: montheme.marginTop, ...style }}
      placeholder={placeholder}
      {...props}
    >
      {enabledAucuneValeur && (
        <MenuItem
          key=""
          value=""
          style={{ color: "#97989D", fontStyle: "italic" }}
        >
          Aucune valeur
        </MenuItem>
      )}
      {options &&
        options.map((option, index) => (
          <MenuItem
            key={option}
            value={values !== undefined ? values[index] : option}
          >
            {option}
          </MenuItem>
        ))}
    </CssTextField>
  );
}
