import React, { useEffect, useState } from "react";
import InputTexte from "./InputTexte";
import {
  formatterTelephone,
  formatterOnlyChiffres,
  getEmoticonPays,
} from "../../functions/Formatter";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import PhoneIcon from "@material-ui/icons/Phone";
import PhoneIphoneIcon from "@material-ui/icons/PhoneIphone";
import WorkIcon from "@material-ui/icons/Work";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import Divider from "@material-ui/core/Divider";
import BoutonTexte from "../Boutons/BoutonTexte";
import SurfacePopup from "../Surfaces/SurfacePopUp";
import BoutonActionPrincipale from "../Boutons/BoutonActionPrincipale";
import {
  isTelephonePortable,
  getPaysTelephone,
  isTelephone,
} from "../../functions/FonctionsTelephone";
import InputAdornment from "@material-ui/core/InputAdornment";

const modeleTelephone = {
  numero: "",
  type: "Téléphone",
  is_favori: false,
  is_portable: false,
};

function MenuTelephone({
  onTypeChange,
  onClickSuppression,
  onClickPersonnaliser,
}) {
  return (
    <div>
      <div className="TitreBloc" style={{ margin: "10px" }}>
        Type de téléphone
      </div>
      {/* MENU TYPE DE TELEPHONE  */}
      <MenuItem onClick={() => onTypeChange("Téléphone")}>
        <PhoneIcon style={{ color: "#666", marginRight: "15px" }} />
        <ListItemText primary="Téléphone" />
      </MenuItem>
      <MenuItem onClick={() => onTypeChange("Portable")}>
        <PhoneIphoneIcon style={{ color: "#666", marginRight: "15px" }} />
        <ListItemText primary="Portable" />
      </MenuItem>
      <MenuItem onClick={() => onTypeChange("Travail")}>
        <WorkIcon style={{ color: "#666", marginRight: "15px" }} />
        <ListItemText primary="Travail" />
      </MenuItem>
      <MenuItem onClick={() => onTypeChange("Parents")}>
        <SupervisorAccountIcon style={{ color: "#666", marginRight: "15px" }} />
        <ListItemText primary="Parents" />
      </MenuItem>
      {/* BOUTON TELEPHONE PERSONNALISE */}
      <BoutonTexte
        texte="Autre (ex: tuteur...)"
        fullWidth
        onClick={onClickPersonnaliser}
      />
      <Divider style={{ margin: "10px 0px" }} />
      <MenuItem style={{ color: "red" }} onClick={onClickSuppression}>
        <ListItemIcon>
          <DeleteForeverIcon style={{ color: "red" }} />
        </ListItemIcon>
        <ListItemText primary="Supprimer" />
      </MenuItem>
    </div>
  );
}

export default function InputTelephone({
  objTelephone,
  onTelephoneChange,
  autoFocus,
  onBlur,
  id,
  label,
  autoComplete,
  theme,
  placeholder,
  onClickSuppression,
  disableTypeChange,
}) {
  //NES Gestion du state
  const [isWarning, setIsWarning] = useState("");
  const [telephone, setTelephone] = useState(modeleTelephone);
  const [afficherPopup, setAfficherPopup] = useState(false);
  const [typePerso, setTypePerso] = useState("");

  useEffect(() => {
    if (objTelephone?.numero) {
      //NES je reformatte le numéro de téléphone
      let data = {
        ...objTelephone,
        numero: objTelephone.numero,
      };
      setTelephone(data);
    }
  }, [objTelephone]);

  //NES Gestion du type personnalisé
  const handleChangeTypePerso = (event) => {
    setTypePerso(event.target.value);
  };

  //NES sauvegarde du type personnalise
  const handleSavePerso = () => {
    handleTypeChange(typePerso);
    setAfficherPopup(false);
  };

  //NES Gestion des modifications
  const handleTypeChange = (value) => {
    let newData = { ...telephone, type: value };

    //NES si c'est téléphone je force le boolean à 0
    if (value === "Téléphone") {
      newData = { ...newData, is_portable: false };
    }

    //NES Si c'est portable je force le boolean à 1
    if (value === "Portable") {
      newData = { ...newData, is_portable: true };
    }

    setTelephone(newData);
    if (onTelephoneChange) {
      onTelephoneChange(newData);
    }
    if (onBlur) {
      onBlur(newData);
    }
  };

  const handleNumeroChange = (value) => {
    let texteSansLettres = formatterOnlyChiffres(value, true);
    let newData = { ...telephone, numero: texteSansLettres };

    //NES pour gérer l'erreur quand on corrige
    if (isWarning) {
      if (isTelephone(texteSansLettres)) {
        setIsWarning("");
      }
    }

    //NES pour le boolean pour envoyer des sms
    if (isTelephonePortable(texteSansLettres)) {
      newData = { ...newData, is_portable: true };

      if (telephone.type === "Téléphone") {
        newData = { ...newData, type: "Portable" };
      }
    } else {
      newData = { ...newData, is_portable: false };
      if (telephone.type === "Portable") {
        newData = { ...newData, type: "Téléphone" };
      }
    }
    //NES autodetection du pays
    newData = { ...newData, pays: getPaysTelephone(texteSansLettres) };

    setTelephone(newData);
    if (onTelephoneChange) {
      onTelephoneChange(newData);
    }
  };

  //NES quand je quitte le champ je controle et je formatte le téléphone
  const handleBlur = (event) => {
    let texte = event.target.value;
    //NES je formatte le téléphone
    if (texte.length === 10) {
      texte = formatterTelephone(texte);
      event.target.value = texte;
      handleNumeroChange(texte);
    }
    //NES controle du n° de téléphone
    if (texte !== "") {
      if (isTelephone(texte)) {
        setIsWarning("");
      } else {
        setIsWarning("Le numéro de téléphone est incorrect");
      }
    } else {
      setIsWarning("");
    }
    if (onBlur) {
      onBlur(event);
    }
  };

  return (
    <div>
      <InputTexte
        id={id}
        isErreur={isWarning}
        value={telephone.numero}
        label={isWarning ? isWarning : label ? label : telephone.type}
        onChange={(event) => handleNumeroChange(event.target.value)}
        autoFocus={autoFocus}
        onBlur={handleBlur}
        autoComplete={autoComplete}
        theme={theme}
        placeholder={placeholder}
        startAdornment={
          telephone.pays && (
            <InputAdornment position="start">
              {getEmoticonPays(telephone.pays)}
            </InputAdornment>
          )
        }
        menu={
          disableTypeChange ? (
            ""
          ) : (
            <MenuTelephone
              onTypeChange={(type) => handleTypeChange(type)}
              onClickSuppression={onClickSuppression}
              onClickPersonnaliser={() => setAfficherPopup(true)}
            />
          )
        }
      />
      <SurfacePopup
        open={afficherPopup}
        onClose={() => setAfficherPopup(false)}
        titre="Téléphone"
        sousTitre="  Vous pouvez préciser le type de téléphone, par exemple : tuteur,
        enfants..."
      >
        <InputTexte
          autoFocus
          label="Type de téléphone"
          onChange={handleChangeTypePerso}
          value={typePerso}
        />
        <BoutonActionPrincipale
          texte="OK"
          couleur="bleu"
          fullWidth
          style={{ marginTop: "20px" }}
          onClick={handleSavePerso}
        />
      </SurfacePopup>
    </div>
  );
}
