import React, { useContext, useEffect, useState } from "react";
import IconesMesures from "../../../../Design/Icones/IconesMesures";
import TextField from "@material-ui/core/TextField";
import { withStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import BookmarkBorderIcon from "@material-ui/icons/BookmarkBorder";
import BoutonRond from "../../../../Design/Boutons/BoutonRond";
import { formatterDecimal } from "../../../../functions/Formatter";
import { ConsultationsContext } from "../../../../Data/ConsultationsContext";
import { ConstantesContext } from "../../../../Data/ConstantesContext";
import TurnedInIcon from "@material-ui/icons/TurnedIn";
import { couleurAction } from "../../../../functions/FonctionsCouleurs";

//NES Style de composant NORMAL
const CssTextField = withStyles({
  root: {
    // height: "40px",
    width: (props) => (props.fullWidth ? "100%" : props.monstyle.width),
    "& .MuiInputBase-input::placeholder": {
      color: "black",
    },
    "& .MuiInputLabel-outlined": {
      color: "#00000060",
    },
    "& .MuiOutlinedInput-root": {
      backgroundColor: (props) => props.monstyle.backgroundColor,
      borderColor: (props) => props.monstyle.borderColor,
      borderRadius: "30px",
      color: (props) => props.monstyle.color,
      textAlign: "center",
      "&.Mui-focused": {
        backgroundColor: "white",
      },
      "& fieldset": {
        borderColor: (props) => props.monstyle.borderColor,
      },
      "&:hover fieldset": {
        borderColor: "#208AB0",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#208AB0",
      },
      "&.Mui-disabled fieldset": {
        borderColor: "#DDD",
      },
    },
  },
})(TextField);

export default function EditionMesure({
  consultation_id,
  patient_id,
  mesure,
  fullWidth,
  style,
}) {
  //NES Gestion du style si je n'ai pas de texte
  let MonStyleVide = {
    backgroundColor: "#F6F6F6",
    borderColor: "#F6F6F6",
    couleurIcone: "#666666",
    color: "#208AB0",
  };

  //NES Gestion du style si j'ai un texte
  let MonStyleAvecTexte = {
    backgroundColor: "white",
    borderColor: "#DDD",
    couleurIcone: mesure.couleur ? mesure.couleur : "#208AB0",
    color: "#208AB0",
  };

  // //NES Gestion du style si j'ai un texte
  // let MonStyleAvecAlerte = {
  //   backgroundColor: "white",
  //   borderColor: "red",
  //   couleurIcone: "red",
  //   color: "red",
  // };

  //NES Gestion du contexte
  const { putConsultationMesure } = useContext(ConsultationsContext);
  const { bookmarkMesuresPatient, mesuresPatient } =
    useContext(ConstantesContext);

  //NES gestion du state
  const [data, setData] = useState({
    mesure_id: "",
    icone_mesure: "",
    valeur: "",
    libelle: "",
    libelle_court: "",
    code: "",
    unite: "",
    is_number: false,
    alerte: {},
  });
  const [isBookmarked, setIsBookMarked] = useState(false);
  const [width, setWidth] = useState("150px");
  const [theme, setTheme] = useState(
    mesure.valeur ? MonStyleAvecTexte : MonStyleVide
  );

  //USEEFFECT
  useEffect(() => {
    let tempData = {
      mesure_id: mesure._id ? mesure._id : mesure.mesure_id,
      icone_mesure: mesure.icone_mesure,
      valeur: mesure.valeur ? mesure.valeur : "",
      libelle: mesure.libelle,
      libelle_court: mesure.libelle_court,
      code: mesure.code,
      unite: mesure.unite,
      is_number: mesure.is_number,
      alerte: {},
    };
    //console.log({ tempData });
    setData(tempData);
  }, [mesure]);

  //USEEFFECT
  useEffect(() => {
    if (mesuresPatient.length) {
      let maMesurePatient = mesuresPatient.find(
        (item) => item.mesure_id === data.mesure_id
      );

      setIsBookMarked(maMesurePatient?.is_bookmark);
    }
  }, [mesuresPatient, data]);

  /// quand on change la valeur
  const handleValeurChange = (e) => {
    let texte = e.target.value;
    console.log(texte);
    //NES si la valeur est numérique alors j'empeche les lettre
    if (data.is_number) {
      texte = formatterDecimal(texte);
    }
    //NES modification de l'affichage
    if (texte) {
      setTheme(MonStyleAvecTexte);
    } else {
      setTheme(MonStyleVide);
    }
    setData({ ...data, valeur: texte });
  };

  //PUT quand on quitte le champ
  const handleBlur = () => {
    console.log("handleBlur");

    putConsultationMesure(consultation_id, patient_id, data.mesure_id, data);

    // if (consultation_id && patient_id && mesure_id) {
    //   if (data.valeur === "") {
    //     //NES On efface la mesure pour la consultation
    //     deleteConsultationMesureContext(patient_id, consultation_id, mesure_id);
    //   } else {
    //     putConsultationMesureContext(
    //       consultation_id,
    //       patient_id,
    //       mesure_id,
    //       data
    //     );
    //   }
    // }
  };

  //PATCH gestion du bookmark de la constante pour le patient
  const handleBookmark = (valeur) => {
    bookmarkMesuresPatient(patient_id, data.mesure_id, valeur);
  };

  //USEEFFECT calcul de la taille dynamique
  useEffect(() => {
    //NES Taille du placeholder
    let nbCaracteresPlaceholder = 0;
    if (data.libelle) {
      nbCaracteresPlaceholder = data.libelle.length;
    } else {
      nbCaracteresPlaceholder = 0;
    }

    //NEs taille de l'unité de mesure
    let nbCaractereMesures = data.unite ? data.unite.length : 0;

    //NES taille de la valeur
    let nbCaractereValeur = data.valeur
      ? data.valeur.replace(",", "").length
      : 0;

    let nbCaracteres = 0;
    if (nbCaractereValeur) {
      nbCaracteres = nbCaractereMesures + nbCaractereValeur;
    } else {
      nbCaracteres = nbCaracteresPlaceholder;
    }

    let largeurCaracteres = nbCaracteres * 8;
    let largeurNecessaire = largeurCaracteres + 60 + 80;
    let largeurMinimale = 200;

    //console.log(data.libelle, nbCaracteres);

    if (largeurNecessaire > largeurMinimale) {
      setWidth(largeurNecessaire + "px");
    } else {
      setWidth(largeurMinimale + "px");
    }
  }, [data]);

  return (
    <CssTextField
      disabled={!consultation_id || !patient_id}
      value={data.valeur}
      onChange={handleValeurChange}
      onBlur={handleBlur}
      variant="outlined"
      monstyle={{ ...theme, width: width }}
      placeholder={mesure.libelle}
      fullWidth={fullWidth}
      label={data.valeur ? mesure.libelle : ""}
      //helperText="coucou"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <IconesMesures
              nomIcone={data.icone_mesure}
              style={{ color: theme.couleurIcone }}
            />
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end">
            <div
              style={{
                color: "#97989D",
                display: "flex",
                alignItems: "center",
              }}
            >
              {data.valeur || fullWidth ? data.unite : ""}

              <BoutonRond
                onClick={() => handleBookmark(!isBookmarked)}
                iconeComposant={
                  isBookmarked ? (
                    <TurnedInIcon style={{ color: couleurAction() }} />
                  ) : (
                    <BookmarkBorderIcon />
                  )
                }
                style={{ marginLeft: "10px", color: "#DDDDDD" }}
                tooltip="Suivre la constante pour ce patient"
              />
            </div>
          </InputAdornment>
        ),
      }}
    />
  );
}
