import React from "react";
import IllustrationAucuneDonne from "../../Images/Illustrations/AucuneDonnee.svg";

export default function MessageAucuneDonnee({
  message,
  conseil,
  hideIllustration,
  style,
  ...props
}) {
  const Style = {
    marginTop: "50px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    ...style,
  };

  if (message) {
    return (
      <div style={Style}>
        {hideIllustration !== true ? (
          <img
            src={IllustrationAucuneDonne}
            alt="Aucune donnée trouvée"
            style={{ width: "300px" }}
          />
        ) : (
          ""
        )}
        <div
          style={{
            fontFamily: "Raleway",
            fontWeight: 900,
            fontSize: "24px",
            color: "#213945",
            textAlign: "center",
          }}
        >
          {message}
        </div>
        <div className="SousTitre">{conseil}</div>
        <div>{props.children}</div>
      </div>
    );
  } else {
    return "";
  }
}
