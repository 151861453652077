import React, { useContext, useEffect, useState } from "react";
import BoutonActionSecondaire from "../../../../Design/Boutons/BoutonActionSecondaire";
import MessageErreur from "../../../../Design/Messages/MessageErreur";
import { CotationsContext } from "../../../../Data/CotationsContext";
import EditionCotation from "./EditionCotation";
import SurfacePapier from "../../../../Design/Surfaces/SurfacePapier";
import BoutonActionPrincipale from "../../../../Design/Boutons/BoutonActionPrincipale";
import { numberToEuro } from "../../../../functions/Formatter";
import { PatientsContext } from "../../../../Data/PatientsContext";
import { CabinetsContext } from "../../../../Data/CabinetContext";
import LoaderHorizontal from "../../../../Design/Progression/LoaderHorizontal";

export default function ListeCotations({
  patient_id,
  consultation_id,
  maConsultation,
}) {
  //NES Gestion du contexte
  const {
    cotations,
    postCotation,
    getCotationsPatientNonFacture,
    postFacture,
  } = useContext(CotationsContext);

  const { getObjetPatient } = useContext(PatientsContext);
  const { getObjetCabinet } = useContext(CabinetsContext);

  //NES Gestion du state
  const [cotationsConsultation, setCotationConsultations] = useState([]);
  const [cotationsPrecedentes, setCotationPrecedentes] = useState([]);
  const [listeIdSelected, setListeIdSelected] = useState([]);
  const [messageErreur, setMessageErreur] = useState("");
  const [messageErreurChargement, setMessageErreurChargement] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [libelleBoutonFacturer, setLibelleBoutonFacturer] =
    useState("Facturer");

  //USEEFFECT Affichage dynamique du libelle du bouton facturer
  useEffect(() => {
    if (listeIdSelected.length > 1 && cotations) {
      let tempTotal = 0;
      cotations.forEach((cotation) => {
        if (listeIdSelected.includes(cotation._id)) {
          tempTotal = tempTotal + cotation?.part_patient.montant_a_regler;
        }
      });
      tempTotal = numberToEuro(tempTotal, " €");
      let libelleBouton =
        "Facturer " +
        listeIdSelected.length +
        " consultations pour " +
        tempTotal;
      setLibelleBoutonFacturer(libelleBouton);
    } else {
      setLibelleBoutonFacturer("Facturer");
    }
  }, [listeIdSelected, cotations]);

  //USEEFFECT chargement des cotations à afficher
  useEffect(() => {
    let active = true;

    if (patient_id && consultation_id) {
      setIsLoading(true);
      getCotationsPatientNonFacture(patient_id).then((reponse) => {
        if (active) {
          setIsLoading(false);

          if (reponse.code !== "OK") {
            setMessageErreurChargement(
              "Erreur lors de la récupération des cotations non réglées"
            );
            setError(reponse);
          }
        }
      });
    }
    return () => {
      active = false;
    };
    // eslint-disable-next-line
  }, [patient_id, consultation_id]);

  //USEEFFECT mise à jour selon le contexte
  useEffect(() => {
    let active = true;

    if (cotations && maConsultation._id && active) {
      let tempCotationsConsultations = [...cotations];
      let tempCotationsPrececentes = [...cotations];

      tempCotationsConsultations = tempCotationsConsultations.filter(
        (item) => item.consultation_id === maConsultation._id
      );
      tempCotationsPrececentes = tempCotationsPrececentes.filter(
        (item) => item.consultation_id !== maConsultation._id
      );

      setCotationConsultations(tempCotationsConsultations);
      setCotationPrecedentes(tempCotationsPrececentes);
    }

    return () => (active = false);
  }, [cotations, maConsultation._id]);

  //POST ajouter une cotation
  const ajouterCotation = () => {
    console.log({ maConsultation });

    let data = {
      date_cotation: maConsultation.date_consultation,
      consultation: {
        consultation_id: maConsultation._id,
        code: maConsultation.type_consultation.code,
        libelle: maConsultation.type_consultation.libelle,
        couleur: maConsultation.type_consultation.couleur,
        date_consultation: maConsultation.date_consultation,
      },
      cabinet: maConsultation.cabinet ? maConsultation.cabinet : {},
      praticien: {},
      patient: maConsultation.patient ? maConsultation.patient : {},
      prestations: [],
    };

    postCotation(maConsultation.patient_id, maConsultation._id, data)
      .then((reponse) => {
        if (reponse.code === "OK") {
          setMessageErreur("");
        } else {
          setMessageErreur("Erreur lors de l'ajout de la cotation");
        }
      })
      .catch((error) => {
        setMessageErreur("Erreur lors de l'ajout de la cotation");
        setError(error);
      });
  };

  //NES quand je sélectionne une cotation
  const handleSelectCotation = (cotation_id) => {
    if (listeIdSelected.includes(cotation_id)) {
      //NES je l'enlève
      let tempArray = [...listeIdSelected];
      tempArray = tempArray.filter((item) => item !== cotation_id);
      setListeIdSelected(tempArray);
    } else {
      //NES je l'ajoute
      setListeIdSelected([...listeIdSelected, cotation_id]);
    }
  };

  //NES quand je supprime une cotation
  const handleSupprimerCotation = (cotation_id) => {
    let tempArray = [...listeIdSelected];
    let index = tempArray.findIndex((item) => item === cotation_id);
    console.log({ handleSupprimerCotation: index });
    tempArray.splice(index, 1);
    setListeIdSelected(tempArray);
  };

  //POST Facture
  const facturer = () => {
    let data = {
      patient: getObjetPatient(maConsultation.patient_id, {
        withAdresse: true,
      }),
      cabinet: getObjetCabinet(),
      praticien: maConsultation.praticien ? maConsultation.praticien : {},
      //date_facture: new Date(),
      cotations: listeIdSelected,
      mentions: {
        rgpd: "Conformément à l'article 27 de la loi 'Informatique et Libertés' du 6 janvier 1978, les champs d'information que nous stockons sont nécessaires à  l'enregistrement et au traitement de vos demandes. Nous ne les transmettrons pas à des tiers.",
        informatique_et_liberte:
          "Conformément aux dispositions de la loi n°78-17 du 6 janvier 1978 relative à l’informatique, aux fichiers et aux libertés, les patients disposent d’un droit d’accès, de modification, de rectification et de suppression des données qui les concernent.",
        texte_libre: "",
      },
    };

    postFacture(maConsultation.patient_id, maConsultation._id, data)
      .then((reponse) => {
        if (reponse.code === "OK") {
          //NES je vide la liste des consultations sélectionnées
          setListeIdSelected([]);
        } else {
          setMessageErreur("Erreur lors de l'enregistrementde la facture");
        }
      })
      .catch((error) => {
        setMessageErreur("Erreur lors de l'enregistrement de la facture");
        setError(error);
      });
  };

  //NES en cas d'erreur
  if (messageErreurChargement) {
    return (
      <MessageErreur
        message={messageErreurChargement}
        error={error}
        style={{ margin: "40px 0px" }}
      />
    );
  }

  //NES Chargement
  if (isLoading) {
    return (
      <LoaderHorizontal
        isLoading
        color="#fafafa"
        style={{ margin: "40px 0px" }}
      />
    );
  }

  if (cotationsConsultation.length === 0) {
    return (
      <BoutonActionSecondaire
        texte="Facturer et régler"
        taille="L"
        fullWidth
        onClick={ajouterCotation}
        style={{ marginTop: "40px" }}
      />
    );
  } else {
    return (
      <div id="Cotations">
        <SurfacePapier style={{ marginTop: "40px" }}>
          <div className="Titre2">Facturer ma consultation</div>

          <MessageErreur
            message={messageErreur}
            error={error}
            style={{ marginBottom: "20px" }}
          />

          <div style={{ marginTop: "20px" }}>
            {cotationsConsultation.map((cotation) => (
              <EditionCotation
                key={cotation._id}
                consultation={maConsultation}
                cotation={cotation}
                selected={listeIdSelected.includes(cotation._id)}
                onSelect={() => handleSelectCotation(cotation._id)}
                onSupprimer={() => handleSupprimerCotation(cotation._id)}
              />
            ))}

            {cotationsPrecedentes.length > 0 && (
              <div style={{ marginTop: "20px" }}>
                <div className="Titre2">Cotations précédentes non réglées</div>
                <div>
                  {cotationsPrecedentes.map((cotation) => (
                    <EditionCotation
                      key={cotation._id}
                      consultation={maConsultation}
                      cotation={cotation}
                      selected={listeIdSelected.includes(cotation._id)}
                      onSelect={() => handleSelectCotation(cotation._id)}
                    />
                  ))}
                </div>
              </div>
            )}
          </div>
          <BoutonActionPrincipale
            texte={libelleBoutonFacturer}
            fullWidth
            style={{ margin: "20px 0px" }}
            disabled={listeIdSelected.length === 0}
            onClick={facturer}
          />
        </SurfacePapier>
      </div>
    );
  }
}
