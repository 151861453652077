import React, { useContext, useEffect, useState } from "react";
import MonStyle from "./PageRendezVous.module.css";
import { RdvContext } from "../../../../Data/RdvContext";
import LoaderHorizontal from "../../../../Design/Progression/LoaderHorizontal";
import MessageErreur from "../../../../Design/Messages/MessageErreur";
import { DateToString } from "../../../../functions/FonctionsDateHeure";
import ObjetRdv from "../../../../Design/Objets/ObjetRdv";
import BoutonFiltre from "../../../../Design/Recherche/BoutonFiltre";
import ThumbUpAltIcon from "@material-ui/icons/ThumbUpAlt";
import ThumbDownAltIcon from "@material-ui/icons/ThumbDownAlt";
import MessageAucuneDonnee from "../../../../Design/Messages/MessageAucuneDonnee";

export default function PageRendezVous({
  match,
  patient_id,
  filtreAnnulation,
}) {
  let url_patient_id = patient_id;

  //NES parametres de l'URL
  if (match) {
    url_patient_id = match.params.patient_id;
  }
  //NES Gesiton du contexte
  const { getRdvPatient } = useContext(RdvContext);

  //NES Gestion du state
  const [listeRdv, setListeRdv] = useState([]);
  const [messageErreur, setMessageErreur] = useState();
  const [error, setError] = useState();
  const [isLoading, setIsloading] = useState();
  const [groupesRdv, setGroupesRdv] = useState([]);
  const [listeAffiche, setListeAffiche] = useState([]);
  const [listeRdvNonHonores, setListeRdvNonHonores] = useState([]);
  const [listeRdvExcuses, setListeRdvExcuses] = useState([]);
  const [listeRdvExcusesDerniereMinute, setListeRdvExcusesDerniereMinute] =
    useState([]);
  const [filtre, setFiltre] = useState();
  const [nbRdv, setNbRdv] = useState();

  //USEEFFECT pour passer un filtre sur le type d'annulation
  useEffect(() => {
    setFiltre(filtreAnnulation);
  }, [filtreAnnulation]);

  //USEEFFECT chargement des données
  useEffect(() => {
    setIsloading(true);

    getRdvPatient(url_patient_id)
      .then((reponse) => {
        setIsloading(false);
        if (reponse.code === "OK") {
          let listeDesRdv = reponse.data;

          //NES je n'affiche pas les rdv supprimés
          listeDesRdv = listeDesRdv.filter((item) => item.is_archive !== true);

          //NES Liste des rdv excusés
          setListeRdvExcuses(
            listeDesRdv.filter((item) => item.annulation_rdv?.mode === "EXCUSE")
          );
          //NES Liste des rdv excusés à la dernière minute
          setListeRdvExcusesDerniereMinute(
            listeDesRdv.filter(
              (item) => item.annulation_rdv?.mode === "EXCUSE_DERNIERE_MINUTE"
            )
          );
          //NES Liste des rdv non honorés
          setListeRdvNonHonores(
            listeDesRdv.filter(
              (item) => item.annulation_rdv?.mode === "NON_HONORE"
            )
          );

          setListeRdv(listeDesRdv);

          //NES par default un affiche toutes les rdv
          setListeAffiche(listeDesRdv);
        } else {
          setMessageErreur(
            "Erreur lors de la récupération des rendez-vous du patient"
          );
        }
      })
      .catch((error) => {
        setIsloading(false);
        setMessageErreur(
          "Erreur lors de la récupération des rendez-vous du patient"
        );
        setError(error);
      });
    // eslint-disable-next-line
  }, []);

  //USEEFFECT construction des groupes
  useEffect(() => {
    if (listeRdv) {
      let listeAAfficher = [];

      //NES est ce que filtre?
      switch (filtre) {
        case "EXCUSE":
          listeAAfficher = listeRdvExcuses;
          break;
        case "EXCUSE_DERNIERE_MINUTE":
          listeAAfficher = listeRdvExcusesDerniereMinute;
          break;
        case "NON_HONORE":
          listeAAfficher = listeRdvNonHonores;
          break;

        default:
          listeAAfficher = listeRdv;
          break;
      }

      //NES nb de rdv à afficher
      setNbRdv(listeAAfficher.length);

      //NES je trie par date
      listeAAfficher.sort((a, b) => {
        let dateA = new Date(a.date_heure);
        let dateB = new Date(b.date_heure);
        if (dateA > dateB) {
          return -1;
        } else {
          return 1;
        }
      });

      //NES pour l'affichage par groupe
      let newGroupes = [];
      let groupeRdvAVenir = { nom: "A venir", liste: [] };
      listeAAfficher.forEach((rdv) => {
        //NES est-ce que la date est une date future ?
        let aujourdhui = Date.now();
        let dateRdv = new Date(rdv.date_heure);

        if (dateRdv > aujourdhui) {
          groupeRdvAVenir.liste.push(rdv);
        } else {
          //NES je vais ajouter le rdv au bon groupe

          let anneeRdv = DateToString(dateRdv, { year: "numeric" });

          //NES est-ce que le groupe avec l'année existe?
          let index = newGroupes.findIndex((item) => item.nom === anneeRdv);
          if (index > -1) {
            //NES j'ajoute
            let groupe = newGroupes[index];
            let newListe = groupe.liste;
            newListe.push(rdv);
            groupe = { ...groupe, liste: newListe };
            newGroupes.splice(index, 1, groupe);
          } else {
            //NES je crée un nouveau groupe
            let newGroupe = { nom: anneeRdv, liste: [rdv] };
            newGroupes = [...newGroupes, newGroupe];
          }
        }
      });

      if (groupeRdvAVenir.liste.length > 0) {
        newGroupes = [groupeRdvAVenir, ...newGroupes];
      }

      setGroupesRdv(newGroupes);
    }
    // eslint-disable-next-line
  }, [listeAffiche, filtre]);

  //NES quand on veut filtrer sur un motif d'annulation
  const handleFilter = (code) => {
    if (filtre === code) {
      //NES alors j'efface le filtre
      setFiltre();
    } else {
      //NES alors j'applique le filtre
      setFiltre(code);
    }
  };

  return (
    <div className={MonStyle.PageRendezVous}>
      <div className="TitreMobile">Rendez-vous</div>
      <div className="SousTitre">{nbRdv + " rendez-vous"}</div>
      {groupesRdv.length > 0 && (
        <div className={MonStyle.Filtres}>
          <BoutonFiltre
            texte={listeRdvExcuses.length + " rdv excusé"}
            startIcon={ThumbUpAltIcon}
            color={listeRdvExcuses.length > 0 ? "#19A400" : "#97989D"}
            onClick={() => handleFilter("EXCUSE")}
            libelleFiltre={
              filtre === "EXCUSE" ? listeRdvExcuses.length + " rdv excusé" : ""
            }
            style={{
              marginRight: "20px",
              marginBottom: "10px",
            }}
          />
          <BoutonFiltre
            texte={
              listeRdvExcusesDerniereMinute.length +
              " excusés à la dernière minute"
            }
            startIcon={ThumbDownAltIcon}
            color={
              listeRdvExcusesDerniereMinute.length > 0 ? "#FF9800" : "#97989D"
            }
            onClick={() => handleFilter("EXCUSE_DERNIERE_MINUTE")}
            libelleFiltre={
              filtre === "EXCUSE_DERNIERE_MINUTE"
                ? listeRdvExcusesDerniereMinute.length +
                  " excusés à la dernière minute"
                : ""
            }
            style={{
              marginRight: "20px",
              marginBottom: "10px",
            }}
          />
          <BoutonFiltre
            texte={listeRdvNonHonores.length + " Non honorés"}
            startIcon={ThumbDownAltIcon}
            color={listeRdvNonHonores.length > 0 ? "#F44336" : "#97989D"}
            onClick={() => handleFilter("NON_HONORE")}
            libelleFiltre={
              filtre === "NON_HONORE"
                ? listeRdvNonHonores.length + " Non honorés"
                : ""
            }
            style={{
              marginRight: "20px",
              marginBottom: "10px",
            }}
          />
        </div>
      )}
      <div className={MonStyle.ListeRdv}>
        <LoaderHorizontal isLoading={isLoading} />
        <MessageErreur
          message={messageErreur}
          error={error}
          style={{ marginTop: "20px" }}
        />
        {groupesRdv.length === 0 && (
          <MessageAucuneDonnee
            message="Aucun rendez vous trouvé"
            hideIllustration
          />
        )}
        {groupesRdv.map((groupe) => (
          <div>
            <div
              className="Titre2"
              style={{
                marginTop: "40px",
                borderBottom: "1px solid #DDD",
                height: "30px",
              }}
            >
              {groupe.nom}
            </div>
            <div>
              {groupe.liste.map((rdv) => (
                <div className={MonStyle.Ligne}>
                  <ObjetRdv rdv={rdv} />
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
