import React from "react";
import MonStyle from "./CodeConsultationPicker.module.css";
import SurfaceBloc from "../../Design/Surfaces/SurfaceBloc";
import IconesCodeConsultation from "../Icones/IconesCodeConsultation";

export default function CodeConsultationPicker({ onClick }) {
  return (
    <div
      className={MonStyle.CodeConsultationPicker}
      id="CodeConsultationPicker"
    >
      <SurfaceBloc
        isBouton
        className={MonStyle.CodeConsultation}
        onClick={() => onClick("consultation")}
      >
        <IconesCodeConsultation
          code={"consultation"}
          className={MonStyle.Illustration}
        />

        <h3 className={MonStyle.Titre}>Santé</h3>
        <p className="SousTitre" style={{ textAlign: "center" }}>
          Affichage des consultations générique pour la médecine classique
        </p>
        <ul>
          <li>Motif de consultation</li>
          <li>Interrogatoire & examen</li>
          <li>Constantes (poids, taille, etc...)</li>
          <li>Diagnostic</li>
          <li>Documents</li>
        </ul>
      </SurfaceBloc>
      <SurfaceBloc
        style={{ height: "auto" }}
        isBouton
        className={MonStyle.CodeConsultation}
        onClick={() => onClick("consultation_energetique")}
      >
        <IconesCodeConsultation
          code={"consultation_energetique"}
          className={MonStyle.Illustration}
        />
        <h3 className={MonStyle.Titre}>Médecine énergétique</h3>
        <p className="SousTitre" style={{ textAlign: "center" }}>
          Affichage des consultations orienté pour la médecine énergétique
        </p>
        <ul>
          <li>Pouls</li>
          <li>Langue</li>
          <li>Description</li>
          <li>Traitement</li>
          <li>Documents</li>
        </ul>
      </SurfaceBloc>
      <SurfaceBloc
        style={{ height: "auto" }}
        isBouton
        className={MonStyle.CodeConsultation}
        onClick={() => onClick("consultation_generique")}
      >
        <IconesCodeConsultation
          code={"consultation_generique"}
          className={MonStyle.Illustration}
        />

        <h3 className={MonStyle.Titre}>Générique</h3>
        <p className="SousTitre" style={{ textAlign: "center" }}>
          Affichage des consultations multi-métier permettant de saisir du texte
          libre
        </p>
        <ul>
          <li>Motif de consultation</li>
          <li>Description</li>
          <li>Documents</li>
        </ul>
      </SurfaceBloc>
    </div>
  );
}
