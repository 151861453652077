import React, { useState, useEffect } from "react";
import BoutonRond from "../../../../../Design/Boutons/BoutonRond";
import ObjetPatient from "../../../../../Design/Objets/ObjetPatients";
import InputAutocompletePatient from "../../../../../Design/Recherche/InputAutocompletePatient";
import MonStyle from "./BlocPatient.module.css";
import CloseIcon from "@material-ui/icons/Close";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";

export default function BlocPatient({
  patient,
  onSelect,
  onClickNouveau,
  onSupprimerPatient,
  onModifierPatient,
  onAutocompleteChange,
  rdv_id,
}) {
  //NES gestion du state
  const [monPatient, setMonPatient] = useState({});

  //USEEFFECT
  useEffect(() => {
    let data = {
      _id: patient.patient_id,
      nom: patient.patient_nom,
      prenom: patient.patient_prenom,
      date_naissance: patient.patient_date_naissance,
      age: patient.patient_age,
      sexe: patient.patient_sexe,
      email: patient.patient_email,
    };
    setMonPatient(data);
  }, [patient]);

  const handleSelect = (patient) => {
    let objetPatientRdv = {
      patient_id: patient._id,
      patient_nom: patient.nom,
      patient_prenom: patient.prenom,
      patient_date_naissance: patient.date_de_naissance,
      patient_age: patient.age,
      patient_sexe: patient.sexe,
      patient_email: patient.email,
    };

    //NES gestion du téléphone
    if (patient.contact) {
      objetPatientRdv = {
        ...objetPatientRdv,
        patient_contact: patient.contact,
      };
    }

    //NES gestion de l'adresse
    if (patient.adresse) {
      objetPatientRdv = {
        ...objetPatientRdv,
        adresse: patient.adresse,
      };
    }

    if (onSelect) {
      onSelect(objetPatientRdv, patient);
    }
  };

  return (
    <div className={MonStyle.BlocPatient} id="bloc_patient">
      {patient.patient_id ? (
        <div className={MonStyle.PatientEtBoutons}>
          <div className={MonStyle.Patient}>
            <ObjetPatient patient={monPatient} onClick={onModifierPatient} />
          </div>
          {!rdv_id ? (
            <BoutonRond
              iconeComposant={<CloseIcon />}
              onClick={onSupprimerPatient}
              tooltip="Rechercher un autre patient"
            />
          ) : (
            <BoutonRond
              iconeComposant={<NavigateNextIcon />}
              tooltip="Modifier les informations du patient"
            />
          )}
        </div>
      ) : (
        <div className={MonStyle.Autocomplete}>
          <InputAutocompletePatient
            autoFocus
            onSelectSuggestion={(patient) => handleSelect(patient)}
            onClickNouveau={onClickNouveau}
            fullWidth
            onChange={onAutocompleteChange}
          />
        </div>
      )}
    </div>
  );
}
