import React, { useContext, useEffect, useState } from "react";
import SurfacePage from "../../Design/Surfaces/SurfacePage";
import ZoneRecherche from "./Components/ZoneRecherche";
import Style from "./PageRecherche.module.css";
import queryString from "query-string";
import LigneResultatPatient from "../../Design/Recherche/LigneResultatsObjets/LigneResultatPatient";
import MessageAucuneDonnee from "../../Design/Messages/MessageAucuneDonnee";
import Loader from "../../Design/Progression/LoaderHorizontal";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import BoutonActionFlottant from "../../Design/Boutons/BoutonActionFlottant";
import BoutonActionSecondaire from "../../Design/Boutons/BoutonActionSecondaire";
import { PatientsContext } from "../../Data/PatientsContext";
import LigneResultat from "../../Design/Recherche/LigneResultat";
import ObjetPatient from "../../Design/Objets/ObjetPatients";
import MessageAide from "../../Design/Messages/MessageAide";

//NES style ligne
export default function PageRecherche() {
  //NES gestion du contexte
  const { getPatientsRecherche } = useContext(PatientsContext);

  //NES récupération des paramètres de l'URL
  let url = window.location.search;
  let params = queryString.parse(url);

  //NES Gestion du state
  const [texteRecherche, setTexteRecherche] = useState("");
  const [typeDonneePatient, setTypeDonneePatient] = useState("nom");
  const [patients, setPatients] = useState([]);
  const [labelNbResultats, setLabelNbResultats] = useState("-");
  const [erreurAucuneDonnee, setErreurAucuneDonnee] = useState("");
  const [texteUrl, setTexteUrl] = useState("");
  const [loader, setLoader] = useState(false);
  const [message, setMessage] = useState("");

  useEffect(() => {
    //NES Je recherche pas à chaque lettre sinon c'est trop souvent
    const timer = setTimeout(() => {
      console.log({ texteRecherche });
      if (texteRecherche !== "" && texteRecherche !== undefined) {
        setLoader(true);
        rechercherPatient();
      }
    }, 500);
    return () => clearTimeout(timer);
    // eslint-disable-next-line
  }, [texteRecherche]);

  const handleTexteRechercheChange = (event) => {
    setTexteRecherche(event.target.value);
  };

  //NES en cas de modification de l'url
  useEffect(() => {
    setTexteUrl(params.texteRecherche);
    setTexteRecherche(params.texteRecherche);
    document.getElementById("ChampPrincipalDeRecherche").focus();
  }, [params.texteRecherche]);

  //NES avec la touche ENTREE
  const handlePressEnter = () => {
    rechercherPatient();
  };

  //NES quand on change le type de donnée cherchée :)
  const handleSelectTypeDonnee = (type) => {
    setTypeDonneePatient(type);
  };

  //NES lancer la recherche patient manuellement
  const rechercherPatient = async () => {
    getPatientsRecherche(texteRecherche, typeDonneePatient).then(
      (reponseJson) => {
        setLoader(false);
        console.log(reponseJson);

        if (reponseJson.code === "OK") {
          //NES je sauvegarde les résultats dans le state
          if (reponseJson.nbresults >= 1) {
            setPatients(reponseJson.data);
            setErreurAucuneDonnee("");
          } else {
            setPatients([]);
            setErreurAucuneDonnee("Aucun patient trouvé");
          }
          //NES mise à jour du nombre de résultats
          if (reponseJson) {
            let nbResultats = reponseJson.nbresults;
            if (nbResultats > 1) {
              let label = nbResultats + " patients trouvés";
              setLabelNbResultats(label);
            } else {
              let label = nbResultats + " patient trouvé";
              setLabelNbResultats(label);
            }
          }
        } else {
          if (reponseJson.code === "INSUFFISANT") {
            setMessage(reponseJson.message);
          }
        }
      }
    );
  };

  return (
    <SurfacePage couleur="gris">
      <div className={Style.PageRecherche}>
        <div className={Style.TitrePage}>
          <h1>Rechercher</h1>
          <div className="SousTitre">{labelNbResultats}</div>
        </div>
        <div className={Style.ZoneRecherche}>
          <ZoneRecherche
            value={texteRecherche}
            onTexteChange={handleTexteRechercheChange}
            onPressEnter={handlePressEnter}
            onSelectTypeDonnee={handleSelectTypeDonnee}
            texteUrl={texteUrl}
          />
        </div>
        <div>
          <div style={{ height: "10px" }}>
            <Loader isLoading={loader} />
          </div>
          <MessageAucuneDonnee message={erreurAucuneDonnee}>
            <BoutonActionSecondaire
              texte="Nouveau dossier"
              style={{ marginTop: "20px" }}
              link="/patients/nouveau/dossier"
            />
          </MessageAucuneDonnee>

          <MessageAide message={message} />
          {patients.map((patient) => (
            <>
              <LigneResultat>
                <ObjetPatient patient={patient} />
              </LigneResultat>
              <LigneResultatPatient patient={patient} key={patient._id} />
            </>
          ))}
        </div>
      </div>
      <BoutonActionFlottant
        iconeComposant={<PersonAddIcon />}
        tooltip="Nouveau patient"
        link="/patients/nouveau/dossier"
        id="bouton_nouveau_patient"
      />
    </SurfacePage>
  );
}
