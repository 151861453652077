import React, { useState, useEffect, useContext } from "react";
import MonStyle from "./PageCotationsAFacturer.module.css";
import { CotationsContext } from "../../../../Data/CotationsContext";
import LoaderHorizontal from "../../../../Design/Progression/LoaderHorizontal";
import MessageErreur from "../../../../Design/Messages/MessageErreur";
import ObjetCotation from "../../../../Design/Objets/ObjetCotation";
import LigneResultat from "../../../../Design/Recherche/LigneResultat";
import MessageAucuneDonnee from "../../../../Design/Messages/MessageAucuneDonnee";
import BoutonRetour from "../../../../Design/Boutons/BoutonRetour";
import { pluriel } from "../../../../functions/Formatter";

export default function PageCotationsAFacturer() {
  //NES gestion du contexte
  const { cotationsAFacturer, getCotationsAFacturer } =
    useContext(CotationsContext);

  //NES Gestion du state
  const [isLoading, setIsLoading] = useState(false);
  const [messageErreur, setMessageErreur] = useState();
  const [error, setError] = useState();
  const [message, setMessage] = useState();

  useEffect(() => {
    if (cotationsAFacturer.length === 0) setIsLoading(true);
    getCotationsAFacturer()
      .then((reponse) => {
        setIsLoading(false);
        if (reponse.code === "OK") {
          if (reponse.data.length === 0) {
            setMessage("Aucune cotation à facturer");
          } else {
            setMessage("");
          }
        } else {
          setMessageErreur(
            "Erreur lors de la récupération des cotations non facturées"
          );
        }
      })
      .catch((error) => {
        setIsLoading(false);
        setMessageErreur(
          "Erreur lors de la récupération des cotations non facturées"
        );
        setError(error);
      });
    // eslint-disable-next-line
  }, []);

  return (
    <div className={MonStyle.PageCotationsAFacturer}>
      <div>
        <div className="Titre1">Cotations à facturer</div>
        <div className="SousTitre">
          {pluriel(
            cotationsAFacturer.length,
            "cotation à facturer",
            "cotations à facturer",
            "F"
          )}
        </div>
      </div>
      <div className={MonStyle.BoutonRetour}>
        <BoutonRetour
          texte="Retour au suivi des règlements"
          link="/suivireglements/"
        />
      </div>
      <div className={MonStyle.Content}>
        <MessageAucuneDonnee message={message} hideIllustration />
        <LoaderHorizontal isLoading={isLoading} />
        <MessageErreur message={messageErreur} error={error} />
        {cotationsAFacturer.map((cotation) => (
          <LigneResultat key={cotation._id}>
            <ObjetCotation cotation={cotation} />
          </LigneResultat>
        ))}
      </div>
    </div>
  );
}
