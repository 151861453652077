import React, { useState } from "react";
import InputTexteFilled from "./InputTexteFilled";

export default function InputEmailFilled({ value, ...props }) {
  //NES gestion du state
  const [erreur, setErreur] = useState("");

  function checkEmailValide(email) {
    const mailformat = /^[\w\-_.]+@+[\w\-_.]*\.+[a-z]{2,6}$/g;
    if (email) {
      if (email.toLowerCase().match(mailformat)) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  //NES quand on quitte le champ
  const handleBlur = (event) => {
    let email = event.target.value;
    if (checkEmailValide(email)) {
      setErreur("");
    } else {
      setErreur("L'adresse email n'est pas correcte");
    }
  };

  return (
    <InputTexteFilled
      value={value}
      onBlur={handleBlur}
      label="Email"
      helperText={erreur}
      error={erreur ? true : false}
      {...props}
    />
  );
}
