import React from "react";
import ObjetPrix from "./ObjetPrix";
import { DateToString } from "../../functions/FonctionsDateHeure";
import { HashLink as Link } from "react-router-hash-link";
import { capitalize } from "../../functions/Formatter";

export default function ObjetCotation({ cotation }) {
  if (!cotation.patient) {
    console.log(cotation);
  }

  return (
    <Link
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-start",
        justifyContent: "space-between",
        width: "100%",
      }}
      to={`/patients/${cotation.patient_id}/consultations/${cotation.consultation_id}#Cotations`}
    >
      <div>
        <div className="InformationPricipale" style={{ color: "#000000" }}>
          Consultation du {DateToString(cotation.date_cotation)}
        </div>
        <div className="InformationPricipale" style={{ color: "#208AB0" }}>
          {cotation?.patient?.nom.toUpperCase() +
            " " +
            capitalize(cotation?.patient?.prenom)}
        </div>
        <div className="InformationSecondaire">
          {cotation.prestations.map((prestation) => (
            <div key={prestation._id}>
              {prestation.nom}{" "}
              <span>({prestation.prestation_total_ttc_net} €)</span>{" "}
            </div>
          ))}
        </div>
      </div>
      <div>
        <ObjetPrix prix={cotation.part_patient.montant_a_regler} />
      </div>
    </Link>
  );
}
