function stringToDateIso(strDate) {
  let strDateIso = "";

  if (strDate) {
    let premierSlash = "";
    let secondSlash = "";
    premierSlash = strDate.indexOf("/");
    secondSlash = strDate.indexOf("/", premierSlash + 1);
    if (premierSlash > -1 && secondSlash > -1) {
      //NES à priori c'est une date
      let jour = strDate.substr(0, premierSlash);
      let mois = strDate.substr(premierSlash + 1, 2);
      let annee = strDate.substr(secondSlash + 1);
      if (annee.length === "2") {
        let anneeCourante = Date.now().getFullYear() - 2000;
        if (annee > anneeCourante) {
          annee = "19" + annee;
        } else {
          annee = "20" + annee;
        }
      }
      strDateIso = annee + "-" + mois + "-" + jour;
    }
  }
  return strDateIso;
}

export function calculAgeAnnees(date_de_naissance) {
  let ageAnnees = 0;
  if (date_de_naissance !== "" && date_de_naissance !== undefined) {
    let dateNaissanceIso = stringToDateIso(date_de_naissance);
    let dateDateNaissance = new Date(dateNaissanceIso);
    let dateJour = Date.now();
    ageAnnees = Math.floor(
      (dateJour - dateDateNaissance) / 1000 / 60 / 60 / 24 / 30 / 12,
      0
    );
  }
  return ageAnnees;
}

export function calculAgeMois(date_de_naissance, date) {
  let ageMois = 0;
  if (date_de_naissance !== "" && date_de_naissance !== undefined) {
    let dateNaissanceIso = stringToDateIso(date_de_naissance);
    let dateDateNaissance = new Date(dateNaissanceIso);
    let dateJour = new Date(date ? date : null);
    ageMois = Math.floor(
      (dateJour - dateDateNaissance) / 1000 / 60 / 60 / 24 / 30,
      0
    );
  }
  return ageMois;
}

export function calculAgeJours(date_de_naissance) {
  let ageJours = 0;
  if (date_de_naissance !== "" && date_de_naissance !== undefined) {
    let dateNaissanceIso = stringToDateIso(date_de_naissance);
    let dateDateNaissance = new Date(dateNaissanceIso);
    let dateJour = Date.now();
    ageJours = Math.floor(
      (dateJour - dateDateNaissance) / 1000 / 60 / 60 / 24,
      0
    );
  }
  return ageJours;
}

export function calculObjetAge(date_de_naissance) {
  let age = { nombre: 0, unite: "ans" };

  if (date_de_naissance) {
    let ageAnnees = calculAgeAnnees(date_de_naissance);
    let ageMois = calculAgeMois(date_de_naissance);
    let ageJours = calculAgeJours(date_de_naissance);
    if (ageAnnees < 2) {
      if (ageMois < 1) {
        age.nombre = ageJours;
        age.unite = "jours";
      } else {
        age.nombre = ageMois;
        age.unite = "mois";
      }
    } else {
      age.nombre = ageAnnees;
      age.unite = "ans";
    }
  }

  return age;
}

export function AfficherAgeAnneeMoisJour(age, date_de_naissance) {
  let strAge = "";
  if (
    age !== undefined &&
    age !== "" &&
    date_de_naissance !== undefined &&
    date_de_naissance !== ""
  ) {
    if (age > 2) {
      strAge = age + "ans ";
    } else {
      console.log("mois");
      //NES je calcule l'âge en mois voir en jour
      let ageMois = calculAgeMois(date_de_naissance);
      if (ageMois >= 2) {
        strAge = ageMois + "mois ";
      } else {
        let ageJour = calculAgeJours(date_de_naissance);
        strAge = ageJour + "jours ";
      }
    }

    return strAge;
  }
}
