import React, { useEffect, useState } from "react";
import LigneDemande from "./LigneDemande";

export default function LignesDemandes({ jour }) {
  //NES gestion du state
  const [lignes, setLignes] = useState([]);

  useEffect(() => {
    if (jour.demandes) {
      let listeDemandes = jour.demandes;

      let listeDisponible = [];

      if (jour.disponible > 0) {
        listeDisponible = new Array(jour.disponible);
        listeDisponible.fill(undefined);
      }

      let liste = listeDemandes.concat(listeDisponible);
      setLignes(liste);
    }
  }, [jour]);

  if (lignes.length === 0) {
    return (
      <div
        style={{
          backgroundColor: "#666",
          color: "white",
          height: "50px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          fontSize: "16px",
        }}
      >
        Fermé
      </div>
    );
  }

  return (
    <div>
      {lignes.map((demande, index) => (
        <LigneDemande demande={demande} key={index} />
      ))}
    </div>
  );
}
