import React, { useEffect, useContext } from "react";
import SurfacePapier from "../../../../Design/Surfaces/SurfacePapier";
import BoutonActionSecondaire from "../../../../Design/Boutons/BoutonActionSecondaire";
import Style from "./PageUtilisateurs.module.css";
import { UtilisateursContext } from "../../../../Data/UtilisateursContext";
import ObjetUtilisateur from "../../../../Design/Objets/ObjetUtilisateur";
import { TagRole } from "../../../../Design/DataDisplay/Tags";
import ActionsUtilisateur from "./Components/ActionsUtlisateur";
import BoutonMenu from "../../../../Design/Navigation/BoutonMenu";
import { HashLink as Link } from "react-router-hash-link";
import PopupChoixRole from "./Components/PopupChoixRole";
import { useState } from "react";
import IllustrationEquipe from "../../../../Images/Illustrations/Equipe.png";
import BoutonRetour from "../../../../Design/Boutons/BoutonRetour";

export default function PageUtilisateurs() {
  //NES gestion du contexte
  const { utilisateurs, getUtilisateurs, deleteUtilisateurs } =
    useContext(UtilisateursContext);

  //NES Gestion du state
  const [editionRoleId, setEditionRoleId] = useState();

  //NES au 1er chargement je vais chercher les utilisateurs
  useEffect(() => {
    getUtilisateurs();
    // eslint-disable-next-line
  }, []);

  //NES Suppression de l'utilisateur
  const SupprimerUtilisateur = (id) => {
    deleteUtilisateurs(id);
  };

  ///Quand on clique sur le choix du role
  const handleChoixRole = (utilisateur_id) => {
    setEditionRoleId(utilisateur_id);
  };

  return (
    <SurfacePapier>
      <div className={Style.PageUtilisateurs}>
        <div className="MobileOnly">
          <BoutonRetour
            texte="Retour aux options"
            link="/options"
            style={{ marginBottom: "10px" }}
          />
        </div>

        <div className={Style.Entete}>
          <div className={Style.TitreExplications}>
            <h2>Utilisateurs</h2>
            <div className="SousTitre">
              La gestion des utilisateurs permet à plusieurs personnes de se
              connecter à {process.env.REACT_APP_NOM}. Vous pouvez utilisez la
              gestion des profils pour limiter les droits lié à chaque
              utilisateur.
            </div>
            {/*
            <a href="https://www.google.com" target="_blank" rel="noreferrer">
              En savoir plus
            </a>
             */}
            <BoutonActionSecondaire
              texte="Ajouter un utilisateur"
              fullWidth
              link="/options/utilisateurs/nouveau"
              taille="L"
              style={{ marginTop: "20px" }}
            />
          </div>

          <div className={Style.IllustrationEquipe}>
            <img
              src={IllustrationEquipe}
              alt="Equipe"
              className="Illustration"
            />
          </div>
        </div>

        <div className={Style.ListeUtilisateurs}>
          {utilisateurs.map((utilisateur) => (
            <div className={Style.Ligne} key={utilisateur._id}>
              <div className={Style.ContenuLigne}>
                <Link
                  to={"/options/utilisateurs/" + utilisateur._id}
                  className={Style.ColonneUtilisateur}
                >
                  <ObjetUtilisateur utilisateur={utilisateur} />
                </Link>

                <div className={Style.ColonneTag}>
                  <TagRole
                    code_role={utilisateur.code_role}
                    onClick={() => handleChoixRole(utilisateur._id)}
                  />
                </div>
              </div>
              <BoutonMenu tooltip="Modifier, rôles...">
                <ActionsUtilisateur
                  id={utilisateur._id}
                  onProfil={() => handleChoixRole(utilisateur._id)}
                  onSupprimer={() => SupprimerUtilisateur(utilisateur._id)}
                />
              </BoutonMenu>
            </div>
          ))}
        </div>

        <PopupChoixRole id={editionRoleId} onClose={() => setEditionRoleId()} />
      </div>
    </SurfacePapier>
  );
}

/*
<LigneTableauUtilisateur
                id={utilisateur.utilisateur_id}
                utilisateur={utilisateur}
                onSupprimer={() =>
                  SupprimerUtilisateur(utilisateur.utilisateur_id)
                }
              />
*/
