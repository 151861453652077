import React from "react";
import BoutonActionSecondaire from "../../../Design/Boutons/BoutonActionSecondaire";
import MonStyle from "./EntetePageOptions.module.css";

export default function EntetePageOptions({
  titre,
  sousTitre,
  texteBoutonAjouter,
  linkAjouter,
}) {
  return (
    <div className={MonStyle.TitreEtBouton}>
      <div className={MonStyle.BlocTitre}>
        <div className="Titre1">{titre}</div>
        <div className="SousTitre">{sousTitre}</div>
      </div>
      <BoutonActionSecondaire
        taille="L"
        link={linkAjouter}
        texte={texteBoutonAjouter ? texteBoutonAjouter : "Ajouter"}
      />
    </div>
  );
}
