import React from "react";
import { AlertTitle } from "@material-ui/lab";
import MessageAide from "../../../Design/Messages/MessageAide";
import { useState, useEffect } from "react";

export default function ControleInformationCabinet({ cabinet, style, taille }) {
  //NES gestion du state
  const [message, setMessage] = useState("");
  const [details, setDetails] = useState([]);
  const [titre, setTitre] = useState(
    "Informations obligatoires sur les factures"
  );

  //USEEFFECT
  useEffect(() => {
    let tempMessage = "";
    let tempDetail = [];

    if (cabinet) {
      //NES je controle l'adresse
      if (!cabinet.adresse) {
        tempDetail.push("L'adresse du cabinet");
      }

      //NES je controle le nom de la société
      if (!cabinet?.informations_juridiques?.nom_entite_juridique) {
        tempDetail.push("Le nom de l'entité juridique du cabinet");
      }

      //NES je controle le nnumero_siret  de la société
      if (!cabinet?.informations_juridiques?.numero_siret) {
        tempDetail.push("Le numero siret du cabinet");
      }

      //NES je controle le capital social  de la société
      if (!cabinet?.informations_juridiques?.capital_social) {
        tempDetail.push("Le capital social du cabinet");
      }

      //NES je controle le capital social  de la société
      if (!cabinet?.informations_juridiques?.adresse_siege) {
        tempDetail.push("L'adresse du siège social");
      }

      if (tempDetail.length) {
        tempMessage =
          "Ces informations sont nécessaires pour que vos factures soient conformes.";
      }

      //NES mise à jour du state
      setMessage(tempMessage);
      setDetails(tempDetail);

      //NES gestion du titre
      if (tempDetail.length === 1) {
        const lowercased = tempDetail.map((item) => item.toLowerCase());
        setTitre("Devez renseigner " + lowercased);
      }
    }
  }, [cabinet]);

  //NES si je n'ai pas de cabinet
  if (!cabinet) {
    return "";
  }

  if (taille === "L" && message) {
    return (
      <div style={style}>
        <h4>Informations du cabinet</h4>
        <h3>{titre}</h3>
        {details.map((ligne, index) => (
          <li key={index}>{ligne}</li>
        ))}
        <MessageAide
          message={message}
          severity="error"
          disableClose
          style={{ margin: "15px 0px" }}
        />
      </div>
    );
  }

  return (
    <MessageAide
      message={message}
      disableClose
      style={style}
      severity={cabinet._id ? "error" : "info"}
    >
      <AlertTitle>{titre}</AlertTitle>
      {details.map((ligne, index) => (
        <li key={index}>{ligne}</li>
      ))}
    </MessageAide>
  );
}
