import React, { useContext, useEffect } from "react";
import Style from "./PageHome.module.css";
import SurfacePage from "../../Design/Surfaces/SurfacePage";
import { AuthentificationContext } from "../../Data/AuthentificationContext";
import { SupportContext } from "../../Data/SupportContext";
import JoursEssai from "./Components/JoursEssai";
import PopupModificationPassword from "./Components/PopupModificationPassword";
import SuggestionAjouterUtilisateur from "./Components/SuggestionAjouterUtilisateur";
import ActionWelcome from "./Components/ActionWelcome";
import SuggestionInformationCabinet from "./Components/SuggestionInformationsCabinet";
import queryString from "query-string";
import { capitalize } from "../../functions/Formatter";
import SuggestionAjouterTarif from "./Components/SuggestionAjouterTarif";
import AffichageProblemePaiement from "./Components/AffichageProblemePaiement";
import AffichageAlerteCarteBancaire from "./Components/AffichageAlerteCarteBancaire";
import AffichageAcceptationCgv from "./Components/AffichageAcceptationCgv";

export default function PageHome() {
  //NES récupération des paramètres de l'URL
  let url = window.location.search;
  let params = queryString.parse(url);
  let ACTION = params.action;

  //NES gestion du contexte
  const auth = useContext(AuthentificationContext);
  const { getDemandes } = useContext(SupportContext);

  //USEEFFECT
  useEffect(() => {
    ///Récupération des demandes
    getDemandes();
    // eslint-disable-next-line
  }, []);

  return (
    <SurfacePage couleur="gris">
      <h1>
        {ACTION === "welcome" ? "Bienvenue" : "Bonjour"}{" "}
        {capitalize(auth.payload.prenom)},{" "}
      </h1>{" "}
      <div className="SousTitre"> </div>
      <div className={Style.PageHome}>
        <div className={Style.Informations}>
          <AffichageAcceptationCgv />
          <ActionWelcome open={ACTION === "welcome" ? true : false} />
          <JoursEssai />
          <AffichageAlerteCarteBancaire />
          <SuggestionInformationCabinet />
          <SuggestionAjouterTarif />
          <SuggestionAjouterUtilisateur />
          <AffichageProblemePaiement />
        </div>

        <PopupModificationPassword />
      </div>
    </SurfacePage>
  );
}
