import React, { useState, createContext, useContext } from "react";
import {
  GET_ApiMedical_Motifs,
  POST_ApiMedical_Motifs,
  DELETE_ApiMedical_Consultations_Mesures,
} from "../Api/ApiMedical";
import { AuthentificationContext } from "./AuthentificationContext";
import { DateToString } from "../functions/FonctionsDateHeure";
import { DELETE, GET, PATCH, POST, PUT } from "../functions/FonctionsApi";

//NES Création du contexte
export const ConsultationsContext = createContext();

export const ConsultationsProvider = (props) => {
  //NES gestion du contexte
  const authContext = useContext(AuthentificationContext);

  //NES Gestion du state
  const [consultations, setConsultations] = useState([]);
  const [maConsultation, setMaConsultation] = useState();
  const [motifsConsultationUsuels, setMotifsConsultationUsuels] = useState([]);
  const [typesConsultations, setTypesConsultations] = useState([]);

  //GET /patients/:id/consultations
  const getConsultations = async (patient_id) => {
    //NES est ce que j'ai déjà les consultations dans le state

    let tempArray = consultations.filter(
      (item) => item.patient_id === patient_id
    );

    if (tempArray.length > 0) {
      return { code: "OK", data: tempArray };
    } else {
      setConsultations([]);

      const MonUrl = `${process.env.REACT_APP_URL_API_MEDICAL}/patients/${patient_id}/consultations/`;
      const reponseJson = await GET(MonUrl, authContext.token);
      //NES je mets à jour le contexte
      if (reponseJson.code === "OK") setConsultations(reponseJson.data);
      //NES je retourne à ma fonction appelante le résultat
      return reponseJson;
    }
  };

  //GET récupération de la consultation dans le contexte pou dans l'API si le context est vidé
  const getConsultationById = async (patient_id, consultation_id) => {
    setMaConsultation();

    //NES je cherche dans toutes les consultations
    let tempData = consultations.find((item) => item._id === consultation_id);

    if (tempData) {
      return { code: "OK", data: tempData };
    }

    //NES je j'appelle l'API
    const MonUrl = `${process.env.REACT_APP_URL_API_MEDICAL}/patients/${patient_id}/consultations/${consultation_id}`;

    let reponseAPI = await GET(MonUrl, authContext.token);

    //NES je stocke ma consulation dans le contexte
    if (reponseAPI.code === "OK") {
      setMaConsultation(reponseAPI.data);
    }

    //jre retourne le résultat
    return reponseAPI;
  };

  // POST dans l'API et ajout dans le contexte
  const postConsultationContext = async (patient_id, data) => {
    const MonUrl = `${process.env.REACT_APP_URL_API_MEDICAL}/patients/${patient_id}/consultations/`;

    let reponseApi = await POST(MonUrl, authContext.token, data);

    if (reponseApi.code === "OK") {
      //NES je mets à jour la liste des consultations
      setConsultations((prev) => [...prev, reponseApi.data]);
    }

    return reponseApi;
  };

  // DELETE dans l'API et dans le contexte
  const deleteConsultationContext = async (patient_id, consultation_id) => {
    const MonUrl = `${process.env.REACT_APP_URL_API_MEDICAL}/patients/${patient_id}/consultations/${consultation_id}`;

    let reponseApi = await DELETE(MonUrl, authContext.token);

    console.log(reponseApi);

    if (reponseApi.code === "OK") {
      //NES je supprimer la consultation du contexte
      let tempArray = [...consultations];
      tempArray = tempArray.filter((item) => item._id !== reponseApi.data._id);
      setConsultations(tempArray);
    }

    return reponseApi;
  };

  //PATCH mise à jour de la consultation
  const patchConsultationContext = async (
    patient_id,
    consultation_id,
    data
  ) => {
    //NES J'empèche l'envoi des constantes
    delete data.mesures;

    const MonUrl = `${process.env.REACT_APP_URL_API_MEDICAL}/patients/${patient_id}/consultations/${consultation_id}`;
    let reponseApi = await PATCH(MonUrl, authContext.token, data);

    //NES Je sauvegarde dans le contexte des consultations
    if (reponseApi.code === "OK") {
      //NES Je récupère la consultation dans le contexte
      let index = consultations.findIndex(
        (item) => item._id === reponseApi.data._id
      );
      let tempArray = [...consultations];
      tempArray.splice(index, 1, reponseApi.data);
      setConsultations(tempArray);
    }
    //NES je retourne à ma fonction appelante le résultat
    return reponseApi;
  };

  /// récupération des motifs de consultations usuels
  const getMotifsConsultationsUsuels = async () => {
    if (motifsConsultationUsuels.length > 0) {
      return motifsConsultationUsuels;
    } else {
      //NES appel de l'API /mesures pour récupérer les motifs usuels
      let reponseApi = await GET_ApiMedical_Motifs(authContext.token);
      if (reponseApi.code === "OK") {
        //NES Je regarde la date de calcul pour voir si je dois recalculer
        let aujourdhui = new Date();
        let dateCalcul = reponseApi.date_calcul_favoris;
        let newMotifs = reponseApi.data;
        setMotifsConsultationUsuels(newMotifs);
        if (DateToString(dateCalcul) === DateToString(aujourdhui)) {
          //NES c'est ceux du jour, je retourne les motifs usuels
          return newMotifs;
        } else {
          //NES je demande le recalcul des motifs fréquents
          let reponsePOST = await POST_ApiMedical_Motifs(authContext.token);
          if (reponsePOST.code === "OK") {
            //NES Je mets à jour le contexte
            newMotifs = reponsePOST.data;
            setMotifsConsultationUsuels(newMotifs);
            return newMotifs;
          } else {
            //NES réponse KO je retourne ceux non recalculés
            return newMotifs;
          }
        }
      } else {
        //NES réponse KO
        return [];
      }
    }
  };

  //PUT MAJ des mesures de consultation
  const putConsultationMesure = async (
    consultation_id,
    patient_id,
    mesure_id,
    mesure
  ) => {
    //NES on appelle l'API pour mettre à jour la mesure
    //NES construction de l'objet à passer
    let valeur = { valeur: mesure.valeur };

    const MonUrl = `${process.env.REACT_APP_URL_API_MEDICAL}/patients/${patient_id}/consultations/${consultation_id}/mesures/${mesure_id}`;

    let reponseApi = await PUT(MonUrl, authContext.token, valeur);

    if (reponseApi.code === "OK") {
      //NES je mets à jour ma consultation
      setMaConsultation(reponseApi.data);

      //NES Je mets à jour le context historique avec mon retour
      let tempArrayConsultations = [...consultations];
      let indexConsultation = tempArrayConsultations.findIndex(
        (item) => item._id === consultation_id
      );
      tempArrayConsultations.splice(indexConsultation, 1, reponseApi.data);
      setConsultations(tempArrayConsultations);
    }
    return reponseApi;
  };

  //DELETE de la mesure pour la consultation
  const deleteConsultationMesureContext = async (
    patient_id,
    consultation_id,
    mesure_id
  ) => {
    if (patient_id && consultation_id && mesure_id) {
      //NES on appelle l'API pour supprimer la mesure
      DELETE_ApiMedical_Consultations_Mesures(
        patient_id,
        consultation_id,
        mesure_id,
        authContext.token
      ).then((reponseApi) => {
        if (reponseApi.code === "OK") {
          //NES mise à jour du contexte
          let tempArray = [...consultations];
          let index = consultations.findIndex(
            (item) => item._id === consultation_id
          );

          if (index > -1) {
            tempArray.splice(index, 1, reponseApi.data);
            setConsultations(tempArray);
          }
        }
        return {};
      });
    } else {
      return {};
    }
  };

  //GET /TypesConsultations
  const getTypesConsultations = async () => {
    if (typesConsultations.length > 0) {
      //NES je retourne les données en cache
      return { message: "OK", data: typesConsultations };
    } else {
      //NES j'appelle l'API Médicale
      const MonUrl = `${process.env.REACT_APP_URL_API_MEDICAL}/typesconsultations/`;
      const dataJson = await GET(MonUrl, authContext.token);
      if (dataJson.code === "OK") {
        let listeTypes = dataJson.data;
        setTypesConsultations(listeTypes);
      }
      //NES je retourne à ma fonction appelante le résultat
      return dataJson;
    }
  };

  //GET typeConsultationById
  const getTypeConsultationById = async (consultation_id) => {
    let types = await getTypesConsultations();
    let liste = types.data;
    let monTypeConsultation = liste.find(
      (item) => item._id === consultation_id
    );
    return monTypeConsultation;
  };

  //GET typeConsultationByCode
  const getTypeConsultationGeneriqueByCode = async (code) => {
    let types = await getTypesConsultations();
    let liste = types.data;
    //NES je ne garde que les consultations génériques
    liste = liste.filter(
      (item) =>
        item.owned_by_organisation !== authContext.payload.owned_by_organisation
    );
    //NES cas particulier quand on est connecté sur l'organisation générique dans ce cas le filter retourne rien
    if (liste.length === 0) {
      liste = types.data;
    }

    let monTypeConsultation = liste.find((item) => item.code === code);
    return monTypeConsultation;
  };

  //POST TypesConsultation
  const postTypesConsultations = async (data) => {
    //NES j'appelle l'API Médicale
    const MonUrl = `${process.env.REACT_APP_URL_API_MEDICAL}/typesconsultations/`;
    //NES pour détecter les appels en boucle
    console.log({ POST: MonUrl });
    const reponseApi = await fetch(MonUrl, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        token: authContext.token,
      },
      body: JSON.stringify(data),
    });
    //NES je convertis ce que je récupère en JSON
    const dataJson = await reponseApi.json();
    if (dataJson.code === "OK") {
      //NES je mets à jour le contexte
      let tempArray = [...typesConsultations];
      tempArray.push(dataJson.data);
      setTypesConsultations(tempArray);
    }
    //NES je retourne à ma fonction appelante le résultat
    return dataJson;
  };

  //PATCH typesConsultations
  const patchTypesConsultations = async (data) => {
    console.log(data);
    //NES j'appelle l'API Médicale
    const MonUrl = `${process.env.REACT_APP_URL_API_MEDICAL}/typesconsultations/${data._id}`;
    //NES pour détecter les appels en boucle
    console.log({ PATCH: MonUrl });
    const reponseApi = await fetch(MonUrl, {
      method: "PATCH",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        token: authContext.token,
      },
      body: JSON.stringify(data),
    });
    //NES je convertis ce que je récupère en JSON
    const dataJson = await reponseApi.json();
    if (dataJson.code === "OK") {
      //NES je mets à jour le contexte
      let index = typesConsultations.findIndex((item) => item._id === data._id);
      if (index > -1) {
        let tempArray = [...typesConsultations];
        tempArray.splice(index, 1, dataJson.data);
        setTypesConsultations(tempArray);
      }
    }
    //NES je retourne à ma fonction appelante le résultat
    return dataJson;
  };

  //DELETE typesConsultations
  const deleteTypesConsultations = async (typeConsultation_id) => {
    //NES j'appelle l'API Médicale
    const MonUrl = `${process.env.REACT_APP_URL_API_MEDICAL}/typesconsultations/${typeConsultation_id}`;
    //NES pour détecter les appels en boucle
    console.log({ DELETE: MonUrl });
    const reponseApi = await fetch(MonUrl, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        token: authContext.token,
      },
    });
    //NES je convertis ce que je récupère en JSON
    const dataJson = await reponseApi.json();
    if (dataJson.code === "OK") {
      //NES je mets à jour le contexte
      let index = typesConsultations.findIndex(
        (item) => item._id === typeConsultation_id
      );
      console.log({ index });
      if (index > -1) {
        let tempArray = [...typesConsultations];
        tempArray.splice(index, 1);
        console.log(tempArray);
        setTypesConsultations(tempArray);
      }
    }
    //NES je retourne à ma fonction appelante le résultat
    return dataJson;
  };

  //NES retourne l'objet de l'utilisateur
  const getObjetPraticien = (utilisateur_id) => {};

  return (
    <ConsultationsContext.Provider
      value={{
        consultations,
        maConsultation,
        setMaConsultation,
        postConsultationContext,
        deleteConsultationContext,
        typesConsultations,
        patchConsultationContext,
        getConsultationById,
        getMotifsConsultationsUsuels,
        putConsultationMesure,
        deleteConsultationMesureContext,
        getTypesConsultations,
        postTypesConsultations,
        getTypeConsultationById,
        getTypeConsultationGeneriqueByCode,
        patchTypesConsultations,
        deleteTypesConsultations,
        getObjetPraticien,
        getConsultations,
      }}
    >
      {props.children}
    </ConsultationsContext.Provider>
  );
};
