import React, { useState } from "react";
import SurfacePapier from "../../../../../Design/Surfaces/SurfacePapier";
import SurfacePopUp from "../../../../../Design/Surfaces/SurfacePopUp";
import Style from "./PopupChoixRole.module.css";
import BoutonActionPrincipale from "../../../../../Design/Boutons/BoutonActionPrincipale";
import { useEffect } from "react";
import { useContext } from "react";
import { UtilisateursContext } from "../../../../../Data/UtilisateursContext";
import MessageErreur from "../../../../../Design/Messages/MessageErreur";
import { TagRole } from "../../../../../Design/DataDisplay/Tags";
import DescriptionChoixRole from "./DescriptionChoixRole";
import { AuthentificationContext } from "../../../../../Data/AuthentificationContext";

export default function PopupChoixRole({ id, onClose }) {
  //NES gestion du contexte
  const { getRoles, patchUtilisateurs } = useContext(UtilisateursContext);
  const auth = useContext(AuthentificationContext);

  //NES gestion du state
  const [roles, setRoles] = useState([]);
  const [erreur, setErreur] = useState();
  const [error, setError] = useState();

  //USEEFFECT récupération des rôles
  useEffect(() => {
    if (id) {
      getRoles().then((reponse) => {
        if (reponse.code === "OK") {
          let tempArray = reponse.data;

          //NES ne pas afficher les profils type ADPX
          if (auth.payload.code_role !== "ADPX") {
            tempArray = tempArray.filter((item) => item.is_adpx !== true);
          }

          setRoles(tempArray);
        } else {
          console.log(reponse);
          setErreur("Error lors de la récupération des profils");
          setError(reponse);
        }
      });
    }
    // eslint-disable-next-line
  }, [id]);

  //PATCH
  const enregistrerRole = (code_role) => {
    console.log(id, code_role);
    let newData = { code_role: code_role };
    patchUtilisateurs(id, newData);
    if (onClose) {
      onClose();
    }
  };

  if (erreur) {
    return (
      <SurfacePopUp couleur="gris" onClose={onClose} open={id ? true : false}>
        <div className={Style.PopupChoixRole}>
          <MessageErreur message={erreur} error={error} taille="XL" />
        </div>
      </SurfacePopUp>
    );
  }

  return (
    <SurfacePopUp
      couleur="gris"
      onClose={onClose}
      open={id ? true : false}
      titre="Choix du type de compte"
      style={{ width: "fit-content" }}
    >
      <div className={Style.PopupChoixRole}>
        <div className={Style.ListeRoles}>
          {roles.map((role) => (
            <SurfacePapier key={role._id} style={{ maxWidth: "340px" }}>
              <div className={Style.Role}>
                <TagRole code_role={role.code_role} />
                <div
                  className="InformationSecondaire"
                  style={{ marginTop: "10px", textAlign: "center" }}
                >
                  {role.description}
                </div>
                <h3 className={Style.Tarif}>
                  {role.tarif_unitaire_ttc} €
                  <span className="SousTitre"> / mois</span>
                </h3>
                <DescriptionChoixRole code_role={role.code_role} />
                <BoutonActionPrincipale
                  texte="Sélectionner"
                  fullWidth
                  style={{ marginTop: "20px" }}
                  onClick={() => enregistrerRole(role.code_role)}
                />
              </div>
            </SurfacePapier>
          ))}
        </div>
      </div>
    </SurfacePopUp>
  );
}
