import IconesDocuments from "../../Icones/IconesDocuments";
import React, { useContext, useState } from "react";
import MenuItem from "@material-ui/core/MenuItem";
import { Divider } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import LockIcon from "@material-ui/icons/Lock";
import PeopleAltIcon from "@material-ui/icons/PeopleAlt";
import DeleteIcon from "@material-ui/icons/Delete";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import SwipeableViews from "react-swipeable-views";
import BoutonRetour from "../../Boutons/BoutonRetour";
import { DocumentsContext } from "../../../Data/DocumentsContext";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import BoutonRond from "../../Boutons/BoutonRond";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Popover from "@mui/material/Popover";
import GetAppIcon from "@material-ui/icons/GetApp";
export default function MenuDocuments({
  onClickRenommer,
  onClickType,
  onClickPartage,
  onClickPrive,
  onClickSupprimer,
  onClickTelecharger,
  ...props
}) {
  //NES gestion du state
  const [index, setIndex] = useState(0);
  const handleChangeIndex = (index) => {
    setIndex(index);
  };

  //NES Gestion du contexte
  const { typesDocuments } = useContext(DocumentsContext);

  //NES POPOVER
  const [anchorEl, setAnchorEl] = React.useState(null);
  const boutonActionClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setIndex(0);
    setAnchorEl(null);
  };

  //NES quand on clique sur renommer
  const handleClicRenommer = () => {
    if (onClickRenommer) {
      onClickRenommer();
    }
    handleClose();
  };

  //NES quand on clique sur partager
  const handleClickPartage = () => {
    if (onClickPartage) {
      onClickPartage();
    }
    handleClose();
  };

  //NES quand on clique sur un type de document
  const handleClicTypeDocument = (typeDocument) => {
    if (onClickType) {
      onClickType(typeDocument);
    }
    handleClose();
  };

  //NES quand on clique sur privé
  const handleClicPrive = () => {
    if (onClickPrive) {
      onClickPrive();
    }
    handleClose();
  };

  //NES quand on clique sur supprimer
  const handleClicSupprimer = () => {
    if (onClickSupprimer) {
      onClickSupprimer();
    }
    handleClose();
  };

  //NES quand on clique sur télécharger
  const handleClicTelecharger = () => {
    if (onClickTelecharger) {
      onClickTelecharger();
    }
    handleClose();
  };

  return (
    <div>
      <BoutonRond
        iconeComposant={<MoreVertIcon />}
        tooltip="Renommer, partager..."
        onClick={boutonActionClick}
        {...props}
      />
      <Popover
        open={anchorEl ? true : false}
        anchorEl={anchorEl}
        onClose={handleClose}
        // onClick={handleClose}
        anchorOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "center ",
          horizontal: "center",
        }}
      >
        <div
          style={{
            width: "300px",
            padding: "15px",
            // border: "1px solid red",
            height: "250px",
          }}
        >
          <SwipeableViews
            index={index}
            onChangeIndex={handleChangeIndex}
            animateHeight
          >
            {/* 0:MENU */}
            <div>
              <MenuItem onClick={() => handleClicRenommer()}>
                <EditIcon className="IconeMenu" />
                <div style={{ width: "100%" }}>Renommer</div>
              </MenuItem>
              <MenuItem onClick={() => handleChangeIndex(1)}>
                <IconesDocuments className="IconeMenu" />
                <div style={{ width: "100%" }}> Type de document</div>
                <ArrowForwardIosIcon
                  fontSize="small"
                  style={{ color: "#97989D", marginLeft: "15px" }}
                />
              </MenuItem>
              <MenuItem onClick={() => handleClicTelecharger()}>
                <GetAppIcon className="IconeMenu" />
                Télécharger
              </MenuItem>
              <MenuItem onClick={() => handleClicPrive()}>
                <LockIcon className="IconeMenu" />
                Privé
              </MenuItem>
              <MenuItem onClick={() => handleClickPartage()}>
                <PeopleAltIcon className="IconeMenu" />
                Limiter le partage
              </MenuItem>
              <Divider style={{ marginTop: "10px", marginBottom: "10px" }} />
              <MenuItem style={{ color: "red" }} onClick={handleClicSupprimer}>
                <DeleteIcon className="IconeMenu" style={{ color: "red" }} />
                Supprimer
              </MenuItem>
            </div>
            {/* 1:TYPE DE DOCUMENT */}
            <div style={{ padding: "5px" }}>
              <BoutonRetour
                texte="Retour"
                fullWidth
                onClick={() => handleChangeIndex(0)}
                style={{ paddingLeft: "20px" }}
              />
              <List>
                {typesDocuments.map((type) => (
                  <ListItem
                    button
                    disableGutters
                    divider
                    key={type}
                    onClick={() => handleClicTypeDocument(type)}
                  >
                    <div
                      style={{
                        height: "30px",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <IconesDocuments className="Icone" nomIcone={type} />
                      {type}
                    </div>
                  </ListItem>
                ))}
              </List>
            </div>
          </SwipeableViews>
        </div>
      </Popover>
    </div>
  );
}
