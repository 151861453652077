import React, { useContext, useEffect, useState } from "react";
import { MedicalContext } from "../../../../Data/MedicalContext";
import SurfaceBloc from "../../../../Design/Surfaces/SurfaceBloc";
//import { Link } from "react-router-dom";
import MonStyle from "./AffichageSyntheseAntecedents.module.css";
import BoutonAjoutBloc from "../../../../Design/Boutons/BoutonAjoutBloc";
import ObjetAntecedent from "../../../../Design/Objets/ObjetAntecedent";
import { HashLink as Link } from "react-router-hash-link";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";

export default function AffichageSyntheseAntecedents({
  label,
  codes_type_antecedent,
  link,
  patient_id,
  cacherSiVide,
}) {
  //Nes utilisation du contexte
  const { antecedentsEtAllergies } = useContext(MedicalContext);

  //NES gestion du state
  const [antecedents, setAntecedents] = useState([]);

  useEffect(() => {
    let liste = antecedentsEtAllergies.filter(
      (item) =>
        codes_type_antecedent?.includes(item.code_type_antecedent) &&
        item.patient_id === patient_id
    );
    setAntecedents(liste);
  }, [antecedentsEtAllergies, patient_id, codes_type_antecedent]);

  //NES pour ne pas afficher le bloc pour les antécédents familial, chirurgical etc....
  if (cacherSiVide && antecedents.length === 0) {
    return "";
  }

  return (
    <div>
      <h4 style={{ marginBottom: "5px", marginLeft: "10px" }}>{label}</h4>

      {antecedents.length === 0 ? (
        <BoutonAjoutBloc
          iconeComposant={<AddCircleOutlineIcon />}
          texte="AJOUTER"
          link={link}
          taille="L"
          fullWidth
          style={{
            justifyContent: "left",
            paddingLeft: "80px",
          }}
        />
      ) : (
        <SurfaceBloc
          style={{ display: "flex", flexDirection: "column" }}
          isBouton={true}
        >
          <Link to={link}>
            {antecedents.map((antecedent) => (
              <div className={MonStyle.Antecedent} key={antecedent._id}>
                <ObjetAntecedent antecedent={antecedent} />
              </div>
            ))}
          </Link>
        </SurfaceBloc>
      )}
    </div>
  );
}
