import React, { useEffect, useState } from "react";
import InputTexte from "./InputTexte";

export default function InputEmail({
  value,
  label,
  onChange,
  autoFocus,
  isErreur,
  placeholder,
  id,
  style,
  onBlur,
  theme,
  borderRadius,
  onErreur,
  ...props
}) {
  //NES gestion du state
  const [texte, setTexte] = useState("");
  const [erreur, setErreur] = useState("");

  useEffect(() => {
    if (value) {
      setTexte(value);
    }
  }, [value]);

  function checkEmailValide(email) {
    const mailformat = /^[\w\-_.]+@+[\w\-_.]*\.+[a-z]{2,6}$/g;
    if (email) {
      if (email.toLowerCase().match(mailformat)) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  //NES quand on change le texte
  const handleChange = (event) => {
    setTexte(event.target.value);
    if (onChange) {
      onChange(event);
    }
  };

  //NES quand on quitte le champ
  const handleBlur = (event) => {
    let email = event.target.value;
    if (checkEmailValide(email)) {
      setErreur("");
      if (onErreur) {
        onErreur("");
      }
    } else {
      setErreur("L'adresse email n'est pas correcte");
      if (onErreur) {
        onErreur("L'adresse email n'est pas correcte");
      }
    }
    if (onBlur) {
      onBlur(event);
    }
  };

  return (
    <InputTexte
      value={texte}
      label={erreur ? erreur : label}
      isErreur={erreur}
      onBlur={handleBlur}
      onChange={handleChange}
      autoFocus={autoFocus}
      id={id ? id : label ? label : placeholder}
      theme={theme}
      borderRadius={borderRadius}
      placeholder={placeholder}
      {...props}
    />
  );
}
