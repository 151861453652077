import React from "react";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import ObjetItem from "./ObjetItem";

const style = { display: "flex", flexDirection: "row", alignItems: "center" };

export default function ObjetAdresse({ adresse, onClick }) {
  if (!adresse?.rue && !adresse?.ville) {
    return "";
  }

  return (
    <div style={style} onClick={onClick}>
      <LocationOnIcon className="IconeObjet" />
      <ObjetItem principale={adresse?.rue} secondaire={adresse?.ville} />
    </div>
  );
}
