import React from "react";
import ClearCache from "../../Components/ClearCache/ClearCache";
import SurfacePage from "../../Design/Surfaces/SurfacePage";
import { DateToDateString } from "../../functions/FonctionsDateHeure";
import { capitalize } from "../../functions/Formatter";

export default function PageApropos() {
  return (
    <SurfacePage couleur="gris">
      <div style={{ minHeight: "calc(100vh - 70px)" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginTop: "30px",
          }}
        >
          <div className="TitreBloc">A propos </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              borderTop: "1px solid #666",
              borderBottom: "1px solid #666",
              padding: "20px",
              margin: "20px",
            }}
          >
            <h1>
              {capitalize(process.env.REACT_APP_NOM)}{" "}
              {process.env.REACT_APP_COUCOU}
            </h1>
            <div className="Titre2">{process.env.REACT_APP_VERSION}</div>{" "}
            <div className="SousTitre" style={{ marginTop: "10px" }}>
              Mise à jour le{" "}
              {DateToDateString(process.env.REACT_APP_VERSION_DATE * 1000)}
            </div>
          </div>

          <ClearCache taille="L" disabledAutoRefresh />
        </div>
      </div>
    </SurfacePage>
  );
}
