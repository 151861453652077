import React, { useState, useEffect } from "react";
import SurfacePapier from "../../../../../Design/Surfaces/SurfacePapier";
import InputTexteFilled from "../../../../../Design/Formulaires/InputTexteFilled";
import BoutonActionPrincipale from "../../../../../Design/Boutons/BoutonActionPrincipale";
import BoutonAnnuler from "../../../../../Design/Boutons/BoutonAnnuler";
import LoaderHorizontal from "../../../../../Design/Progression/LoaderHorizontal";
import MessageErreur from "../../../../../Design/Messages/MessageErreur";
import InputSwitch from "../../../../../Design/Formulaires/Switch";
import ColorPicker from "../../../../../Design/Pickers/ColorPicker";
import { useContext } from "react";
import { MedicalContext } from "../../../../../Data/MedicalContext";
import BoutonRond from "../../../../../Design/Boutons/BoutonRond";
import ObjetTracabilite from "../../../../../Design/Objets/ObjetTracabilite";
import InputCheckbox from "../../../../../Design/Formulaires/InputCheckbox";

export default function EditionAllergieAntecedent({
  patient_id,
  onAnnuler,
  onEnregistrer,
  codeTypeAntecedent,
  dataEdited,
  titre,
}) {
  //NES gestion du contexte
  const {
    postAntecedentsEtAllergiesContext,
    patchAntecedentEtAllergiesContext,
    deleteAntecedentEtAllergiesContext,
  } = useContext(MedicalContext);

  //NES constantes
  const listeParentes = [
    { code: "Père", libelle: "Père" },
    { code: "Mère", libelle: "Mère" },
    { code: "Frère", libelle: "Frère" },
    { code: "Soeur", libelle: "Soeur" },
    { code: "Grand-Père", libelle: "Grand-Père" },
    { code: "Grand-Mère", libelle: "Grand-Mère" },
    { code: "Oncle", libelle: "Oncle" },
    { code: "Tante", libelle: "Tante" },
  ];

  //NES Gestion du state
  const [isLoading, setIsLoading] = useState(false);
  const [erreur, setErreur] = useState("");
  const [error, setError] = useState(null);
  const [data, setData] = useState({
    libelle: "",
    commentaire: "",
    depuis_le: "",
    is_important: false,
    is_bookmark: false,
    is_archive: false,
    code_couleur: "",
    code_type_antecedent: codeTypeAntecedent,
  });

  //USEEFFECT
  useEffect(() => {
    if (codeTypeAntecedent) {
      let newData = { ...data, code_type_antecedent: codeTypeAntecedent };
      setData(newData);
    }
    // eslint-disable-next-line
  }, [codeTypeAntecedent]);

  //USEEFFECT
  useEffect(() => {
    if (dataEdited) {
      setData(dataEdited);
    }
  }, [dataEdited]);

  ///handleChange
  const handleChange = (key, value) => {
    let newData = { ...data, [key]: value };
    setData(newData);
  };

  ///handleImportant
  const handleImportant = (value) => {
    //NES important je force la couleur rouge
    let couleur = "";
    if (value) {
      couleur = "red";
    }

    let newData = {
      ...data,
      is_important: value,
      is_boorkmark: value,
      code_couleur: couleur,
    };
    setData(newData);
  };

  ///handleEnregistrer
  const handleEnregistrer = async (e) => {
    e.preventDefault();

    setIsLoading(true);

    let reponseApi = {};

    if (data._id) {
      reponseApi = await patchAntecedentEtAllergiesContext(data);
    } else {
      reponseApi = await postAntecedentsEtAllergiesContext(patient_id, data);
    }

    setIsLoading(false);

    if (reponseApi.code === "OK") {
      setErreur("");
      setError();
      onEnregistrer();
    } else {
      setErreur("Erreur lors de l'enregistrement");
      setError(reponseApi.error);
    }
  };

  ///handleDelete
  const handleDelete = async (e) => {
    e.preventDefault();

    onEnregistrer();
    setIsLoading(true);

    let reponseApi = await deleteAntecedentEtAllergiesContext(data);

    setIsLoading(false);

    if (reponseApi.code === "OK") {
      onEnregistrer();
    } else {
      setErreur("Erreur lors de la suppression");
      setError(reponseApi.error);
    }
  };

  return (
    <SurfacePapier>
      <form>
        <hgroup>
          <h2>{data._id ? data.libelle : titre}</h2>
          <BoutonRond onClick={onAnnuler} couleurIcone="noir" />
        </hgroup>

        <InputTexteFilled
          label={titre}
          autoFocus
          value={data.libelle}
          onChange={(e) => handleChange("libelle", e.target.value)}
        />
        <InputTexteFilled
          value={data.commentaire}
          onChange={(e) => handleChange("commentaire", e.target.value)}
          label="Commentaire"
          multiline
          rows={3}
        />
        <InputTexteFilled
          value={data.depuis_le}
          label="Depuis le"
          onChange={(e) => handleChange("depuis_le", e.target.value)}
        />

        {codeTypeAntecedent === "antecedent_familial" && (
          <InputCheckbox
            label="Parenté"
            values={listeParentes}
            onChecked={(liste) => handleChange("parente", liste)}
            valuesChecked={data.parente}
          />
        )}
        <InputSwitch
          value={data.is_important}
          onChange={handleImportant}
          variant="formulaire"
          label="Important"
          description="Il apparaitra en rouge dans la synthèse patient"
        />
        {data.is_important && (
          <ColorPicker
            accepteVide
            isCouleursBase
            couleurSelectionne={data.code_couleur}
            label="Personnaliser la couleur"
            onCouleurChange={(color) => handleChange("code_couleur", color)}
            listeCouleurs={[
              "red",
              "DarkOrange",
              "DarkMagenta",
              "Green",
              "DarkCyan",
              "RoyalBlue",
            ]}
          />
        )}
        <LoaderHorizontal isLoading={isLoading} />

        <MessageErreur message={erreur} error={error} />
        <BoutonActionPrincipale
          texte="Enregistrer"
          onClick={handleEnregistrer}
          disabled={!data.libelle}
        />
        <BoutonAnnuler onClick={onAnnuler} />

        <ObjetTracabilite
          tracabiliteCreation={data.tracabilite_creation}
          tracabiliteModification={data.tracabilite_modification}
          onDelete={(e) => handleDelete(e)}
        />
      </form>
    </SurfacePapier>
  );
}
