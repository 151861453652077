import React, { useState } from "react";
import MonStyle from "./EditionEvenement.module.css";
import ListItem from "@material-ui/core/ListItem";
import AutorenewIcon from "@material-ui/icons/Autorenew";
import ClearIcon from "@material-ui/icons/Clear";
import SurfaceBloc from "../../../../../Design/Surfaces/SurfaceBloc";
import AfficherPlus from "../../../../../Design/DataDisplay/AfficherPlus";
import InputSelect from "../../../../../Design/Formulaires/InputSelect";

export default function EditionRecurrence({ recurrence, onRecurrenceChange }) {
  const [recurrencePerso, setRecurrencePerso] = useState({ frequence: 2 });

  const handleClick = (code) => {
    let objRecurrence = {};
    switch (code) {
      case "jour":
        objRecurrence = { libelle: "Tous les jours", code: "jour" };
        break;
      case "semaine":
        objRecurrence = { libelle: "Toutes les semaines", code: "semaine" };

        break;
      case "mois":
        objRecurrence = { libelle: "Tous les mois", code: "mois" };
        break;
      case "aucune":
        objRecurrence = {};
        break;
      default:
        objRecurrence = {};
        break;
    }

    if (onRecurrenceChange) {
      onRecurrenceChange(objRecurrence);
    }
  };

  const libelleRecurrencePersonnalise = (frequence, code) => {
    let libelle = "";

    switch (code) {
      case "jour":
        libelle = "Tous les " + frequence + " jours";

        break;
      case "semaine":
        libelle = "Toutes les  " + frequence + " semaines";
        break;
      case "mois":
        libelle = "Tous les " + frequence + "  mois";
        break;
      default:
        libelle = "";
        break;
    }
    return libelle;
  };

  const handleFrequencePersoChange = (value) => {
    let objRecurrence = {};
    objRecurrence = { ...recurrencePerso, frequence: value };

    setRecurrencePerso(objRecurrence);

    console.log(objRecurrence);
    //NES en cas de modification de la fréquence
    if (objRecurrence.code) {
      objRecurrence = {
        ...objRecurrence,
        libelle: libelleRecurrencePersonnalise(value, objRecurrence.code),
      };

      if (onRecurrenceChange) {
        onRecurrenceChange(objRecurrence);
      }
    }
  };

  const handleCodePersoChange = (code) => {
    let objRecurrence = { ...recurrencePerso };

    objRecurrence = {
      ...objRecurrence,
      code: code,
      libelle: libelleRecurrencePersonnalise(objRecurrence.frequence, code),
    };

    setRecurrencePerso(objRecurrence);

    if (onRecurrenceChange) {
      onRecurrenceChange(objRecurrence);
    }
  };

  return (
    <SurfaceBloc
      style={{
        marginTop: "20px",
        maxHeight: "360px",
        overflow: "scroll",
        border: "0px solid green",
      }}
    >
      <ListItem
        button
        className={MonStyle.Choix}
        onClick={() => handleClick("aucune")}
      >
        <ClearIcon className="Icone" />
        Aucune récurrence
      </ListItem>
      <ListItem
        button
        className={MonStyle.Choix}
        onClick={() => handleClick("jour")}
      >
        <AutorenewIcon className="Icone" />
        Tous les jours{" "}
      </ListItem>
      <ListItem
        button
        className={MonStyle.Choix}
        onClick={() => handleClick("semaine")}
      >
        <AutorenewIcon className="Icone" />
        Toutes les semaines{" "}
      </ListItem>
      <ListItem
        button
        className={MonStyle.Choix}
        onClick={() => handleClick("mois")}
      >
        <AutorenewIcon className="Icone" />
        Tous les mois{" "}
      </ListItem>

      <AfficherPlus
        open={true}
        labelOpen="Récurrence personnalisée"
        labelClose="Récurrence personnalisée"
        style={{ padding: "20px" }}
      >
        <InputSelect
          value={recurrencePerso.frequence}
          options={[2, 3, 4, 5, 6]}
          label="Tous les "
          onChange={(e) => handleFrequencePersoChange(e.target.value)}
        />
        <InputSelect
          options={["jours", "semaines", "mois"]}
          values={["jour", "semaine", "mois"]}
          label="jours, semaines, mois"
          onChange={(e) => handleCodePersoChange(e.target.value)}
        />
      </AfficherPlus>
    </SurfaceBloc>
  );
}
