import React, { useState, useEffect, useContext } from "react";
import MessageAucuneDonnee from "../../../../Design/Messages/MessageAucuneDonnee";
import MessageErreur from "../../../../Design/Messages/MessageErreur";
import LoaderHorizontal from "../../../../Design/Progression/LoaderHorizontal";
import SurfacePapier from "../../../../Design/Surfaces/SurfacePapier";
import MonStyle from "./PageJournalDesReglements.module.css";
import { CotationsContext } from "../../../../Data/CotationsContext";
import ObjetReglement from "../../../../Design/Objets/ObjetReglement";
import { pluriel } from "../../../../functions/Formatter";
import { DateShortOuHeureToString } from "../../../../functions/FonctionsDateHeure";
import BoutonFiltre from "../../../../Design/Recherche/BoutonFiltre";
import { IconeReglement } from "../../../../Design/Icones/IconesReglements";
import FiltreDate from "../../../../Design/Recherche/Filtres/FiltreDate";
import BoutonMenu from "../../../../Design/Navigation/BoutonMenu";
import PrintIcon from "@material-ui/icons/Print";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { Divider } from "@material-ui/core";
import DocumentJournalDesReglements from "../../../../Docs/Pages/DocumentJournalDesReglements";
import { isMobile } from "../../../../functions/Navigateur";
import BoutonRetour from "../../../../Design/Boutons/BoutonRetour";
import DocumentJournalDeCaisse from "../../../../Docs/Pages/DocumentJournalDeCaisse";

export default function PageJournalDesReglements() {
  //NES Gestion du context
  const { getReglements } = useContext(CotationsContext);

  let aujourdhui = new Date();

  //NES Gestion du state
  const [liste, setListe] = useState([]);
  const [listeAffiche, setListeAffiche] = useState([]);
  const [isLoading, setIsLoading] = useState();
  const [messageErreur, setMessageErreur] = useState("");
  const [error, setError] = useState();
  const [message, setMessage] = useState();
  const [modesReglements, setModesReglements] = useState([]);
  const [totalReglements, setTotalReglements] = useState();
  const [filtreMode, setFiltreMode] = useState();
  const [filtreDateDebut, setFiltreDateDebut] = useState(aujourdhui);
  const [filtreDateFin, setFiltreDateFin] = useState(aujourdhui);
  const [isImprimerJournalReglements, setIsImprimerJournalReglements] =
    useState(false);
  const [isImprimerJournalCaisse, setIsImprimerJournalCaisse] = useState(false);

  //USEEFFECT Chargment des règlements
  useEffect(() => {
    //NES Je charge les règlements
    getReglements(filtreDateDebut, filtreDateFin)
      .then((reponse) => {
        setIsLoading(false);
        if (reponse.code === "OK") {
          setListe(reponse.data);
          setListeAffiche(reponse.data);
          setMessageErreur();
          if (reponse.data.length === 0) {
            setMessage("Aucun règlement");
          } else {
            setMessage("");
          }
        } else {
          setMessageErreur("Erreur lors de la récupération des règlements");
        }
      })
      .catch((error) => {
        setIsLoading(false);
        setMessageErreur("Erreur lors de la récupération des règlements");
        setError(error);
      });
    // eslint-disable-next-line
  }, [filtreDateDebut, filtreDateFin]);

  //USEEFFECT calcul du total
  useEffect(() => {
    let totalParMode = [];
    let total = 0;

    liste.forEach((reglement) => {
      //NES Calcul du total global
      total = total + reglement.montant;
      //NES Calcul du total pour le mode de règlement
      let indexModeReglement = totalParMode.findIndex(
        (item) => item.code_mode_reglement === reglement.code_mode_reglement
      );

      if (indexModeReglement > -1) {
        //NES je mets à jour le total par mode
        let modeReglement = totalParMode[indexModeReglement];
        modeReglement = {
          ...modeReglement,
          total: modeReglement.total + reglement.montant,
        };
        totalParMode.splice(indexModeReglement, 1, modeReglement);
      } else {
        //NES je créé le total par mode de règlement
        let newTotalMode = {
          code_mode_reglement: reglement.code_mode_reglement,
          libelle_mode_reglement: reglement.libelle_mode_reglement,
          total: reglement.montant,
        };
        totalParMode.push(newTotalMode);
      }
      // eslint-disable-next-line
    });

    setTotalReglements(total);
    setModesReglements(totalParMode);
  }, [liste]);

  //USEEFFECT Filtrer la liste affichée
  useEffect(() => {
    if (filtreMode) {
      let tempArray = [...liste];
      tempArray = tempArray.filter(
        (item) => item.libelle_mode_reglement === filtreMode
      );
      setListeAffiche(tempArray);
    } else {
      setListeAffiche(liste);
    }
    // eslint-disable-next-line
  }, [filtreMode]);

  //NES filtrer par mode de règlement
  const handleFiltreMode = (libelle_mode_reglement) => {
    setFiltreMode(libelle_mode_reglement);
  };

  //NES Effacer le filtre du mode de règlement
  const handleEffacerFiltre = () => {
    setFiltreMode();
  };

  //NES quand je change la date de début
  const handleDateDebutChange = (date) => {
    setFiltreDateDebut(date);
    //NES si la date de fin est < je change aussi la date de fin
    if (date > filtreDateFin) {
      setFiltreDateFin(date);
    }
  };

  //NES quand je change la date de fin
  const handleDateFinChange = (date) => {
    setFiltreDateFin(date);
    //NES si la date de fin est < je change aussi la date de debut
    if (date < filtreDateDebut) {
      setFiltreDateDebut(date);
    }
  };
  return (
    <SurfacePapier>
      <div className={MonStyle.PageJournalDesReglements}>
        <div className={MonStyle.TitreEtBoutons}>
          <div>
            <div className="Titre1">Journal des règlements</div>
            <div className="SousTitre">
              {pluriel(liste.length, "règlement", "règlements")}
              {" pour un total de : "}
              {totalReglements + " €"}
            </div>
          </div>

          <BoutonMenu
            iconeComposant={<PrintIcon />}
            couleur="vert"
            couleurIcone="blanc"
            tooltip="imprimer"
          >
            <List disablePadding>
              <ListItem
                button
                onClick={() => setIsImprimerJournalReglements(true)}
              >
                <PrintIcon className="IconeMenu" />
                <ListItemText
                  primary="Journal des règlements"
                  secondary="Impression des règlements affichés"
                />
              </ListItem>
              <Divider />
              <ListItem button onClick={() => setIsImprimerJournalCaisse(true)}>
                <PrintIcon className="IconeMenu" />
                <ListItemText
                  primary="Journal de caisse"
                  secondary="Total par jour"
                />
              </ListItem>
            </List>
          </BoutonMenu>
        </div>
        <div className={MonStyle.BoutonRetour}>
          <BoutonRetour
            texte="Retour au suivi des règlements"
            link="/suivireglements/"
          />
        </div>

        <div className={MonStyle.Filtres}>
          <FiltreDate
            label="du :"
            style={{ margin: "5px 5px" }}
            value={filtreDateDebut}
            onChange={handleDateDebutChange}
          />
          <FiltreDate
            label="au :"
            style={{ margin: "5px 5px" }}
            value={filtreDateFin}
            onChange={handleDateFinChange}
          />
          <BoutonFiltre
            texte="Mode de règlement"
            onEffacer={handleEffacerFiltre}
            libelleFiltre={filtreMode}
            style={{ margin: "5px 5px" }}
          >
            <List>
              {modesReglements.map((mode) => (
                <ListItem
                  button
                  disableGutters
                  divider
                  key={mode.code_mode_reglement}
                  selected={mode.libelle_mode_reglement === filtreMode}
                  //onClick={() => setConsultationSelectionne(consultation._id)}
                  onClick={() => handleFiltreMode(mode.libelle_mode_reglement)}
                >
                  <div className="IconeMenu">
                    <IconeReglement
                      code={mode.code_mode_reglement}
                      taille="32"
                    />
                  </div>
                  <div style={{ minWidth: "150px" }}>
                    {mode.libelle_mode_reglement}
                  </div>
                  <div>{mode.total} €</div>
                </ListItem>
              ))}
            </List>
          </BoutonFiltre>
        </div>
        <DocumentJournalDesReglements
          open={isImprimerJournalReglements}
          onClose={() => setIsImprimerJournalReglements(false)}
          listeReglements={listeAffiche}
          filtreModeReglement={filtreMode}
          dateDebut={filtreDateDebut}
          dateFin={filtreDateFin}
        />
        <DocumentJournalDeCaisse
          listeReglements={liste}
          open={isImprimerJournalCaisse}
          onClose={() => setIsImprimerJournalCaisse(false)}
          dateDebut={filtreDateDebut}
          dateFin={filtreDateFin}
        />
        <div className={MonStyle.ListeReglements}>
          <LoaderHorizontal isLoading={isLoading} />
          <MessageErreur message={messageErreur} error={error} />
          {listeAffiche.map((reglement) => (
            <div className="ListeLigne" key={reglement._id}>
              <div className={MonStyle.ColonneDate}>
                {DateShortOuHeureToString(reglement.date, {
                  day: "numeric",
                  month: "numeric",
                  year: "numeric",
                })}
              </div>
              <ObjetReglement
                reglement={reglement}
                isAfficherDate={isMobile()}
              />
            </div>
          ))}
          <MessageAucuneDonnee message={message} hideIllustration />
        </div>
      </div>
    </SurfacePapier>
  );
}
