import React, { useEffect, useState } from "react";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FormLabel from "@material-ui/core/FormLabel";
import { withStyles } from "@material-ui/core/styles";

//NES style dynamique de la checkbox
const BeCheckbox = withStyles({
  root: {
    color: "#208AB0",
    "&$checked": {
      color: "#208AB0",
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

//NES style dynamique du label de la checkbox
const BeFormControlLabel = withStyles({
  root: {
    "& .MuiTypography-body1": {
      //fontFamily: "Lato",
      color: (props) => (props.checked ? "#208AB0" : "#666"),

      // fontWeight: (props) => (props.checked ? "500" : "400"),
    },
  },
})(FormControlLabel);

export default function InputCheckbox({
  label,
  values,
  valuesChecked,
  onChecked,
  onClick,
  column,
  style,
}) {
  //NES Gestion du state
  const [checked, setChecked] = useState([]);

  useEffect(() => {
    if (valuesChecked) {
      setChecked(valuesChecked);
    }
  }, [valuesChecked]);

  const handleClick = (event) => {
    let tempArray;

    if (event.target.checked) {
      tempArray = [...checked, event.target.name];
      setChecked(tempArray);
    } else {
      tempArray = checked.filter((item) => item !== event.target.name);
      setChecked(tempArray);
    }
    if (onClick) {
      onClick(event);
    }
    //NES je retourne les codes cochés
    if (onChecked) {
      onChecked(tempArray);
    }
  };

  return (
    <div
      style={{
        paddingLeft: "15px",
        marginTop: "20px",
        ...style,
      }}
    >
      <FormLabel component="legend" style={{ fontSize: "12px" }}>
        {label}
      </FormLabel>
      <FormGroup
        style={{
          display: column ? "grid" : "flex",
          gridTemplateColumns: column ? `repeat(${column}, 1fr)` : "",
        }}
      >
        {values &&
          values.map((item) => (
            <BeFormControlLabel
              control={
                <BeCheckbox
                  checked={checked.includes(item.code)}
                  onChange={handleClick}
                  name={item.code}
                />
              }
              label={item.libelle ? item.libelle : item.code}
              key={item.code}
              onClick={handleClick}
              checked={checked.includes(item.code)}
              //style={styleCoche}
            />
          ))}
      </FormGroup>
    </div>
  );
}
