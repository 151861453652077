import React, { useRef } from "react";
import { Bar, getElementAtEvent } from "react-chartjs-2";
import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
} from "chart.js";

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip
);

export default function GraphiqueBarre({ labelsX, titreX, titreY, valeurs }) {
  //NES gestion du state

  //NES gestion des ref
  const chartRef = useRef();

  const onClick = (event) => {
    console.log(getElementAtEvent(chartRef.current, event));
  };

  let modele = {
    labelX: "Age en mois",
    labelY: "Taille en cm",
    borderColor: "red",
    backgroundColor: "red",
    axeYmin: 0,
  };

  //NES Options d'affichage du graphique
  let options = {
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      y: {
        title: { display: true, text: titreY },
        min: modele.axeYmin,
      },
      x: {
        title: { display: true, text: titreX },
      },
    },
    //responsive: true,
    //maintainAspectRatio: false,
  };

  let data = {
    labels: labelsX,
    //labels: ["January", "February", "March", "April", "May", "June"],
    datasets: [
      {
        label: "Valeurs",
        //pointStyle: "circle",
        data: valeurs,
        backgroundColor: modele.backgroundColor,
        borderWidth: 2,
        // radius: 2,
        //borderColor: "red",
        //spanGaps: true,
        //bezierCurve: true,
        // tension: 0,
      },
    ],
  };

  return (
    <div>
      <Bar data={data} options={options} onClick={onClick} ref={chartRef} />
    </div>
  );
}
