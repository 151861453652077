import React, { useEffect, useContext, useState } from "react";
import MonStyle from "./SuggestionInformationsCabinet.module.css";
import BoutonActionSecondaire from "../../../Design/Boutons/BoutonActionSecondaire";
import SurfacePapier from "../../../Design/Surfaces/SurfacePapier";
import IllustrationLegal from "../../../Images/Illustrations/Legal.png";
import { CabinetsContext } from "../../../Data/CabinetContext";
import SettingsIcon from "@material-ui/icons/Settings";
import MessageAide from "../../../Design/Messages/MessageAide";

export default function SuggestionInformationCabinet({ style }) {
  //NES gestion du contexte
  const { cabinetConnexion, controleInformationsCabinet } =
    useContext(CabinetsContext);

  //NES gestion du state
  const [afficher, setAfficher] = useState(false);
  const [liste, setListe] = useState([]);

  //USEEFFECT
  useEffect(() => {
    if (cabinetConnexion) {
      let listeErreurs = controleInformationsCabinet(cabinetConnexion);
      if (listeErreurs.length) {
        setListe(listeErreurs);
        setAfficher(true);
      }
    }
    // eslint-disable-next-line
  }, [cabinetConnexion]);

  if (!afficher) {
    return "";
  }

  return (
    <SurfacePapier style={style}>
      <div className={MonStyle.SuggestionInformationCabinet}>
        <MessageAide
          message="Ces informations sont obligatoires pour que vos factures soient conformes."
          severity="error"
          disableClose
          style={{ marginBottom: "30px" }}
        />

        <div className={MonStyle.Illustration}>
          <img src={IllustrationLegal} alt="" className="Illustration" />
        </div>

        <div className={MonStyle.TexteEtBouton}>
          <h4>Informations du cabinet</h4>
          <h3>Vous devez renseigner :</h3>
          {liste.map((ligne, index) => (
            <li key={index}>{ligne}</li>
          ))}

          <BoutonActionSecondaire
            startIcon={<SettingsIcon />}
            texte="Renseigner les informations du cabinet"
            style={{ margin: "30px 0px" }}
            taille="L"
            link={"/options/cabinets/" + cabinetConnexion?._id}
          />
        </div>
      </div>
    </SurfacePapier>
  );
}
