import React from "react";
import MonStyle from "./EditionEvenement.module.css";
import SurfaceBloc from "../../../../../Design/Surfaces/SurfaceBloc";
import ListItem from "@material-ui/core/ListItem";
import EventIcon from "@material-ui/icons/Event";
import AutorenewIcon from "@material-ui/icons/Autorenew";

export default function EnregistrerChoixRecurrence({
  label,
  onClickCetEvenement,
  onClickTous,
}) {
  return (
    <div>
      <div className="Titre2" style={{ marginTop: "40px" }}>
        {label}
      </div>
      <SurfaceBloc style={{ marginTop: "40px" }}>
        <ListItem
          button
          className={MonStyle.Choix}
          onClick={onClickCetEvenement}
        >
          <EventIcon className="Icone" />
          Juste cet évenement
        </ListItem>
      </SurfaceBloc>
      <SurfaceBloc style={{ marginTop: "20px" }}>
        <ListItem button className={MonStyle.Choix} onClick={onClickTous}>
          <AutorenewIcon className="Icone" />
          Aussi les évènements à venir
        </ListItem>
      </SurfaceBloc>
    </div>
  );
}
