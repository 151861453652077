import React, { useContext } from "react";
import { useState, useEffect } from "react";
import { DateOuHeureToString } from "../../functions/FonctionsDateHeure";
import MonStyle from "./ObjetSms.module.css";
import DoneIcon from "@material-ui/icons/Done";
import { SvgIcon } from "@material-ui/core";
import MessageAide from "../Messages/MessageAide";
import AffichageConditionnel from "../DataDisplay/AffichageConditionnel";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import { MessagesContext } from "../../Data/MessagesContext";

export default function ObjetSms({ sms }) {
  //NES gestion du state
  const [data, setData] = useState({});
  const [open, setOpen] = useState(false);
  const [erreur, setErreur] = useState("");
  const [isDistribue, setIsDistribue] = useState(false);

  //NES gestion du contexte
  const { getSmsStatut } = useContext(MessagesContext);

  useEffect(() => {
    if (sms) {
      console.log(sms);
      setData(sms);
    }

    //NES SMSMODE
    if (sms.smsmode_status_url) {
      getSmsStatut(sms._id).then((reponse) => {
        console.log(reponse);
        if (reponse.code === "OK") {
          if (reponse.data.status.value === "DELIVERED") {
            setIsDistribue(true);
          } else {
            setIsDistribue(false);
            setErreur(reponse.data.status.detail);
          }
        }
      });
    }
    // eslint-disable-next-line
  }, [sms]);

  let dateEtUtilisateur = "";

  let couleur = "#0fa600";

  if (data.is_automatique) {
    couleur = " #205a71";
  }

  if (data.is_erreur) {
    couleur = "red";
  }

  if (!data.is_automatique) {
    dateEtUtilisateur =
      DateOuHeureToString(data.tracabilite_creation?.date_tracabilite) +
      " par " +
      data.tracabilite_creation?.prenom_utilisateur +
      " " +
      data.tracabilite_creation?.nom_utilisateur;
  } else {
    dateEtUtilisateur = DateOuHeureToString(
      data.tracabilite_creation?.date_tracabilite
    );
  }

  ///handleAfficher
  const handleAfficher = () => {
    setOpen(true);
  };

  ///handleAfficher
  const handleMasquer = () => {
    setOpen(false);
  };

  return (
    <div>
      <div className={MonStyle.Sms} style={{ backgroundColor: couleur }}>
        {data.message}
      </div>
      {data.tracabilite_creation && (
        <div className={MonStyle.InformationsSecondaire}>
          <div className={MonStyle.DateEnvoi}>{dateEtUtilisateur} </div>
          <AffichageConditionnel open={isDistribue}>
            <div className={MonStyle.Suivi}>
              <SvgIcon component={DoneIcon} style={{ fontSize: "20px" }} />
              Distribué
            </div>
          </AffichageConditionnel>
          <AffichageConditionnel open={erreur}>
            <div className={MonStyle.SuiviError} onClick={handleAfficher}>
              <SvgIcon
                component={ErrorOutlineIcon}
                style={{ fontSize: "16px" }}
              />
              Non distribué
            </div>
          </AffichageConditionnel>
        </div>
      )}
      <AffichageConditionnel open={open}>
        <MessageAide
          message=""
          onClose={handleMasquer}
          severity="error"
          style={{ marginTop: "10px" }}
        />
      </AffichageConditionnel>
    </div>
  );
}
