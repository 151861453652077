import React, { useState, useEffect } from "react";
import OngletsBoutons from "../../../../Design/DataDisplay/OngletsBoutons";
import InputSwitch from "../../../../Design/Formulaires/Switch";
import { Collapse } from "@material-ui/core";
import SimulationSms from "./SimulationSms";
import AfficherPlus from "../../../../Design/DataDisplay/AfficherPlus";
export default function AgendaBlocSms({ agenda, onChange }) {
  //NES Gestion du state
  const [sms, setSms] = useState({});

  //USEEFFECT
  useEffect(() => {
    if (agenda?.sms) {
      setSms(agenda.sms);
    }
  }, [agenda]);

  //NES Quand on change une valeur
  const handleChange = (key, value) => {
    let newSms = { ...sms, [key]: value };
    setSms(newSms);
    let newData = { ...agenda, sms: newSms };
    //NES remonter l'état
    if (onChange) {
      onChange(newData);
    }
  };

  //NES quand on choche rappel
  const handleCheckRappel = (e) => {
    let value = e;
    let newSms = {};
    if (value) {
      newSms = {
        ...sms,
        is_sms_rappel_rdv: value,
        email_delai_rappel_heures: 24,
      };
    } else {
      newSms = {
        ...sms,
        is_sms_rappel_rdv: value,
      };
      delete newSms.sms_delai_rappel_heures;
    }

    setSms(newSms);
    let newData = { ...agenda, sms: newSms };
    //NES remonter l'état
    if (onChange) {
      onChange(newData);
    }
  };

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <section>
        <h3>Confirmer le rendez-vous par email</h3>
        <InputSwitch
          value={sms.is_sms_confirmation_rdv}
          onChange={(e) => handleChange("is_sms_confirmation_rdv", e)}
          label="Envoyer un SMS de confirmation"
          description="Un SMS sera envoyé au patient lors de la prise de rendez-vous.
            Ainsi que lors de la modification ou l'annulation du rendez-vous."
        />
      </section>
      <section>
        <h3>Rappel de rendez-vous par SMS</h3>
        <InputSwitch
          value={sms.is_sms_rappel_rdv}
          onChange={handleCheckRappel}
          label="SMS de rappel"
          description="Envoi un SMS pour rappeller le rendez vous au patient"
        />
        <Collapse in={sms.is_sms_rappel_rdv}>
          <div style={{ marginTop: "20px" }}>Délai de rappel</div>
          <OngletsBoutons
            labels={["24 heures", "48 heures", "72 heures"]}
            onglets={[24, 48, 72]}
            onSelect={(onglet) =>
              handleChange("sms_delai_rappel_heures", onglet)
            }
            ongletSelectionne={sms.sms_delai_rappel_heures}
          />
        </Collapse>
      </section>
      <section>
        <h3>Personnaliser le texte</h3>
        <p className="SousTitre" style={{ marginBottom: "20px" }}>
          Vous pouvez saisir du texte qui sera ajouté au sms envoyé au patient
        </p>
        {/* <InputTexte
          label="Texte confirmation de rdv"
          multiline
          value={sms?.sms_texte_personnalise}
          onChange={(e) =>
            handleChange("sms_texte_personnalise", e.target.value)
          }
        /> */}
        <SimulationSms
          titre="Confirmation du rendez-vous"
          agenda={agenda}
          modele="CONFIRMATION"
          value={sms?.sms_texte_personnalise}
          onChange={(e) =>
            handleChange("sms_texte_personnalise", e.target.value)
          }
        />
        <AfficherPlus
          labelOpen="Personnaliser les sms d'annulation"
          labelClose="Masquer"
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "40px",
              marginTop: "20px",
            }}
          >
            <SimulationSms
              titre="Rendez-vous excusé"
              agenda={agenda}
              modele="EXCUSE"
              value={sms?.sms_texte_personnalise_excuse}
              onChange={(e) =>
                handleChange("sms_texte_personnalise_excuse", e.target.value)
              }
            />
            <SimulationSms
              titre="Excusé à la dernière minute"
              agenda={agenda}
              modele="EXCUSE_DERNIERE_MINUTE"
              value={sms?.sms_texte_personnalise_excuse_derniere_minute}
              onChange={(e) =>
                handleChange(
                  "sms_texte_personnalise_excuse_derniere_minute",
                  e.target.value
                )
              }
            />
            <SimulationSms
              titre="Rendez vous non honoré"
              agenda={agenda}
              modele="NON_HONORE"
              value={sms?.sms_texte_personnalise_non_honore}
              onChange={(e) =>
                handleChange(
                  "sms_texte_personnalise_non_honore",
                  e.target.value
                )
              }
            />
          </div>
        </AfficherPlus>
      </section>
    </div>
  );
}
