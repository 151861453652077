import React, { useEffect, useState } from "react";
import MonStyle from "./HorairesSemaine.module.css";
import HorairesJour from "./HorairesJour";
import BoutonTexte from "../../../../Design/Boutons/BoutonTexte";
import { numeroJourToNomJour } from "../../../../functions/FonctionsDateHeure";
import BoutonAjoutBloc from "../../../../Design/Boutons/BoutonAjoutBloc";

export default function HorairesSemaine({
  value,
  joursSelectionnes,
  onChange,
}) {
  const [afficherBoutonChoisir, setAfficherBoutonChoisir] = useState(true);
  const [horaires, setHoraires] = useState([
    {
      numero_jour: "all",
      jour_nom: "",
      matin_debut: "",
      matin_fin: "",
      apresmidi_debut: "",
      apresmidi_fin: "",
    },
  ]);

  //NES gestion du state des horaires
  const handleHorairesSemaineChange = (numero_jour, key, value) => {
    let tempHorairesSemaine = [...horaires];
    if (numero_jour !== "") {
      let indexJour = tempHorairesSemaine.findIndex(
        (item) => item.numero_jour === numero_jour
      );
      if (indexJour > -1) {
        let monHoraire = tempHorairesSemaine[indexJour];
        monHoraire[key] = value;
        tempHorairesSemaine.splice(indexJour, 1, monHoraire);
        setHoraires(tempHorairesSemaine);
        //NES retour de l'horaire modifié
        if (onChange) {
          onChange(tempHorairesSemaine);
        }
      }
    }
  };

  //NES Construction des horaires de la semaine avec pour chaque jour travaillé les horaires de la semaine
  //NES Quand je clique sur "Choisir par jour"
  const handleClickHorairesJours = (jours) => {
    //NES on masque le bouton choisir par jour
    setAfficherBoutonChoisir(false);
    let listeHoraires = [];
    let horairesAll = horaires.find((item) => item.numero_jour === "all");
    jours.forEach((element) => {
      let horaires = {
        numero_jour: element,
        jour_nom: numeroJourToNomJour(element),
        matin_debut: horairesAll.matin_debut,
        matin_fin: horairesAll.matin_fin,
        apresmidi_debut: horairesAll.apresmidi_debut,
        apresmidi_fin: horairesAll.apresmidi_fin,
      };
      listeHoraires.push(horaires);
    });
    setHoraires(listeHoraires);
    //NES je retourne le nouvel horaire
    if (onChange) {
      onChange(listeHoraires);
    }
  };

  //NES Dé-Construction des horaires de la semaine
  //NES quand je clique sur "horaires semaine"
  const handleClickHorairesSemaine = () => {
    //NES je garde le 1er item de la semaine
    let horairesAll = horaires[0];
    horairesAll.numero_jour = "all";
    horairesAll.jour_nom = "";
    setHoraires([horairesAll]);
    //NES j'affiche le bouton choisir par jour
    setAfficherBoutonChoisir(true);
    //NES je retourne le nouvel horaire
    if (onChange) {
      onChange([horairesAll]);
    }
  };

  useEffect(() => {
    //NES récupération des horaires
    if (value) {
      setHoraires(value);
      //NES je dois afficher quel bouton?
      let all = value.find((item) => item.numero_jour === "all");
      if (all) {
        setAfficherBoutonChoisir(true);
      } else {
        setAfficherBoutonChoisir(false);
      }
    }
  }, [value]);

  useEffect(() => {
    //NES est ce que je suis en affichage par jour?
    let indexAll = horaires.findIndex((item) => item.numero_jour === "all");
    if (indexAll === -1) {
      let tempHoraires = [...horaires];
      //NES je suis en affichage par semaine dans ce cas je vérifie si je dois ajouter des jours
      joursSelectionnes.forEach((numero_jour) => {
        let indexHoraire = tempHoraires.findIndex(
          (item) => item.numero_jour === numero_jour
        );
        if (indexHoraire === -1) {
          let nouvelHoraire = {
            numero_jour: numero_jour,
            jour_nom: numeroJourToNomJour(numero_jour),
            matin_debut: "08:00",
            matin_fin: "12:00",
            apresmidi_debut: "14:00",
            apresmidi_fin: "18:00",
          };
          tempHoraires.push(nouvelHoraire);
          //NES on trie les jours
          tempHoraires.sort((a, b) => {
            return a.numero_jour - b.numero_jour;
          });
          setHoraires(tempHoraires);
        }
      });
      //NES je vérifie si je dois supprimer des jours
      tempHoraires.forEach((horaire) => {
        let indexJourSelectionne = joursSelectionnes.findIndex(
          (numero_jour) => numero_jour === horaire.numero_jour
        );
        if (indexJourSelectionne === -1) {
          tempHoraires = tempHoraires.filter(
            (item) => item.numero_jour !== horaire.numero_jour
          );
          setHoraires(tempHoraires);
        }
      });
      //NES je retourne le nouvel horaire par jour
      console.log({ tempHoraires });
      //NES retour de l'horaire modifié
      if (onChange) {
        onChange(tempHoraires);
      }
    }
    // eslint-disable-next-line
  }, [joursSelectionnes]);

  return (
    <div className={MonStyle.HorairesSemaine}>
      {!afficherBoutonChoisir && (
        <BoutonTexte
          texte="Mêmes horaires tous les jours"
          fullWidth
          style={{ marginBottom: "10px" }}
          onClick={() => handleClickHorairesSemaine()}
        />
      )}
      {horaires.map((jour) => (
        <div className={MonStyle.LigneJour} key={jour.numero_jour}>
          {jour.jour_nom && (
            <div className={MonStyle.NomJour}>{jour.jour_nom}</div>
          )}
          <HorairesJour
            matinDebut={jour.matin_debut}
            onMatinDebutChange={(event) =>
              handleHorairesSemaineChange(
                jour.numero_jour,
                "matin_debut",
                event.target.value
              )
            }
            matinFin={jour.matin_fin}
            onMatinFinChange={(event) =>
              handleHorairesSemaineChange(
                jour.numero_jour,
                "matin_fin",
                event.target.value
              )
            }
            apresmidiDebut={jour.apresmidi_debut}
            onApresmidiDebutChange={(event) =>
              handleHorairesSemaineChange(
                jour.numero_jour,
                "apresmidi_debut",
                event.target.value
              )
            }
            apresmidiFin={jour.apresmidi_fin}
            onApresmidiFinChange={(event) =>
              handleHorairesSemaineChange(
                jour.numero_jour,
                "apresmidi_fin",
                event.target.value
              )
            }
          />
        </div>
      ))}
      {afficherBoutonChoisir && (
        <BoutonAjoutBloc
          taille="L"
          texte="Choisir par jour"
          fullWidth
          style={{ marginTop: "10px" }}
          onClick={() => handleClickHorairesJours(joursSelectionnes)}
        />
      )}
    </div>
  );
}
