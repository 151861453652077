import React, { useContext, useEffect, useState } from "react";
import MonStyle from "./Agenda.module.css";
import ColonneHeures from "./ColonneHeures";
import ColonneJour from "./ColonneJour";
import { RdvContext } from "../../../../Data/RdvContext";
import { AgendasContext } from "../../../../Data/AgendasContext";
import { timeToMinutes } from "../../../../functions/FonctionsDateHeure";

export default function Agenda({ agenda, heureDebutAgenda, heureFinAgenda }) {
  //NES gestion du contexte
  const {
    hauteurMiniHeure,
    datesSemaine,
    selectedDate,
    getListeCasesHoraires,
  } = useContext(AgendasContext);
  const { evenements } = useContext(RdvContext);

  //NES gestion du state
  const [joursAffiches, setJoursAffiches] = useState([]);
  const [cases, setCases] = useState([]);
  const [hauteurCase, setHauteurCase] = useState(20);
  const [hauteurHeure, setHauteurHeure] = useState(20);

  //NES Quand la liste des jours change
  useEffect(() => {
    if (datesSemaine && selectedDate) {
      setJoursAffiches(datesSemaine);
    }
  }, [datesSemaine, selectedDate]);

  useEffect(() => {
    let cases = getListeCasesHoraires(agenda._id);

    console.log(cases);

    setCases(cases);
    let hauteurFenetre = window.innerHeight;
    let hauteurEntete = document.getElementById("EnteteApp").offsetHeight;
    let hauteurEnteteAgenda =
      document.getElementById("Agenda-Entete").offsetHeight;
    let nbCases = cases.length;
    let hauteurCaseCalcule =
      (hauteurFenetre - hauteurEntete - hauteurEnteteAgenda - 80) / nbCases;

    hauteurCaseCalcule = Math.round(hauteurCaseCalcule, 0);
    let hauteurMiniInteger = parseInt(hauteurMiniHeure, 10);
    hauteurCaseCalcule =
      hauteurCaseCalcule > hauteurMiniInteger
        ? hauteurCaseCalcule
        : hauteurMiniInteger;

    setHauteurCase(hauteurCaseCalcule);

    //NES calcul de la hauteur d'une heure
    let dureeCase = 0;
    let typesRdv = agenda.types_rendez_vous;
    let typeConsultation = typesRdv[0];
    let dureeCaseParametre = parseInt(agenda.intervalle_minutes, 10);

    if (dureeCaseParametre) {
      dureeCase = dureeCaseParametre;
    } else {
      if (typeConsultation) {
        dureeCase = timeToMinutes(typeConsultation.duree);
      }
    }

    let hauteurHeureCalcule = (60 * hauteurCaseCalcule) / dureeCase;

    console.log({
      typeConsultation,
      dureeCase,
      dureeCaseParametre,
      hauteurHeureCalcule,
    });

    setHauteurHeure(hauteurHeureCalcule);
    // eslint-disable-next-line
  }, [agenda, evenements, hauteurMiniHeure]);

  return (
    <div className={MonStyle.Agenda}>
      <div className={MonStyle.HeuresEtJours}>
        <div className={MonStyle.ColonneHeures}>
          <ColonneHeures
            cases={cases}
            heureDebutAgenda={heureDebutAgenda}
            heureFinAgenda={heureFinAgenda}
            hauteurCase={hauteurCase}
          />
        </div>

        <div className={MonStyle.ListeColonnes}>
          {joursAffiches.map((jour) => (
            <div
              className={
                jour.toLocaleDateString("fr-FR") ===
                selectedDate.toLocaleDateString("fr-FR")
                  ? MonStyle.ColonneJourSelectionne
                  : MonStyle.Colonne
              }
              key={jour}
            >
              <ColonneJour
                cases={cases}
                agenda={agenda}
                date={jour}
                hauteurCase={hauteurCase}
                hauteurHeure={hauteurHeure}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
