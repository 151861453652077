import React, { useContext, useCallback } from "react";
import BoutonRond from "../../Design/Boutons/BoutonRond";
import Style from "./EnteteApp.module.css";
import Logo from "../../Design/Logo";
import SettingsIcon from "@material-ui/icons/Settings";
import { AuthentificationContext } from "../../Data/AuthentificationContext";
import { Link } from "react-router-dom";
import MenuMobile from "../Menu/MenuMobile";
import InputRechercheEntete from "../../Design/Recherche/InputRechercheEntete";
import { useHistory } from "react-router-dom";
import AffichageCabinet from "./Components/ChoixCabinet/AffichageCabinet";
import Deconnexion from "./Components/Deconnexion";
import UtilisateurConnecte from "./Components/UtilisateurConnecte/UtilisateurConnecte";
import ClearCache from "../ClearCache/ClearCache";
import InfoAdpx from "./Components/InfoAdpx/InfoAdpx";
import IndicateurPerformance from "./Components/IndicateurPerformance/IndicateurPerformance";

export default function EnteteApp() {
  //NES gestion du contexte
  const authContext = useContext(AuthentificationContext);

  //NES gestion du routage
  const history = useHistory();
  const redirectPageRecherche = useCallback(
    (texteRecherche) =>
      history.push(`/patientsrecents?texteRecherche=${texteRecherche}`),
    [history]
  );

  //NES gestion de la recherche
  const handleRecherche = (event) => {
    //NES redirection sur la page de recherche
    redirectPageRecherche(event.target.value);
  };

  return (
    <div className={Style.EnteteApp} id="EnteteApp">
      <MenuMobile />

      <div className={Style.Logo}>
        <Link to="/">
          <Logo taille="M" />
        </Link>
      </div>

      <div className={Style.BoutonsEntete}>
        <AffichageCabinet />
        <InfoAdpx />

        <div className="HideMobile">
          <InputRechercheEntete
            onRecherche={handleRecherche}
            isDeletedAfterSearch={true}
          />
        </div>

        {authContext.payload.is_admin && (
          <div className="HideMobile">
            <BoutonRond
              iconeComposant={<SettingsIcon />}
              tooltip="Options et paramètres"
              link="/options"
              couleurIcone="blanc"
              id="bouton_options"
            />
          </div>
        )}
        <div
          className="HideMobile"
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <Deconnexion />
          <ClearCache />
        </div>
        <IndicateurPerformance />
        <UtilisateurConnecte />
      </div>
    </div>
  );
}
