import React, { useContext, useEffect, useState } from "react";
import MonStyle from "./PageConstantes.module.css";
import { ConstantesContext } from "../../../../Data/ConstantesContext";
import AffichageAncienneMesure from "../../Components/AffichageMesure/AffichageAncienneMesure";
import { Route, Switch } from "react-router-dom";
import PageGraphiqueConstante from "./PageGraphiqueConstante";
import MessageAucuneDonnee from "../../../../Design/Messages/MessageAucuneDonnee";

export default function PageConstantes({ match }) {
  //NES récupération de l'ID dans l'URL
  let patient_id = match.params.patient_id;
  let mesure_id = match.params.mesure_id;

  //NES gestion du contexte
  const { getMesuresPatient } = useContext(ConstantesContext);

  //NES gestion du state
  const [mesureSelected, setMesureSelected] = useState();
  const [mesures, setMesures] = useState([]);
  const [message, setMessage] = useState("");

  //GET des mesures patients
  useEffect(() => {
    getMesuresPatient(patient_id);
    // eslint-disable-next-line
  }, [patient_id]);

  useEffect(() => {
    getMesuresPatient(patient_id).then((reponse) => {
      if (reponse.code === "OK") {
        setMesures(reponse.data);

        if (reponse.data.length === 0) {
          setMessage("Aucune constante");
        }
      }
    });
    // eslint-disable-next-line
  }, [patient_id]);

  useEffect(() => {
    setMesureSelected(mesure_id);
  }, [mesure_id]);

  return (
    <div className={MonStyle.PageConstantes}>
      <MessageAucuneDonnee
        message={message}
        conseil="Les constantes saisies dans les consultations apparaitront dans cette page."
        hideIllustration
      />{" "}
      <div className={MonStyle.BlocTitre}>
        <span className="TitreMobile">Historique des constantes</span>
      </div>
      <div className={MonStyle.Wrapper}>
        <div className={MonStyle.ListeMesures}>
          {mesures.map((dataMesure) => (
            <AffichageAncienneMesure
              key={dataMesure.mesure_id}
              mesure={dataMesure}
              selected={mesureSelected === dataMesure.mesure_id}
              onClick={() => setMesureSelected(dataMesure.mesure_id)}
              to={
                "/patients/" +
                patient_id +
                "/constantes/" +
                dataMesure.mesure_id
              }
            />
          ))}
        </div>
        <div className={MonStyle.Graphique}>
          <Switch>
            <Route
              path="/patients/:patient_id/constantes/:mesure_id"
              exact
              component={PageGraphiqueConstante}
            />
          </Switch>
        </div>
      </div>
    </div>
  );
}
