import React from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import PeopleAltIcon from "@material-ui/icons/PeopleAlt";
import IconesProduits from "../../../../Design/Icones/IconesProduits";
import { IconeMenuConsultation } from "../../../../Design/Icones/IconesMenu";
import ApartmentIcon from "@material-ui/icons/Apartment";
import { Link } from "react-router-dom";
import IconesMesures from "../../../../Design/Icones/IconesMesures";

export default function PageMenuOptions() {
  //NES récupération de l'URL
  var adresseActuelle = window.location.pathname;

  return (
    <div>
      <List component="nav">
        <ListItem
          button
          component={Link}
          selected={adresseActuelle === "/options/utilisateurs"}
          to="/options/utilisateurs"
        >
          <PeopleAltIcon className="IconeMenu" />
          <ListItemText primary="Utilisateurs" />
        </ListItem>
        <ListItem
          button
          component={Link}
          selected={adresseActuelle === "/options/typesconsultations"}
          to="/options/typesconsultations"
        >
          <IconeMenuConsultation className="IconeMenu" />
          <ListItemText primary="Types de consultations" />
        </ListItem>
        <ListItem
          button
          component={Link}
          selected={adresseActuelle === "/options/cabinets"}
          to="/options/cabinets"
        >
          <ApartmentIcon className="IconeMenu" />
          <ListItemText primary="Cabinets" />
        </ListItem>
        <ListItem
          button
          component={Link}
          selected={adresseActuelle === "/options/laboratoires"}
          to="/options/laboratoires"
        >
          <IconesProduits className="IconeMenu" />
          <ListItemText primary="Laboratoires" />
        </ListItem>
        <ListItem
          button
          component={Link}
          selected={adresseActuelle === "/options/constantes"}
          to="/options/constantes"
        >
          <IconesMesures className="IconeMenu" />
          <ListItemText primary="Mesures & constantes" />
        </ListItem>
      </List>
    </div>
  );
}
