import React, { useContext } from "react";
import MonStyle from "./AffichageAncienneMesure.module.css";
import IconesPerso from "../../../../Design/Icones/IconesMesures";
import ObjetItem from "../../../../Design/Objets/ObjetItem";
import {
  DateOuHeureToString,
  DateShortOuHeureToString,
} from "../../../../functions/FonctionsDateHeure";
import { Link } from "react-router-dom";
import BoutonRond from "../../../../Design/Boutons/BoutonRond";
import TurnedInNotIcon from "@material-ui/icons/TurnedInNot";
import { ConstantesContext } from "../../../../Data/ConstantesContext";
import TurnedInIcon from "@material-ui/icons/TurnedIn";
import SurfaceBloc from "../../../../Design/Surfaces/SurfaceBloc";
export default function AffichageAncienneMesure({
  mesure,
  selected,
  onClick,
  to,
  taille,
  style,
}) {
  //NES gestion du contexte
  const { bookmarkMesuresPatient, getColorMesure } =
    useContext(ConstantesContext);

  //NES je trie les mesures par date
  let toutesMesures = mesure.mesures;
  let mesuresTries = toutesMesures.sort((a, b) => a.date - b.date);
  //NES je filtre pour éviter les mesures vides
  mesuresTries = mesuresTries.filter((item) => item.valeur !== "");
  let derniereMesure = {};
  if (mesuresTries.length > 0) {
    derniereMesure = mesuresTries[mesuresTries.length - 1];
  }

  //NES gestion propre de l'affichage
  let valeur = derniereMesure.valeur ? derniereMesure.valeur : "Aucune valeur";
  let unite = derniereMesure.unite ? derniereMesure.unite : mesure.unite;
  //NES ne pas afficher d'unite si je n'ai pas de valeur
  unite = derniereMesure.valeur ? unite : "";
  let icone = mesure.icone_mesure ? mesure.icone_mesure : "";

  //NES on ajoute aux mesures à suivre
  const handleBookmark = () => {
    let valeur = mesure.is_bookmark ? false : true;
    console.log(valeur);
    bookmarkMesuresPatient(mesure.patient_id, mesure.mesure_id, valeur);
  };

  switch (taille) {
    case "M":
      return (
        <div className={MonStyle.AffichageAncienneMesureM} style={style}>
          <SurfaceBloc theme="rond" isBouton>
            <Link
              className={MonStyle.Content}
              to={
                "/patients/" +
                mesure.patient_id +
                "/constantes/" +
                mesure.mesure_id
              }
            >
              <div className={MonStyle.Icone}>
                <IconesPerso
                  nomIcone={icone}
                  style={{
                    color: getColorMesure(mesure),
                    width: "30px",
                    height: "30px",
                  }}
                />
              </div>
              <div>
                <div>
                  <span className={MonStyle.Valeur}>{valeur}</span>
                  <span className={MonStyle.Unite}>{unite}</span>
                </div>
                <div className={MonStyle.Date}>
                  {DateShortOuHeureToString(derniereMesure.date)}
                </div>
              </div>
            </Link>
          </SurfaceBloc>
        </div>
      );

    default:
      return (
        <div
          className={
            selected
              ? MonStyle.AffichageAncienneMesureSelected
              : MonStyle.AffichageAncienneMesure
          }
        >
          <Link onClick={onClick} to={to} className={MonStyle.Link}>
            <div
              style={{
                minWidth: "60px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <IconesPerso
                nomIcone={icone}
                style={{ color: selected ? "white" : "#205a71" }}
              />
            </div>
            {!selected && (
              <div className={MonStyle.Label}>{mesure.libelle}</div>
            )}
            <div style={{ width: "100%" }}>
              <ObjetItem
                principale={valeur + " " + unite}
                isBold
                secondaire={DateOuHeureToString(derniereMesure.date)}
                couleurTexte={selected ? "white" : ""}
              />
            </div>
          </Link>

          <div style={{ marginRight: "10px" }}>
            <BoutonRond
              iconeComposant={
                mesure.is_bookmark ? (
                  <TurnedInIcon style={{ color: "#0FA600" }} />
                ) : (
                  <TurnedInNotIcon
                    style={{ color: selected ? "white" : "#DDD" }}
                  />
                )
              }
              tooltip="Suivre la valeur pour ce patient"
              onClick={handleBookmark}
            />
          </div>
        </div>
      );
  }
}
