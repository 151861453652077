import React from "react";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";

const useStyles = makeStyles({
  root: {
    color: (props) => props.color,
    border: (props) => `1px solid ${props.color}`,
    padding: "0 20px",
    fontFamily: "Lato",
    fontWeight: "700",
    textTransform: "none",
    borderRadius: "4px",
    minHeight: (props) => props.height,
    "&:hover": {
      backgroundColor: (props) => props.backgroundOver,
    },
  },
  disabled: {
    backgroundColor: "red",
  },
});

export default function BoutonActionSecondaire({
  texte,
  couleur,
  taille,
  onClick,
  href,
  fullWidth,
  link,
  disabled,
  id,
  style,
  ...props
}) {
  //NES gestion des taille S,M,L
  let height = "";
  switch (taille) {
    case "S":
      height = "30px";
      break;
    case "M":
      height = "40px";
      break;
    case "L":
      height = "50px";
      break;
    default:
      height = "40px";
  }

  //NES Customisation des couleurs
  let color = "";
  let backgroundOver = "";
  switch (couleur) {
    case "blanc":
      color = "white";
      backgroundOver = "#FFFFFF15";
      break;
    case "rouge":
      color = "#F44336";
      backgroundOver = "#F4433615";
      break;
    case "bleu":
      color = "#208AB0";
      backgroundOver = "#208AB015";
      break;
    case "gris":
      color = "#97989D";
      backgroundOver = "#97989D15";
      break;
    case "rose":
      color = "#D2215F";
      backgroundOver = "#D2215F15";
      break;
    default:
      color = "#0FA600";
      backgroundOver = "#0FA60015";
      if (process.env.REACT_APP_NOM === "SINOBI") {
        color = "#D2215F";
        backgroundOver = "#D2215F15";
      }
  }
  //NES création du style
  let theme = {
    color: color,
    height: height,
    backgroundOver: backgroundOver,
  };
  const classes = useStyles(theme);

  return (
    <Button
      className={classes.root}
      variant="outlined"
      size="large"
      onClick={onClick}
      href={href}
      fullWidth={fullWidth}
      style={style}
      disabled={disabled}
      name={texte}
      component={link ? Link : Button}
      to={link}
      id={id ? id : texte}
      {...props}
    >
      {texte}
    </Button>
  );
}
