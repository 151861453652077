import React, { createContext, useContext, useState } from "react";
import { AuthentificationContext } from "./AuthentificationContext";
import { PerformancesContext } from "./PerformancesContext";

//NES Création du contexte
export const CabinetsContext = createContext();

export const CabinetsProvider = (props) => {
  //NES gestion du contexte
  const authContext = useContext(AuthentificationContext);
  const { GET } = useContext(PerformancesContext);

  //NES gestion du state
  const [cabinets, setCabinets] = useState([]);
  const [cabinetConnexion, setCabinetConnexion] = useState();

  //GET cabinets/
  const getCabinets = async () => {
    if (cabinets.length > 0) {
      let reponse = { code: "OK", data: cabinets };
      return reponse;
    } else {
      //NES j'appelle l'API Médicale
      let MonUrl = `${process.env.REACT_APP_URL_API_UTILISATEURS}/cabinets`;
      let reponseApi = await GET(MonUrl, authContext.token);
      //NES je garde la réponse dans le contexte
      if (reponseApi.code === "OK") {
        setCabinets(reponseApi.data);
      }
      return reponseApi;
    }
  };

  //POST cabinets/
  const postCabinets = async (data) => {
    //NES j'appelle l'API Utlisateurs
    const MonUrl = `${process.env.REACT_APP_URL_API_UTILISATEURS}/cabinets/`;
    //NES pour détecter les appels en boucle
    console.log({ POST: MonUrl, data });
    const reponseApi = await fetch(MonUrl, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        token: authContext.token,
      },
      body: JSON.stringify(data),
    });
    //NES je convertis ce que je récupère en JSON
    const reponseJson = await reponseApi.json();
    //NES mise à jour du contexte
    if (reponseJson.code === "OK") {
      let tempArray = [...cabinets];
      tempArray.push(reponseJson.data);
      setCabinets(tempArray);
    }
    //NES je retourne à ma fonction appelante le résultat
    return reponseJson;
  };

  //NES Récupération du cabinet avec son id
  const getCabinetById = async (cabinet_id) => {
    let listeCabinet = await getCabinets();
    let data = listeCabinet.data.find((item) => item._id === cabinet_id);
    return data;
  };

  //PATCH Cabinets/
  const patchCabinets = async (cabinet_id, data) => {
    //NES j'appelle l'API Utlisateurs
    const MonUrl = `${process.env.REACT_APP_URL_API_UTILISATEURS}/cabinets/${cabinet_id}`;
    //NES pour détecter les appels en boucle
    console.log({ PATCH: MonUrl, data });
    const reponseApi = await fetch(MonUrl, {
      method: "PATCH",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        token: authContext.token,
      },
      body: JSON.stringify(data),
    });
    //NES je convertis ce que je récupère en JSON
    const reponseJson = await reponseApi.json();
    //NES mise à jour du contexte
    if (reponseJson.code === "OK") {
      let tempArray = [...cabinets];
      let index = tempArray.findIndex((item) => item._id === data._id);
      tempArray.splice(index, 1, reponseJson.data);
      setCabinets(tempArray);
      //NES Si je viens de modifier mon cabinet de connexion (cas normal)

      console.log(reponseJson.data._id, cabinetConnexion._id);

      if (reponseJson.data._id === cabinetConnexion._id) {
        setCabinetConnexion(reponseJson.data);
      }
    }
    //NES je retourne à ma fonction appelante le résultat
    return reponseJson;
  };

  //DELETE cabinet
  const deleteCabinets = async (cabinet_id) => {
    //NES j'appelle l'API Médicale
    const MonUrl = `${process.env.REACT_APP_URL_API_UTILISATEURS}/cabinets/${cabinet_id}`;
    //NES pour détecter les appels en boucle
    console.log({ DELETE: MonUrl });
    const reponseApi = await fetch(MonUrl, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        token: authContext.token,
      },
    });
    //NES je convertis ce que je récupère en JSON
    const reponseJson = await reponseApi.json();
    //NES Mise à jour du contexte
    if (reponseJson.code === "OK") {
      let tempArray = [...cabinets];
      let index = tempArray.findIndex((item) => item._id === cabinet_id);
      tempArray.splice(index, 1);
      setCabinets(tempArray);
    }
    //NES je retourne à ma fonction appelante le résultat
    return reponseJson;
  };

  //NES récupération du cabinet par utilisateur
  const getCabinetByUtilisateur = async () => {
    let utilisateur_id = authContext.payload.utilisateur_id;
    let reponseListeCabinets = await getCabinets();
    let listeCabinets = reponseListeCabinets.data;
    let listeCabinetUtilisateur = listeCabinets?.filter((item) =>
      item.utilisateurs.includes(utilisateur_id)
    );
    return listeCabinetUtilisateur;
  };

  //NES Récupératation de l'objet du cabinet de connexion pour le stockage API
  const getObjetCabinet = () => {
    let objetCabinet = {};
    if (cabinetConnexion) {
      objetCabinet = {
        cabinet_id: cabinetConnexion._id,
        nom: cabinetConnexion.nom,
        telephone: cabinetConnexion.telephone,
        fax: cabinetConnexion.nfaxom,
        email: cabinetConnexion.email,
        adresse: cabinetConnexion.adresse,
        texte_horaire: cabinetConnexion.texte_horaire,
        texte_rdv: cabinetConnexion.texte_rdv,
        texte_libre: cabinetConnexion.texte_libre,
        numero_finess: cabinetConnexion.numero_finess,
        informations_juridiques: cabinetConnexion.informations_juridiques,
      };
    }
    return objetCabinet;
  };

  ///Controle des informations du cabinet
  const controleInformationsCabinet = (cabinet) => {
    let retour = [];
    if (cabinet) {
      //NES je controle l'adresse
      if (!cabinet.adresse) {
        retour.push("L'adresse du cabinet");
      }

      //NES je controle le nom de la société
      if (!cabinet?.informations_juridiques?.nom_entite_juridique) {
        retour.push("Le nom de l'entité juridique du cabinet");
      }

      //NES je controle le nnumero_siret  de la société
      if (!cabinet?.informations_juridiques?.numero_siret) {
        retour.push("Le numero siret du cabinet");
      }

      //NES je controle le capital social  de la société
      if (!cabinet?.informations_juridiques?.capital_social) {
        retour.push("Le capital social du cabinet");
      }

      //NES je controle le capital social  de la société
      if (!cabinet?.informations_juridiques?.adresse_siege) {
        retour.push("L'adresse du siège social");
      }
    }
    return retour;
  };

  return (
    <CabinetsContext.Provider
      value={{
        cabinets,
        cabinetConnexion,
        getCabinets,
        postCabinets,
        getCabinetById,
        patchCabinets,
        deleteCabinets,
        getCabinetByUtilisateur,
        setCabinetConnexion,
        getObjetCabinet,
        controleInformationsCabinet,
      }}
    >
      {props.children}
    </CabinetsContext.Provider>
  );
};
