import React, { useState, createContext, useContext } from "react";
import { AuthentificationContext } from "./AuthentificationContext";
import { PerformancesContext } from "./PerformancesContext";

//NES Création du contexte
export const DocumentsContext = createContext();

export const DocumentsProvider = (props) => {
  //NES gestion du contexte
  const authContext = useContext(AuthentificationContext);
  const { GET } = useContext(PerformancesContext);

  //NES gestion du state
  const [documents, setDocuments] = useState([]);
  // eslint-disable-next-line
  const [typesDocuments, setTypesDocuments] = useState([
    "biologie",
    "courrier",
    "ordonnance",
    "radio",
    "questionnaire",
    "compte rendu hospitalier",
    "autre",
    "photo",
  ]);

  //GET documents/
  const getDocumentsPatient = async (patient_id) => {
    //NES Est-ce que j'ai déjà des documents dans le contexte?
    let tempArray = [...documents];
    let listeDocuments = tempArray.filter(
      (item) => item.patient_id === patient_id
    );
    if (listeDocuments.length > 0) {
      //NES je retourne le contenu du contexte
      let reponse = { code: "OK", data: listeDocuments };
      return reponse;
    } else {
      setDocuments([]);
      //NES j'appelle l'API
      const MonUrl = `${process.env.REACT_APP_URL_API_DOCUMENTS}/patients/${patient_id}/documents`;

      const reponse = await GET(MonUrl, authContext.token);

      if (reponse.code === "OK") {
        //NES Je stocke les données dans le contexte
        setDocuments(reponse.data);
      }
      return reponse;
    }
  };

  const ajouterDocumentsUploadContext = async (
    fichiers,
    patient_id,
    consultation_id
  ) => {
    if (fichiers && patient_id) {
      console.log("ajouterDocumentsUploadContext");

      //NES j'ajoute les fichers en cours de chargment dans le contexte car ca peut prendre du temps
      let tempArray = [...documents];
      fichiers.forEach((fichier) => {
        let id_temporaire = "upload" + fichier.lastModified;
        //NES construction de l'objet temporaire
        let uploadDocument = {
          _id: id_temporaire,
          patient_id: patient_id,
          consultation_id: consultation_id,
          nom: fichier.name,
          tracabilite_creation: { date_tracabilite: new Date() },
          taille_Mo: Math.round(fichier.size / 1024 / 1024),
          is_upload: true,
          upload_fichier: fichier,
        };
        tempArray.push(uploadDocument);
      });

      setDocuments(tempArray);
    } else {
      return {};
    }
  };

  const terminerDocumentsUploadContext = (fichier_id, data) => {
    if (fichier_id && data) {
      console.log("terminerDocumentsUploadContext", fichier_id, data);
      setDocuments((current) => {
        let tempArray = [...current];
        let index = tempArray.findIndex((item) => item._id === fichier_id);
        if (index > -1) {
          tempArray.splice(index, 1, data);
        }
        return tempArray;
      });
    }
  };

  return (
    <DocumentsContext.Provider
      value={{
        documents,
        setDocuments,
        getDocumentsPatient,
        typesDocuments,
        ajouterDocumentsUploadContext,
        terminerDocumentsUploadContext,
      }}
    >
      {props.children}
    </DocumentsContext.Provider>
  );
};
