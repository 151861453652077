import React, { useContext, useState } from "react";
import MonStyle from "./PageCarteBancaire.module.css";
import { UtilisateursContext } from "../../../Data/UtilisateursContext";
import { useEffect } from "react";
import BoutonActionPrincipale from "../../../Design/Boutons/BoutonActionPrincipale";
import CarteBancaire from "../Components/Cartebancaire/CarteBancaire";
import StripeSecret from "../Components/Stripe/StripeSecret";
import AffichageConditionnel from "../../../Design/DataDisplay/AffichageConditionnel";
import CheckoutForm from "../Components/Stripe/CheckoutForm";
import AffichageProblemePaiement from "../../Home/Components/AffichageProblemePaiement";
import MessageSecuritePaiement from "../Components/MessageSecuritePaiement/MessageSecuritePaiement";
import LoaderHorizontal from "../../../Design/Progression/LoaderHorizontal";

export default function PageCarteBancaire() {
  //NES récupération des paramètres dans l'url
  const url_payment_intent = new URLSearchParams(window.location.search).get(
    "payment_intent"
  );

  //NES Gestion du contexte
  const {
    monOrganisation,
    postCreateCustomer,
    getPaymentmethod,
    paymentMethod,
  } = useContext(UtilisateursContext);

  //NES Gestion du state
  const [isLoading, setIsLoading] = useState(true);

  //USEEFFECT
  useEffect(() => {
    getPaymentmethod().then((reponse) => {
      console.log(reponse);
      setIsLoading(false);
    });

    // eslint-disable-next-line
  }, []);

  //USEEFFECT
  useEffect(() => {
    console.log(monOrganisation);
    console.log(monOrganisation?.stripe?.setup_intent_client_secret);
  }, [monOrganisation]);

  //USEEFFECT
  useEffect(() => {
    console.log({ paymentMethod });
  }, [paymentMethod]);

  ///HandleCreateCustomer
  const handleCreateCustomer = () => {
    postCreateCustomer();
  };

  //NES le loader est hyper important car l'appel appelle Stripe en background
  if (isLoading) {
    return (
      <div className={MonStyle.Loader}>
        <LoaderHorizontal isLoading={isLoading} />
      </div>
    );
  }

  return (
    <div className={MonStyle.PageCarteBancaire}>
      <div className={MonStyle.PartieGauche}>
        <AffichageConditionnel
          open={
            monOrganisation?.facturation?.reglement?.etat_reglement ===
              "ERREUR_PAIEMENT" && !url_payment_intent
          }
        >
          <AffichageProblemePaiement disableButton={true} />
        </AffichageConditionnel>
        <AffichageConditionnel open={paymentMethod}>
          <CarteBancaire />
        </AffichageConditionnel>
        <AffichageConditionnel open={!monOrganisation?.stripe}>
          <BoutonActionPrincipale
            texte="Ajouter une carte bancaire"
            onClick={handleCreateCustomer}
          />
        </AffichageConditionnel>
        <AffichageConditionnel
          open={
            monOrganisation?.stripe?.setup_intent_client_secret &&
            paymentMethod === undefined
          }
        >
          <StripeSecret
            secret={monOrganisation?.stripe?.setup_intent_client_secret}
          >
            <CheckoutForm />
          </StripeSecret>
        </AffichageConditionnel>
      </div>

      <MessageSecuritePaiement />
    </div>
  );
}
