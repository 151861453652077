import React, { useContext, useEffect, useState } from "react";
import { SupportContext } from "../../../../Data/SupportContext";
import EtatDemande from "./EtatDemande";
import Popover from "@mui/material/Popover";
import ObjetItem from "../../../../Design/Objets/ObjetItem";
import ListItem from "@material-ui/core/ListItem";
import { AuthentificationContext } from "../../../../Data/AuthentificationContext";

export default function SelectEtatDemande({ demande, style }) {
  //NES gestion du contexte
  const { ETAT_DEMANDE, patchDemandes } = useContext(SupportContext);
  const auth = useContext(AuthentificationContext);

  //NES gestion du state
  const [etat, setEtat] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);

  //USEEFFECT
  useEffect(() => {
    if (demande) {
      setEtat(demande.etat);
    }
  }, [demande]);

  const handleOpenMenu = (event) => {
    if (auth.payload.code_role === "ADPX") {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  ///PATCH mise à jour de l'état
  const handleEtatChange = (etat) => {
    console.log(etat);
    let newData = { ...demande, etat: etat.code };
    patchDemandes(newData);
  };

  return (
    <div style={style}>
      <EtatDemande etat={etat} onClick={handleOpenMenu} />
      <Popover
        open={anchorEl ? true : false}
        anchorEl={anchorEl}
        onClose={handleClose}
        onClick={handleClose}
        anchorOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
      >
        <div>
          {ETAT_DEMANDE.map((item) => (
            <ListItem
              button
              //disableGutters
              divider
              key={item.code}
              onClick={() => handleEtatChange(item)}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <EtatDemande etat={item.code} />
                <ObjetItem
                  principale={item.description}
                  secondaire={"Code : " + item.code}
                />
              </div>
            </ListItem>
          ))}
        </div>
      </Popover>
    </div>
  );
}
