import React, { useState, createContext, useContext } from "react";
import { AuthentificationContext } from "./AuthentificationContext";
import { GET, POST, PATCH, DELETE } from "../functions/FonctionsApi";

export const CommentairesPatientsContext = createContext();

export const CommentairesPatientsProvider = (props) => {
  //NES gestion du contexte
  const authContext = useContext(AuthentificationContext);

  //NES gestion du state
  const [commentairesPatients, setCommentairesPatients] = useState([]);

  //NES je sépare l'initisalisation car si je n'ai pas de commentaire je ne sais pas si j'ai déjà fait l'appel
  //NES et du coup j'appelle l'API en boucle
  //GET récupération des commentaires dans l'API
  const getCommentairesContext = async (patient_id, forceAppelApi) => {
    if (patient_id) {
      //NES je regarde si j'ai des commentaires patients
      let listeCommentaires = commentairesPatients.filter(
        (item) => item.patient_id === patient_id
      );
      if (listeCommentaires.length === 0 || forceAppelApi) {
        //NES j'appelle l'API patient pour récupérer les commentaires.
        const MonUrl = `${process.env.REACT_APP_URL_API_PATIENTS}/patients/${patient_id}/commentaires/`;

        const reponseApi = await GET(MonUrl, authContext.token);

        //NES je stocke mes demandes dans le contexte
        if (reponseApi.code === "OK") {
          //NES je stocke les commentaires dans le contexte
          let tempArray = [...commentairesPatients];
          tempArray = tempArray.concat(reponseApi.data);
          setCommentairesPatients(tempArray);
          //NES je retourne à ma fonction appelante le résultat
          return reponseApi;
        }
      } else {
        //NES j'ai déjà l'information pas la peine de refaire un appel
        return { code: "OK", data: listeCommentaires };
      }
    }
  };

  //POST Ajouter un commentaire
  const postCommentairesContext = async (patient_id, commentaire) => {
    if (patient_id && commentaire) {
      const MonUrl = `${process.env.REACT_APP_URL_API_PATIENTS}/patients/${patient_id}/commentaires/`;
      const reponseApi = await POST(MonUrl, authContext.token, commentaire);
      if (reponseApi.code === "OK") {
        //NES mise à jour du contexte
        let tempArray = [...commentairesPatients, reponseApi.data];
        setCommentairesPatients(tempArray);
      }
      //NES je retourne à ma fonction appelante le résultat
      return reponseApi;
    } else {
      return {};
    }
  };

  //DELETE Supprimer un commentaire
  const deleteCommentairesContext = async (patient_id, commentaire_id) => {
    if (patient_id && commentaire_id) {
      const MonUrl = `${process.env.REACT_APP_URL_API_PATIENTS}/patients/${patient_id}/commentaires/${commentaire_id}`;
      const reponseApi = await DELETE(MonUrl, authContext.token);

      if (reponseApi.code === "OK") {
        //NES mise à jour du contexte
        let tempArray = [...commentairesPatients];
        let index = tempArray.findIndex((item) => item._id === commentaire_id);
        if (index > -1) {
          //NES je supprime dans le state
          tempArray.splice(index, 1);
          setCommentairesPatients(tempArray);
        }
      }
      return reponseApi;
    } else {
      return {};
    }
  };

  //PATCH Mise à jour du commentaire
  const patchCommentairesContext = async (patient_id, data) => {
    if (data._id && patient_id) {
      const MonUrl = `${process.env.REACT_APP_URL_API_PATIENTS}/patients/${patient_id}/commentaires/${data._id}`;
      const reponseApi = await PATCH(MonUrl, authContext.token, data);
      if (reponseApi.code === "OK") {
        //NES mise à jour du contexte
        let tempArray = [...commentairesPatients];
        let index = tempArray.findIndex((item) => item._id === data._id);
        if (index > -1) {
          //NES je remplace dans le state
          tempArray.splice(index, 1, reponseApi.data);
          setCommentairesPatients(tempArray);
        }
      }
      //NES je retourne à ma fonction appelante le résultat
      return reponseApi;
    } else {
      return {};
    }
  };

  return (
    <CommentairesPatientsContext.Provider
      value={{
        commentairesPatients,
        getCommentairesContext,
        postCommentairesContext,
        patchCommentairesContext,
        deleteCommentairesContext,
      }}
    >
      {props.children}
    </CommentairesPatientsContext.Provider>
  );
};
