import React from "react";
import MonStyle from "./AppNonDisponible.module.css";
import ZoneConnexion from "./ZoneConnexion";
import SurfacePage from "../../../Design/Surfaces/SurfacePage";
import MessageErreur from "../../../Design/Messages/MessageErreur";

export default function AppNonDisponible({ error }) {
  /*
  useEffect(() => {
    setInterval(() => {
      console.log("timer");
      //NES Je retente le hello sur l'API

    }, 5000);
  }, []);
  */

  return (
    <div className={MonStyle.AppNonDisponible}>
      <ZoneConnexion></ZoneConnexion>
      <SurfacePage>
        <MessageErreur message="Erreur" taille="XL" error={error} />
      </SurfacePage>

      {/* 
      <div className={MonStyle.Titre}>
        <span className={MonStyle.Nom}>
          {capitalize(process.env.REACT_APP_NOM) + " "}
        </span>
      </div>
      <div className={MonStyle.Titre2}>Non disponible</div>
      <div className={MonStyle.Description}>
        Nous travaillons actuellement à rétablir l'application.
        <br /> Nous nous excusons du dérangement que cela vous occasionne.
      </div>
      <div className={MonStyle.Delai}>
        <div>Dans combien de temps cela sera réglé?</div>
        <div className={MonStyle.Titre2}>30min</div>
      </div>
      <div className={MonStyle.Titre2}>Quel est le problème ?</div>
      Il semble que l'environnement sécurisé se soit mis en protection. Les
      équipes technique sont en cours d'investigation.
    */}
    </div>
  );
}
