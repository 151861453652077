import React from "react";

export function IconeMedecin({ taille, ...props }) {
  return (
    <svg
      width={taille ? taille : "40px"}
      height="40px"
      viewBox="0 0 40 40"
      version="1.1"
    >
      <g
        id="Icones"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="IconeMedecin" fillRule="nonzero">
          <g
            id="5929214_avatar_doctor_health_hospital_man_icon"
            transform="translate(6.000000, 0.000000)"
          >
            <path
              d="M8.38709677,6.8516129 L8.38709677,10.3225806 L7.74193548,10.3225806 C7.03019234,10.3247063 6.45373858,10.9011601 6.4516129,11.6129032 L6.4516129,8.38709677 C6.92112029,7.6861917 7.59776934,7.14938346 8.38709677,6.8516129 L8.38709677,6.8516129 Z"
              id="Path"
              fill="#40240F"
            ></path>
            <path
              d="M21.9354839,8.38709677 L21.9354839,11.6129032 C21.9333582,10.9011601 21.3569044,10.3247063 20.6451613,10.3225806 L20,10.3225806 L20,6.8516129 C20.7893274,7.14938346 21.4659765,7.6861917 21.9354839,8.38709677 L21.9354839,8.38709677 Z"
              id="Path"
              fill="#40240F"
            ></path>
            <polygon
              id="Path"
              fill="#13969D"
              points="27.8645161 32.8709677 28.3870968 34.8387097 26.4516129 34.8387097 22.5806452 34.8387097 22.5806452 32.9032258 27.7419355 32.9032258"
            ></polygon>
            <polygon
              id="Path"
              fill="#F2C4B2"
              points="26.4516129 34.8387097 26.4516129 40 22.5806452 40 22.5806452 36.7741935 22.5806452 34.8387097"
            ></polygon>
            <path
              d="M25.8064516,25.1612903 L19.2645161,23.2903226 L14.1935484,27.0967742 L9.12258065,23.2903226 L2.58064516,25.1612903 L0.516129032,32.9032258 L5.80645161,32.9032258 L5.80645161,40 L22.5806452,40 L22.5806452,32.9032258 L27.7419355,32.9032258 L27.8645161,32.8709677 L25.8064516,25.1612903 Z M19.3548387,38.0645161 L17.4193548,38.0645161 C16.7076117,38.0623905 16.1311579,37.4859367 16.1290323,36.7741935 L16.1290323,32.9032258 L20.6451613,32.9032258 L20.6451613,36.7741935 C20.6430356,37.4859367 20.0665818,38.0623905 19.3548387,38.0645161 L19.3548387,38.0645161 Z"
              id="Shape"
              fill="#34C1AD"
            ></path>
            <path
              d="M21.9354864,11.6129032 L21.9354864,12.5096774 C21.9365403,13.1583988 21.6117775,13.7642996 21.0709677,14.1225806 L20,14.8387097 C18.1756367,14.0085076 16.1978219,13.5689933 14.1935484,13.5483871 C12.1892748,13.5689933 10.2114601,14.0085076 8.38709677,14.8387097 L7.31612903,14.1225806 C6.77531929,13.7642996 6.45055645,13.1583988 6.45161033,12.5096774 L6.45161033,11.6129032 C6.45373858,10.9011601 7.03019234,10.3247063 7.74193548,10.3225806 L8.38709677,10.3225806 L8.38709677,6.8516129 C10.2221071,6.08025313 12.2047403,5.72337915 14.1935484,5.80645161 C16.1823565,5.72337915 18.1649897,6.08025313 20,6.8516129 L20,10.3225806 L20.6451613,10.3225806 C21.3569044,10.3247063 21.9333582,10.9011601 21.9354864,11.6129032 Z"
              id="Path"
              fill="#F2C4B2"
            ></path>
            <path
              d="M20,6.8516129 C18.1649897,6.08025313 16.1823565,5.72337915 14.1935484,5.80645161 C12.2047403,5.72337915 10.2221071,6.08025313 8.38709677,6.8516129 C7.59776934,7.14938346 6.92112029,7.6861917 6.4516129,8.38709677 L6.4516129,5.16129032 C6.4516129,2.31078839 8.76240129,0 11.6129032,0 L16.7741935,0 C19.6246955,0 21.9354839,2.31078839 21.9354839,5.16129032 L21.9354839,8.38709677 C21.4659765,7.6861917 20.7893274,7.14938346 20,6.8516129 L20,6.8516129 Z"
              id="Path"
              fill="#34C1AD"
            ></path>
            <path
              d="M8.38709677,8.14193548 L8.38709677,6.8516129 C7.59776934,7.14938346 6.92112029,7.6861917 6.4516129,8.38709677 L6.4516129,9.67741935 C6.92112029,8.97651428 7.59776934,8.43970604 8.38709677,8.14193548 Z"
              id="Path"
              fill="#241408"
            ></path>
            <path
              d="M20,6.8516129 L20,8.14193548 C20.7893274,8.43970604 21.4659765,8.97651428 21.9354839,9.67741935 L21.9354839,8.38709677 C21.4659765,7.6861917 20.7893274,7.14938346 20,6.8516129 L20,6.8516129 Z"
              id="Path"
              fill="#241408"
            ></path>
            <path
              d="M14.1935484,7.09677419 C16.1823565,7.01370173 18.1649897,7.37057571 20,8.14193548 L20,6.8516129 C18.1649897,6.08025313 16.1823565,5.72337915 14.1935484,5.80645161 C12.2047403,5.72337915 10.2221071,6.08025313 8.38709677,6.8516129 L8.38709677,8.14193548 C10.2221071,7.37057571 12.2047403,7.01370173 14.1935484,7.09677419 L14.1935484,7.09677419 Z"
              id="Path"
              fill="#F2A283"
            ></path>
            <path
              d="M20.6451613,32.9032258 L20.6451613,36.7741935 C20.6430356,37.4859367 20.0665818,38.0623905 19.3548387,38.0645161 L17.4193548,38.0645161 C16.7076117,38.0623905 16.1311579,37.4859367 16.1290323,36.7741935 L16.1290323,32.9032258 L20.6451613,32.9032258 Z"
              id="Path"
              fill="#13969D"
            ></path>
            <polygon
              id="Path"
              fill="#13969D"
              points="19.2645161 23.2903226 14.1935484 27.0967742 9.12258065 23.2903226 11.6129032 22.5806452 14.1935484 24.516129 16.7741935 22.5806452"
            ></polygon>
            <path
              d="M16.7741935,18.7612903 C15.1447665,19.5526547 13.2423303,19.5526547 11.6129032,18.7612903 C9.99521026,18.0147592 8.80718134,16.5701161 8.38709677,14.8387097 C10.2114601,14.0085076 12.1892748,13.5689933 14.1935484,13.5483871 C16.1978219,13.5689933 18.1756367,14.0085076 20,14.8387097 C19.5799154,16.5701161 18.3918865,18.0147592 16.7741935,18.7612903 L16.7741935,18.7612903 Z"
              id="Path"
              fill="#13969D"
            ></path>
            <path
              d="M16.7741935,18.7612903 L16.7741935,22.5806452 L14.1935484,24.516129 L11.6129032,22.5806452 L11.6129032,18.7612903 C13.2423303,19.5526547 15.1447665,19.5526547 16.7741935,18.7612903 L16.7741935,18.7612903 Z"
              id="Path"
              fill="#F2B59D"
            ></path>
            <rect
              id="Rectangle"
              fill="#F2C4B2"
              x="1.93548387"
              y="34.8387097"
              width="3.87096774"
              height="5.16129032"
            ></rect>
            <polygon
              id="Path"
              fill="#13969D"
              points="5.80645161 32.9032258 5.80645161 34.8387097 1.93548387 34.8387097 0 34.8387097 0.516129032 32.9032258"
            ></polygon>
            <path
              d="M26.4516129,34.8387097 L22.5806452,34.8387097 L22.5806452,40 L24.516129,40 L24.516129,37.4193548 C24.516129,36.7067294 25.0938261,36.1290323 25.8064516,36.1290323 L26.4516129,36.1290323 L26.4516129,34.8387097 Z"
              id="Path"
              fill="#F2A283"
            ></path>
            <path
              d="M1.93548387,34.8387097 L5.80645161,34.8387097 L5.80645161,40 L3.87096774,40 L3.87096774,37.4193548 C3.87096774,36.7067294 3.29327064,36.1290323 2.58064516,36.1290323 L1.93548387,36.1290323 L1.93548387,34.8387097 Z"
              id="Path"
              fill="#F2A283"
            ></path>
            <path
              d="M16.7741935,18.7612903 C15.1447665,19.5526547 13.2423303,19.5526547 11.6129032,18.7612903 L11.6129032,22.5806452 L12.0683871,22.9219355 L16.7741935,19.7851613 L16.7741935,18.7612903 Z"
              id="Path"
              fill="#F2A283"
            ></path>
            <path
              d="M20,10.9677419 L20,13.5483871 L20.5767742,13.26 C21.0140522,13.0414915 21.290306,12.5946398 21.2903226,12.1058065 L21.2903226,11.6129032 C21.2903226,11.2565905 21.001474,10.9677419 20.6451613,10.9677419 L20,10.9677419 Z"
              id="Path"
              fill="#D9896D"
            ></path>
            <path
              d="M8.38709677,10.9677419 L8.38709677,13.5483871 L7.81032258,13.26 C7.37304455,13.0414915 7.09679079,12.5946398 7.09677419,12.1058065 L7.09677419,11.6129032 C7.09677419,11.2565905 7.38562274,10.9677419 7.74193548,10.9677419 L8.38709677,10.9677419 Z"
              id="Path"
              fill="#D9896D"
            ></path>
            <ellipse
              id="Oval"
              fill="#D9896D"
              cx="14.1935484"
              cy="12.9032258"
              rx="1"
              ry="1"
            ></ellipse>
            <path
              d="M15.7419355,8.37870968 C15.7330588,8.38146777 15.7240059,8.38362321 15.7148387,8.38516129 C15.5858065,8.4083871 15.4870968,8.20322581 15.4870968,8.06258065 C15.4870968,8.02322581 15.5019355,7.09483871 16.7774194,7.09483871 C18.0529032,7.09483871 18.0677419,8.02322581 18.0677419,8.06258065 C18.0715352,8.17766957 18.0169278,8.28688451 17.9225806,8.35290323 C17.8290323,8.41032258 17.7374194,8.35677419 17.6645161,8.26903226 C17.6290323,8.22516129 17.6,8.17096774 17.5632258,8.12967742 C17.5254518,8.09236353 17.4818751,8.06142409 17.4341935,8.03806452 C17.3211712,7.97192737 17.2016101,7.91766003 17.0774194,7.87612903 C16.7427206,7.78027834 16.3822546,7.85960027 16.1187097,8.08709677 C16.0111734,8.20562489 15.883642,8.30433067 15.7419355,8.37870968 L15.7419355,8.37870968 Z"
              id="Path"
              fill="#43251A"
            ></path>
            <path
              d="M12.6483871,8.37870968 C12.6572638,8.38146777 12.6663167,8.38362321 12.6754839,8.38516129 C12.8045161,8.4083871 12.9032258,8.20322581 12.9032258,8.06258065 C12.9032258,8.02322581 12.8883871,7.09483871 11.6129032,7.09483871 C10.3374194,7.09483871 10.3225806,8.02322581 10.3225806,8.06258065 C10.3187873,8.17766957 10.3733948,8.28688451 10.4677419,8.35290323 C10.5612903,8.41032258 10.6529032,8.35677419 10.7258065,8.26903226 C10.7612903,8.22516129 10.7903226,8.17096774 10.8270968,8.12967742 C10.8648708,8.09236353 10.9084475,8.06142409 10.956129,8.03806452 C11.0691513,7.97192737 11.1887125,7.91766003 11.3129032,7.87612903 C11.6476019,7.78027834 12.008068,7.85960027 12.2716129,8.08709677 C12.3791492,8.20562489 12.5066806,8.30433067 12.6483871,8.37870968 Z"
              id="Path"
              fill="#43251A"
            ></path>
            <ellipse
              id="Oval"
              fill="#43251A"
              cx="17.0967742"
              cy="11.2903226"
              rx="1"
              ry="1.29032258"
            ></ellipse>
            <ellipse
              id="Oval"
              fill="#43251A"
              cx="11.2903226"
              cy="11.2903226"
              rx="1"
              ry="1.29032258"
            ></ellipse>
            <path
              d="M18.7096774,3.87096774 L11.6129032,3.87096774 C10.543965,3.87096773 9.67741938,3.00442209 9.67741938,1.93548387 C9.67741938,0.866545657 10.543965,0 11.6129032,0 C8.76240129,0 6.4516129,2.31078839 6.4516129,5.16129032 L6.4516129,8.38709677 C6.92112029,7.6861917 7.59776934,7.14938346 8.38709677,6.8516129 C10.2221071,6.08025313 12.2047403,5.72337915 14.1935484,5.80645161 C16.1823565,5.72337915 18.1649897,6.08025313 20,6.8516129 C20.7893274,7.14938346 21.4659765,7.6861917 21.9354839,8.38709677 L21.9354839,7.09677419 C21.9354839,5.31521048 20.4912411,3.87096774 18.7096774,3.87096774 Z"
              id="Path"
              fill="#21ABAB"
            ></path>
            <path
              d="M20,5.56129032 C18.1649897,4.78993055 16.1823565,4.43305657 14.1935484,4.51612903 C12.2047403,4.43305657 10.2221071,4.78993055 8.38709677,5.56129032 C7.59776934,5.85906088 6.92112029,6.39586912 6.4516129,7.09677419 L6.4516129,8.38709677 C6.92112029,7.6861917 7.59776934,7.14938346 8.38709677,6.8516129 C10.2221071,6.08025313 12.2047403,5.72337915 14.1935484,5.80645161 C16.1823565,5.72337915 18.1649897,6.08025313 20,6.8516129 C20.7893274,7.14938346 21.4659765,7.6861917 21.9354839,8.38709677 L21.9354839,7.09677419 C21.4659765,6.39586912 20.7893274,5.85906088 20,5.56129032 L20,5.56129032 Z"
              id="Path"
              fill="#13969D"
            ></path>
            <polygon
              id="Path"
              fill="#107C82"
              points="23.8709677 34.1935484 23.8709677 32.9032258 22.5806452 32.9032258 22.5806452 34.8387097 26.4516129 34.8387097 28.3870968 34.8387097 28.2154839 34.1935484"
            ></polygon>
            <polygon
              id="Path"
              fill="#107C82"
              points="4.51612903 34.1935484 0.172258065 34.1935484 0 34.8387097 1.93548387 34.8387097 5.80645161 34.8387097 5.80645161 32.9032258 4.51612903 32.9032258"
            ></polygon>
            <path
              d="M22.5806452,32.9032258 L22.5806452,28.3870968 L22.5806452,28.3870968 C23.2932706,28.3870968 23.8709677,28.9647939 23.8709677,29.6774194 L23.8709677,32.9032258 L22.5806452,32.9032258 Z"
              id="Path"
              fill="#21ABAB"
            ></path>
            <path
              d="M5.80645161,32.9032258 L5.80645161,28.3870968 L5.80645161,28.3870968 C5.09382613,28.3870968 4.51612903,28.9647939 4.51612903,29.6774194 L4.51612903,32.9032258 L5.80645161,32.9032258 Z"
              id="Path"
              fill="#21ABAB"
            ></path>
            <path
              d="M12.3316129,23.3916129 L12.5251613,23.2625806 L11.6129032,22.5806452 L9.12258065,23.2903226 L14.1935484,27.0967742 L14.8722581,26.5870968 L12.2767742,24.423871 C12.1204379,24.2936025 12.0347259,24.0972101 12.0455215,23.894 C12.056317,23.6907898 12.1623545,23.5045841 12.3316129,23.3916129 L12.3316129,23.3916129 Z"
              id="Path"
              fill="#107C82"
            ></path>
            <path
              d="M19.3548387,38.0645161 L17.4193548,38.0645161 C16.7288877,38.0623347 16.1624994,37.516987 16.1341935,36.8270968 C10.0645161,35.6619355 5.80645161,32.9032258 5.80645161,29.6774194 L5.80645161,40 L22.5806452,40 L22.5806452,37.4193548 C21.8748387,37.4193548 21.1812903,37.3967742 20.4980645,37.3580645 C20.2793718,37.7890732 19.8381473,38.061726 19.3548387,38.0645161 Z"
              id="Path"
              fill="#21ABAB"
            ></path>
            <rect
              id="Rectangle"
              fill="#107C82"
              x="16.1290323"
              y="32.9032258"
              width="4.51612903"
              height="1.29032258"
            ></rect>
            <path
              d="M10.3664516,14.1103226 C9.68776438,14.2982906 9.02573553,14.541912 8.38709677,14.8387097 C8.80718134,16.5701161 9.99521026,18.0147592 11.6129032,18.7612903 C13.2423303,19.5526547 15.1447665,19.5526547 16.7741935,18.7612903 C16.8187097,18.74 16.8606452,18.7129032 16.9032258,18.6909677 C13.48,18.5122581 10.7322581,16.5748387 10.3664516,14.1103226 Z"
              id="Path"
              fill="#107C82"
            ></path>
            <path
              d="M8.06451613,23.2258065 L8.06451613,35.896129 L6.34967742,36.4677419 C6.21777667,36.5116736 6.12885979,36.6351692 6.12903201,36.7741935 L6.12903201,40 L6.77419355,40 L6.77419355,37.0064516 L8.38709677,36.4690323 L10,37.0064516 L10,40 L10.6451613,40 L10.6451613,36.7741935 C10.6453338,36.6351692 10.5564169,36.5116736 10.4245161,36.4677419 L8.70967742,35.8967742 L8.70967742,23.2258065 C8.70967742,22.3350246 9.43179879,21.6129032 10.3225806,21.6129032 L11.6129032,21.6129032 L11.6129032,20.9677419 L10.3225806,20.9677419 C9.07548605,20.9677419 8.06451613,21.9787119 8.06451613,23.2258065 L8.06451613,23.2258065 Z"
              id="Path"
              fill="#E3E3E3"
            ></path>
            <path
              d="M18.0645161,20.9677419 L16.7741935,20.9677419 L16.7741935,21.6129032 L18.0645161,21.6129032 C18.955298,21.6129032 19.6774194,22.3350246 19.6774194,23.2258065 L19.6774194,32.9032258 L20.3225806,32.9032258 L20.3225806,23.2258065 C20.3225806,21.9787119 19.3116107,20.9677419 18.0645161,20.9677419 L18.0645161,20.9677419 Z"
              id="Path"
              fill="#E3E3E3"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
}

export function IconeInfirmiere(props) {
  return (
    <svg width="40px" height="40px" viewBox="0 0 40 40" version="1.1">
      <g
        id="Icones"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Group" transform="translate(0.000000, -0.000000)">
          <g id="Group-4" transform="translate(7.000000, 0.000000)">
            <g
              id="5929214_avatar_doctor_health_hospital_man_icon"
              transform="translate(0.000000, 4.057971)"
              fillRule="nonzero"
            >
              <path
                d="M7.53623188,6.15652174 L7.53623188,9.27536232 L6.95652174,9.27536232 C6.31698443,9.27727235 5.79901148,9.79524529 5.79710145,10.4347826 L5.79710145,7.53623188 C6.21897765,6.90643312 6.82698114,6.42408369 7.53623188,6.15652174 L7.53623188,6.15652174 Z"
                id="Path"
                fill="#241408"
              ></path>
              <path
                d="M19.7101449,7.53623188 L19.7101449,10.4347826 C19.7082349,9.79524529 19.190262,9.27727235 18.5507246,9.27536232 L17.9710145,9.27536232 L17.9710145,6.15652174 C18.6802652,6.42408369 19.2882687,6.90643312 19.7101449,7.53623188 L19.7101449,7.53623188 Z"
                id="Path"
                fill="#241408"
              ></path>
              <polygon
                id="Path"
                fill="#FF509E"
                points="25.0376812 29.5362319 25.5072464 31.3043478 23.7681159 31.3043478 20.2898551 31.3043478 20.2898551 29.5652174 24.9275362 29.5652174"
              ></polygon>
              <polygon
                id="Path"
                fill="#F2C4B2"
                points="23.7681159 31.3043478 23.7681159 35.942029 20.2898551 35.942029 20.2898551 33.0434783 20.2898551 31.3043478"
              ></polygon>
              <path
                d="M23.1884058,22.6086957 L17.3101449,20.9275362 L12.7536232,24.3478261 L8.19710145,20.9275362 L2.31884058,22.6086957 L0.463768116,29.5652174 L5.2173913,29.5652174 L5.2173913,35.942029 L20.2898551,35.942029 L20.2898551,29.5652174 L24.9275362,29.5652174 L25.0376812,29.5362319 L23.1884058,22.6086957 Z M17.3913043,34.2028986 L15.6521739,34.2028986 C15.0126366,34.2009885 14.4946637,33.6830156 14.4927536,33.0434783 L14.4927536,29.5652174 L18.5507246,29.5652174 L18.5507246,33.0434783 C18.5488146,33.6830156 18.0308417,34.2009885 17.3913043,34.2028986 L17.3913043,34.2028986 Z"
                id="Shape"
                fill="#F3D8D8"
              ></path>
              <path
                d="M19.7101472,10.4347826 L19.7101472,11.2405797 C19.7110942,11.8234887 19.4192783,12.3679214 18.9333333,12.6898551 L17.9710145,13.3333333 C16.3317315,12.5873547 14.5545646,12.1924287 12.7536232,12.173913 C10.9526817,12.1924287 9.17551489,12.5873547 7.53623188,13.3333333 L6.57391304,12.6898551 C6.08796806,12.3679214 5.79615218,11.8234887 5.79709914,11.2405797 L5.79709914,10.4347826 C5.79901148,9.79524529 6.31698443,9.27727235 6.95652174,9.27536232 L7.53623188,9.27536232 L7.53623188,6.15652174 C9.18508171,5.46341585 10.9665782,5.14274648 12.7536232,5.2173913 C14.5406681,5.14274648 16.3221647,5.46341585 17.9710145,6.15652174 L17.9710145,9.27536232 L18.5507246,9.27536232 C19.190262,9.27727235 19.7082349,9.79524529 19.7101472,10.4347826 Z"
                id="Path"
                fill="#F2C4B2"
              ></path>
              <path
                d="M17.9710145,6.15652174 C16.3221647,5.46341585 14.5406681,5.14274648 12.7536232,5.2173913 C10.9665782,5.14274648 9.18508171,5.46341585 7.53623188,6.15652174 C6.82698114,6.42408369 6.21897765,6.90643312 5.79710145,7.53623188 L5.79710145,4.63768116 C5.79710145,2.07636058 7.87346203,0 10.4347826,0 L15.0724638,0 C17.6337843,0 19.7101449,2.07636058 19.7101449,4.63768116 L19.7101449,7.53623188 C19.2882687,6.90643312 18.6802652,6.42408369 17.9710145,6.15652174 L17.9710145,6.15652174 Z"
                id="Path"
                fill="#F3D8D8"
              ></path>
              <path
                d="M7.53623188,7.31594203 L7.53623188,6.15652174 C6.82698114,6.42408369 6.21897765,6.90643312 5.79710145,7.53623188 L5.79710145,8.69565217 C6.21897765,8.06585341 6.82698114,7.58350398 7.53623188,7.31594203 Z"
                id="Path"
                fill="#241408"
              ></path>
              <path
                d="M17.9710145,6.15652174 L17.9710145,7.31594203 C18.6802652,7.58350398 19.2882687,8.06585341 19.7101449,8.69565217 L19.7101449,7.53623188 C19.2882687,6.90643312 18.6802652,6.42408369 17.9710145,6.15652174 L17.9710145,6.15652174 Z"
                id="Path"
                fill="#241408"
              ></path>
              <path
                d="M12.7536232,6.37681159 C14.5406681,6.30216677 16.3221647,6.62283614 17.9710145,7.31594203 L17.9710145,6.15652174 C16.3221647,5.46341585 14.5406681,5.14274648 12.7536232,5.2173913 C10.9665782,5.14274648 9.18508171,5.46341585 7.53623188,6.15652174 L7.53623188,7.31594203 C9.18508171,6.62283614 10.9665782,6.30216677 12.7536232,6.37681159 L12.7536232,6.37681159 Z"
                id="Path"
                fill="#F2A283"
              ></path>
              <path
                d="M18.5507246,29.5652174 L18.5507246,33.0434783 C18.5488146,33.6830156 18.0308417,34.2009885 17.3913043,34.2028986 L15.6521739,34.2028986 C15.0126366,34.2009885 14.4946637,33.6830156 14.4927536,33.0434783 L14.4927536,29.5652174 L18.5507246,29.5652174 Z"
                id="Path"
                fill="#FF509E"
              ></path>
              <polygon
                id="Path"
                fill="#FF509E"
                points="17.3101449 20.9275362 12.7536232 24.3478261 8.19710145 20.9275362 10.4347826 20.2898551 12.7536232 22.0289855 15.0724638 20.2898551"
              ></polygon>
              <path
                d="M15.0724638,16.857971 C13.6083409,17.5690521 11.8989055,17.5690521 10.4347826,16.857971 C8.98120343,16.187175 7.91369918,14.8890898 7.53623188,13.3333333 C9.17551489,12.5873547 10.9526817,12.1924287 12.7536232,12.173913 C14.5545646,12.1924287 16.3317315,12.5873547 17.9710145,13.3333333 C17.5935472,14.8890898 16.526043,16.187175 15.0724638,16.857971 L15.0724638,16.857971 Z"
                id="Path"
                fill="#FF509E"
              ></path>
              <path
                d="M15.0724638,16.857971 L15.0724638,20.2898551 L12.7536232,22.0289855 L10.4347826,20.2898551 L10.4347826,16.857971 C11.8989055,17.5690521 13.6083409,17.5690521 15.0724638,16.857971 L15.0724638,16.857971 Z"
                id="Path"
                fill="#F2B59D"
              ></path>
              <rect
                id="Rectangle"
                fill="#F2C4B2"
                x="1.73913043"
                y="31.3043478"
                width="3.47826087"
                height="4.63768116"
              ></rect>
              <polygon
                id="Path"
                fill="#FF509E"
                points="5.2173913 29.5652174 5.2173913 31.3043478 1.73913043 31.3043478 0 31.3043478 0.463768116 29.5652174"
              ></polygon>
              <path
                d="M23.7681159,31.3043478 L20.2898551,31.3043478 L20.2898551,35.942029 L22.0289855,35.942029 L22.0289855,33.6231884 C22.0289855,32.9828583 22.5480757,32.4637681 23.1884058,32.4637681 L23.7681159,32.4637681 L23.7681159,31.3043478 Z"
                id="Path"
                fill="#F2A283"
              ></path>
              <path
                d="M1.73913043,31.3043478 L5.2173913,31.3043478 L5.2173913,35.942029 L3.47826087,35.942029 L3.47826087,33.6231884 C3.47826087,32.9828583 2.95917072,32.4637681 2.31884058,32.4637681 L1.73913043,32.4637681 L1.73913043,31.3043478 Z"
                id="Path"
                fill="#F2A283"
              ></path>
              <path
                d="M15.0724638,16.857971 C13.6083409,17.5690521 11.8989055,17.5690521 10.4347826,16.857971 L10.4347826,20.2898551 L10.844058,20.5965217 L15.0724638,17.777971 L15.0724638,16.857971 Z"
                id="Path"
                fill="#F2A283"
              ></path>
              <path
                d="M17.9710145,9.85507246 L17.9710145,12.173913 L18.4892754,11.9147826 C18.8821919,11.7184416 19.1304199,11.3169227 19.1304348,10.8776812 L19.1304348,10.4347826 C19.1304348,10.1146175 18.8708897,9.85507246 18.5507246,9.85507246 L17.9710145,9.85507246 Z"
                id="Path"
                fill="#D9896D"
              ></path>
              <path
                d="M7.53623188,9.85507246 L7.53623188,12.173913 L7.01797101,11.9147826 C6.62505452,11.7184416 6.37682651,11.3169227 6.37681159,10.8776812 L6.37681159,10.4347826 C6.37681159,10.1146175 6.63635667,9.85507246 6.95652174,9.85507246 L7.53623188,9.85507246 Z"
                id="Path"
                fill="#D9896D"
              ></path>
              <ellipse
                id="Oval"
                fill="#D9896D"
                cx="12.7536232"
                cy="11.5942029"
                rx="1"
                ry="1"
              ></ellipse>
              <path
                d="M14.1449275,7.52869565 C14.1369514,7.53117394 14.1288169,7.53311071 14.1205797,7.53449275 C14.0046377,7.55536232 13.915942,7.37101449 13.915942,7.24463768 C13.915942,7.20927536 13.9292754,6.37507246 15.0753623,6.37507246 C16.2214493,6.37507246 16.2347826,7.20927536 16.2347826,7.24463768 C16.2381911,7.34805092 16.1891235,7.44618608 16.1043478,7.50550725 C16.0202899,7.55710145 15.937971,7.50898551 15.8724638,7.43014493 C15.8405797,7.39072464 15.8144928,7.34202899 15.7814493,7.30492754 C15.7475074,7.27139911 15.7083516,7.24359846 15.6655072,7.2226087 C15.563951,7.16318112 15.4565192,7.11441915 15.3449275,7.07710145 C15.0441838,6.99097474 14.7202867,7.06224952 14.4834783,7.26666667 C14.3868514,7.37317019 14.272258,7.46186234 14.1449275,7.52869565 L14.1449275,7.52869565 Z"
                id="Path"
                fill="#43251A"
              ></path>
              <path
                d="M11.3652174,7.52869565 C11.3731936,7.53117394 11.381328,7.53311071 11.3895652,7.53449275 C11.5055072,7.55536232 11.5942029,7.37101449 11.5942029,7.24463768 C11.5942029,7.20927536 11.5808696,6.37507246 10.4347826,6.37507246 C9.28869565,6.37507246 9.27536232,7.20927536 9.27536232,7.24463768 C9.27195385,7.34805092 9.32102143,7.44618608 9.4057971,7.50550725 C9.48985507,7.55710145 9.57217391,7.50898551 9.63768116,7.43014493 C9.66956522,7.39072464 9.69565217,7.34202899 9.72869565,7.30492754 C9.76263752,7.27139911 9.80179337,7.24359846 9.84463768,7.2226087 C9.94619397,7.16318112 10.0536257,7.11441915 10.1652174,7.07710145 C10.4659612,6.99097474 10.7898582,7.06224952 11.0266667,7.26666667 C11.1232935,7.37317019 11.2378869,7.46186234 11.3652174,7.52869565 Z"
                id="Path"
                fill="#43251A"
              ></path>
              <ellipse
                id="Oval"
                fill="#43251A"
                cx="15.3623188"
                cy="10.1449275"
                rx="1"
                ry="1.15942029"
              ></ellipse>
              <ellipse
                id="Oval"
                fill="#43251A"
                cx="10.1449275"
                cy="10.1449275"
                rx="1"
                ry="1.15942029"
              ></ellipse>
              <path
                d="M16.8115942,3.47826087 L10.4347826,3.47826087 C9.4742874,3.47826086 8.6956522,2.69962564 8.6956522,1.73913043 C8.6956522,0.778635228 9.4742874,0 10.4347826,0 C7.87346203,0 5.79710145,2.07636058 5.79710145,4.63768116 L5.79710145,7.53623188 C6.21897765,6.90643312 6.82698114,6.42408369 7.53623188,6.15652174 C9.18508171,5.46341585 10.9665782,5.14274648 12.7536232,5.2173913 C14.5406681,5.14274648 16.3221647,5.46341585 17.9710145,6.15652174 C18.6802652,6.42408369 19.2882687,6.90643312 19.7101449,7.53623188 L19.7101449,6.37681159 C19.7101449,4.77598623 18.4124196,3.47826087 16.8115942,3.47826087 Z"
                id="Path"
                fill="#D4AAAA"
              ></path>
              <path
                d="M17.9710145,4.99710145 C16.3221647,4.30399556 14.5406681,3.98332619 12.7536232,4.05797101 C10.9665782,3.98332619 9.18508171,4.30399556 7.53623188,4.99710145 C6.82698114,5.2646634 6.21897765,5.74701283 5.79710145,6.37681159 L5.79710145,7.53623188 C6.21897765,6.90643312 6.82698114,6.42408369 7.53623188,6.15652174 C9.18508171,5.46341585 10.9665782,5.14274648 12.7536232,5.2173913 C14.5406681,5.14274648 16.3221647,5.46341585 17.9710145,6.15652174 C18.6802652,6.42408369 19.2882687,6.90643312 19.7101449,7.53623188 L19.7101449,6.37681159 C19.2882687,5.74701283 18.6802652,5.2646634 17.9710145,4.99710145 L17.9710145,4.99710145 Z"
                id="Path"
                fill="#F3D8D8"
              ></path>
              <polygon
                id="Path"
                fill="#CE1F9F"
                points="21.4492754 30.7246377 21.4492754 29.5652174 20.2898551 29.5652174 20.2898551 31.3043478 23.7681159 31.3043478 25.5072464 31.3043478 25.3530435 30.7246377"
              ></polygon>
              <polygon
                id="Path"
                fill="#CE1F9F"
                points="4.05797101 30.7246377 0.154782609 30.7246377 0 31.3043478 1.73913043 31.3043478 5.2173913 31.3043478 5.2173913 29.5652174 4.05797101 29.5652174"
              ></polygon>
              <path
                d="M20.2898551,29.5652174 L20.2898551,25.5072464 L20.2898551,25.5072464 C20.9301852,25.5072464 21.4492754,26.0263365 21.4492754,26.6666667 L21.4492754,29.5652174 L20.2898551,29.5652174 Z"
                id="Path"
                fill="#FF509E"
              ></path>
              <path
                d="M5.2173913,29.5652174 L5.2173913,25.5072464 L5.2173913,25.5072464 C4.57706116,25.5072464 4.05797101,26.0263365 4.05797101,26.6666667 L4.05797101,29.5652174 L5.2173913,29.5652174 Z"
                id="Path"
                fill="#FF509E"
              ></path>
              <path
                d="M11.0805797,21.0185507 L11.2544928,20.9026087 L10.4347826,20.2898551 L8.19710145,20.9275362 L12.7536232,24.3478261 L13.3634783,23.8898551 L11.0313043,21.946087 C10.8908283,21.8290342 10.8138117,21.6525656 10.823512,21.469971 C10.8332124,21.2873764 10.9284925,21.1200611 11.0805797,21.0185507 L11.0805797,21.0185507 Z"
                id="Path"
                fill="#CE1F9F"
              ></path>
              <path
                d="M17.3913043,34.2028986 L15.6521739,34.2028986 C15.0317541,34.2009384 14.5228255,33.7109158 14.4973913,33.0910145 C9.04347826,32.044058 5.2173913,29.5652174 5.2173913,26.6666667 L5.2173913,35.942029 L20.2898551,35.942029 L20.2898551,33.6231884 C19.6556522,33.6231884 19.0324638,33.6028986 18.4185507,33.5681159 C18.2220442,33.9553991 17.8255816,34.2003915 17.3913043,34.2028986 Z"
                id="Path"
                fill="#FF509E"
              ></path>
              <rect
                id="Rectangle"
                fill="#CE1F9F"
                x="14.4927536"
                y="29.5652174"
                width="4.05797101"
                height="1.15942029"
              ></rect>
              <path
                d="M9.31478261,12.6788406 C8.7049477,12.8477393 8.1100812,13.0666456 7.53623188,13.3333333 C7.91369918,14.8890898 8.98120343,16.187175 10.4347826,16.857971 C11.8989055,17.5690521 13.6083409,17.5690521 15.0724638,16.857971 C15.1124638,16.8388406 15.1501449,16.8144928 15.1884058,16.7947826 C12.1124638,16.6342029 9.64347826,14.8933333 9.31478261,12.6788406 Z"
                id="Path"
                fill="#CE1F9F"
              ></path>
            </g>
            <path
              d="M12.4869423,3.37356936 C13.8003086,3.37356936 14.3821971,3.32476117 14.2326076,3.22714481 C15.1143333,0.653132574 16.4368515,-0.381080695 18.2001621,0.124505001 C20.8451282,0.882883545 21.9320875,2.48194798 21.5287855,5.05284922 C21.1254835,7.62375046 21.9315547,10.9139362 24.5465003,12.5993152 C24.1223152,13.2830966 21.1691829,11.5258669 20.3416373,10.6226894 C19.5140918,9.71951199 19.2309785,8.16210182 18.5547987,6.54810563 C17.838928,4.8393698 16.9880361,4.16078226 16.671533,4.39632736 C17.9143133,6.53660269 19.0460445,9.0424442 20.0667266,11.9138519 C18.7783845,10.8602619 17.7247394,10.172237 16.9057913,9.84977727 C16.0868433,9.52731753 14.7994056,9.33231575 13.0434783,9.26477193 L12.7507246,10.6226894 C11.5090995,10.1074147 10.4472638,9.84977727 9.56521739,9.84977727 C8.68317102,9.84977727 7.4271316,10.5378021 5.79709914,11.9138519 C5.6503491,9.59888199 5.94020495,7.75320189 6.66666667,6.37681159 C7.75635925,4.31222615 10.5168928,3.37356936 12.4869423,3.37356936 Z"
              id="Path-9"
              fill="#3D231A"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
}

export function IconePharmacie(props) {
  return (
    <svg width="40px" height="40px" viewBox="0 0 40 40" version="1.1">
      <g
        id="Icones"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="IconePharmacie"
          fill="#0FA600"
          fillRule="nonzero"
          stroke="#1C5D3B"
        >
          <g id="Group">
            <polygon
              id="Path"
              points="33 22.3412935 23.0512821 22.3412935 23.0512821 33 17.8974359 33 17.8974359 22.3412935 8 22.3412935 8 17.6069652 17.8974359 17.6069652 17.8974359 7 23.0512821 7 23.0512821 17.6069652 33 17.6069652"
            ></polygon>
          </g>
        </g>
      </g>
    </svg>
  );
}

export function IconeAutreContact(props) {
  return (
    <svg width="40px" height="40px" viewBox="0 0 40 40" version="1.1">
      <g
        id="Icones"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Group">
          <g
            id="5929214_avatar_doctor_health_hospital_man_icon"
            transform="translate(6.000000, 3.214708)"
            fillRule="nonzero"
          >
            <path
              d="M8.38709677,3.63690495 L8.38709677,7.1078727 L7.74193548,7.1078727 C7.03019234,7.10999837 6.45373858,7.68645214 6.4516129,8.39819528 L6.4516129,5.17238882 C6.92112029,4.47148375 7.59776934,3.93467551 8.38709677,3.63690495 L8.38709677,3.63690495 Z"
              id="Path"
              fill="#40240F"
            ></path>
            <path
              d="M21.9354839,5.17238882 L21.9354839,8.39819528 C21.9333582,7.68645214 21.3569044,7.10999837 20.6451613,7.1078727 L20,7.1078727 L20,3.63690495 C20.7893274,3.93467551 21.4659765,4.47148375 21.9354839,5.17238882 L21.9354839,5.17238882 Z"
              id="Path"
              fill="#40240F"
            ></path>
            <polygon
              id="Path"
              fill="#205A71"
              points="27.8645161 29.6562598 28.3870968 31.6240017 26.4516129 31.6240017 22.5806452 31.6240017 22.5806452 29.6885179 27.7419355 29.6885179"
            ></polygon>
            <polygon
              id="Path"
              fill="#F2C4B2"
              points="26.4516129 31.6240017 26.4516129 36.785292 22.5806452 36.785292 22.5806452 33.5594856 22.5806452 31.6240017"
            ></polygon>
            <polygon
              id="Shape"
              fill="#208AB0"
              points="25.8064516 21.9465824 19.2645161 20.0756146 14.1935484 23.8820662 9.12258065 20.0756146 2.58064516 21.9465824 0.516129032 29.6885179 5.80645161 29.6885179 5.80645161 36.785292 22.5806452 36.785292 22.5806452 29.6885179 27.7419355 29.6885179 27.8645161 29.6562598"
            ></polygon>
            <path
              d="M21.9354864,8.41390483 L21.9354864,9.30631176 C21.9365403,9.95187386 21.6117775,10.554824 21.0709677,10.9113602 L20,11.6240017 C18.1756367,10.7978427 16.1978219,10.3604688 14.1935484,10.339963 C12.1892748,10.3604688 10.2114601,10.7978427 8.38709677,11.6240017 L7.31612903,10.9113602 C6.77531929,10.554824 6.45055645,9.95187386 6.45161033,9.30631176 L6.45161033,8.41390483 C6.45373858,7.70562785 7.03019234,7.13198139 7.74193548,7.12986607 L8.38709677,7.12986607 L8.38709677,3.6758018 C10.2221071,2.90819852 12.2047403,2.5530625 14.1935484,2.6357304 C16.1823565,2.5530625 18.1649897,2.90819852 20,3.6758018 L20,7.12986607 L20.6451613,7.12986607 C21.3569044,7.13198139 21.9333582,7.70562785 21.9354864,8.41390483 Z"
              id="Path"
              fill="#F2C4B2"
            ></path>
            <path
              d="M8.38709677,4.92722753 L8.38709677,3.63690495 C7.59776934,3.93467551 6.92112029,4.47148375 6.4516129,5.17238882 L6.4516129,6.4627114 C6.92112029,5.76180633 7.59776934,5.22499809 8.38709677,4.92722753 Z"
              id="Path"
              fill="#241408"
            ></path>
            <path
              d="M20,3.63690495 L20,4.92722753 C20.7893274,5.22499809 21.4659765,5.76180633 21.9354839,6.4627114 L21.9354839,5.17238882 C21.4659765,4.47148375 20.7893274,3.93467551 20,3.63690495 L20,3.63690495 Z"
              id="Path"
              fill="#241408"
            ></path>
            <path
              d="M20,5.57995759 L20,3.38110938 C18.1649897,2.0666295 16.1823565,-0.135058141 14.1935484,0.00650625764 C12.2047403,-0.135058141 10.2221071,2.0666295 8.38709677,3.38110938 L8.38709677,5.57995759 C8.92289331,5.19614843 9.7933607,4.54846421 10.9984989,3.63690495 C11.8668175,4.11615467 12.5017264,4.4125857 12.9032258,4.52619804 C14.1992821,4.8929433 15.2587069,4.8234407 15.7674663,4.85965446 C17.1853387,4.75872956 18.3634732,3.85475152 19.2645161,4.19066028 C19.6273397,4.32592089 19.4732157,5.20260423 20,5.57995759 Z"
              id="Path"
              fill="#F2A283"
            ></path>
            <path
              d="M14.1935484,23.8820662 C12.5032258,23.8820662 10.8129032,22.613249 9.12258065,20.0756146 L11.6129032,19.3659372 C12.4731183,20.6562598 13.3333333,21.3014211 14.1935484,21.3014211 C15.0537634,21.3014211 15.9139785,20.6562598 16.7741935,19.3659372 L19.2645161,20.0756146 C17.5741935,22.613249 15.883871,23.8820662 14.1935484,23.8820662 Z"
              id="Path"
              fill="#205A71"
            ></path>
            <path
              d="M11.6129032,15.5267956 C9.99521026,14.7553768 8.80718134,13.2625725 8.38709677,11.4734448 C10.2114601,10.6155656 12.1892748,10.1613988 14.1935484,10.1401057 C16.1978219,10.1613988 18.1756367,10.6155656 20,11.4734448 C19.5799154,13.2625725 18.3918865,14.7553768 16.7741935,15.5267956 C15.1447665,16.3445424 13.2423303,16.3445424 11.6129032,15.5267956 Z"
              id="Path"
              fill="#F2C4B2"
            ></path>
            <path
              d="M16.7741935,19.3659372 C15.9139785,20.6562598 15.0537634,21.3014211 14.1935484,21.3014211 C13.3333333,21.3014211 12.4731183,20.6562598 11.6129032,19.3659372 L11.6129032,15.5465824 C13.2423303,16.3379468 15.1447665,16.3379468 16.7741935,15.5465824 L16.7741935,19.3659372 Z"
              id="Path"
              fill="#F2B59D"
            ></path>
            <rect
              id="Rectangle"
              fill="#F2C4B2"
              x="1.93548387"
              y="31.6240017"
              width="3.87096774"
              height="5.16129032"
            ></rect>
            <polygon
              id="Path"
              fill="#205A71"
              points="5.80645161 29.6885179 5.80645161 31.6240017 1.93548387 31.6240017 0 31.6240017 0.516129032 29.6885179"
            ></polygon>
            <path
              d="M26.4516129,31.6240017 L22.5806452,31.6240017 L22.5806452,36.785292 L24.516129,36.785292 L24.516129,34.2046469 C24.516129,33.4920214 25.0938261,32.9143243 25.8064516,32.9143243 L26.4516129,32.9143243 L26.4516129,31.6240017 Z"
              id="Path"
              fill="#F2A283"
            ></path>
            <path
              d="M1.93548387,31.6240017 L5.80645161,31.6240017 L5.80645161,36.785292 L3.87096774,36.785292 L3.87096774,34.2046469 C3.87096774,33.4920214 3.29327064,32.9143243 2.58064516,32.9143243 L1.93548387,32.9143243 L1.93548387,31.6240017 Z"
              id="Path"
              fill="#F2A283"
            ></path>
            <path
              d="M16.7741935,15.5465824 C15.1447665,16.3379468 13.2423303,16.3379468 11.6129032,15.5465824 L11.6129032,19.3659372 L12.0683871,19.7072275 L16.7741935,16.5704533 L16.7741935,15.5465824 Z"
              id="Path"
              fill="#F2A283"
            ></path>
            <path
              d="M20,7.75303399 L20,10.3336791 L20.5767742,10.045292 C21.0140522,9.82678354 21.290306,9.37993185 21.2903226,8.8910985 L21.2903226,8.39819528 C21.2903226,8.04188253 21.001474,7.75303399 20.6451613,7.75303399 L20,7.75303399 Z"
              id="Path"
              fill="#D9896D"
            ></path>
            <path
              d="M8.38709677,7.75303399 L8.38709677,10.3336791 L7.81032258,10.045292 C7.37304455,9.82678354 7.09679079,9.37993185 7.09677419,8.8910985 L7.09677419,8.39819528 C7.09677419,8.04188253 7.38562274,7.75303399 7.74193548,7.75303399 L8.38709677,7.75303399 Z"
              id="Path"
              fill="#D9896D"
            ></path>
            <ellipse
              id="Oval"
              fill="#D9896D"
              cx="14.1935484"
              cy="9.68851786"
              rx="1"
              ry="1"
            ></ellipse>
            <path
              d="M15.7419355,5.16400173 C15.7330588,5.16675982 15.7240059,5.16891526 15.7148387,5.17045334 C15.5858065,5.19367915 15.4870968,4.98851786 15.4870968,4.8478727 C15.4870968,4.80851786 15.5019355,3.88013076 16.7774194,3.88013076 C18.0529032,3.88013076 18.0677419,4.80851786 18.0677419,4.8478727 C18.0715352,4.96296162 18.0169278,5.07217656 17.9225806,5.13819528 C17.8290323,5.19561463 17.7374194,5.14206624 17.6645161,5.05432431 C17.6290323,5.01045334 17.6,4.95625979 17.5632258,4.91496947 C17.5254518,4.87765558 17.4818751,4.84671614 17.4341935,4.82335657 C17.3211712,4.75721942 17.2016101,4.70295208 17.0774194,4.66142108 C16.7427206,4.56557039 16.3822546,4.64489232 16.1187097,4.87238882 C16.0111734,4.99091694 15.883642,5.08962272 15.7419355,5.16400173 L15.7419355,5.16400173 Z"
              id="Path"
              fill="#43251A"
            ></path>
            <path
              d="M12.6483871,5.16400173 C12.6572638,5.16675982 12.6663167,5.16891526 12.6754839,5.17045334 C12.8045161,5.19367915 12.9032258,4.98851786 12.9032258,4.8478727 C12.9032258,4.80851786 12.8883871,3.88013076 11.6129032,3.88013076 C10.3374194,3.88013076 10.3225806,4.80851786 10.3225806,4.8478727 C10.3187873,4.96296162 10.3733948,5.07217656 10.4677419,5.13819528 C10.5612903,5.19561463 10.6529032,5.14206624 10.7258065,5.05432431 C10.7612903,5.01045334 10.7903226,4.95625979 10.8270968,4.91496947 C10.8648708,4.87765558 10.9084475,4.84671614 10.956129,4.82335657 C11.0691513,4.75721942 11.1887125,4.70295208 11.3129032,4.66142108 C11.6476019,4.56557039 12.008068,4.64489232 12.2716129,4.87238882 C12.3791492,4.99091694 12.5066806,5.08962272 12.6483871,5.16400173 Z"
              id="Path"
              fill="#43251A"
            ></path>
            <ellipse
              id="Oval"
              fill="#43251A"
              cx="17.0967742"
              cy="8.07561463"
              rx="1"
              ry="1.29032258"
            ></ellipse>
            <ellipse
              id="Oval"
              fill="#43251A"
              cx="11.2903226"
              cy="8.07561463"
              rx="1"
              ry="1.29032258"
            ></ellipse>
            <polygon
              id="Path"
              fill="#205A71"
              points="23.8709677 30.9788404 23.8709677 29.6885179 22.5806452 29.6885179 22.5806452 31.6240017 26.4516129 31.6240017 28.3870968 31.6240017 28.2154839 30.9788404"
            ></polygon>
            <polygon
              id="Path"
              fill="#205A71"
              points="4.51612903 30.9788404 0.172258065 30.9788404 0 31.6240017 1.93548387 31.6240017 5.80645161 31.6240017 5.80645161 29.6885179 4.51612903 29.6885179"
            ></polygon>
            <path
              d="M22.5806452,29.6885179 L22.5806452,25.1723888 L22.5806452,25.1723888 C23.2932706,25.1723888 23.8709677,25.7500859 23.8709677,26.4627114 L23.8709677,29.6885179 L22.5806452,29.6885179 Z"
              id="Path"
              fill="#205A71"
            ></path>
            <path
              d="M5.80645161,29.6885179 L5.80645161,25.1723888 L5.80645161,25.1723888 C5.09382613,25.1723888 4.51612903,25.7500859 4.51612903,26.4627114 L4.51612903,29.6885179 L5.80645161,29.6885179 Z"
              id="Path"
              fill="#205A71"
            ></path>
            <path
              d="M12.2767742,20.176905 L11.6129032,19.3659372 L9.12258065,20.0756146 L14.1935484,23.8820662 L14.8722581,23.3723888 L12.2767742,21.209163 C12.1204379,21.0788946 12.0347259,20.8825022 12.0455215,20.679292 C12.056317,20.4760819 12.1623545,20.2898762 12.3316129,20.176905 L12.2767742,20.176905 Z"
              id="Path"
              fill="#205A71"
            ></path>
            <path
              d="M8.38709677,11.6240017 C8.80718134,13.3554081 9.99521026,14.8000513 11.6129032,15.5465824 C13.2423303,16.3379468 15.1447665,16.3379468 16.7741935,15.5465824 C16.8187097,15.525292 16.8606452,15.4981953 16.9032258,15.4762598 C13.48,15.2975501 9.02573553,11.327204 8.38709677,11.6240017 Z"
              id="Path"
              fill="#F2C4B2"
            ></path>
            <path
              d="M5.80645161,26.4627114 L5.80645161,36.785292 L22.5806452,36.785292 L22.5806452,34.2046469 C21.8748387,34.2046469 5.80645161,29.6885179 5.80645161,26.4627114 Z"
              id="Path"
              fill="#205A71"
            ></path>
          </g>
          <path
            d="M20.6483871,17.3787097 C20.6572638,17.3814678 20.6663167,17.3836232 20.6754839,17.3851613 C20.8045161,17.4083871 20.9032258,17.2032258 20.9032258,17.0625806 C20.9032258,17.0232258 20.8883871,16.0948387 19.6129032,16.0948387 C18.3374194,16.0948387 18.3225806,17.0232258 18.3225806,17.0625806 C18.3187873,17.1776696 18.3733948,17.2868845 18.4677419,17.3529032 C18.5612903,17.4103226 18.6529032,17.3567742 18.7258065,17.2690323 C18.7612903,17.2251613 18.7903226,17.1709677 18.8270968,17.1296774 C18.8648708,17.0923635 18.9084475,17.0614241 18.956129,17.0380645 C19.0691513,16.9719274 19.1887125,16.91766 19.3129032,16.876129 C19.6476019,16.7802783 20.008068,16.8596003 20.2716129,17.0870968 C20.3791492,17.2056249 20.5066806,17.3043307 20.6483871,17.3787097 Z"
            id="Path"
            fill="#D9896D"
            fillRule="nonzero"
            transform="translate(19.612811, 16.740906) rotate(180.000000) translate(-19.612811, -16.740906) "
          ></path>
          <path
            d="M12.4516129,10.9677419 C13.4054672,8.73301552 14.8823024,7.09109444 16.8821185,6.0419787 L16.8821185,4.83612816 C17.451272,6.23558978 18.466358,7.09819339 19.9273764,7.42393901 C22.1189042,7.91255744 23.6046307,7.32403026 25.1333525,6.67580599 C26.7285461,7.71262232 27.6625908,9.25079451 27.9354864,11.2903226 C28.3279261,7.43400387 28.1429412,4.77558519 27.380532,3.31506654 C26.2369181,1.12428857 23.6352441,0.31593464 21.1281427,0.103130926 C19.4567418,-0.0387382161 17.7350934,0.485491763 15.9631976,1.67582086 C14.1719645,1.35693148 12.9652094,2.36651993 12.3429324,4.7045862 C11.4095169,8.21168561 11.9937905,9.7949883 12.4516129,10.9677419 Z"
            id="Path"
            fill="#3D231A"
          ></path>
        </g>
      </g>
    </svg>
  );
}

export default function IconesContacts({ nomIcone, type, ...props }) {
  if (type) {
    switch (type.toUpperCase()) {
      case "MEDECIN":
        return <IconeMedecin {...props} />;
      case "INFIRMIERE":
        return <IconeInfirmiere {...props} />;
      case "PHARMACIE":
        return <IconePharmacie {...props} />;
      default:
        return <IconeAutreContact {...props} />;
    }
  }

  switch (nomIcone) {
    case "IconeMedecin":
      return <IconeMedecin {...props} />;
    case "IconeInfirmiere":
      return <IconeInfirmiere {...props} />;
    case "IconePharmacie":
      return <IconePharmacie {...props} />;
    case "IconeAutreContact":
      return <IconeAutreContact {...props} />;
    default:
      return <IconeAutreContact {...props} />;
  }
}
