import React, { useContext, useState } from "react";
import MonStyle from "./EditionConseilSeance.module.css";
import InputTextArea from "../../../../Design/Formulaires/InputTextArea";
import { useEffect } from "react";
import MessageOuiNon from "../../../../Design/Messages/MessageOuiNon";
import { ConseilsContext } from "../../../../Data/ConseilsContext";
import MessageErreur from "../../../../Design/Messages/MessageErreur";
import MessageAlerte from "../../../../Design/Messages/MessageAlerte";

export default function EditionConseilSeance({ conseilSeance, style, onBlur }) {
  //NES Gestion du contexte
  const { patchPreferences } = useContext(ConseilsContext);

  //NES gestion du state
  const [texte, setTexte] = useState("");
  const [afficherEnregistrer, setAfficherEnregistrer] = useState(false);
  const [messageSucces, setMessageSucces] = useState("");
  const [erreur, setErreur] = useState("");
  const [error, setError] = useState();

  useEffect(() => {
    if (conseilSeance) {
      setTexte(conseilSeance);
    }
  }, [conseilSeance]);

  const handleTexteChange = (event) => {
    let value = event.target.value;
    setTexte(value);

    setAfficherEnregistrer(true);
  };

  const handleBlur = () => {
    if (onBlur) {
      onBlur(texte);
    }
  };

  //PATCH preferences
  const enregistrerConseils = () => {
    setMessageSucces("");
    setErreur("");
    patchPreferences({ conseils_seance: texte })
      .then((reponse) => {
        setAfficherEnregistrer(false);
        if (reponse.code === "OK") {
          setMessageSucces(
            "Ces conseils sont enregistrés comme conseils par défaut"
          );
        } else setErreur(reponse.erreur.message);
      })
      .catch((error) => {
        setErreur(
          "Erreur lors de l'enregistrement des conseils en tant que conseils par défaut"
        );
        setError(error);
      });
  };

  return (
    <div style={style}>
      <div className="TitreBloc">Conseils </div>
      <div className={MonStyle.Conseils}>
        <InputTextArea
          value={texte}
          onChange={(e) => handleTexteChange(e)}
          placeholder="Ajouter des conseils"
          minRows={3}
          //onClick={() => setEdition(true)}
          onBlur={handleBlur}
        />
      </div>
      <MessageErreur
        message={erreur}
        error={error}
        style={{ margin: "20px 0px" }}
      />
      <MessageAlerte message={messageSucces} />
      <MessageOuiNon
        message="Voulez enregistrer comme conseils par défaut ?"
        style={{ marginTop: "2px" }}
        open={afficherEnregistrer}
        onClickOui={() => enregistrerConseils()}
        onClickNon={() => setAfficherEnregistrer(false)}
      />
    </div>
  );
}
