import React, { useEffect, useState } from "react";
import MonStyle from "./DureeRdv.module.css";
import TypeRdv from "./TypeRdv";
import BoutonAjoutBloc from "../../../../Design/Boutons/BoutonAjoutBloc";

export default function DureeRdv({ types_rendez_vous, onChange }) {
  const [typesRdv, setTypesRdv] = useState([]);

  //NES Gestion dynamique de la prochaine couleur du type de rdv
  const getNextCouleur = () => {
    let couleurs = [
      "#AD1357",
      "#F4511E",
      "#E4C442",
      "#0A8043",
      "#1F5A71",
      "#8E24AA",
      //NES Ligne2
      "#D2215F",
      "#EF6C00",
      "#C0CA33",
      "#009688",
      "#7886CB",
      "#795548",
      //NES Ligne3
      "#D50001",
      "#F09300",
      "#7CB342",
      "#049BE5",
      "#B29DDB",
      "#616161",
      //NES Ligne4
      "#E67B73",
      "#F5BF25",
      "#10A600",
      "#4284F4",
      "#B370E1",
      "#A79B8D",
    ];
    let listeTypesPerso = typesRdv.filter((item) => item.type_code === "");
    let nextIndexCouleur = listeTypesPerso.length;
    if (nextIndexCouleur > 5 || nextIndexCouleur < 0) {
      nextIndexCouleur = 0;
    }
    return couleurs[nextIndexCouleur];
  };

  useEffect(() => {
    if (types_rendez_vous) {
      setTypesRdv(types_rendez_vous);
    }
  }, [types_rendez_vous]);

  //NES gestion du changement
  const handleChange = (key, value, index) => {
    console.log({ key, value, index });
    let tempArray = [...typesRdv];
    tempArray[index][key] = value;
    console.log(tempArray);
    setTypesRdv(tempArray);
    //NES retour du résultat
    if (onChange) {
      onChange(tempArray);
    }
  };

  //NES Ajouter un type de consultation
  const ajouterTypeConsultation = () => {
    let dureeConsultation = typesRdv.find(
      (item) => item.type_code === "consultation"
    ).duree;
    let nouveauType = {
      libelle: "Nouveau type de rdv",
      couleur: getNextCouleur(),
      duree: dureeConsultation ? dureeConsultation : "0:15",
      type_code: "",
    };
    let tempArray = [...typesRdv];
    tempArray.push(nouveauType);
    setTypesRdv(tempArray);
    //NES retour du résultat
    if (onChange) {
      onChange(tempArray);
    }
  };

  //NES supprimer un RDV
  const handleDelete = (index) => {
    if (index) {
      let tempArray = [...typesRdv];
      tempArray.splice(index, 1);
      setTypesRdv(tempArray);
      //NES retour du résultat
      if (onChange) {
        onChange(tempArray);
      }
    }
  };

  const remonterTypeRdv = (index) => {
    if (index > 0) {
      let newIndex = index - 1;

      let newArray = [...typesRdv];
      let typeADeplacer = newArray[index];
      newArray.splice(index, 1);
      newArray.splice(newIndex, 0, typeADeplacer);
      setTypesRdv(newArray);

      //NES retour du résultat
      if (onChange) {
        onChange(newArray);
      }
    }
  };

  const descrendreTypeRdv = (index) => {
    if (index < typesRdv.length - 1) {
      let newIndex = index + 1;
      let newArray = [...typesRdv];
      let typeADeplacer = newArray[index];
      newArray.splice(index, 1);
      newArray.splice(newIndex, 0, typeADeplacer);
      setTypesRdv(newArray);

      //NES retour du résultat
      if (onChange) {
        onChange(newArray);
      }
    }
  };

  return (
    <div className={MonStyle.DureeRdv}>
      {typesRdv.map((type, index) => (
        <TypeRdv
          libelle={type.libelle}
          couleur={type.couleur}
          duree={type.duree}
          code={type.type_code}
          key={index}
          onChange={(key, value) => handleChange(key, value, index)}
          onDelete={() => handleDelete(index)}
          onClickRemonter={() => remonterTypeRdv(index)}
          onClickDescendre={() => descrendreTypeRdv(index)}
        />
      ))}
      <BoutonAjoutBloc
        texte="Ajouter un type de rendez-vous"
        taille="L"
        fullWidth
        style={{ marginTop: "10px" }}
        onClick={ajouterTypeConsultation}
      />
    </div>
  );
}
