import React, { useContext, useEffect, useState } from "react";
import LoaderHorizontal from "../../../Design/Progression/LoaderHorizontal";
import { AdpxContext } from "../../../Data/AdpxContext";
import MessageErreur from "../../../Design/Messages/MessageErreur";
import { pluriel } from "../../../functions/Formatter";
import { SupportContext } from "../../../Data/SupportContext";

export default function ConstructionJoursPlanning({ style }) {
  //NES gestion du contexte
  const { jours, getAdpxPlanningJours, postAdpxPlannigJours } =
    useContext(AdpxContext);
  const { demandes } = useContext(SupportContext);

  //NES gestion du state
  const [isLoading, setIsLoading] = useState(true);
  const [messageErreur, setMessageErreur] = useState();
  const [error, setError] = useState();
  const [nbDemandes, setNbDemandes] = useState(0);

  useEffect(() => {
    let dateDebut = new Date();
    let dateFin = new Date();
    dateFin.setFullYear(dateDebut.getFullYear() + 1);

    getAdpxPlanningJours(dateDebut, dateFin).then((reponse) => {
      console.log(reponse);
      setIsLoading(false);
      if (reponse.code === "OK") {
        let liste = reponse.data;
        let nbJoursPlannifies = liste.length;

        //NES vais poser les jours suivants
        if (nbJoursPlannifies < 60) {
          //NES le derniers jours plannifié
          let dernierjour = liste[liste.length - 1];
          let derniereDate = new Date();
          derniereDate.setHours(0);
          derniereDate.setMinutes(0);
          derniereDate.setSeconds(0);
          derniereDate.setMilliseconds(0);

          if (liste.length > 0) {
            dernierjour = liste[liste.length - 1];
            derniereDate = new Date(dernierjour.date);
          }

          let data = [];

          do {
            // console.log(derniereDate);

            let newDate = new Date(derniereDate);
            newDate.setDate(derniereDate.getDate() + 1);

            derniereDate = newDate;

            //NES on ne prévoit rien le samedi===6 et le dimnche===0
            //console.log(newDate.getDay());
            if (newDate.getDay() > 0 && newDate.getDay() < 6) {
              let newData = { date: newDate, capacite: 3 };
              data.push(newData);
            }
          } while (data.length < 60);

          //POST nouveau jours
          let newData = { jours: data };
          postAdpxPlannigJours(newData).then((reponse) => {
            console.log(reponse);
          });
        }
      } else {
        setError(reponse.error);
        setMessageErreur(
          "Erreur lors de la récupération des jours du planning"
        );
      }
    });
    // eslint-disable-next-line
  }, [demandes]);

  //USEEFFECT pour calculer le nombre de demandes planifiées
  useEffect(() => {
    let nb = 0;
    jours.forEach((jour) => {
      nb += jour?.demandes?.length;
    });
    setNbDemandes(nb);
  }, [jours]);

  if (messageErreur) {
    return (
      <div style={style}>
        <MessageErreur error={error} message={messageErreur} />
      </div>
    );
  }

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          border: "1px solid #205A71",
          color: "#205A71",
          backgroundColor: "#205A7120",
          padding: "10px",
          ...style,
        }}
      >
        <div>Construction des jours suivants du planning</div>
        <div className="SousTitre">{jours.length + " jours de planing"}</div>
        <LoaderHorizontal isLoading={isLoading} style={{ marginTop: "10px" }} />
      </div>
    );
  }

  return (
    <div className="SousTitre">
      {jours.length + " jours de planning"} -{" "}
      {pluriel(nbDemandes, "demande planifiée", "demandes planifiées", "F")}
    </div>
  );
}
