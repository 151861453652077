import React from "react";
import { ButtonBase } from "@material-ui/core";
import { IconeReglement } from "../../../../Design/Icones/IconesReglements";

export default function BoutonReglement({
  onClick,
  label,
  code,
  disabled,
  style,
}) {
  let color = "#000000";

  switch (code) {
    case "CARTE_BANCAIRE":
      color = "#0088CE";

      break;
    case "CHEQUE":
      color = "#1C5D3B";
      break;
    case "ESPECES":
      color = "#FF9800";
      break;
    case "VIREMENT":
      color = "#6E1E78";
      break;
    case "PLUSTARD":
      color = "#F44336";
      break;
    default:
      break;
  }

  let MonStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100px",
    background: color + 20,
    borderRadius: "4px",
    height: "80px",
    color: color,
    ...style,
  };

  let MonStyleDisabled = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100px",
    background: "#DDDDDD50",
    borderRadius: "4px",
    height: "80px",
    color: "#97989D",
    ...style,
  };

  return (
    <ButtonBase
      disabled={disabled}
      onClick={onClick}
      style={disabled ? MonStyleDisabled : MonStyle}
    >
      <div>
        <IconeReglement code={code} color={disabled ? "#97989D" : ""} />
      </div>
      <div style={{ fontSize: "12px" }}>{label}</div>
    </ButtonBase>
  );
}
