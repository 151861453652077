import React, { useContext, useState } from "react";
import ListeTelephones from "../../../../../Components/ListeTelephones";
import BoutonActionPrincipale from "../../../../../Design/Boutons/BoutonActionPrincipale";
import { PatientsContext } from "../../../../../Data/PatientsContext";
import MessageErreur from "../../../../../Design/Messages/MessageErreur";
import LoaderHorizontal from "../../../../../Design/Progression/LoaderHorizontal";

export default function EditionTelephone({ contact, patient_id, onChange }) {
  //NES Gestion du contexte
  const { patchPatient } = useContext(PatientsContext);

  //NES gestion du state
  const [data, setData] = useState(contact);
  const [messageErreur, setMessageErreur] = useState();
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (telephones) => {
    setData({ ...data, telephones: telephones });
  };

  const handleEnregistrer = () => {
    let newData = { contact: data };

    setIsLoading(true);
    //PATCH Patient
    patchPatient(patient_id, newData)
      .then((reponse) => {
        setIsLoading(false);

        if (reponse.code === "OK") {
          if (onChange) {
            onChange(data);
          }
        } else {
          setMessageErreur("Erreur lors de l'enregistrement");
        }
      })
      .catch((error) => {
        setIsLoading(false);

        setMessageErreur("Erreur lors de l'enregistrement");
        setError(error);
      });
  };

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <ListeTelephones
        ListeTelephones={contact?.telephones}
        onChange={handleChange}
        autoAjout
      />
      <MessageErreur
        message={messageErreur}
        error={error}
        style={{ marginBottom: "20px" }}
      />

      <BoutonActionPrincipale
        couleur="bleu"
        texte="Enregistrer"
        onClick={handleEnregistrer}
      />
      <div
        className="InformationSecondaire"
        style={{ marginTop: "10px", textAlign: "center" }}
      >
        <LoaderHorizontal isLoading={isLoading} />
        On met à jour les numéros de téléphones du patient quand vous les
        modifiez dans le rendez-vous
      </div>
    </div>
  );
}
