import React, { useContext } from "react";
import MonStyle from "./PageMenuSuiviReglements.module.css";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import { Link } from "react-router-dom";
import SurfaceBloc from "../../../../Design/Surfaces/SurfaceBloc";
import { CotationsContext } from "../../../../Data/CotationsContext";
import AffichageNombre from "../../../../Design/DataDisplay/AffichageNombre";

export default function PageMenuSuiviReglements() {
  //NES récupération de l'URL
  var adresseActuelle = window.location.pathname;

  //NES Gestion du contexte
  const { facturesARegler } = useContext(CotationsContext);
  const { cotationsAFacturer } = useContext(CotationsContext);

  return (
    <div className={MonStyle.PageMenuSuiviReglements}>
      <h1>Suivi des règlements</h1>

      <div className={MonStyle.Bloc}>
        <div className="TitreBloc" style={{ marginBottom: "10px" }}>
          Patients
        </div>
        <SurfaceBloc>
          <List component="nav">
            <ListItem
              button
              component={Link}
              selected={
                adresseActuelle === "/suivireglements/journalreglements"
              }
              to="/suivireglements/journalreglements"
            >
              <ListItemText
                primary="Journal des règlements"
                component={Link}
                selected={
                  adresseActuelle === "/suivireglements/journalreglements"
                }
              />
            </ListItem>
            <Divider />
            <ListItem
              button
              component={Link}
              selected={
                adresseActuelle === "/suivireglements/cotationsafacturer"
              }
              to="/suivireglements/cotationsafacturer"
            >
              <ListItemText primary="Cotations à facturer" />
              <AffichageNombre>{cotationsAFacturer.length}</AffichageNombre>
            </ListItem>
            <Divider />
            <ListItem
              button
              component={Link}
              selected={adresseActuelle === "/suivireglements/facturesaregler"}
              to="/suivireglements/facturesaregler"
            >
              <ListItemText primary="Factures à régler" />
              <AffichageNombre>{facturesARegler.length}</AffichageNombre>
            </ListItem>
            <Divider />
            <ListItem
              button
              component={Link}
              selected={adresseActuelle === "/suivireglements/journalfactures"}
              to="/suivireglements/journalfactures"
            >
              <ListItemText primary="Journal des factures" />
            </ListItem>
          </List>
        </SurfaceBloc>
      </div>
    </div>
  );
}
