import React from "react";
import BoutonRond from "../Boutons/BoutonRond";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Popover from "@mui/material/Popover";

const styleMenu = {
  margin: "10px",
  minWidth: "140px",
  maxWidth: "300px",
};

export default function BoutonMenu({
  hide,
  tooltip,
  disabled,
  couleurIcone,
  onClick,
  close,
  id,
  iconeComposant,
  ...props
}) {
  //NES POPOVER
  const [anchorEl, setAnchorEl] = React.useState(null);

  //NES OUVERTURE POPOVER
  const boutonActionClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  //NES FERMETURE POPOVER
  const handleClose = () => {
    setAnchorEl(null);
  };

  if (hide) return <div style={{ width: "40px" }}>&nbsp;</div>;

  return (
    <div>
      <BoutonRond
        iconeComposant={iconeComposant ? iconeComposant : <MoreVertIcon />}
        tooltip={tooltip}
        onClick={boutonActionClick}
        disabled={disabled}
        couleurIcone={couleurIcone}
        id={id}
        {...props}
      />
      <Popover
        open={anchorEl ? true : false}
        anchorEl={anchorEl}
        onClose={handleClose}
        onClick={handleClose}
        anchorOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
      >
        <div style={styleMenu} id="menu_action">
          {props.children}
        </div>
      </Popover>
    </div>
  );
}
