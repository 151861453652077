import React, { useState, useEffect, useContext } from "react";
import IconeContactsPicker from "../../../../Design/Pickers/IconeContactsPicker";
import InputTexte from "../../../../Design/Formulaires/InputTexte";
import InputNombres from "../../../../Design/Formulaires/InputNombres";
import InputSelectObjet from "../../../../Design/Formulaires/InputSelectObjet";
import BoutonActionPrincipale from "../../../../Design/Boutons/BoutonActionPrincipale";
import BoutonAnnuler from "../../../../Design/Boutons/BoutonAnnuler";
import InputTelephone from "../../../../Design/Formulaires/InputTelephone";
import InputEmail from "../../../../Design/Formulaires/InputEmail";
import Adresse from "../../../../Components/Adresse";
import MessageErreur from "../../../../Design/Messages/MessageErreur";
import InputSwitch from "../../../../Design/Formulaires/Switch";
import AffichageConditionnel from "../../../../Design/DataDisplay/AffichageConditionnel";
import AfficherPlus from "../../../../Design/DataDisplay/AfficherPlus";
import { ContactsContext } from "../../../../Data/ContactsContext";
import ObjetItem from "../../../../Design/Objets/ObjetItem";

export default function EditionContact({
  patient_id,
  onClickAnnuler,
  onSauvegarde,
  contact_id,
  is_medecin_traitant,
}) {
  //NES gestion du contexte
  const {
    postContact,
    patchContact,
    getContactContextById,
    getSpecialites,
    specialites,
  } = useContext(ContactsContext);

  //NES Gestion du state
  const [isASauvegarder, setIsASauvegarder] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [messageErreur, setMessageErreur] = useState();
  const [error, setError] = useState();

  const [data, setData] = useState({
    icone: "IconeMedecin",
    type: "MEDECIN",
    nom: "",
    prenom: "",
    numero_rpps: "",
    numero_finess: "",
    telephone: { type: "Téléphone", numero: "" },
    email: "",
    adresse: {},
    etablissement: "",
    specialite: {},
    commentaire: "",
  });

  //USEEFFECT contact_id
  useEffect(() => {
    //NES récupération des spécialités
    getSpecialites();

    if (contact_id) {
      getContactContextById(contact_id).then((reponse) => {
        setData(reponse);
        //NES pour ne pas l'enregistrer dans l'annuaire
        if (!reponse.annuraire_id) {
          setIsASauvegarder(false);
        }
      });
    }

    // eslint-disable-next-line
  }, [contact_id]);

  //NES gestion de l'icone pour gérer le nom de l'icone et le type
  const handleIconeChange = (nomIcone) => {
    let newData = { ...data };
    switch (nomIcone) {
      case "IconeMedecin":
        newData = { ...newData, icone: nomIcone, type: "MEDECIN" };
        break;
      case "IconeInfirmiere":
        newData = { ...newData, icone: nomIcone, type: "INFIRMIERE" };
        break;
      case "IconePharmacie":
        newData = { ...newData, icone: nomIcone, type: "PHARMACIE" };
        break;
      case "IconeAutreContact":
        newData = { ...newData, icone: nomIcone, type: "" };
        break;
      default:
        break;
    }
    setData(newData);
  };

  /// handleChange
  const handleChange = (key, value) => {
    let newData = { ...data, [key]: value };
    setData(newData);
  };

  //NES enregistrer
  const handleEnregistrer = (event) => {
    event.preventDefault();
    sauvegarder(patient_id, data, isASauvegarder)
      .then((reponse) => {
        setIsLoading(false);
        if (reponse.code === "OK") {
          if (onSauvegarde) {
            onSauvegarde(reponse.data);
          }
        } else {
          setMessageErreur("Erreur lors de l'enregistrement");
        }
      })
      .catch((error) => {
        setIsLoading(false);
        setMessageErreur("Erreur lors de l'enregistrement");
        setError(error);
      });
  };

  const sauvegarder = async (patient_id, data, isASauvegarder) => {
    //NES si c'est le médécin traitant
    if (is_medecin_traitant) {
      data = { ...data, is_medecin_traitant: true };
    }

    if (data._id) {
      //PATCH
      return patchContact(patient_id, data, isASauvegarder);
    } else {
      //POST
      return postContact(patient_id, data, isASauvegarder);
    }
  };

  return (
    <form style={{ marginTop: "20px" }}>
      <section>
        <IconeContactsPicker
          onChange={(nomIcone) => handleIconeChange(nomIcone)}
          value={data.icone}
        />
        <InputTexte
          label="Nom"
          value={data.nom}
          onChange={(e) => handleChange("nom", e.target.value)}
          textTransform="uppercase"
          //style={{ marginTop: "20px" }}
        />
        <InputTexte
          label="Prénom"
          value={data.prenom}
          onChange={(e) => handleChange("prenom", e.target.value)}
          textTransform="capitalize"
        />
        <AfficherPlus labelOpen="N° RPPS, Spécialité..." labelClose="Masquer">
          <InputNombres
            label="N°RPPS"
            value={data.numero_rpps}
            onChange={(e) => handleChange("numero_rpps", e.target.value)}
          />
          <InputSelectObjet
            style={{ marginTop: "20px" }}
            placeholder="Spécialité"
            options={specialites}
            id="select_specialite"
            //disabled={disabled}
            enabledAucuneValeur
            value={data.libelle}
            renderObjets={(data) => {
              return (
                <ObjetItem
                  principale={data.libelle}
                  secondaire={"code : " + data.code}
                />
              );
            }}
            onChange={(objet) => handleChange("specialite", objet)}
          />
          <InputTexte
            label="Etablissement"
            value={data.etablissement}
            onChange={(e) => handleChange("etablissement", e.target.value)}
          />
        </AfficherPlus>
      </section>

      <section>
        <h3>Contact</h3>
        <InputTelephone
          label="Téléphone"
          objTelephone={data.telephone}
          onTelephoneChange={(telephone) =>
            handleChange("telephone", telephone)
          }
          disableTypeChange
        />
        <InputEmail
          label="Email"
          value={data.email}
          onChange={(e) => handleChange("email", e.target.value)}
        />
      </section>
      <section style={{ maxWidth: "100%" }}>
        <h3>Adresse</h3>
        <Adresse
          value={data.adresse}
          onChange={(adresse) => handleChange("adresse", adresse)}
        />
      </section>
      <section
        style={{ display: "flex", flexDirection: "column", gap: "20px" }}
      >
        <AffichageConditionnel open={!data.annuaire_id && !data._id}>
          <InputSwitch
            value={isASauvegarder}
            label="Enregistrer le contact"
            description="Pour réutiliser pour un autre dossier patient"
            onChange={() => setIsASauvegarder(!isASauvegarder)}
            variant="bloc"
          />
        </AffichageConditionnel>

        <AffichageConditionnel open={data._id && data.annuaire_id}>
          <InputSwitch
            value={isASauvegarder}
            label="Mettre à jour l'annuaire des contacts"
            description="Pour réutiliser pour un autre dossier patient"
            onChange={() => setIsASauvegarder(!isASauvegarder)}
            variant="bloc"
          />
        </AffichageConditionnel>

        <MessageErreur message={messageErreur} error={error} />
        <BoutonActionPrincipale
          texte="Enregistrer"
          taille="L"
          disabled={data.nom ? false : true}
          onClick={handleEnregistrer}
          isLoading={isLoading}
        />
        <BoutonAnnuler onClick={onClickAnnuler} />
      </section>
    </form>
  );
}
