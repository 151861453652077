import React, { useContext, useEffect, useState } from "react";
import MonStyle from "./PageListePeriodes.module.css";
import { Link, useHistory } from "react-router-dom";
import { CotationsContext } from "../../../../Data/CotationsContext";
import {
  DateToString,
  getFirstDayOfMonth,
  getLastDayOfMonth,
  toApiDate,
} from "../../../../functions/FonctionsDateHeure";
import ListItem from "@material-ui/core/ListItem";
import ObjetItem from "../../../../Design/Objets/ObjetItem";
import { pluriel } from "../../../../functions/Formatter";
import ObjetPrix from "../../../../Design/Objets/ObjetPrix";
import SurfaceBloc from "../../../../Design/Surfaces/SurfaceBloc";
import BoutonRetour from "../../../../Design/Boutons/BoutonRetour";
import LoaderHorizontal from "../../../../Design/Progression/LoaderHorizontal";
import MessageErreur from "../../../../Design/Messages/MessageErreur";
import BoutonRond from "../../../../Design/Boutons/BoutonRond";
import AddIcon from "@material-ui/icons/Add";

export default function PageListePeriodes() {
  //NES gestion des contantes
  const AUJOURDHUI = new Date();
  const PREMIERJOUR = getFirstDayOfMonth(AUJOURDHUI);
  const DERNIERJOUR = getLastDayOfMonth(AUJOURDHUI);
  const URLMOISCOURANT = `/suivireglements/journalfactures?datedebut=${toApiDate(
    PREMIERJOUR
  )}&datefin=${toApiDate(DERNIERJOUR)}`;

  //NES Gestion du routage pour faire des redirections
  let history = useHistory();

  //NES Gestion du contexte
  const { getFactures, getPeriodes, postPeriode } =
    useContext(CotationsContext);

  //NES Gestion du state
  const [moisCourant, setMoisCourant] = useState({});
  const [liste, setListe] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [messageErreur, setMessageErreur] = useState();
  const [error, setError] = useState();

  //USEEFFECT création de la période courante en dynamique
  useEffect(() => {
    //NES quel est le mois courant ?
    let mois = DateToString(AUJOURDHUI, { month: "long", year: "numeric" });
    //GET récupération de toutes les factures de la période courante pour calculer le chiffre d'affaire
    let montantCa = 0;
    let listeFacture = [];
    setIsLoading(true);
    getFactures(PREMIERJOUR, DERNIERJOUR)
      .then((reponse) => {
        setIsLoading(false);

        if (reponse.code === "OK") {
          setMessageErreur("");
          listeFacture = reponse.data;
          listeFacture.forEach((facture) => {
            montantCa = montantCa + facture.total_montant_ttc;
          });

          //NES je créé la période dynamiquement
          let periodeCourante = {
            type_periode: "MENSUEL",
            is_manuel: false,
            nom_periode: mois,
            date_debut: toApiDate(PREMIERJOUR),
            date_fin: toApiDate(DERNIERJOUR),
            nombre_de_factures: listeFacture.length,
            total_montant_ttc: montantCa,
          };

          setMoisCourant(periodeCourante);
        } else {
          setMessageErreur(
            "Erreur lors de la récupération des factures du mois courant"
          );
        }
      })
      .catch((error) => {
        setIsLoading(false);
        setError(error);
        setMessageErreur(
          "Erreur lors de la récupération des factures du mois courant"
        );
      });

    //NES redirection sur le mois courant
    history.push(URLMOISCOURANT);
    // eslint-disable-next-line
  }, []);

  //USEEFFECT récupération des périodes passées
  useEffect(() => {
    getPeriodes()
      .then((reponse) => {
        setIsLoading(false);
        if (reponse.code === "OK") {
          setListe(reponse.data);
        } else {
          setMessageErreur("Erreur lors de la récupération des périodes");
        }
      })
      .catch((error) => {
        setIsLoading(false);
        setMessageErreur("Erreur lors de la récupération des périodes");
        setError(error);
      });
    // eslint-disable-next-line
  }, []);

  //POST ajouter une période de controle
  const handleAjouterPeriode = () => {
    postPeriode()
      .then((reponse) => {
        setIsLoading(false);
        if (reponse.code === "OK") {
        } else {
          setMessageErreur("Erreur lors de l'enregistrement");
        }
      })
      .catch((error) => {
        setIsLoading(false);
        setMessageErreur("Erreur lors de l'enregistrement");
        setError(error);
      });
  };

  return (
    <div>
      <div className={MonStyle.Titre}>
        <div className="Titre1">Périodes</div>
        <div className={MonStyle.BoutonRond}>
          <BoutonRond
            iconeComposant={<AddIcon />}
            couleur="vert"
            couleurIcone="blanc"
            onClick={handleAjouterPeriode}
            tooltip="Tester les périodes"
          />
        </div>
      </div>

      <div className={MonStyle.BoutonRetour}>
        <BoutonRetour texte="retour au suivi des règlements" />
      </div>

      <MessageErreur
        message={messageErreur}
        error={error}
        style={{ marginTop: "20px" }}
      />

      <div className="TitreBloc" style={{ marginBottom: "10px" }}>
        Période
      </div>
      <SurfaceBloc>
        <LoaderHorizontal isLoading={isLoading} />

        <ListItem
          button
          component={Link}
          to={`/suivireglements/journalfactures?datedebut=${moisCourant.date_debut}&datefin=${moisCourant.date_fin}`}
          divider
        >
          <div className={MonStyle.Periode}>
            <ObjetItem
              principale={moisCourant.nom_periode}
              secondaire={pluriel(
                moisCourant.nombre_de_factures,
                "factures",
                "factures"
              )}
            />
            <ObjetPrix prix={moisCourant.total_montant_ttc} />
          </div>
        </ListItem>
        {liste?.map((periode) => (
          <ListItem
            key={periode._id}
            button
            divider
            component={Link}
            to={`/suivireglements/journalfactures?datedebut=${periode.date_debut}&datefin=${periode.date_fin}`}
          >
            <div className={MonStyle.Periode}>
              <ObjetItem
                principale={periode.nom_periode}
                secondaire={pluriel(
                  periode.nombre_de_factures,
                  "factures",
                  "factures"
                )}
              />
              <ObjetPrix prix={periode.total_montant_ttc} />
            </div>
          </ListItem>
        ))}
      </SurfaceBloc>
    </div>
  );
}
