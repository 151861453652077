import React from "react";
import MonStyle from "./PageEditionProduit.module.css";
import BoutonActionPrincipale from "../../Design/Boutons/BoutonActionPrincipale";
import BoutonAnnuler from "../../Design/Boutons/BoutonAnnuler";
import InputTexte from "../../Design/Formulaires/InputTexte";
import IconePicker from "../../Design/Pickers/IconePicker";
import { useState, useEffect } from "react";
import { useContext } from "react";
import { ProduitsContext } from "../../Data/ProduitsContext";
import InputSelectObjet from "../../Design/Formulaires/InputSelectObjet";
import ObjetLaboratoire from "../../Design/Objets/ObjetLaboratoire";
import MessageErreur from "../../Design/Messages/MessageErreur";
import MessageAlerte from "../../Design/Messages/MessageAlerte";
import { useHistory } from "react-router-dom";
import ObjetTracabilite from "../../Design/Objets/ObjetTracabilite";
import { AuthentificationContext } from "../../Data/AuthentificationContext";
import InputPrix from "../../Design/Formulaires/InputPrix";

const objProduit = {
  url_image: "",
  nom: "",
  forme: "IconePlante",
  conditionnement: "",
  description: "",
  action: "",
  conseil: "",
  composition: "",
  contre_indications: "",
  laboratoire: "",
  code_commande: "",
  prix_de_vente_ttc: "",
};

export default function PageEditionProduit({
  produit_id,
  onAnnuler,
  onEnregistrer,
  onSupprimer,
}) {
  //NES gestion du contexte
  const {
    getLaboratoires,
    postProduit,
    getProduitById,
    patchProduit,
    deleteProduit,
  } = useContext(ProduitsContext);
  const auth = useContext(AuthentificationContext);

  //NES Gestion du routage pour faire des redirections
  let history = useHistory();

  //NES Gestion du state
  const [data, setData] = useState(objProduit);
  const [laboratoires, setLaboratoires] = useState([]);
  const [messageErreur, setMessageErreur] = useState("");
  const [error, setError] = useState();
  const [alerte, setAlerte] = useState("");
  const [erreurGet, setErreurGet] = useState("");
  const [disabled, setDisabled] = useState(false);

  const handleChange = (key, value) => {
    setData({ ...data, [key]: value });
  };

  const handleLaboratoireChange = (laboratoire) => {
    let newData = {
      _id: laboratoire._id,
      logo: laboratoire.logo,
      nom: laboratoire.nom,
    };
    setData({ ...data, laboratoire: newData });
  };

  useEffect(() => {
    if (produit_id && produit_id !== "new") {
      //GET produit/:id
      getProduitById(produit_id)
        .then((reponse) => {
          console.log(reponse.data);
          if (reponse.code === "OK") {
            let produit = reponse.data;
            console.log({ produit });
            let newData = {
              _id: produit._id,
              url_image: produit.url_image,
              nom: produit.nom,
              forme: produit.forme,
              conditionnement: produit.conditionnement,
              description: produit.description,
              action: produit.action,
              conseil: produit.conseil,
              composition: produit.composition,
              contre_indications: produit.contre_indications,
              laboratoire: produit.laboratoire,
              code_commande: produit.code_commande,
              prix_de_vente_ttc: produit.prix_de_vente_ttc,
              tracabilite_creation: produit.tracabilite_creation,
              tracabilite_modification: produit.tracabilite_modification,
            };
            setData(newData);

            //NES je disable le produit si ce n'est pas le mien
            if (
              auth.payload.owned_by_organisation ===
              reponse.data.owned_by_organisation
            ) {
              setDisabled(false);
            } else {
              setDisabled(true);
            }
          }
        })
        .catch((error) => {
          setError(error);
          setErreurGet("Erreur lors de la récupération du produit");
        });
    }
    // eslint-disable-next-line
  }, [produit_id]);

  //USEEFFECT
  useEffect(() => {
    //NES chargement des laboratoires
    getLaboratoires().then((reponse) => {
      if (reponse.code === "OK") {
        setLaboratoires(reponse.data);
      }
    });
    // eslint-disable-next-line
  }, []);

  const handleEnregistrer = () => {
    enregistrer()
      .then((reponse) => {
        if (reponse.code === "OK") {
          if (onEnregistrer) {
            onEnregistrer(reponse.data);
          }
        } else {
          setMessageErreur("Erreur lors de l'enregistrement");
        }
      })
      .catch((error) => {
        setMessageErreur("Erreur lors de l'enregistrement");
        setError(error);
      });
  };

  //NES enregistrer
  const enregistrer = async () => {
    console.log("enregistrer", data);
    if (data._id) {
      //PATCH
      let reponse = await patchProduit(data);
      return reponse;
    } else {
      //POST
      let reponse = postProduit(data);
      return reponse;
    }
  };

  //NES Supprimer
  const supprimer = () => {
    if (data._id) {
      //DELETE
      console.log("delete");
      deleteProduit(data._id)
        .then((reponse) => {
          console.log(reponse);
          if (reponse.code === "OK") {
            setAlerte("Suppression OK");
            if (onSupprimer) {
              onSupprimer(data);
            }
            //NES retour à la liste des produits
            history.push("/produits/");
          }
        })
        .catch((error) => {
          console.log(error);
          setMessageErreur("Erreur lors de la suppression");
        });
    }
  };

  //NES annuler
  const annuler = () => {
    console.log("annuler", data);
    if (onAnnuler) {
      onAnnuler();
    }
  };

  return (
    <div className={MonStyle.PageEditionProduit}>
      <div className="Titre2" style={{ color: "#287819" }}>
        {data.nom}
      </div>
      <MessageErreur message={erreurGet} error={error} />
      <div className={MonStyle.BlocProduit}>
        <IconePicker
          disabled={disabled}
          value={data.forme}
          onChange={(icone) => handleChange("forme", icone)}
        />
        <InputTexte
          label="Nom"
          value={data.nom}
          onChange={(event) => handleChange("nom", event.target.value)}
          disabled={disabled}
          theme={disabled ? "lecture" : ""}
          style={{ marginTop: "20px" }}
        />
        <InputTexte
          multiline
          label="Description"
          value={data.description}
          onChange={(event) => handleChange("description", event.target.value)}
          disabled={disabled}
          theme={disabled ? "lecture" : ""}
          style={{ marginTop: "20px" }}
        />
        <InputTexte
          multiline
          label="Conditionnement"
          value={data.conditionnement}
          onChange={(event) =>
            handleChange("conditionnement", event.target.value)
          }
          disabled={disabled}
          theme={disabled ? "lecture" : ""}
          style={{ marginTop: "20px" }}
        />
        <InputTexte
          multiline
          label="Action"
          value={data.action}
          onChange={(event) => handleChange("action", event.target.value)}
          disabled={disabled}
          theme={disabled ? "lecture" : ""}
          style={{ marginTop: "20px" }}
        />
        <InputTexte
          multiline
          label="Conseil"
          value={data.conseil}
          onChange={(event) => handleChange("conseil", event.target.value)}
          disabled={disabled}
          theme={disabled ? "lecture" : ""}
          style={{ marginTop: "20px" }}
        />
        <InputTexte
          multiline
          label="Composition ou formule"
          value={data.composition}
          onChange={(event) => handleChange("composition", event.target.value)}
          disabled={disabled}
          theme={disabled ? "lecture" : ""}
          style={{ marginTop: "20px" }}
        />
        <InputTexte
          multiline
          label="Contre-indications"
          value={data.contre_indications}
          disabled={disabled}
          theme={disabled ? "lecture" : ""}
          style={{ marginTop: "20px" }}
          onChange={(event) =>
            handleChange("contre_indications", event.target.value)
          }
        />
      </div>

      <div className={MonStyle.BlocLaboratoire}>
        <div className="Titre2">Laboratoire</div>
        <InputSelectObjet
          id="select_laboratoire"
          disabled={disabled}
          placeholder="Laboratoire"
          enabledAucuneValeur
          options={laboratoires}
          value={data.laboratoire}
          renderObjets={(data) => {
            return <ObjetLaboratoire laboratoire={data} />;
          }}
          onChange={(objet) => handleLaboratoireChange(objet)}
        />
        <InputTexte
          disabled={disabled}
          label="Code de commande"
          value={data.code_commande}
          onChange={(event) =>
            handleChange("code_commande", event.target.value)
          }
          theme={disabled ? "lecture" : ""}
          style={{ marginTop: "20px" }}
        />
        <InputPrix
          disabled={disabled}
          label="Prix de vente"
          value={data.prix_de_vente_ttc}
          onChange={(event) =>
            handleChange("prix_de_vente_ttc", event.target.value)
          }
          theme={disabled ? "lecture" : ""}
          style={{ marginTop: "20px" }}
        />
      </div>

      <MessageErreur message={messageErreur} error={error} />
      <MessageAlerte message={alerte} />
      <BoutonActionPrincipale
        taille="L"
        style={{ margin: "20px 0px" }}
        texte="Enregistrer"
        couleur="bleu"
        onClick={handleEnregistrer}
        disabled={!data.nom || disabled}
      />
      <BoutonAnnuler taille="L" onClick={annuler} fullWidth />
      <ObjetTracabilite
        tracabiliteCreation={data.tracabilite_creation}
        tracabiliteModification={data.tracabilite_modification}
        style={{ margin: "50px 0px" }}
        onDelete={supprimer}
      />
    </div>
  );
}
