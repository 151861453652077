import React, { useState, useContext, useEffect } from "react";
import { PostitsPatientsContext } from "../../../Data/PostitsPatientsContext";
import { IconePostit } from "../../../Design/Icones/IconesDocuments";
import BadgeNombre from "../../../Design/DataDisplay/BadgeNombre";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import List from "@material-ui/core/List";
import ViewModuleIcon from "@material-ui/icons/ViewModule";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import Postit from "../../../Design/Objets/ObjetPostit";
import MessageAlerte from "../../../Design/Messages/MessageAlerte";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import BoutonMenu from "../../../Design/Navigation/BoutonMenu";

//NES je fait un composant car on ne va pas ajouter un post it de la même manière en mobile et en Desktop

export default function BoutonAjouterPostitPatient({ patient_id }) {
  //NES Gestion du contexte
  const { postitPatients, postPostit, getPostit } = useContext(
    PostitsPatientsContext
  );

  //NES GestionState
  const [afficher, setAfficher] = useState(true);
  const [data, setData] = useState([]);
  const [erreur, setErreur] = useState("");

  //USEEFFECT
  useEffect(() => {
    //GET
    getPostit(patient_id)
      .then((reponse) => {
        if (reponse.code === "OK") {
          setData(reponse.data);
        } else {
          setErreur(reponse.erreur.message);
        }
      })
      .catch((error) => {
        setErreur("Erreur lors de la récupétation des notes");
        console.log(error);
      });
    // eslint-disable-next-line
  }, [patient_id]);

  const handleAfficher = () => {
    setAfficher(true);
  };

  const handleMasquer = () => {
    setAfficher(false);
  };

  //POST postit
  const handleAjouter = () => {
    postPostit(patient_id)
      .then((reponse) => {
        if (reponse.code === "OK") {
          setAfficher(true);
        } else {
          setErreur(reponse.erreur.message);
        }
      })
      .catch((error) => {
        setErreur("Erreur lors de l'ajout de la note");
        console.log(error);
      });
  };

  return (
    <div>
      <MessageAlerte message={erreur} severity="error" />
      <BadgeNombre
        invisible={afficher || data.length === 0}
        badgeContent={data.length}
        color="secondary"
        id="badge_nb_patients"
      >
        <BoutonMenu
          id="bouton_ajouter_postit"
          tooltip="Ajouter une note"
          iconeComposant={
            <IconePostit style={{ width: "30px", height: "30px" }} />
          }
        >
          <List id="menu_postit">
            {data.length > 0 &&
              (!afficher ? (
                <ListItem button onClick={handleAfficher}>
                  <ViewModuleIcon className="IconeMenu" />
                  <ListItemText primary="Afficher les notes" />
                </ListItem>
              ) : (
                <ListItem button onClick={handleMasquer}>
                  <VisibilityOffIcon className="IconeMenu" />
                  <ListItemText primary="Masquer les notes" />
                </ListItem>
              ))}

            <ListItem button onClick={handleAjouter} id="AjouterUnNote">
              <AddCircleIcon className="IconeMenu" />
              <ListItemText primary="Ajouter une note" />
            </ListItem>
          </List>
        </BoutonMenu>
      </BadgeNombre>

      {afficher && (
        <div>
          {postitPatients.map((item, index) => (
            <Postit
              postit={item}
              onMasquer={handleMasquer}
              key={item._id}
              index={index}
            />
          ))}
        </div>
      )}
    </div>
  );
}
