import React from "react";
import IconesDocuments from "../../Design/Icones/IconesDocuments";
import SurfacePage from "../../Design/Surfaces/SurfacePage";
import Button from "@material-ui/core/Button";
import { Divider } from "@material-ui/core";
//import volet1 from "../../pdf/Attestation caisse sécurisée volet 1.pdf";
import volet2 from "../../pdf/Attestation caisse sécurisée volet 2.pdf";

export default function PageCaisseSecurisee() {
  return (
    <SurfacePage>
      <h1>Caisse Sécurisé</h1>
      <div className="SousTitre">
        Le module de caisse de Sinobi est conforme à la législation des
        logiciels et systemes de caisses.
      </div>
      <div
        style={{
          backgroundColor: "#EDEDED",
          height: "40px",
          borderRadius: "4px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          margin: "30px",
        }}
      >
        Attention, les 2 volets doivent être renseignés en cas de contrôle.
      </div>

      <Divider style={{ margin: "20px 0px" }} />
      <div className="TitreBloc">Volet 1 : attestation éditeur</div>
      <Button
        href={
          process.env.PUBLIC_URL + "/Attestation_caisse_securisee_volet_1.pdf"
        }
        target="_blank"
      >
        <IconesDocuments /> Attestation Editeur
      </Button>
      <Divider style={{ margin: "20px 0px" }} />

      <div className="TitreBloc">Volet 2 : attestation client</div>
      <Button href={volet2} target="_blank">
        <IconesDocuments /> Attestation client
      </Button>
    </SurfacePage>
  );
}
