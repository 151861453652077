import React from "react";
import { Alert } from "@material-ui/lab";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

export default function MessageAide({
  message,
  onClick,
  onClose,
  style,
  disableClose,
  severity,
  ...props
}) {
  if (!message) return "";

  return (
    <div
      style={{
        boxSizing: "border-box",
        width: "100%",
        maxWidth: "100%",
        ...style,
      }}
    >
      <Alert
        severity={severity ? severity : "info"}
        onClick={onClick}
        action={
          disableClose ? (
            ""
          ) : (
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={onClose}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          )
        }
        {...props}
      >
        {props.children}
        {message}
      </Alert>
    </div>
  );
}
