import React from "react";
import MonStyle from "./BlocAdresse.module.css";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import ObjetAdresse from "../../../../../Design/Objets/ObjetAdresse";
import BoutonRond from "../../../../../Design/Boutons/BoutonRond";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";

export default function BlocAdresse({ adresse, onClick }) {
  return (
    <div className={MonStyle.BlocAdresse} onClick={onClick} id="bloc_adresse">
      {adresse && adresse.rue ? (
        <div className={MonStyle.AdresseEtIcone}>
          <ObjetAdresse adresse={adresse} />
        </div>
      ) : (
        <div className={MonStyle.AdresseEtIcone}>
          <LocationOnIcon className="IconeObjet" />
          <div className={MonStyle.Adresse}>Adresse</div>
        </div>
      )}
      <BoutonRond
        iconeComposant={<NavigateNextIcon />}
        tooltip="Adresse du patient"
      />
    </div>
  );
}
