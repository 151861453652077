import React from "react";
import ObjetItem from "./ObjetItem";
import CreditCardIcon from "@material-ui/icons/CreditCard";

export default function ObjetStripePaymentMethod({ paymentMethod, ...props }) {
  const Style = {
    display: "flex",
    alignItems: "center",
    border: "1px solid #DDD",
    width: "fit-content",
    padding: "10px 20px 10px 10px",
    borderRadius: "8px",
  };

  if (!paymentMethod) {
    return "";
  }

  return (
    <div style={Style}>
      <CreditCardIcon className="IconeObjet" />
      <ObjetItem
        principale={" •••• •••• •••• " + paymentMethod?.card?.last4}
        secondaire={
          "expire le : " +
          +paymentMethod?.card?.exp_month +
          " / " +
          +paymentMethod?.card?.exp_year
        }
      />
      {props.children}
    </div>
  );
}
