import React from "react";
import MonStyle from "./HorairesJour.module.css";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { Divider } from "@material-ui/core";

const heures = [
  "07:00",
  "07:15",
  "07:30",
  "07:45",
  "08:00",
  "08:15",
  "08:30",
  "08:45",
  "09:00",
  "09:15",
  "09:30",
  "09:45",
  "10:00",
  "10:15",
  "10:30",
  "10:45",
  "11:00",
  "11:15",
  "11:30",
  "11:45",
  "12:00",
  "12:15",
  "12:30",
  "12:45",
  "13:00",
  "13:15",
  "13:30",
  "13:45",
  "14:00",
  "14:15",
  "14:30",
  "14:45",
  "15:00",
  "15:15",
  "15:30",
  "15:45",
  "16:00",
  "16:15",
  "16:30",
  "16:45",
  "17:00",
  "17:15",
  "17:30",
  "17:45",
  "18:00",
  "18:15",
  "18:30",
  "18:45",
  "19:00",
  "19:15",
  "19:30",
  "19:45",
  "20:00",
  "20:15",
  "20:30",
  "20:45",
  "21:00",
];

function SelectHeure({ value, onChange }) {
  return (
    <div className={MonStyle.SelectHeure}>
      <Select
        fullWidth
        disableUnderline={true}
        value={value}
        style={{ color: "#208AB0" }}
        onChange={onChange}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: "50%",
            },
          },
        }}
      >
        <MenuItem key="-" value="-">
          -
        </MenuItem>

        {heures.map((option) => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
}

export default function HorairesJour({
  matinDebut,
  onMatinDebutChange,
  matinFin,
  onMatinFinChange,
  apresmidiDebut,
  onApresmidiDebutChange,
  apresmidiFin,
  onApresmidiFinChange,
}) {
  return (
    <div className={MonStyle.HorairesJour}>
      {/* MATIN */}
      <div className={MonStyle.PlageHoraire}>
        <SelectHeure value={matinDebut} onChange={onMatinDebutChange} />
        <Divider orientation="vertical" flexItem />
        <SelectHeure value={matinFin} onChange={onMatinFinChange} />
      </div>
      {/* APRES MIDI */}
      <div className={MonStyle.PlageHoraire}>
        <SelectHeure value={apresmidiDebut} onChange={onApresmidiDebutChange} />
        <Divider orientation="vertical" flexItem />
        <SelectHeure value={apresmidiFin} onChange={onApresmidiFinChange} />
      </div>
    </div>
  );
}
