import React, { useEffect, useState, useContext } from "react";
import ObjetTracabilite from "../../../../Design/Objets/ObjetTracabilite";
import MessageErreur from "../../../../Design/Messages/MessageErreur";
import { ConsultationsContext } from "../../../../Data/ConsultationsContext";
import { useHistory } from "react-router-dom";
import EditionConsultation from "../../Components/AffichageConsultation/EditionConsultation";
import MonStyle from "./PageConsultation.module.css";
import BoutonRetour from "../../../../Design/Boutons/BoutonRetour";
import ListeConseils from "../../Components/AffichageConseil/ListeConseils";
import SyntheseConsultation from "../../Components/SyntheseConsultation/SyntheseConsultation";
import ListeCotations from "../../Components/AffichageCotation/ListeCotations";
import ListeFactures from "../../Components/AffichageFactures/ListeFactures";
import LoaderHorizontal from "../../../../Design/Progression/LoaderHorizontal";
import MessageArchive from "../../../../Design/Messages/MessageArchive";
import { AuthentificationContext } from "../../../../Data/AuthentificationContext";

const STYLEARCHIVE = {
  background:
    "linear-gradient(  45deg,  #ddd 5%,  #fff 5%,  #fff 45%,  #ddd 45%, #ddd 55%, #fff 55%, #fff 95%,#ddd 95%)",
  backgroundSize: "10px 10px",
  backgroundPosition: "0 0",
  border: "2px dashed #00000080",
};

export default function PageConsultation({ match }) {
  let idUrlPatient = match.params.idpatient;
  let idUrlConsultation = match.params.idconsultation;

  //NES gestion du contexte
  const {
    //maConsultation,
    deleteConsultationContext,
    getConsultationById,
    patchConsultationContext,
  } = useContext(ConsultationsContext);
  const auth = useContext(AuthentificationContext);

  //NES Gestion du state
  const [messageErreur, setMessageErreur] = useState("");
  const [error, setError] = useState();
  const [maConsultation, setMaConsultation] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  //NES Gestion du routage pour faire des redirections
  let history = useHistory();

  //USEEFFECT récupération de la consultation
  useEffect(() => {
    if (idUrlConsultation && idUrlPatient) {
      setIsLoading(true);
      setMaConsultation({});
      //NES Récupération de la consultation uniquement le reste se fait au cas par cas pour gérer l'affichage
      //NES cela permet de gérer les modules non disponibles, ce n'est pas nécessairement grave
      getConsultationById(idUrlPatient, idUrlConsultation).then((reponse) => {
        setIsLoading(false);

        if (reponse.code !== "OK") {
          setMessageErreur("Erreur lors de la récupération de la consultation");
          setError(reponse);
        } else {
          setMaConsultation(reponse.data);
          setMessageErreur();
          setError();
        }
      });
    }
    // eslint-disable-next-line
  }, [idUrlConsultation, idUrlPatient]);

  /// Suppression de la consultation
  const supprimerConsultation = () => {
    //NES suppression dans le contexte et dans l'API
    deleteConsultationContext(idUrlPatient, idUrlConsultation).then(
      (reponse) => {
        //NES redirection sur la nouvelle consultation
        history.push("/patients/" + idUrlPatient + "/consultations/");
      }
    );
  };

  ///Annulation de la supression
  const annulerArchive = () => {
    let newData = {
      is_archive: false,
      tracabilite_suppression: {},
    };
    console.log(newData);
    patchConsultationContext(
      maConsultation.patient_id,
      maConsultation._id,
      newData
    );
  };

  if (messageErreur) {
    return (
      <MessageErreur
        message={messageErreur}
        style={{ margin: "40px 0px" }}
        error={error}
        taille="XL"
      />
    );
  }

  if (isLoading) {
    return (
      <div className={MonStyle.PageConsultation}>
        <div
          style={{ width: "100%", boxSizing: "border-box", padding: "60px" }}
        >
          <LoaderHorizontal isLoading />
        </div>
      </div>
    );
  }

  return (
    <div
      className={MonStyle.PageConsultation}
      style={maConsultation?.is_archive ? STYLEARCHIVE : undefined}
    >
      <MessageArchive
        message={
          maConsultation?.is_archive ? "La consultation est archivée" : ""
        }
        tracabilite={maConsultation?.tracabilite_suppression}
        texteAction="Annuler"
        onClickAction={annulerArchive}
      />
      <div className={MonStyle.BarreActionHaut}>
        <div className={MonStyle.BoutonRetour}>
          <BoutonRetour
            texte="Retour"
            link={"/patients/" + idUrlPatient + "/consultations/"}
          />
        </div>

        <SyntheseConsultation maConsultation={maConsultation} />
      </div>

      <main className={MonStyle.Consultation}>
        <div className={MonStyle.BlocPages}>
          <EditionConsultation
            consultation={maConsultation}
            patient_id={idUrlPatient}
            consultation_id={idUrlConsultation}
          />

          {/* 
        <EditionOrdonnance style={{ marginTop: "20px" }} />
*/}
          <ListeConseils
            patient_id={maConsultation.patient_id}
            consultation_id={maConsultation._id}
          />

          <ListeFactures
            patient_id={maConsultation.patient_id}
            consultation_id={maConsultation._id}
          />

          <ListeCotations
            patient_id={maConsultation.patient_id}
            consultation_id={maConsultation._id}
            maConsultation={maConsultation}
          />

          <ObjetTracabilite
            tracabiliteCreation={maConsultation.tracabilite_creation}
            tracabiliteModification={maConsultation.tracabilite_modification}
            onDelete={supprimerConsultation}
            style={{ marginTop: "100px" }}
            disableDelete={
              maConsultation?.tracabilite_creation?.utilisateur_id !==
              auth.payload.utilisateur_id
            }
            tooltip={
              maConsultation?.tracabilite_creation?.utilisateur_id ===
              auth.payload.utilisateur_id
                ? ""
                : "Seul l'utilisateur ayant créé la consultation peut la supprimer"
            }
          />
        </div>
      </main>
    </div>
  );
}
