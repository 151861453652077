import React from "react";
import InputTexte from "../../../../Design/Formulaires/InputTexte";
import {
  IconeLangue,
  IconePoul,
} from "../../../../Design/Icones/IconesEnergetique";

export default function EditionLanguePouls({
  type,
  value,
  onChange,
  onBlur,
  minRows,
  placeholder,
  id,
}) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "top",
        backgroundColor: "#F6F6F6",
        borderRadius: "0px 25px 10px 0px",
        position: "relative",
      }}
    >
      <InputTexte
        multiline
        placeholder={placeholder ? placeholder : "Saisir"}
        theme="fondgris"
        taille="L"
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        paddingRight="30px"
        minRows={minRows}
        id={id}
      />
      <div
        style={{
          width: "56px",
          height: "56px",
          position: "absolute",
          right: "-10px",
          top: "-5px",
        }}
      >
        {type === "langue" && <IconeLangue />}
        {type === "pouls" && <IconePoul />}
      </div>
    </div>
  );
}
