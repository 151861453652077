import React, { useContext, useState } from "react";
import SurfacePapier from "../../../../../Design/Surfaces/SurfacePapier";
import MonStyle from "./EditionEvenement.module.css";
import BoutonRond from "../../../../../Design/Boutons/BoutonRond";
import CloseIcon from "@material-ui/icons/Close";
import SwipeableViews from "react-swipeable-views";
import { Divider } from "@material-ui/core";
import SurfaceBloc from "../../../../../Design/Surfaces/SurfaceBloc";
import BlocDate from "./BlocDateEvenement";
import {
  addHeuresMinutes,
  setHeureFromString,
} from "../../../../../functions/FonctionsDateHeure";
import BoutonRetour from "../../../../../Design/Boutons/BoutonRetour";
import DatePicker from "../../../../../Design/Pickers/DatePicker";
import BlocRecurrence from "./BlocRecurrence";
import EditionRecurrence from "./EditionRecurrence";
import BoutonActionPrincipale from "../../../../../Design/Boutons/BoutonActionPrincipale";
import { RdvContext } from "../../../../../Data/RdvContext";
import EnregistrerChoixRecurrence from "./EnregistrerChoixRecurrence";
import BoutonTexte from "../../../../../Design/Boutons/BoutonTexte";
import BoutonConfirmerSuppression from "../../../../../Design/Boutons/BoutonConfirmerSuppression";
import MessageErreur from "../../../../../Design/Messages/MessageErreur";
import InputTextArea from "../../../../../Design/Formulaires/InputTextArea";
import MonSwitch from "../../../../../Design/Formulaires/Switch";
import ColorPicker from "../../../../../Design/Pickers/ColorPicker";
import Popover from "@mui/material/Popover";

export default function EditionEvenement({
  agenda_id,
  date,
  heure,
  onClose,
  evenement,
}) {
  const initialisation = () => {
    if (evenement) {
      const data = {
        _id: evenement._id,
        agenda_id: evenement.agenda_id,
        couleur: evenement.couleur,
        nom_evenement: evenement.nom_evenement,
        is_toute_la_journee: evenement.is_toute_la_journee,
        date_heure_debut: new Date(evenement.date_heure_debut),
        date_heure_fin: new Date(evenement.date_heure_fin),
        recurrence: evenement.recurrence,
      };
      return data;
    } else {
      let heureDebut = heure;
      let heureFin = addHeuresMinutes(heureDebut, 1, 0);

      const data = {
        agenda_id: agenda_id,
        couleur: "#AD1357",
        nom_evenement: "",
        is_toute_la_journee: false,
        date_heure_debut: setHeureFromString(date, heureDebut),
        date_heure_fin: setHeureFromString(date, heureFin),
      };
      return data;
    }
  };
  //NES gestion du contexte
  const { postEvenement, patchEvenement, deleteEvenement } =
    useContext(RdvContext);
  //NES gestion du state
  const [monEvenement, setMonEvenement] = useState(initialisation);
  const [modeEdition, setModeEdition] = useState();
  const [afficherSuppression, setAfficherSuppression] = useState(false);
  const [messageErreur, setMessageErreur] = useState("");
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState(false);

  //NES POPOVER
  const [anchorEl, setAnchorEl] = useState(null);
  const afficherPopOver = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  //NES State pour SwipeableViews
  const [index, setIndex] = useState(0);
  const handleChangeIndex = (index) => {
    setIndex(index);
  };

  //NES quand je clique sur retour
  const handleRetour = () => {
    handleChangeIndex(0);
  };

  //NES handleChange
  const handleChange = (key, value) => {
    setMonEvenement({ ...monEvenement, [key]: value });
  };

  //NES quand je change l'heure
  const handleChangeDateHeure = (key, dateHeure) => {
    if (key === "date_heure_debut" && dateHeure > monEvenement.date_heure_fin) {
      //NES je recalcule l'haure de fin car sinon c'est insupportable
      let heureDebut = dateHeure.toLocaleTimeString("fr-FR", {
        hour: "numeric",
        minute: "numeric",
      });
      let heureFin = addHeuresMinutes(heureDebut, 1, 0);
      let dateHeureFin = setHeureFromString(dateHeure, heureFin);
      console.log({ heureDebut, heureFin, dateHeureFin });

      setMonEvenement({
        ...monEvenement,
        date_heure_debut: dateHeure,
        date_heure_fin: dateHeureFin,
      });
    } else {
      setMonEvenement({
        ...monEvenement,
        [key]: dateHeure,
      });
    }
  };

  //NES quand je clique sur la date pour changer
  const handleDateClick = (key) => {
    setModeEdition(key);
    handleChangeIndex(1);
  };

  //NES quand je change la date de debut
  const handleModificationDate = (key, date) => {
    //NES si la date est > à la date de début je change la date de fin.
    if (date > monEvenement.date_heure_fin) {
      console.log("changement");
      let heureFin = monEvenement.date_heure_fin.toLocaleTimeString("fr-FR", {
        hour: "numeric",
        minute: "numeric",
      });
      setMonEvenement({
        ...monEvenement,
        [key]: date,
        date_heure_fin: setHeureFromString(date, heureFin),
      });
    } else {
      setMonEvenement({
        ...monEvenement,
        [key]: date,
      });
    }
    //NES je repasse sur l'affichage de l'évènement
    handleChangeIndex(0);
  };

  //NES quand je clique sur la récurrence
  const handleClickRecurrence = () => {
    setModeEdition("recurrence");
    handleChangeIndex(1);
  };

  //NES quand je change la récurrence
  const handleChangeRecurrence = (recurrence) => {
    setMonEvenement({
      ...monEvenement,
      recurrence: recurrence,
    });
    handleChangeIndex(0);
  };

  /// modification de la date de fin de récurrence
  const handleModificationDateFinRecurrence = (date) => {
    let tempData = { ...monEvenement };
    let tempRecurrence = tempData.recurrence ? tempData.recurrence : {};
    tempRecurrence.date_fin_recurrence = date;

    let newData = {
      ...tempData,
      recurrence: tempRecurrence,
    };

    setMonEvenement(newData);
    handleChangeIndex(0);
  };

  //NES Quand je clique sur enregister
  const handleClickEnregistrer = () => {
    if (monEvenement.recurrence && monEvenement._id) {
      //NES J'affiche le choix de quel événenement modifier
      setModeEdition("confimerModification");
      handleChangeIndex(1);
    } else {
      enregistrer();
    }
  };

  //NES quand je clique sur cet évenement dans la confirmation de modification
  const handleModifierCetEvenement = () => {
    enregistrer();
  };

  //NES quand je clique sur tous les évenemennts dans la confirmation de suppression
  const handleModifierTous = () => {
    enregistrer("all");
  };

  //NES enregistrer
  const enregistrer = (paramTous) => {
    setIsLoading(true);
    sauvegarder(paramTous)
      .then((reponse) => {
        setIsLoading(false);
        if (reponse.code === "OK") {
          //NES fermeture de la popup
          onClose();
        } else {
          setMessageErreur("Erreur lors de l'enregistrement de l'évènement");
          setError(reponse);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        setMessageErreur("Erreur lors de l'enregistrement de l'évènement");
        setError(error);
      });
  };

  const sauvegarder = async (paramTous) => {
    if (monEvenement._id) {
      //PATCH
      return patchEvenement(
        agenda_id,
        monEvenement._id,
        monEvenement,
        paramTous
      );
    } else {
      //POST
      return postEvenement(agenda_id, monEvenement);
    }
  };

  //NES quand je clique sur supprimer
  const handleClickSupprimer = () => {
    if (monEvenement.recurrence) {
      //NES on confirme en choisant si on supprime cet évenement ou et les suivants
      setModeEdition("confimerSuppression");
      handleChangeIndex(1);
    } else {
      //NES on affiche la confirmation de suppression
      setAfficherSuppression(true);
    }
  };

  const handleSupprimerCetEvenement = () => {
    suppression();
  };

  const handleSupprimerTous = () => {
    suppression("all");
  };

  const suppression = (paramTous) => {
    console.log(paramTous);
    deleteEvenement(agenda_id, monEvenement._id, paramTous)
      .then((reponse) => {
        setIsLoading(false);
        if (reponse.code === "OK") {
          //NES fermeture de la popup
          onClose();
        } else {
          setMessageErreur("Erreur lors de la suppression de l'évènement");
        }
      })
      .catch((error) => {
        setIsLoading(false);
        setMessageErreur("Erreur lors de la suppression de l'évènement");
        setError(error);
      });
  };

  return (
    <SurfacePapier couleur="gris" disablePadding>
      <div className={MonStyle.EditionEvenement}>
        <div className={MonStyle.TitreEtBouton}>
          <div className="TitrePop">Evènement</div>
          <BoutonRond
            tooltip="Annuler et fermer"
            iconeComposant={<CloseIcon />}
            onClick={onClose}
          />
        </div>

        <SwipeableViews index={index} onChangeIndex={handleChangeIndex}>
          {/* 0 : BLOC AFFICHAGE DU RDV */}
          <div className={MonStyle.View}>
            <SurfaceBloc style={{ marginBottom: "20px" }}>
              <div className={MonStyle.NomEvement}>
                <div
                  style={{
                    minWidth: "24px",
                    minHeight: "24px",
                    borderRadius: "4px",
                    cursor: "pointer",
                    backgroundColor: monEvenement.couleur
                      ? monEvenement.couleur
                      : "#DDD",
                  }}
                  onClick={afficherPopOver}
                >
                  {""}
                </div>
                <Popover
                  open={anchorEl ? true : false}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  onClick={handleClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                >
                  <ColorPicker
                    couleurSelectionne={monEvenement.couleur}
                    onCouleurChange={(couleur) =>
                      handleChange("couleur", couleur)
                    }
                  />
                </Popover>

                <InputTextArea
                  placeholder="Nom affiché dans l'agenda"
                  autoFocus={monEvenement.nom_evenement ? false : true}
                  value={monEvenement.nom_evenement}
                  onChange={(e) =>
                    handleChange("nom_evenement", e.target.value)
                  }
                />
              </div>

              <Divider />
              <MonSwitch
                style={{ margin: "0px 10px ", minHeight: "50px" }}
                label="Toute la journée"
                className="Icone"
                value={monEvenement.is_toute_la_journee}
                onChange={(value) => handleChange("is_toute_la_journee", value)}
              />
            </SurfaceBloc>

            <SurfaceBloc style={{ marginBottom: "20px" }}>
              <BlocDate
                label="du"
                dateHeure={monEvenement.date_heure_debut}
                is_toute_la_journee={monEvenement.is_toute_la_journee}
                onClick={() => handleDateClick("date_heure_debut")}
                onDateHeureChange={(dateHeure) =>
                  handleChangeDateHeure("date_heure_debut", dateHeure)
                }
              />
              <Divider />
              <BlocDate
                label="au"
                dateHeure={monEvenement.date_heure_fin}
                is_toute_la_journee={monEvenement.is_toute_la_journee}
                onClick={() => handleDateClick("date_heure_fin")}
                onDateHeureChange={(dateHeure) =>
                  handleChangeDateHeure("date_heure_fin", dateHeure)
                }
              />
            </SurfaceBloc>
            <SurfaceBloc style={{ marginBottom: "20px" }}>
              <BlocRecurrence
                recurrence={monEvenement.recurrence}
                onClick={handleClickRecurrence}
              />
              <Divider />
              {monEvenement?.recurrence?.code && (
                <BlocDate
                  label="jusqu'au"
                  placeholder="date de fin"
                  dateHeure={monEvenement?.recurrence?.date_fin_recurrence}
                  is_toute_la_journee
                  onClick={() => handleDateClick("date_fin_recurrence")}
                  enableEffacer={
                    monEvenement?.recurrence?.date_fin_recurrence ? true : false
                  }
                  onClickEffacer={(date) =>
                    handleModificationDateFinRecurrence("")
                  }
                />
              )}
            </SurfaceBloc>
            {!afficherSuppression && (
              <BoutonActionPrincipale
                texte="Enregistrer"
                taille="L"
                fullWidth
                disabled={!monEvenement.nom_evenement}
                onClick={handleClickEnregistrer}
                isLoading={isLoading}
              />
            )}
            {monEvenement._id && !afficherSuppression && (
              <BoutonTexte
                texte="Supprimer"
                couleur="rouge"
                taille="L"
                fullWidth
                style={{ marginTop: "10px" }}
                onClick={handleClickSupprimer}
              />
            )}
            {afficherSuppression && (
              <BoutonConfirmerSuppression
                flexDirection="column"
                taille="L"
                onAnnuler={() => setAfficherSuppression(false)}
                onSupprimer={() => suppression()}
              />
            )}
          </div>
          {/* 1 : EDITION*/}

          <div className={MonStyle.View}>
            <BoutonRetour texte="Retour" onClick={handleRetour} />
            {modeEdition === "date_heure_debut" && (
              <DatePicker
                onDateSelect={(date) =>
                  handleModificationDate("date_heure_debut", date)
                }
                date={monEvenement.date_heure_debut}
              />
            )}
            {modeEdition === "date_heure_fin" && (
              <DatePicker
                onDateSelect={(date) =>
                  handleModificationDate("date_heure_fin", date)
                }
                date={monEvenement.date_heure_fin}
              />
            )}
            {modeEdition === "recurrence" && (
              <EditionRecurrence onRecurrenceChange={handleChangeRecurrence} />
            )}
            {modeEdition === "date_fin_recurrence" && (
              <DatePicker
                onDateSelect={(date) =>
                  handleModificationDateFinRecurrence(date)
                }
                date={monEvenement?.recurrence?.date_fin_recurrence}
              />
            )}
            {modeEdition === "confimerModification" && (
              <EnregistrerChoixRecurrence
                label="Quels évènements souhaitez-vous modifier?"
                onClickCetEvenement={handleModifierCetEvenement}
                onClickTous={handleModifierTous}
              />
            )}
            {modeEdition === "confimerSuppression" && (
              <EnregistrerChoixRecurrence
                label="Quels évènements souhaitez-vous supprimer?"
                onClickCetEvenement={handleSupprimerCetEvenement}
                onClickTous={handleSupprimerTous}
              />
            )}
          </div>
        </SwipeableViews>
        <MessageErreur message={messageErreur} error={error} />
      </div>
    </SurfacePapier>
  );
}
