import React, { useState } from "react";
import { DateToString } from "../../../../functions/FonctionsDateHeure";
import MonStyle from "./EditionDate.module.css";
import Popover from "@mui/material/Popover";
import SurfacePapier from "../../../../Design/Surfaces/SurfacePapier";
import DatePicker from "../../../../Design/Pickers/DatePicker";

export default function EditionDate({ date, onChange }) {
  //NES gestion du state
  const [anchorEl, setAnchorEl] = useState(null);

  //NES POPOVER
  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  //NES quand je sélectionne la date
  const handleDatechange = (date) => {
    if (onChange) {
      onChange(date);
    }
    if (handleClose) {
      handleClose();
    }
  };

  return (
    <div className={MonStyle.DateConsultation}>
      <div onClick={handleOpen}>
        {DateToString(date, {
          weekday: "short",
          year: "numeric",
          month: "long",
          day: "numeric",
        })}
      </div>
      <Popover
        open={anchorEl ? true : false}
        anchorEl={anchorEl}
        onClose={handleClose}
        //onClick={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <SurfacePapier>
          <div className={MonStyle.DatePicker}>
            <DatePicker date={date} onDateSelect={handleDatechange} />
          </div>
        </SurfacePapier>
      </Popover>
    </div>
  );
}
