import React, { useEffect, useContext } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import InputTexte from "../Formulaires/InputTexte";
import { ProduitsContext } from "../../Data/ProduitsContext";
import ObjetProduit from "../Objets/ObjetProduit";

const objProduit = { nom: "" };

export default function InputAutocompleteProduits({
  produit,
  autoFocus,
  label,
  onSelect,
  onBlur,
  style,
  id,
}) {
  const [monProduit, setMonProduit] = React.useState(objProduit);
  const [inputValue, setInputValue] = React.useState("");
  const [options, setOptions] = React.useState([]);

  //NES Gestion du contexte
  const { getProduit, getProduitsFavoris } = useContext(ProduitsContext);

  //NES initialisation de la valeur
  useEffect(() => {
    if (produit) {
      setInputValue(produit?.nom);
      setMonProduit(produit);
    }
  }, [produit]);

  useEffect(() => {
    //NES Je recherche pas à chaque lettre sinon c'est trop souvent
    const timer = setTimeout(() => {
      if (inputValue.length === 0) {
        //GET produitsFavoris
        getProduitsFavoris().then((reponse) => {
          if (reponse.code === "OK") {
            setOptions(reponse.data);
          }
        });
      }

      if (inputValue.length >= 3) {
        //GET PRODUITS
        getProduit(inputValue).then((reponse) => {
          if (reponse.code === "OK") {
            setOptions(reponse.data.nom);
          }
        });
      }
    }, 500);
    return () => clearTimeout(timer);
    // eslint-disable-next-line
  }, [inputValue]);

  useEffect(() => {
    //console.log("useEffect", monProduit);
  }, [monProduit]);

  const handleSelect = (event, produit) => {
    if (produit) {
      setMonProduit(produit);
      if (onSelect) {
        onSelect(produit);
      }
    }
  };

  const handleTexteChange = (texte) => {
    setInputValue(texte);
    setMonProduit({ nom: texte });
  };

  const handleEnter = (event) => {
    let objetProduit = { nom: event.target.value };
    if (onSelect) {
      onSelect(objetProduit);
    }
  };

  return (
    <Autocomplete
      id={id}
      getOptionLabel={(option) => (option.nom ? option.nom : "")}
      options={options}
      freeSolo
      noOptionsText={
        inputValue ? "Aucun produit trouvé" : "Aucun produit favori"
      }
      autoFocus
      //autoHighlight
      //NES Objet
      value={monProduit}
      onChange={(event, newValue) => {
        handleSelect(event, newValue);
      }}
      //NES valeur du champ
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => {
        handleTexteChange(newInputValue);
      }}
      onBlur={onBlur}
      //NES quand je clique sur une suggestion
      onSelect={handleSelect}
      style={style}
      onKeyPress={(event) =>
        window.event.keyCode === 13 ? handleEnter(event) : ""
      }
      openOnFocus
      filterOptions={(x) => x}
      renderInput={(params) => (
        <InputTexte
          {...params}
          label={label}
          placeholder="Nom du produit"
          autoComplete="jkijfsicjeijijd<"
          fullWidth
          autoFocus={true}
          //theme="lecture"
          disablepadding
          size="small"
        />
      )}
      renderOption={(option) => {
        return <ObjetProduit produit={option} taille="L" />;
      }}
    />
  );
}
