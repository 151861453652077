import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { HashLink as Link } from "react-router-hash-link";
import ListItem from "@material-ui/core/ListItem";

//NES Style de composant NORMAL
const CssMenuItem = withStyles({
  root: {
    maxWidth: "100%",
    backgroundColor: "white",
    minHeight: "60px",
    margin: "2px 0px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    boxSizing: "border-box",
    width: "100%",
    border: "0px solid red",
    textDecoration: "none",
    listStyle: "none",
  },
})(ListItem);

const styleNouveau = {
  color: "black",
  fontWeight: "900",
  fontFamily: "Lato",
};

const styleLigne = {
  color: "#666",
  fontWeight: "500",
  fontFamily: "Lato",
};

export default function LigneResultat({
  id,
  onClick,
  link,
  isNouveau,
  onMouseEnter,
  onMouseLeave,
  ...props
}) {
  return (
    <CssMenuItem
      id={id}
      button
      onClick={onClick}
      component={link ? Link : ""}
      to={link}
      {...props}
      style={isNouveau ? styleNouveau : styleLigne}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {props.children}
    </CssMenuItem>
  );
}
