import React, { useState } from "react";
import SurfacePopUp from "../Surfaces/SurfacePopUp";
import MonStyle from "./CaptureEcran.module.css";

export default function CaptureEcran({ src, url }) {
  //NES gestion du state
  const [open, setOpen] = useState(false);

  return (
    <div>
      <img
        src={src}
        alt=""
        className={MonStyle.ImageSmall}
        onClick={() => setOpen(true)}
      />
      <SurfacePopUp open={open} onClose={() => setOpen(false)}>
        <img src={src} alt="" className={MonStyle.ImageLarge} />
        <div className={MonStyle.Url}>{url}</div>
      </SurfacePopUp>
    </div>
  );
}
