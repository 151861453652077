import React, { useEffect, useState } from "react";
import MonStyle from "./ColorPicker.module.css";
import SvgIcon from "@material-ui/core/SvgIcon";

function Color({ couleur, svgIcon, isSelected, onClick }) {
  return (
    <div
      className={isSelected ? MonStyle.ColorSelected : MonStyle.Color}
      style={{ color: couleur, borderColor: isSelected ? couleur : "" }}
      onClick={onClick}
      id={couleur}
    >
      {svgIcon ? (
        <SvgIcon component={svgIcon} />
      ) : (
        <div
          className={MonStyle.SvgIcon}
          style={{
            borderRadius: "15px",
            backgroundColor: couleur,
          }}
        >
          {""}
        </div>
      )}
    </div>
  );
}

export default function ColorPicker({
  couleurSelectionne,
  onCouleurChange,
  svgIcon,
  label,
  listeCouleurs,
  isCouleursBase,
  accepteVide,
}) {
  const couleursBase = [
    "#205A71",
    "#F79860",
    "#0FA600",
    "#E9587F",
    "#0091BA",
    "#B370E1",
  ];

  const couleurs = [
    //NES Ligne 1
    "#AD1357",
    "#F4511E",
    "#E4C442",
    "#0A8043",
    "#1F5A71",
    "#8E24AA",
    //NES Ligne2
    "#D2215F",
    "#EF6C00",
    "#C0CA33",
    "#009688",
    "#7886CB",
    "#795548",
    //NES Ligne3
    "#D50001",
    "#F09300",
    "#7CB342",
    "#049BE5",
    "#B29DDB",
    "#616161",
    //NES Ligne4
    "#E67B73",
    "#F5BF25",
    "#10A600",
    "#4284F4",
    "#B370E1",
    "#A79B8D",
  ];

  const [selectedColor, setSelectedColor] = useState();
  const [colors, setColors] = useState(
    isCouleursBase ? couleursBase : couleurs
  );

  useEffect(() => {
    if (listeCouleurs) {
      setColors(listeCouleurs);
    }
  }, [listeCouleurs]);

  const handleClick = (couleur) => {
    if (couleur === selectedColor && accepteVide) {
      setSelectedColor("");
      if (onCouleurChange) onCouleurChange("");
    } else {
      setSelectedColor(couleur);
      if (onCouleurChange) onCouleurChange(couleur);
    }
  };

  useEffect(() => {
    if (couleurSelectionne) {
      setSelectedColor(couleurSelectionne);
    }
  }, [couleurSelectionne]);

  return (
    <div
      style={{
        marginTop: "10px",
      }}
    >
      {label && <p style={{ paddingLeft: "10px" }}>{label}</p>}
      <div
        style={{
          marginTop: "5px",
          //backgroundColor: "lightgray",
          display: "grid",
          gridTemplateColumns: "repeat(6, 1fr)",
          padding: "0px",
        }}
      >
        {colors.map((couleur) => (
          <Color
            couleur={couleur}
            isSelected={selectedColor === couleur ? true : false}
            onClick={() => handleClick(couleur)}
            svgIcon={svgIcon}
            key={couleur}
          />
        ))}
      </div>
    </div>
  );
}
