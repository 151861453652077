import React from "react";
import MonStyle from "./PanneauProduit.module.css";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import BoutonRond from "../../../Design/Boutons/BoutonRond";
import CloseIcon from "@material-ui/icons/Close";

export default function PanneauProduit({
  titre,
  open,
  onClose,
  onOpen,
  ...props
}) {
  return (
    <SwipeableDrawer
      anchor="right"
      open={open}
      onClose={onClose}
      onOpen={onOpen}
    >
      <div className={MonStyle.Panneau}>
        <div className={MonStyle.BoutonTitre}>
          <BoutonRond
            iconeComposant={<CloseIcon />}
            style={{ marginRight: "10px" }}
            tooltip="Fermer"
            onClick={onClose}
            couleurIcone="noir"
            id="bouton_fermer_panneau"
          />
          <span className="TitrePop">{titre}</span>
        </div>
        <div>{props.children}</div>
      </div>
    </SwipeableDrawer>
  );
}
