import React from "react";
import { Line } from "react-chartjs-2";

export default function GraphiqueCourbe({ labelsX, titreX, valeurs, modele }) {
  //NES gestion du state

  //NES Options d'affichage du graphique
  let options = {
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      y: {
        title: { display: true, text: modele.labelY },
        min: modele.axeYmin,
      },
      x: {
        title: { display: true, text: titreX },
      },
    },
    responsive: true,
    maintainAspectRatio: false,
  };

  let data = {
    labels: labelsX,
    datasets: [
      {
        label: "MIN",
        data: modele.valeursMin,
        borderWidth: 1,
        radius: 0,
        borderColor: modele.borderColor,
        borderDash: [5, 5],
        fill: {
          target: "start",
          above: modele.backgroundColor,
        },
      },
      {
        label: "MAX",
        data: modele.valeursMax,
        backgroundColor: modele.backgroundColor,
        borderWidth: 1,
        radius: 0,
        borderColor: modele.borderColor,
        borderDash: [5, 5],
        spanGaps: true,
        bezierCurve: true,
        tension: 0.8,
        fill: {
          target: "end",
        },
      },
      {
        label: "Valeurs",
        pointStyle: "circle",
        data: valeurs,
        backgroundColor: modele.backgroundColor,
        borderWidth: 2,
        radius: 2,
        borderColor: "red",
        spanGaps: true,
        bezierCurve: true,
        tension: 0,
      },
    ],
  };

  return (
    <div style={{ height: "50vh" }}>
      <Line data={data} options={options} />
    </div>
  );
}
