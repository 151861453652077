import React from "react";
import SurfaceZoneSecondaire from "../../../../Design/Surfaces/SurfaceZoneSecondaire";

export default function MessageSecuritePaiement() {
  return (
    <SurfaceZoneSecondaire style={{ maxWidth: "420px" }}>
      <h3 style={{ marginBottom: "20px" }}>
        La sécurité de vos informations de paiement compte pour nous !
      </h3>
      <section>
        <h4>Nous utilisons Stripe !</h4>
        <p>
          Stripe c’est là référence sécurité high-tech. Des entreprises comme
          Dailymotion, Twitter, Apple Pay, Adidas … utilise ces technologies
          d’avant garde. Stripe est certifiée selon les normes les plus élevées
          du secteur et a obtenu des autorisations réglementaires dans le monde
          entier.
        </p>
      </section>
      <section>
        <h4>Sécurisation et chiffrement</h4>
        <p>
          Tous les numéros de carte sont chiffrés sur disque selon la norme
          AES-256. Les clés de chiffrement sont stockées sur des machines
          distinctes.
        </p>
      </section>
      <section>
        <h4>Stockage de vos informations de carte bancaire</h4>

        <p>
          L’infrastructure de Stripe pour le stockage, le déchiffrage et la
          transmission des numéros de carte fonctionne de manière indépendante,
          sans partager aucune information sensible avec les services de base de
          Stripe.
        </p>
      </section>
      <section>
        <h4>Nous n'avons pas accès à vos données bancaires !</h4>
        <p>
          <strong>
            Les équipe de Sinobi n'ont pas accès à vos données de carte
            bancaire.
          </strong>{" "}
          Nous ne pouvons récupérer que les 4 derniers numéros de votre carte et
          la date d'expiration pour gérer notre affichage.
        </p>
      </section>
    </SurfaceZoneSecondaire>
  );
}
