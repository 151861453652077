import React, { useEffect, useState } from "react";
import MonStyle from "./PanneauContacts.module.css";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import CloseIcon from "@material-ui/icons/Close";
import BoutonRond from "../../../../Design/Boutons/BoutonRond";
import RechercheContacts from "./RechercheContacts";
import BoutonActionFlottant from "../../../../Design/Boutons/BoutonActionFlottant";
import EditionContact from "./EditionContact";

export default function PanneauContacts({
  open,
  onClose,
  onOpen,
  onSelect,
  afficherNouveau,
  contact_id,
  patient_id,
  is_medecin_traitant,
  ...props
}) {
  //NES gestion du state
  const [afficherFormulaire, setAfficherFormulaire] = useState(true);

  useEffect(() => {
    if (contact_id) {
      setAfficherFormulaire(true);
    } else {
      setAfficherFormulaire(false);
    }
  }, [contact_id]);

  //NES quand je sélectionne un contact
  const handleSelect = (contact) => {
    if (onSelect) {
      onSelect(contact);
    }
  };

  const handleSauvegarder = () => {
    setAfficherFormulaire(false);
    if (onClose) {
      onClose();
    }
  };

  return (
    <SwipeableDrawer
      anchor="right"
      open={open}
      onClose={onClose}
      onOpen={onOpen}
    >
      <div className={MonStyle.PanneauContacts}>
        <div className={MonStyle.TitreEtBouton}>
          <BoutonRond
            iconeComposant={<CloseIcon />}
            style={{ marginRight: "10px" }}
            tooltip="Fermer"
            onClick={onClose}
            couleurIcone="noir"
            id="bouton_fermer_panneau"
          />
          <h2>{is_medecin_traitant ? "Médecin traitant" : "Contacts"}</h2>
        </div>
        {afficherFormulaire ? (
          <EditionContact
            onClickAnnuler={onClose}
            contact_id={contact_id}
            patient_id={patient_id}
            is_medecin_traitant={is_medecin_traitant}
            onSauvegarde={handleSauvegarder}
          />
        ) : (
          <RechercheContacts
            onSelect={handleSelect}
            is_medecin_traitant={is_medecin_traitant}
          />
        )}
        {!afficherFormulaire && (
          <BoutonActionFlottant
            tooltip="Nouveau contact"
            onClick={() => setAfficherFormulaire(true)}
          />
        )}
      </div>
    </SwipeableDrawer>
  );
}
