import React, { useContext } from "react";
import BadgeNombre from "../../../../Design/DataDisplay/BadgeNombre";
import { HashLink as Link } from "react-router-hash-link";
import { pluriel } from "../../../../functions/Formatter";
import { ConseilsContext } from "../../../../Data/ConseilsContext";
import { IconePlante } from "../../../../Design/Icones/IconesProduits";
import Grow from "@mui/material/Grow";

export default function SyntheseConseils() {
  const style = {
    border: "none",
    color: "#186D1B",
    backgroundColor: "#186D1B20",
    minHeight: "25px",
    borderRadius: "20px",
    padding: "2px 10px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "14px",
    fontWeight: "500",
    minWidth: "40px",
    gap: "5px",
  };

  //NES gestion du contexte
  const { conseils } = useContext(ConseilsContext);

  //NES je n'affiche rien si je n'ai pas de documents
  if (conseils.length === 0) {
    return "";
  }

  return (
    <Grow in={conseils.length ? true : false}>
      <div>
        <BadgeNombre
          badgeContent={conseils.length}
          invisible={conseils.length <= 1}
        >
          <Link to="#Conseils" smooth style={style}>
            <IconePlante />
            {pluriel(conseils.length, "conseil", "conseils")}
          </Link>
        </BadgeNombre>
      </div>
    </Grow>
  );
}
