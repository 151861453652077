import React, { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import BoutonActionPrincipale from "../../../Design/Boutons/BoutonActionPrincipale";
import InputNombres from "../../../Design/Formulaires/InputNombres";
import MessageErreur from "../../../Design/Messages/MessageErreur";
import { DateToString } from "../../../functions/FonctionsDateHeure";
import { POST } from "../../../functions/FonctionsApi";
import { useContext } from "react";
import { AuthentificationContext } from "../../../Data/AuthentificationContext";
import queryString from "query-string";
import { useHistory } from "react-router-dom";
import MessageAide from "../../../Design/Messages/MessageAide";

export default function VerificationCompte({ verificationCompte }) {
  //NES récupération des paramètres de l'URL
  let url = window.location.search;
  let params = queryString.parse(url);

  //NES gestion des ref
  const inputNombre0 = useRef(null);
  const inputNombre1 = useRef(null);
  const inputNombre2 = useRef(null);
  const inputNombre3 = useRef(null);

  //NES Gestion du routage pour faire des redirections
  let history = useHistory();

  //NES gestion du contexte
  const auth = useContext(AuthentificationContext);

  //NES gestion du state
  const [nombres, setNombres] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [messageErreur, setMessageErreur] = useState();
  const [message, setMessage] = useState();
  const [error, setError] = useState();
  const [afficherBouton, setAfficherBouton] = useState(false);
  const [hashVerification, setHashVerification] = useState();
  const [code, setCode] = useState();

  //USEEFFECT
  useEffect(() => {
    if (params.hash) {
      setHashVerification(params.hash);
    }
  }, [params]);

  //USEEFFECT verificationCompte
  useEffect(() => {
    if (verificationCompte) {
      setHashVerification(verificationCompte?.hash);
    }
  }, [verificationCompte]);

  ///Vérification du compte
  const verifierCompte = () => {
    let data = {
      verification: {
        hash: hashVerification,
        code: code,
      },
    };

    const monUrl = `${process.env.REACT_APP_URL_API_UTILISATEURS}/verification/`;
    setIsLoading(true);
    POST(monUrl, auth.token, data).then((reponse) => {
      setIsLoading(false);

      console.log(reponse);

      if (reponse.code === "OK") {
        //NES tocken de connexion
        auth.Connexion(reponse.connexion.token).then((retour) => {
          console.log({ retour });
          //NES routage sur la homepage
          console.log("routage sur la homepage");
          history.push("/home?action=welcome");

          // history.push("/");
        });
      } else {
        if (reponse.code === "erreur") {
          setMessage(reponse.message);
        } else {
          setMessageErreur(reponse.message);
          setError(reponse);
        }
      }
    });
  };

  ///handleChange
  const handleChange = (e, index) => {
    let nombre = e.target.value;
    let tempArray = [...nombres];
    tempArray[index] = nombre;

    let monIndex = index;

    //NES si je fais du copier collé
    if (nombre.length > 1) {
      tempArray = nombre.split("");
      monIndex = tempArray.length - 1;
    }

    setNombres(tempArray);

    if (nombre) {
      switch (monIndex) {
        case 0:
          inputNombre1.current.focus();
          break;
        case 1:
          inputNombre2.current.focus();
          break;
        case 2:
          inputNombre3.current.focus();
          break;
        case 3:
          inputNombre3.current.focus();
          break;
        default:
          inputNombre1.current.focus();
      }
    }

    //NES je lance le controle
    let code = tempArray.join("");

    setCode(code);

    if (hashVerification && code.length === 4) {
      setAfficherBouton(true);
    } else {
      setAfficherBouton(false);
    }
  };

  ///pour effacer le code
  const handleKeyPress = (e, index) => {
    if (window.event.keyCode === 13 && code.length === 4) {
      verifierCompte();
    }

    //NES revenir au champ d'avant
    if (window.event.keyCode === 8 && e.target.value === "") {
      console.log({ index });
      switch (index) {
        case 1:
          inputNombre1.current.focus();
          break;
        case 2:
          inputNombre1.current.focus();
          break;
        case 3:
          inputNombre2.current.focus();
          break;
        case 4:
          inputNombre3.current.focus();
          break;

        default:
          inputNombre1.current.focus();
          break;
      }
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        alignItems: "center",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
        }}
      >
        <h3 style={{ textAlign: "center" }}>Code de vérification</h3>
        <p className="SousTitre">
          Entrez le code de vérification reçu par email.
        </p>
        {verificationCompte && (
          <div>
            <p className="SousTitre" style={{ textAlign: "center" }}>
              à l'adresse : {verificationCompte?.email},
            </p>
            <p className="SousTitre" style={{ textAlign: "center" }}>
              envoyé le {DateToString(verificationCompte?.date_envoi)}
            </p>
          </div>
        )}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-evenly",
            //backgroundColor: "#DDDDDD50",
            alignItems: "center",
            padding: "20px",
            margin: "20px 0px",
            borderTop: "1px solid #DDDDDD",
            borderBottom: "1px solid #DDDDDD",
            width: "100%",
            boxSizing: "border-box",
          }}
        >
          <InputNombres
            style={{ maxWidth: "50px" }}
            size="medium"
            inputProps={{ maxLength: 4 }}
            textalign="center"
            inputRef={inputNombre0}
            value={nombres[0]}
            onChange={(e) => handleChange(e, 0)}
            autoFocus
            onKeyDown={(e) => handleKeyPress(e, 0)}
            disablepadding
          />
          <InputNombres
            style={{ maxWidth: "50px" }}
            size="medium"
            inputProps={{ maxLength: 1 }}
            textalign="center"
            inputRef={inputNombre1}
            value={nombres[1]}
            onChange={(e) => handleChange(e, 1)}
            onKeyDown={(e) => handleKeyPress(e, 1)}
            disablepadding
          />
          <InputNombres
            style={{ maxWidth: "50px" }}
            size="medium"
            inputProps={{ maxLength: 1 }}
            textalign="center"
            inputRef={inputNombre2}
            value={nombres[2]}
            onChange={(e) => handleChange(e, 2)}
            onKeyDown={(e) => handleKeyPress(e, 2)}
            disablepadding
          />{" "}
          <InputNombres
            style={{ maxWidth: "50px" }}
            size="medium"
            inputProps={{ maxLength: 1 }}
            textalign="center"
            inputRef={inputNombre3}
            value={nombres[3]}
            onChange={(e) => handleChange(e, 3)}
            onKeyDown={(e) => handleKeyPress(e, 3)}
            disablepadding
          />{" "}
        </div>
        <MessageAide
          message={message}
          style={{ marginBottom: "20px" }}
          disableClose
          severity="error"
        />
        <MessageErreur
          message={messageErreur}
          error={error}
          style={{ marginBottom: "20px" }}
        />
        <BoutonActionPrincipale
          couleur="bleu"
          texte="Vérifier"
          taille="L"
          fullWidth
          disabled={!afficherBouton}
          onClick={verifierCompte}
          isLoading={isLoading}
          onKeyDown={handleKeyPress}
        />
      </div>
    </div>
  );
}
