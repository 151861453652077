import React, { useContext, useEffect, useState } from "react";
import MonStyle from "./AffichageMesuresSuivies.module.css";
import { ConstantesContext } from "../../../../Data/ConstantesContext";
import AffichageAncienneMesure from "./AffichageAncienneMesure";

export default function AffichageMesuresSuivies({ patient_id }) {
  //NES gestion du contexte
  const { mesuresPatient, getMesuresPatient } = useContext(ConstantesContext);

  //NES Gesiton du state
  const [mesuresSuivies, setMesuresSuivies] = useState([]);

  //GET récupération des mesures du patient
  useEffect(() => {
    if (patient_id) {
      setMesuresSuivies([]);
      //NES récupération des mesures du patient
      getMesuresPatient(patient_id);
    }
    // eslint-disable-next-line
  }, [patient_id]);

  //USEEFFECT Filtrer les mesures suivies
  useEffect(() => {
    let tempArray = mesuresPatient;
    tempArray = tempArray.filter((item) => item.is_bookmark);
    setMesuresSuivies(tempArray);
  }, [mesuresPatient]);

  if (mesuresSuivies.length > 0) {
    return (
      <div className={MonStyle.AffichageMesuresSuivies}>
        <div
          className="TitreBloc"
          style={{
            marginBottom: "10px",
            marginLeft: "10px",
          }}
        >
          Mesures suivies
        </div>
        <div className={MonStyle.Mesures}>
          {mesuresSuivies.map((mesure) => (
            <AffichageAncienneMesure
              mesure={mesure}
              taille="M"
              key={mesure.mesure_id}
              style={{ marginRight: "6px" }}
            />
          ))}
        </div>
      </div>
    );
  } else {
    return "";
  }
}
