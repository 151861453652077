import React from "react";

export default function ConditionsGenerales() {
  return (
    <div>
      <h5 style={{ marginBottom: "20px" }}>
        Condition générales d'utilisation
        <div className="SousTitre" style={{ marginBottom: "20px" }}>
          du 23 juin 2022
        </div>
        <hr />
        <div className="InformationPrincipale">
          Même si vous pouvez être tenté de ne pas lire les présentes conditions
          d'utilisation, il est important que vous preniez connaissance des
          bonnes règles d’utilisation du fait que vous manipulez des données
          personnelles de santé.
        </div>
        <hr style={{ marginBottom: "20px" }} />
      </h5>

      <section>
        <h3>Description</h3>
        <p>
          Les présentes conditions d’utilisation traduisent le fonctionnement de
          Sinobi et certains principes que nous avons toujours considérés comme
          importants. Elles contribuent à définir votre relation avec l’Atelier
          du Pixel lorsque vous interagissez avec nos outils. Il est essentiel
          de bien comprendre ces conditions, car vous devez obligatoirement les
          accepter pour utiliser nos services.
        </p>
        <p>
          Sinobi est développé par l’Atelier du Pixel <br /> Immatriculé à Nancy
          sous le numéro 901 628 370 R.C.S <br />
          domicilié au 8 rue du président Roosevelt 57940 YUTZ
        </p>
      </section>
      <section>
        <h3>Définitions</h3>
        <p>
          <strong>utilisateur :</strong> personne physique identifiée utilisant
          l’outil au travers des interfaces et/ou des API techniques.
          <br />
          <strong>outil :</strong> Désigne l’ensemble des composants techniques
          (interfaces, API, base de données, modules…) composant Sinobi.
          <br />
          <strong>données :</strong> Désigne les informations saisies
          manuellement par un utilisateur, ou automatiquement (ex: données de
          traçabilités) par l’outil.
          <br />
          <strong>données personnelles :</strong> Désigne toutes les
          informations propres à une personne physique, et /ou permettant de
          l’identifier.
        </p>
      </section>
      <section>
        <h3>Objet de Sinobi</h3>
        <p>
          Le but de Sinobi est de vous aider à gérer votre cabinet en vous
          permettant de prendre vos rendez-vous, de gérer vos dossiers patients,
          de suivre vos consultations, et plus globalement de vous fournir les
          informations nécessaires pour vous aider à suivre et soigner vos
          patients.
        </p>
      </section>
      <section>
        <h3>Identifiants de connexion</h3>
        <p>
          Vos identifiants de connexion sont strictement personnels, ils ne
          peuvent pas être prêtés (même temporairement) à un tiers. Ils nous
          servent à garantir et protéger l’accès aux données de vos patients,
          ainsi qu’à tracer toutes les actions réalisées dans le logiciel.
        </p>
      </section>
      <section>
        <h3>Sécurisation des données</h3>
        <p>
          Nous hébergeons vos données médicales dans des environnements agréés
          hébergement des données de santé. Toutes les données sont stockées
          dans des environnements sécurisés et/ou cryptés selon la
          classification données/données personnelles. Afin de garantir la
          sécurité de ces données, nous vous rappelons que vous ne devez pas
          stocker ces données sur votre poste, ni les communiquer par des canaux
          non sécurisés.
        </p>
      </section>
      <section>
        <h3>Données personnelles</h3>
        <p>
          Vos données personnelles sont stockées et utilisées conformément à
          notre déclaration RGPD. Pour rappel, nous n’utilisons vos données
          uniquement pour vous fournir le service lié à Sinobi. Vos données ne
          sont ni partagées, ni vendues.
        </p>
      </section>
      <section>
        <h3>Respecter les autres en particulier à propos des commentaires</h3>
        <p>
          Nous vous encourageons à rester respectueux des personnes, en
          particulier dans les zones de commentaires. Les informations
          collectées sur les personnes doivent être adéquates, pertinentes et
          non excessives. Les commentaires ne doivent donc pas être
          inappropriés, subjectifs et insultants. Voir les recommandation de la
          CNIL sur les commentaires
        </p>
      </section>
      <section>
        <h3>Responsabilité de l’éditeur</h3>
        <p>
          Nous construisons l’outil avec le plus grand soin et avec
          professionnalisme, pour vous fournir l’outil le plus adapté à vos
          besoins. Nous assurons la mise à disponibilité de l’outil et son bon
          fonctionnement. Mais nous ne pouvons être tenu responsable des soins
          et actes pratiqués vis à vis de vos patients sur la base des
          informations présentés par l’outil. Par ailleurs, nous mettons un
          focus important sur la sécurité des données, nous ne pouvons pas être
          tenu responsable en cas de fuites de données liées à une mauvaise
          utilisation ou une utilisation malveillante de votre part.
        </p>
      </section>
      <section>
        <h3>Responsabilité de l’utilisateur</h3>
        <p>
          Il est de la responsabilité de l’utilisateur de collecter uniquement
          les données nécessaires pour la mission à remplir vis-à-vis de son
          patient. De la même manière, l’utilisateur doit tout mettre en œuvre
          pour garantir la sécurité et la confidentialité des informations
          collectées sur ses patients.
        </p>
      </section>
      <section>
        <h3>Sanctions en cas de non respect des conditions d’utilisations</h3>
        <p>
          En cas de non-respect des présentes conditions d’utilisation, il est
          possible d’aller jusqu’à l’exclusion d’un utilisateur.
        </p>
      </section>
      <section>
        <h3>Accès à Sinobi et disponibilité</h3>
        <p>
          L’accès à Sinobi est réputé disponible 7j/7 et 24h/24. L’utilisation
          de l’outil est accessible à tout utilisateur abonné à Sinobi et
          disposant d'un accès internet. Il est seul responsable du bon
          fonctionnement de son équipement informatique ainsi que de son accès
          internet. Par ailleurs, nous nous réservons la possibilité
          d’interrompre le service pour des besoins impérieux de service: mise à
          jour de l’application, modification technique, protection des données
          en cas d’attaque… L’Atelier du Pixel n'est tenu à aucune obligation de
          résultat concernant l'accessibilité au Site, et n'est en aucun cas
          responsable des interruptions et des conséquences qui peuvent en
          découler.
        </p>
      </section>
      <section>
        <h3>Modification des CGU</h3>
        <p>
          En cas de modification des conditions générales d’utilisation, vous
          serez invité à accepter la nouvelle version sous peine de ne pas
          pouvoir accéder à l’outil.
        </p>
      </section>
    </div>
  );
}
