import React, { useEffect, useState } from "react";
import MonStyle from "./BlocTypeRdv.module.css";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import BoutonRond from "../../../../../Design/Boutons/BoutonRond";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";

export default function BlocTypeRdv({
  type_rendez_vous,
  duree,
  onDureeChange,
  onClick,
}) {
  //NES gestion du state
  const [monTypeRdv, setMonTypeRdv] = useState({});

  useEffect(() => {
    if (type_rendez_vous) {
      setMonTypeRdv(type_rendez_vous);
    }
  }, [type_rendez_vous]);

  return (
    <div className={MonStyle.BlocTypeRdv}>
      <div className={MonStyle.LibelleTypeRdv} onClick={onClick}>
        <div
          //className={MonStyle.IconeRdv}
          className="IconeObjet"
          onClick={onClick}
        >
          <div
            style={{
              width: "24px",
              height: "24px",
              backgroundColor: monTypeRdv.couleur,
              borderRadius: "4px",
            }}
          ></div>
        </div>
        {monTypeRdv.libelle}
      </div>
      <div className={MonStyle.Choix}>
        <Select
          id="rdv_duree"
          disableUnderline={true}
          style={{ color: "#208AB0" }}
          value={duree}
          onChange={onDureeChange}
        >
          <MenuItem value="0:10">10min</MenuItem>
          <MenuItem value="0:15">15min</MenuItem>
          <MenuItem value="0:20">20min</MenuItem>
          <MenuItem value="0:25">25min</MenuItem>
          <MenuItem value="0:30">30min</MenuItem>
          <MenuItem value="0:35">35min</MenuItem>
          <MenuItem value="0:40">40min</MenuItem>
          <MenuItem value="0:45">45min</MenuItem>
          <MenuItem value="0:50">50min</MenuItem>
          <MenuItem value="0:55">55min</MenuItem>
          <MenuItem value="1:00">1h00</MenuItem>
          <MenuItem value="1:15">1h15</MenuItem>
          <MenuItem value="1:30">1h30</MenuItem>
          <MenuItem value="1:45">1h45</MenuItem>
          <MenuItem value="2:00">2h00</MenuItem>
        </Select>
      </div>

      <BoutonRond
        iconeComposant={<NavigateNextIcon />}
        onClick={onClick}
        tooltip="Changer le type de rdv"
      />
    </div>
  );
}
