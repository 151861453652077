import React, { useContext } from "react";
import { HashLink as Link } from "react-router-hash-link";
import { DocumentsContext } from "../../../../Data/DocumentsContext";
import { useState } from "react";
import { useEffect } from "react";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import { pluriel } from "../../../../functions/Formatter";
import Grow from "@mui/material/Grow";

export default function SyntheseDocuments({ consultation }) {
  const style = {
    border: "none",
    color: "#205A71",
    backgroundColor: "#205A7120",
    minHeight: "25px",
    borderRadius: "20px",
    padding: "2px 10px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "14px",
    fontWeight: "500",
    minWidth: "40px",
    gap: "5px",
  };

  //NES gestion du contexte
  const { documents } = useContext(DocumentsContext);

  //NES gestion du contexte
  const [documentsConsultation, setDocucumentsConsultation] = useState([]);

  //USEEFFECT
  useEffect(() => {
    if (
      consultation.patient_id !== undefined &&
      consultation._id !== undefined
    ) {
      let listeDocuments = documents.filter(
        (item) => item.consultation_id === consultation._id
      );
      setDocucumentsConsultation(listeDocuments);
    }
    // eslint-disable-next-line
  }, [consultation?._id, documents]);

  //NES je n'affiche rien si je n'ai pas de documents
  if (documentsConsultation.length === 0) {
    return "";
  }

  return (
    <Grow in={documentsConsultation.length ? true : false}>
      <div>
        <Link to="#PiecesJointes" smooth style={style}>
          <AttachFileIcon />
          {pluriel(documentsConsultation.length, "document", "documents")}
        </Link>
      </div>
    </Grow>
  );
}
