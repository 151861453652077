import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import PageLogin from "../Pages/Login/PageLogin";
import PageInscription from "../Pages/Login/Pages/PageInscription";
import PageVerification from "../Pages/Login/Pages/PageVerification";
import PageMotdepassePerdu from "../Pages/Login/Pages/PageMotdepassePerdu";
import PageRgpd from "../Pages/RGPD/PageRgpd";

export default function RoutesPubliques() {
  return (
    <Router>
      <Switch>
        <Route path="/recoverPassword/:hash" component={PageMotdepassePerdu} />
        <Route path="/recoverPassword" exact component={PageMotdepassePerdu} />
        <Route path="/" exact component={PageLogin} />
        <Route path="/inscription/verification" component={PageVerification} />

        <Route path="/inscription" component={PageInscription} />
        <Route path="/rgpd" component={PageRgpd} />

        <Route path="*" exact component={PageLogin} />
      </Switch>
    </Router>
  );
}
