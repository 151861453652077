import React, { useState, useEffect, useContext } from "react";
import MonStyle from "./EditionRemise.module.css";
import SurfacePopUp from "../../../../Design/Surfaces/SurfacePopUp";
import InputNombres from "../../../../Design/Formulaires/InputNombres";
import OngletsBoutons from "../../../../Design/DataDisplay/OngletsBoutons";
import BoutonActionPrincipale from "../../../../Design/Boutons/BoutonActionPrincipale";
import BoutonAnnuler from "../../../../Design/Boutons/BoutonAnnuler";
import MessageErreur from "../../../../Design/Messages/MessageErreur";
import { CalculHt } from "../../../../functions/PrixDeVente";
import { CotationsContext } from "../../../../Data/CotationsContext";

export default function EditionRemise({ cotation, index, onClose }) {
  //NES gestion du state
  const [typeRemise, setTypeRemise] = useState("€");
  const [montantRemise, setMontantRemise] = useState("");
  const [data, setData] = useState({});
  const [messageErreur, setMessageErreur] = useState("");
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  //NES Gestion du context
  const { patchCotation } = useContext(CotationsContext);

  //USEEFFECT
  useEffect(() => {
    if (cotation && index !== undefined) {
      let tempData = cotation.prestations[index];
      if (tempData) {
        setData(tempData);
        //NES j'initilise le montant de ma remise
        if (tempData.montant_remise) {
          setMontantRemise(tempData.montant_remise);
          setTypeRemise("€");
        }
        if (tempData.pourcentage_remise) {
          setMontantRemise(tempData.pourcentage_remise);
          setTypeRemise("%");
        }
      }
    }
    // eslint-disable-next-line
  }, [index]);

  //NES quand je change le montant de la remise
  const handleEditionRemise = (event) => {
    let montant = event.target.value;
    setMontantRemise(montant);
  };

  //USEEFFECT
  useEffect(() => {
    //NES je créé un objet temporaire
    let tempData = { ...data };

    //NES calcul du prix de vente net TTC
    let prixNetTtc = tempData.prestation_total_ttc_net;

    //NES calcul du montant Net ttc
    if (typeRemise === "%") {
      //NES gestion d'une remise supérieure à 100%
      if (montantRemise > 100) {
        setMessage("Le montant est supérieur à 100%");
      } else {
        setMessage("");
        //NES calcul prix net
        prixNetTtc =
          tempData.prix_vente_ttc_unitaire_brut * (1 - montantRemise / 100);
        prixNetTtc = prixNetTtc.toFixed(2);
        prixNetTtc = parseFloat(prixNetTtc);
        tempData = {
          ...tempData,
          montant_remise: "",
          pourcentage_remise: parseFloat(montantRemise),
          prestation_total_ttc_net: prixNetTtc,
          prestation_part_patient: prixNetTtc,
        };
      }
    }
    if (typeRemise === "€") {
      //NES gestion d'une remise supérieure au montant de la prestation
      if (montantRemise > tempData.prix_vente_ttc_unitaire_brut) {
        setMessage(
          "Le montant est supérieur à " +
            tempData.prix_vente_ttc_unitaire_brut +
            " €"
        );
      } else {
        setMessage("");
        //NES calcul prix net
        prixNetTtc = tempData.prix_vente_ttc_unitaire_brut - montantRemise;
        prixNetTtc = prixNetTtc.toFixed(2);
        prixNetTtc = parseFloat(prixNetTtc);

        tempData = {
          ...tempData,
          pourcentage_remise: "",
          montant_remise: parseFloat(montantRemise),
          prestation_total_ttc_net: prixNetTtc,
          prestation_part_patient: prixNetTtc,
        };
      }
    }

    //NES calcul HT
    let montantNetHt = CalculHt(prixNetTtc, tempData.taux_tva);
    montantNetHt = parseFloat(montantNetHt);
    tempData = {
      ...tempData,
      prestation_total_ht_net: montantNetHt,
    };

    //NES calcul de la TVA
    let montantTvaNet = prixNetTtc - montantNetHt;
    montantTvaNet = montantTvaNet.toFixed(2);
    montantTvaNet = parseFloat(montantTvaNet);
    tempData = {
      ...tempData,
      prestation_total_tva_net: montantTvaNet,
    };

    setData(tempData);
    // eslint-disable-next-line
  }, [montantRemise, typeRemise]);

  //PATCH Cotations
  const handleEnregistrer = () => {
    //NES je mets à jour le tableau des prestations avec ma prestations remisée
    let newPrestations = cotation.prestations;
    newPrestations.splice(index, 1, data);
    //NES je reconstruis ma cotation
    let newCotation = { ...cotation, prestations: newPrestations };
    patchCotation(
      cotation.patient_id,
      cotation.consultation_id,
      cotation._id,
      newCotation
    )
      .then((reponse) => {
        setIsLoading(false);
        if (reponse.code === "OK") {
          if (onClose) {
            onClose();
          }
        } else {
          setMessageErreur("Erreur lors de l'enregistrement de la remise");
        }
      })
      .catch((error) => {
        setIsLoading(false);
        setMessageErreur("Erreur lors de l'enregistrement de la remise");
        setError(error);
      });
  };

  return (
    <SurfacePopUp open={index >= 0 ? true : false} onClose={onClose}>
      <div className={MonStyle.EditionRemise}>
        <div className="TitrePop">Saisir une remise</div>
      </div>
      <main>
        <div className={MonStyle.Formulaire}>
          <div className={MonStyle.Champ}>
            <InputNombres
              placeholder={"Remise en " + typeRemise}
              className={MonStyle.Champ}
              style={{ marginTop: "0px" }}
              value={montantRemise}
              onChange={handleEditionRemise}
              autoFocus
              isErreur={message}
            />
          </div>
          <div className={MonStyle.Champ}>
            <OngletsBoutons
              onglets={["€", "%"]}
              ongletSelectionne={typeRemise}
              onSelect={(value) => setTypeRemise(value)}
            />
          </div>
        </div>
        <div className={MonStyle.Erreur}>{message}</div>
        <div className={MonStyle.Detail}>
          <div className={MonStyle.Prestation}>{data.nom}</div>
          <div className={MonStyle.Label}>
            Montant avant remise :{" "}
            <span className={MonStyle.Montant}>
              {data.prix_vente_ttc_unitaire_brut} €
            </span>
          </div>
          <div className={MonStyle.Label}>
            Montant après remise :{" "}
            <span className={MonStyle.Montant}>
              {data.prestation_total_ttc_net} €
            </span>
          </div>
        </div>

        <MessageErreur message={messageErreur} error={error} />
        <BoutonActionPrincipale
          texte={
            montantRemise
              ? "Remise de " + montantRemise + " " + typeRemise
              : "Enregistrer"
          }
          fullWidth
          style={{ margin: "20px 0px 10px 0px" }}
          taille="L"
          onClick={handleEnregistrer}
          isLoading={isLoading}
          disabled={message}
        />
        <BoutonAnnuler fullWidth />
      </main>
    </SurfacePopUp>
  );
}
