import React from "react";
import MonStyle from "./SurfaceZoneSecondaire.module.css";

export default function SurfaceZoneSecondaire({
  style,
  illustration,
  id,
  ...props
}) {
  return (
    <div className={MonStyle.SurfaceZoneSecondaire} id={id} style={style}>
      {illustration && (
        <img className={MonStyle.IconeIllustration} src={illustration} alt="" />
      )}
      <div className={MonStyle.Children}>{props.children}</div>
    </div>
  );
}
