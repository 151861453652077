import Tooltip from "../DataDisplay/ToolTip";
import React from "react";
import IconeInconnu from "../../Images/Icones/Inconnu.svg";

import IconeHomme from "../../Images/Icones/Homme.svg";
import IconeBebeHomme from "../../Images/Icones/BebeHomme.svg";
import IconeJuniorHomme from "../../Images/Icones/JuniorHomme.svg";
import IconeSeniorHomme from "../../Images/Icones/SeniorHomme.svg";

import IconeFemme from "../../Images/Icones/Femme.svg";
import IconeBebeFemme from "../../Images/Icones/BebeFemme.svg";
import IconeJuniorFemme from "../../Images/Icones/JuniorFemme.svg";
import IconeSeniorFemme from "../../Images/Icones/SeniorFemme.svg";

export default function ObjetIconePatient({
  age,
  sexe,
  taille,
  id,
  iconeOpacity,
}) {
  let IconePatient = IconeInconnu;
  let TooltipAge = "Sexe non renseigné";

  switch (sexe) {
    case "Homme":
      IconePatient = IconeHomme;
      TooltipAge = "Homme";
      if (age) {
        if (age.unite === "jours" || age.unite === "mois") {
          IconePatient = IconeBebeHomme;
          TooltipAge = "Bébé <2ans";
        }
        if (age.nombre >= 60 && age.unite === "ans") {
          IconePatient = IconeSeniorHomme;
          TooltipAge = "Senior >60ans";
        }
        if (age.nombre <= 16 && age.nombre >= 2 && age.unite === "ans") {
          IconePatient = IconeJuniorHomme;
          TooltipAge = "Junior <16ans";
        }
      }
      break;
    case "Femme":
      TooltipAge = "Femme";
      IconePatient = IconeFemme;
      if (age) {
        if (age.unite === "jours" || age.unite === "mois") {
          IconePatient = IconeBebeFemme;
          TooltipAge = "Bébé <2ans";
        }
        if (age.nombre >= 60 && age.unite === "ans") {
          IconePatient = IconeSeniorFemme;
          TooltipAge = "Senior >60ans";
        }
        if (age.nombre <= 16 && age.nombre >= 2 && age.unite === "ans") {
          IconePatient = IconeJuniorFemme;
          TooltipAge = "Junior <16ans";
        }
      }
      break;
    default:
      IconePatient = IconeInconnu;
  }

  return (
    <Tooltip texte={TooltipAge}>
      <img
        src={IconePatient}
        style={{
          height: taille === "L" ? "65px" : "24px",
          opacity: iconeOpacity,
        }}
        alt={TooltipAge}
        id={id}
      />
    </Tooltip>
  );
}
