import React, { useEffect, useState, useContext } from "react";
import BoutonRond from "../../../../Design/Boutons/BoutonRond";
import InputNombres from "../../../../Design/Formulaires/InputNombres";
import ObjetReglement from "../../../../Design/Objets/ObjetReglement";
import BoutonReglement from "../BoutonsReglement/BoutonsReglement";
import MonStyle from "./EditionReglement.module.css";
import CancelIcon from "@material-ui/icons/Cancel";
import MessageErreur from "../../../../Design/Messages/MessageErreur";
import BoutonActionPrincipale from "../../../../Design/Boutons/BoutonActionPrincipale";
import { PatientsContext } from "../../../../Data/PatientsContext";
import { CabinetsContext } from "../../../../Data/CabinetContext";
import { CotationsContext } from "../../../../Data/CotationsContext";

export default function EditionReglement({ facture, consultation }) {
  //NES Gestion du contexte
  const { getObjetPatient } = useContext(PatientsContext);
  const { getObjetCabinet } = useContext(CabinetsContext);
  const { postReglement } = useContext(CotationsContext);

  //NES gestion du state
  // eslint-disable-next-line
  const [montantARegler, setMontantARegler] = useState(0);
  const [montantReglement, setMontantReglement] = useState(0);
  const [data, setData] = useState([]);
  const [messageErreur, setMessageErreur] = useState("");
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState(false);

  //USEEFFECT quel est le montant à payer?
  useEffect(() => {
    if (facture.part_patient) {
      //NES Montant à payer
      setMontantARegler(facture.part_patient.montant_a_regler);
      let tempMontantReglement = facture.part_patient.montant_a_regler;
      if (facture.part_patient.montant_regle) {
        tempMontantReglement =
          tempMontantReglement - facture.part_patient.montant_regle;
      }
      setMontantReglement(tempMontantReglement);
    }
  }, [facture]);

  //NES quand je change manuellement le montant du règlement
  const handleChangeMontant = (event) => {
    if (event.target.value) {
      let montant = parseFloat(event.target.value);
      setMontantReglement(montant);
      //NES controle que l'on ne paye pas plus que le montant de la facture
      if (montant > montantARegler) {
        setMessageErreur(
          "Le montant à régler ne peut pas être supérieur à " +
            montantARegler +
            " €"
        );
      } else {
        setMessageErreur("");
      }
    }
  };

  //NES Quand je clique sur un mode de réglement
  const handleClicModeReglement = (
    libelle_mode_reglement,
    code_mode_reglement
  ) => {
    //NES je crée le détail des cotations réglées par le montant à partir de la facture
    let listeCotationsReglees = [];

    facture.cotations.forEach((cotation) => {
      let ligne = {
        cotation_id: cotation._id,
        cotation_date: cotation.date_cotation,
        cotation_montant: cotation.total_montant_ttc_net,
        cotation_patient: facture.patient,
        cotation_montant_reglement: 0,
      };
      listeCotationsReglees.push(ligne);
    });

    //NES je vais créer un règlement
    let newData = {
      facture_id: facture._id,
      date: new Date(),
      montant: montantReglement,
      libelle_mode_reglement: libelle_mode_reglement,
      code_mode_reglement: code_mode_reglement,
      libelle_type_reglement: "Règlement",
      code_type_reglement: "REGLEMENT",
      patient: getObjetPatient(facture.patient_id),
      cabinet: getObjetCabinet(),
      commentaire: "",
      cotations: listeCotationsReglees,
    };
    let tempData = [...data];
    tempData.push(newData);

    //NES je mets à jour le montant à régler
    let newMontantRestantARegler = montantARegler - montantReglement;
    setMontantARegler(newMontantRestantARegler);
    setMontantReglement(newMontantRestantARegler);
    setData(tempData);
  };

  //NES quand on supprime un règlement
  const deleteReglement = (index) => {
    //NES reglement à annuler
    let reglementAnnuler = data[index];

    //NES je supprime  le règlement
    let tempArray = [...data];
    tempArray.splice(index, 1);
    setData(tempArray);

    //NES je mets à jour le montant global à régler
    let newMontantRestantARegler =
      montantARegler + parseFloat(reglementAnnuler.montant);
    setMontantARegler(newMontantRestantARegler);
    setMontantReglement(newMontantRestantARegler);
  };

  //POST Enregietrement du règlement
  const enregistrerReglement = () => {
    setIsLoading(true);
    postReglement(data)
      .then((reponse) => {
        setIsLoading(false);
        if (reponse.code === "OK") {
          //NES j'efface le découpage du règlement
          setData([]);
        } else {
          setMessageErreur("Erreur lors de l'enregistrement");
        }
      })
      .catch((error) => {
        setMessageErreur("Erreur lors de l'enregistrement");
        setError(error);
      });
  };

  return (
    <div className={MonStyle.EditionReglement}>
      <div className="TitreBloc">Règlement</div>
      <div className={MonStyle.BlocReglement}>
        <div className="SousTitre" style={{ marginBottom: "10px" }}>
          Vous pouvez modifier le montant pour régler en plusieurs fois et/ou
          plusieurs modes de règlements
        </div>

        <div className={MonStyle.MontantEtBoutons}>
          <div className={MonStyle.Montant}>
            <div className="TitreBloc">Montant à regler </div>
            <InputNombres
              //textAlign="center"
              isDecimal
              theme="lecture"
              style={{
                textAlign: "center",
                fontSize: "30px",
                width: "80px",
              }}
              onChange={handleChangeMontant}
              value={montantReglement}
              isErreur={messageErreur ? "Montant" : ""}
              endAdornment="€"
            />
          </div>
          <div className={MonStyle.Boutons}>
            <BoutonReglement
              label="Carte bancaire"
              code="CARTE_BANCAIRE"
              disabled={messageErreur || montantReglement === 0 ? true : false}
              onClick={() =>
                handleClicModeReglement("Carte bancaire", "CARTE_BANCAIRE")
              }
            />
            <BoutonReglement
              disabled={messageErreur || montantReglement === 0 ? true : false}
              label="Chèque"
              code="CHEQUE"
              onClick={() => handleClicModeReglement("Chèque", "CHEQUE")}
            />
            <BoutonReglement
              disabled={messageErreur || montantReglement === 0 ? true : false}
              label="Espèces"
              code="ESPECES"
              onClick={() => handleClicModeReglement("Espèces", "ESPECES")}
            />
            <BoutonReglement
              disabled={messageErreur || montantReglement === 0 ? true : false}
              label="Virement"
              code="VIREMENT"
              onClick={() => handleClicModeReglement("Virement", "VIREMENT")}
            />
          </div>
        </div>

        <MessageErreur
          message={messageErreur}
          error={error}
          style={{ marginBottom: "20px" }}
        />

        <div>
          {data.map((reglement, index) => (
            <div className={MonStyle.LigneReglement} key={index}>
              <ObjetReglement reglement={reglement} />
              <BoutonRond
                iconeComposant={<CancelIcon />}
                onClick={() => deleteReglement(index)}
                style={{ marginLeft: " 10px" }}
                tooltip="Annuler"
              />
            </div>
          ))}
        </div>

        <BoutonActionPrincipale
          fullWidth
          taille="L"
          texte="Enregistrer"
          style={{ marginTop: "20px" }}
          onClick={enregistrerReglement}
          disabled={data.length === 0}
          isLoading={isLoading}
        />
      </div>
    </div>
  );
}
