import React from "react";
import { TagRole } from "../../../../Design/DataDisplay/Tags";
import MonStyle from "./LigneAbonnement.module.css";
import ObjetPrix from "../../../../Design/Objets/ObjetPrix";

export default function LigneAbonnementUtilisateur({ ligne }) {
  return (
    <div className={MonStyle.Ligne}>
      <div className={MonStyle.Utilisateur}>
        <div>{ligne.nom}</div>
      </div>
      <div className={MonStyle.Role}>
        <TagRole code_role={ligne.tarif.code_role} />
      </div>

      <div className={MonStyle.Tarif}>
        <ObjetPrix
          prix={ligne.tarif.tarif_unitaire_ttc}
          complement={" / mois"}
        />
      </div>
    </div>
  );
}
