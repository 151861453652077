import React, { useState, useContext, useEffect } from "react";
import MonStyle from "./EditionTypeRdv.module.css";
import { AgendasContext } from "../../../../../Data/AgendasContext";
import ObjetTypeRdv from "../../../../../Design/Objets/ObjetTypeRdv";
import ListItem from "@material-ui/core/ListItem";

export default function EditionTypeRdv({ agenda_id, onSelect }) {
  //NES récupération du contexte
  const { agendaById } = useContext(AgendasContext);

  //NES gestion du state
  const [typesRdv, setTypeRdv] = useState([]);

  //NES sélection du type de rdv
  const handleSelectType = (typeRdv) => {
    if (onSelect) {
      onSelect(typeRdv);
    }
  };

  useEffect(() => {
    if (agenda_id) {
      let agenda = agendaById(agenda_id);
      setTypeRdv(agenda.types_rendez_vous);
    }
    // eslint-disable-next-line
  }, [agenda_id]);

  return (
    <div className={MonStyle.EditionTypeRdv}>
      <div className="TitreBloc">Sélectionnez le type de rendez-vous</div>

      <div
        //autoFocusItem={focus}
        id="menu-suggestion"
        //onKeyDown={handleKeyDownSuggestions}
        style={{
          maxHeight: "450px",
          overflow: "auto",
          marginTop: "10px",
          // border: "1px solid red",
        }}
      >
        {typesRdv.map((type) => (
          <ListItem
            button
            dense
            id={type._id}
            key={type._id}
            onClick={() => handleSelectType(type)}
            disableGutters={true}
            className={MonStyle.TypeRdv}
          >
            <ObjetTypeRdv
              libelle={type.libelle}
              couleur={type.couleur}
              id={type._id}
              duree={type.duree}
              style={{ height: "40px" }}
            />
          </ListItem>
        ))}
      </div>
    </div>
  );
}
