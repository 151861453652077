import React from "react";
import { Document, Page, Text, View, StyleSheet } from "@react-pdf/renderer";
import DocumentPdf from "../DocumentPdf";
import { StylesDocuments } from "../StylesDocumentsPdf";
import { DateToString } from "../../functions/FonctionsDateHeure";
import { numberToEuro, truncateHead } from "../../functions/Formatter";

export default function DocumentAdpxFacture({ facture, onClose }) {
  //NES Gestion du style
  const Styles = StyleSheet.create(StylesDocuments);

  if (!facture) {
    return "";
  }

  return (
    <DocumentPdf
      openDocumentPdf={facture ? true : false}
      onClose={onClose}
      autoPrint={false}
      nomFichier={
        process.env.REACT_APP_NOM +
        " - Facture n° " +
        facture.numero_facture +
        " - " +
        DateToString(facture.date_facture, {
          year: "numeric",
          month: "long",
          day: "numeric",
        })
      }
    >
      <Document>
        <Page size="A4" style={Styles.page}>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              border: "0px solid black",
            }}
          >
            {/* ATELIER DU PIXEL */}
            <div style={{ border: "0px solid red", width: "50%" }}>
              <Text style={Styles.titreBloc}>
                {facture.entite_juridique?.nom}
              </Text>
              <Text style={Styles.informationSecondaire}>
                {facture.entite_juridique?.adresse}
              </Text>
              <Text style={Styles.informationSecondaire}>
                {facture.entite_juridique?.email}
              </Text>
            </div>
            {/* ENTETE FACTURE */}
            <div
              style={{
                border: "0px solid red",
                width: "50%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Text style={Styles.titre}>FACTURE</Text>
              <Text style={Styles.informationPrincipale}>
                n°{facture.numero_facture}
              </Text>
              <Text style={Styles.informationPrincipale}>
                {DateToString(facture.date_facture, {
                  year: "numeric",
                  month: "numeric",
                  day: "numeric",
                })}
              </Text>
            </div>
          </View>
          <View
            style={{
              border: "0px solid red",
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              margin: "20px 0px",
            }}
          >
            <div
              style={{
                backgroundColor: "#EEEEEE",
                borderRadius: "8px",
                minHeight: "40px",
                display: "flex",
                flexDirection: "column",
                flexWrap: "wrap",
                alignItems: "flex-start",
                justifyContent: "center",
                minWidth: "200px",
                width: "50%",
                padding: "10px",
              }}
            >
              {/* INFORMATION ORGANISATION */}
              <Text style={Styles.titreBloc}>{facture.organisation_nom}</Text>
              <Text>{facture?.adresse_facture?.rue}</Text>
              <Text>
                {facture?.adresse_facture?.code_postal}{" "}
                {facture?.adresse_facture?.ville}
              </Text>
              <Text>{facture?.adresse_facture?.complement}</Text>
              <Text>{facture?.adresse_facture?.pays} </Text>
            </div>
          </View>

          <View
            style={{
              height: "20px",
              color: "white",
              backgroundColor: "black",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              paddingLeft: "10px",
              fontWeight: "bold",
            }}
          >
            {/* ENTETES TITRE ABONNEMENT */}

            <Text>Abonnement SINOBI </Text>
            <Text>
              {" du " +
                DateToString(facture?.periode_abonnement_date_debut, {
                  year: "numeric",
                  month: "numeric",
                  day: "numeric",
                }) +
                " au " +
                DateToString(facture?.periode_abonnement_date_fin, {
                  year: "numeric",
                  month: "numeric",
                  day: "numeric",
                })}
            </Text>
          </View>

          <View>
            {/* ENTETES DE COLONNES */}
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                height: "30px",
                borderBottom: "1px solid #EEEEEE",
                paddingLeft: "10px",
                paddingRight: "10px",
              }}
            >
              <Text style={[Styles.titreColonneLeft, { width: "230px" }]}>
                Prestations
              </Text>
              <Text style={[Styles.titreColonneRight, { width: "40px" }]}>
                Qte
              </Text>
              <Text style={[Styles.titreColonneRight, { width: "60px" }]}>
                Montant HT
              </Text>

              <Text style={[Styles.titreColonneRight, { width: "40px" }]}>
                TVA
              </Text>
              <Text style={[Styles.titreColonneRight, { width: "60px" }]}>
                Montant TTC
              </Text>
            </div>
            {/* LIGNES D'ABONNEMENT */}
            {facture.lignes.map((ligne, index) => (
              <View
                key={index}
                wrap={false}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  height: "30px",
                  borderBottom: "1px solid #EEEEEE",
                  paddingLeft: "10px",
                  paddingRight: "10px",
                }}
              >
                <Text style={[Styles.normal, { width: "230px" }]}>
                  {ligne.nom}

                  {ligne?.tarif?.type_tarif === "SMS" && (
                    <Text style={Styles.informationSecondaire}>
                      {" du " +
                        DateToString(facture?.periode_sms_date_debut, {
                          year: "numeric",
                          month: "numeric",
                          day: "numeric",
                        }) +
                        " au " +
                        DateToString(facture?.periode_sms_date_fin, {
                          year: "numeric",
                          month: "numeric",
                          day: "numeric",
                        })}
                    </Text>
                  )}
                </Text>

                <Text
                  style={[Styles.informationSecondaireRight, { width: "40px" }]}
                >
                  {ligne.quantite}
                </Text>
                <Text
                  style={[Styles.informationSecondaireRight, { width: "60px" }]}
                >
                  {numberToEuro(ligne.montant_ht_net)}
                </Text>
                <Text
                  style={[Styles.informationSecondaireRight, { width: "40px" }]}
                >
                  {ligne.tarif?.tva_taux}%
                </Text>
                <Text style={[Styles.normalRight, { width: "60px" }]}>
                  {numberToEuro(ligne.montant_ttc_net, " €")}
                </Text>
              </View>
            ))}
          </View>

          {/* TOTAL*/}
          <View style={{ borderTop: "1px solid black", paddingTop: "10px" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                border: "0px solid blue",
                height: "15px",
              }}
            >
              <Text style={[Styles.normalRight, { width: "100%" }]}>
                Total HT :{" "}
              </Text>
              <Text style={[Styles.normalRight, { width: "100px" }]}>
                {numberToEuro(facture.total_montant_ht, " €")}
              </Text>
            </div>

            {facture?.total_montant_tva.map((tva) => (
              <div
                key={tva.taux}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  border: "0px solid blue",
                  height: "15px",
                }}
              >
                <Text style={[Styles.normalRight, { width: "100%" }]}>
                  Total TVA {tva.taux}%
                </Text>
                <Text style={[Styles.normalRight, { width: "100px" }]}>
                  {numberToEuro(tva.montant, " €")}
                </Text>
              </div>
            ))}

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                border: "0px solid blue",
                height: "15px",
              }}
            >
              <Text
                style={[Styles.informationPrincipaleRight, { width: "100%" }]}
              >
                TOTAL TTC
              </Text>
              <Text
                style={[Styles.informationPrincipaleRight, { width: "100px" }]}
              >
                {numberToEuro(facture.total_montant_ttc, " €")}
              </Text>
            </div>
          </View>
          {/* REGLEMENT*/}
          <View>
            <Text
              style={[
                Styles.informationPrincipaleRight,
                {
                  marginTop: "20px",
                  border: "0px solid black",
                  textAlign: "right",
                },
              ]}
            >
              Réglée le{" "}
              {DateToString(facture.reglement.date_reglement, {
                year: "numeric",
                month: "numeric",
                day: "numeric",
              })}{" "}
              par carte bancaire pour un montant de{" "}
              {numberToEuro(
                facture.reglement.montant_reglement_centimes,
                " €",
                true
              )}
            </Text>
          </View>
          {/* REGLEMENT*/}
          <View>
            <Text
              style={[
                Styles.informationJuridiques,
                {
                  marginTop: "20px",
                  border: "0px solid pink",
                  textAlign: "right",
                },
              ]}
            >
              Clé de sécurité :{" "}
              {truncateHead(facture?.securisation?.cle_calcule)}
            </Text>
          </View>
          {/* TEXTE LIBRE */}
          <View>
            <Text>{facture?.mentions?.texte_libre}</Text>
          </View>

          {/* PIED DE PAGE */}
          <View
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              alignItems: "center",
              fontSize: "8px",
              position: "absolute",
              bottom: "10mm",
              left: "10mm",
              paddingTop: "5px",
            }}
            fixed
          >
            <Text>{facture?.mentions?.informatique_et_liberte}</Text>
            <Text>{facture?.mentions?.rgpd}</Text>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                fontSize: "8px",
                borderTop: "1px solid black",
                paddingTop: "5px",
                marginTop: "5px",
              }}
            >
              <Text>
                {facture?.entite_juridique?.nom +
                  ", " +
                  facture?.entite_juridique?.description +
                  " | " +
                  facture?.entite_juridique?.numero_tva +
                  " | RCS :  " +
                  facture?.entite_juridique?.rcs}
              </Text>
              <Text
                style={{
                  fontSize: "8px",
                  fontWeight: "bold",
                }}
                render={({ pageNumber, totalPages }) =>
                  `Page ${pageNumber} / ${totalPages}`
                }
              />
            </div>
          </View>
        </Page>
      </Document>
    </DocumentPdf>
  );
}
