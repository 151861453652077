import React, { useState, createContext, useContext } from "react";
import { API } from "../functions/FonctionsApi";
import { AuthentificationContext } from "./AuthentificationContext";

//NES Création du contexte
export const PerformancesContext = createContext();

export const PerformancesProvider = (props) => {
  //NES gestion du context
  const auth = useContext(AuthentificationContext);

  //NES gestion du state
  const [appelsApi, setAppelsApi] = useState([]);
  const [moyenne, setMoyenne] = useState(0);

  /// addAppel
  const addAppel = (url, method, reponse) => {
    setMoyenne((current) => {
      let nb = 1;
      let somme = reponse.duree_ms;
      appelsApi.forEach((element) => {
        nb += 1;
        somme += element.duree_ms;
      });
      let moyenne = somme / nb;
      return moyenne;
    });

    setAppelsApi((appelsApi) => {
      let newData = {
        time: new Date(),
        method: method,
        url: url,
        code: reponse?.code,
        duree_ms: reponse?.duree_ms,
        nb_data: reponse?.data?.length,
        status: reponse?.status,
      };
      let tempArray = appelsApi;
      tempArray.unshift(newData);
      return tempArray;
    });
  };

  //GET
  const GET = async (url) => {
    let reponseAPI = await API("GET", url, auth.token);
    //NES je conserve l'appel dans le contexte
    addAppel(url, "GET", reponseAPI);
    //NES je retourne la réponse
    return reponseAPI;
  };

  return (
    <PerformancesContext.Provider value={{ appelsApi, moyenne, GET }}>
      {props.children}
    </PerformancesContext.Provider>
  );
};
