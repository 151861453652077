import React, { useState, useContext, useEffect } from "react";
import MonStyle from "./AffichageFacture.module.css";
import SurfacePapier from "../../../../Design/Surfaces/SurfacePapier";
import BoutonRond from "../../../../Design/Boutons/BoutonRond";
import PrintIcon from "@material-ui/icons/Print";
import { DateToString } from "../../../../functions/FonctionsDateHeure";
import ObjetItem from "../../../../Design/Objets/ObjetItem";
import ObjetPrix from "../../../../Design/Objets/ObjetPrix";
import DocumentFacture from "../../../../Docs/Pages/DocumentFacture";
import ObjetTracabilite from "../../../../Design/Objets/ObjetTracabilite";
import AffichagePartPatient from "./AffichagePartPatient";
import EditionReglement from "./EditionReglement";
import AffichageReglements from "./AffichageReglements";
import { CotationsContext } from "../../../../Data/CotationsContext";
import AffichageConditionnel from "../../../../Design/DataDisplay/AffichageConditionnel";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import PopupAnnulationFacture from "./PopupAnnulationFacture";

export default function AffichageFacture({ facture }) {
  //NES Constantes
  const DATE_FORMAT = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  //NES Gestion du state
  const [openFactureId, setOpenFactureId] = useState();
  const [afficherReglements, setAfficherReglements] = useState(false);
  const [libelleAvoir, setLibelleAvoir] = useState("");
  const [isAvoir, setIsAvoir] = useState(false);
  const [isFactureAnnule, setIsFactureAnnule] = useState(false);
  const [afficherDetail, setAfficherDetail] = useState(true);
  const [afficherPopup, setAfficherPopup] = useState(false);

  //NES Gestion du contexte
  const { deleteFacture } = useContext(CotationsContext);

  //NES annuler la facture
  const handleSupprimerFacture = () => {
    if (facture.part_patient.montant_regle > 0) {
      //NES afficher pop-up confirmation rendu des règlements
      setAfficherPopup(true);
    } else {
      //NES je peux annuler "simplement" la facture
      annulerFacture();
    }
  };

  //DELETE
  const annulerFacture = () => {
    deleteFacture(facture._id);
  };

  //USEEFFECT affichage du fait que la facture est annulée
  useEffect(() => {
    if (facture) {
      if (facture.avoir_facture) {
        //NES je garde le fait que c'est une facture annulée
        setIsFactureAnnule(true);

        //NES construction du libelle de l'avoir
        let libelleAffiche =
          "Annulé par l'avoir n°" +
          facture.avoir_facture.numero_avoir +
          " du " +
          DateToString(facture.avoir_facture.date_avoir, DATE_FORMAT);
        setLibelleAvoir(libelleAffiche);

        //NES je masque le détail
        setAfficherDetail(false);
      }
      //NES je garde le fait que c'est un avoir
      if (facture.is_avoir) {
        setIsAvoir(true);
        //NES construction du libelle de la facture d'origine
        let libelleAffiche =
          "sur la facture n°" +
          facture.facture_origine.numero_facture +
          " du " +
          DateToString(facture.facture_origine.date_facture, DATE_FORMAT);
        setLibelleAvoir(libelleAffiche);
        //NES je masque le détail
        setAfficherDetail(false);
      }
    }
    // eslint-disable-next-line
  }, [facture]);

  return (
    <SurfacePapier
      style={{ marginTop: "20px" }}
      key={facture._id}
      id={"facture_" + facture._id}
    >
      <div className={MonStyle.EnteteFacture}>
        <div>
          <div className="Titre2">
            {isAvoir ? "Avoir sur facture" : "Facture"}{" "}
            <span className={MonStyle.LibelleAvoir}>{libelleAvoir}</span>
          </div>
          <div className="SousTitre">
            N°{facture.numero_facture} du{" "}
            {DateToString(facture.date_facture, DATE_FORMAT)}
          </div>
        </div>
        <BoutonRond
          onClick={() => setAfficherDetail(!afficherDetail)}
          couleurIcone="noir"
          iconeComposant={
            afficherDetail ? <ExpandLessIcon /> : <ExpandMoreIcon />
          }
          tooltip={
            afficherDetail
              ? "Masquer le détail de la facture"
              : "Afficher le détail"
          }
        />
      </div>

      <AffichageConditionnel open={afficherDetail}>
        <div>
          <div className={MonStyle.Boutons}>
            <BoutonRond
              iconeComposant={<PrintIcon />}
              couleurIcone="blanc"
              couleur="vert"
              tooltip="Imprimer"
              onClick={() => setOpenFactureId(facture._id)}
            />
          </div>

          <div className={MonStyle.Content}>
            {facture.cotations.map((cotation) => (
              <div key={cotation._id}>
                <div className={MonStyle.TitreBlocCotation}>
                  Consultation du{" "}
                  {DateToString(cotation.date_cotation, DATE_FORMAT)}
                </div>
                <div>
                  {cotation.prestations.map((ligne) => (
                    <div className={MonStyle.Ligne} key={ligne._id}>
                      <div>
                        <ObjetItem
                          principale={ligne.nom}
                          secondaire={ligne.description}
                        />
                      </div>
                      {ligne.montant_remise > 0 && (
                        <div className={MonStyle.Remise}>
                          {"remise " + ligne.montant_remise + " €"}
                        </div>
                      )}
                      {ligne.pourcentage_remise > 0 && (
                        <div className={MonStyle.Remise}>
                          {"remise " + ligne.pourcentage_remise + " %"}
                        </div>
                      )}

                      <div>
                        <ObjetPrix prix={ligne.prestation_total_ttc_net} />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))}

            <div className={MonStyle.LigneTotal}>
              <div style={{ color: "black", fontWeight: "bold" }}>Total</div>
              <div>
                <ObjetPrix
                  prix={facture.total_montant_ttc}
                  style={{ color: "black", fontWeight: "bold" }}
                />
              </div>
            </div>

            <AffichageConditionnel open={!isAvoir}>
              <div className={MonStyle.Parts}>
                <AffichagePartPatient
                  facture={facture}
                  onAfficherDetail={() =>
                    setAfficherReglements(!afficherReglements)
                  }
                />
              </div>
            </AffichageConditionnel>

            <AffichageConditionnel open={!isAvoir}>
              <AffichageReglements
                facture={facture}
                open={afficherReglements}
              />
            </AffichageConditionnel>

            <AffichageConditionnel open={!isAvoir && !isFactureAnnule}>
              <div>
                {!facture.part_patient.montant_is_regle && (
                  <EditionReglement facture={facture} />
                )}
              </div>
            </AffichageConditionnel>

            <ObjetTracabilite
              tracabiliteCreation={facture.tracabilite_creation}
              texteSupprimer="Annuler la facture"
              style={{ paddingBottom: "0px" }}
              onDelete={handleSupprimerFacture}
              taille={isAvoir || isFactureAnnule ? "S" : ""}
            />
          </div>
        </div>
      </AffichageConditionnel>
      <DocumentFacture
        facture_id={openFactureId}
        open={openFactureId ? true : false}
        onClose={() => setOpenFactureId()}
        autoPrint
      />
      <PopupAnnulationFacture
        facture_id={facture._id}
        open={afficherPopup}
        onClose={() => setAfficherPopup(false)}
        onReglementsRendus={annulerFacture}
      />
    </SurfacePapier>
  );
}
