import React, { useState, useContext, useEffect } from "react";
import { ProduitsContext } from "../../../../../Data/ProduitsContext";
import InputTexte from "../../../../../Design/Formulaires/InputTexte";
import MessageErreur from "../../../../../Design/Messages/MessageErreur";
import BoutonActionPrincipale from "../../../../../Design/Boutons/BoutonActionPrincipale";
import BoutonAnnuler from "../../../../../Design/Boutons/BoutonAnnuler";
import Adresse from "../../../../../Components/Adresse";
import InputTelephone from "../../../../../Design/Formulaires/InputTelephone";
import EditionFraisDePort from "./EditionFraisDePort";
import BoutonAjoutBloc from "../../../../../Design/Boutons/BoutonAjoutBloc";
import ObjetFraisDePort from "../../../../../Design/Objets/ObjetFraisDePort";
import BoutonRond from "../../../../../Design/Boutons/BoutonRond";

export default function EditionLaboratoire({
  laboratoire_id,
  onAnnuler,
  onEnregistrer,
}) {
  //NES Gestion du contexte
  const { getLaboratoireById, postLaboratoire, patchLaboratoire } =
    useContext(ProduitsContext);

  //NES Gestion du state
  const [data, setData] = useState({
    nom: "",
    adresse: {},
    telephone: {},
    frais_de_port: [],
  });
  const [erreur, setErreur] = useState("");
  const [indexEdition, setIndexEdition] = useState();

  const handleChange = (key, value) => {
    console.log({ key, value });
    setData({ ...data, [key]: value });
  };

  //NES AJOUTER FRAIS DE PORT
  const ajouterFraisDePort = () => {
    let tempArray = data.frais_de_port;
    tempArray.push({
      pays: "",
      description: "",
      is_default: false,
      montant_min_commande: "",
      montant_max_commande: "",
      montant_frais_de_port: 0,
    });
    setData({ ...data, frais_de_port: tempArray });
    //NES je panse en mode édition
    setIndexEdition(tempArray.length - 1);
  };

  //NES SUPPRIMER FRAIS DE PORT
  const supprimerFraisDePort = (index) => {
    let tempArray = data.frais_de_port;
    tempArray.splice(index, 1);
    setData({ ...data, frais_de_port: tempArray });
  };

  //NES Enregistrer
  const Enregistrer = () => {
    console.log({ data });

    if (data._id) {
      //PATCH
      patchLaboratoire(data)
        .then((reponse) => {
          console.log({ reponse });
          if (reponse.code === "OK") {
            if (onEnregistrer) {
              onEnregistrer();
            }
          } else {
            setErreur(reponse.erreur.message);
          }
        })
        .catch((error) => {
          setErreur("Erreur lors de l'enregistrement");
        });
    } else {
      //POST
      postLaboratoire(data)
        .then((reponse) => {
          if (reponse.code === "OK") {
            if (onEnregistrer) {
              onEnregistrer();
            }
          } else {
            setErreur(reponse.erreur.message);
          }
        })
        .catch((error) => {
          setErreur("Erreur lors de l'enregistrement");
        });
    }
  };

  //USEEFFECT
  useEffect(() => {
    if (laboratoire_id !== "new") {
      getLaboratoireById(laboratoire_id).then((reponse) => {
        if (reponse.code === "OK") {
          setData(reponse.data);
        }
      });
    }
    // eslint-disable-next-line
  }, [laboratoire_id]);

  const handleUpdateFraisDePort = (index, objet) => {
    let tempArray = data.frais_de_port;

    console.log({ tempArray });
    tempArray.splice(index, 1, objet);
    setData({ ...data, frais_de_port: tempArray });
    //NES je ferme le mode edition
    setIndexEdition();
  };

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <section>
        <InputTexte
          label="Nom du laboratoire"
          value={data.nom}
          onChange={(e) => handleChange("nom", e.target.value)}
        />
      </section>

      <section>
        <h3>Adresse</h3>
        <Adresse
          value={data.adresse}
          onChange={(adresse) => handleChange("adresse", adresse)}
        />
      </section>

      <section>
        <h3>Contact</h3>
        <InputTelephone
          objTelephone={data.telephone}
          onTelephoneChange={(telephone) =>
            handleChange("telephone", telephone)
          }
          disableTypeChange
        />
      </section>

      <section>
        <h3>Frais de port</h3>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            marginTop: "20px",
          }}
        >
          {data?.frais_de_port?.map((item, index) =>
            indexEdition === index ? (
              <EditionFraisDePort
                fraisDePort={item}
                key={index}
                onClickOk={(data) => handleUpdateFraisDePort(index, data)}
                onClickAnnuler={() => setIndexEdition("")}
              />
            ) : (
              <div
                key={index}
                style={{
                  border: "1px solid #DDD",
                  padding: "10px",
                  borderRadius: "6px",
                  cursor: "pointer",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <ObjetFraisDePort
                  objet={item}
                  key={index}
                  onClick={() => setIndexEdition(index)}
                />
                <BoutonRond
                  tooltip="Supprimer"
                  onClick={() => supprimerFraisDePort(index)}
                />
              </div>
            )
          )}
        </div>

        <BoutonAjoutBloc
          texte="Ajouter des frais de port"
          fullWidth
          style={{ marginTop: "10px" }}
          onClick={ajouterFraisDePort}
        />
      </section>

      <MessageErreur message={erreur} style={{ marginTop: "20px" }} />
      <BoutonActionPrincipale
        texte="Enregistrer"
        style={{ margin: "20px 0px" }}
        onClick={Enregistrer}
        disabled={!data.nom}
        taille="L"
      />
      <BoutonAnnuler onClick={onAnnuler} />
    </div>
  );
}
