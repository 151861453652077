import React from "react";
import { useState, useContext } from "react";
import InputTexte from "../../../../../Design/Formulaires/InputTexte";
import { PatientsContext } from "../../../../../Data/PatientsContext";
import { useEffect } from "react";

export default function BlocCommentaireRdv({ patient_id }) {
  //NES Gestion du contexte
  const { patchPatient, getPatientContext } = useContext(PatientsContext);

  //NES Gestion du state
  const [value, setValue] = useState("");

  //USEEFFECT
  useEffect(() => {
    if (patient_id) {
      getPatientContext(patient_id, true)
        .then((reponse) => {
          if (reponse.code === "OK") {
            setValue(reponse.data.commentaire_prise_rdv);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
    // eslint-disable-next-line
  }, [patient_id]);

  //NES handleChange
  const handleChange = (event) => {
    setValue(event.target.value);
  };

  //NES onBlur
  const handleBlur = (event) => {
    let data = { commentaire_prise_rdv: event.target.value };
    if (patient_id) {
      patchPatient(patient_id, data).catch((error) => {
        console.log(error);
      });
    }
  };

  //NES style renseigne
  const styleRenseigne = {
    margin: "0px",
    boxShadow: " 0 1px 2px 0 rgba(0,0,0,0.15)",
    backgroundColor: "white",
  };

  return (
    <div>
      <h4 style={{ textTransform: "uppercase" }}>Info prise de rdv </h4>
      <InputTexte
        placeholder="Ce commentaire est affiché pour chaque rdv de ce patient
          "
        theme={"lecture"}
        style={value ? styleRenseigne : { margin: "0px" }}
        multiline
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
      />
    </div>
  );
}
