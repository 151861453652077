import React from "react";
import ObjetPrix from "../../../../Design/Objets/ObjetPrix";
import MonStyle from "./LigneAbonnement.module.css";

export default function LigneAbonnementSms({ onClickDetail, ligne }) {
  return (
    <div className={MonStyle.Ligne}>
      <div className={MonStyle.Sms}>{ligne.quantite + " " + ligne.nom}</div>
      <div>
        <ObjetPrix
          prix={ligne.tarif.tarif_unitaire_ttc}
          complement={" / sms"}
        />
      </div>
      <div className={MonStyle.Tarif}>
        <ObjetPrix prix={ligne.montant_ttc_net} />
      </div>
    </div>
  );
}
