import React from "react";
import MonStyle from "./EditionEvenement.module.css";
import DateRangeIcon from "@material-ui/icons/DateRange";
import SelectHeure from "../../../../../Design/Formulaires/SelectHeure";
import BoutonRond from "../../../../../Design/Boutons/BoutonRond";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import {
  setHeureFromString,
  DateToString,
} from "../../../../../functions/FonctionsDateHeure";
import CloseIcon from "@material-ui/icons/Close";
export default function BlocDate({
  label,
  dateHeure,
  is_toute_la_journee,
  onClick,
  onClickEffacer,
  onDateHeureChange,
  placeholder,
  enableEffacer,
}) {
  //NEs quand je change l'heure
  const handleChangeHeure = (event) => {
    let newDate = setHeureFromString(dateHeure, event.target.value);
    if (onDateHeureChange) {
      onDateHeureChange(newDate);
    }
  };

  return (
    <div className={MonStyle.Bloc}>
      <DateRangeIcon className="Icone" />
      <div className={MonStyle.LabelBleu} onClick={onClick}>
        <span style={{ fontWeight: "300", color: "black" }}>{label} : </span>
        {dateHeure ? (
          DateToString(dateHeure)
        ) : (
          <span style={{ color: "#97989D" }}>{placeholder}</span>
        )}
      </div>
      {!is_toute_la_journee && (
        <SelectHeure
          theme="lecture"
          heure={dateHeure.toLocaleTimeString("fr-FR", {
            hour: "numeric",
            minute: "numeric",
          })}
          onHeureChange={handleChangeHeure}
        />
      )}
      {enableEffacer ? (
        <BoutonRond
          iconeComposant={<CloseIcon />}
          onClick={onClickEffacer}
          tooltip="Effacer la date"
        />
      ) : (
        <BoutonRond
          iconeComposant={<NavigateNextIcon />}
          onClick={onClick}
          tooltip="Changer de date"
        />
      )}
    </div>
  );
}
