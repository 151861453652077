import React, { useContext, useEffect } from "react";
import MonStyle from "./EditionConseil.module.css";
import SurfacePapier from "../../../../Design/Surfaces/SurfacePapier";
import EnteteBloc from "../EnteteBloc/EnteteBloc";
import EditionEntete from "./EditionEntete";
import EditionConsultant from "./EditionConsultant";
import BoutonRond from "../../../../Design/Boutons/BoutonRond";
import PrintIcon from "@material-ui/icons/Print";
import BoutonAjoutBloc from "../../../../Design/Boutons/BoutonAjoutBloc";
import LigneConseil from "./LigneConseil";
import { useState } from "react";
import EditionConseilPrise from "./EditionConseilPrise";
import EditionConseilSeance from "./EditionConseilSeance";
import { IllustrationPlantes } from "../../../../Images/Illustrations/Illustrations";
import InputAutocompleteProduits from "../../../../Design/Recherche/InputAutocompleteProduits";
import CloseIcon from "@material-ui/icons/Close";
import BoutonConfirmerSuppression from "../../../../Design/Boutons/BoutonConfirmerSuppression";
import DeleteIcon from "@material-ui/icons/Delete";
import { ConseilsContext } from "../../../../Data/ConseilsContext";
import MessageErreur from "../../../../Design/Messages/MessageErreur";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import DocumentConseil from "../../../../Docs/Pages/DocumentConseil";
import BoutonMenu from "../../../../Design/Navigation/BoutonMenu";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import DocumentBonDeCommande from "../../../../Docs/Pages/DocumentBonDeCommande";
import { stringToDecimal } from "../../../../functions/Formatter";
import SuggestionSaisieCodePraticien from "./SuggestionSaisieCodePraticien";

const objetConseil = {
  date: "",
  avertissement_conseil: "",
  consultant: "",
  conseils_seance: "",
  lignes_conseil: [],
  conseil_prise: { duree: "30 minutes", temperature: "FRAICHE" },
  tracabilite_creation: {},
};

export default function EditionConseil({
  conseil,
  style,
  onAfficherProduit,
  id,
  patient_id,
  consultation_id,
}) {
  //NES gestion du contexte
  const { deleteConseils, patchConseils } = useContext(ConseilsContext);

  //NES Gestion du state
  const [data, setData] = useState(objetConseil);
  const [afficherAutocomplete, setAfficherAutocomplete] = useState(false);
  const [afficherSuppression, setAfficherSuppression] = useState(false);
  const [erreur, setErreur] = useState("");
  const [error, setError] = useState();
  const [isImprimerConseil, setIsImprimerConseil] = useState(false);
  const [laboratoires, setLaboratoires] = useState([]);
  const [imprimerBonDeCommande, setImprimerBonDeCommande] = useState();

  //PATCH conseils/:id
  const handleChange = (key, value) => {
    let newData = { [key]: value };
    patchConseils(patient_id, consultation_id, conseil._id, newData)
      .then((reponse) => {
        if (reponse.code === "OK") {
          console.log({ reponse });
          setData(reponse.data);
        } else {
          setErreur(reponse.erreur.message);
        }
      })
      .catch((error) => {
        setErreur("Erreur lors de l'enregistrement du conseil");
        setError(error);
      });
  };

  //USEEFFECT chargement des données passées en param
  useEffect(() => {
    if (conseil) {
      setData(conseil);
    }
  }, [conseil]);

  //USEEFFECT détection des labo dans les ligne de conseil pour l'impression des bon de commande
  useEffect(() => {
    let lignes = data?.lignes_conseils;
    let laboratoiresLignes = [];
    if (lignes) {
      lignes.forEach((ligne) => {
        let labo_id = ligne?.produit?.laboratoire?._id;
        console.log();
        if (labo_id) {
          let index = laboratoiresLignes.findIndex(
            (item) => item._id === labo_id
          );
          if (index === -1) {
            laboratoiresLignes.push(ligne?.produit?.laboratoire);
          }
        }
      });
      setLaboratoires(laboratoiresLignes);
    }
  }, [data]);

  //NES handle blur sur l'autocomplete
  const handleBlur = (produit) => {
    if (produit.nom) {
      ajouterLigne(produit);
    }
    //NES je masque l'autocomplete
    setAfficherAutocomplete(false);
  };

  //NES quand je sélectionne un produit
  const handleSelectProduit = (produit) => {
    if (produit) {
      let dataProduit = {
        produit_id: produit._id,
        external_id: produit.external_id,
        forme: produit.forme,
        nom: produit.nom,
        description: produit.description,
        conditionnement: produit.conditionnement,
        laboratoire: produit.laboratoire,
        code_commande: produit.code_commande,
        contre_indications: produit.contre_indications,
        prix_de_vente_unitaire: stringToDecimal(produit.prix_de_vente_ttc),
        qte: 1,
        prix_de_vente_total: stringToDecimal(produit.prix_de_vente_ttc),
      };
      ajouterLigne(dataProduit);
      console.log({ dataProduit });
    }
    //NES je masque l'autocomplete
    setAfficherAutocomplete(false);
  };

  //NES ajouter une ligne
  const ajouterLigne = (produit) => {
    let tempArray = data.lignes_conseils;
    tempArray.push({ produit: produit });
    handleChange("lignes_conseils", tempArray);
  };

  //NES modifier une ligne
  const handleModifierLigne = (newData) => {
    let newLignes = data.lignes_conseils;
    let index = newLignes.findIndex((item) => item._id === newData._id);
    newLignes.splice(index, 1, newData);
    handleChange("lignes_conseils", newLignes);
  };

  //NES supprimer une ligne
  const handleSupprimerLigne = (ligne_id) => {
    let tempArray = data.lignes_conseils;
    let index = tempArray.findIndex((item) => item._id === ligne_id);
    tempArray.splice(index, 1);
    handleChange("lignes_conseils", tempArray);
  };

  //DELETE
  const supprimerConseil = () => {
    deleteConseils(patient_id, consultation_id, conseil._id);
  };

  return (
    <SurfacePapier style={style} id={"id_conseil_" + id}>
      <EnteteBloc
        titre="Fiche Conseil"
        labelOpen="Avertissement, consultant"
        sousTitre={
          data
            ? "par " +
              data.tracabilite_creation.nom_utilisateur +
              " " +
              data.tracabilite_creation.prenom_utilisateur
            : ""
        }
        date={data.date}
        onDateChange={(date) => handleChange("date", date)}
      >
        <div style={{ paddingBottom: "20px" }}>
          <EditionEntete
            value={data.avertissement_conseil}
            onBlur={(e) =>
              handleChange("avertissement_conseil", e.target.value)
            }
          />
          <EditionConsultant
            consultant={data.consultant}
            onChange={(e) => handleChange("consultant", e.target.value)}
          />
        </div>
      </EnteteBloc>
      <div className={MonStyle.Boutons}>
        <BoutonRond
          iconeComposant={<DeleteIcon />}
          couleur="gris"
          couleurIcone="rouge"
          tooltip="Supprimer la fiche conseil"
          onClick={() => setAfficherSuppression(true)}
          id={"bouton_supprimer_conseil_" + id}
        />

        <BoutonMenu
          iconeComposant={<PrintIcon />}
          couleur="vert"
          couleurIcone="blanc"
          tooltip="Imprimer"
          id={"bouton_imprimer_conseil_" + id}
        >
          <List>
            <ListItem button divider onClick={() => setIsImprimerConseil(true)}>
              <PrintIcon className="IconeMenu" />
              <ListItemText primary="Fiche conseil" />
            </ListItem>
            {laboratoires.map((labo) => (
              <ListItem
                button
                divider
                onClick={() => setImprimerBonDeCommande(labo._id)}
                key={labo._id}
              >
                <PrintIcon className="IconeMenu" />
                <ListItemText primary={labo.nom} secondary="Bon de commande" />
              </ListItem>
            ))}
          </List>
        </BoutonMenu>
      </div>
      <DocumentConseil
        conseil_id={data?._id}
        open={isImprimerConseil}
        onClose={() => setIsImprimerConseil(false)}
        autoPrint
      />
      <DocumentBonDeCommande
        conseil_id={data?._id}
        laboratoire_id={imprimerBonDeCommande}
        open={imprimerBonDeCommande ? true : false}
        onClose={() => setImprimerBonDeCommande()}
        autoPrint
      />
      <BoutonConfirmerSuppression
        variant="bloc"
        texte="Voulez vous supprimer cette fiche conseil?"
        onSupprimer={supprimerConseil}
        visible={afficherSuppression}
        fullWidth
        onAnnuler={() => setAfficherSuppression(false)}
        style={{ margin: "20px 0px" }}
      />
      <MessageErreur
        message={erreur}
        error={error}
        style={{ marginTop: "20px" }}
      />
      <SuggestionSaisieCodePraticien conseil={conseil} />
      <div className={MonStyle.Lignes} id="lignes_conseil">
        {data?.lignes_conseils?.map((ligne, index) => (
          <LigneConseil
            id={index}
            ligneConseil={ligne}
            key={index}
            onSupprimer={() => handleSupprimerLigne(ligne._id)}
            onAfficherProduit={() =>
              onAfficherProduit(ligne.produit.produit_id)
            }
            onChange={handleModifierLigne}
          />
        ))}
      </div>
      {afficherAutocomplete ? (
        <div className={MonStyle.NouveauProduit}>
          <div className={MonStyle.Autocomplete}>
            <InputAutocompleteProduits
              onBlur={handleBlur}
              onSelect={handleSelectProduit}
              id="autocomplete_produit"
            />
          </div>
          <BoutonRond
            iconeComposant={<CloseIcon />}
            tooltip="Fermer"
            style={{ marginLeft: "20px" }}
            couleurIcone="noir"
          />
        </div>
      ) : (
        <BoutonAjoutBloc
          id="bouton_ajouter_produit"
          texte="Ajouter un produit, une plante"
          iconeComposant={<AddCircleOutlineIcon />}
          taille="L"
          fullWidth
          couleur="bleu"
          onClick={() => setAfficherAutocomplete(true)}
          style={{ margin: "20px 0px 40px 0px" }}
        />
      )}

      <div className={MonStyle.Conseils}>
        {data?.lignes_conseils?.length > 0 && (
          <EditionConseilPrise
            conseilPrise={data.conseil_prise}
            onChange={(conseil) => handleChange("conseil_prise", conseil)}
          />
        )}
        <EditionConseilSeance
          style={{ marginTop: "30px" }}
          conseilSeance={data.conseils_seance}
          onBlur={(conseils_seance) =>
            handleChange("conseils_seance", conseils_seance)
          }
        />
      </div>
      <div style={{ marginBottom: "-30px", marginTop: "50px" }}>
        <IllustrationPlantes height="150px" />
      </div>
    </SurfacePapier>
  );
}
