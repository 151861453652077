import React, { useContext, useState, useEffect } from "react";
import MonStyle from "./PageAfficherAgenda.module.css";
import SurfacePapier from "../../../Design/Surfaces/SurfacePapier";
import Agenda from "./components/Agenda";
import { AgendasContext } from "../../../Data/AgendasContext";
import LigneJours from "./components/LigneJours";
import BarreMenuAgenda from "./components/BarreMenuAgenda/BarreMenuAgenda";
import NomAgendaMobile from "./components/NomAgendaMobile";
import LoaderHorizontal from "../../../Design/Progression/LoaderHorizontal";
import { RdvContext } from "../../../Data/RdvContext";
import MessageErreur from "../../../Design/Messages/MessageErreur";
import AffichageGrilleTouteLaJournee from "./components/AffichageEvenementsTouteLaJournee/AffichageGrilleTouteLaJournee";

export default function PageAfficherAgenda({ match }) {
  //NES récupération du param id
  let idUrl = match.params.id;

  //NES gestion du context
  const { agendaSelected, datesSemaine, heureDebutAgendas, heureFinAgendas } =
    useContext(AgendasContext);
  const { getRdv, getEvenements } = useContext(RdvContext);

  //NES gestion du state
  const [isLoading, setIsLoading] = useState(false);
  const [messageErreur, setMessageErreur] = useState();
  const [messageErreurEvt, setMessageErreurEvt] = useState();
  const [error, setError] = useState();
  const [errorEvt, setErrorEvt] = useState();

  //USEEFFECT récupération des rdv
  useEffect(() => {
    if (datesSemaine.length > 0 && idUrl) {
      //NES le démarre le chargement
      setIsLoading(true);
      getRdv(
        idUrl,
        datesSemaine[0],
        datesSemaine[datesSemaine.length - 1]
      ).then((reponse) => {
        setIsLoading(false);
        if (reponse.code === "OK") {
          setMessageErreur();
          setError();
        } else {
          setMessageErreur("Erreur lors de la récupération des rdv");
          setError(reponse);
        }
      });
    }

    // eslint-disable-next-line
  }, [idUrl, datesSemaine]);

  //USEEFFECT Récupération des evenements
  useEffect(() => {
    if (datesSemaine.length > 0 && idUrl) {
      //NES le démarre le chargement
      setIsLoading(true);

      //NES récupération des évènements
      getEvenements(
        idUrl,
        datesSemaine[0],
        datesSemaine[datesSemaine.length - 1]
      ).then((reponse) => {
        setIsLoading(false);
        if (reponse.code === "OK") {
          setMessageErreurEvt();
          setErrorEvt();
        } else {
          setMessageErreurEvt("Erreur lors de la récupération des évènements");
          setErrorEvt(reponse);
        }
      });
    }

    // eslint-disable-next-line
  }, [idUrl, datesSemaine]);

  if (agendaSelected) {
    return (
      <SurfacePapier disablePadding>
        <div className={MonStyle.EnteteAgenda} id="Agenda-Entete">
          <NomAgendaMobile agenda={agendaSelected} />
          <div className={MonStyle.ContentEnteteAgenda}>
            <BarreMenuAgenda agenda_id={agendaSelected._id} />
            <div className={MonStyle.EnteteColonnes}>
              <LigneJours
                couleurAgenda={agendaSelected.couleur_agenda}
                nomAgenda={agendaSelected.nom_agenda}
              />
              <AffichageGrilleTouteLaJournee agenda_id={agendaSelected._id} />
            </div>

            <LoaderHorizontal isLoading={isLoading} />
            <MessageErreur
              message={messageErreur}
              error={error}
              style={{ margin: "20px 0px" }}
            />
            <MessageErreur
              message={messageErreurEvt}
              error={errorEvt}
              style={{ margin: "20px 0px" }}
            />
          </div>
        </div>
        <div className={MonStyle.Agenda}>
          <Agenda
            agenda={agendaSelected}
            heureDebutAgenda={heureDebutAgendas}
            heureFinAgenda={heureFinAgendas}
          />
        </div>
      </SurfacePapier>
    );
  }

  return "";
}
