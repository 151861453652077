import React, { useState, useEffect } from "react";
import MonStyle from "./IconeMesuresPicker.module.css";
import IconesMesures from "../Icones/IconesMesures";

export default function IconeMesurePicker({ value, onClick }) {
  let ICONES = [
    "IconePoids",
    "IconeTension",
    "IconeTemperature",
    "IconeTaille",
    "IconeFrequenceCardiaque",
    "IconeSang",
    "IconeSouffle",
    "IconeQi",
    "IconeDouleur",
    "IconeVirus",
    "IconeEnceinte",
    "IconeBebe",
    "IconeBiberon",
    "IconeFamille",
    "IconeSommeil",
    "IconeTabac",
    "IconeAlimentation",
    "IconeDrogue",
    "IconeAlcool",
    "IconeSport",
    "IconeTravail",
    "IconeHandicap",
    "IconeOreille",
    "IconePc",
  ];

  //NES Gestion du state
  const [selected, setSelected] = useState("");

  //USEEFFECT
  useEffect(() => {
    setSelected(value);
  }, [value]);

  ///handleClick
  const handleClick = (nom) => {
    let newValue = nom;

    if (nom === selected) {
      newValue = "";
    }

    if (onClick) {
      onClick(newValue);
    }

    setSelected(newValue);
  };

  return (
    <div className={MonStyle.IconeMesurePicker}>
      {ICONES.map((nom) => (
        <div
          key={nom}
          className={
            selected === nom
              ? MonStyle.BoutonIconeSelected
              : MonStyle.BoutonIcone
          }
          onClick={() => handleClick(nom)}
        >
          <IconesMesures nomIcone={nom} className="IconeObjet" />
        </div>
      ))}
    </div>
  );
}
