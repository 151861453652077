import React, { useEffect, useState } from "react";
import MonStyle from "./ObjetContact.module.css";
import BoutonTelephone from "../Boutons/BoutonTelephone";
import IconesContacts from "../Icones/IconesContacts";
import { getColorByTypeContact } from "../../functions/FonctionsCouleurs";
import { Tag } from "../DataDisplay/Tags";
import { capitalize } from "../../functions/Formatter";
import BoutonEmail from "../Boutons/BoutonEmail";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import PhoneIcon from "@mui/icons-material/Phone";
import MailIcon from "@mui/icons-material/Mail";

export default function ObjetContact({ contact, taille }) {
  //NES Gestion du state
  const [data, setData] = useState({});
  const [adresse, setAdresse] = useState("");
  const [libelle, setLibelle] = useState("");
  const [numero, setNumero] = useState("");

  //USEEFFECT
  useEffect(() => {
    if (contact) {
      setData(contact);

      //NES Gestion de l'affichage
      if (contact.type === "PHARMACIE" && contact?.etablissement) {
        setLibelle(contact?.etablissement?.toUpperCase());
        setNumero("N° finess: " + contact.numero_finess);
      } else {
        let tempLibelle =
          contact?.nom?.toUpperCase() + " " + capitalize(contact.prenom);
        if (contact.type === "MEDECIN") {
          tempLibelle = "Dr " + tempLibelle;
        }
        setLibelle(tempLibelle);

        if (contact.numero_rpps) {
          setNumero("N° RPPS: " + contact.numero_rpps);
        } else {
          setNumero("");
        }
      }

      //NES gestion de l'adresse si c'est un string
      if (typeof contact?.adresse === "string") {
        setAdresse(contact?.adresse);
      }

      //NES gestion de l'adresse si c'est un objet
      if (
        contact?.adresse?.rue ||
        contact?.adresse?.code_postal ||
        contact?.adresse?.ville
      ) {
        let rue = contact.adresse.rue ? contact.adresse.rue : "";
        let codePostal = contact.adresse.code_postal
          ? contact.adresse.code_postal
          : "";
        let ville = contact.adresse.ville ? contact.adresse.ville : "";
        let monAdresse = rue + " " + codePostal + " " + ville;
        setAdresse(monAdresse);
      }
    }
  }, [contact]);

  if (taille === "L") {
    return (
      <div className={MonStyle.ObjetContact}>
        <div
          className={MonStyle.Contact}
          style={{
            display: "flex",
            flexDirection: "row",
            color: "#1B8287",
            gap: "5px",
            alignItems: "flex-start",
          }}
        >
          <div style={{ width: "40px" }}>
            <IconesContacts type={data.type} />
          </div>

          <div style={{ color: getColorByTypeContact(data.type) }}>
            <div
              style={{
                fontWeight: "700",
                fontFamily: "Lato",
                display: "flex",
                flexWrap: "wrap",
                marginBottom: "2px",
                alignItems: "center",
              }}
            >
              {libelle}

              <Tag
                texte={data.specialite?.libelle}
                color={getColorByTypeContact(data.type)}
                style={{ marginLeft: "5px" }}
              />
            </div>
            <div className="InformationSecondaire">{numero}</div>
            <div className={MonStyle.InformationsSecondairesL}>
              <div className={MonStyle.LigneSecondairesL}>
                <LocationCityIcon
                  style={{
                    color: adresse ? "" : " rgb(151, 152, 157)",
                    fontSize: "20px",
                  }}
                />
                {adresse ? adresse : " - "}
              </div>
              <div className={MonStyle.LigneSecondairesL}>
                <PhoneIcon
                  style={{
                    color: contact.telephone?.numero
                      ? ""
                      : " rgb(151, 152, 157)",
                    fontSize: "20px",
                  }}
                />
                {contact.telephone?.numero ? contact.telephone?.numero : " - "}
              </div>
              <div className={MonStyle.LigneSecondairesL}>
                <MailIcon
                  style={{
                    color: contact.email ? "" : " rgb(151, 152, 157)",
                    fontSize: "20px",
                  }}
                />
                {contact.email ? (
                  <a
                    href={"mailto:" + contact.email}
                    style={{ color: "inherit", textDecoration: "underline" }}
                  >
                    {" "}
                    {contact.email}
                  </a>
                ) : (
                  " - "
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={MonStyle.ObjetContact}>
      <div
        className={MonStyle.Contact}
        style={{
          display: "flex",
          flexDirection: "row",
          color: "#1B8287",
          gap: "5px",
          alignItems: "center",
        }}
      >
        <div style={{ width: "40px" }}>
          <IconesContacts type={data.type} />
        </div>

        <div style={{ color: getColorByTypeContact(data.type) }}>
          <div
            style={{
              fontWeight: "700",
              fontFamily: "Lato",
              display: "flex",
              flexWrap: "wrap",
              marginBottom: "2px",
              alignItems: "center",
            }}
          >
            {libelle}

            <Tag
              texte={data.specialite?.libelle}
              color={getColorByTypeContact(data.type)}
              style={{ marginLeft: "5px" }}
            />
          </div>
          <div className="InformationSecondaire">{adresse}</div>
          <div className="InformationSecondaire">{numero}</div>
        </div>
      </div>

      <div className={MonStyle.Boutons} style={{ display: "flex" }}>
        {taille === "L" && contact.telephone?.numero && (
          <div>
            <BoutonTelephone
              couleur={getColorByTypeContact(data.type)}
              telephone={contact.telephone}
              taille="S"
              style={{ marginTop: "10px" }}
            />
          </div>
        )}
        {taille === "L" && contact.email && (
          <div>
            <BoutonEmail
              couleur={getColorByTypeContact(data.type)}
              email={contact.email}
              taille="S"
              style={{ marginTop: "10px" }}
            />
          </div>
        )}
      </div>
    </div>
  );
}
