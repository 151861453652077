import React from "react";
import MonStyle from "./MessageArchive.module.css";
import BoutonActionSecondaire from "../Boutons/BoutonActionSecondaire";
import { DateToString } from "../../functions/FonctionsDateHeure";

export default function MessageArchive({
  message,
  tracabilite,
  texteAction,
  style,
  onClickAction,
  hideAction,
}) {
  if (!message) {
    return "";
  }

  return (
    <div className={MonStyle.MessageArchive}>
      <div className={MonStyle.Messages}>
        <div className={MonStyle.MessagePrincipal}>
          {message ? message : "message"}
        </div>
        {tracabilite && (
          <div className={MonStyle.MessageSecondaire}>
            par {tracabilite?.nom_utilisateur + " "}
            {tracabilite?.prenom_utilisateur + " "}
            le {DateToString(tracabilite?.date_tracabilite)}
          </div>
        )}
      </div>
      {!hideAction && (
        <BoutonActionSecondaire
          couleur="blanc"
          taille="S"
          texte={texteAction ? texteAction : "texteAction"}
          onClick={onClickAction}
        />
      )}
    </div>
  );
}
