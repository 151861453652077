import React, { useState, useEffect } from "react";
import { usePDF } from "@react-pdf/renderer";
import { Document, Page } from "react-pdf/dist/umd/entry.webpack";
import BoutonRond from "../Design/Boutons/BoutonRond";
import PrintIcon from "@material-ui/icons/Print";
import GetAppIcon from "@material-ui/icons/GetApp";
import CloseIcon from "@material-ui/icons/Close";
import download from "downloadjs";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import MonStyle from "./DocumentPdf.module.css";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { hauteurNavigateur } from "../functions/Navigateur";

export default function DocumentPdf({
  openDocumentPdf,
  onClose,
  autoPrint,
  documentComposant,
  nomFichier,
  ...props
}) {
  ///EVENT
  const iframe = document.getElementById("iframe-impression");

  //USEEFFECT
  useEffect(() => {
    if (autoPrint && iframe) {
      imprimer();
    }
  }, [iframe, autoPrint]);

  //NES  Gestion du state
  // eslint-disable-next-line
  const [instance, updateInstance] = usePDF({ document: props.children });
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const imprimer = () => {
    let frameList = window.frames;
    frameList[1].print();
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);

    //let frameList = window.frames;
    //console.log(frameList[1]);

    //if (autoPrint) {
    //  imprimer();
    // }
  };

  const onDownload = () => {
    //NES si j'ai demandé à downloader le fichier
    download(instance.blob, nomFichier);
  };

  const onPrint = () => {
    imprimer();
  };

  const handleClose = () => {
    if (onClose) {
      onClose();
    }
  };

  //NES gestion du scale d'affiche à l'écran car c'est mini sur mon mac et trop grand sur l'ipad
  const setScale = () => {
    let scale = 1;
    let hauteurDefaut = 840;
    let hauteurTotal = hauteurNavigateur();
    let hauteurAttendue = hauteurTotal * 0.8;

    scale = hauteurAttendue / hauteurDefaut;

    return scale;
  };

  if (instance.loading) return <div>Loading ...</div>;

  if (instance.error) return <div>Something went wrong: {instance.error}</div>;

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={openDocumentPdf}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Fade in={openDocumentPdf}>
        <div className={MonStyle.Page}>
          <div className={MonStyle.BarreOutils}>
            <div className={MonStyle.BoutonFermer}>
              <BoutonRond
                couleur="vert"
                iconeComposant={<CloseIcon />}
                couleurIcone="blanc"
                onClick={handleClose}
                tooltip="Fermer"
              />
            </div>

            <div className={MonStyle.BoutonsActions}>
              <BoutonRond
                couleur="vert"
                iconeComposant={<GetAppIcon />}
                couleurIcone="blanc"
                onClick={onDownload}
                style={{ marginRight: "10px" }}
                tooltip="Télécharger"
              />
              <BoutonRond
                couleur="vert"
                iconeComposant={<PrintIcon />}
                couleurIcone="blanc"
                onClick={onPrint}
                tooltip="Imprimer"
              />
            </div>
          </div>

          <Document file={instance.blob} onLoadSuccess={onDocumentLoadSuccess}>
            <Page
              pageNumber={pageNumber}
              scale={setScale()}
              open={openDocumentPdf}
            />
          </Document>

          <div className={MonStyle.NumeroPage}>
            <div className={MonStyle.BoutonsPage}>
              <BoutonRond
                iconeComposant={<ArrowBackIosIcon />}
                couleurIcone="blanc"
                disabled={pageNumber === 1}
                onClick={() => setPageNumber(pageNumber - 1)}
                tooltip="Page précédente"
              />
              <div className={MonStyle.TexteNumeroPage}>
                {"Page " + pageNumber + " / " + numPages}
              </div>
              <BoutonRond
                iconeComposant={<ArrowForwardIosIcon />}
                couleurIcone="blanc"
                disabled={pageNumber === numPages}
                onClick={() => setPageNumber(pageNumber + 1)}
                tooltip="Page suivante"
              />
            </div>
          </div>

          {instance.url && (
            <div style={{ display: "none" }}>
              <iframe
                id="iframe-impression"
                title="DocumentPDF"
                src={instance.url}
                allowtransparency="true"
                style={{ backgroundColor: "#FFFFFF" }}
              ></iframe>
            </div>
          )}
        </div>
      </Fade>
    </Modal>
  );
}
