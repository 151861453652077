import React from "react";
import ObjetItem from "./ObjetItem";

export default function ObjetPrestation({ prestation }) {
  return (
    <ObjetItem
      principale={prestation.nom}
      secondaire={prestation.description}
    />
  );
}
