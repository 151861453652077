import React, { useState, createContext, useContext } from "react";
import { DELETE, POST } from "../functions/FonctionsApi";
import { AuthentificationContext } from "./AuthentificationContext";
import { PerformancesContext } from "./PerformancesContext";

//NES Création du contexte
export const ConseilsContext = createContext();

export const ConseilsProvider = (props) => {
  //NES gestion du contexte
  const authContext = useContext(AuthentificationContext);
  const { GET } = useContext(PerformancesContext);

  //NES Gestion du state
  const [conseils, setConseils] = useState([]);
  const [preferences, setPreferences] = useState();

  //POST conseils/
  const postConseils = async (patient_id, consultation_id, data) => {
    //NES j'appelle l'API Energétique
    const MonUrl = `${process.env.REACT_APP_URL_API_ENERGETIQUE}/patients/${patient_id}/consultations/${consultation_id}/conseils`;

    let reponseJson = await POST(MonUrl, authContext.token, data);

    //NES je le garde dans le contexte
    if (reponseJson.code === "OK") {
      let tempArray = [...conseils];
      tempArray.push(reponseJson.data);
      setConseils(tempArray);
    }
    //NES je retourne à ma fonction appelante le résultat
    return reponseJson;
  };

  //GET conseils
  const getConseils = async (patient_id, consultation_id) => {
    //NES je mets à 0 le contexte
    setConseils([]);

    //NES j'appelle l'API
    const MonUrl = `${process.env.REACT_APP_URL_API_ENERGETIQUE}/patients/${patient_id}/consultations/${consultation_id}/conseils`;

    let reponseJson = await GET(MonUrl, authContext.token);

    //NES Je conserve dans le contexte
    if (reponseJson.code === "OK") {
      setConseils(reponseJson.data);
    }
    //NES je retourne à ma fonction appelante le résultat
    return reponseJson;
  };

  //DELETE Conseils/:id
  const deleteConseils = async (patient_id, consultation_id, conseil_id) => {
    //NES j'appelle l'API
    const MonUrl = `${process.env.REACT_APP_URL_API_ENERGETIQUE}/patients/${patient_id}/consultations/${consultation_id}/conseils/${conseil_id}`;

    let reponseJson = await DELETE(MonUrl, authContext.token);

    //NES Mise à jour du contexte
    if (reponseJson.code === "OK") {
      let tempArray = [...conseils];
      let index = tempArray.findIndex((item) => item._id === conseil_id);
      tempArray.splice(index, 1);
      setConseils(tempArray);
    }
    //NES je retourne à ma fonction appelante le résultat
    return reponseJson;
  };

  //PATCH conseils/:id
  const patchConseils = async (
    patient_id,
    consultation_id,
    conseil_id,
    newData
  ) => {
    //NES j'appelle l'API Energétique
    const MonUrl = `${process.env.REACT_APP_URL_API_ENERGETIQUE}/patients/${patient_id}/consultations/${consultation_id}/conseils/${conseil_id}`;
    //NES pour détecter les appels en boucle
    const reponseApi = await fetch(MonUrl, {
      method: "PATCH",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        token: authContext.token,
      },
      body: JSON.stringify(newData),
    });
    //NES je convertis ce que je récupère en JSON
    const reponseJson = await reponseApi.json();
    //NES je le garde dans le contexte
    if (reponseJson.code === "OK") {
      let tempArray = [...conseils];
      let index = tempArray.findIndex((item) => item._id === conseil_id);
      tempArray.splice(index, 1, reponseJson.data);
      setConseils(tempArray);
    }
    //NES je retourne à ma fonction appelante le résultat
    return reponseJson;
  };

  //PATCH preferences/
  const patchPreferences = async (data) => {
    console.log({ data });
    //NES j'appelle l'API Energétique
    const MonUrl = `${process.env.REACT_APP_URL_API_ENERGETIQUE}/preferences`;
    //NES pour détecter les appels en boucle
    console.log({ PATCH: MonUrl });
    const reponseApi = await fetch(MonUrl, {
      method: "PATCH",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        token: authContext.token,
      },
      body: JSON.stringify(data),
    });
    //NES je convertis ce que je récupère en JSON
    const reponseJson = await reponseApi.json();
    //NES je le garde dans le contexte
    if (reponseJson.code === "OK") {
      setPreferences(reponseJson.data);
    }
    //NES je retourne à ma fonction appelante le résultat
    return reponseJson;
  };

  //GET préférences/
  const getPreferences = async () => {
    //NES est-ce que j'ai déjà les preferences dans le contexte?
    if (preferences) {
      return { code: "OK", data: preferences };
    } else {
      //NES j'appelle l'API
      const MonUrl = `${process.env.REACT_APP_URL_API_ENERGETIQUE}/preferences`;
      //NES pour détecter les appels en boucle
      console.log({ GET: MonUrl });
      const reponseApi = await fetch(MonUrl, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          token: authContext.token,
        },
      });
      //NES je convertis ce que je récupère en JSON
      const reponseJson = await reponseApi.json();
      //NES Je conserve dans le contexte
      if (reponseJson.code === "OK" && reponseJson.data) {
        setPreferences(reponseJson.data);
      }
      //NES je retourne à ma fonction appelante le résultat
      return reponseJson;
    }
  };

  //GET conseils/:id
  const getConseilById = async (conseil_id) => {
    //NES est-ce que j'ai déjà le conseil dans le contexte?
    const conseilsPatient = conseils.find((item) => item._id === conseil_id);
    if (conseilsPatient) {
      return { code: "OK", data: conseilsPatient };
    } else {
      //NES j'appelle l'API
      const MonUrl = `${process.env.REACT_APP_URL_API_ENERGETIQUE}/conseils/${conseil_id}`;
      //NES pour détecter les appels en boucle
      console.log({ GET: MonUrl });
      const reponseApi = await fetch(MonUrl, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          token: authContext.token,
        },
      });
      //NES je convertis ce que je récupère en JSON
      const reponseJson = await reponseApi.json();
      //NES Je ne conserve pas dans le contexte

      //NES je retourne à ma fonction appelante le résultat
      return reponseJson;
    }
  };

  return (
    <ConseilsContext.Provider
      value={{
        conseils,
        getConseils,
        postConseils,
        deleteConseils,
        patchConseils,
        patchPreferences,
        getPreferences,
        getConseilById,
      }}
    >
      {props.children}
    </ConseilsContext.Provider>
  );
};
