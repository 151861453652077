import React, { useEffect, useState } from "react";
import MonStyle from "./OngletsBoutons.module.css";

export default function OngletsBoutons({
  label,
  labels,
  onglets,
  ongletSelectionne,
  onSelect,
  style,
}) {
  //NES gestion du style input
  const styleOngletsBoutons = {
    minHeight: "40px",
    border: "1px solid #DDDDDD",
    display: "flex",
    flexDirection: "row",
    backgroundColor: "white",
    borderRadius: "6px",
    boxSizing: "border-box",
    padding: "2px",
    zIndex: 1,
  };

  //NES Gestion du state
  const [selected, setSelected] = useState(ongletSelectionne);
  const [affichages, setAffichages] = useState([]);

  //NES quand on sélectionne un onglet
  const handleSelect = (nom) => {
    setSelected(nom);
    if (onSelect) {
      onSelect(nom);
    }
  };

  //USEEFFECT
  useEffect(() => {
    setSelected(ongletSelectionne);
  }, [ongletSelectionne]);

  //USEEFFECT
  useEffect(() => {
    if (labels) {
      setAffichages(labels);
    } else {
      setAffichages(onglets);
    }
  }, [onglets, labels]);

  return (
    <div>
      {label && (
        <div
          style={{
            marginLeft: "10px",
            marginTop: "10px",
            marginBottom: "5px",
            zIndex: 2,
            width: "fit-content",
            paddingLeft: " 5px",
            paddingRight: "5px",
            position: "relative",
          }}
        >
          <div className="Label" style={{ marginBottom: "-6px" }}>
            {label}
          </div>
        </div>
      )}
      <div style={styleOngletsBoutons}>
        {onglets.map((item, index) => (
          <div
            className={
              selected === item ? MonStyle.OngletActif : MonStyle.Onglet
            }
            onClick={() => handleSelect(item)}
            key={index}
          >
            {affichages[index]}
          </div>
        ))}
      </div>
    </div>
  );
}
