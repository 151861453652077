import React from "react";
import MonStyle from "./EditionMotifConsultation.module.css";
import ListeMotifsConsultation from "./ListeMotifsConsultation";

export default function EditionMotifConsultation({
  consultation,
  titre,
  onChange,
  disableSuggestions,
}) {
  return (
    <div className={MonStyle.EditionMotifConsultation}>
      <div className="TitreBloc">{titre ? titre : "Motif de consultation"}</div>

      <ListeMotifsConsultation
        values={consultation.motifs}
        onChange={onChange}
        disableSuggestions={disableSuggestions}
      />
    </div>
  );
}
