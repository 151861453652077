import React, { useContext, useEffect } from "react";
//import MonStyle from "./PageCommencerAbonnement.module.css";
import SurfacePopUp from "../../Design/Surfaces/SurfacePopUp";
import AffichageConditionnel from "../../Design/DataDisplay/AffichageConditionnel";
import PageAbonnement from "./Pages/PageAbonnement";
import BoutonActionPrincipale from "../../Design/Boutons/BoutonActionPrincipale";
import CheckoutFormPaiement from "./Components/Stripe/CheckoutFormPaiement";
import StripeSecret from "./Components/Stripe/StripeSecret";
import { UtilisateursContext } from "../../Data/UtilisateursContext";

export default function PageCommencerAbonnement() {
  //NES gestion du contexte
  const { monOrganisation, getMyOrganisation, postDebutAbonnement } =
    useContext(UtilisateursContext);

  //USEEFFECT
  useEffect(() => {
    getMyOrganisation();
    // eslint-disable-next-line
  }, []);

  //USEEFFECT
  useEffect(() => {
    console.log(monOrganisation);
  }, [monOrganisation]);

  /// HANDLE ABONNEMENT
  const handleDemarrerAbonnement = () => {
    postDebutAbonnement();
  };

  return (
    <SurfacePopUp open={true} titre="Abonnement">
      <AffichageConditionnel open={true}>CGV</AffichageConditionnel>
      <AffichageConditionnel open={true}>
        Synthèse Abonnement
        <PageAbonnement hideSms />
        <BoutonActionPrincipale
          texte="Appel demarrer abonnement"
          onClick={handleDemarrerAbonnement}
          fullWidth
        />
      </AffichageConditionnel>
      <AffichageConditionnel open={true}>
        Formulaire Carte CarteBancaire
        <StripeSecret
          secret={monOrganisation.stripe.payment_intent_client_secret}
        >
          <CheckoutFormPaiement montant />
        </StripeSecret>
      </AffichageConditionnel>
      <AffichageConditionnel open={true}>
        Merci et profitez bien
      </AffichageConditionnel>
    </SurfacePopUp>
  );
}
