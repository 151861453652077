import React, { useContext, useState, useEffect } from "react";
import MonStyle from "./AffichageReglements.module.css";
import Collapse from "@material-ui/core/Collapse";
import ObjetReglement from "../../../../Design/Objets/ObjetReglement";
import BoutonActionSecondaire from "../../../../Design/Boutons/BoutonActionSecondaire";
import { CotationsContext } from "../../../../Data/CotationsContext";
import LoaderHorizontal from "../../../../Design/Progression/LoaderHorizontal";
import MessageAucuneDonnee from "../../../../Design/Messages/MessageAucuneDonnee";
import MessageErreur from "../../../../Design/Messages/MessageErreur";

export default function AffichageReglements({ facture, open }) {
  //NES gestion du contexte
  const { getReglementsByFacture, deleteReglement } =
    useContext(CotationsContext);

  //NES gestion du state
  const [reglements, setReglements] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [messageErreur, setMessageErreur] = useState();
  const [error, setError] = useState();
  const [messageAucunReglement, setMessageAucunReglement] = useState();

  //USEEFFECT Aller chercher les règlements de la facture
  useEffect(() => {
    if (facture?._id && open) {
      getReglementsByFacture(facture._id)
        .then((reponse) => {
          setIsLoading(false);
          if (reponse.code === "OK") {
            if (reponse.data.length > 0) {
              setMessageAucunReglement();
            } else {
              setMessageAucunReglement("Aucun règlement pour cette facture");
            }
            setReglements(reponse.data);
          } else {
            setMessageErreur(
              "Erreur lors de la récupération de l'historique des règlements"
            );
          }
        })
        .catch((error) => {
          setIsLoading(false);
          setMessageErreur(
            "Erreur lors de la récupération de l'historique des règlements"
          );
          setError(error);
        });
    }
    // eslint-disable-next-line
  }, [facture, open]);

  //DELETE annuler un règlement
  const handleAnnuler = (reglement_id) => {
    deleteReglement(reglement_id)
      .then((reponse) => {
        setIsLoading(false);
        if (reponse.code === "OK") {
        } else {
          setMessageErreur(reponse.message);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        setMessageErreur("Erreur lors de l'annulation du règlement");
        setError(error);
      });
  };

  return (
    <Collapse in={open}>
      <div>
        <LoaderHorizontal isLoading={isLoading} />
        <MessageErreur message={messageErreur} error={error} />

        <div className="TitreBloc">Historique des règlements</div>

        <div className={MonStyle.ListeReglements}>
          <MessageAucuneDonnee
            message={messageAucunReglement}
            hideIllustration
          />

          {reglements.map((reglement) => (
            <div key={reglement._id} className={MonStyle.LigneReglement}>
              <ObjetReglement reglement={reglement} isAfficherDate />
              <div className={MonStyle.BoutonAnnuler}>
                {reglement.code_type_reglement === "REGLEMENT" && (
                  <BoutonActionSecondaire
                    texte="Annuler"
                    taille="S"
                    onClick={() => handleAnnuler(reglement._id)}
                    disabled={reglement.is_rendu}
                  />
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </Collapse>
  );
}
