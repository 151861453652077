import React, { useState, useEffect } from "react";
import { useContext } from "react";
import BoutonFavori from "../../../Design/Boutons/BoutonFavori";
import { ProduitsContext } from "../../../Data/ProduitsContext";

export default function BoutonProduitFavori({
  produit_id,
  produit_external_id,
  id,
  style,
}) {
  //NES Gestion du contexte
  const { isProduitFavori, postProduitsFavoris, deleteProduitsFavoris } =
    useContext(ProduitsContext);

  //NES gestion du state
  const [isFavori, setIsFavori] = useState(false);

  //USEEFFECT
  useEffect(() => {
    isProduitFavori(produit_id).then((reponse) => {
      if (reponse === true) {
        setIsFavori(true);
      } else {
        setIsFavori(false);
      }
    });
    // eslint-disable-next-line
  }, [produit_id]);

  //NES Quand on clique
  const handleClick = () => {
    if (isFavori) {
      //DELETE Favori
      deleteProduitsFavoris(produit_external_id).then((reponse) =>
        setIsFavori(false)
      );
    } else {
      //POST Favori
      postProduitsFavoris(produit_external_id).then((reponse) =>
        setIsFavori(true)
      );
    }
  };

  if (!produit_external_id) {
    return "";
  }

  return (
    <BoutonFavori
      id={id}
      onClick={handleClick}
      isFavori={isFavori}
      style={style}
    />
  );
}
