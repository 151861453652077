import React, { useEffect } from "react";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";

export default function MessageAlerte({ message, severity, ...props }) {
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    setOpen(message ? true : false);
  }, [message]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  return (
    <Snackbar
      open={open}
      autoHideDuration={6000}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
    >
      <Alert
        elevation={6}
        variant="filled"
        onClose={handleClose}
        severity={severity ? severity : "success"}
        {...props}
      >
        {message}
      </Alert>
    </Snackbar>
  );
}
