import React from "react";
import MonStyle from "./AffichageSyntheseConsultation.module.css";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import NotificationsNoneIcon from "@material-ui/icons/NotificationsNone";
import AffichageMesure from "../AffichageMesure/AffichageMesure";
import { Link } from "react-router-dom";
import { DateShortOuHeureToString } from "../../../../functions/FonctionsDateHeure";
import { couleurAction } from "../../../../functions/FonctionsCouleurs";

function AfficherConstantes({ mesures, selected }) {
  if (mesures) {
    return (
      <div className={MonStyle.AfficherConstantes}>
        {mesures.map((mesure, index) => (
          <AffichageMesure
            mesure={mesure}
            key={index}
            color={selected ? "#FFFFFF" : ""}
          />
        ))}
      </div>
    );
  } else {
    return "";
  }
}

export default function AffichageSyntheseConsultation({
  consultation,
  link,
  selected,
}) {
  ///STYLE
  let style = {
    borderRadius: "8px",
    background: selected ? couleurAction() : "",
  };

  //NES pour voir facilement les anamnèses dans l'historique
  if (!selected && consultation.type_consultation.code === "anamnese") {
    style = {
      ...style,
      background: consultation.type_consultation.couleur + "15",
    };
  }

  //NES pour voir facilement les anamnèses dans l'historique
  if (!selected && consultation.is_archive) {
    style = {
      ...style,
      background:
        "linear-gradient(  45deg,  #ddd 5%,  #fff 5%,  #fff 45%,  #ddd 45%, #ddd 55%, #fff 55%, #fff 95%,#ddd 95%)",
      backgroundSize: "10px 10px",
      backgroundPosition: "0 0",
      border: "2px dashed #00000080",
      opacity: 0.5,
    };
  }

  ///TITRE
  let titre = "";
  if (consultation.motifs) {
    titre = consultation.motifs.map((item) => item.libelle).join(", ");
  } else {
    titre = consultation.type_consultation.libelle;
  }

  return (
    <Link
      className={MonStyle.AffichageSyntheseConsultation}
      to={link}
      style={style}
    >
      <div className={MonStyle.ZoneIcone}>
        {consultation?.has_documents && (
          <AttachFileIcon
            style={{ fontSize: "20px", color: selected ? "white" : "#97989D" }}
            className={MonStyle.Icone}
          />
        )}
        {consultation?.courriers?.length > 0 && (
          <MailOutlineIcon
            style={{ fontSize: "20px", color: selected ? "white" : "#97989D" }}
            className={MonStyle.Icone}
          />
        )}
        {consultation?.rappels?.length > 0 && (
          <NotificationsNoneIcon
            style={{ fontSize: "20px", color: selected ? "white" : "#97989D" }}
            className={MonStyle.Icone}
          />
        )}
      </div>

      <div
        className={MonStyle.TypeConsultation}
        style={{
          backgroundColor: consultation?.type_consultation?.couleur,
        }}
      ></div>

      <div className={MonStyle.Affichage}>
        <header className={MonStyle.Entete}>
          <div
            className={MonStyle.MotifConsultation}
            style={{ color: selected ? "white" : "" }}
          >
            {titre}
          </div>
          <div
            className={MonStyle.DateConsultation}
            style={{ color: selected ? "white" : "" }}
          >
            {DateShortOuHeureToString(consultation.date_consultation)}
          </div>
        </header>

        <div
          className={MonStyle.Description}
          style={{ color: selected ? "white" : "" }}
        >
          {consultation.description}
        </div>
        <div
          className={MonStyle.BilanEnergetique}
          style={{ color: selected ? "white" : "" }}
        >
          {consultation.bilan_energetique}
        </div>
        <div
          className={MonStyle.Diagnostic}
          style={{
            color: selected
              ? "white"
              : consultation?.type_consultation?.couleur,
          }}
        >
          {consultation.diagnostic}
          {consultation.traitement_energetique}
        </div>

        <AfficherConstantes
          mesures={consultation.mesures}
          selected={selected}
        />
      </div>
    </Link>
  );
}
