import React, { useContext, useEffect, useState } from "react";
import MonStyle from "./JourPlanningPicker.module.css";
import { AdpxContext } from "../../../Data/AdpxContext";
import { DateToString } from "../../../functions/FonctionsDateHeure";
import CapaciteJours from "./CapaciteJours";

export default function JourPlanningPicker({ onSelect }) {
  //NES gestion du contexte
  const { jours } = useContext(AdpxContext);

  //NES gestion du state
  const [groupes, setGroupe] = useState([]);

  const handleClick = (jour) => {
    if (onSelect) {
      onSelect(jour);
    }
  };

  useEffect(() => {
    let tempArray = [];

    jours.forEach((jour) => {
      let maDate = new Date(jour.date);
      let mois = maDate.toLocaleDateString("fr-FR", { month: "long" });
      let index = tempArray.findIndex((item) => item.mois === mois);

      if (index === -1) {
        //NES je créé le groupe
        let newGroupe = { mois: mois, listeJours: [] };
        tempArray.push(newGroupe);
      } else {
        //NES j'ajoute le jour au mois
        let tempGroupe = tempArray[index];
        tempGroupe.listeJours.push(jour);
      }
    });

    setGroupe(tempArray);
  }, [jours]);

  return (
    <div className={MonStyle.JourPlanningPicker}>
      {groupes.map((groupe) => (
        <div className={MonStyle.Groupe} key={groupe.mois}>
          <div className={MonStyle.Mois}>{groupe.mois}</div>
          {groupe.listeJours.map((jour) => (
            <div
              onClick={() => handleClick(jour)}
              key={jour._id}
              className={
                DateToString(jour.date, {
                  weekday: "short",
                }) === "lun."
                  ? MonStyle.Lundi
                  : MonStyle.Jour
              }
            >
              <div>
                {DateToString(jour.date, {
                  weekday: "short",
                  month: "long",
                  day: "numeric",
                })}
              </div>

              <div>
                <CapaciteJours jour={jour} />
              </div>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
}
