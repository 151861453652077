import React, { useContext, useEffect, useState } from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { Link } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { isMobile } from "../../../../functions/Navigateur";
import { ContactsContext } from "../../../../Data/ContactsContext";
import { DocumentsContext } from "../../../../Data/DocumentsContext";

const BeTabs = withStyles({
  root: {
    borderBottom: "none",
  },
  indicator: {
    backgroundColor: "#205A71",
  },
})(Tabs);

const BeTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    color: "#666",
    minWidth: 72,
    fontSize: "14px",
    fontWeight: 500,
    padding: "0px 0px ",
    //fontWeight: theme.typography.fontWeightRegular,
    marginRight: "20px",
    fontFamily: ["Raleway"].join(","),
    "&:hover": {
      color: "#205A71",
      opacity: 1,
    },
    "&$selected": {
      color: "#205A71",
      border: "none",
      fontWeight: 700,

      //fontWeight: theme.typography.fontWeightMedium,
    },
    "&:focus": {
      color: "#205A71",
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);

export default function OngletDossier({ patient_id }) {
  //NES gestion du contexte
  const { contacts } = useContext(ContactsContext);
  const { documents, getDocumentsPatient } = useContext(DocumentsContext);

  //NES Gestion du state
  const [ongletSelectionne, setOngletSelectionne] = useState(0);

  //USEEFFECT
  useEffect(() => {
    getDocumentsPatient(patient_id);
    // eslint-disable-next-line
  }, [patient_id]);

  //NES URL de la page
  let url = document.location.href;
  useEffect(() => {
    if (url.indexOf("consultations") > -1) {
      setOngletSelectionne(0);
    }
    if (url.indexOf("dossier") > -1) {
      setOngletSelectionne(1);
    }
    if (url.indexOf("allergies") > -1) {
      setOngletSelectionne(2);
    }
    if (url.indexOf("constantes") > -1) {
      setOngletSelectionne(3);
    }
    if (url.indexOf("documents") > -1) {
      setOngletSelectionne(4);
    }
    if (url.indexOf("messages") > -1) {
      setOngletSelectionne(5);
    }
    if (url.indexOf("rendezvous") > -1) {
      setOngletSelectionne(6);
    }
    if (url.indexOf("contacts") > -1) {
      setOngletSelectionne(7);
    }
  }, [url]);

  //NES Gestion des onglets du dossier
  const OngletsDossier = [
    {
      id: 1,
      texte: "Consultations",
      link: "/patients/" + patient_id + "/consultations/",
    },
    { id: 2, texte: "Dossier", link: "/patients/" + patient_id + "/dossier/" },
    {
      id: 3,
      texte: "Allergies & antécédents",
      link: "/patients/" + patient_id + "/allergies",
    },
    {
      id: 4,
      texte: "Constantes",
      link: "/patients/" + patient_id + "/constantes",
    },
    {
      id: 5,
      texte:
        documents.length > 0
          ? "Documents (" + documents.length + ")"
          : "Documents",
      link: "/patients/" + patient_id + "/documents",
    },
    {
      id: 6,
      texte: "Messages",
      link: "/patients/" + patient_id + "/messages",
    },
    {
      id: 7,
      texte: "Rendez-vous",
      link: "/patients/" + patient_id + "/rendezvous",
    },

    {
      id: 8,
      texte:
        contacts.length > 0
          ? "Médecins et contacts (" + contacts.length + ")"
          : "Médecins et contacts",
      link: "/patients/" + patient_id + "/contacts",
    },
  ];

  //NES On n'affiche pas le composant sur mobile
  if (isMobile()) {
    return "";
  }

  return (
    <div
      style={{
        maxWidth: "100%",
        height: "48px",
        minHeight: "48px",
        display: "flex",
        justifyContent: "flex-start",
        boxSizing: "border-box",
        borderBottom: "1px solid #DDD",
        backgroundColor: "white",
      }}
    >
      <BeTabs
        value={ongletSelectionne}
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable"
        scrollButtons="on"
      >
        {OngletsDossier.map((item) => (
          <BeTab
            selected
            label={item.texte}
            component={Link}
            to={item.link}
            key={item.texte}
          />
        ))}
      </BeTabs>
      {/* 
      
 
      <BoutonAjouterPostitPatient patient_id={patient_id} />
           */}
    </div>
  );
}
