import React, { useState, useEffect, useContext } from "react";
import { AuthentificationContext } from "../../../Data/AuthentificationContext";

export default function Deconnexion() {
  //NES gestion du contexte
  const auth = useContext(AuthentificationContext);

  //NES gestion du state
  const [timer, setTimer] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      const delaiMillisecondes = Math.round(
        (auth.payload.exp * 1000 - Date.now()) / 1000
      );

      /*
      let heures = Math.round(delaiMillisecondes / 3600);
      let minutes = Math.round((delaiMillisecondes % 3600) / 60);
      let secondes = Math.round((delaiMillisecondes % 3600) % 60);
      */

      //console.log({ heures, minutes, secondes });

      //NES 90 secondes avant la fin du délai d'expiration du token je le refresh
      if (delaiMillisecondes < 90) {
        auth.autoRefreshToken();
      }

      setTimer(delaiMillisecondes);
    }, 1000);
    return () => clearInterval(interval);
  }, [auth]);

  /*
  if (window.location.hostname === "localhost") {
    return (
      <div
        style={{
          backgroundColor: "#FFFFFF20",
          minWidth: "40px",
          textAlign: "center",
          color: "orange",
          height: "40px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "5px",
          border: "1px solid orange",
          marginLeft: "10px",
          padding: "0px 10px",
        }}
      >
        {timer}
      </div>
    );
  }
  */

  if (window.location.hostname !== "localhost") {
    return "";
  }

  return (
    <div
      style={{
        backgroundColor: "#FFFFFF20",
        minWidth: "40px",
        textAlign: "center",
        color: "orange",
        height: "40px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "5px",
        border: "1px solid orange",
        marginLeft: "10px",
        padding: "0px 10px",
      }}
    >
      {timer}
    </div>
  );
}
