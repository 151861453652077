import React, { useState, createContext, useContext } from "react";
import { DELETE, GET, PATCH, POST } from "../functions/FonctionsApi";
import { AuthentificationContext } from "./AuthentificationContext";

export const PostitsPatientsContext = createContext();

export const PostitsPatientsProvider = (props) => {
  //NES gestion du contexte
  const authContext = useContext(AuthentificationContext);

  //NES gestion du state
  const [postitPatients, setPostitPatients] = useState([]);

  //POST d'un post-it
  const postPostit = async (patient_id) => {
    let data = {
      texte: "",
      is_medical: false,
      position: getNextPosition(patient_id),
    };

    //NES j'appelle l'API
    const MonUrl = `${process.env.REACT_APP_URL_API_PATIENTS}/patients/${patient_id}/postits/`;
    const reponseApi = await POST(MonUrl, authContext.token, data);
    //NES je le garde dans le contexte
    if (reponseApi.code === "OK") {
      let tempArray = [...postitPatients];
      tempArray.push(reponseApi.data);
      setPostitPatients(tempArray);
    }
    //NES je retourne à ma fonction appelante le résultat
    return reponseApi;
  };

  //GET récupération des post-it du patient
  const getPostit = async (patient_id) => {
    //NES j'appelle l'API
    const MonUrl = `${process.env.REACT_APP_URL_API_PATIENTS}/patients/${patient_id}/postits/`;

    let reponseAPI = await GET(MonUrl, authContext.token);

    //NES Je conserve dans le contexte
    if (reponseAPI.code === "OK") {
      setPostitPatients(reponseAPI.data);
    }
    //NES je retourne à ma fonction appelante le résultat
    return reponseAPI;
  };

  //NES permet de calculer l'affichage du postit suivant
  const getNextPosition = (patient_id) => {
    let retour = { top: "200px", left: "200px", zindex: "100" };
    let tempArray = [...postitPatients];

    //NES récupération des postits de mon patient
    tempArray = tempArray.filter((item) => item.patient_id === patient_id);

    //NES récupération du dernier postit
    let dernierPostit = tempArray[tempArray.length - 1];

    if (dernierPostit) {
      //NES calcul de la nouvelle position
      let newTop = parseInt(dernierPostit.position.top.replace("px", "")) + 100;
      let nexLeft =
        parseInt(dernierPostit.position.left.replace("px", "")) + 100;
      let newIndex = parseInt(dernierPostit.position.zindex) + 1;

      retour = {
        top: newTop + "px",
        left: nexLeft + "px",
        zindex: newIndex,
      };
    }

    return retour;
  };

  //DELETE suppression du post-it
  const deletePostit = async (patient_id, postit_id) => {
    //NES j'appelle l'API
    const MonUrl = `${process.env.REACT_APP_URL_API_PATIENTS}/patients/${patient_id}/postits/${postit_id}`;
    const reponseApi = await DELETE(MonUrl, authContext.token);

    //NES Mise à jour du contexte
    if (reponseApi.code === "OK") {
      let tempArray = [...postitPatients];
      let index = tempArray.findIndex((item) => item._id === postit_id);
      tempArray.splice(index, 1);
      setPostitPatients(tempArray);
    }
    //NES je retourne à ma fonction appelante le résultat
    return reponseApi;
  };

  //PATCH modification d'un post-it
  const patchPostitContext = async (data) => {
    //NES j'appelle l'API
    const MonUrl = `${process.env.REACT_APP_URL_API_PATIENTS}/patients/${data.patient_id}/postits/${data._id}`;
    const reponseApi = await PATCH(MonUrl, authContext.token, data);

    //NES je le garde dans le contexte
    if (reponseApi.code === "OK") {
      let tempArray = [...postitPatients];
      let index = tempArray.findIndex((item) => item._id === data._id);
      tempArray.splice(index, 1, reponseApi.data);
      setPostitPatients(tempArray);
    }
    //NES je retourne à ma fonction appelante le résultat
    return reponseApi;
  };

  //NES calcul du zindex max pour un patient
  const calculMaxZindex = (patient_id) => {
    //NES calcul du Zindex max
    let max = 1;
    //NES je récupère les postits du patient
    let listePostit = postitPatients.filter(
      (item) => item.patient_id === patient_id
    );
    //NES je cherche le max
    listePostit.forEach((item) => {
      if (parseInt(item.position.zindex) > max) {
        max = parseInt(item.position.zindex);
      }
    });
    return max;
  };

  return (
    <PostitsPatientsContext.Provider
      value={{
        postitPatients,
        postPostit,
        getPostit,
        deletePostit,
        patchPostitContext,
        calculMaxZindex,
      }}
    >
      {props.children}
    </PostitsPatientsContext.Provider>
  );
};
