import React from "react";
import SurfacePopUp from "../../../../Design/Surfaces/SurfacePopUp";
import InputTexte from "../../../../Design/Formulaires/InputTexte";
import BoutonActionPrincipale from "../../../../Design/Boutons/BoutonActionPrincipale";
import { useState, useContext, useEffect } from "react";
import { ProduitsContext } from "../../../../Data/ProduitsContext";
import MessageErreur from "../../../../Design/Messages/MessageErreur";

export default function EditionCodePraticien({ laboratoire, onClose }) {
  //NES gestion du state
  const [code, setCode] = useState("");
  const [erreur, setErreur] = useState("");
  const [error, setError] = useState("");

  //NES gestion du contexte
  const { putLaboratoireCodePraticien } = useContext(ProduitsContext);

  //USEEFFECT
  useEffect(() => {
    if (laboratoire) {
      setCode(laboratoire.code_praticien);
    }
  }, [laboratoire]);

  ///handleSauvegarder
  const handleSauvegarder = () => {
    let newData = { code_praticien_commande: code };
    putLaboratoireCodePraticien(laboratoire?._id, newData).then((reponse) => {
      if (reponse.code === "OK") {
        onClose();
      } else {
        setErreur("Erreur lors de l'enregistrement du code praticien");
        setError(reponse);
      }
    });
  };

  return (
    <SurfacePopUp
      open={laboratoire ? true : false}
      titre="Code praticien"
      sousTitre={laboratoire?.nom}
      onClose={onClose}
      style={{ minWidth: "300px" }}
    >
      <p>
        Le code praticien apparait sur les bons de commandes imprimés par Sinobi
      </p>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "20px",
          margin: "0px auto",
          width: "300px",
        }}
      >
        <InputTexte
          placeholder={"code praticien " + laboratoire?.nom}
          autoFocus
          value={code}
          onChange={(e) => setCode(e.target.value)}
        />
        <MessageErreur message={erreur} error={error} />
        <BoutonActionPrincipale
          texte="Enregistrer le code praticien"
          onClick={handleSauvegarder}
        />
      </div>
    </SurfacePopUp>
  );
}
