import React, { useContext, useEffect, useState } from "react";
import MonStyle from "./PageHistoriqueConsultations.module.css";
import { ConsultationsContext } from "../../../../Data/ConsultationsContext";
import AffichageSyntheseConsultation from "../../Components/AffichageConsultation/AffichageSyntheseConsultation";
import BoutonRond from "../../../../Design/Boutons/BoutonRond";
import ListItem from "@material-ui/core/ListItem";
import AddIcon from "@material-ui/icons/Add";
import MessageErreur from "../../../../Design/Messages/MessageErreur";
import { useHistory } from "react-router-dom";
import InputRecherche from "../../../../Design/Recherche/InputRecherche";
import { DateOuHeureToString } from "../../../../functions/FonctionsDateHeure";
import FiltreTypeConsultation from "../../../../Design/Recherche/Filtres/FiltreTypeConsultation";
import FiltreUtilisateur from "../../../../Design/Recherche/Filtres/FiltreUtilisateur";
import Collapse from "@material-ui/core/Collapse";
import { isPetitEcran } from "../../../../functions/Navigateur";
import SeparateurBloc from "../../../../Design/DataDisplay/SeparateurBloc";
import BoutonTexte from "../../../../Design/Boutons/BoutonTexte";
import IllustrationPiedDePage from "../../../../Images/Illustrations/Illustrations";
import { isSinobi } from "../../../../functions/FonctionsEnvironnement";
import { DateToString } from "../../../../functions/FonctionsDateHeure";
import { PatientsContext } from "../../../../Data/PatientsContext";
import { CabinetsContext } from "../../../../Data/CabinetContext";
import LoaderHorizontal from "../../../../Design/Progression/LoaderHorizontal";
import { pluriel } from "../../../../functions/Formatter";
import FiltreArchive from "../../../../Design/Recherche/Filtres/FiltreArchive";
import MessageAucuneDonnee from "../../../../Design/Messages/MessageAucuneDonnee";
import BoutonActionPrincipale from "../../../../Design/Boutons/BoutonActionPrincipale";
import { UtilisateursContext } from "../../../../Data/UtilisateursContext";
import AffichageDonneesMedicales from "../../../../Design/DataDisplay/AffichageDonneesMedicales";

export default function PageHistoriqueConsultations({ match }) {
  //NES récupération de l'ID dans l'URL
  let idUrlPatient = match ? match.params.idpatient : "";
  let idUrlConsultation = match?.params?.idconsultation;
  let urlConsultation = "/patients/" + idUrlPatient + "/consultations/";

  //NES Gestion du contexte
  const {
    consultations,
    postConsultationContext,
    getTypeConsultationById,
    getTypeConsultationGeneriqueByCode,
    getConsultations,
  } = useContext(ConsultationsContext);

  //const auth = useContext(AuthentificationContext);
  const { getObjetPatient } = useContext(PatientsContext);
  const { getObjetCabinet } = useContext(CabinetsContext);
  const { getPreferencesUtilisateur } = useContext(UtilisateursContext);

  //NES Gestion du state
  const [groupesConsultations, setGroupesConsultations] = useState([]);
  const [messageErreur, setMessageErreur] = useState("");
  const [filtreTexte, setFiltreTexte] = useState("");
  const [filtreTypeConsultation, setFiltreTypeConsultation] = useState({});
  const [filtreArchive, setFiltreArchive] = useState(false);
  const [afficherFiltres, setAfficherFiltres] = useState(false);
  const [filtreUtilisateur, setFiltreUtilisateur] = useState({});
  const [sousTitre, setSousTitre] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const [conseil, setConseil] = useState("");

  //NES Gestion du routage pour faire des redirections
  let history = useHistory();

  //USEEFFECT
  useEffect(() => {
    //NES Si n'a pas de consultation sélectionnnée, je sélectionne la 1ère automatiquement

    if (
      !isPetitEcran() &&
      groupesConsultations.length > 0 &&
      !idUrlConsultation &&
      !isLoading
    ) {
      let newUrl =
        urlConsultation + groupesConsultations[0].consultations[0]._id;
      history.push(newUrl);
    }

    //NES aucun consultation
    if (!isLoading && groupesConsultations.length === 0) {
      setMessage("Aucune consultation trouvée");
      if (filtreTexte) {
        setConseil("Essayez de modifier votre recherche");
      } else {
        setConseil("");
      }
    } else {
      setMessage("");
      setConseil("");
    }
    // eslint-disable-next-line
  }, [groupesConsultations, isLoading]);

  //USEEFFECT quand on charge la page
  useEffect(() => {
    if (idUrlPatient) {
      setGroupesConsultations([]);
      console.log("PageHistoriqueConsultations", idUrlPatient);

      getConsultations(idUrlPatient).then((reponse) => {
        setIsLoading(false);

        if (reponse.code !== "OK") {
          setMessageErreur("Erreur lors de la récupération des consultations");
          setError(reponse);
        }
      });
    }
    // eslint-disable-next-line
  }, [idUrlPatient]);

  //USEEFFECT Mise en groupe et filtrage
  useEffect(() => {
    let tempArray = [...consultations];

    //NES ne pas afficher les consultations archivées
    if (!filtreArchive) {
      tempArray = tempArray.filter((item) => item.is_archive !== true);
    }

    //NES je trie les résultats du plus récent au moins récent
    tempArray.sort((b, a) => {
      return new Date(a.date_consultation) - new Date(b.date_consultation);
    });

    //NES construction du sousTitres
    let nbConsultations = tempArray.length;
    let premiereConsultation = tempArray[nbConsultations - 1];

    let texte =
      pluriel(tempArray.length, "consultation", "consultations") +
      " depuis le " +
      DateToString(
        premiereConsultation?.tracabilite_creation?.date_tracabilite,
        { year: "numeric", month: "long" }
      );

    if (nbConsultations === 0) {
      texte = "Aucune consultation";
    }

    setSousTitre(texte);

    //NES je filtre avec le texte de recherche
    if (filtreTexte && filtreTexte !== "") {
      tempArray = tempArray.filter((item) => {
        //NES construction dynamique de la chaine de recherche on cherche sur tous les éléments affichées
        let chaineRecherche = DateOuHeureToString(item.date_consultation);
        chaineRecherche = chaineRecherche + item.type_consultation.libelle;
        chaineRecherche = chaineRecherche + item.description;
        chaineRecherche = chaineRecherche + item.diagnostic;

        if (item.motifs) {
          chaineRecherche =
            chaineRecherche + item.motifs.map((item) => item.libelle).join(" ");
        }

        let regExp = new RegExp(`${filtreTexte.replace(" ", "(.*)")}`, "i");
        let index = chaineRecherche.search(regExp);
        return index > -1 ? true : false;
      });
    }

    //NES je filtre par type de consultation
    if (filtreTypeConsultation.libelle) {
      tempArray = tempArray.filter((item) => {
        return (
          item.type_consultation.libelle === filtreTypeConsultation.libelle
        );
      });
    }

    //NES je filtre par utilisateur
    if (filtreUtilisateur._id) {
      tempArray = tempArray.filter(
        (item) =>
          item.tracabilite_creation.utilisateur_id === filtreUtilisateur._id
      );
    }

    //NES affichage par groupes
    let groupes = [];
    tempArray.forEach((consultation) => {
      let dateConsultation = new Date(consultation.date_consultation);
      let aujourdhui = new Date();
      let anneeCourante = aujourdhui.toLocaleDateString("fr-FR", {
        year: "numeric",
      });
      let mois = dateConsultation.toLocaleDateString("fr-FR", {
        month: "long",
      });
      let annee = dateConsultation.toLocaleDateString("fr-FR", {
        year: "numeric",
      });
      let titreGroupe = annee === anneeCourante ? mois : annee;

      let index = groupes.findIndex((item) => item.titre === titreGroupe);
      if (index === -1) {
        //NES je dois créer le groupe
        let newGroupe = {
          titre: titreGroupe,
          consultations: [consultation],
        };
        groupes.push(newGroupe);
      } else {
        let tempGroupe = groupes[index];
        tempGroupe.consultations.push(consultation);
        groupes.splice(index, 1, tempGroupe);
      }
    });

    setGroupesConsultations(groupes);
  }, [
    consultations,
    filtreTexte,
    filtreTypeConsultation,
    filtreUtilisateur,
    filtreArchive,
  ]);

  //POST ajout d'une consultation
  const ajouterConsultation = async () => {
    //NES Récupération du type par défaut du produit
    let code = "consultation";
    if (isSinobi()) {
      if (groupesConsultations.length === 0) {
        code = "anamnese";
      } else {
        code = "consultation_energetique";
      }
    } else {
      code = "consultation";
    }
    let dataTypeConsultation = await getTypeConsultationGeneriqueByCode(code);

    //NES on va selectionner le type de consultation par défaut selon les préférences de l'utilisateur
    let reponse = await getPreferencesUtilisateur();
    if (
      reponse.code === "OK" &&
      reponse.data.affichage_type_consultation_par_defaut
    ) {
      let typeConsultationId =
        reponse.data.affichage_type_consultation_par_defaut;
      let tempType = await getTypeConsultationById(typeConsultationId);
      //NES cas des types de consultations archivés
      if (tempType) {
        dataTypeConsultation = tempType;
      }
    }

    //NES creation de l'objet de la consultation
    let aujourdhui = new Date();
    let consultationJson = {
      date_consultation: aujourdhui,
      type_consultation: {
        libelle: dataTypeConsultation.libelle,
        code: dataTypeConsultation.code,
        couleur: dataTypeConsultation.couleur,
      },
      cabinet: getObjetCabinet(),
      patient: getObjetPatient(idUrlPatient),
    };

    console.log(consultationJson);

    //NES POST de la consultation
    postConsultationContext(idUrlPatient, consultationJson).then((reponse) => {
      setMessageErreur("");
      if (reponse.code === "OK") {
        //NES redirection sur la nouvelle consultation
        history.push(urlConsultation + reponse.data._id);
      } else {
        setMessageErreur(
          "Erreur de lors de l'enregistrement de la nouvelle consultation"
        );
        setError(reponse);
      }
    });
  };

  return (
    <div className={MonStyle.PageHistoriqueConsultations} id="historique">
      <AffichageDonneesMedicales taille="L" titre="Affichage des consultations">
        <header className={MonStyle.Entete}>
          <div>
            <div className="Titre1">Historique</div>
            <div className="SousTitre">{sousTitre}</div>
          </div>
          <BoutonRond
            couleur="vert"
            iconeComposant={<AddIcon />}
            couleurIcone="blanc"
            tooltip="Ajouter une consultation"
            onClick={ajouterConsultation}
          />
        </header>

        {/* FILTRES */}
        <div className={MonStyle.Filtres}>
          <InputRecherche
            value={filtreTexte}
            placeholder="Rechercher dans l'historique"
            onChange={(event) => setFiltreTexte(event.target.value)}
            autoComplete="RechercheConsultation"
            onEffacer={() => setFiltreTexte("")}
          />
          <Collapse in={afficherFiltres}>
            <div
              style={{
                margin: "10px 0px 6px 0px",
                display: "flex",
                flexDirection: "column",
                gap: "10px",
              }}
            >
              <FiltreTypeConsultation
                onChange={(type) => setFiltreTypeConsultation(type)}
              />
              <FiltreUtilisateur
                onChange={(utilisateur) => setFiltreUtilisateur(utilisateur)}
              />
              <FiltreArchive
                label="Afficher les consultations archivées"
                texte="Consultations archivées"
                onChange={(value) => setFiltreArchive(value)}
              />
            </div>
          </Collapse>
          <BoutonTexte
            texte={
              afficherFiltres
                ? "Masquer les filtres"
                : "Type de consultation, praticien..."
            }
            fullWidth
            onClick={() => setAfficherFiltres(!afficherFiltres)}
            style={{ marginTop: "4px" }}
          />
        </div>

        <LoaderHorizontal isLoading={isLoading} />
        <MessageErreur
          message={messageErreur}
          error={error}
          style={{ marginTop: "10px" }}
        />
        <MessageAucuneDonnee message={message} conseil={conseil}>
          {!filtreTexte && (
            <BoutonActionPrincipale
              texte="Nouvelle une consultation"
              style={{ marginTop: "20px" }}
              onClick={ajouterConsultation}
            />
          )}
        </MessageAucuneDonnee>

        <div className={MonStyle.Historique}>
          {groupesConsultations.map((groupe) => (
            <div key={groupe.titre}>
              <SeparateurBloc titre={groupe.titre} key={groupe.titre} />
              <div className={MonStyle.Groupe}>
                {groupe.consultations.map((consultation) => (
                  <ListItem
                    button
                    disableGutters
                    key={consultation._id}
                    selected={idUrlConsultation === consultation._id}
                    style={{
                      padding: 0,
                      borderRadius: "8px",
                    }}
                  >
                    <AffichageSyntheseConsultation
                      consultation={consultation}
                      link={urlConsultation + consultation._id}
                      selected={idUrlConsultation === consultation._id}
                    />
                  </ListItem>
                ))}
              </div>
            </div>
          ))}
        </div>

        <div className={MonStyle.IllustrationPiedPage}>
          <IllustrationPiedDePage />
        </div>
      </AffichageDonneesMedicales>
    </div>
  );
}
