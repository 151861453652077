import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Fade from "@mui/material/Fade";
import CircularProgress from "@mui/material/CircularProgress";

const CircularProgressCss = withStyles((theme) => ({
  colorPrimary: {
    color: (props) => (props.couleur ? props.couleur : "#0FA600"),
  },
}))(CircularProgress);

export default function LoaderCirculaire({
  isLoading,
  color,
  size,
  style,
  progression,
}) {
  return (
    <Fade
      in={isLoading || progression}
      style={{
        transitionDelay: isLoading ? "100ms" : "0ms",
        ...style,
      }}
      unmountOnExit
    >
      <CircularProgressCss
        variant={progression ? "determinate" : "indeterminate"}
        value={progression}
        couleur={color}
        size={size ? 30 : 40}
      />
    </Fade>
  );
}
