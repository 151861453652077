import React, { useState, createContext, useContext } from "react";
import { DELETE } from "../functions/FonctionsApi";
import { AuthentificationContext } from "./AuthentificationContext";
import { PerformancesContext } from "./PerformancesContext";

//NES Création du contexte
export const ContactsContext = createContext();

export const ContactsProvider = (props) => {
  //NES gestion du contexte
  const authContext = useContext(AuthentificationContext);
  const { GET } = useContext(PerformancesContext);

  //NES Gestion du state
  const [contacts, setContacts] = useState([]);
  const [medecinTraitant, setMedecinTraitant] = useState();
  const [specialites, setSpecialites] = useState([]);

  //GET /annuaire/suggestions?type="MEDECIN"
  const getAnnuaireSuggestions = async (codeSpecialite) => {
    let param = "";
    if (codeSpecialite) {
      param = "?code=" + codeSpecialite;
    }
    //NES j'appelle l'API
    let MonUrl =
      `${process.env.REACT_APP_URL_API_SESAMVITAL}/annuaire/suggestions` +
      param;

    let reponseJson = await GET(MonUrl);

    //NES je retourne à ma fonction appelante le résultat
    return reponseJson;
  };

  //GET /annuaire?type="MEDECIN"&recherche="HOU"&nb=50
  const getAnnuaire = async (recherche, codeSpecialite) => {
    let param = "";
    if (recherche) {
      param = "?recherche=" + recherche;
      if (codeSpecialite) {
        param = param + "&code=" + codeSpecialite;
      }
    }

    //NES j'appelle l'API
    let MonUrl = `${process.env.REACT_APP_URL_API_SESAMVITAL}/annuaire` + param;

    let reponseJson = await GET(MonUrl);

    //NES je retourne à ma fonction appelante le résultat
    return reponseJson;
  };

  //POST Contact
  const postContact = async (patient_id, data, save) => {
    let param = "";
    if (save) {
      param = "?save=1";
    }

    //NES j'appelle l'API
    let MonUrl =
      `${process.env.REACT_APP_URL_API_SESAMVITAL}/patients/${patient_id}/contacts` +
      param;
    //NES pour détecter les appels en boucle
    console.log({ POST: MonUrl });
    const reponseApi = await fetch(MonUrl, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        token: authContext.token,
      },
      body: JSON.stringify(data),
    });
    //NES je convertis ce que je récupère en JSON
    const reponseJson = await reponseApi.json();
    //NES je le garde dans le contexte
    if (reponseJson.code === "OK") {
      if (reponseJson.data.is_medecin_traitant) {
        setMedecinTraitant(reponseJson.data);
      } else {
        let tempArray = [...contacts];
        tempArray.push(reponseJson.data);
        setContacts(tempArray);
      }
    }
    //NES je retourne à ma fonction appelante le résultat
    return reponseJson;
  };

  //DELETE Contact
  const deleteContact = async (patient_id, contact_id) => {
    //NES j'appelle l'API
    let MonUrl = `${process.env.REACT_APP_URL_API_SESAMVITAL}/patients/${patient_id}/contacts/${contact_id}`;
    //NES pour détecter les appels en boucle
    console.log({ DELETE: MonUrl });
    const reponseApi = await fetch(MonUrl, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        token: authContext.token,
      },
    });
    //NES je convertis ce que je récupère en JSON
    const reponseJson = await reponseApi.json();
    //NES je le garde dans le contexte
    if (reponseJson.code === "OK") {
      let tempArray = [...contacts];
      let index = tempArray.findIndex((item) => item._id === contact_id);

      if (index > -1) {
        tempArray.splice(index, 1);
      }
      setContacts(tempArray);
    }
    //NES je retourne à ma fonction appelante le résultat
    return reponseJson;
  };

  //NES suppression du médecin traitant
  const deleteMedecinTraitant = (patient_id) => {
    let contact_id = medecinTraitant._id;
    deleteContact(patient_id, contact_id).then((reponse) => {
      setMedecinTraitant();
      return reponse;
    });
  };

  //GET contact
  const getContacts = async (patient_id) => {
    //NES est ce que j'ai déjà des contacts?
    let listeContacts = contacts.filter(
      (item) => item.patient_id === patient_id
    );

    if (listeContacts?.length > 1) {
      return { code: "OK", data: contacts };
    }

    setMedecinTraitant();
    setContacts([]);
    //NES j'appelle l'API
    let MonUrl = `${process.env.REACT_APP_URL_API_SESAMVITAL}/patients/${patient_id}/contacts`;

    let reponseApi = await GET(MonUrl, authContext.token);

    //NES je le garde dans le contexte
    if (reponseApi.code === "OK") {
      let data = reponseApi.data;

      //NES je recherche le médecin traitant
      let leMedecinTraitant = data.find(
        (item) => item.is_medecin_traitant === true
      );
      setMedecinTraitant(leMedecinTraitant);

      //NES je garde les autres contacts
      let autresContacts = data.filter(
        (item) => item.is_medecin_traitant !== true
      );
      setContacts(autresContacts);
    }
    //NES je retourne à ma fonction appelante le résultat
    return reponseApi;
  };

  //NES permet de récupérer un contact dans le contexte
  const getContactContextById = async (contact_id) => {
    let retour = contacts.find((item) => item._id === contact_id);
    console.log({ retour });
    return retour;
  };

  //PATCH patients/:id/contacts/:id?save=1
  const patchContact = async (patient_id, data, save) => {
    console.log({ data });

    let param = "";

    if (save) {
      param = "?save=1";
    }

    //NES j'appelle l'API
    let MonUrl =
      `${process.env.REACT_APP_URL_API_SESAMVITAL}/patients/${patient_id}/contacts/${data._id}` +
      param;
    //NES pour détecter les appels en boucle
    console.log({ PATCH: MonUrl });
    const reponseApi = await fetch(MonUrl, {
      method: "PATCH",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        token: authContext.token,
      },
      body: JSON.stringify(data),
    });
    //NES je convertis ce que je récupère en JSON
    const reponseJson = await reponseApi.json();
    console.log({ reponseJson });
    //NES je le garde dans le contexte
    if (reponseJson.code === "OK") {
      let tempArray = [...contacts];
      let index = tempArray.findIndex((item) => item._id === data._id);
      tempArray.splice(index, 1, data);
      setContacts(tempArray);
    }
    //NES je retourne à ma fonction appelante le résultat
    return reponseJson;
  };

  //GET /specialites
  const getSpecialites = async () => {
    if (specialites.length > 0) {
      return { code: "OK", data: specialites };
    } else {
      let MonUrl = `${process.env.REACT_APP_URL_API_SESAMVITAL}/specialites`;
      let reponseAPI = await GET(MonUrl, authContext.token);
      if (reponseAPI.code === "OK") {
        setSpecialites(reponseAPI.data);
      }
      //NES je retourne à ma fonction appelante le résultat
      return reponseAPI;
    }
  };

  //DELETE dans l'annuaire
  const deleteFromAnnuaire = async (contact_id) => {
    let MonUrl =
      `${process.env.REACT_APP_URL_API_SESAMVITAL}/annuaire/` + contact_id;

    let reponseAPI = await DELETE(MonUrl, authContext.token);

    return reponseAPI;
  };

  return (
    <ContactsContext.Provider
      value={{
        contacts,
        getAnnuaireSuggestions,
        getAnnuaire,
        medecinTraitant,
        postContact,
        deleteContact,
        deleteMedecinTraitant,
        getContacts,
        getContactContextById,
        patchContact,
        specialites,
        getSpecialites,
        deleteFromAnnuaire,
      }}
    >
      {props.children}
    </ContactsContext.Provider>
  );
};
