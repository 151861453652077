import React, { useState } from "react";
import { useEffect } from "react";
import InputTexte from "../../../../Design/Formulaires/InputTexte";

export default function EditionConsultant({ consultant, onChange }) {
  //NES gestion du state
  const [value, setValue] = useState("");

  useEffect(() => {
    if (consultant) {
      setValue(consultant);
    }
  }, [consultant]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        marginTop: "20px",
      }}
    >
      <span className="TitreBloc" style={{ marginBottom: "5px" }}>
        Consultant
      </span>
      <div
        style={{
          backgroundColor: "#F6F6F6",
        }}
      >
        <InputTexte
          theme="lecture"
          placeholder="ex: M. MARTIN Nicolas"
          value={value}
          onChange={(e) => setValue(e.target.value)}
          onBlur={onChange}
        />
      </div>
    </div>
  );
}
