import React, { useState } from "react";
import BoutonRetour from "../../../../Design/Boutons/BoutonRetour";
import InputTexte from "../../../../Design/Formulaires/InputTexte";
import SurfacePapier from "../../../../Design/Surfaces/SurfacePapier";
import MonStyle from "./PageEditionCabinet.module.css";
import Adresse from "../../../../Components/Adresse";
import InputTelephone from "../../../../Design/Formulaires/InputTelephone";
import InputEmail from "../../../../Design/Formulaires/InputEmail";
import BoutonActionPrincipale from "../../../../Design/Boutons/BoutonActionPrincipale";
import BoutonAnnuler from "../../../../Design/Boutons/BoutonAnnuler";
import MessageErreur from "../../../../Design/Messages/MessageErreur";
import { useContext } from "react";
import { CabinetsContext } from "../../../../Data/CabinetContext";
import { useHistory } from "react-router-dom";
import { useEffect } from "react";
import ObjetTracabilite from "../../../../Design/Objets/ObjetTracabilite";
import { UtilisateursContext } from "../../../../Data/UtilisateursContext";
import ObjetUtilisateur from "../../../../Design/Objets/ObjetUtilisateur";
import CheckBox from "../../../../Design/Formulaires/CheckBox";
import { isBe } from "../../../../functions/FonctionsEnvironnement";
import SurfaceZoneSecondaire from "../../../../Design/Surfaces/SurfaceZoneSecondaire";
import ControleInformationCabinet from "../../Components/ControleInformationsCabinet";

const modelCabinet = {
  nom: "",
  telephone: { numero: "", type: "Télephone" },
  fax: { numero: "", type: "Fax" },
  email: "",
  adresse: {
    rue: "",
    code_postal: "",
    ville: "",
    complement: "",
    pays: "",
  },
  texte_horaire: "",
  texte_rdv: "",
  texte_libre: "",
  numero_finess: "",
  utilisateurs: [],
  informations_juriques: {
    numero_siret: "",
    numero_siren: "",
    capital_social: "",
    nom_entite_juridique: "",
    adresse_siege: "",
    numero_tva: "",
  },
};

export default function PageEditionCabinet({ match }) {
  //NES id passé en URL
  const cabinet_id_url = match.params.id;

  //NES gestion du context
  const { postCabinets, getCabinetById, patchCabinets } =
    useContext(CabinetsContext);
  const { getUtilisateurs } = useContext(UtilisateursContext);

  //NES Gestion du routage pour faire des redirections
  let history = useHistory();

  //NES Gestion du state
  const [titre, setTitre] = useState("Nouveau cabinet");
  const [data, setData] = useState(modelCabinet);
  const [erreur, setErreur] = useState();
  const [listeUtilisateurs, setListeUtilisateurs] = useState([]);

  const handleChange = (key, value) => {
    setData({ ...data, [key]: value });
  };

  const handleChangeInformationsJuridiques = (key, value) => {
    let newData = data.informations_juridiques;
    newData = { ...newData, [key]: value };
    handleChange("informations_juridiques", newData);
  };
  //USEEFFECT au chargement récupération des utilisateurs
  useEffect(() => {
    //GET utilisateurs
    getUtilisateurs().then((reponse) => {
      if (reponse.code === "OK") {
        setListeUtilisateurs(reponse.data);
      }
    });
    // eslint-disable-next-line
  }, []);

  //NES chargement des données selonf l'id passé en paramètre
  useEffect(() => {
    if (cabinet_id_url && cabinet_id_url !== "new") {
      //GET cabinet
      getCabinetById(cabinet_id_url).then((reponse) => {
        setData(reponse);
        setTitre("Modification du cabinet");
      });
    }
    // eslint-disable-next-line
  }, [cabinet_id_url]);

  //NES ajouter ou enlever un utilisateur
  const handleClickUtilisateur = (utilisateur_id) => {
    if (data.utilisateurs.includes(utilisateur_id)) {
      //NES je l'enlève
      let listeUtilisateur = data.utilisateurs;
      listeUtilisateur = listeUtilisateur.filter(
        (item) => item !== utilisateur_id
      );
      handleChange("utilisateurs", listeUtilisateur);
    } else {
      //NES je l'ajoute
      let listeUtilisateur = data.utilisateurs;
      listeUtilisateur.push(utilisateur_id);
      handleChange("utilisateurs", listeUtilisateur);
    }
  };

  //NES Enregistrer
  const enregistrer = () => {
    console.log("enregisrer", data);
    //POST cabinets/
    if (cabinet_id_url === "new") {
      postCabinets(data)
        .then((reponse) => {
          console.log(reponse);
          if (reponse.code === "OK") {
            history.push("/options/cabinets");
          } else {
            setErreur(reponse.message);
          }
        })
        .catch((error) => {
          console.log(error);
          setErreur("Erreur lors de l'enregistrement du cabinet");
        });
    } else {
      //PATCH cabinets/
      console.log({ data });
      patchCabinets(cabinet_id_url, data)
        .then((reponse) => {
          console.log(reponse);
          if (reponse.code === "OK") {
            history.push("/options/cabinets");
          } else {
            setErreur(reponse.message);
          }
        })
        .catch((error) => {
          console.log(error);
          setErreur("Erreur lors de l'enregistrement du cabinet");
        });
    }
  };

  return (
    <SurfacePapier>
      <div className="Titre1">{titre}</div>
      <BoutonRetour texte="retour à la liste" link="/options/cabinets" />
      <ControleInformationCabinet
        cabinet={data}
        style={{ marginTop: "20px" }}
      />
      <div className={MonStyle.content}>
        <div className={MonStyle.Formulaire}>
          <section>
            <h3>Cabinet</h3>
            <InputTexte
              label="Nom du cabinet"
              value={data.nom}
              onChange={(event) => handleChange("nom", event.target.value)}
            />
            {isBe() && (
              <InputTexte
                label="N° finess (optionnel)"
                value={data.numero_finess}
                onChange={(event) =>
                  handleChange("numero_finess", event.target.value)
                }
              />
            )}
          </section>
          <section>
            <div className="Titre2">Adresse</div>
            <Adresse
              value={data.adresse}
              onChange={(adresse) => {
                console.log(adresse);
                handleChange("adresse", adresse);
              }}
            />
          </section>
          <section>
            <div className="Titre2">Téléphones, email</div>
            <InputTelephone
              disableTypeChange
              objTelephone={data.telephone}
              onTelephoneChange={(telephone) => {
                handleChange("telephone", telephone);
              }}
            />
            <InputTelephone
              disableTypeChange
              objTelephone={data.fax}
              label="Fax"
              onTelephoneChange={(telephone) => {
                handleChange("fax", telephone);
              }}
            />
            <InputEmail
              label="Email"
              value={data.email}
              onChange={(event) => {
                handleChange("email", event.target.value);
              }}
            />
          </section>
          <section>
            <div className="Titre2">Textes libres</div>
            <InputTexte
              label="Texte rendez-vous"
              multiline
              value={data.texte_rdv}
              onChange={(event) => {
                handleChange("texte_rdv", event.target.value);
              }}
            />
            <InputTexte
              label="Texte horaires"
              multiline
              value={data.texte_horaire}
              onChange={(event) => {
                handleChange("texte_horaire", event.target.value);
              }}
            />
            <InputTexte
              label="Texte libre"
              multiline
              value={data.texte_libre}
              onChange={(event) => {
                handleChange("texte_libre", event.target.value);
              }}
            />
          </section>

          <section>
            <div className="Titre2">Informations juridiques</div>
            <InputTexte
              label="Nom de la société"
              multiline
              value={data?.informations_juridiques?.nom_entite_juridique}
              onChange={(event) => {
                handleChangeInformationsJuridiques(
                  "nom_entite_juridique",
                  event.target.value
                );
              }}
            />
            <InputTexte
              label="N° SIRET"
              multiline
              value={data?.informations_juridiques?.numero_siret}
              onChange={(event) => {
                handleChangeInformationsJuridiques(
                  "numero_siret",
                  event.target.value
                );
              }}
            />
            <InputTexte
              label="Capital social"
              multiline
              value={data?.informations_juridiques?.capital_social}
              onChange={(event) => {
                handleChangeInformationsJuridiques(
                  "capital_social",
                  event.target.value
                );
              }}
            />
            <InputTexte
              label="Adresse du siège social"
              multiline
              value={data?.informations_juridiques?.adresse_siege}
              onChange={(event) => {
                handleChangeInformationsJuridiques(
                  "adresse_siege",
                  event.target.value
                );
              }}
            />
            <InputTexte
              label="Numéro de TVA intracommunautaire"
              value={data?.informations_juridiques?.numero_tva}
              onChange={(event) => {
                handleChangeInformationsJuridiques(
                  "numero_tva",
                  event.target.value
                );
              }}
            />
          </section>
        </div>

        <div className={MonStyle.partieDroite}>
          <SurfaceZoneSecondaire>
            <div className="Titre2">Utilisateurs</div>
            <div className="SousTitre">Liste des utilisateurs du cabinet</div>
            <div className={MonStyle.listeUtilisateurs}>
              {listeUtilisateurs.map((utilisateur) => (
                <div
                  className={MonStyle.utilisateur}
                  onClick={() =>
                    handleClickUtilisateur(utilisateur.utilisateur_id)
                  }
                >
                  <CheckBox
                    checked={data?.utilisateurs?.includes(
                      utilisateur.utilisateur_id
                    )}
                  />
                  <ObjetUtilisateur
                    utilisateur={utilisateur}
                    taille_avatar="S"
                  />
                </div>
              ))}
            </div>
          </SurfaceZoneSecondaire>
        </div>
      </div>

      <div className={MonStyle.BlocEnregistrer}>
        <MessageErreur message={erreur} />
        <BoutonActionPrincipale
          couleur="bleu"
          taille="L"
          texte="Enregistrer"
          style={{ margin: "20px 0px" }}
          onClick={enregistrer}
        />
        <BoutonAnnuler taille="L" link="/options/cabinets" fullWidth />
        <ObjetTracabilite
          tracabiliteCreation={data.tracabilite_creation}
          tracabiliteModification={data.tracabilite_modification}
        />
      </div>
    </SurfacePapier>
  );
}
