import React, { useState, useEffect, useContext } from "react";
import SurfaceBloc from "../../../../../Design/Surfaces/SurfaceBloc";
import BoutonChoixOuiNon from "../../../../../Design/Boutons/BoutonChoixOuiNon";
import InputTexteFilled from "../../../../../Design/Formulaires/InputTexteFilled";
import InputRadio from "../../../../../Design/Formulaires/InputRadio";
import SmokeFreeIcon from "@material-ui/icons/SmokeFree";
import SmokingRoomsIcon from "@material-ui/icons/SmokingRooms";
import { MedicalContext } from "../../../../../Data/MedicalContext";
import MessageAlerte from "../../../../../Design/Messages/MessageAlerte";
import AffichageConditionnel from "../../../../../Design/DataDisplay/AffichageConditionnel";
import {
  IconeHabitusAlcoolFree,
  IconeHabitusAlcool,
} from "../../../../../Design/Icones/IconesHabitus";
import {
  IconeHabitusDrogueFree,
  IconeHabitusDrogue,
} from "../../../../../Design/Icones/IconesHabitus";
import ObjetTracabilite from "../../../../../Design/Objets/ObjetTracabilite";

export default function EditionHabitus({
  code_type_antecedent,
  patient_id,
  className,
}) {
  const Style = {
    padding: "0px 20px 20px 20px",
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  };

  //NES gestion du state
  const [data, setData] = useState({});
  const [message, setMessage] = useState("");

  //NES gestion du context
  const {
    antecedentsEtAllergies,
    getHabitus,
    postAntecedentsEtAllergiesContext,
    patchAntecedentEtAllergiesContext,
    deleteAntecedentEtAllergiesContext,
  } = useContext(MedicalContext);

  //USEEFFECT
  useEffect(() => {
    if (patient_id && code_type_antecedent) {
      let temp = getHabitus(patient_id, code_type_antecedent)[0];
      temp = { ...temp, code_type_antecedent: code_type_antecedent };

      if (temp) {
        setData(temp);
      }
    }

    // eslint-disable-next-line
  }, [patient_id, antecedentsEtAllergies, code_type_antecedent]);

  ///Enregistrer
  const enregistrer = async (data) => {
    setMessage("");

    let reponseApi = {};
    if (data._id) {
      reponseApi = await patchAntecedentEtAllergiesContext(data);
    } else {
      reponseApi = await postAntecedentsEtAllergiesContext(patient_id, data);
    }
    if (reponseApi.code === "OK") {
      setMessage("Enregistrement OK");
    }
  };

  ///saveOnBlur
  const saveOnBlur = () => {
    let tempData = { ...data };
    enregistrer(tempData);
  };

  ///handleChange
  const handleChange = (key, value, save) => {
    let tempData = { ...data, [key]: value };
    setData(tempData);
    if (save) {
      enregistrer(tempData);
    }
  };

  ///handleDelete
  const handleDelete = () => {
    setData({ commentaire: "" });
    deleteAntecedentEtAllergiesContext(data);
  };
  switch (code_type_antecedent) {
    case "habitus_tabac":
      return (
        <SurfaceBloc style={Style} className={className} id="habitus_tabac">
          <h3 style={{ textAlign: "center", marginBottom: "10px" }}>Tabac</h3>
          <BoutonChoixOuiNon
            iconeOui={SmokingRoomsIcon}
            iconeNon={SmokeFreeIcon}
            value={data.choix}
            onChange={(value) => handleChange("choix", value, true)}
          />
          <AffichageConditionnel open={data.choix === "oui"}>
            <InputTexteFilled
              fullWidth
              label={"Quantité et fréquence"}
              multiline
              value={data.quantite_frequence}
              onChange={(e) =>
                handleChange("quantite_frequence", e.target.value)
              }
              onBlur={saveOnBlur}
            />
            <InputTexteFilled
              fullWidth
              label={"Depuis quand ?"}
              multiline
              value={data.depuis_le}
              onChange={(e) => handleChange("depuis_le", e.target.value)}
              onBlur={saveOnBlur}
            />
            <InputTexteFilled
              fullWidth
              label={"Commentaire"}
              multiline
              value={data.commentaire}
              onChange={(e) => handleChange("commentaire", e.target.value)}
              onBlur={saveOnBlur}
            />
            <InputRadio
              label="Souhaitez-vous arrêter de fumer ?"
              values={[
                { code: "oui", libelle: "Oui" },
                { code: "oui_plus_tard", libelle: "Oui, plus tard" },
                { code: "non", libelle: "Non" },
              ]}
              valueChecked={data?.tabac?.souhaite_arreter_fumer}
              onChange={(value) =>
                handleChange("tabac", { souhaite_arreter_fumer: value })
              }
            />
          </AffichageConditionnel>
          <ObjetTracabilite
            tracabiliteCreation={data.tracabilite_creation}
            taille="M"
            onDelete={handleDelete}
            idBoutonDelete="id_delete_tabac"
          />
          <MessageAlerte message={message} />
        </SurfaceBloc>
      );

    case "habitus_alcool":
      return (
        <SurfaceBloc style={Style} className={className} id="habitus_alcool">
          <h3 style={{ textAlign: "center", marginBottom: "10px" }}>Alcool</h3>
          <BoutonChoixOuiNon
            iconeOui={IconeHabitusAlcool}
            iconeNon={IconeHabitusAlcoolFree}
            value={data.choix}
            onChange={(value) => handleChange("choix", value, true)}
          />
          <AffichageConditionnel open={data.choix === "oui"}>
            <InputTexteFilled
              fullWidth
              label={"Quantité et fréquence"}
              multiline
              value={data.quantite_frequence}
              onChange={(e) =>
                handleChange("quantite_frequence", e.target.value)
              }
              onBlur={saveOnBlur}
            />
            <InputTexteFilled
              fullWidth
              label={"Depuis quand ?"}
              multiline
              value={data.depuis_le}
              onChange={(e) => handleChange("depuis_le", e.target.value)}
              onBlur={saveOnBlur}
            />
            <InputTexteFilled
              fullWidth
              label={"Commentaire"}
              multiline
              value={data.commentaire}
              onChange={(e) => handleChange("commentaire", e.target.value)}
              onBlur={saveOnBlur}
            />
          </AffichageConditionnel>
          <ObjetTracabilite
            tracabiliteCreation={data.tracabilite_creation}
            taille="M"
            onDelete={handleDelete}
            idBoutonDelete="id_delete_alcool"
          />
          <MessageAlerte message={message} />
        </SurfaceBloc>
      );

    case "habitus_drogue":
      return (
        <SurfaceBloc style={Style} className={className} id="habitus_drogue">
          <h3 style={{ textAlign: "center", marginBottom: "10px" }}>Drogue</h3>
          <BoutonChoixOuiNon
            iconeOui={IconeHabitusDrogue}
            iconeNon={IconeHabitusDrogueFree}
            value={data.choix}
            onChange={(value) => handleChange("choix", value, true)}
          />
          <AffichageConditionnel open={data.choix === "oui"}>
            <InputTexteFilled
              fullWidth
              label={"Quantité et fréquence"}
              multiline
              value={data.quantite_frequence}
              onChange={(e) =>
                handleChange("quantite_frequence", e.target.value)
              }
              onBlur={saveOnBlur}
            />
            <InputTexteFilled
              fullWidth
              label={"Depuis quand ?"}
              multiline
              value={data.depuis_le}
              onChange={(e) => handleChange("depuis_le", e.target.value)}
              onBlur={saveOnBlur}
            />
            <InputTexteFilled
              fullWidth
              label={"Commentaire"}
              multiline
              value={data.commentaire}
              onChange={(e) => handleChange("commentaire", e.target.value)}
              onBlur={saveOnBlur}
            />
          </AffichageConditionnel>
          <ObjetTracabilite
            tracabiliteCreation={data.tracabilite_creation}
            taille="M"
            onDelete={handleDelete}
            idBoutonDelete="id_delete_drogue"
          />
          <MessageAlerte message={message} />
        </SurfaceBloc>
      );

    case "habitus_sport":
      return (
        <SurfaceBloc style={Style} className={className} id="habitus_sport">
          <h3 style={{ textAlign: "center", marginBottom: "10px" }}>Sport</h3>
          <BoutonChoixOuiNon
            iconeOui={IconeHabitusAlcool}
            iconeNon={IconeHabitusAlcoolFree}
            value={data.choix}
            onChange={(value) => handleChange("choix", value, true)}
          />
          <AffichageConditionnel open={data.choix === "oui"}>
            <InputTexteFilled
              fullWidth
              label={"Sports, commentaires"}
              multiline
              value={data.commentaire}
              onChange={(e) => handleChange("commentaire", e.target.value)}
              onBlur={saveOnBlur}
            />
            <InputTexteFilled
              fullWidth
              label={"Quantité et fréquence"}
              multiline
              value={data.quantite_frequence}
              onChange={(e) =>
                handleChange("quantite_frequence", e.target.value)
              }
              onBlur={saveOnBlur}
            />
            <InputTexteFilled
              fullWidth
              label={"Depuis quand ?"}
              multiline
              value={data.depuis_le}
              onChange={(e) => handleChange("depuis_le", e.target.value)}
              onBlur={saveOnBlur}
            />
          </AffichageConditionnel>
          <ObjetTracabilite
            tracabiliteCreation={data.tracabilite_creation}
            taille="M"
            onDelete={handleDelete}
            idBoutonDelete="id_delete_sport"
          />
          <MessageAlerte message={message} />
        </SurfaceBloc>
      );
    case "habitus_travail":
      return (
        <SurfaceBloc style={Style} className={className} id="habitus_travail">
          <h3 style={{ textAlign: "center", marginBottom: "10px" }}>Travail</h3>

          <InputTexteFilled
            fullWidth
            label={"Travail, commentaires"}
            multiline
            value={data.commentaire}
            onChange={(e) => handleChange("commentaire", e.target.value)}
            onBlur={saveOnBlur}
          />
          <AffichageConditionnel open={data.commentaire}>
            <InputTexteFilled
              fullWidth
              label={"Depuis quand ?"}
              multiline
              value={data.depuis_le}
              onChange={(e) => handleChange("depuis_le", e.target.value)}
              onBlur={saveOnBlur}
            />
          </AffichageConditionnel>
          <ObjetTracabilite
            tracabiliteCreation={data.tracabilite_creation}
            taille="M"
            onDelete={handleDelete}
            idBoutonDelete="id_delete_travail"
          />
          <MessageAlerte message={message} />
        </SurfaceBloc>
      );

    default:
      return "";
  }
}
