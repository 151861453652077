import React, { useEffect, useState, useContext } from "react";
import SurfacePage from "../../Design/Surfaces/SurfacePage";
import { AuthentificationContext } from "../../Data/AuthentificationContext";
import { UtilisateursContext } from "../../Data/UtilisateursContext";
import MessageErreur from "../../Design/Messages/MessageErreur";
import EditionAvatar from "./Components/EditionAvatar";
import InputTexteFilled from "../../Design/Formulaires/InputTexteFilled";
import InputEmailFilled from "../../Design/Formulaires/InputEmailFilled";
import InputPasswordFilled from "../../Design/Formulaires/InputPasswordFilled";
import LoaderHorizontal from "../../Design/Progression/LoaderHorizontal";
import BoutonActionPrincipale from "../../Design/Boutons/BoutonActionPrincipale";
import BoutonTexte from "../../Design/Boutons/BoutonTexte";
import MessageAlerte from "../../Design/Messages/MessageAlerte";

export default function PageMonCompte() {
  //NES gestion du contexte
  const { getUtilisateursById, patchUtilisateurs } =
    useContext(UtilisateursContext);

  //NES Gestion du state
  const [utilisateur, setUtilisateur] = useState({
    nom: "",
    prenom: "",
    specialite: "",
    numero_rpps: "",
    numero_am: "",
    email: "",
    //NES Astuce car le mot de passe est hashé je ne peux donc pas le connaitre
    password_hash: "••••••••••",
  });
  const [messageErreur, setMessageErreur] = useState("");
  const [messageErreurGet, setMessageErreurGet] = useState();
  const [messageOk, setMessageOk] = useState("");
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (prop) => (event) => {
    setUtilisateur({ ...utilisateur, [prop]: event.target.value });
  };

  //NES récupération des données de l'API
  const authContext = useContext(AuthentificationContext);

  //USEEFFECT appel au 1er montage de la page uniquement
  useEffect(() => {
    const monUtilisateurId = authContext.payload.utilisateur_id;
    getUtilisateursById(monUtilisateurId).then((reponse) => {
      if (reponse.code === "OK") {
        let tempData = {
          nom: reponse.data.nom,
          prenom: reponse.data.prenom,
          specialite: reponse.data.specialite,
          numero_rpps: reponse.data.numero_rpps,
          numero_am: reponse.data.numero_am,
          email: reponse.data.email,
          //NES Astuce car le mot de passe est hashé je ne peux donc pas le connaitre
          password_hash: "••••••••••",
        };
        setUtilisateur(tempData);
      } else {
        setMessageErreurGet(
          "Erreur lors de la récupération des informations de mon utilisateur"
        );
        setError(reponse);
      }
    });
    // eslint-disable-next-line
  }, []);

  //NES mise à jour des données dans l'API
  const sauvegarderUtilisateur = (event) => {
    event.preventDefault();

    setIsLoading(true);
    patchUtilisateurs(authContext.payload.utilisateur_id, utilisateur).then(
      (reponse) => {
        setIsLoading(false);

        if (reponse.code === "OK") {
          authContext.autoRefreshToken();
          setMessageErreur("");
          setMessageOk("Enregistrement réussi!");
        } else {
          if (reponse.message) {
            setMessageErreur(reponse.message);
          } else {
            setMessageErreur("Erreur lors de l'enregistrement");
          }

          setError(reponse);
          setMessageOk("");
        }
      }
    );
  };

  ///Cas particulier du password
  const onPasswordEnter = () => {
    if (!utilisateur.password) {
      setUtilisateur({ ...utilisateur, password_hash: "" });
    }
  };

  if (messageErreurGet) {
    return (
      <SurfacePage>
        <h1>Mon compte</h1>
        <MessageErreur message={messageErreurGet} error={error} taille="XL" />
      </SurfacePage>
    );
  }

  return (
    <SurfacePage>
      <h1>Mon compte</h1>
      <form>
        <EditionAvatar
          style={{
            marginTop: "20px",
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        />
        <h3>A propos de vous</h3>
        <InputTexteFilled
          label="Nom"
          value={utilisateur.nom}
          onChange={handleChange("nom")}
        />
        <InputTexteFilled
          label="Prénom"
          value={utilisateur.prenom}
          onChange={handleChange("prenom")}
        />
        <InputTexteFilled
          label="Spécialité"
          value={utilisateur.specialite}
          onChange={handleChange("specialite")}
        />

        <h3>Pour vous connecter</h3>
        <InputEmailFilled
          label="Email de connexion"
          value={utilisateur.email}
          onChange={handleChange("email")}
        />
        <InputPasswordFilled
          onFocus={onPasswordEnter}
          value={
            utilisateur.password
              ? utilisateur.password
              : utilisateur.password_hash
          }
          onChange={handleChange("password")}
        />
        <LoaderHorizontal isLoading={isLoading} />
        <MessageErreur message={messageErreur} error={error} />
        <MessageAlerte message={messageOk} />
        <BoutonActionPrincipale
          texte="Enregistrer"
          onClick={sauvegarderUtilisateur}
          disabled={
            !(
              utilisateur.nom &&
              utilisateur.prenom &&
              utilisateur.email &&
              (utilisateur.password_hash || utilisateur.password)
            )
          }
        />
        <BoutonTexte texte="Annuler" taille="L" link={"/"} />
      </form>
    </SurfacePage>
  );
}
