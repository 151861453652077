import React, { useContext } from "react";
import MonStyle from "./PageParrainage.module.css";
import SurfacePage from "../../Design/Surfaces/SurfacePage";
import BoutonActionSecondaire from "../../Design/Boutons/BoutonActionSecondaire";
import MessageAucuneDonnee from "../../Design/Messages/MessageAucuneDonnee";
import SurfacePopUp from "../../Design/Surfaces/SurfacePopUp";
import LoaderHorizontal from "../../Design/Progression/LoaderHorizontal";
import MessageErreur from "../../Design/Messages/MessageErreur";
import { useState } from "react";
import { useEffect } from "react";
import { UtilisateursContext } from "../../Data/UtilisateursContext";
import Inscription from "../Login/Components/Inscription";
import { pluriel } from "../../functions/Formatter";
import ObjetOrganisation from "../../Design/Objets/ObjetOrganisation";
import ObjetItem from "../../Design/Objets/ObjetItem";
import { DateToString } from "../../functions/FonctionsDateHeure";

export default function PageParrainage() {
  //NES gestion du contexte
  const { getOrganisations } = useContext(UtilisateursContext);

  //NES gestion du state
  const [liste, setListe] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [messageErreur, setMessageErreur] = useState();
  const [error, setError] = useState();
  const [message, setMessage] = useState();
  const [open, setOpen] = useState(false);

  //USEEFFECT
  useEffect(() => {
    getOrganisations().then((reponse) => {
      setIsLoading(false);
      if (reponse.code === "OK") {
        if (reponse.data.length === 0) {
          setMessage("Aucune organisation parrainée");
        } else {
          setMessage("");
          setListe(reponse.data);
        }
      } else {
        setMessageErreur("Erreur lors de la récupération des parrainés");
        setError(reponse);
      }
    });
    // eslint-disable-next-line
  }, []);

  ///handleOpen
  const handleOpen = () => {
    setOpen(true);
  };

  ///handleClose
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <SurfacePage>
      <header className={MonStyle.Header}>
        <h1>
          Parrainage
          <p className="SousTitre">
            {pluriel(liste.length, "compte parrainé", "comptes parrainés")}
          </p>
        </h1>

        <div className={MonStyle.BoutonAjouter}>
          <BoutonActionSecondaire
            texte={"Créer un nouveau compte " + process.env.REACT_APP_NOM}
            onClick={handleOpen}
            fullWidth
          />
        </div>
      </header>
      <main className={MonStyle.Main}>
        <LoaderHorizontal isLoading={isLoading} />
        <MessageErreur message={messageErreur} error={error} taille="XL" />
        <MessageAucuneDonnee message={message} />
        {liste.map((organisation) => (
          <div className="ListeLigne" key={organisation._id}>
            <ObjetOrganisation organisation={organisation} />
            <ObjetItem
              principale="Période d'essai"
              secondaire={
                "jusqu'au " +
                DateToString(organisation?.facturation?.date_fin_periode_essai)
              }
            />
          </div>
        ))}
      </main>
      <SurfacePopUp
        open={open}
        onClose={handleClose}
        titre="Nouveau parrainage"
      >
        <Inscription isParrainage={true} onClose={handleClose} />
      </SurfacePopUp>
    </SurfacePage>
  );
}
