import React, { useContext, useState, useRef } from "react";
import BoutonRond from "../../../../Design/Boutons/BoutonRond";
import InputTexte from "../../../../Design/Formulaires/InputTexte";
import PhotoCameraIcon from "@material-ui/icons/PhotoCamera";
import SendIcon from "@material-ui/icons/Send";
import { SupportContext } from "../../../../Data/SupportContext";
import MessageErreur from "../../../../Design/Messages/MessageErreur";
import { NotificationsContext } from "../../../../Data/NotificationsContext";

export default function EditionMessage({ demande_id }) {
  //NES gestion du contexte
  const { postDemandesMessages } = useContext(SupportContext);
  const { ajouterNotification } = useContext(NotificationsContext);

  //NES gestion du state
  const [texte, setTexte] = useState("");
  const [messageErreur, setMessageErreur] = useState();
  const [error, setError] = useState();

  //NES Gestion des ref
  const fileInput = useRef(null);

  //POST envoyer le texte
  const envoyerMessage = () => {
    let data = { texte: texte };
    postDemandesMessages(demande_id, data).then((reponse) => {
      console.log(reponse);
      if (reponse.code === "OK") {
        setTexte("");
        //NES Notification d'envoi au support
        let dataNotification = {
          type: "SUPPORT",
          titre: "Message envoyé au support",
          texte: texte,
        };
        ajouterNotification(dataNotification);
      } else {
        setError(reponse.error);
        setMessageErreur("Erreur lors de l'envoi du message");
      }
    });
  };

  //NEs si je n'ai pas de demande_id
  if (!demande_id) {
    return "";
  }

  //POSTR handleFileSelect
  const handleFileSelect = (e) => {
    console.log(e.target.files[0]);

    const file = e.target.files[0];

    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      //
      //setFichier(reader.result);
      const data = {
        capture_ecran: reader.result,
      };

      postDemandesMessages(demande_id, data).then((reponse) => {
        console.log(reponse);
        if (reponse.code === "OK") {
          setTexte("");
          //NES on affiche une notification pour "confirmer" l'envoi au support
          //NES Notification d'envoi au support
          let dataNotification = {
            type: "SUPPORT",
            titre: "Fichier envoyé au support ",
            //texte: texte,
          };
          ajouterNotification(dataNotification);
        } else {
          setError(reponse.error);
          setMessageErreur("Erreur lors de l'envoi du message");
        }
      });
    };
  };

  return (
    <div>
      <MessageErreur
        message={messageErreur}
        style={{ marginBottom: "20px" }}
        error={error}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          gap: "10px",
        }}
      >
        <BoutonRond
          iconeComposant={<PhotoCameraIcon />}
          tooltip="Ajouter une image"
          onClick={() => fileInput.current.click()}
        />
        <input
          type="file"
          onChange={handleFileSelect}
          style={{ display: "none" }}
          ref={fileInput}
          accept="image/*"
        />

        <InputTexte
          theme="fondgris"
          placeholder="Nouveau message"
          style={{ border: "1px solid #DDD" }}
          multiline
          value={texte}
          onChange={(e) => setTexte(e.target.value)}
          autoFocus
        />
        <BoutonRond
          iconeComposant={<SendIcon />}
          tooltip="Envoyer"
          onClick={envoyerMessage}
          disabled={!texte}
        />
      </div>{" "}
    </div>
  );
}
