import React, { useState, useEffect, useRef } from "react";
import OngletsBoutons from "../../../../Design/DataDisplay/OngletsBoutons";
import InputTexte from "../../../../Design/Formulaires/InputTexte";
import InputSwitch from "../../../../Design/Formulaires/Switch";
import { Collapse } from "@material-ui/core";
import BoutonTexte from "../../../../Design/Boutons/BoutonTexte";
import ObjetDocument from "../../../../Design/Objets/ObjetDocument";
import BoutonRond from "../../../../Design/Boutons/BoutonRond";

export default function AgendaBlocEmail({ agenda, onChange }) {
  //NES Gestion du state
  const [email, setEmail] = useState({});
  const [piecesJointes, setPiecesJointes] = useState([]);

  //NES GEStion des ref
  const fileInput = useRef(null);

  //USEEFFECT
  useEffect(() => {
    if (agenda?.email) {
      setEmail(agenda.email);
    }
    if (agenda.email.pieces_jointes) {
      setPiecesJointes(agenda.email.pieces_jointes);
    }
  }, [agenda]);

  //NES Quand on change une valeur
  const handleChange = (key, value) => {
    let newEmail = { ...email, [key]: value };
    setEmail(newEmail);
    let newData = { ...agenda, email: newEmail };
    //NES remonter l'état
    if (onChange) {
      onChange(newData);
    }
  };

  //NES quand on choche rappel
  const handleCheckRappel = (e) => {
    let value = e;
    let newEmail = {};
    if (value) {
      newEmail = {
        ...email,
        is_email_rappel_rdv: value,
        email_delai_rappel_heures: 24,
      };
    } else {
      newEmail = {
        ...email,
        is_email_rappel_rdv: value,
      };
      delete newEmail.email_delai_rappel_heures;
    }

    setEmail(newEmail);
    let newData = { ...agenda, email: newEmail };
    //NES remonter l'état
    if (onChange) {
      onChange(newData);
    }
  };

  //POST Documents
  const ajouterDocument = (event) => {
    let fichiers = Array.from(event.target.files);
    let fichier = fichiers[0];

    let id_temporaire = "upload" + fichier.lastModified;

    let data = {
      _id: id_temporaire,
      patient_id: "",
      consultation_id: "",
      nom: fichier.name,
      taille_Mo: Math.round(fichier.size / 1024 / 1024),
      is_upload: true,
      upload_fichier: fichier,
    };

    let tempArray = [...piecesJointes];
    tempArray.push(data);
    setPiecesJointes(tempArray);
  };

  //NES quand j'ajoute une piece jointe
  const ajouterPieceJointe = (reponse) => {
    if (reponse.code === "OK") {
      let newData = [...piecesJointes];
      let index = newData.findIndex((item) => item._id === reponse.data._id);
      newData.splice(index, 1, reponse.data);
      setPiecesJointes(newData);
      handleChange("pieces_jointes", newData);
    }
  };

  //NES en cas de suppression de la pièce jointe
  const supprimerPieceJointe = (document_id) => {
    let newData = [...piecesJointes];
    let index = newData.findIndex((item) => item._id === document_id);
    newData.splice(index, 1);
    setPiecesJointes(newData);
    handleChange("pieces_jointes", newData);
  };

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <section>
        <h3>Confirmer le rendez-vous par email</h3>
        <InputSwitch
          value={email.is_email_confirmation_rdv}
          onChange={(value) => handleChange("is_email_confirmation_rdv", value)}
          label="Envoyer un email de confirmation"
          description="Un email sera envoyé au patient lors de la prise de rendez-vous.
              Ainsi que lors de la modification ou l'annulation du rendez-vous."
        />
      </section>
      <section>
        <h3>Rappel de rendez-vous par email</h3>
        <InputSwitch
          value={email.is_email_rappel_rdv}
          onChange={handleCheckRappel}
          label="Email de rappel"
          description="Envoi un email pour rappeller le rendez vous au patient"
        />
        <Collapse in={email.is_email_rappel_rdv}>
          <div style={{ marginTop: "20px" }}>Délai de rappel</div>
          <OngletsBoutons
            labels={["24 heures", "48 heures", "72 heures"]}
            onglets={[24, 48, 72]}
            onSelect={(onglet) =>
              handleChange("email_delai_rappel_heures", onglet)
            }
            ongletSelectionne={email.email_delai_rappel_heures}
          />
        </Collapse>
      </section>
      <section>
        <h3>Personnaliser le texte du mail</h3>
        <p className="SousTitre">
          Vous pouvez saisir du texte qui sera ajouté à l'email envoyé au
          patient, lors de la confirmation du rendez-vous
        </p>
        <InputTexte
          label="En cas de confirmation de rdv"
          multiline
          value={email?.email_texte_personnalise}
          onChange={(e) =>
            handleChange("email_texte_personnalise", e.target.value)
          }
        />
        <InputTexte
          label="Annulation de rendez vous, excusé"
          multiline
          value={email?.email_texte_personnalise_excuse}
          onChange={(e) =>
            handleChange("email_texte_personnalise_excuse", e.target.value)
          }
        />{" "}
        <InputTexte
          label="Annulation, excusée à la dernière minute"
          multiline
          value={email?.email_texte_personnalise_excuse_derniere_minute}
          onChange={(e) =>
            handleChange(
              "email_texte_personnalise_excuse_derniere_minute",
              e.target.value
            )
          }
        />{" "}
        <InputTexte
          label="Annulation, non excusé"
          multiline
          value={email?.email_texte_personnalise_non_honore}
          onChange={(e) =>
            handleChange("email_texte_personnalise_non_honore", e.target.value)
          }
        />
      </section>

      <section>
        <h3>Pièces jointes</h3>
        <p className="SousTitre">
          Les documents seront ajouté en pièce jointe au mail de confirmation et
          de rappel.
        </p>
        <input
          type="file"
          style={{ display: "none" }}
          ref={fileInput}
          onChange={ajouterDocument}
          accept="application/pdf"
        />
        <div
          style={{
            margin: "10px 0px",
            display: "flex",
            flexDirection: "column",
            gap: "10px",
          }}
        >
          {piecesJointes.map((document) => (
            <div style={{ display: "flex", alignItems: "center" }}>
              <ObjetDocument
                document={document}
                onPost={ajouterPieceJointe}
                onPatch={ajouterPieceJointe}
                onDelete={supprimerPieceJointe}
                key={document._id}
                disableMenu
              />
              <BoutonRond
                onClick={() => supprimerPieceJointe(document._id)}
                tooltip="Enlever la pièce jointe"
              />
            </div>
          ))}
        </div>

        <BoutonTexte
          texte="Ajouter un document"
          onClick={() => fileInput.current.click()}
          fullWidth
        />
      </section>
    </div>
  );
}
