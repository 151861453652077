import React, { useContext, useState } from "react";
import MonStyle from "./PageTypesConsultations.module.css";
import BoutonActionSecondaire from "../../../../Design/Boutons/BoutonActionSecondaire";
import LoaderHorizontal from "../../../../Design/Progression/LoaderHorizontal";
import SurfacePapier from "../../../../Design/Surfaces/SurfacePapier";
import { ConsultationsContext } from "../../../../Data/ConsultationsContext";
import { useEffect } from "react";
import MessageAucuneDonnee from "../../../../Design/Messages/MessageAucuneDonnee";
import MessageErreur from "../../../../Design/Messages/MessageErreur";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Menu from "../../../../Design/Navigation/BoutonMenu";
import { AuthentificationContext } from "../../../../Data/AuthentificationContext";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { Link } from "react-router-dom";
import BoutonConfirmerSuppression from "../../../../Design/Boutons/BoutonConfirmerSuppression";
import BoutonDefaut from "../../../../Design/Boutons/BoutonDefaut";
import { UtilisateursContext } from "../../../../Data/UtilisateursContext";
import ObjetTypeConsultation from "../../../../Design/Objets/ObjetTypeConsultation";
import BoutonTexte from "../../../../Design/Boutons/BoutonTexte";

export default function PageTypesConsultations() {
  //NES Gestion du contexte
  const {
    typesConsultations,
    getTypesConsultations,
    deleteTypesConsultations,
  } = useContext(ConsultationsContext);
  const authentification = useContext(AuthentificationContext);
  const { getPreferencesUtilisateur, patchPreferencesUtilisateur } =
    useContext(UtilisateursContext);

  //NES Gestion du state
  const [loading, setLoading] = useState();
  const [erreur, setErreur] = useState("");
  const [suppressionId, setSuppressionId] = useState("");
  const [error, setError] = useState();
  const [typeFavori, setTypeFavori] = useState();
  const [survol, setSurvol] = useState();

  //USEEFFECT récupération du type de consultation par defaut pour mon utilisateur
  useEffect(() => {
    getPreferencesUtilisateur().then((reponse) => {
      if (reponse.code === "OK") {
        setTypeFavori(reponse.data.affichage_type_consultation_par_defaut);
      }
    });
    // eslint-disable-next-line
  }, []);

  //NES Chargement des types de consultations
  useEffect(() => {
    setLoading(true);
    getTypesConsultations().then((reponse) => {
      setLoading(false);
      if (reponse.message === "OK") {
        setErreur();
        setError();
      } else {
        setErreur(reponse.message);
        setError(reponse);
      }
    });

    // eslint-disable-next-line
  }, []);

  //NES suppression du type de consultation
  const handleSupprimer = (typeConsultation_id) => {
    console.log("handleSupprimer", typeConsultation_id);
    deleteTypesConsultations(typeConsultation_id)
      .then((reponse) => {
        console.log(reponse);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  ///Definir par défaut
  const handleDefaut = (id) => {
    let newData = { affichage_type_consultation_par_defaut: id };

    //NES pour effacer
    if (typeFavori === id) {
      newData = { affichage_type_consultation_par_defaut: "" };
    }

    console.log(newData);
    patchPreferencesUtilisateur(newData).then((reponse) => {
      if (reponse.code === "OK") {
        setTypeFavori(reponse.data.affichage_type_consultation_par_defaut);
        setErreur("");
        setError();
      } else {
        setErreur("Erreur lors de l'enregistrement");
        setError(reponse);
      }
    });
  };

  return (
    <SurfacePapier id="PageTypesConsultations">
      <div className={MonStyle.TitreEtBouton}>
        <div className={MonStyle.TitreDescription}>
          <div className="Titre1">Types de consultations</div>
          <div className="SousTitre">
            Il est possible pour mieux gérer votre historique patient d'ajouter
            des types de consultations avec votre propre couleur et libellé.
            Vous pouvez aussi choisir comment va s'afficher votre consultation :
            Santé, Enérgétique ou Générique.
          </div>
          <BoutonTexte
            texte="En savoir plus"
            href="https://shop.sinobi.fr/support/ajouter-type-consultation"
            target="_blank"
            rel="noreferrer"
          />
        </div>
        <div className={MonStyle.BoutonActionSecondaire}>
          <BoutonActionSecondaire
            texte="Ajouter un type de consultation"
            link="/options/typesconsultations/new"
            taille="L"
            fullWidth
          />
        </div>
      </div>
      <LoaderHorizontal isLoading={loading} />
      <MessageErreur message={erreur} error={error} />
      <MessageAucuneDonnee
        message={
          typesConsultations.length === 0 &&
          !loading &&
          "Aucun type de consultation trouvé"
        }
        hideIllustration
      />
      <List>
        {typesConsultations.map((type) => (
          <div
            className="ListeLigne"
            key={type._id}
            onMouseEnter={() => setSurvol(type._id)}
            onMouseLeave={() => setSurvol()}
          >
            <ListItem className={MonStyle.Content}>
              <ObjetTypeConsultation typeConsultation={type} />
            </ListItem>

            {suppressionId === type._id && (
              <BoutonConfirmerSuppression
                fullWidth
                onAnnuler={() => setSuppressionId("")}
                onSupprimer={() => handleSupprimer(type._id)}
                style={{
                  position: "absolute",
                  right: "0px",
                  backgroundColor: "white",
                  zIndex: "3",
                }}
              />
            )}

            <div style={{ width: "60px" }}>
              <BoutonDefaut
                onClick={() => handleDefaut(type._id)}
                selected={typeFavori === type._id}
                hide={survol !== type._id}
              />
            </div>

            <Menu
              id={"menu_" + type.libelle}
              tooltip="Modifier, supprimer"
              disabled={
                type.owned_by_organisation !==
                authentification.payload.owned_by_organisation
                  ? true
                  : false
              }
            >
              <List component="nav">
                <ListItem
                  button
                  component={Link}
                  to={"/options/typesconsultations/" + type._id}
                >
                  <EditIcon className="IconeMenu" />
                  <ListItemText primary="Modifier" />
                </ListItem>
                <ListItem
                  button
                  style={{ color: "red" }}
                  onClick={() => setSuppressionId(type._id)}
                >
                  <DeleteIcon className="IconeMenu" style={{ color: "red" }} />
                  Supprimer
                </ListItem>
              </List>
            </Menu>
          </div>
        ))}
      </List>
    </SurfacePapier>
  );
}
