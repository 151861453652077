import React, { useState, useRef, useEffect, useContext } from "react";
import Avatar from "../../../Design/Avatar";
import Style from "./EditionAvatar.module.css";
import { PATCH_ApiUtilisateurs_Avatar } from "../../../Api/ApiUtilisateurs";
import { AuthentificationContext } from "../../../Data/AuthentificationContext";

export default function EditionAvatar({ style }) {
  const [monImage, setMonImage] = useState("");
  const [fichier, setFichier] = useState();
  const fileInput = useRef(null);

  //NES récupération des données de l'API
  const authContext = useContext(AuthentificationContext);

  //NES quand j'ai un fichier je le PATCH à l'API
  useEffect(() => {
    if (fichier) {
      console.log("j'ai un fichier");
      //NES j'efface l'image
      setMonImage(null);
      //NES appel API
      PATCH_ApiUtilisateurs_Avatar(
        fichier,
        authContext.payload.utilisateur_id,
        authContext.token,
        callbackSauvegarder
      );
    }

    // eslint-disable-next-line
  }, [fichier]);

  //NES gestion du callback de l'API
  const callbackSauvegarder = (reponseJson) => {
    console.log(reponseJson);
    if (reponseJson.code === "OK") {
      //NES mise à jour du payload on fait un refresh token
      authContext.autoRefreshToken();
    }
  };

  //NES Sélection de l'image
  const imageSelect = (e) => {
    setFichier(e.target.files[0]);
  };

  //NES affichage de l'image
  useEffect(() => {
    //NES astuce pour forcer le rafraichissement de l'image car elle a la même URl en cas de modif
    let newImage = authContext.payload.image_L + "?id" + Date.now();
    setMonImage(newImage);
  }, [authContext]);

  return (
    <div
      onClick={() => fileInput.current.click()}
      className={Style.EditionAvatar}
      style={style}
    >
      <input
        type="file"
        onChange={imageSelect}
        style={{ display: "none" }}
        ref={fileInput}
      />
      <div>
        <Avatar taille="L" image={monImage} />
        <div className={Style.BoutonChanger}>Changer</div>
      </div>
    </div>
  );
}
