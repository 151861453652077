import React, { useEffect, useState } from "react";
import InputTelephone from "../../../../Design/Formulaires/InputTelephone";
import BoutonAjoutBloc from "../../../../Design/Boutons/BoutonAjoutBloc";

const modeleTelephone = {
  numero: "",
  type: "Téléphone",
  is_favori: false,
};
const modeleContact = { telephones: [modeleTelephone] };

export default function EditionTelephones({
  contact,
  onChange,
  onBlur,
  onDeleteTelephone,
}) {
  //NES Gestion du state
  const [data, setData] = useState(modeleContact);

  //NES mise à jour du state
  useEffect(() => {
    if (contact) {
      setData(contact);
    } else {
      setData(modeleContact);
    }
  }, [contact]);

  //NES mise à jour du numéro
  const handleTelephoneChange = (index, telephone) => {
    let tempArray = data.telephones;
    tempArray.splice(index, 1, telephone);
    let newData = { ...data, telephones: tempArray };
    setData(newData);
    if (onChange) {
      onChange(newData);
    }
  };

  //NES Suppression du téléphone
  const handleSupprimerTelephone = (index) => {
    let tempArray = data.telephones;
    tempArray.splice(index, 1);
    let newData = { ...data, telephones: tempArray };
    setData(newData);
    if (onDeleteTelephone) {
      onDeleteTelephone(newData);
    }
  };

  //NES Ajouter un téléphone
  const handleAjouterTelephone = () => {
    let tempArray = data.telephones;
    tempArray.push(modeleTelephone);
    setData({ ...data, telephones: tempArray });
  };

  return (
    <div>
      {data.telephones.map((telephone, index) => (
        <div
          style={{
            width: "100%",
            position: "relative",
          }}
          key={index}
        >
          <InputTelephone
            objTelephone={telephone}
            key={index}
            id={"telephone" + index}
            onTelephoneChange={(telephone) =>
              handleTelephoneChange(index, telephone)
            }
            onBlur={onBlur}
            autoComplete="qsdjqzdlqjdiz"
            onClickSuppression={() => handleSupprimerTelephone(index)}
          />
        </div>
      ))}
      <div style={{ margin: "20px 0px" }}>
        <BoutonAjoutBloc
          texte="Ajouter un téléphone"
          fullWidth
          onClick={handleAjouterTelephone}
        />
      </div>
    </div>
  );
}
