import React, { useContext, useEffect, useState } from "react";
import ConstructionJoursPlanning from "../Components/ConstructionJoursPlanning";
import JourPlanning from "../Components/JourPlanning";
import ListeDemandesEnRetard from "../Components/ListeDemandesEnRetard";
import ListeDemandesNonPlannifies from "../Components/ListeDemandesNonPlannifies";
import MonStyle from "./PageAdpxPlanning.module.css";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import PageDemande from "../../Support/Pages/PageDemande";
import { AdpxContext } from "../../../Data/AdpxContext";

export default function PageApdxPlanning({ match }) {
  //NES gestion du contexte
  const { jours } = useContext(AdpxContext);

  //NES gestion du state
  const [demandeId, setDemandeId] = useState();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (match.params.id) {
      setDemandeId(match.params.id);
      setOpen(true);
    }
  }, [match]);

  const handleClickDemande = (demande_id) => {
    console.log("handleClickDemande", demande_id);
    setDemandeId(demande_id);
    setOpen(true);
  };

  return (
    <div className={MonStyle.PageApdxPlanning}>
      <h1>Page Planning</h1>
      <ConstructionJoursPlanning />
      <main className={MonStyle.ListeDemandes}>
        <ListeDemandesNonPlannifies onClickDemande={handleClickDemande} />
        <ListeDemandesEnRetard onClickDemande={handleClickDemande} />

        {jours.map((jour) => (
          <JourPlanning
            jour={jour} // onClickDemande={handleClickDemande}
          />
        ))}
      </main>
      <SwipeableDrawer
        anchor="right"
        open={open}
        onClose={() => setOpen(false)}
        // onOpen={onOpen}
      >
        <div className={MonStyle.PageDemande}>
          <PageDemande demande_id={demandeId} onClose={() => setOpen(false)} />
        </div>
      </SwipeableDrawer>
    </div>
  );
}
