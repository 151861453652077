import React, { useState } from "react";
import MonStyle from "./CaseHoraire.module.css";
import Popover from "@mui/material/Popover";
import EditionRdv from "./RendezVous/EditionRdv";
import BoutonRond from "../../../../Design/Boutons/BoutonRond";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import MenuRdv from "./MenuRdv";
import EditionEvenement from "./Evenement/EditionEvenement";
import { isMobile } from "../../../../functions/Navigateur";

export default function CaseHoraire({
  agenda_id,
  date,
  heure,
  isGrise,
  hauteurCase,
}) {
  let minutes = heure.split(":")[1];
  let isSeparateur = minutes === "00" ? true : false;

  //NES gestion du state
  const [survol, setSuvol] = useState(false);

  //NES POPOVER
  const [anchorRdv, setAnchorRdv] = useState(null);
  const [anchorMenu, setAnchorMenu] = useState(null);
  const [anchorEvenement, setAnchorEvenement] = useState(null);

  const afficherRdv = (anchorRdv) => {
    setAnchorRdv(anchorRdv);
  };
  const handleCloseRdv = () => {
    setAnchorRdv(null);
  };

  const afficherMenu = (event) => {
    event.preventDefault();
    //NES je prends le parent car le bouton disparait
    setAnchorMenu(event.currentTarget.parentNode);
  };
  const handleCloseMenu = () => {
    setAnchorMenu(null);
  };

  const afficherEvenement = (nodeAnchor) => {
    //NES je prends le parent car le bouton disparait
    setAnchorEvenement(nodeAnchor);
  };
  const handleCloseEvenement = () => {
    setAnchorEvenement(null);
  };

  return (
    <div
      id={date.getDate() + "_" + heure}
      className={MonStyle.CaseHoraire}
      style={{
        height: hauteurCase,
        backgroundColor: isGrise ? "#F2F1F5" : "",
        //border: "1px solid red",
        boxSizing: "border-box",
      }}
    >
      <div
        onMouseEnter={() => setSuvol(true)}
        onMouseLeave={() => setSuvol(false)}
        onMouseOver={() => setSuvol(true)}
        className={MonStyle.Case}
        style={{
          borderTop: isSeparateur ? "2px solid #DDD" : "",
        }}
      >
        {survol && (
          <BoutonRond
            iconeComposant={<MoreVertIcon />}
            tooltip="Evènements, absences..."
            onClick={afficherMenu}
            taille="S"
            couleurIcone="blanc"
            style={{ marginLeft: "5%" }}
          />
        )}
        <div
          onContextMenu={afficherMenu}
          onClick={(event) => afficherRdv(event.currentTarget)}
          className={MonStyle.HeureRdv}
          //onTouchStartCapture={afficherMenu}
        >
          {survol && <div>{heure}</div>}
        </div>
      </div>
      <Popover
        open={anchorRdv ? true : false}
        anchorEl={anchorRdv}
        onClose={handleCloseRdv}
        //onClick={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: isMobile() ? "bottom" : "center",
          horizontal: "center",
        }}
      >
        <EditionRdv
          agenda_id={agenda_id}
          date={date}
          heure={heure}
          onClose={handleCloseRdv}
        />
      </Popover>
      <Popover
        open={anchorMenu ? true : false}
        anchorEl={anchorMenu}
        onClose={handleCloseMenu}
        onClick={handleCloseMenu}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
      >
        <MenuRdv
          onClickRdv={() => afficherRdv(anchorMenu)}
          onClickEvenement={() => afficherEvenement(anchorMenu)}
        />
      </Popover>
      <Popover
        open={anchorEvenement ? true : false}
        anchorEl={anchorEvenement}
        onClose={handleCloseEvenement}
        //onClick={handleCloseEvenement}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: isMobile() ? "bottom" : "center",
          horizontal: "center",
        }}
      >
        <EditionEvenement
          agenda_id={agenda_id}
          date={date}
          heure={heure}
          onClose={handleCloseEvenement}
        />
      </Popover>
    </div>
  );
}
