import React, { useEffect } from "react";
import { useContext } from "react";
import { useState } from "react";
import { MessagesContext } from "../../../../Data/MessagesContext";
import MessageErreur from "../../../../Design/Messages/MessageErreur";
import LigneSmsEnvoye from "./LigneSmsEnvoye";
import { pluriel } from "../../../../functions/Formatter";
import SurfacePanneau from "../../../../Design/Surfaces/SurfacePanneau";

export default function PanneauDetailSms({
  open,
  onOpen,
  onClose,
  dateDebut,
  dateFin,
}) {
  //NES gestion du contexte
  const { getSms } = useContext(MessagesContext);

  //NES gestion du state
  const [liste, setListe] = useState([]);
  const [erreur, setErreur] = useState();
  const [error, setError] = useState();

  //USEEFFECT récupération des sms envoyés
  useEffect(() => {
    if (dateDebut && dateFin && open) {
      console.log(dateDebut, dateFin);

      let dateStart = new Date(dateDebut);
      let dateEnd = new Date(dateFin);

      getSms(dateStart, dateEnd).then((reponse) => {
        console.log({ reponse });
        if (reponse.code === "OK") {
          setListe(reponse.data);
        } else {
          setErreur("Erreur lors de la récupération des sms envoyés");
          setError(reponse);
        }
      });
    }

    // eslint-disable-next-line
  }, [dateDebut, dateFin]);

  return (
    <SurfacePanneau
      open={open}
      onClose={onClose}
      titre="Sms envoyés"
      onOpen={onOpen}
    >
      <div style={{ marginTop: "20px", maxWidth: "400px" }}>
        <h4>{pluriel(liste.length, "sms envoyé", "sms envoyés")}</h4>
        <MessageErreur
          message={erreur}
          error={error}
          style={{ margin: "20px 0px" }}
        />
        {liste.map((sms) => (
          <div
            id={sms._id}
            style={{
              padding: "10px 0px",
              borderTop: "1px solid #DDD",
            }}
          >
            <LigneSmsEnvoye data={sms} />
          </div>
        ))}
      </div>
    </SurfacePanneau>
  );
}
