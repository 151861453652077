import React, { useEffect, useState, useContext } from "react";
import { withStyles } from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import ObjetPatient from "../Objets/ObjetPatients";
import AddBoxIcon from "@material-ui/icons/AddBox";
import { PatientsContext } from "../../Data/PatientsContext";
import LoaderHorizontal from "../Progression/LoaderHorizontal";
import AffichageConditionnel from "../DataDisplay/AffichageConditionnel";

//NES Style de composant NORMAL
const CssTextField = withStyles({
  root: {
    width: "100%",
    backgroundColor: "white",
    placeholder: "red",
    "& .MuiInputBase-input": {
      color: "#208AB0",
    },
    "& .MuiInputBase-input::placeholder": {
      color: "#666666",
    },

    "& label": {
      color: "#00000087",
    },
    "& label.Mui-focused": {
      color: "#208AB0",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "none",
    },
    "& .MuiOutlinedInput-root": {
      placeholder: "red",
      "& fieldset": {
        borderColor: "#DDD",
      },
      "&:hover fieldset": {
        borderColor: "#DDD",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#208AB0",
      },
    },
  },
})(TextField);

//NES Style titre bloc
const styleTitre = {
  padding: "10px ",
  border: "0px solid red",
  backgroundColor: "#DDDDDD20",
};

//NES Style suggestion
const styleSuggestions = {
  backgroundColor: "white",
  borderRadius: "4px",
  minWidth: "300px",
  zIndex: 10,
  position: "absolute",
  boxShadow: "0 2px 4px 0 rgba(0,0,0,0.25)",
};

export default function InputAutocompletePatient({
  onChange,
  onBlur,
  onSelectSuggestion,
  autoFocus,
  label,
  onClickNouveau,
  fullWidth,
}) {
  //NES gestion du context
  const { getPatientsRecherche, ongletsPatients } = useContext(PatientsContext);

  //NES Gestion du state
  const [texte, setTexte] = useState("");
  const [recherche, setRecherche] = useState("");

  const [anchorEl, setAnchorEl] = useState(null);
  const [focus, setFocus] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  //NES gestion du champ de recherche
  //NES le texte et la recherche sont décorelés pour ne pas lancer la recherche quand je passe une value
  const handleChange = (event) => {
    let recherche = event.target.value;
    setTexte(recherche);
    setRecherche(recherche);
    if (onChange) {
      onChange(event);
    }
  };

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
    setFocus(false);
  };

  const handleKeyDown = (event) => {
    if (event.key === "ArrowDown") {
      setFocus(true);
    }
    if (event.key === "Tab") {
      closeSuggestions();
    }
  };

  //USEEFFECT recherche
  useEffect(() => {
    //NES actif me sert à mettre a jour le state uniquement quand c'est terminé
    let actif = true;

    //NES Je recherche pas à chaque lettre sinon c'est trop souvent
    const timer = setTimeout(() => {
      if (recherche !== "") {
        setIsLoading(true);

        getPatientsRecherche(recherche).then((reponse) => {
          if (reponse.code === "OK") {
            if (actif) {
              setIsLoading(false);
              setSuggestions(reponse.data);
            }
          } else {
            setIsLoading(false);
            setSuggestions([]);
          }
        });
      }
    }, 1000);
    return () => {
      clearTimeout(timer);
      actif = false;
    };
    // eslint-disable-next-line
  }, [recherche]);

  //NES Gestion des suggestions
  const handleKeyDownSuggestions = (event) => {
    if (event.key === "Tab") {
      closeSuggestions();
    }
  };
  const closeSuggestions = () => {
    setSuggestions([]);
  };

  const handleSelected = (patient) => {
    //console.log(patient);
    //NES je ferme les suggestions
    closeSuggestions();
    //NES j'efface le champs de recherche
    setTexte("");
    //NES je retourne l'objet
    if (onSelectSuggestion) {
      onSelectSuggestion(patient);
    }
  };

  return (
    <div>
      <CssTextField
        id="autocomplete_patients"
        fullWidth={fullWidth}
        value={texte}
        onChange={handleChange}
        label={label}
        variant="outlined"
        size="small"
        placeholder="NOM Prénom ou téléphone"
        onClick={handleClick}
        onKeyDown={handleKeyDown}
        onBlur={onBlur}
        autoFocus={autoFocus}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <SearchIcon style={{ color: "#666666" }} />
            </InputAdornment>
          ),
        }}
      />
      <div style={styleSuggestions}>
        <LoaderHorizontal isLoading={isLoading} />

        <ClickAwayListener onClickAway={closeSuggestions}>
          <MenuList
            autoFocusItem={focus}
            id="menu-suggestion"
            onKeyDown={handleKeyDownSuggestions}
            style={{ maxHeight: "300px", overflow: "auto" }}
          >
            <AffichageConditionnel
              open={
                suggestions.length === 0 &&
                recherche === "" &&
                ongletsPatients.length > 0
              }
            >
              <h4 style={styleTitre}>Onglets patients ouverts</h4>
              {ongletsPatients.map((patient) => (
                <MenuItem
                  id={patient._id}
                  key={patient._id}
                  onClick={() => handleSelected(patient)}
                  disableGutters={true}
                >
                  <ObjetPatient patient={patient} />
                </MenuItem>
              ))}
            </AffichageConditionnel>

            <AffichageConditionnel open={suggestions.length === 0 && recherche}>
              <div
                className="TitreBloc"
                style={{
                  width: "100%",
                  textAlign: "center",
                  height: "50px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                Aucun patient trouvé
              </div>
            </AffichageConditionnel>

            {suggestions.map((patient) => (
              <MenuItem
                id={patient._id}
                key={patient._id}
                onClick={() => handleSelected(patient)}
                disableGutters={true}
              >
                <ObjetPatient
                  patient={patient}
                  sexe={patient.sexe}
                  nom={patient.nom}
                  nom_jeune_fille={patient.nom_jeune_fille}
                  prenom={patient.prenom}
                  age={patient.age}
                />
              </MenuItem>
            ))}
            <MenuItem
              style={{
                height: "50px",
                color: "#208AB0",
              }}
              onClick={onClickNouveau}
            >
              <AddBoxIcon style={{ width: "36px", marginRight: "10px" }} />
              Créer un nouveau dossier
            </MenuItem>
          </MenuList>
        </ClickAwayListener>
      </div>
    </div>
  );
}
