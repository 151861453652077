import React, { useContext, useState, useEffect } from "react";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import MonStyle from "./EditionTypeConsultation.module.css";
import SurfaceBloc from "../../../../Design/Surfaces/SurfaceBloc";
import Popover from "@mui/material/Popover";
import { ConsultationsContext } from "../../../../Data/ConsultationsContext";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { EtiquetteAdmin } from "../../../../Design/Objets/ObjetUtilisateur";
import { Divider } from "@material-ui/core";
import BoutonTexte from "../../../../Design/Boutons/BoutonTexte";
import { AuthentificationContext } from "../../../../Data/AuthentificationContext";
import { UtilisateursContext } from "../../../../Data/UtilisateursContext";
import BoutonDefaut from "../../../../Design/Boutons/BoutonDefaut";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ObjetTypeConsultation from "../../../../Design/Objets/ObjetTypeConsultation";

export default function EditionTypeConsultation({
  typeConsultation,
  tracabiliteCreation,
  onChange,
}) {
  //NES Gestion du contexte
  const { typesConsultations, getTypesConsultations } =
    useContext(ConsultationsContext);
  const auth = useContext(AuthentificationContext);
  const { getPreferencesUtilisateur, patchPreferencesUtilisateur } =
    useContext(UtilisateursContext);

  //NES gestion du state
  const [type, setType] = useState({});
  const [praticien, setPraticien] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [afficherBoutonDefaut, setAfficherBoutonDefault] = useState();
  const [typeFavori, setTypeFavori] = useState();

  //USEEFFECT
  useEffect(() => {
    let actif = true;
    if (anchorEl) {
      getPreferencesUtilisateur().then((reponse) => {
        if (reponse.code === "OK") {
          if (actif) {
            setTypeFavori(reponse.data.affichage_type_consultation_par_defaut);
          }
        }
      });
    }
    return () => (actif = false); // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (typeConsultation) {
      setType(typeConsultation);
    }
  }, [typeConsultation]);

  useEffect(() => {
    let actif = true;
    //NES construction de l'affichage du praticien
    if (tracabiliteCreation) {
      let affichagePraticien =
        "par " +
        tracabiliteCreation.nom_utilisateur.toUpperCase() +
        " " +
        tracabiliteCreation.prenom_utilisateur;

      if (actif) {
        setPraticien(affichagePraticien);
      }
    }
    return () => (actif = false);
  }, [tracabiliteCreation]);

  //NES POPOVER
  const boutonTypeClick = (event) => {
    setAnchorEl(event.currentTarget);
    getTypesConsultations();
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  //NES quand je sélectionne le type de consultation
  const handleClickTypeConsulation = (type) => {
    if (onChange) {
      onChange(type);
    }
    handleClose();
  };

  ///Definir par défaut
  const handleDefaut = (id) => {
    let newData = { affichage_type_consultation_par_defaut: id };

    //NES pour effacer
    if (typeFavori === id) {
      newData = { affichage_type_consultation_par_defaut: "" };
    }

    patchPreferencesUtilisateur(newData).then((reponse) => {
      if (reponse.code === "OK") {
        setTypeFavori(reponse.data.affichage_type_consultation_par_defaut);
      }
    });
  };

  return (
    <div
      id="type_consultation"
      style={{
        borderLeft: "3px solid " + type.couleur,
        paddingLeft: "10px",
      }}
    >
      <div className={MonStyle.TypeConsultation} onClick={boutonTypeClick}>
        <div className="Titre2">{type.libelle}</div>
        <ArrowDropDownIcon />
      </div>
      <div className="SousTitre" style={{ marginLeft: "5px" }}>
        {praticien}
      </div>
      <Popover
        open={anchorEl ? true : false}
        anchorEl={anchorEl}
        onClose={handleClose}
        //onClick={handleClose}
        anchorOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
      >
        <SurfaceBloc id="popover_type_consultation">
          <List>
            {typesConsultations.map((type) => (
              <ListItem
                button
                key={type._id}
                onClick={() => handleClickTypeConsulation(type)}
                onMouseEnter={() => setAfficherBoutonDefault(type._id)}
              >
                <ObjetTypeConsultation typeConsultation={type} taille="S" />

                <ListItemSecondaryAction>
                  <BoutonDefaut
                    selected={typeFavori === type._id}
                    hide={afficherBoutonDefaut !== type._id}
                    onClick={() => handleDefaut(type._id)}
                  />
                </ListItemSecondaryAction>
              </ListItem>
            ))}
            <Divider style={{ margin: "10px " }} />

            <BoutonTexte
              disabled={!auth.payload.is_admin ? true : false}
              texte="Ajouter un type"
              fullWidth
              link="/options/typesconsultations/new"
            >
              <EtiquetteAdmin color="inherit" />
            </BoutonTexte>
          </List>
        </SurfaceBloc>
      </Popover>
    </div>
  );
}
