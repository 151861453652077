import React from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

import EventIcon from "@material-ui/icons/Event";
import EventNoteIcon from "@material-ui/icons/EventNote";
//import EventBusyIcon from "@material-ui/icons/EventBusy";
//import EventAvailableIcon from "@material-ui/icons/EventAvailable";

export default function MenuRdv({ onClickRdv, onClickEvenement }) {
  const styleIcon = {
    marginLeft: "5px",
    marginRight: "15px",
    color: "#666",
  };

  return (
    <div>
      <List component="nav">
        <ListItem button onClick={onClickRdv}>
          <EventIcon style={styleIcon} />
          <ListItemText primary="Nouveau Rdv" />
        </ListItem>
        <ListItem button onClick={onClickEvenement}>
          <EventNoteIcon style={styleIcon} />
          <ListItemText
            primary="Nouvel évènement"
            secondary="Réunions, absences..."
          />
        </ListItem>
        {/*  
        <ListItem button>
          <EventBusyIcon style={styleIcon} />
          <ListItemText primary="Non disponible" />
        </ListItem>
        <ListItem button>
          <EventAvailableIcon style={styleIcon} />
          <ListItemText primary="Réserver par type de rdv " />
        </ListItem>
        */}
      </List>
    </div>
  );
}
