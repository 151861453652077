import React, { useEffect, useState } from "react";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { withStyles } from "@material-ui/core/styles";
import DoneIcon from "@material-ui/icons/Done";
import ReportProblemIcon from "@material-ui/icons/ReportProblem";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import MessageAide from "../Messages/MessageAide";

function ControlePassword({ texte, open }) {
  //NES Styles
  const styleRegleKO = {
    margin: "5px 0px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "10px",
    color: "#97989D",
    flexWrap: "wrap",
  };

  const styleRegleOK = { ...styleRegleKO, color: "green" };

  //NES gestion du state
  const [isNbCaracteres, setIsNbCaracteres] = useState(false);
  const [isMajuscule, setIsMajuscule] = useState(false);
  const [isCaractereSpecial, setIsCaractereSpecial] = useState(false);
  const [isChiffre, setIsChiffre] = useState(false);
  const [isCorrect, setIsCorrect] = useState(false);

  useEffect(() => {
    if (isChiffre && isCaractereSpecial && isMajuscule && isNbCaracteres) {
      setIsCorrect(true);
    } else {
      setIsCorrect(false);
    }
  }, [isChiffre, isCaractereSpecial, isMajuscule, isNbCaracteres]);

  //USEEFFECT
  useEffect(() => {
    //NES controle du nombre de caracteres
    if (texte?.length >= 8) {
      setIsNbCaracteres(true);
    } else {
      setIsNbCaracteres(false);
    }
    //NES au moins une majuscule   (?=.*[A-Z])       // should contain at least one upper case
    if (texte?.match(/(?=.*[A-Z])/)) {
      setIsMajuscule(true);
    } else {
      setIsMajuscule(false);
    }
    //NES un caractère spécial
    if (texte?.match(/(?=.*[#@:=+!?.&§/])/)) {
      setIsCaractereSpecial(true);
    } else {
      setIsCaractereSpecial(false);
    }
    //NES un chiffre
    if (texte?.match(/(?=.*[0-9])/)) {
      setIsChiffre(true);
    } else {
      setIsChiffre(false);
    }
  }, [texte]);

  if (isCorrect) {
    return (
      <div
        style={{
          padding: "10px 20px",
          margin: "20px 0px",
          backgroundColor: "#0FA60020",
          color: "#0FA600",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: "10px",
        }}
      >
        <VerifiedUserIcon /> Mot de passe conforme aux règles de sécurité!
      </div>
    );
  }

  if (open) {
    return (
      <div>
        <div style={{ padding: "10px", marginTop: "0px" }}>
          <div>
            <div className="InformationSecondaire" style={{ color: "black" }}>
              Pour des raison de sécurité, votre mot de passe doit comporter, au
              moins:{" "}
            </div>

            <div style={isNbCaracteres ? styleRegleOK : styleRegleKO}>
              {isNbCaracteres ? <DoneIcon /> : <ReportProblemIcon />}
              <b>8 caractères</b>
            </div>
            <div style={isMajuscule ? styleRegleOK : styleRegleKO}>
              {isMajuscule ? <DoneIcon /> : <ReportProblemIcon />}
              <b>1 majuscule</b>
            </div>
            <div style={isCaractereSpecial ? styleRegleOK : styleRegleKO}>
              {isCaractereSpecial ? <DoneIcon /> : <ReportProblemIcon />}
              <b>1 caractère spécial</b> (&?.+!@:-/)
            </div>
            <div style={isChiffre ? styleRegleOK : styleRegleKO}>
              {isChiffre ? <DoneIcon /> : <ReportProblemIcon />}
              <b>1 chiffre</b>
            </div>
          </div>
        </div>{" "}
      </div>
    );
  }

  return "";
}

//NES Style de composant NORMAL
const CssTextField = withStyles({
  root: {
    width: "100%",
    backgroundColor: "white",
    borderRadius: (props) => props.theme.borderRadius,

    "& .MuiInputBase-input": {
      borderRadius: (props) => props.theme.borderRadius,

      color: (props) => (props.couleur === "erreur" ? "#F44336" : "#208AB0"),
      textTransform: (props) =>
        props.texttransform ? props.texttransform : "none",
      "&.Mui-disabled ": {
        border: "none",
        backgroundColor: "#EDEDED",
        color: "#97989D",
      },
    },
    "& .MuiInputBase-input::placeholder": {
      color: (props) => props.theme.placeholderColor,
      opacity: 1,
    },

    "& label": {
      color: (props) => (props.couleur === "erreur" ? "#F44336" : "#00000087"),
    },
    "& label.Mui-focused": {
      color: (props) => (props.couleur === "erreur" ? "#F44336" : "#208AB0"),
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "none",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderRadius: (props) => props.theme.borderRadius,
        borderColor: (props) =>
          props.couleur === "erreur" ? "#F44336" : "#DDD",
      },
      "&:hover fieldset": {
        borderColor: (props) =>
          props.couleur === "erreur" ? "#F44336" : "#DDD",
      },
      "&.Mui-focused fieldset": {
        borderColor: (props) =>
          props.couleur === "erreur" ? "#F44336" : "#208AB0",
      },
      "&.Mui-disabled fieldset": {
        border: "none",
        //backgroundColor: "#EDEDED",
      },
      "&.Mui-disabled ": {
        border: "none",
        backgroundColor: "#EDEDED",
        color: "#97989D",
      },
    },
  },
})(TextField);

export default function InputPassword({
  value,
  label,
  onChange,
  isErreur,
  valueHash,
  disabled,
  theme,
  taille,
  style,
  placeholder,
  autoComplete,
  afficherControle,
  afficherConseil,
  ...props
}) {
  //NES Style de de la div contenant le composant
  const StyleDivContainer = {
    marginTop: "20px",
    position: "relative",
    width: "100%",
    ...style,
  };

  //NES gestion du state
  const [values, setValues] = React.useState({
    password: value,
    showPassword: false,
    valueHash: valueHash,
  });

  //USEEFFECT
  useEffect(() => {
    if (values.password === "") {
      setValues({ ...values, password: "" });
    }

    setValues({
      ...values,
      password: value,
      valueHash: valueHash,
    });

    // eslint-disable-next-line
  }, [value, valueHash]);

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
    //NES retour de event au parent
    if (onChange) {
      onChange(event);
    }
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  //NES themes selon les variantes d'affichage
  const themeModeFormulaire = {
    borderRadius: "4px",
    placeholderColor: "#00000050",
  };

  const themeConnexion = {
    borderRadius: "0px 0px 8px 8px",
    placeholderColor: "#00000090",
  };

  let montheme = themeModeFormulaire;
  switch (theme) {
    case "connexion":
      montheme = themeConnexion;

      break;

    default:
      montheme = themeModeFormulaire;
      break;
  }

  let maTaille = "small";
  switch (taille) {
    case "L":
      maTaille = "medium";
      break;
    default:
      maTaille = "small";

      break;
  }

  return (
    <div style={StyleDivContainer}>
      <CssTextField
        value={
          values.password === undefined && valueHash
            ? valueHash
            : values.password
        }
        onChange={handleChange("password")}
        type={values.showPassword ? "text" : "password"}
        label={label}
        variant="outlined"
        id="password"
        size={maTaille}
        couleur={isErreur ? "erreur" : ""}
        disabled={disabled}
        name={label ? label : placeholder}
        theme={montheme}
        placeholder={placeholder}
        autoComplete={autoComplete}
        //couleur={isErreur ? "erreur" : ""}
        InputProps={{
          endAdornment: values.password && (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
                disabled={values.password === "••••••••••" ? true : false}
              >
                {values.showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
        {...props}
      />
      {afficherControle && (
        <div
          style={{
            margin: "10px 0px",
            //position: "absolute",
          }}
        >
          {afficherConseil && (
            <MessageAide
              message="Il doit être différent des 3 derniers mots de passe utilisés"
              disableClose
            />
          )}

          <ControlePassword texte={values.password} open={true} />
        </div>
      )}
    </div>
  );
}
