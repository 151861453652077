import React, { useContext, useState, useEffect } from "react";
import EditionConseil from "./EditionConseil";
import { ConseilsContext } from "../../../../Data/ConseilsContext";
import MessageErreur from "../../../../Design/Messages/MessageErreur";
import BoutonActionSecondaire from "../../../../Design/Boutons/BoutonActionSecondaire";
import { CabinetsContext } from "../../../../Data/CabinetContext";
import { PatientsContext } from "../../../../Data/PatientsContext";
import { capitalize } from "../../../../functions/Formatter";
import LoaderHorizontal from "../../../../Design/Progression/LoaderHorizontal";

export default function ListeConseils({ patient_id, consultation_id }) {
  //NES Gestion du contexte
  const { conseils, postConseils, getConseils, getPreferences } =
    useContext(ConseilsContext);
  const { getObjetCabinet } = useContext(CabinetsContext);
  const { getObjetPatient } = useContext(PatientsContext);

  //NES Gestion du state
  const [erreurChargemenet, setErreurChargement] = useState("");
  const [erreur, setErreur] = useState("");
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState(true);

  //NES ajouter un conseil
  const ajouterConseil = async () => {
    //NES récupération de l'avertissement dans les préférences énergétiques
    let preferences = await getPreferences();
    let avertissementConseil = preferences?.data?.avertissement_conseil
      ? preferences.data.avertissement_conseil
      : "Le présent document n'est pas une prescription et ne peut donner lieu à aucun remboursement. \n Il n'est qu'un conseil phyto-alimentaire assimilable à une démarche personnelle.";
    let conseilsSeance = preferences?.data.conseils_seance
      ? preferences?.data.conseils_seance
      : "Après une séance en Energétique manuelle, voici quelques conseils: \n 1 - pas d'activité sportive pendant 8 jours \n 2 - sur une période de 3 jours, bien s'hydrater - bain chaud d'une vingtaine de minutes suivi de la position allongée30 à 60 minutes à plat dos sur un surface dure, coussin sous la nuque et sous les lombaires \n 3 - dans les 48 à 72 h qui suivent la séance, des douleurs de régulation peuvent apparaitre (durée : quelques jours) cela est normal \n 4 - Evitez les gestes répétitifs, les mouvements d'effort et les positions statiques sur les 3 premiers jours";

    //NES récupération du consultant = dossier patient
    let objetPatient = getObjetPatient(patient_id);
    let consultant =
      objetPatient.nom.toUpperCase() + " " + capitalize(objetPatient.prenom);

    //NES construction du nouveau conseil
    let newData = {
      date: new Date(),
      avertissement_conseil: avertissementConseil,
      consultant: consultant,
      conseils_seance: conseilsSeance,
      lignes_conseils: [],
      conseil_prise: { duree: "30 minutes", temperature: "FRAICHE" },
      patient: objetPatient,
      cabinet: getObjetCabinet(),
    };

    //POST conseil
    postConseils(patient_id, consultation_id, newData)
      .then((reponse) => {
        if (reponse.code !== "OK") {
          setErreur(reponse.erreur.message);
        }
      })
      .catch((error) => {
        setErreur("Erreur lors de l'ajout d'un conseil");
        setError(error);
      });
  };

  //USEEFFECT
  useEffect(() => {
    let active = true;

    if (patient_id && consultation_id) {
      setIsLoading(true);
      getConseils(patient_id, consultation_id).then((reponse) => {
        if (active) {
          setIsLoading(false);
          if (reponse.code !== "OK") {
            setErreurChargement("Erreur lors du chargement des conseils");
            setError(reponse);
          }
        }
      });
    }

    return () => {
      active = false;
    };
    // eslint-disable-next-line
  }, [patient_id, consultation_id]);

  if (erreurChargemenet) {
    return (
      <MessageErreur
        message={erreurChargemenet}
        error={error}
        style={{ margin: "40px 0px" }}
      />
    );
  }

  if (isLoading) {
    return (
      <LoaderHorizontal
        isLoading={true}
        color="#fafafa"
        style={{ margin: "40px 0px" }}
      />
    );
  }

  return (
    <div id="Conseils">
      <MessageErreur message={erreur} error={error} />
      {conseils.map((conseil, index) => (
        <EditionConseil
          patient_id={patient_id}
          consultation_id={consultation_id}
          id={index}
          style={{ marginTop: "40px" }}
          conseil={conseil}
          key={conseil._id}
        />
      ))}

      <BoutonActionSecondaire
        texte="Ajouter une fiche conseil"
        taille="L"
        fullWidth
        style={{ marginTop: "40px" }}
        onClick={ajouterConseil}
      />
    </div>
  );
}
