import React, { useEffect, useState } from "react";
import MonStyle from "./PageEditionTypeConsultation.module.css";
import SurfacePapier from "../../../../Design/Surfaces/SurfacePapier";
import BoutonRetour from "../../../../Design/Boutons/BoutonRetour";
import BoutonActionPrincipale from "../../../../Design/Boutons/BoutonActionPrincipale";
import BoutonAnnuler from "../../../../Design/Boutons/BoutonAnnuler";
import InputTexte from "../../../../Design/Formulaires/InputTexte";
import ColorPicker from "../../../../Design/Pickers/ColorPicker";
import InputSelect from "../../../../Design/Formulaires/InputSelect";
import MessageErreur from "../../../../Design/Messages/MessageErreur";
import { ConsultationsContext } from "../../../../Data/ConsultationsContext";
import { useContext } from "react";
import { useHistory } from "react-router-dom";
import AffichageConditionnel from "../../../../Design/DataDisplay/AffichageConditionnel";
import { AuthentificationContext } from "../../../../Data/AuthentificationContext";
import SurfaceZoneSecondaire from "../../../../Design/Surfaces/SurfaceZoneSecondaire";
import ObjetAffichagaCodeConsultation from "../../../../Design/Objets/ObjetAffichageCodeConsultation";
import CodeConsultationPicker from "../../../../Design/Pickers/CodeConsultationPicker";
import SurfacePopUp from "../../../../Design/Surfaces/SurfacePopUp";

const modeleTypeConsultation = {
  libelle: "",
  code:
    process.env.REACT_APP_NOM === "SINOBI"
      ? "consultation_energetique"
      : "consultation",
  couleur: "#205A71",
  code_produit: process.env.REACT_APP_NOM,
};

export default function PageEditionTypeConsultation({ match }) {
  //NES récupération des paramètres dans l'URL
  let typeConsultation_id = match.params.id;

  //NES Gestion du routage pour faire des redirections
  let history = useHistory();

  //NES Gestion du contexte
  const {
    postTypesConsultations,
    getTypeConsultationById,
    patchTypesConsultations,
  } = useContext(ConsultationsContext);
  const auth = useContext(AuthentificationContext);

  //NES Gestion du state
  const [data, setData] = useState(modeleTypeConsultation);
  const [erreur, setErreur] = useState("");
  const [open, setOpen] = useState(false);

  //NES modification des valeurs
  const handleChange = (key, valeur) => {
    setData({ ...data, [key]: valeur });
  };

  //NES on enregistre
  const handleEnregistrer = async () => {
    setErreur("");
    if (!data._id) {
      //POST nouveau type de consultation
      let reponse = await postTypesConsultations(data);
      if (reponse.code === "OK") {
        history.push("/options/typesconsultations");
      } else {
        setErreur("Erreur lors de l'enregistrement");
      }
    } else {
      //PATCH du type de consultation
      let reponse = await patchTypesConsultations(data);
      if (reponse.code === "OK") {
        history.push("/options/typesconsultations");
      } else {
        setErreur("Erreur lors de l'enregistrement");
      }
    }
  };

  useEffect(() => {
    //NES nouveau type de consultation
    if (typeConsultation_id === "new") {
      setData(modeleTypeConsultation);
    } else {
      //NES j'initialise avec mon type de consultation
      getTypeConsultationById(typeConsultation_id).then((reponse) => {
        setData(reponse);
      });
    }
    // eslint-disable-next-line
  }, [typeConsultation_id]);

  return (
    <SurfacePapier id="PageEditionTypeConsultation">
      <div className="Titre1">
        {typeConsultation_id === "new" ? "Nouveau" : "Modifier le"} type de
        consultation
      </div>
      <BoutonRetour texte="Retour " link="/options/typesconsultations" />
      <div className={MonStyle.content}>
        <div className={MonStyle.partieGauche}>
          <section>
            <ColorPicker
              couleurSelectionne={data.couleur}
              onCouleurChange={(couleur) => handleChange("couleur", couleur)}
            />
            <InputTexte
              label="Nom du type de consultation"
              placeholder="ex : consultation spécialisée"
              value={data.libelle}
              onChange={(event) => handleChange("libelle", event.target.value)}
            />

            <AffichageConditionnel open={auth.payload.code_role === "ADPX"}>
              <InputSelect
                label="Produit (ADPX only)"
                options={["BE", "SINOBI"]}
                enabledAucuneValeur
                value={data.code_produit}
                onChange={(event) =>
                  handleChange("code_produit", event.target.value)
                }
              />
            </AffichageConditionnel>
          </section>

          <section>
            <h3>Affichage</h3>
            <div className={MonStyle.InputCode} id="InputCode">
              <ObjetAffichagaCodeConsultation
                code={data.code}
                onClick={() => setOpen(true)}
                couleur="#208AB0"
              />
            </div>

            <SurfacePopUp
              open={open}
              couleur="gris"
              onClose={() => setOpen(false)}
              titre="Personnalisez l'affichage de la consultation"
              sousTitre="Cela permet de modifier les informations affichées"
            >
              <CodeConsultationPicker
                onClick={(code) => {
                  handleChange("code", code);
                  setOpen(false);
                }}
              />
            </SurfacePopUp>
          </section>

          <MessageErreur message={erreur} />
          <BoutonActionPrincipale
            taille="L"
            texte="Enregistrer"
            couleur="bleu"
            style={{ marginTop: "0px" }}
            onClick={handleEnregistrer}
            disabled={!data.libelle}
          />
          <BoutonAnnuler
            style={{ marginTop: "20px" }}
            taille="L"
            link="/options/typesconsultations"
            fullWidth
          />
        </div>
        <div className={MonStyle.partieDroite}>
          <SurfaceZoneSecondaire>
            <section>
              <div className="Titre2">
                A quoi sert un type de consultation ?
              </div>
              <p>
                Un type de consultation vous permet de différencier plus
                rapidement les consultations dans l'historique. Cela vous permet
                aussi de préciser les constantes que vous souhaitez afficher par
                défaut{" "}
              </p>
            </section>
            <section>
              <div className="Titre2">Est ce cela modifie le calendrier ?</div>
              <p>
                Non, cela ne provoque pas de changement dans les types de rendez
                vous du calendrier. Cela permet de gérer plus finement
                l'historique du dossier patient sans pour autant compliquer les
                types de rendez-vous.
              </p>
            </section>
          </SurfaceZoneSecondaire>
        </div>
      </div>
    </SurfacePapier>
  );
}
