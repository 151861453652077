import React from "react";

export default function AfficherNomAgenda({ agenda }) {
  return (
    <div
      style={{
        height: "40px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: agenda.couleur_agenda,
        overflow: "hidden",
        textOverflow: "ellipsis",
        width: "100%",
        color: "white",
        textAlign: "center",
      }}
    >
      {agenda.nom_agenda}
    </div>
  );
}
