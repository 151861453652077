import React from "react";
import BoutonRetour from "../../../../Design/Boutons/BoutonRetour";
import SurfacePapier from "../../../../Design/Surfaces/SurfacePapier";
import MonStyle from "./PageEditionLaboratoire.module.css";
import EditionLaboratoire from "./Components/EditionLaboratoire";
import { useHistory } from "react-router-dom";

export default function PageEditionLaboratoire({ match }) {
  //NES ID du laboratoire dans l'URL
  const url_laboratoire_id = match.params.id;

  //NES Gestion du routage pour faire des redirections
  let history = useHistory();

  const handleAnnuler = () => {
    history.push("/options/laboratoires/");
  };

  const handleEnregistrer = () => {
    history.push("/options/laboratoires/");
  };

  return (
    <SurfacePapier>
      <div className="Titre1">
        {url_laboratoire_id === "new"
          ? "Nouveau laboratoire"
          : "Modifier laboratoire"}
      </div>
      <BoutonRetour
        texte="Retour à la liste des laboratoires"
        link={"/options/laboratoires"}
        style={{ margin: "20px 0px" }}
      />
      <div className={MonStyle.Formulaire}>
        <EditionLaboratoire
          laboratoire_id={url_laboratoire_id}
          onAnnuler={handleAnnuler}
          onEnregistrer={handleEnregistrer}
        />
      </div>
    </SurfacePapier>
  );
}
