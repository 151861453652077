import React from "react";
//import Fade from "@material-ui/core/Fade";
import Fade from "@mui/material/Fade";

export default function AffichageConditionnel({
  open,
  style,
  className,
  ...props
}) {
  if (open) {
    return (
      <Fade in={open ? true : false}>
        <div style={style} className={className}>
          {props.children}
        </div>
      </Fade>
    );
  } else {
    return "";
  }
}
