import React, { useContext, useState, useEffect } from "react";
import MonStyle from "./InfoAdpx.module.css";
import { IconeAdpx } from "../../../../Design/Icones/IconesBe";
import { couleurAction } from "../../../../functions/FonctionsCouleurs";
import { AuthentificationContext } from "../../../../Data/AuthentificationContext";
import { UtilisateursContext } from "../../../../Data/UtilisateursContext";
import ObjetItem from "../../../../Design/Objets/ObjetItem";
import Popover from "@mui/material/Popover";
import BoutonTexte from "../../../../Design/Boutons/BoutonTexte";
import MessageAlerte from "../../../../Design/Messages/MessageAlerte";
import BoutonActionSecondaire from "../../../../Design/Boutons/BoutonActionSecondaire";
import { AdpxContext } from "../../../../Data/AdpxContext";
import { useHistory } from "react-router-dom";
import BoutonRond from "../../../../Design/Boutons/BoutonRond";

export default function InfoAdpx(params) {
  //NES Gestion du routage pour faire des redirections
  let history = useHistory();

  //NES gestion du contexte
  const auth = useContext(AuthentificationContext);
  const { monOrganisation, getMyOrganisation } =
    useContext(UtilisateursContext);
  const { postAuthentificationRetourAdpx } = useContext(AdpxContext);

  //NES gestion du state
  const [anchorEl, setAnchorEl] = useState();
  const [message, setMessage] = useState("");

  //USEEFFECT
  useEffect(() => {
    getMyOrganisation();
    // eslint-disable-next-line
  }, [auth.payload]);

  if (auth.payload.code_role !== "ADPX") {
    return "";
  }

  ///handleClose
  const handleClose = () => {
    setAnchorEl(null);
  };

  ///copyPayload
  const copyPayload = async () => {
    setMessage("");
    let texte = await JSON.stringify(auth.payload, null, "  ");
    navigator.clipboard.writeText(texte);
    setMessage("Payload copié");
  };

  ///copyToken
  const copyToken = () => {
    setMessage("");
    navigator.clipboard.writeText(auth.token);
    setMessage("Token copié");
  };

  ///Reconnexion
  const reconnexionOrganisationAdpx = () => {
    postAuthentificationRetourAdpx().then((reponse) => {
      console.log(reponse);
      if (reponse.code === "OK") {
        auth
          .Connexion(reponse.connexion.token, reponse.connexion)
          .then((reponse) => {
            //NES rediriger sur la page d'acceuil
            history.push("/");
            //NES fermeture de la popover
            handleClose();
          });
      }
    });
  };

  return (
    <div>
      <div
        className={MonStyle.InfoAdpx}
        style={{
          backgroundColor:
            window.location.hostname === "localhost"
              ? "orange"
              : couleurAction(),
          //border: "1px solid #FFFFFF50",
        }}
        onClick={(event) => setAnchorEl(event.currentTarget)}
      >
        <IconeAdpx color="white" />
        <div className={MonStyle.Detail}>
          <ObjetItem
            principale={monOrganisation?.nom}
            isBold
            couleurTexte="white"
            secondaire={"_id : " + auth.payload?.owned_by_organisation}
          />
        </div>
      </div>
      <Popover
        open={anchorEl ? true : false}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <div
          className={MonStyle.Popover}
          style={{ borderLeft: "10px solid " + couleurAction() }}
        >
          <div className={MonStyle.BoutonClose}>
            <BoutonRond onClick={handleClose} tooltip="Fermer" />
          </div>
          <h3>{monOrganisation?.nom}</h3>
          <p>_id: {monOrganisation?._id}</p>
          <section>
            <h3>
              Payload
              <BoutonTexte texte="copier" taille="S" onClick={copyPayload} />
            </h3>
            <pre>{JSON.stringify(auth.payload, null, 2)}</pre>
          </section>

          <section>
            <h3>
              Token{" "}
              <BoutonTexte texte="copier" taille="S" onClick={copyToken} />
            </h3>
            <code>{auth.token}</code>
          </section>
          <BoutonActionSecondaire
            texte="Retour organisation ADPX"
            fullWidth
            onClick={reconnexionOrganisationAdpx}
          />
        </div>
      </Popover>
      <MessageAlerte message={message} />
    </div>
  );
}
