import React from "react";
import MonStyle from "./PageNouvelleDemande.module.css";
import { useHistory } from "react-router-dom";
import BoutonRond from "../../../Design/Boutons/BoutonRond";
import NouvelleDemande from "../Components/NouvelleDemande/NouvelleDemande";

export default function PageNouvelleDemande() {
  //NES gestion du routage
  const history = useHistory();

  const handleEnregistrer = (demande) => {
    //NES je redirige vers la page avec toutes les demandes
    history.push(`/support/${demande._id}`);
  };

  const handleAnnuler = () => {
    //NES je redirige vers la page avec toutes les demandes
    history.push(`/support/`);
  };

  return (
    <div className={MonStyle.PageNouvelleDemande}>
      <header>
        <h2>Nouvelle Demande</h2>
        <BoutonRond link={"/support/"} tooltip="Annuler" />
      </header>
      <NouvelleDemande
        onEnregistrer={handleEnregistrer}
        onAnnuler={handleAnnuler}
      />
    </div>
  );
}
