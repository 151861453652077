import React from "react";
import IconesProduits from "../Icones/IconesProduits";
import ObjetPrix from "./ObjetPrix";

export default function ObjetProduit({
  produit,
  taille,
  afficherURL,
  onClick,
  style,
}) {
  const StyleObjetProduit = {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
  };

  //NES gestion du state
  let afficherLabo = true;
  let afficherDescription = true;

  switch (taille) {
    case "S":
      afficherLabo = false;
      afficherDescription = false;
      break;
    default:
      afficherLabo = true;
      afficherDescription = true;
      break;
  }

  //NES STYLES
  const StyleProduit = {
    border: "0px solid red",
    display: "flex",
    flexDirection: "row",
    gap: "15px",
    color: "green",
    maxWidth: "100%",
    boxSizing: "border-box",
  };

  const StyleNomProduit = {
    border: "0px solid blue",
    display: "flex",
    flexDirection: "column",
    flex: 1,
    maxWidth: "calc( 100% - 50px)",
    boxSizing: "border-box",
  };

  const StyleConditionnement = {
    //fontWeight: "bolder",
    padding: "1px 8px",
    border: "1px solid green",
    marginLeft: "10px",
    whiteSpace: "nowrap",
    color: "#186D1B",
    color: "green",
    borderRadius: "4px",
    fontWeight: "bolder",
  };

  const StyleDescription = {
    border: "0px solid green",
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    marginTop: "10px",
  };

  if (taille === "L") {
    return (
      <div style={StyleProduit} onClick={onClick}>
        <IconesProduits
          nomIcone={produit?.forme}
          style={{ height: "32px", width: "32px" }}
        />
        <div style={StyleNomProduit}>
          <h6 style={{ color: "#186D1B" }}>
            {produit?.nom}{" "}
            {produit?.conditionnement && (
              <small style={StyleConditionnement}>
                {produit?.conditionnement}
              </small>
            )}
          </h6>
          <small>{produit?.laboratoire?.nom} </small>
          <small>Référence : {produit?.code_commande}</small>
          <ObjetPrix
            prix={produit?.prix_de_vente_ttc}
            style={{ textAlign: "left", fontWeight: "bolder" }}
            label="Prix : "
          />

          <div style={StyleDescription}>
            <p>{produit?.description}</p>
            <small>{produit.conseil}</small>
            {produit?.contre_indications && (
              <strong>{produit?.contre_indications}</strong>
            )}
            <a href={produit.url_externe} target="_blank">
              {produit.url_externe}
            </a>
          </div>
        </div>

        {/* <BoutonProduitFavori
          produit_id={produit._id}
          produit_external_id={produit.external_id}
          id={"bouton_produit_favori_" + produit.nom}
        /> */}
      </div>
    );
  }

  return (
    <div style={StyleObjetProduit}>
      <IconesProduits
        nomIcone={produit?.forme}
        style={{
          color: "#186D1B",
          marginRight: "10px",
          width: "24px",
          height: "24px",
        }}
      />

      <div
        style={{
          minHeight: "24px",
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <div className="InformationPrincipale" style={{ color: "#186D1B" }}>
          {produit?.nom}{" "}
          {produit?.conditionnement && (
            <span
              style={{
                //fontWeight: "bolder",
                backgroundColor: "#186D1B20",
                padding: "0px 8px",
                border: "1px solid #186D1B80",
                fontSize: "smaller",
                marginLeft: "2px",
                whiteSpace: "nowrap",
              }}
            >
              {produit?.conditionnement}
            </span>
          )}
        </div>

        {afficherLabo && (
          <div
            className="Tracabilite"
            style={{ color: "#186D1B80", fontSize: "14px" }}
          >
            {produit?.laboratoire?.nom}{" "}
            {produit?.code_commande ? " , ref  : " + produit.code_commande : ""}
          </div>
        )}
        {afficherDescription && (
          <div className="InformationSecondaire">{produit?.description}</div>
        )}
        {afficherURL && <a>{produit?.url_externe}</a>}
        <a>{produit?.url_externe}</a>
      </div>
    </div>
  );
}
