import React from "react";
import AutorenewIcon from "@material-ui/icons/Autorenew";
import MonStyle from "./EditionEvenement.module.css";
import BoutonRond from "../../../../../Design/Boutons/BoutonRond";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";

export default function BlocRecurrence({ recurrence, onClick }) {
  const maRecurrence = recurrence ? recurrence : {};

  return (
    <div className={MonStyle.Bloc}>
      <AutorenewIcon className="Icone" />
      <div
        className={MonStyle.Label}
        onClick={onClick}
        style={{ color: maRecurrence.code ? "#208ab0" : "#97989D" }}
      >
        {maRecurrence.libelle
          ? maRecurrence.libelle
          : "Récurrence de l'évènement"}
      </div>
      <BoutonRond
        iconeComposant={<NavigateNextIcon />}
        onClick={onClick}
        tooltip="Choisir la récurrence"
      />
    </div>
  );
}
