import React from "react";
//import { withStyles } from "@material-ui/core/styles";
//import LinearProgress from "@material-ui/core/LinearProgress";
//import Fade from "@material-ui/core/Fade";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { styled } from "@mui/material/styles";
import Fade from "@mui/material/Fade";

/*
const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 4,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: "#DDD",

  },
  bar: {
    borderRadius: 2,
    //backgroundColor: "#205A71",
    backgroundColor: (props) => props.couleur,
  },
}))(LinearProgress);
*/

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 5,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "#DDD",
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 2,
    backgroundColor: "#205A71",
  },
}));

export default function LoaderHorizontal({ isLoading, unmountOnExit, style }) {
  return (
    <div
      style={{
        width: "100%",
      }}
    >
      <Fade
        in={isLoading}
        style={{
          transitionDelay: isLoading ? "1000ms" : "0ms",
          ...style,
        }}
        unmountOnExit={unmountOnExit}
      >
        <BorderLinearProgress />
      </Fade>
    </div>
  );
}
