import React, { useContext, useEffect, useState } from "react";
import { AuthentificationContext } from "../../../../Data/AuthentificationContext";
import MonAvatar from "../../../../Design/Avatar";
import { couleurAction } from "../../../../functions/FonctionsCouleurs";
import { DateOuHeureToString } from "../../../../functions/FonctionsDateHeure";
import SurfacePopUp from "../../../../Design/Surfaces/SurfacePopUp";

export default function AffichageMessage({ message }) {
  //NES Gesiton du contexte
  const auth = useContext(AuthentificationContext);

  const StyleMessageEnvoye = {
    message: {
      //border: "1px solid red",
      display: "flex",
      flexDirection: "row-reverse",
      justifyContent: "flex-start",
      gap: "5px",
      alignItems: "flex-end",
    },
    bulle: {
      backgroundColor: couleurAction(),
      color: "white",
      padding: "10px 20px 10px 20px ",
      borderRadius: "8px 8px 0px 8px",
      border: "1px solid " + couleurAction(),
    },
    bulleEtDate: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "flex-end",
      maxWidth: "60%",
      //border: "1px solid green",
    },
  };

  const StyleMessageRecu = {
    message: {
      //border: "1px solid red",
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-start",
      gap: "5px",
      alignItems: "flex-end",
    },
    bulle: {
      backgroundColor: "#f2f1f5",
      color: "black",
      padding: "10px 20px 10px 20px ",
      borderRadius: "8px 8px 8px 0px",
      border: "1px solid #DDD",
    },
    bulleEtDate: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "flex-start",
      maxWidth: "60%",
      //border: "1px solid green",
    },
  };

  const [style, setStyle] = useState(StyleMessageEnvoye);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (message.owned_by_organisation === auth.payload.owned_by_organisation) {
      setStyle(StyleMessageEnvoye);
    } else {
      setStyle(StyleMessageRecu);
    }
    // eslint-disable-next-line
  }, [message]);

  return (
    <div style={style.message}>
      <MonAvatar
        nom={message.tracabilite_creation.nom_utilisateur}
        prenom={message.tracabilite_creation.prenom_utilisateur}
        image={message.tracabilite_creation.avatar_utilisateur_S}
        taille="M"
      />
      <div style={style.bulleEtDate}>
        {message?.capture_ecran && (
          <div style={{ width: "100%", maxWidth: "300px" }}>
            <img
              onClick={() => setOpen(true)}
              src={message?.capture_ecran}
              style={{
                width: "100%",
                maxWidth: "300px",
                borderRadius: "10px",
                border: "1px solid #DDD",
              }}
              alt=""
            />
            <SurfacePopUp open={open} onClose={() => setOpen(false)}>
              <div
                style={{
                  marginTop: "40px",
                  width: "90vw",
                  maxHeight: "90vh",
                  boxSizing: "border-box",
                  position: "relative",
                }}
              >
                <img
                  src={message?.capture_ecran}
                  alt=""
                  style={{ width: "100%" }}
                />
                {message.url_capture_ecran && (
                  <div
                    style={{
                      backgroundColor: "#00000090",
                      position: "absolute",
                      bottom: "0px",
                      width: "100%",
                      boxSizing: "border-box",
                      color: "white",
                      padding: "10px",
                    }}
                  >
                    {message.url_capture_ecran}
                  </div>
                )}
              </div>
            </SurfacePopUp>
          </div>
        )}
        {message.texte && <div style={style.bulle}>{message.texte}</div>}
        <div className="Label">
          {DateOuHeureToString(message.tracabilite_creation.date_tracabilite)}
        </div>
      </div>
    </div>
  );
}
