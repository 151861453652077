import React, { useContext, useState, createContext } from "react";
import { AuthentificationContext } from "./AuthentificationContext";
import { DELETE } from "../functions/FonctionsApi";
import { PerformancesContext } from "./PerformancesContext";

export const PatientsContext = createContext();

export const PatientsProvider = (props) => {
  //NES gestion du contexte
  const authContext = useContext(AuthentificationContext);
  const { GET } = useContext(PerformancesContext);

  //NES Gestion du state
  const [ongletsPatients, setOngletsPatients] = useState([]);

  //GET Patients/recents
  const getPatientsRecents = async () => {
    const MonUrl = `${process.env.REACT_APP_URL_API_PATIENTS}/patients/recents`;
    let reponseAPI = await GET(MonUrl, authContext.token);
    //let reponseAPI = await GET(MonUrl, authContext.token);
    //NES je retourne à ma fonction appelante le résultat
    return reponseAPI;
  };

  //GET Patients/:id
  const getPatientContext = async (patient_id, hide) => {
    let indexPatient = ongletsPatients.findIndex(
      (item) => item._id === patient_id
    );
    if (indexPatient >= 0) {
      let data = ongletsPatients[indexPatient];
      return { code: "OK", data: data };
    } else {
      //NES j'appelle l'API Médicale
      let MonUrl = `${process.env.REACT_APP_URL_API_PATIENTS}/patients/${patient_id}`;
      let reponseAPI = await GET(MonUrl, authContext.token);
      if (reponseAPI.code === "OK" && !hide) {
        let tempArray = [...ongletsPatients];
        tempArray.push(reponseAPI.data);
        setOngletsPatients(tempArray);
      }
      //NES je retourne à ma fonction appelante le résultat
      return reponseAPI;
    }
  };

  //GET /patients?recherche=type=....
  const getPatientsRecherche = async (recherche, type) => {
    let paramType = "nom";
    //NES gestion automatique du type
    if (type) {
      paramType = type;
    } else {
      //NES recherche automatique par n° de téléphone
      let premiereLettre = recherche[0];
      let listeCaracteres = [
        "+",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
      ];
      if (listeCaracteres.includes(premiereLettre)) {
        paramType = "telephone";
      }
    }

    //NES cas particulier du + dans la recherche
    let strRecherche = recherche.replace("+", "00");

    const MonUrl = `${process.env.REACT_APP_URL_API_PATIENTS}/patients?recherche="${paramType}=${strRecherche}"&sort="nom"&nb=50`;
    let reponseAPI = await GET(MonUrl, authContext.token);
    //NES je retourne à ma fonction appelante le résultat
    return reponseAPI;
  };

  //PATCH Patients/:id
  const patchPatient = async (patient_id, data) => {
    //NES j'appelle l'API
    const MonUrl = `${process.env.REACT_APP_URL_API_PATIENTS}/patients/${patient_id}`;
    //NES pour détecter les appels en boucle
    console.log({ PATCH: MonUrl });
    const reponseApi = await fetch(MonUrl, {
      method: "PATCH",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        token: authContext.token,
      },
      body: JSON.stringify(data),
    });
    //NES je convertis ce que je récupère en JSON
    const reponseJson = await reponseApi.json();
    //NES je le mets à jour dans dans le contexte
    if (reponseJson.code === "OK") {
      let tempArray = [...ongletsPatients];
      let index = tempArray.findIndex((item) => item._id === patient_id);
      tempArray.splice(index, 1, reponseJson.data);
      setOngletsPatients(tempArray);
    }
    //NES je retourne à ma fonction appelante le résultat
    return reponseJson;
  };

  const closePatientContext = (id) => {
    //NES le patient existe-t-il dans les onglets?
    let indexPatient = ongletsPatients.findIndex((item) => item._id === id);
    if (indexPatient >= 0) {
      let tempArray = [...ongletsPatients];
      tempArray.splice(indexPatient, 1);
      setOngletsPatients(tempArray);
    }
  };

  //POST Patients/
  const postPatient = async (data, options) => {
    //NES j'appelle l'API
    const MonUrl = `${process.env.REACT_APP_URL_API_PATIENTS}/patients/`;
    //NES pour détecter les appels en boucle
    console.log({ POST: MonUrl });
    const reponseApi = await fetch(MonUrl, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        token: authContext.token,
      },
      body: JSON.stringify(data),
    });
    //NES je convertis ce que je récupère en JSON
    const reponseJson = await reponseApi.json();
    //NES je le mets à jour dans dans le contexte sauf si l'option ignoreContext===true
    if (reponseJson.code === "OK" && !options?.ignoreContext) {
      let tempArray = [...ongletsPatients];
      tempArray.push(reponseJson.data);
      setOngletsPatients(tempArray);
    }
    //NES je retourne à ma fonction appelante le résultat
    return reponseJson;
  };

  //NES suppression d'un patient dans l'API et dans le contexte
  const deletePatientContext = async (patient_id) => {
    if (patient_id) {
      //NES Appel de l'api
      const MonUrl = `${process.env.REACT_APP_URL_API_PATIENTS}/patients/${patient_id}`;

      let reponseApi = await DELETE(MonUrl, authContext.token);

      if (reponseApi.code === "OK") {
        //NES Je le supprime du contexte
        closePatientContext(patient_id);
      }
      return reponseApi;
    }
  };

  /// NES Récupération de l'objet patient pour les passer dans les API
  const getObjetPatient = (patient_id, options) => {
    let retour = {};

    let indexPatient = ongletsPatients.findIndex(
      (item) => item._id === patient_id
    );

    if (indexPatient > -1) {
      let patient = ongletsPatients[indexPatient];
      retour = {
        patient_id: patient._id,
        titre: patient.titre,
        nom: patient.nom,
        nom_jeune_fille: patient.nom_jeune_fille,
        prenom: patient.prenom,
        date_de_naissance: patient.date_de_naissance,
        lieu_naissance: patient.lieu_naissance,
        sexe: patient.sexe,
        age: patient.age,
      };

      if (options?.withAdresse) {
        retour = { ...retour, adresse: patient.adresse };
      }
    }

    return retour;
  };

  return (
    <PatientsContext.Provider
      value={{
        ongletsPatients,
        getPatientContext,
        patchPatient,
        closePatientContext,
        postPatient,
        deletePatientContext,
        getObjetPatient,
        getPatientsRecents,
        getPatientsRecherche,
      }}
    >
      {props.children}
    </PatientsContext.Provider>
  );
};
