import React, { useContext, useState, useEffect } from "react";
import MonStyle from "./AffichageProblemePaiement.module.css";
import BoutonActionPrincipale from "../../../Design/Boutons/BoutonActionPrincipale";
import SurfacePapier from "../../../Design/Surfaces/SurfacePapier";
import SurfacePopUp from "../../../Design/Surfaces/SurfacePopUp";
import { UtilisateursContext } from "../../../Data/UtilisateursContext";
import { capitalize } from "../../../functions/Formatter";
import {
  DateToString,
  nombreJoursBetweenDates,
} from "../../../functions/FonctionsDateHeure";
import MessageAide from "../../../Design/Messages/MessageAide";
import BoutonTexte from "../../../Design/Boutons/BoutonTexte";
import CorrectionProblementPaiement from "../../Facturation/Components/CorrectionProblemePaiement/CorrectionProblemePaiement";
import AffichageConditionnel from "../../../Design/DataDisplay/AffichageConditionnel";
import ObjetStripePaymentMethod from "../../../Design/Objets/ObjetStripePaymentMethod";
import { AuthentificationContext } from "../../../Data/AuthentificationContext";

export default function AffichageProblemePaiement({ disableButton }) {
  //NES Gestion du contexte
  const { monOrganisation } = useContext(UtilisateursContext);
  const auth = useContext(AuthentificationContext);

  //NES Gestion du state
  const [open, setOpen] = useState(false);
  const [afficher, setAfficher] = useState(false);
  const [message, setMessage] = useState("");
  const [afficherPopupCorrection, setAfficherPopupCorrection] = useState(false);
  const [delai, setDelai] = useState();

  //USEEFFECT
  useEffect(() => {
    if (
      monOrganisation &&
      monOrganisation?.facturation?.reglement?.etat_reglement !== "OK"
    ) {
      setAfficher(true);
      setMessage(
        "Lors du règlement du " +
          DateToString(monOrganisation?.facturation?.reglement?.date_reglement)
      );

      let aujourdhui = new Date();
      let nbJours = nombreJoursBetweenDates(
        aujourdhui,
        monOrganisation?.facturation?.reglement?.date_reglement
      );
      let monDelai = 8 - nbJours;
      setDelai(8 - nbJours);

      //NES affichage en mode popup blocante
      if (monDelai < 1) {
        setOpen(true);
      }
    } else {
      setAfficher(false);
    }
  }, [monOrganisation]);

  if (!afficher) {
    return "";
  }

  return (
    <SurfacePapier>
      <MessageAide severity="error" message={message} disableClose>
        <strong>
          {monOrganisation?.facturation?.reglement?.erreur_reglement + " "}
        </strong>
      </MessageAide>
      <h3 style={{ marginTop: "20px" }}>
        Il y a un problème avec votre carte bancaire
      </h3>

      <p>
        Votre carte carte de paiment a été invalidée. Vous devez mettre à jour
        votre carte bancaire de paiement pour continuer à utiliser{" "}
        <span className="NomProduit">
          {capitalize(process.env.REACT_APP_NOM)}
        </span>
        .
      </p>

      <ObjetStripePaymentMethod
        paymentMethod={
          monOrganisation?.facturation?.reglement
            ?.information_paiement_electronique?.payment_method
        }
      />
      <AffichageConditionnel open={!disableButton}>
        <div className={MonStyle.Boutons}>
          <BoutonActionPrincipale
            texte="Mettre à jour la carte"
            taille="L"
            disabled={!auth.payload.is_admin}
            link="/facturation/carte-bancaire#formulaire"
          />
          <h4>({delai} jours restants)</h4>
        </div>
      </AffichageConditionnel>

      <SurfacePopUp
        open={open}
        titre="Il ya un problème avec votre carte bancaire"
        disableCloseIcone={true}
      >
        <p>
          {message}, nous avons reçu l'erreur suivante:{" "}
          <strong>
            {monOrganisation?.facturation?.reglement?.erreur_reglement}
          </strong>
        </p>
        <p>
          Vous devez mettre à jour votre carte bancaire de paiement pour
          continuer à utiliser{" "}
          <span className="NomProduit">
            {capitalize(process.env.REACT_APP_NOM)}
          </span>
          .
        </p>
        <div className={MonStyle.Boutons}>
          <BoutonTexte texte="Déconnexion" />
          <BoutonActionPrincipale
            texte="Mettre à jour la carte de paiement"
            onClick={() => setAfficherPopupCorrection(true)}
          />
        </div>
      </SurfacePopUp>

      <CorrectionProblementPaiement
        open={afficherPopupCorrection}
        onClose={() => setAfficherPopupCorrection(false)}
      />
    </SurfacePapier>
  );
}
