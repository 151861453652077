import React from "react";
import BoutonRond from "./BoutonRond";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import StarIcon from "@mui/icons-material/Star";

export default function BoutonDefaut({ selected, hide, ...params }) {
  if (hide && !selected) {
    return "";
  }

  return (
    <BoutonRond
      iconeComposant={
        selected ? (
          <StarIcon />
        ) : (
          <StarBorderIcon style={{ color: "#97989D80" }} />
        )
      }
      tooltip={selected ? "Par défaut" : "Utiliser par défaut"}
      {...params}
    />
  );
}
