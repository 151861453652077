import React from "react";
import IconesBe from "../Icones/IconesBe";
import ObjetItem from "./ObjetItem";

export default function ObjetOrganisation({ organisation }) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        gap: "10px",
        width: "100%",
      }}
    >
      <IconesBe nomIcone="SINOBI" />
      <ObjetItem
        principale={organisation.nom}
        secondaire={organisation._id}
        couleurPrincipale="#213945"
      />
    </div>
  );
}
