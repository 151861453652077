import React from "react";
import MonStyle from "./SurfaceObjetSelectionnable.module.css";

export default function SurfaceObjetSelectionnable({
  style,
  selected,
  onClick,
  ...props
}) {
  return (
    <div
      onClick={onClick}
      className={
        selected ? MonStyle.Selected : MonStyle.SurfaceObjetSelectionnable
      }
      style={style}
    >
      {props.children}
    </div>
  );
}
