import React from "react";

export default function DescriptionChoixRole({ code_role }) {
  const styleNonDisponible = {
    color: "#97989D",
    textDecoration: "line-through",
  };

  switch (code_role?.toUpperCase()) {
    case "MEDECIN":
      return (
        <ul style={{ width: "100%", margin: 0 }}>
          <li>Agenda</li>
          <li style={styleNonDisponible}>Agenda</li>
        </ul>
      );
    case "SECRETAIRE":
      return (
        <ul style={{ width: "100%", margin: 0 }}>
          <li>Agenda</li>
          <li>Dossier Médical</li>
          <li style={styleNonDisponible}>Agenda</li>
        </ul>
      );

    default:
      return "";
  }
}
