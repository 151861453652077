import React, { useContext } from "react";
import MonStyle from "./BlocDateRdv.module.css";
import EventIcon from "@material-ui/icons/Event";
import BoutonRond from "../../../../../Design/Boutons/BoutonRond";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { AgendasContext } from "../../../../../Data/AgendasContext";
import {
  DateToString,
  DateToHeure,
} from "../../../../../functions/FonctionsDateHeure";

export default function BlocDateRdv({
  date_heure,
  onHeureChange,
  onClick,
  agenda_id,
}) {
  //NES gestion du state
  const { getListeCasesHoraires } = useContext(AgendasContext);

  const heures = getListeCasesHoraires(agenda_id);

  return (
    <div className={MonStyle.BlocDateRdv}>
      <div className={MonStyle.LibelleDate} onClick={onClick}>
        <EventIcon className="IconeObjet" />

        {date_heure &&
          DateToString(date_heure, {
            weekday: "short",
            month: "long",
            day: "numeric",
          })}
      </div>
      <div className={MonStyle.Choix}>
        <Select
          id="rdv_heure"
          disableUnderline={true}
          value={DateToHeure(date_heure, {
            hour: "numeric",
            minute: "numeric",
          })}
          style={{ color: "#208AB0" }}
          onChange={onHeureChange}
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: "50%",
              },
            },
          }}
        >
          {heures.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </Select>
      </div>
      <BoutonRond
        iconeComposant={<NavigateNextIcon />}
        onClick={onClick}
        tooltip="Changer de date"
      />
    </div>
  );
}
