import React, { useContext, useEffect, useState } from "react";
import BoutonActionFlottant from "../../Design/Boutons/BoutonActionFlottant";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import BarreOngletsPatients from "../../Components/BarreOngletsPatients/BarreOngletsPatients";
import SurfacePage from "../../Design/Surfaces/SurfacePage";
import MonStyle from "./PagePatientsRecents.module.css";
import LigneResultat from "../../Design/Recherche/LigneResultat";
import ObjetPatient from "../../Design/Objets/ObjetPatients";
import MessageAucuneDonnee from "../../Design/Messages/MessageAucuneDonnee";
import {
  DateToHeure,
  AujourdhuiToString,
  DateToString,
} from "../../functions/FonctionsDateHeure";
import BoutonActionSecondaire from "../../Design/Boutons/BoutonActionSecondaire";
import { PatientsContext } from "../../Data/PatientsContext";
import LoaderHorizontal from "../../Design/Progression/LoaderHorizontal";
import MessageErreur from "../../Design/Messages/MessageErreur";
import InputRecherche from "../../Design/Recherche/InputRecherche";
import { pluriel } from "../../functions/Formatter";
import ObjetAdresse from "../../Design/Objets/ObjetAdresse";
import AffichageConditionnel from "../../Design/DataDisplay/AffichageConditionnel";
import ObjetListeTelephones from "../../Design/Objets/ObjetListeTelephones";
import queryString from "query-string";

export default function PagePatientsRecents() {
  //NES récupération des paramètres de l'URL
  let PARAMS = queryString.parse(window.location.search);

  //NES gestion du contexte
  const { getPatientsRecents, getPatientsRecherche } =
    useContext(PatientsContext);

  //NES gestion du state
  const [sousTitre, setSousTitre] = useState("");
  const [dossiersRecents, setDossiersRecents] = useState([]);
  const [patients, setPatients] = useState([]);
  const [erreur, setErreur] = useState("");
  const [messageErreur, setMessageErreur] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [texteRecherche, setTexteRecherche] = useState("");
  const [erreurAucuneDonnee, setErreurAucuneDonnee] = useState("");
  const [conseil, setConseil] = useState("");

  //USEEFFECT récupération des param
  useEffect(() => {
    if (PARAMS.texteRecherche) {
      setTexteRecherche(PARAMS.texteRecherche);
      document.getElementById("ChampDeRecherchePatientsRecents").focus();
    }
  }, [PARAMS.texteRecherche]);

  //USEEFFECT récupération des patients récents
  useEffect(() => {
    getPatientsRecents().then((reponseJson) => {
      setIsLoading(false);
      if (reponseJson.code === "OK") {
        let listePatients = reponseJson.data;

        if (listePatients.length > 0) {
          //NES grouper les patients par jour
          let aujourdhui = AujourdhuiToString();
          let PatientsParJour = [];
          for (const element of listePatients) {
            let jourDernierAcces = DateToString(element.date_dernier_acces);
            //NES pour remplacer la date du jour par aujourd'hui
            if (jourDernierAcces === aujourdhui) {
              jourDernierAcces = "aujourdhui";
            }
            //NES est que le groupe existe déjà ?
            let index = PatientsParJour.findIndex(
              (item) => item.jour === jourDernierAcces
            );
            if (index === -1) {
              //NES on ajoute le patient et on ajoute le patient courant
              PatientsParJour.push({
                jour: jourDernierAcces,
                patients: [element],
              });
            } else {
              //NES le groupe existe j'ajoute juste le patient
              PatientsParJour[index].patients.push(element);
            }
          }
          setDossiersRecents(PatientsParJour);
        } else {
          setErreurAucuneDonnee("Aucun dossier patient ouvert récemment");
        }
      } else {
        setErreur(reponseJson);
        setMessageErreur("Erreur lors de la récupération des patients récents");
      }
    });
    // eslint-disable-next-line
  }, []);

  //USEEFFECT Recherche patient automatique
  useEffect(() => {
    setPatients([]);
    setErreurAucuneDonnee("");

    const timer = setTimeout(() => {
      if (texteRecherche) {
        setIsLoading(true);
        //GET
        getPatientsRecherche(texteRecherche).then((reponse) => {
          setIsLoading(false);
          if (reponse.code === "OK") {
            //NES affichage des résultats
            setPatients(reponse.data);
            //NES message aucun résultat
            if (reponse.data.length > 0) {
              setErreurAucuneDonnee();
              setSousTitre(
                pluriel(
                  reponse.data.length,
                  "patient trouvé",
                  "patients trouvés"
                )
              );
            } else {
              setErreurAucuneDonnee("Aucun patient trouvé");
              setConseil("Essayez de modifier votre recherche");
              setSousTitre("Aucun patient trouvé");
            }
          }
          //NES cas particulier où l'on a pas saisi assez de chiffre pour la recherche par téléphone
          if (reponse.code === "INSUFFISANT") {
            setErreurAucuneDonnee("Complétez votre recherche");
            setConseil(reponse.message);
            setSousTitre("");
          }

          if (reponse.code === "KO") {
            //NES en cas d'erreur
            setErreur(reponse);
            setMessageErreur(
              "Erreur lors de la récupération des patients recherchés"
            );
          }
        });
      }
    }, 1000);
    return () => clearTimeout(timer);
    // eslint-disable-next-line
  }, [texteRecherche]);

  return (
    <div className={MonStyle.PagePatientsRecents}>
      <BarreOngletsPatients idUrl="patientsrecents" />
      <SurfacePage
        couleur="gris"
        style={{
          maxHeight: "calc(100vh - 120px)",
          minHeight: "calc(100vh - 120px)",
          border: "0px solid red",
        }}
      >
        <div className={MonStyle.Content}>
          <header className={MonStyle.TitreEtBouton}>
            <h1>
              Recherche patient
              <div className="SousTitre">
                {texteRecherche
                  ? sousTitre
                  : "Liste des dossiers patients consultés récemment"}
              </div>
            </h1>
            <div className={MonStyle.Boutons}>
              <BoutonActionSecondaire
                texte="Nouveau patient"
                link="/patients/nouveau/dossier"
              />
            </div>
          </header>

          <div className={MonStyle.ZoneRecherche}>
            <InputRecherche
              name="Rechercher un patient"
              id="ChampDeRecherchePatientsRecents"
              placeholder="Nom prénom ou téléphone"
              value={texteRecherche}
              onEffacer={() => setTexteRecherche("")}
              onChange={(e) => setTexteRecherche(e.target.value)}
            />
          </div>
          <main className={MonStyle.TableauResultat}>
            <LoaderHorizontal isLoading={isLoading} />
            <MessageAucuneDonnee
              message={erreurAucuneDonnee}
              conseil={conseil}
            />
            <MessageErreur message={messageErreur} error={erreur} taille="XL" />

            <AffichageConditionnel open={texteRecherche ? false : true}>
              {dossiersRecents.map((groupe) => (
                <section key={groupe.jour}>
                  <h4 className={MonStyle.TitreGroupe}>{groupe.jour}</h4>
                  {groupe.patients.map((item) => (
                    <LigneResultat
                      link={"/patients/" + item._id}
                      key={item._id}
                    >
                      <div className={MonStyle.ColonnePatient}>
                        <ObjetPatient
                          patient={item}
                          isArchive={item.is_archive}
                        />
                      </div>
                      <div className={MonStyle.Heure}>
                        {DateToHeure(item.date_dernier_acces)}
                      </div>
                    </LigneResultat>
                  ))}
                </section>
              ))}
            </AffichageConditionnel>

            <AffichageConditionnel
              open={texteRecherche ? true : false}
              className={MonStyle.Resultats}
            >
              {patients.map((patient) => (
                <LigneResultat
                  link={"/patients/" + patient._id}
                  key={patient._id}
                >
                  <div className={MonStyle.ColonnePatient}>
                    <ObjetPatient patient={patient} />
                  </div>
                  <div className={MonStyle.ColonneTelephone}>
                    <ObjetListeTelephones
                      telephones={patient?.contact?.telephones}
                    />
                  </div>
                  <div className={MonStyle.ColonneAdresse}>
                    <ObjetAdresse adresse={patient?.adresse} />
                  </div>
                </LigneResultat>
              ))}
            </AffichageConditionnel>
          </main>
        </div>
        <BoutonActionFlottant
          iconeComposant={<PersonAddIcon />}
          tooltip="Nouveau patient"
          link="/patients/nouveau/dossier"
          id="bouton_nouveau_patient"
        />
      </SurfacePage>
    </div>
  );
}
