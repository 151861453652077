import React from "react";
import IllustrationTypeSante from "../../Images/Illustrations/TypeSante.png";
import IllustrationTypeGenerique from "../../Images/Illustrations/TypeGenerique.png";
import IllustrationTypeEnergetique from "../../Images/Illustrations/TypeEnergetique.png";

export default function IconesCodeConsultation({ code, style, className }) {
  switch (code) {
    case "consultation_generique":
      return (
        <img
          src={IllustrationTypeGenerique}
          alt=""
          style={style}
          className={className}
        />
      );
    case "consultation":
      return (
        <img
          src={IllustrationTypeSante}
          alt=""
          style={style}
          className={className}
        />
      );

    case "consultation_energetique":
      return (
        <img
          src={IllustrationTypeEnergetique}
          alt=""
          style={style}
          className={className}
        />
      );

    case "anamnese":
      return (
        <img
          src={IllustrationTypeEnergetique}
          alt=""
          style={style}
          className={className}
        />
      );

    default:
      return "";
  }
}
