import React, { useContext, useState, useEffect } from "react";
import MonStyle from "./BoutonCaptureEcran.module.css";
import SurfacePopUp from "../../Design/Surfaces/SurfacePopUp";
import { AuthentificationContext } from "../../Data/AuthentificationContext";
import LoaderCirculaire from "../../Design/Progression/LoaderCirculaire";
import CameraAltIcon from "@material-ui/icons/CameraAlt";
import { SupportContext } from "../../Data/SupportContext";
import ObjetDemande from "../../Design/Objets/ObjetDemande";
import LigneResultat from "../../Design/Recherche/LigneResultat";
import BoutonActionSecondaire from "../../Design/Boutons/BoutonActionSecondaire";
import { resizeImageFn } from "../../functions/FonctionsFichiers";
import Compress from "compress.js";
import PageDemande from "../../Pages/Support/Pages/PageDemande";
import NouvelleDemande from "../../Pages/Support/Components/NouvelleDemande/NouvelleDemande";
import LoaderHorizontal from "../../Design/Progression/LoaderHorizontal";
import MessageErreur from "../../Design/Messages/MessageErreur";
import MessageAucuneDonnee from "../../Design/Messages/MessageAucuneDonnee";
import AppTooltip from "../../Design/DataDisplay/ToolTip";
import IconButton from "@material-ui/core/IconButton";
import { withStyles } from "@material-ui/core/styles";
import CaptureEcran from "../../Design/DataDisplay/CaptureEcran";

const BoutonCaptureTheme = withStyles((theme) => ({
  root: {
    color: "white",
    fontWeight: "700",
    height: "60px",
    width: "60px",
    display: "flex",
    flexDirection: "colunm",
    alignItems: "center",
    justifyContent: "center",
    padding: "0px",
    border: "1px solid white",
    backgroundColor: "#208AB0",
    "&:hover": {
      backgroundColor: "#208AB080",
    },
  },
}))(IconButton);

export default function BoutonCapture() {
  //NES gestion du contexte
  const authContext = useContext(AuthentificationContext);
  const { demandes, getDemandes, postDemandesMessages } =
    useContext(SupportContext);

  //NES gestion du state
  const [afficher, setAfficher] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [messageErreur, setMessageErreur] = useState();
  const [error, setError] = useState();
  const [capture, setCapture] = useState();
  const [afficherConversation, setAfficherConversation] = useState();
  const [afficherNouvelleDemande, setAfficherNouvelleDemande] = useState(false);
  const [liste, setListe] = useState([]);

  //NES capture d'écran
  const handleCapture = () => {
    setAfficher(true);
    authContext.getCaptureEcran();
    //GET récupération des demandes
    getDemandes().then((reponse) => {
      setIsLoading(false);
      if (reponse.code === "OK") {
        //NES pour ne pas afficehr les demandes corrigées
        let data = reponse.data;
        data = data.filter((item) => item.etat !== "CORRIGE");
        setListe(data);
      } else {
        setMessageErreur(reponse.message);
        setError(reponse.error);
      }
    });
  };

  ///Fermeture de la popup
  const handleClose = () => {
    setAfficher(false);
    setCapture();
  };

  //USEEFFECT compression de la capture
  useEffect(() => {
    setAfficherConversation();
    setAfficherNouvelleDemande(false);
    let captureTropGrande = authContext.captureEcran;
    if (captureTropGrande) {
      const array = captureTropGrande.split(";");
      console.log({ array });
      if (array.length >= 2) {
        let data64 = array[1].replace("base64,", "");
        const captureFile = Compress.convertBase64ToFile(data64, "image/png");
        resizeImageFn(captureFile).then((fileResized) => {
          let reader = new FileReader();
          reader.readAsDataURL(fileResized);
          reader.onload = function () {
            setCapture(reader.result);
          };
        });
      }
    }
  }, [authContext.captureEcran]);

  //POST
  const handleSelectDemande = (demande_id) => {
    let newData = {
      capture_ecran: capture,
      url_capture_ecran: document.location.href,
    };

    postDemandesMessages(demande_id, newData).then((reponse) => {
      setIsLoading(false);
      if (reponse.code === "OK") {
        setAfficherConversation(reponse?.demande?._id);
      } else {
        setMessageErreur("Erreur lors de l'enregistrement");
      }
    });
  };

  //NES quand je viens d'enregistrer une nouvelle demande
  const handleEnregistrer = (demande) => {
    setAfficherNouvelleDemande(false);
    setAfficherConversation(demande._id);
  };

  //NES novuelle demande
  const handleNouvelleDemande = () => {
    setAfficherNouvelleDemande(false);
    setAfficherConversation();
  };

  return (
    <div className={MonStyle.BoutonCapture}>
      <AppTooltip texte="Faire une capture d'écran" placement="right">
        <BoutonCaptureTheme
          //to="/patientsrecents"
          id="bouton_capture"
          onClick={handleCapture}
        >
          <CameraAltIcon style={{ fontSize: 32, marginTop: "2px" }} />
        </BoutonCaptureTheme>
      </AppTooltip>
      <SurfacePopUp
        open={afficher}
        onClose={handleClose}
        disablePadding
        style={{ width: "fit-content" }}
      >
        <div className={MonStyle.PopUp}>
          {!afficherConversation ? (
            <div className={MonStyle.PopUp}>
              <div className={MonStyle.PartieGauche}>
                {authContext.captureEcran ? (
                  <div className={MonStyle.Capture}>
                    <h5>Selectionnez la demande</h5>
                    <p className="SousTitre">
                      La capture d'écran sera ajouté à la conversation
                    </p>
                    <div className={MonStyle.Image}>
                      <CaptureEcran src={capture} />
                    </div>
                  </div>
                ) : (
                  <LoaderCirculaire isLoading={true} />
                )}
              </div>

              <div className={MonStyle.PartieDroite}>
                {afficherNouvelleDemande ? (
                  <NouvelleDemande
                    onAnnuler={handleNouvelleDemande}
                    onEnregistrer={handleEnregistrer}
                    capture={capture}
                  />
                ) : (
                  <div className={MonStyle.ListeDemandes}>
                    <BoutonActionSecondaire
                      texte="Nouvelle demande"
                      fullWidth
                      style={{ margin: "20px 0px" }}
                      //onClick={(e) => setIndex(1)}
                      onClick={() => setAfficherNouvelleDemande(true)}
                    />
                    <LoaderHorizontal isLoading={isLoading} />
                    <MessageErreur message={messageErreur} error={error} />
                    <MessageAucuneDonnee
                      message={
                        demandes.length === 0 &&
                        !isLoading &&
                        "Aucune demande en cours"
                      }
                      conseil="Créez une nouvelle demande"
                      hideIllustration
                    />
                    {liste.map((demande) => (
                      <LigneResultat
                        onClick={() => handleSelectDemande(demande._id)}
                      >
                        <ObjetDemande demande={demande} />
                      </LigneResultat>
                    ))}
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div className={MonStyle.PageDemande}>
              <PageDemande demande_id={afficherConversation} />
            </div>
          )}
        </div>
      </SurfacePopUp>
    </div>
  );
}
