import React from "react";
import MonStyle from "./PageRgpd.module.css";
import SurfacePage from "../../Design/Surfaces/SurfacePage";
import IllustrationRgpd from "../../Images/Illustrations/RGPD.svg";
import LogoCnil from "../../Images/Logo/CNIL.png";

export default function PageRgpd() {
  return (
    <SurfacePage>
      <div className={MonStyle.Titre}>
        <h1> Protection de vos données </h1>
        <h4>RGPD</h4>

        <img
          src={IllustrationRgpd}
          className={MonStyle.Illustration}
          alt="illustration sécurité"
        />
      </div>

      <section>
        <h2>
          Conformité RGPD : comment informer les personnes et assurer la
          transparence ?
        </h2>
        <p>
          Le règlement général sur la protection des données (RGPD) impose une
          <mark> information concise, transparente, compréhensible</mark> et
          aisément accessible des personnes concernées. Cette obligation de
          transparence est définie aux articles 12, 13 et 14 du RGPD. La CNIL
          fait le point sur les mesures permettant de respecter cette
          obligation.
        </p>
        <p>
          Le RGPD impose une information complète et précise. Les modalités de
          fourniture et de présentation de cette information doivent être
          adaptées au contexte. La transparence permet aux personnes concernées
          : de connaître la raison de la collecte des différentes données les
          concernant ; de comprendre le traitement qui sera fait de leurs
          données ; d’assurer la maîtrise de leurs données, en facilitant
          l’exercice de leurs droits. Pour les responsables de traitement, elle
          contribue à un traitement loyal des données et permet d’instaurer une
          relation de confiance avec les personnes concernées.
        </p>
      </section>
      <section>
        <h2>Informations générales</h2>
        <p>
          Toutes les informations collectées et stockées par l’Atelier du Pixel
          n’ont pour unique but que d’
          <mark>assurer le fonctionnement de ses logiciels </mark>
          de gestion de santé et des outils de prise de rendez vous ou de suivi
          de comptabilité et de facturation. de comptabilité et de facturation.{" "}
          <mark>
            {" "}
            Aucune des informations saisies n’est transformée, ni transmise à
            des tiers, de comptabilité et de facturation.{" "}
          </mark>{" "}
          sous quelque forme que ce soit (excepté lors des transmissions
          automatisées vers le portail Sesam Vital pour les médecins soumis à
          ces obligations légales).{" "}
        </p>
        <p>
          Ci après sont désignés par le terme “Utilisateur” tout médecin,
          soignant, personnel de santé, secrétaire médical, praticien qui
          souscrit un abonnement actif ou inactif aux logiciels de gestion de
          santé de l’Atelier de Pixel.
        </p>
        <p>
          Ci après sont désignés par le terme “Patient” toute personne se
          rendant chez un utilisateur et pour lequel ce dernier va créer un
          dossier de suivi et y saisir des informations relatives à sa personne.
        </p>
        <p>
          <mark>
            Toute donnée collectée et enregistrée dans nos systèmes est écrite
            dans des bases de données encryptées.
          </mark>{" "}
          Conformément à la loi sur les données médicales, l’ensemble des
          données permettant à un utilisateur de retrouver le dossier médical
          complet d’un patient sont stockées 20 ans à partir du moment où
          l’utilisateur cesse d’utiliser le système. Pour toute information
          complémentaire sur l’utilisation faite par vos données personnelles,
          nous vous invitons à nous contacter directement via notre page de
          contact, ou ouvrir un cas de support directement dans le logiciel si
          vous êtes abonné. L’Atelier du Pixel n’est pas responsable de
          l’utilisation faite des données que les utilisateurs ont la
          possibilité d’extraire dans le cas d’un changement de système de
          gestion de leur cabinet. Ces données les concernent, leur organisation
          et leur patients.
        </p>
      </section>
      <section>
        <h2>Concernant les utilisateurs</h2>
        <p>
          L’Atelier du Pixel traite les données recueillies de ses utilisateurs
          dans l’unique but de pouvoir établir légalement ses factures, et pour
          pouvoir leur apporter un service de support optimal et sécurisé. Les
          utilisateurs du logiciel sont capables à chaque instant de vérifier
          leurs informations personnelles dans le système par simple utilisation
          du logiciel, elles sont visibles sur la page de leur profil
          utilisateur. Elles sont consultables et modifiables à tout moment.
        </p>
        <p>
          L’Atelier du Pixel n’effectue aucune collecte de données externe ou
          complémentaire sur ses utilisateurs. Leurs moyens de paiement ne sont
          pas stockés par l’Atelier du Pixel, l’Atelier du Pixel utilise l’outil
          Stripe (acteur mondial dans le domaine du paiement sécurisé en ligne),
          afin d’effectuer ses transactions financières et garantir la parfaite
          sécurité et conformité de celles-ci. Un utilisateur peut à tout
          moment, s’il le désire, faire la demande de mise à disposition de
          l’intégralité des données personnelles le concernant, ainsi que celles
          de ses patients, sous forme de fichiers.
        </p>
        <p>
          L’Atelier du Pixel se dégage de toute responsabilité quant à
          l’utilisation et la protection des fichiers récupérés ainsi par
          l’utilisateur. L’utilisateur a un devoir de transparence vis à vis de
          ses patients, il doit les informer qu’il collecte des données sur eux,
          et dans quel but.
        </p>
      </section>
      <section>
        <h2>Concernant les patients</h2>
        <p>
          Les <mark>données personnelles des patients</mark> des utilisateurs ne
          sont visibles que des utilisateurs en charge de leur dossier ou des
          utilisateurs de la même organisation et autorisés. Les données
          médicales des patients des utilisateurs ne sont visibles que des
          utilisateurs en charge de leur dossier ou des utilisateurs de la même
          organisation et autorisés, ces données d’une sensibilité supérieure{" "}
          <mark>
            sont hébergées sur des serveurs garantis et certifiés "Hébergeur de
            données de santé” chez Amazon Web Services. sont hébergées sur des
            serveurs garantis et certifiés{" "}
          </mark>
        </p>
        <p>
          L’Atelier du Pixel n’a pas accès aux données personnelles des patients
          des utilisateurs.
        </p>
        <p>
          La signalétique des patients est anonymisée dans les bases de données
          de développement.
        </p>
      </section>
      <section>
        <img
          src={LogoCnil}
          className={MonStyle.LogoCnil}
          alt="Logo de la Cnil"
        />
        <h2>DÉSIGNATION D'UN DÉLÉGUÉ À LA PROTECTION DES DONNÉES</h2>
        <p>DÉSIGNATION N° DPO-125227</p>
        <h3>ORGANISME DÉSIGNANT LE DÉLÉGUÉ À LA PROTECTION DES DONNÉES</h3>
        <p>
          <div>N° Siren : 901628370</div>
          <div>Nom de l'organisme : L'ATELIER DU PIXEL</div>
          <div>Nom du représentant légal : Monsieur Nicolas ESSE</div>
          <div>Adresse postale: 8 rue du président Roosevelt 57940 YUTZ</div>
          <div>PAYS : FRANCE</div>
        </p>
        <h3>DÉLÉGUÉ À LA PROTECTION DES DONNÉES DÉSIGNÉ</h3>{" "}
        <p>
          <div>Nom du délégué : Monsieur Jérémy BAUE</div>
          <div>Date de prise de fonction : 23/11/2022</div>
          <div>
            Adresse postale publique :8 rue du président Roosevelt 57940 YUTZ{" "}
          </div>
          <div>Adresse postale: 8 rue du président Roosevelt 57940 YUTZ</div>
          <div>PAYS : FRANCE</div>
          <div>Adresse électronique dédiée : latelierdupixel@gmail.com</div>
        </p>
      </section>
    </SurfacePage>
  );
}
