import React, { useState, useEffect, useCallback } from "react";
import Chip from "@material-ui/core/Chip";
import { withStyles } from "@material-ui/core/styles";
import { couleurAction } from "../../functions/FonctionsCouleurs";
import InputBase from "@material-ui/core/InputBase";

// The `withStyles()` higher-order component is injecting a `classes`
// prop that is used by the `Button` component.
const StyledChip = withStyles({
  root: {
    backgroundColor: couleurAction(),
    //border: "1px solid " + couleurAction(),
    fontFamily: "Lato",
    color: "white",
    fontSize: "16px",
    margin: "0px",
    maxWidth: "100%",
    boxSizing: "content-box",
    display: "flex",
    height: "fit-content",
    padding: "3px",
    width: "fit-content",
    "&:hover": {
      backgroundColor: couleurAction(),
    },
    "&:focus": {
      backgroundColor: couleurAction(),
    },
  },
  label: {
    overflow: "wrap",
    overflowWrap: "normal",
    whiteSpace: "normal",
    paddingBottom: "2px",
  },
  deleteIcon: {
    color: "#FFFFFF80",
    "&:hover": {
      color: "#FFFFFF",
    },
  },
})(Chip);

const StyledChipInput = withStyles({
  root: {
    backgroundColor: "#FFFFFF",
    border: "1px solid #208AB0",
    borderRadius: "16px",
    padding: "3px 8px 0px 13px",
    fontFamily: "Lato",
    color: "#208AB0",
    fontSize: "16px",
    margin: "0px",
    maxWidth: "100%",
    display: "flex",
    height: "fit-content",
    minWidth: "10px",
    overflowWrap: "normal",
    overflow: "auto",
    whiteSpace: "normal",
    boxSizing: "border-box",
  },
  input: {
    width: "100%",
    minWidth: "unset",
    padding: "0px",
    paddingBottom: "3px",
  },
})(InputBase);

export default function EditableChip({ label, onChange, onBlur, ...props }) {
  const STYLEHIDDEN = { height: 0, overflow: "hidden" };

  //NES gestion du state
  const [value, setValue] = useState("");
  const [width, setWidth] = useState(0);
  const [afficherInput, setAfficherInput] = useState(false);

  //NES gestion des ref
  // eslint-disable-next-line
  const refChip = useCallback((node) => {
    if (node !== null) {
      setWidth(node.getBoundingClientRect().width);
    }
  });

  //USEEFFECT
  useEffect(() => {
    setValue(label);
  }, [label]);

  ///handleChange
  const handleChange = (e) => {
    let newValue = e.target.value;

    setValue(newValue);
    if (onChange) {
      onChange(newValue);
    }
  };

  ///handleClick
  const handleClick = () => {
    setAfficherInput(true);
  };

  ///handleBlur
  const handleBlur = () => {
    setAfficherInput(false);
    if (onBlur) {
      onBlur(value);
    }
  };

  ///handleKeyDown
  const handleKeyDown = (key) => {
    if (key.code === "Enter") {
      handleBlur();
    }
  };

  return (
    <div
      style={{
        width: "fit-content",
        border: "0px solid red",
        boxSizing: "content-box",
      }}
    >
      {afficherInput && (
        <StyledChipInput
          value={value}
          onChange={handleChange}
          multiline
          style={{ width: width }}
          onBlur={handleBlur}
          onKeyDown={handleKeyDown}
          autoFocus
        />
      )}
      <div style={afficherInput ? STYLEHIDDEN : {}}>
        <StyledChip
          label={value}
          ref={refChip}
          onClick={handleClick}
          clickable
          {...props}
        />
      </div>
    </div>
  );
}
