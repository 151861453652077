import React, { useContext, useState, useEffect } from "react";
import MonStyle from "./EditionRdv.module.css";
import SurfacePapier from "../../../../../Design/Surfaces/SurfacePapier";
import BoutonRond from "../../../../../Design/Boutons/BoutonRond";
import CloseIcon from "@material-ui/icons/Close";
import SurfaceBloc from "../../../../../Design/Surfaces/SurfaceBloc";
import { Divider } from "@material-ui/core";
import SwipeableViews from "react-swipeable-views";
import BoutonRetour from "../../../../../Design/Boutons/BoutonRetour";
import EditionPatient from "./EditionPatient";
import BlocTelephone from "./BlocTelephone";
import DatePicker from "../../../../../Design/Pickers/DatePicker";
import { setHeureFromString } from "../../../../../functions/FonctionsDateHeure";
import BlocTypeRdv from "./BlocTypeRdv";
import BlocDateRdv from "./BlocDateRdv";
import BlocPatient from "./BlocPatient";
import BoutonActionPrincipale from "../../../../../Design/Boutons/BoutonActionPrincipale";
import EditionTypeRdv from "./EditionTypeRdv";
import { AgendasContext } from "../../../../../Data/AgendasContext";
import BlocAdresse from "./BlocAdresse";
import EditionAdresse from "./EditionAdresse";
import BlocCommentaire from "./BlocCommentaire";
import { RdvContext } from "../../../../../Data/RdvContext";
import BoutonTexte from "../../../../../Design/Boutons/BoutonTexte";
import EditionSupprimerRdv from "./EditionSupprimerRdv";
import MessageErreur from "../../../../../Design/Messages/MessageErreur";
import BoutonActionSecondaire from "../../../../../Design/Boutons/BoutonActionSecondaire";
import { MessagesContext } from "../../../../../Data/MessagesContext";
import EditionTelephone from "./EditionTelephone";
import InfoRdvPatient from "./InfoRdvPatient";
import BlocEmail from "./BlocEmail";
import MessageAlerte from "../../../../../Design/Messages/MessageAlerte";
import EditionRappel from "./EditionRappel";
import BlocRdvSupprime from "./BlocRdvSupprime";

const nouveauPatient = {
  patient_id: null,
  patient_nom: "",
  patient_nom_jeune_fille: "",
  patient_prenom: "",
  patient_date_naissance: "",
  patient_contact: "",
  adresse: {},
};

export default function EditionRdv({ agenda_id, date, heure, onClose, rdv }) {
  //NES gestion du contexte
  const { agendaById } = useContext(AgendasContext);
  const { postRdv, patchRdv } = useContext(RdvContext);
  const { envoyerEmailConfirmationRdv, envoyerSmsConfirmationRdv } =
    useContext(MessagesContext);

  //NES récupération des infos par défaut de l'agenda
  const initialisationRDV = () => {
    let agenda = agendaById(agenda_id);
    let type_rendez_vous = {};

    if (agenda) {
      //NES récuration du 1er de type consultation
      // type_rendez_vous = agenda.types_rendez_vous.find(
      //   (item) => item.type_code === "consultation"
      // );
      type_rendez_vous = agenda.types_rendez_vous[0];
    }

    let data = {
      date_heure: setHeureFromString(date, heure),
      duree: type_rendez_vous.duree,
      patient: nouveauPatient,
      type_rendez_vous: type_rendez_vous,
    };
    return data;
  };

  //NES gestion du state
  const [monRdv, setMonRdv] = useState(initialisationRDV);
  const [voletEdition, setVoletEdition] = useState("");
  const [texteRecherche, setTexteRecherche] = useState("");
  const [messageErreur, setMessageErreur] = useState("");
  const [error, setError] = useState("");
  const [isModified, setIsModified] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [telephoneConfirmation, setTelephoneConfirmation] = useState(false);
  const [emailConfirmation, setEmailConfirmation] = useState(false);
  const [message, setMessage] = useState("");

  //NES State peur SwipeableViews
  const [index, setIndex] = useState(0);

  //USEEFFECT
  useEffect(() => {
    if (rdv) {
      setMonRdv(rdv);
    }
  }, [rdv]);

  //NES j'ai sélectionné un patient
  const handlePatientSelected = (patientRdv) => {
    let newData = { ...monRdv, patient: patientRdv };
    setMonRdv(newData);
    //NES je passe à l'écran précédent
    setIndex(0);
    //NES sauvegarde auto si possible
    autoSave(newData);
  };

  //NES pour supprimer le patient sélectionné
  const handleSupprimerPatientSelectionne = () => {
    setMonRdv({ ...monRdv, patient: nouveauPatient });
  };

  //NES je veux créer un nouveau patient
  const handleNouveauPatient = () => {
    setVoletEdition("patient");
    setMonRdv({ ...monRdv, patient: nouveauPatient });
    setIndex(1);
  };

  //NES retour au rdv
  const handleRetourRdv = () => {
    //NES je passe à l'écran précédent
    setIndex(0);
  };

  //NES je viens de modifier le dossier patient et je mets à jour le rdv
  const handleSavePatient = (key, value) => {
    setMessage("");
    let newPatient = monRdv.patient;
    if (key) {
      newPatient = { ...newPatient, [key]: value };
    } else {
      newPatient = { ...newPatient, ...value };
      console.log({ value });

      console.log({ newPatient });
    }

    let newData = {
      ...monRdv,
      patient: newPatient,
    };

    setMonRdv(newData);
    if (newPatient.patient_id) {
      setMessage("Dossier patient modifié");
    }
    //NES je passe à l'écran du rdv
    setIndex(0);
    //NES sauvegarde auto si possible
    autoSave(newData);
  };

  //NES changement de l'heure du rdv
  const handleChangementHeure = (strHeure) => {
    let nouvelleDate = setHeureFromString(monRdv.date_heure, strHeure);
    setMonRdv({ ...monRdv, date_heure: nouvelleDate });
    setIsModified(true);
  };

  //NES quand je change la date du rdv
  const handleModificationDate = (date) => {
    let dateActuelleRdv = new Date(monRdv.date_heure);

    //NES je récupère l'heure du rdv courant
    let heureRdv = dateActuelleRdv.toLocaleTimeString("fr-FR", {
      hour: "numeric",
      minute: "numeric",
    });

    let nouvelleDate = setHeureFromString(date, heureRdv);
    setMonRdv({ ...monRdv, date_heure: nouvelleDate });
    setIndex(0);
    setIsModified(true);
  };

  //NES quand je change la durée du rdv
  const handleDureeChange = (duree) => {
    let newData = { ...monRdv, duree: duree };

    setMonRdv(newData);
    //NES sauvegarde auto si possible
    autoSave(newData);
  };

  //NES sélection d'un type de rdv dans la liste
  const handleSelectTypeRdv = (typeRdv) => {
    let data = {
      libelle: typeRdv.libelle,
      couleur: typeRdv.couleur,
      type_code: typeRdv.type_code,
    };

    let newData = { ...monRdv, type_rendez_vous: data, duree: typeRdv.duree };

    setMonRdv(newData);
    //NES je passe à l'écran du rdv
    setIndex(0);
    //NES sauvegarde auto si possible
    autoSave(newData);
  };

  //NES afficher l'édition de l'adresse, du téléphone ....etc....
  const handleClickEdition = (quoi) => {
    setVoletEdition(quoi);
    //NES je passe à l'écran edition
    setIndex(1);
  };

  //NES quand je saisie du commentaire
  const handleChangeCommentaire = (commentaire) => {
    let newData = { ...monRdv, commentaire: commentaire };
    setMonRdv(newData);
  };

  const handleBlurCommentaire = (commentaire) => {
    let newData = { ...monRdv, commentaire: commentaire };
    setMonRdv(newData);
    //NES sauvegarde auto si possible
    autoSave(newData);
  };

  const handleEnregistrerRdv = () => {
    setIsLoading(true);
    sauvegarder(monRdv)
      .then((reponse) => {
        setIsLoading(false);
        if (reponse.code === "OK") {
          //NES Est ce que je dois prévenir par sms ? la fonction gère les tests
          if (telephoneConfirmation) {
            envoyerSmsConfirmationRdv(
              telephoneConfirmation,
              "CONFIRMATION",
              reponse.data
            );
          }
          //NES Est ce que je dois envoyer un email?
          if (emailConfirmation) {
            envoyerEmailConfirmationRdv(
              emailConfirmation,
              "CONFIRMATION",
              reponse.data
            );
          }
          //NES je ferme le rdv
          if (onClose) {
            onClose();
          }
        } else {
          setMessageErreur("Erreur lors de l'enregistrement");
        }
      })
      .catch((error) => {
        setMessageErreur("Erreur lors de l'enregistrement");
        setError(error);
      });
  };

  //NES pour le patient en général j'oublie de sauvegarder, je ne peut pas fait un autosave global
  //NES car si je change la date et l'heure je vais envoyer 2 sms de confirmations :(
  //NES donc tant que cela ne touche pas à la date et heure le type de consultation... autosave
  const autoSave = (data) => {
    setMessage("");
    if (data._id) {
      patchRdv(agenda_id, data._id, data).then((reponse) => {
        if (reponse.code === "OK") {
          console.log(reponse);
          setMonRdv(reponse.data);
          setMessage("Modifications enregistrées");
        }
      });
    }
  };

  //NES je séparer pour ne gérer qu'un seul then error
  const sauvegarder = async (data) => {
    if (data._id) {
      //PATCH rdv
      let reponse = await patchRdv(agenda_id, data._id, data);
      return reponse;
    } else {
      //POST rdv/
      let reponse = await postRdv(agenda_id, data);
      return reponse;
    }
  };

  ///handleDelete
  const handleDelete = () => {
    if (onClose) {
      //NES on ferme la popup
      onClose();
    }
  };

  return (
    <SurfacePapier
      couleur="gris"
      disablePadding
      style={{ display: "flex", flexDirection: "row" }}
    >
      <div className={MonStyle.PartieGauche}>
        <InfoRdvPatient
          patient_id={monRdv?.patient?.patient_id}
          monRdv={monRdv}
        />
      </div>

      <div className={MonStyle.EditionRdv}>
        <div className={MonStyle.TitreEtBouton}>
          <div className="TitrePop">Rendez vous</div>

          <BoutonRond
            tooltip="Fermer"
            iconeComposant={<CloseIcon />}
            onClick={onClose}
            couleurIcone="noir"
            id="bouton_fermer_rdv"
          />
        </div>
        <SwipeableViews index={index}>
          {/* 0 : BLOC AFFICHAGE DU RDV */}
          <div className={MonStyle.View}>
            <SurfaceBloc>
              <BlocPatient
                rdv_id={monRdv._id}
                patient={monRdv.patient}
                onSelect={handlePatientSelected}
                onSupprimerPatient={handleSupprimerPatientSelectionne}
                onModifierPatient={() => handleClickEdition("patient")}
                onClickNouveau={handleNouveauPatient}
                onAutocompleteChange={(e) => setTexteRecherche(e.target.value)}
              />
              <Divider />
              <BlocTelephone
                patient={monRdv.patient}
                onClick={() => handleClickEdition("telephones")}
              />
              <BlocAdresse
                adresse={monRdv.patient.adresse}
                onClick={() => handleClickEdition("adresse")}
              />
              <Divider />
              <BlocEmail
                patient_id={monRdv.patient.patient_id}
                value={monRdv.patient.patient_email}
                onBlur={(e) =>
                  handleSavePatient("patient_email", e.target.value)
                }
              />
            </SurfaceBloc>

            <SurfaceBloc>
              <BlocDateRdv
                agenda_id={agenda_id}
                date_heure={monRdv.date_heure}
                onHeureChange={(event) =>
                  handleChangementHeure(event.target.value)
                }
                onClick={() => handleClickEdition("date")}
              />
              <Divider />
              <BlocTypeRdv
                type_rendez_vous={monRdv.type_rendez_vous}
                duree={monRdv.duree}
                onDureeChange={(event) => handleDureeChange(event.target.value)}
                onClick={() => handleClickEdition("typeRdv")}
              />
            </SurfaceBloc>

            <EditionRappel
              mode="AFFICHAGE"
              onClick={() => handleClickEdition("rappels")}
              emailConfirmation={emailConfirmation}
              telephoneConfirmation={telephoneConfirmation}
              agenda_id={agenda_id}
              monRdv={monRdv}
              onMailConfirmationChange={(email) => setEmailConfirmation(email)}
              onTelephoneConfirmationChange={(numero) =>
                setTelephoneConfirmation(numero)
              }
              style={{ margin: "20px 0px" }}
            />

            <BlocCommentaire
              value={monRdv.commentaire}
              onChange={(event) => handleChangeCommentaire(event.target.value)}
              onBlur={(event) => handleBlurCommentaire(event.target.value)}
            />
            <MessageErreur
              message={messageErreur}
              error={error}
              style={{ marginTop: "20px" }}
            />

            {(isModified || monRdv._id === undefined) && (
              <BoutonActionPrincipale
                fullWidth
                texte={monRdv._id ? "Modifier le rdv" : "Enregistrer le rdv"}
                taille="L"
                //style={{ marginTop: "10px" }}
                disabled={!monRdv.patient.patient_id}
                onClick={handleEnregistrerRdv}
                isLoading={isLoading}
              />
            )}

            {rdv?.patient?.patient_id && rdv._id && (
              <BoutonActionSecondaire
                texte="Ouvrir le dossier"
                taille="L"
                fullWidth
                link={"/patients/" + rdv.patient.patient_id}
                couleur="bleu"
              />
            )}
            {monRdv._id && !monRdv.annulation_rdv && (
              <BoutonTexte
                texte="Annuler le rendez-vous"
                couleur="rouge"
                fullWidth
                onClick={() => handleClickEdition("supprimer")}
              />
            )}
            <BlocRdvSupprime
              annulation_rdv={monRdv.annulation_rdv}
              onClick={() => handleClickEdition("supprimer")}
            />
          </div>

          {/*  1 : BLOC EDITION */}
          <div className={MonStyle.View}>
            <BoutonRetour texte="Retour" onClick={handleRetourRdv} />
            {voletEdition === "patient" && (
              <EditionPatient
                patient={monRdv.patient}
                onEnregistrer={(data) => handleSavePatient("", data)}
                texteRecherche={texteRecherche}
              />
            )}
            {voletEdition === "date" && (
              <DatePicker
                onDateSelect={handleModificationDate}
                date={monRdv.date_heure}
              />
            )}
            {voletEdition === "typeRdv" && (
              <EditionTypeRdv
                agenda_id={agenda_id}
                onSelect={handleSelectTypeRdv}
              />
            )}
            {voletEdition === "adresse" && (
              <EditionAdresse
                adresse={monRdv.patient.adresse}
                onChange={(data) => handleSavePatient("adresse", data)}
                patient_id={monRdv.patient.patient_id}
              />
            )}
            {voletEdition === "telephones" && (
              <EditionTelephone
                contact={monRdv.patient.patient_contact}
                patient_id={monRdv.patient.patient_id}
                onChange={(data) => handleSavePatient("patient_contact", data)}
              />
            )}
            {voletEdition === "rappels" && (
              <EditionRappel
                agenda_id={agenda_id}
                monRdv={monRdv}
                telephones={monRdv?.patient?.patient_contact?.telephones}
                onMailConfirmationChange={(email) =>
                  setEmailConfirmation(email)
                }
                onTelephoneConfirmationChange={(numero) =>
                  setTelephoneConfirmation(numero)
                }
                sousTitre="  Vous pouvez confirmer la date et l'heure du rendez-vous au patient en
                lui envoyant un email et / ou un sms."
                titre="Confirmation du rendez-vous"
              />
            )}
            {voletEdition === "supprimer" && (
              <EditionSupprimerRdv rdv={monRdv} onSupprimer={handleDelete} />
            )}
          </div>
        </SwipeableViews>
        <MessageAlerte message={message} />
      </div>
    </SurfacePapier>
  );
}
