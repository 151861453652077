import Axios from "axios";

//API //////////////////////////////////////////////////////////////////////
//API         AVATAR
//API //////////////////////////////////////////////////////////////////////

//API PATCH https://utilisateurs.nicoandjez.net/utilisateur/:id/avatar
export async function PATCH_ApiUtilisateurs_Avatar(
  fichier,
  utilisateur_id,
  token,
  callback
) {
  const MonUrl = `${process.env.REACT_APP_URL_API_UTILISATEURS}/utilisateurs/${utilisateur_id}/avatar/`;
  //NES pour détecter les appels en boucle
  console.log(MonUrl);

  if (fichier) {
    const fd = new FormData();
    fd.append("image", fichier, fichier.name);
    Axios.patch(MonUrl, fd, {
      mode: "no-cors",
      headers: {
        "Access-Control-Allow-Origin": "*",
        token: token,
      },
      onUploadProgress: (ProgressEvent) => {
        console.log(
          "Upload :" +
            Math.round((ProgressEvent.loaded / ProgressEvent.total) * 100) +
            "%"
        );
      },
    }).then((reponseApi) => {
      //NES On met à jour l'url de l'image
      console.log(reponseApi.data);
      //const reponseJson = reponseApi.data.json();
      callback(reponseApi.data);
    });
  }
}

//API //////////////////////////////////////////////////////////////////////
//API         RECOVER PASSWORD
//API //////////////////////////////////////////////////////////////////////

//API POST https://utilisateurs.nicoandjez.net/authentification/deconnexion
export async function POST_RecoverPassword(email, callback) {
  //NES ma route d'appel
  const MonUrl = `${process.env.REACT_APP_URL_API_UTILISATEURS}/recoverpassword`;
  //NES pour détecter les appels en boucle
  console.log(MonUrl);
  //NES Construction de mon objet d'appel en JSON
  const data = {
    utilisateur: {
      email: email,
    },
  };
  //NES je récupère les data depuis l'API
  const reponseApi = await fetch(MonUrl, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
  //NES je récupère le retour
  const reponseJson = await reponseApi.json();
  callback(reponseJson);
}

//API POST https://utilisateurs.nicoandjez.net/authentification/deconnexion
export async function PATCH_RecoverPassword(password, hash, callback) {
  //NES ma route d'appel
  const MonUrl = `${process.env.REACT_APP_URL_API_UTILISATEURS}/recoverpassword`;
  //NES pour détecter les appels en boucle
  console.log(MonUrl);
  //NES Construction de mon objet d'appel en JSON
  const data = {
    hash: hash,
    password: password,
  };
  //NES je récupère les data depuis l'API
  const reponseApi = await fetch(MonUrl, {
    method: "PATCH",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
  //NES je récupère le retour
  const reponseJson = await reponseApi.json();
  callback(reponseJson);
}
