import React, { useContext } from "react";
import { useState, useEffect } from "react";
import InputTextArea from "../../../../Design/Formulaires/InputTextArea";
import MessageOuiNon from "../../../../Design/Messages/MessageOuiNon";
import { ConseilsContext } from "../../../../Data/ConseilsContext";
import MessageErreur from "../../../../Design/Messages/MessageErreur";
import MessageAlerte from "../../../../Design/Messages/MessageAlerte";

export default function EditionEntete({ value, onBlur }) {
  //NES gestion du contexte
  const { patchPreferences } = useContext(ConseilsContext);

  //NES Gestion du state
  const [texte, setTexte] = useState(
    "Le présent document n'est pas une prescription et ne peut donner lieu à aucun remboursement. Il n'est qu'un conseil phyto-alimentaire assimilable à une démarche personnelle"
  );
  const [afficherSauvegarder, setAfficherSauvegarder] = useState(false);
  const [erreur, setErreur] = useState("");
  const [error, setError] = useState();
  const [succes, setSucces] = useState("");

  useEffect(() => {
    if (value) {
      setTexte(value);
    }
  }, [value]);

  //NES Quand on veut modifier le texte
  const handleChange = (e) => {
    setTexte(e.target.value);
    setAfficherSauvegarder(true);
  };

  //PATCH préferences
  const sauvegarder = () => {
    setSucces("");
    patchPreferences({ avertissement_conseil: texte })
      .then((reponse) => {
        console.log(reponse);
        setAfficherSauvegarder(false);
        if (reponse.code === "OK") {
          setSucces(
            "L'avertissement est bien enregistré comme avertissement par défaut"
          );
        } else {
          setErreur(reponse.erreur.message);
        }
      })
      .catch((error) => {
        setError(error);
        setErreur(
          "Erreur lors de l'enregistrement de l'avertissement par défaut"
        );
      });
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div className="TitreBloc" style={{ marginBottom: "5px" }}>
        Avertissement
      </div>
      <div
        style={{
          padding: "0px",
          color: "white",
          backgroundColor: "#F6F6F6",
          fontSize: "13px",
        }}
      >
        <InputTextArea value={texte} onChange={handleChange} onBlur={onBlur} />
      </div>

      <MessageOuiNon
        message="Voulez enregistrer comme modèle par défaut ?"
        style={{ marginTop: "2px" }}
        open={afficherSauvegarder}
        onClickNon={() => setAfficherSauvegarder(false)}
        onClickOui={sauvegarder}
      />
      <MessageAlerte message={succes} />
      <MessageErreur
        message={erreur}
        error={error}
        style={{ margin: "20px 0px" }}
      />
    </div>
  );
}
