import React, { useContext, useEffect, useState } from "react";
import BoutonFiltre from "../BoutonFiltre";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { UtilisateursContext } from "../../../Data/UtilisateursContext";
import ObjetUtilisateur from "../../Objets/ObjetUtilisateur";

export default function FiltreUtilisateur({ style, onChange }) {
  //NES gestion du state
  const [data, setData] = useState([]);
  const [filtre, setFiltre] = useState({});
  const [libelleFiltre, setLibelleFiltre] = useState("");

  //NES gestion du contexte
  const { getUtilisateurs } = useContext(UtilisateursContext);

  //USEEFFECT
  useEffect(() => {
    getUtilisateurs().then((reponse) => {
      setData(reponse.data);
    });
    // eslint-disable-next-line
  }, []);

  //NES quand je clique sur un utilisateur
  const handleClicUtilisateur = (utilisateur) => {
    let monFiltre = {};
    if (filtre._id === utilisateur._id) {
      //NES c'est le même donc j'enlève le filtre
      monFiltre = {};
      setLibelleFiltre("");
    } else {
      monFiltre = utilisateur;
      setLibelleFiltre(
        utilisateur.nom.toUpperCase() + " " + utilisateur.prenom
      );
    }
    setFiltre(monFiltre);

    if (onChange) {
      onChange(monFiltre);
    }
  };

  //NES Quand je clique sur effacer
  const handleEffacer = () => {
    console.log("handleEffacer");

    setFiltre({});
    setLibelleFiltre("");
    if (onChange) {
      onChange({});
    }
  };

  return (
    <BoutonFiltre
      texte="Utilisateur"
      libelleFiltre={libelleFiltre}
      onEffacer={handleEffacer}
      style={style}
    >
      <List style={{ backgroundColor: "white", minWidth: "300px" }}>
        {data.map((item) => (
          <ListItem
            button
            disableGutters
            divider
            key={item._id}
            selected={filtre._id === item._id}
            style={{ backgroundColor: "white" }}
            onClick={() => handleClicUtilisateur(item)}
          >
            <ObjetUtilisateur utilisateur={item} />
            {/* 
            <div
              style={{
                height: "40px",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                paddingLeft: "15px",
              }}
            >
              <MonAvatar
                image={item.avatar_utilisateur_S}
                nom={item.nom_utilisateur}
                prenom={item.prenom_utilisateur}
                taille="M"
              />
              <div style={{ marginLeft: "10px", paddingRight: "20px" }}>
                {item?.nom_utilisateur?.toUpperCase() +
                  " " +
                  item.prenom_utilisateur}
              </div>
            </div> */}
          </ListItem>
        ))}
      </List>
    </BoutonFiltre>
  );
}
