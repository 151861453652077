import React from "react";
import MonStyle from "./PageVerification.module.css";
import ZoneConnexion from "../Components/ZoneConnexion";
import VerificationCompte from "../Components/VerificationCompte";

export default function PageVerification() {
  return (
    <div className={MonStyle.PageVerification}>
      <ZoneConnexion texteBoutonSecondaire="Connexion" lienBoutonSecondaire="/">
        <h2
          style={{
            color: "white",
            marginBottom: "20px",
          }}
        >
          Pourquoi ce code de vérification ?
        </h2>
        <p
          style={{
            color: "white",
          }}
        >
          Nous avons besoin pour des règles de sécurité, d'être sûr que vous
          disposez d'une adresse email valide et que vous n'êtes pas un robot.{" "}
        </p>
        <p
          style={{
            color: "white",
          }}
        >
          Cette adresse va vous permettre de recevoir des codes pour
          authentifier certaines actions et aussi des alertes de sécurités.{" "}
        </p>
        <div
          className="Signature"
          style={{
            color: "white",
            textAlign: "right",
            border: "0px solid red",
            width: "100%",
            marginTop: "40px",
            paddingRight: "20px",
            boxSizing: "border-box",
          }}
        >
          L'équipe Sinobi{" "}
        </div>
      </ZoneConnexion>
      <div className={MonStyle.PartieDroite}>
        <h2>Vérification du compte</h2>
        <div className={MonStyle.Formulaire}>
          <VerificationCompte />
        </div>
      </div>
    </div>
  );
}
