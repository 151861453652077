import React, { useState, useContext } from "react";
import MonStyle from "./PageLogin.module.css";
import BoutonActionPrincipale from "../../Design/Boutons/BoutonActionPrincipale";
import BoutonTexte from "../../Design/Boutons/BoutonTexte";
import MessageErreur from "../../Design/Messages/MessageErreur";
import Actualites from "./Components/Actualites";
import { AuthentificationContext } from "../../Data/AuthentificationContext";
import AppNonDisponible from "./Components/AppNonDisponible";
import { IllustrationConnexion } from "../../Images/Illustrations/Illustrations";
import LoaderHorizontal from "../../Design/Progression/LoaderHorizontal";
import ZoneConnexion from "./Components/ZoneConnexion";
import BoutonActionSecondaire from "../../Design/Boutons/BoutonActionSecondaire";
import { POST } from "../../functions/FonctionsApi";
import { useHistory } from "react-router-dom";
import InputTexteFilled from "../../Design/Formulaires/InputTexteFilled";
import InputPasswordFilled from "../../Design/Formulaires/InputPasswordFilled";

export default function PageLogin() {
  //NES Gestion du routage pour faire des redirections
  let history = useHistory();

  //NES Gestion du state
  const [erreur, setErreur] = useState("");
  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();
  const authContext = useContext(AuthentificationContext);
  const [isNonDisponible] = useState();

  //NES cliquer sur connexion
  const Connexion = (event) => {
    event.preventDefault();
    //NES réinitialisation des erreurs
    setErreur("");
    if (!login) {
      setErreur("Vous devez renseigner votre email de connexion");
    }
    if (!password) {
      setErreur("Vous devez renseigner votre mot de passe");
    }
    if (login && password) {
      setIsLoading(true);

      //NES Construction de mon objet d'appel en JSON
      const data = {
        login: login,
        password: password,
      };
      //NES ma route d'appel
      const MonUrl = `${process.env.REACT_APP_URL_API_UTILISATEURS}/authentification/`;

      POST(MonUrl, "", data).then((reponse) => {
        setIsLoading(false);
        if (reponse.code === "OK") {
          //NES Stocker le token dans le context
          authContext
            .Connexion(reponse.connexion.token, reponse.connexion)
            .then(() => {
              setError();
              setErreur();
              //NES rediriger sur la page d'acceuil
              history.push("/");
            });
        } else {
          //NES les erreurs gérées
          if (reponse.code !== "OK") {
            if (reponse.message) {
              setErreur(reponse.message);
            } else {
              setErreur(
                "Erreur lors de la connexion au service d'authentification"
              );
            }
            setError(reponse);
          }
        }
      });
    }
  };

  if (isNonDisponible === true) {
    return <AppNonDisponible error={error} />;
  }

  return (
    <div className={MonStyle.PageLogin}>
      <ZoneConnexion
        texteBoutonSecondaire="Inscription"
        lienBoutonSecondaire="/inscription"
      >
        <form style={{ padding: "0px 10px" }}>
          <h2
            className="HideMobile"
            style={{ color: "white", marginBottom: "20px" }}
          >
            Connexion
          </h2>
          <InputTexteFilled
            label="Email de connexion"
            value={login}
            onChange={(e) => setLogin(e.target.value)}
            //placeholder="Quel est votre email de connexion ?"
          />
          <InputPasswordFilled
            label="Mot de passe"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            //placeholder="Quel est votre mot de passe?"
          />

          <LoaderHorizontal isLoading={isLoading} />

          <MessageErreur
            message={erreur}
            error={error}
            //style={{ marginTop: "20px" }}
          />

          <BoutonActionPrincipale
            texte="Connexion"
            fullWidth
            onClick={Connexion}
            taille="L"
            // style={{ marginTop: "20px", marginBottom: "0px" }}
          />
          <div className="MobileOnly">
            <BoutonActionSecondaire
              texte="Inscription"
              id="inscription-mobile"
              fullWidth
              couleur="blanc"
              taille="L"
              //style={{ marginBottom: "20px" }}
              link="/inscription"
            />
          </div>

          <BoutonTexte
            fullWidth
            texte="Mot de passe oublié ?"
            link="/recoverPassword"
            taille="L"
            couleur="blanc"
          />
          <IllustrationConnexion style={{ marginTop: "100px" }} />
        </form>
      </ZoneConnexion>

      <div className={MonStyle.PartieDroite}>
        <div className={MonStyle.Actualites}>
          <Actualites />
        </div>
      </div>
    </div>
  );
}
