import React, { useState, useEffect } from "react";
import ObjetItem from "./ObjetItem";
import { formatterTelephone } from "../../functions/Formatter";
import { SvgIcon } from "@material-ui/core";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import PhoneIcon from "@mui/icons-material/Phone";
export default function ObjetListeTelephones({ telephones }) {
  //NES gestion du state
  const [informationPrincipale, setInformationPrincipale] = useState("");
  const [informationSecondaire, setInformationSecondaire] = useState("");
  const [isPortable, setIsPortable] = useState(false);

  //USEEFFECT
  useEffect(() => {
    if (telephones) {
      //NES pour gérer  l'information secondaire
      let autresTelephones = telephones;

      //NES je commence par chercher si j'ai un portable
      let index = telephones.findIndex((item) => item.is_portable);

      if (index > -1) {
        //NES si j'ai un portable c'est l'information principale
        setInformationPrincipale(formatterTelephone(telephones[index].numero));
        //NES je change l'icone
        setIsPortable(true);
        //NES information secondaires j'enlèce celui que j'ai affichée
        autresTelephones.splice(index, 1);
      } else {
        if (telephones.length > 0) {
          setInformationPrincipale(formatterTelephone(telephones[0].numero));
          autresTelephones.splice(0, 1);
        }
      }

      //NES information secondaire

      //NES si j'ai un seul numéro
      if (autresTelephones.length === 1) {
        setInformationSecondaire(
          formatterTelephone(autresTelephones[0].numero)
        );
      }
      //NES si j'ai plusieurs numéros
      if (autresTelephones.length > 1) {
        setInformationSecondaire(
          autresTelephones.length + " autres téléphones"
        );
      }
    }
  }, [telephones]);

  if (!telephones || !informationPrincipale) {
    return "";
  }

  return (
    <div
      style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
    >
      <SvgIcon
        component={isPortable ? PhoneIphoneIcon : PhoneIcon}
        className="IconeObjet"
      />
      <ObjetItem
        principale={informationPrincipale}
        secondaire={informationSecondaire}
      />
    </div>
  );
}
