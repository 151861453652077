//GET
export async function GET(url, token) {
  let reponseAPI = await API("GET", url, token);
  return reponseAPI;
}

//POST
export async function POST(url, token, data) {
  let reponseAPI = await API("POST", url, token, data);
  return reponseAPI;
}

//PATCH
export async function PATCH(url, token, data) {
  let reponseAPI = await API("PATCH", url, token, data);

  return reponseAPI;
}

//PUT
export async function PUT(url, token, data) {
  let reponseAPI = await API("PUT", url, token, data);
  return reponseAPI;
}

//DELETE
export async function DELETE(url, token) {
  let reponseAPI = await API("DELETE", url, token);

  return reponseAPI;
}

export async function API(method, url, token, data) {
  let couleurLog = "#000";

  switch (method) {
    case "GET":
      couleurLog = "#2E7FAE";
      break;
    case "POST":
      couleurLog = "#08AA08";
      break;
    case "PUT":
      couleurLog = "#C6258F";
      break;
    case "PATCH":
      couleurLog = "#FF9800";
      break;
    case "DELETE":
      couleurLog = "#D50A0A";
      break;
    default:
      break;
  }

  //NES gestion dynamique du token
  let headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };

  if (token) {
    headers = { ...headers, token: token };
  }
  let paramAppel = {
    method: method,
    headers: headers,
  };

  //NES si j'ai un body
  if (data) {
    paramAppel = { ...paramAppel, body: JSON.stringify(data) };
  }

  //NES performances
  let timeStart = performance.now();

  //NES appel
  let reponseApi = await fetch(url, paramAppel);

  //NES performances
  let timeEnd = performance.now();
  let duree = Math.round(timeEnd - timeStart);

  //NES pour détecter les appels en boucle
  if (window.location.hostname === "localhost") {
    console.log(
      `%c ${method} %c ${duree}ms %c ${url}`,
      `background: ${couleurLog}; color: #FFF;`,
      `color: ${couleurLog};`,
      `background: none; `
    );
  }
  try {
    let reponseJson = await reponseApi.json();
    reponseJson = {
      ...reponseJson,
      status: reponseApi.status,
      duree_ms: duree,
    };

    //NES est-ce que mon tocken a expiré?
    if (reponseJson.expiredAt) {
      let reponseAvecErreur = {
        code: "KO",
        message: "Votre compte a expiré, vous devez vous reconnecter",
        error: reponseJson.name + " - " + reponseJson.message + " - " + url,
      };
      return reponseAvecErreur;
    }

    //NES l'API a répondu
    if (reponseJson.code) {
      //NES si tout va bien je retourne la réponse
      return reponseJson;
    }

    //NES est que la réponse est KO
    if (reponseApi.ok === false) {
      let reponseAvecErreur = {
        code: method,
        error: reponseApi.status + " - " + method + " : " + url,
        message: reponseApi.statusText,
        technical_stack: "",
        status: reponseApi.status,
        duree_ms: duree,
      };
      return reponseAvecErreur;
    }

    //NES si tout va bien je retourne la réponse
    return reponseJson;
  } catch (error) {
    //NES pour détecter les appels en boucle
    if (window.location.hostname === "localhost") {
      console.log(error);
    }

    let reponseAvecErreur = {
      code: method,
      error: reponseApi.status + " - " + method + " : " + url,
      message: reponseApi.statusText,
      technical_stack: "",
      status: reponseApi.status,
    };
    return reponseAvecErreur;
  }
}
