import React, { useContext, useEffect, useState } from "react";
import { Document, Page, Text, View, StyleSheet } from "@react-pdf/renderer";
import { StylesDocuments } from "../StylesDocumentsPdf";
import { DateToString } from "../../functions/FonctionsDateHeure";
import DocumentPdf from "../DocumentPdf";
import LoaderGlobal from "../../Design/Progression/LoaderGlobal";
import PopupMessageErreur from "../../Design/Messages/PopupMessageErreur";
import { CabinetsContext } from "../../Data/CabinetContext";
import {
  capitalize,
  formatterTelephone,
  numberToEuro,
  pluriel,
} from "../../functions/Formatter";

export default function DocumentJournalDesHonoraires({
  listeFactures,
  dateDebut,
  dateFin,
  open,
  onClose,
  autoPrint,
}) {
  //NES Gestion du style
  const Styles = StyleSheet.create(StylesDocuments);

  //NES gestion du contexte
  const { getObjetCabinet } = useContext(CabinetsContext);

  //NES gestion du state
  const [data, setData] = useState();
  const [messageErreur, setMessageErreur] = useState();
  const [cabinet, setCabinet] = useState();
  const [totalHt, setTotalHt] = useState(0);
  const [totalTva, setTotalTva] = useState(0);
  const [totalTtc, setTotalTtc] = useState(0);
  const [totalARegler, setTotalARegler] = useState(0);

  //USEEFFECT chargement des données du cabinet
  useEffect(() => {
    if (open) {
      let monCabinet = getObjetCabinet();
      setCabinet(monCabinet);
    }
    // eslint-disable-next-line
  }, [open]);

  //USEEFFECT chargement des règlements à afficher
  useEffect(() => {
    if (listeFactures) {
      //NES je vais calculer les totaux
      let tempTotalHt = 0;
      let tempTotalTva = 0;
      let tempTotalTtc = 0;
      let tempTotalARegler = 0;

      listeFactures.forEach((facture) => {
        tempTotalHt = tempTotalHt + facture.total_montant_ht;
        tempTotalTva = tempTotalTva + facture.total_montant_tva_net;
        tempTotalTtc = tempTotalTtc + facture.total_montant_ttc;

        if (facture?.part_patient?.montant_is_regle !== true) {
          let montantRestantARegler =
            facture.part_patient.montant_a_regler -
            facture.part_patient.montant_regle;
          if (montantRestantARegler) {
            tempTotalARegler = tempTotalARegler + montantRestantARegler;
            console.log({ tempTotalARegler, montantRestantARegler });
          }
        }
      });

      setTotalHt(tempTotalHt);
      setTotalTva(tempTotalTva);
      setTotalTtc(tempTotalTtc);
      setTotalARegler(tempTotalARegler);
      setData(listeFactures);
    }
  }, [listeFactures]);

  //NES fermeture de l'impression
  const handleClose = () => {
    //NES pour ne pas garder les donnée dans le cache sinon
    setCabinet();
    if (onClose) {
      onClose();
    }
  };

  const handlePopupMessageErreurClose = () => {
    setMessageErreur();
    handleClose();
  };

  //NES AFFICHAGE PART PATIENT
  const partPatientRegle = (facture) => {
    console.log(facture);

    //NES en cas d'avoir
    if (facture.is_avoir) {
      return "";
    }

    //NES en cas de facture annulée
    if (facture.avoir_facture) {
      return "Annulée";
    }

    if (facture.part_patient) {
      //NES si la facture est réglée
      if (facture.part_patient.montant_is_regle) {
        return "Réglée";
      } else {
        return numberToEuro(
          facture.part_patient.montant_a_regler -
            facture.part_patient.montant_is_regle,
          "€"
        );
      }
    }

    return "regle";
  };

  if (messageErreur && open) {
    return (
      <PopupMessageErreur
        messageErreur={messageErreur}
        onClose={handlePopupMessageErreurClose}
      />
    );
  }

  if (open && (data === undefined || cabinet === undefined)) {
    return <LoaderGlobal isLoading={true} />;
  }

  return (
    <DocumentPdf
      openDocumentPdf={open}
      onClose={handleClose}
      autoPrint={autoPrint}
    >
      <Document>
        <Page size="A4" style={Styles.page}>
          <View>
            <div style={{ display: "flex", flexDirection: "row" }}>
              {/* BLOC CABINET*/}

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  flex: 1,
                  marginRight: "30px",
                }}
              >
                <Text style={Styles.titreBloc}>{cabinet?.nom}</Text>

                <Text>{cabinet?.adresse?.rue}</Text>

                <Text>
                  {cabinet?.adresse?.code_postal +
                    " " +
                    cabinet?.adresse?.ville}
                </Text>

                {cabinet?.telephone?.numero && (
                  <Text>
                    Tél : {formatterTelephone(cabinet?.telephone?.numero)}
                  </Text>
                )}
                {cabinet?.fax?.numero && (
                  <Text>Fax : {formatterTelephone(cabinet?.fax?.numero)}</Text>
                )}

                <Text>{cabinet?.email}</Text>

                <Text>{cabinet?.texte_horaire}</Text>
                <Text>{cabinet?.texte_rdv}</Text>

                <Text
                  style={[
                    Styles.informationPrincipale,
                    {
                      borderTop: "1px solid #D8D8D8",
                      marginTop: "10px",
                      paddingTop: "10px",
                      textAlign: "center",
                    },
                  ]}
                >
                  {cabinet?.texte_libre}
                </Text>
              </div>

              {/* BLOC TITRE*/}

              <div
                style={{
                  flex: 1,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    minHeight: "60px",
                  }}
                >
                  <Text style={Styles.titre}>JOURNAL </Text>
                  <Text style={Styles.titre}>DES HONORAIRES</Text>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <Text>
                      {DateToString(dateDebut, {
                        year: "numeric",
                        month: "numeric",
                        day: "numeric",
                      }) + " "}
                    </Text>
                    {
                      <Text>
                        {dateFin !== dateDebut &&
                          " au " +
                            DateToString(dateFin, {
                              year: "numeric",
                              month: "numeric",
                              day: "numeric",
                            })}
                      </Text>
                    }
                  </div>
                </div>
              </div>
            </div>

            {/* BLOC ENTETES LIGNES*/}

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                height: "20px",
                backgroundColor: "#EEEEEE",
                padding: "0px 10px",
                marginTop: "20px",
              }}
            >
              <Text style={[Styles.titreColonneLeft, { width: "300px" }]}>
                FACTURE
              </Text>
              <Text style={[Styles.titreColonneRight, { width: "100px" }]}>
                MONTANT HT
              </Text>
              <Text style={[Styles.titreColonneRight, { width: "100px" }]}>
                MONTANT TVA
              </Text>
              <Text style={[Styles.titreColonneRight, { width: "100px" }]}>
                MONTANT TTC
              </Text>
              <Text style={[Styles.titreColonneRight, { width: "100px" }]}>
                A REGLER
              </Text>
            </div>

            {/* BLOC LIGNES*/}

            {data?.map((ligne) => (
              <View
                key={ligne._id}
                wrap={false}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  minHeight: "30px",
                  borderTop: "1px solid #D8D8D8",
                  padding: "5px 10px",
                }}
              >
                <div style={[Styles.titreColonneLeft, { width: "300px" }]}>
                  <Text style={Styles.informationPrincipale}>
                    {ligne.is_avoir ? "Avoir " : "Facture"} n°
                    {ligne.numero_facture}
                  </Text>
                  <Text>{DateToString(ligne.date_facture)}</Text>
                  <Text>
                    {ligne?.patient?.nom.toUpperCase() +
                      " " +
                      capitalize(ligne?.patient?.prenom)}
                  </Text>
                </div>
                <Text style={[Styles.normalRight, { width: "100px" }]}>
                  {numberToEuro(ligne.total_montant_ht)}
                </Text>
                <Text style={[Styles.normalRight, { width: "100px" }]}>
                  {numberToEuro(ligne.total_montant_tva_net)}
                </Text>
                <Text style={[Styles.normalRight, { width: "100px" }]}>
                  {numberToEuro(ligne.total_montant_ttc)}
                </Text>
                <Text style={[Styles.normalRight, { width: "100px" }]}>
                  {partPatientRegle(ligne)}
                </Text>
              </View>
            ))}

            {/* BLOC TOTAUX*/}
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                borderTop: "1px solid black",
                borderBottom: "1px solid black",
                minHeight: "20px",
                padding: "0px 10px",
              }}
            >
              <Text style={[Styles.informationPrincipale, { width: "300px" }]}>
                {pluriel(data?.length, "facture", "factures")}
              </Text>
              <Text
                style={[Styles.informationPrincipaleRight, { width: "100px" }]}
              >
                {numberToEuro(totalHt, "€")}
              </Text>
              <Text
                style={[Styles.informationPrincipaleRight, { width: "100px" }]}
              >
                {numberToEuro(totalTva, "€")}
              </Text>
              <Text
                style={[Styles.informationPrincipaleRight, { width: "100px" }]}
              >
                {numberToEuro(totalTtc, "€")}
              </Text>
              <Text
                style={[Styles.informationPrincipaleRight, { width: "100px" }]}
              >
                {numberToEuro(totalARegler, "€")}
              </Text>
            </div>
          </View>

          <View
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              fontSize: "8px",
              position: "absolute",
              bottom: "10mm",
              borderTop: "1px solid black",
              left: "10mm",
              paddingTop: "5px",
            }}
            fixed
          >
            <Text>
              {cabinet?.informations_juridiques?.nom_entite_juridique +
                ", " +
                cabinet?.informations_juridiques?.adresse_siege +
                ". Capital : " +
                cabinet?.informations_juridiques?.capital_social +
                " - N° SIRET : " +
                cabinet?.informations_juridiques?.numero_siret}
            </Text>
            <Text
              style={{
                fontSize: "8px",
                fontWeight: "bold",
              }}
              render={({ pageNumber, totalPages }) =>
                `Page ${pageNumber} / ${totalPages}`
              }
            />
          </View>
        </Page>
      </Document>
    </DocumentPdf>
  );
}
