import React, { useState } from "react";
import InputSwitch from "../../Formulaires/Switch";
import BoutonFiltre from "../BoutonFiltre";

export default function FiltreArchive({ style, texte, label, onChange }) {
  //NES gestion du state
  const [data, setData] = useState(false);
  const [libelleFiltre, setLibelleFiltre] = useState("");

  ///handleChange
  const handleChange = (value) => {
    setData(value);

    if (value) {
      setLibelleFiltre("Afficher les consultations archivées");
    } else {
      setLibelleFiltre("");
    }

    if (onChange) {
      onChange(value);
    }
  };

  return (
    <BoutonFiltre
      texte={texte ? texte : "texte"}
      libelleFiltre={libelleFiltre}
      //      onEffacer={handleEffacer}
      style={style}
      id="filtreArchive"
    >
      <InputSwitch
        label={label ? label : "label"}
        onChange={handleChange}
        value={data}
      />
    </BoutonFiltre>
  );
}
