import React, { useState, createContext, useContext } from "react";
import { AuthentificationContext } from "./AuthentificationContext";
import { DELETE_ApiMedical_Patients_Mesures } from "../Api/ApiMedical";
import { DELETE, GET, PATCH, POST } from "../functions/FonctionsApi";

//NES Création du contexte
export const ConstantesContext = createContext();

export const ConstantesProvider = (props) => {
  //NES gestion du contexte
  const authContext = useContext(AuthentificationContext);

  //NES gestion du state
  const [mesures, setMesures] = useState([]);
  const [mesuresPatient, setMesuresPatient] = useState([]);

  //#region MESURES/
  //POST de la mesure dans le contexte et dans l'API
  const postMesure = async (mesure) => {
    const MonUrl = `${process.env.REACT_APP_URL_API_MEDICAL}/mesures`;
    let reponseApi = await POST(MonUrl, authContext.token, mesure);

    if (reponseApi.code === "OK") {
      setMesures([...mesures, reponseApi.data]);
    }
    return reponseApi;
  };

  //PATCH de la mesure dans le contexte et dans l'API
  const patchMesure = async (mesure) => {
    const MonUrl = `${process.env.REACT_APP_URL_API_MEDICAL}/mesures/${mesure._id}`;
    let reponseApi = await PATCH(MonUrl, authContext.token, mesure);

    if (reponseApi.code === "OK") {
      //NES mise à jour du contexte
      let indexMesure = mesures.findIndex((item) => item._id === mesure._id);
      let tempArray = [...mesures];
      if (indexMesure > -1) {
        tempArray.splice(indexMesure, 1, mesure);
        setMesures(tempArray);
      }
    }
    return reponseApi;
  };

  //DELETE suppression (archivage) de la mesure dans le contexte et dans l'API
  const deleteMesure = async (mesure_id) => {
    const MonUrl = `${process.env.REACT_APP_URL_API_MEDICAL}/mesures/${mesure_id}`;

    let reponseApi = await DELETE(MonUrl, authContext.token);

    if (reponseApi.code === "OK") {
      let indexMesure = mesures.findIndex((item) => item._id === mesure_id);
      let tempArray = [...mesures];
      if (indexMesure > -1) {
        tempArray.splice(indexMesure, 1);
        setMesures(tempArray);
      }
    }

    return reponseApi;
  };

  //GET Récupération des mesures (constantes)
  const getMesures = async () => {
    //NES est ce que j'ai déjà des mesures?
    if (mesures.length > 0) {
      return { code: "OK", data: mesures };
    } else {
      //NES j'appelle l'API
      const MonUrl = `${process.env.REACT_APP_URL_API_MEDICAL}/mesures`;
      let reponseApi = await GET(MonUrl, authContext.token);
      console.log(reponseApi.code);
      if (reponseApi.code === "OK") {
        setMesures(reponseApi.data);
        return reponseApi;
      } else {
        return [];
      }
    }
  };

  //#endregion MESURES

  //#region PATIENTS/:id/MESURES
  //GET récupération des mesures du patient
  const getMesuresPatient = async (patient_id) => {
    const MonUrl = `${process.env.REACT_APP_URL_API_MEDICAL}/patients/${patient_id}/mesures`;

    let reponseApi = await GET(MonUrl, authContext.token);

    if (reponseApi.code === "OK") {
      //NES je stocke les valeurs dans le contexte
      setMesuresPatient(reponseApi.data);
    }
    return reponseApi;
  };

  //PATCH des mesures patient
  const bookmarkMesuresPatient = async (patient_id, mesure_id, is_bookmark) => {
    let data = { is_bookmark: is_bookmark };

    const MonUrl = `${process.env.REACT_APP_URL_API_MEDICAL}/patients/${patient_id}/mesures/${mesure_id}`;

    let reponseApi = await PATCH(MonUrl, authContext.token, data);

    if (reponseApi.code === "OK") {
      //NES je replace la mesure dans le contexte des mesures patient
      let tempArray = [...mesuresPatient];
      let index = tempArray.findIndex(
        (item) => item.mesure_id === mesure_id && item.patient_id === patient_id
      );
      if (index > -1) {
        tempArray.splice(index, 1, reponseApi.data);
        setMesuresPatient(tempArray);
      } else {
        tempArray.push(reponseApi.data);
        setMesuresPatient(tempArray);
      }
    }
    return reponseApi.data;
  };

  //DELETE des mesures patients
  const deleteMesuresPatient = async (patient_id, mesure_id) => {
    if (patient_id && mesure_id) {
      let reponseApi = await DELETE_ApiMedical_Patients_Mesures(
        patient_id,
        mesure_id,
        authContext.token
      );
      console.log({ reponseApi });
      if (reponseApi.code === "OK") {
        //NES mise à jour du contexte
        let index = mesuresPatient.findIndex(
          (item) =>
            item.mesure_id === mesure_id && item.patient_id === patient_id
        );
        if (index >= -1) {
          let tempArray = [...mesuresPatient];
          tempArray.splice(index, 1);
          setMesuresPatient(tempArray);
        }
      }
      return reponseApi;
    }
  };

  //NES récupération des mesures du patient
  const getMesuresPatientById = (mesure_id, patient_id) => {
    let retour = {};
    if (mesure_id && patient_id) {
      retour = mesuresPatient.find(
        (item) => item.mesure_id === mesure_id && item.patient_id === patient_id
      );
    }
    return retour;
  };

  //GET récupération des mesures du patient qui sont épinglées
  const getMesuresPatientBookmarked = async (patient_id) => {
    if (patient_id) {
      //NES est ce que j'ai déjà les données dans le contexte?
      let reponse = await getMesuresPatient(patient_id);

      let mesuresPatient = reponse.data;
      let mesures = mesuresPatient.filter(
        (item) => item.patient_id === patient_id && item.is_bookmark === true
      );
      return { code: "OK", data: mesures };
    }
    return { code: "KO" };
  };

  //NES récupération des mesures de base d'une consultation
  const getMesuresBaseConsultation = async (patient_id, type_consultation) => {
    let data = await getMesures();
    let retour = [];

    //NES on ajoutes les mesures suivies pour le patient
    let mesuresSuivies = await getMesuresPatientBookmarked(patient_id);
    let listeId = [];
    mesuresSuivies.forEach((item) => listeId.push(item.mesure_id));

    //NES les mesures dépendent du type de consultation
    switch (type_consultation) {
      case "pediatrie":
        retour = data.filter(
          (item) =>
            item.code === "poids" ||
            item.code === "temperature" ||
            item.code === "taille" ||
            item.code === "perimetre_cranien" ||
            listeId.includes(item._id)
        );
        break;

      default:
        retour = data.filter(
          (item) =>
            item.code === "poids" ||
            item.code === "temperature" ||
            item.code === "tension" ||
            listeId.includes(item._id)
        );
        break;
    }

    return retour;
  };

  ///isBookmarked
  //NES permet de savoir si la constantes est suivi pour le patient
  const isBookmarked = (mesure_id) => {
    let retour = false;
    let mesure = mesuresPatient.find((item) => item.mesure_id === mesure_id);
    if (mesure) {
      retour = mesure.is_bookmark;
    }
    return retour;
  };

  //NES Test de couleur
  const getColorMesure = (mesure) => {
    if (mesure.code) {
      switch (mesure.code) {
        case "poids":
          return "#2FAD63";
        case "temperature":
          return "#B63730";
        case "glycemie":
          return "#D05E9C";
        case "taille":
          return "#3081B8";
        case "tension":
          return "#3B98D9";
        case "frequence_cardiaque":
          return "#5B4CA1";
        case "sao2":
          return "#FCA728";
        default:
          return "#205A71";
      }
    }
    if (mesure.couleur) {
      return mesure.couleur;
    } else {
      return "#205A71";
    }
  };

  //#endregion PATIENTS/:id/MESURES

  return (
    <ConstantesContext.Provider
      value={{
        mesures,
        getMesures,
        postMesure,
        patchMesure,
        deleteMesure,
        mesuresPatient,
        getMesuresPatient,
        getMesuresPatientById,
        bookmarkMesuresPatient,
        getMesuresPatientBookmarked,
        getMesuresBaseConsultation,
        deleteMesuresPatient,
        getColorMesure,
        isBookmarked,
      }}
    >
      {props.children}
    </ConstantesContext.Provider>
  );
};
