import React from "react";
import Avatar from "../Avatar";
import { capitalize } from "../../functions/Formatter";

export function EtiquetteAdmin({ id, style, color }) {
  const StyleEtiquette = {
    border: "solid 1px #205A71",
    borderColor: color ? color : "#205A71",
    fontSize: "11px",
    fontWeight: "700",
    color: color ? color : "#205A71",
    //height: "16px",
    padding: "2px 10px 2px 10px",
    borderRadius: "2px",
    marginLeft: "10px",
    display: "flex",
    alignItems: "center",
    ...style,
  };

  return (
    <div style={StyleEtiquette} id={id}>
      Admin
    </div>
  );
}

export function EtiquettePartenaire({ id, style }) {
  const StyleEtiquette = {
    border: "solid 1px #205A71",
    fontSize: "11px",
    fontWeight: "700",
    color: "#205A71",
    height: "16px",
    padding: "2px 10px 0px 10px",
    borderRadius: "2px",
    marginLeft: "10px",
    ...style,
  };

  return (
    <div style={StyleEtiquette} id={id}>
      Partenaire
    </div>
  );
}

export default function ObjetUtilisateur({
  utilisateur,
  taille_avatar,
  style,
}) {
  if (utilisateur) {
    let tailleImage = utilisateur.image_M;
    switch (taille_avatar) {
      case "S":
        tailleImage = utilisateur.image_S;
        break;
      case "M":
        tailleImage = utilisateur.image_M;
        break;
      case "L":
        tailleImage = utilisateur.image_L;
        break;

      default:
        break;
    }

    return (
      <div
        style={{
          ...style,
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <div>
          <Avatar
            nom={utilisateur?.nom}
            prenom={utilisateur?.prenom}
            image={tailleImage}
            taille={taille_avatar}
          />
        </div>
        <div style={{ marginLeft: "15px" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              height: "20px",
              marginTop: "2px",
            }}
          >
            <div className="InformationPrincipale">
              <span>{utilisateur?.nom?.toUpperCase()}</span>
              <span>{" " + capitalize(utilisateur?.prenom)}</span>
            </div>
            {utilisateur?.is_admin ? (
              <EtiquetteAdmin id={"admin_" + utilisateur?.email} />
            ) : (
              ""
            )}
          </div>
          {utilisateur?.email && (
            <div className="InformationSecondaire">{utilisateur?.email}</div>
          )}
        </div>
      </div>
    );
  } else {
    return "";
  }
}
