import React, { useContext, useState, useEffect } from "react";
import MonStyle from "./ListeFactures.module.css";
import { CotationsContext } from "../../../../Data/CotationsContext";
import MessageErreur from "../../../../Design/Messages/MessageErreur";
import AffichageFacture from "./AffichageFacture";

export default function ListeFactures({ patient_id, consultation_id }) {
  //NES gestion du contexte
  const { factures, getFacturesByConsultation } = useContext(CotationsContext);
  //NES Gestion du state
  const [messageErreur, setMessageErreur] = useState("");
  const [error, setError] = useState("");

  //USEEFFECT récupération des factures
  useEffect(() => {
    if (patient_id && consultation_id) {
      getFacturesByConsultation(patient_id, consultation_id)
        .then((reponse) => {
          if (reponse.code === "OK") {
          } else {
            setMessageErreur("Erreur lors de la récupération des factures");
          }
        })
        .catch((error) => {
          setMessageErreur("Erreur lors de la récupération des factues");
          setError(error);
        });
    }
    // eslint-disable-next-line
  }, [patient_id, consultation_id]);

  if (factures.length === 0) return "";

  return (
    <div id="factures" className={MonStyle.ListeFactures}>
      {factures.map((facture) => (
        <AffichageFacture facture={facture} key={facture._id} />
      ))}
      <MessageErreur message={messageErreur} error={error} />
    </div>
  );
}
