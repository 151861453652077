import React from "react";
import ObjetItem from "./ObjetItem";
import ObjetTracabilite from "./ObjetTracabilite";
import IconeMui from "../Icones/IconeMui";
import AffichageConditionnel from "../DataDisplay/AffichageConditionnel";

export default function ObjetAntecedent({ antecedent, taille, onClick }) {
  //NES changement d'icone selon le type d'antécédent
  const getIcone = () => {
    let icone = "";
    switch (antecedent.code_type_antecedent) {
      case "antecedent_patient":
        icone = "account_box";
        break;
      case "antecedent_chirurgical":
        icone = "local_hospital";
        break;
      case "antecedent_familial":
        icone = "family_restroom";
        break;
      case "traitement_en_cours":
        icone = "pill";
        break;
      case "allergie":
        icone = "block";
        break;
      default:
        icone = "";
        break;
    }
    return icone;
  };

  const getColorIcone = () => {
    let couleur = "var(--bleuDark)";

    switch (antecedent.code_type_antecedent) {
      case "allergie":
        couleur = "red";
        break;
      case "antecedent_patient":
        couleur = "var(--bleuDark)";
        break;
      case "antecedent_chirurgical":
        couleur = "var(--bleuDark)";
        break;
      case "antecedent_familial":
        couleur = "var(--bleuDark)";
        break;
      case "traitement_en_cours":
        couleur = "var(--vertSante)";
        break;

      default:
        couleur = "var(--bleuDark)";
        break;
    }

    //NES si la couleur est précisée
    if (antecedent.code_couleur) {
      couleur = antecedent.code_couleur;
    }

    return couleur;
  };

  const getBackground = () => {
    let background = "";
    //NES les antécédents importants précédent n'ont pas de code couleur
    if (antecedent.is_important) {
      background = "red";
    }
    if (antecedent.code_couleur) {
      background = antecedent.code_couleur;
    }
    return background;
  };

  //NES gestion de la couleur pour le texte
  const getTexteColor = () => {
    if (antecedent.is_important) {
      return "white";
    } else {
      return "";
    }
  };

  //NES gesiton de la couleur de l'icone en S
  const getColorIconeTailleS = () => {
    let couleur = "var(--bleuDark)";

    //NES si c'est important c'est en blanc
    if (antecedent.is_important) {
      couleur = "white";
      return couleur;
    }

    //NES je vais chercher la couleur de l'icone
    couleur = getColorIcone();

    return couleur;
  };

  const StyleLibelle = {
    width: "fit-content",
    padding: getBackground() ? "8px" : "8px 0px",
    color: getTexteColor(),
    backgroundColor: getBackground(),
    borderRadius: "4px",
    marginBottom: getBackground() ? "8px" : "0px",
  };

  const StyleData = {
    color: antecedent.code_couleur,
    paddingLeft: "5px",
  };

  switch (taille) {
    case "XL":
      return (
        <article onClick={onClick}>
          <IconeMui nom={getIcone()} filled color={getColorIcone()} />
          <data style={StyleData}>
            <h6 style={StyleLibelle}>{antecedent.libelle} </h6>
            <p>{antecedent.commentaire}</p>
            <AffichageConditionnel
              open={antecedent.depuis_le}
              style={{ marginTop: "8px" }}
            >
              <label>Depuis : </label>
              <strong>{antecedent.depuis_le}</strong>
            </AffichageConditionnel>
            <AffichageConditionnel
              open={antecedent.parente}
              style={{ marginTop: "8px" }}
            >
              <label>Parents : </label>
              <strong>{antecedent.parente?.toString()}</strong>
            </AffichageConditionnel>
            <ObjetTracabilite
              tracabiliteCreation={antecedent.tracabilite_creation}
              //tracabiliteModification={antecedent.tracabilite_modification}
              taille="S"
            />
          </data>
        </article>
      );

    default:
      return (
        <div
          id={"id_" + antecedent.libelle}
          onClick={onClick}
          style={{
            background: getBackground(),
            display: "flex",
            padding: "5px",
            borderRadius: "6px",
            gap: "5px",
          }}
        >
          <IconeMui nom={getIcone()} filled color={getColorIconeTailleS()} />
          <ObjetItem
            isBold={antecedent.is_important}
            principale={
              antecedent.bcb ? antecedent.bcb.libelle : antecedent.libelle
            }
            secondaire={antecedent ? antecedent.commentaire : ""}
            couleurTexte={getTexteColor()}
            style={{ maxWidth: "260px" }}
          />
        </div>
      );
  }
}
