import React, { useContext, useEffect, useState } from "react";
import SurfacePopUp from "../../../../Design/Surfaces/SurfacePopUp";
import MonStyle from "./RecherchePrestation.module.css";
import { PrestationsContext } from "../../../../Data/PrestationsContext";
import MessageAucuneDonnee from "../../../../Design/Messages/MessageAucuneDonnee";
import ObjetItem from "../../../../Design/Objets/ObjetItem";
import ObjetPrix from "../../../../Design/Objets/ObjetPrix";
import BoutonActionSecondaire from "../../../../Design/Boutons/BoutonActionSecondaire";
import CheckBox from "../../../../Design/Formulaires/CheckBox";
import BoutonTexte from "../../../../Design/Boutons/BoutonTexte";
import BoutonActionPrincipale from "../../../../Design/Boutons/BoutonActionPrincipale";
import LoaderHorizontal from "../../../../Design/Progression/LoaderHorizontal";
import MessageErreur from "../../../../Design/Messages/MessageErreur";
import { pluriel } from "../../../../functions/Formatter";
import InputRecherche from "../../../../Design/Recherche/InputRecherche";

export default function RechercheCotation({
  open,
  onClose,
  onAjouterPrestations,
}) {
  //NES Gestion du contexte
  const { getPrestations } = useContext(PrestationsContext);

  //NES Gestion du state
  const [liste, setListe] = useState([]);
  const [messageErreur, setMessageErreur] = useState("");
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [texteRecherche, setTexteRecherche] = useState("");
  const [listeSelectionnee, setListeSelectionnee] = useState([]);
  const [message, setMessage] = useState("");
  const [conseil, setConseil] = useState("");
  const [texteBouton, setTexteBouton] = useState("");
  const [lien, setLien] = useState("");

  //USEEFFECT
  useEffect(() => {
    if (open) {
      setListeSelectionnee([]);
    }
  }, [open]);

  //USEEFFECT
  useEffect(() => {
    if (open) {
      setIsLoading(true);
      getPrestations().then((reponse) => {
        setIsLoading(false);
        if (reponse.code === "OK") {
          let maliste = reponse.data;
          //NES je trie par ordre alphabtique
          maliste.sort((a, b) => (a.nom > b.nom ? 1 : -1));
          //NES si j'ai une recherche je filtre les résultats
          if (texteRecherche) {
            maliste = maliste.filter((item) => {
              let chaineRecherche = item.nom + " " + item.description;
              let regExp = new RegExp(
                `${texteRecherche.replace(" ", "(.*)")}`,
                "i"
              );
              let index = chaineRecherche.search(regExp);
              return index > -1 ? true : false;
            });
          }
          //NES gestion de l'affichage de du message si je n'ai pas de prestations retournées
          if (maliste.length === 0) {
            setMessage("Aucune prestation trouvée");
            if (texteRecherche) {
              setConseil("Essayez de modifier  votre recherche");
              setTexteBouton("Ajouter une prestation");
              setLien("/prestations/new");
            } else {
              setConseil(
                "Commencez par renseigner le tarif de votre consultation"
              );
              setTexteBouton("Renseigner le montant de la consultation");
              setLien("/prestations/new-consultation");
            }
          } else {
            setMessage("");
          }

          setListe(maliste);
        } else {
          setMessageErreur("Erreur lors de l'enregistrement");
          setError(reponse);
        }
      });
    }
    // eslint-disable-next-line
  }, [texteRecherche, open]);

  //NES sélection d'une ligne
  const handleCheck = (prestation) => {
    delete prestation.tracabilite_creation;
    delete prestation.tracabilite_modification;

    let index = listeSelectionnee.findIndex(
      (item) => item._id === prestation._id
    );

    if (index > -1) {
      //NES je retire de la liste
      let tempArray = [...listeSelectionnee];
      let index = tempArray.findIndex((item) => item._id === prestation._id);
      tempArray.splice(index, 1);
      setListeSelectionnee(tempArray);
    } else {
      //NES j'ajoute à la liste
      let tempArray = [...listeSelectionnee];
      tempArray.push(prestation);
      setListeSelectionnee(tempArray);
    }
  };

  const ajouterPrestation = (prestation) => {
    delete prestation.tracabilite_creation;
    delete prestation.tracabilite_modification;
    if (onAjouterPrestations) {
      onAjouterPrestations([prestation]);
    }
  };

  const ajouterPlusieursPrestations = () => {
    if (onAjouterPrestations) {
      onAjouterPrestations(listeSelectionnee);
    }
  };

  if (!open) return "";

  return (
    <SurfacePopUp
      open={open}
      onClose={onClose}
      //style={{ width: "80%", maxWidth: "1000px", height: "80vh" }}
      couleur="gris"
      titre="Recherche prestation"
      sousTitre={pluriel(liste.length, "prestation", "prestations", "F")}
    >
      <div className={MonStyle.RecherchePrestation}>
        <div className={MonStyle.ZoneRecherche}>
          <InputRecherche
            withBorder
            placeholder="Rechercher"
            onChange={(e) => setTexteRecherche(e.target.value)}
            value={texteRecherche}
          />
        </div>
        <div className={MonStyle.Liste}>
          <LoaderHorizontal isLoading={isLoading} />
          <MessageErreur message={messageErreur} error={error} />
          <MessageAucuneDonnee
            message={message}
            conseil={conseil}
            hideIllustration
          >
            <BoutonActionSecondaire
              texte={texteBouton}
              style={{ margin: "40px 0px" }}
              link={lien}
            />
          </MessageAucuneDonnee>
          <div>
            {liste.map((prestation) => (
              <div className={MonStyle.Ligne} key={prestation._id}>
                <div className={MonStyle.CheckBox}>
                  <CheckBox
                    onChange={() => handleCheck(prestation)}
                    checked={listeSelectionnee.some(
                      (item) => item._id === prestation._id
                    )}
                  />
                </div>
                <div
                  className={MonStyle.ColonneNom}
                  onClick={() => handleCheck(prestation)}
                >
                  <ObjetItem
                    principale={prestation.nom}
                    secondaire={prestation.description}
                  />
                </div>
                <div
                  className={MonStyle.ColonnePrix}
                  onClick={() => handleCheck(prestation)}
                >
                  <ObjetPrix prix={prestation.prix_vente_ttc} />
                </div>
                <div className={MonStyle.BoutonAjouterPrestation}>
                  <BoutonActionSecondaire
                    texte="Ajouter & régler"
                    onClick={() => ajouterPrestation(prestation)}
                    style={{ marginLeft: "30px" }}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
        {listeSelectionnee.length > 0 && (
          <div className={MonStyle.BarreSelectionMultiple}>
            <div className={MonStyle.BoutonEffacerSelection}>
              <BoutonTexte
                texte="Effacer la sélection"
                couleur="blanc"
                onClick={() => setListeSelectionnee([])}
              />
            </div>
            <div style={{ color: "white", fontStyle: "italic" }}>
              {pluriel(
                listeSelectionnee.length,
                "prestation sélectionnée",
                "prestations sélectionnées"
              )}
            </div>
            <BoutonActionPrincipale
              texte="Ajouter"
              onClick={ajouterPlusieursPrestations}
            />
          </div>
        )}
      </div>
    </SurfacePopUp>
  );
}
