import React, { useContext, useEffect, useState } from "react";
import MonStyle from "./EditionDocuments.module.css";
import { DocumentsContext } from "../../../../Data/DocumentsContext";
import ObjetDocument from "../../../../Design/Objets/ObjetDocument";
import MenuItem from "@material-ui/core/MenuItem";
import BoutonAjouterDocuments from "../../../../Design/Boutons/BoutonAjouterDocuments";
export default function EditionDocuments({ patient_id, consultation_id }) {
  //NES gestion du state
  const [listeDocuments, setListeDocuments] = useState([]);

  //NES Gestion du contexte
  const { documents, deleteDocumentPatientContext } =
    useContext(DocumentsContext);

  //USEEFFECT
  useEffect(() => {
    let tempArray = [];
    tempArray = documents.filter(
      (item) => item.consultation_id === consultation_id
    );
    setListeDocuments(tempArray);
  }, [documents, consultation_id]);

  //NES suppression d'un document
  const handleSupprimer = (document_id) => {
    console.log("handleSupprimer " + document_id);
    deleteDocumentPatientContext(patient_id, document_id).then((reponse) => {
      console.log(reponse);
    });
  };

  //NES ouverture d'un document
  const openDocument = (document_id) => {
    console.log("openDocument " + document_id);
  };

  return (
    <div className={MonStyle.EditionDocuments}>
      <div className="TitreBloc">Documents</div>
      <div className={MonStyle.ListeDocuments}>
        {listeDocuments.map((item) => (
          <MenuItem
            key={item._id}
            disableGutters
            style={{
              minWidth: "200px",
              backgroundColor: "#DDDDDD40",
              margin: "15px 10px 0px 0px",
              borderRadius: "6px",
            }}
          >
            <ObjetDocument
              document={item}
              onClickSupprimer={() => handleSupprimer(item._id)}
              onClick={() => openDocument(item._id)}
            />
          </MenuItem>
        ))}
        <BoutonAjouterDocuments
          patient_id={patient_id}
          consultation_id={consultation_id}
          taille="L"
          style={{ marginTop: "10px" }}
        />
      </div>
    </div>
  );
}
