import React, { useEffect, useState } from "react";
import IconeHomme from "../../Images/Icones/Homme.svg";
import IconeFemme from "../../Images/Icones/Femme.svg";
import IconeInconnu from "../../Images/Icones/Inconnu.svg";
import IconeInconnuFemme from "../../Images/Icones/InconnuFemme.svg";

import MonStyle from "./SelecteurSexe.module.css";

function ChoixSexe({ sexe, onClick, selected, age }) {
  let icone = IconeInconnu;
  switch (sexe) {
    case "Homme":
      icone = IconeHomme;
      break;
    case "Femme":
      icone = IconeFemme;
      break;
    default:
      icone = IconeInconnu;
      break;
  }

  const iconeNonRenseigne = () => {
    if (sexe === "Homme") {
      return IconeInconnu;
    } else {
      return IconeInconnuFemme;
    }
  };

  return (
    <button
      className={selected ? MonStyle.Choix : MonStyle.NonChoix}
      onClick={onClick}
      name={sexe}
    >
      <div>
        <img
          src={selected ? icone : iconeNonRenseigne(sexe)}
          alt={sexe}
          style={{
            width: "40px",
            margin: "0px 15px ",
          }}
        />
      </div>
      <div>
        <div className={MonStyle.Sexe}>{sexe}</div>
      </div>
    </button>
  );
}

export default function SelecteurSexe({ sexe, onChange }) {
  const [choix, setChoix] = useState("");

  const handleChoix = (valeur) => {
    let nouvelleValeur = "";
    //NES si je reclique j'enlève le choix
    if (valeur === choix) {
      nouvelleValeur = "";
    } else {
      nouvelleValeur = valeur;
    }
    setChoix(nouvelleValeur);
    if (onChange) {
      onChange(nouvelleValeur);
    }
  };

  useEffect(() => {
    setChoix(sexe);
  }, [sexe]);

  return (
    <div className={MonStyle.SelecteurSexe}>
      <ChoixSexe
        sexe="Homme"
        onClick={() => handleChoix("Homme")}
        selected={choix === "Homme" ? true : false}
      />
      <ChoixSexe
        sexe="Femme"
        onClick={() => handleChoix("Femme")}
        selected={choix === "Femme" ? true : false}
      />
    </div>
  );
}
