import React from "react";
import ObjetItem from "./ObjetItem";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import ObjetPrix from "./ObjetPrix";

export default function ObjetFraisDePort({ objet, onClick }) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        minHeight: "40px",
        color: "#208AB0",
        width: "100%",
      }}
      onClick={onClick}
    >
      <div
        style={{
          flex: 1,
          display: "flex",
          alignItems: "center",
          width: "80%",
        }}
      >
        <LocalShippingIcon className="IconeObjet" />
        <ObjetItem
          couleurPrincipale={"#208AB0"}
          principale={objet.description}
          style={{ width: "100%" }}
        />
      </div>

      <ObjetPrix
        prix={objet.montant_frais_de_port}
        style={{ width: "20%", minWidth: "90px", marginRight: "10px" }}
      />
    </div>
  );
}
