import React, { useState } from "react";
import { useEffect } from "react";
import { useContext } from "react";
import MonStyle from "./PageListeFactures.module.css";
import { UtilisateursContext } from "../../../Data/UtilisateursContext";
import BoutonRond from "../../../Design/Boutons/BoutonRond";
import MessageErreur from "../../../Design/Messages/MessageErreur";
import LoaderHorizontal from "../../../Design/Progression/LoaderHorizontal";
import GetAppIcon from "@material-ui/icons/GetApp";
import ObjetPrix from "../../../Design/Objets/ObjetPrix";
import {
  dateToMoisAnnee,
  DateToString,
} from "../../../functions/FonctionsDateHeure";
import ObjetItem from "../../../Design/Objets/ObjetItem";
import DocumentAdpxFacture from "../../../Docs/Adpx/DocumentAdpxFacture";
import PanneauDetailSms from "../Components/PanneauDetailSms/PanneauDetailSms";
import BoutonTexte from "../../../Design/Boutons/BoutonTexte";
import { List } from "@material-ui/core";

export default function PageListeFactures({ match }) {
  //NES gestion du contexte
  const { getfacturationfactures, getfacturationfacturesById } =
    useContext(UtilisateursContext);

  //NES gestion du state
  const [liste, setListe] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [erreur, setErreur] = useState();
  const [error, setError] = useState();
  const [factureAffichee, setFactureAffichee] = useState();
  const [dateDebut, setDateDebut] = useState();
  const [dateFin, setDateFin] = useState();
  const [open, setOpen] = useState(false);

  //USEEFFECT récupération de l'id dans l'url et récupération de la facture
  useEffect(() => {
    if (match.params.id) {
      getFacture(match.params.id);
    }
    // eslint-disable-next-line
  }, [match.params.id]);

  //USEEFFECT
  useEffect(() => {
    getfacturationfactures().then((reponseApi) => {
      setIsLoading(false);
      if (reponseApi.code === "OK") {
        let tempData = reponseApi.data;
        //NES je trie par date
        tempData.sort((a, b) => {
          let dateA = new Date(a.date_heure);
          let dateB = new Date(b.date_heure);
          if (dateA > dateB) {
            return 1;
          } else {
            return -1;
          }
        });
        setListe(tempData);
      } else {
        setErreur("Erreur lors de la récupération des factures");
        setError(reponseApi);
      }
    });
    // eslint-disable-next-line
  }, []);

  ///Récupération de la facture
  const handleDownload = (facture_id) => {
    getFacture(facture_id);
  };

  /// Récupération des informations de la facture
  const getFacture = (facture_id) => {
    getfacturationfacturesById(facture_id).then((reponseApi) => {
      setFactureAffichee(reponseApi.data);
    });
  };

  ///handleOpenDetailSms
  const handleOpenDetailSms = (debut, fin) => {
    setDateDebut(debut);
    setDateFin(fin);
    setOpen(true);
  };

  return (
    <div className={MonStyle.PageListeFactures}>
      <LoaderHorizontal isLoading={isLoading} />
      <MessageErreur message={erreur} error={error} taille="XL" />
      <DocumentAdpxFacture
        facture={factureAffichee}
        onClose={() => setFactureAffichee()}
      />
      <List disablePadding>
        {liste.map((facture) => (
          <div className={MonStyle.Ligne} key={facture._id}>
            <BoutonRond
              iconeComposant={<GetAppIcon />}
              tooltip="Télécharger"
              link={"/facturation/mes-factures/" + facture._id}
              onClick={() => handleDownload(facture._id)}
            />
            <div className={MonStyle.Date}>
              <ObjetItem
                principale={dateToMoisAnnee(facture?.date_facture)}
                secondaire={DateToString(facture?.date_facture, {
                  year: "numeric",
                  month: "numeric",
                  day: "numeric",
                })}
              />
            </div>

            <div>
              <ObjetPrix prix={facture.total_montant_ttc} unite="€" />
            </div>
            <div className={MonStyle.DetailSms}>
              <BoutonTexte
                texte={"détail des sms envoyés"}
                onClick={() =>
                  handleOpenDetailSms(
                    facture.periode_sms_date_debut,
                    facture.periode_sms_date_fin
                  )
                }
              />
            </div>
          </div>
        ))}
      </List>
      <PanneauDetailSms
        open={open}
        onOpen={() => setOpen(true)}
        dateDebut={dateDebut}
        dateFin={dateFin}
        onClose={() => setOpen(false)}
      />
    </div>
  );
}
