import React, { useContext, useEffect, useState } from "react";
import Avatar from "../../../../Design/Avatar";
import { AuthentificationContext } from "../../../../Data/AuthentificationContext";
import socketIOClient from "socket.io-client"; // la bibliotheque cliente de socket io
import BadgeConnected from "../../../../Design/DataDisplay/BadgeConnected";
import { SupportContext } from "../../../../Data/SupportContext";
//import Popover from '@mui/material/Popover';
import MenuUtilisateur from "../MenuUtilisateur";
import { RdvContext } from "../../../../Data/RdvContext";
import Popover from "@mui/material/Popover";

export default function UtilisateurConnecte() {
  //NES Gestion du contexte
  const authContext = useContext(AuthentificationContext);
  const { socketIoReceptionMessages } = useContext(SupportContext);
  const {
    upsertRdvContext,
    deleteRdvContext,
    upsertEvenementContext,
    deleteEvenementContext,
  } = useContext(RdvContext);

  //NES Gestion du state
  const [isConnected, setIsConnected] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  //NES gestion du menu utilisateur quand on clique sur l'avatar
  const avatarClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  ///SOCKET-IO
  let socket;
  const listenSocketIO = () => {
    const urlSocketIO = process.env.REACT_APP_URL_SOCKETIO + "/agendas";
    //NES la room correspond à l'organisation
    //let room = authContext.payload.owned_by_organisation;

    socket = socketIOClient(urlSocketIO, {
      // bloc de connexion du socket avec variables spécifiques pour nous
      withCredentials: false,
      extraHeaders: {
        "socketio_be-header": "echangesocketIOpourBe",
      },
    });

    //NES à la connection au socket on se connecte sur la Room
    socket.on("connect", () => {
      if (socket.id) {
        setIsConnected(true);
      }
      let room = authContext.payload.owned_by_organisation;
      socket.emit("room", room);
    });

    /// nouveau message du support
    socket.on("MsgSupportNonLu", (data) => {
      let dataJson = JSON.parse(data);
      socketIoReceptionMessages(dataJson);
    });

    /// Socket IO quand qqn ajoute ou patch un rdv
    socket.on("UpdatePostRDV", (data) => {
      let dataJson = JSON.parse(data);
      // message que tu recois de l'API de type UpdatePostRDV
      upsertRdvContext(dataJson);
    });

    /// Socket IO quand qqn delete un rdv
    socket.on("DeleteRDV", (data) => {
      let dataJson = JSON.parse(data);
      // message que tu recois de l'API de type UpdatePostRDV
      let monRdv = { _id: dataJson.rdv_id };
      deleteRdvContext(monRdv);
    });

    /// Socket IO quand qqm POST ou PATCH un evenement
    socket.on("UpdatePostEvenement", (data) => {
      let dataJson = JSON.parse(data);
      upsertEvenementContext(dataJson);
    });

    /// Socket IO quand on delete un evenement
    socket.on("DeleteEvenement", (data) => {
      let dataJson = JSON.parse(data);
      let monEvenement = { _id: dataJson.evenement_id };
      deleteEvenementContext(monEvenement);
    });
  };

  //USEEFFECT CONNEXION SOCKET IO
  useEffect(() => {
    if (!isConnected && window.location.hostname !== "localhost") {
      listenSocketIO();
    }

    return () => {
      if (socket) socket.disconnect();
    };

    // eslint-disable-next-line
  }, []);

  return (
    <div>
      <div onClick={avatarClick}>
        <BadgeConnected isConnected={isConnected}>
          <Avatar
            nom={authContext.payload.nom}
            prenom={authContext.payload.prenom}
            taille="bouton"
            id="avatar_utilisateur"
            //NES "?" + authContext.payload.iat me permet de forcer le changemnt d'avatar car c'est la même url en cas de modification
            image={authContext.payload.image_M + "?" + authContext.payload.iat}
          />
        </BadgeConnected>
      </div>
      <Popover
        open={anchorEl ? true : false}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <MenuUtilisateur onClick={handleClose} />
      </Popover>
    </div>
  );
}
