import React, { useState, useContext, useEffect } from "react";
import Popover from "@mui/material/Popover";
import EditionEvenement from "../Evenement/EditionEvenement";
import { AgendasContext } from "../../../../../Data/AgendasContext";
import { getFontColor } from "../../../../../functions/FonctionsCouleurs";

export default function AffichageEvenementTouteLaJournee({
  evenement,
  style,
  agenda_id,
}) {
  //NES Gestion du context
  const { agendaById } = useContext(AgendasContext);

  //NES Gestion du state
  const [agenda, setAgenda] = useState({});

  //NES POPOVER
  const [anchorEl, setAnchorEl] = useState(null);
  const afficherEvenement = (event) => {
    if (!evenement.disabled) {
      setAnchorEl(event.currentTarget);
    }
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  //NES Récupération de l'agenda
  useEffect(() => {
    if (agenda_id) {
      let dataAgenda = agendaById(agenda_id);
      setAgenda(dataAgenda);
    }
    // eslint-disable-next-line
  }, [agenda_id]);

  return (
    <div style={style}>
      <div
        style={{
          width: "100%",
          fontSize: "14px",
          cursor: evenement.disabled ? "" : "pointer",
          padding: "0px 10px ",
          backgroundColor: evenement.couleur
            ? evenement.couleur
            : agenda.couleur_agenda + "50",
          color: getFontColor(evenement.couleur),
        }}
        onClick={afficherEvenement}
      >
        {evenement?.nom_evenement}
      </div>
      <Popover
        open={anchorEl ? true : false}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
      >
        <EditionEvenement
          agenda_id={agenda_id}
          onClose={handleClose}
          evenement={evenement}
        />
      </Popover>
    </div>
  );
}
