import React, { useEffect, useState, useContext } from "react";
import SurfacePapier from "../../../../Design/Surfaces/SurfacePapier";
import MonStyle from "./PageEditionUtilisateur.module.css";
import BoutonRetour from "../../../../Design/Boutons/BoutonRetour";
import InputTexte from "../../../../Design/Formulaires/InputTexte";
import InputNombres from "../../../../Design/Formulaires/InputNombres";
import InputEmail from "../../../../Design/Formulaires/InputEmail";
import InputPassword from "../../../../Design/Formulaires/InputPassword";
import BoutonActionPrincipale from "../../../../Design/Boutons/BoutonActionPrincipale";
import BoutonAnnuler from "../../../../Design/Boutons/BoutonAnnuler";
import MessageErreur from "../../../../Design/Messages/MessageErreur";
import { useHistory } from "react-router-dom";
import { UtilisateursContext } from "../../../../Data/UtilisateursContext";
import SurfaceZoneSecondaire from "../../../../Design/Surfaces/SurfaceZoneSecondaire";

export default function PageEditionUtilisateur({ match }) {
  //NES Gestion de l'id utilisateur
  let id = match.params.id;
  if (id === "nouveau") {
    id = undefined;
  }

  //NES gestion du contexte
  const { patchUtilisateurs, postUtilisateurs, getUtilisateursById } =
    useContext(UtilisateursContext);

  //NES gestion du routage
  const history = useHistory();

  //NES Gestion du state
  const [messageErreur, setMessageErreur] = useState("");
  const [error, setError] = useState();
  const [titre, setTitre] = useState("Nouvel utilisateur");
  const [utilisateur, setUtilisateur] = useState({
    nom: "",
    prenom: "",
    specialite: "",
    numero_am: "",
    numero_rpps: "",
    email: "",
    password: "",
    is_admin: false,
    code_role: "secretaire",
    code_produit: process.env.REACT_APP_NOM,
  });

  const handleChange = (prop) => (event) => {
    setUtilisateur({ ...utilisateur, [prop]: event.target.value });
  };

  //NES Enregistrement de l'utilisateur
  const handleEnregistrer = () => {
    enregistrer().then((reponseJson) => {
      if (reponseJson.code === "OK") {
        //NES redirection vers la page des utilisateurs
        history.push("/options/utilisateurs");
      } else {
        if (reponseJson?.message) {
          setMessageErreur(reponseJson?.message);
        } else {
          setError(reponseJson);
          setMessageErreur("Erreur lors de l'enregistrement de l'utilisateur");
        }
      }
    });
  };

  const enregistrer = async () => {
    if (id) {
      let reponse = await patchUtilisateurs(id, utilisateur);
      return reponse;
    } else {
      let reponse = await postUtilisateurs(utilisateur);
      return reponse;
    }
  };

  useEffect(() => {
    //NES je change le titre de la popup et l'initialise
    setTitre(id ? "Modification de l'utilisateur" : "Nouvel utilisateur");
    if (id) {
      //NES je récupère les données de mon utilisateur
      getUtilisateursById(id).then((reponse) => {
        if (reponse.code === "OK") {
          let tempData = reponse.data;
          //NES Astuce car le mot de passe est hashé je ne peux donc pas le connaitre

          tempData = { ...tempData, password_hash: "••••••••••••" };
          setUtilisateur(tempData);
        } else {
          setMessageErreur(
            "Erreur lors de la récupération des informations de l'utilisateur"
          );
          setError(reponse);
        }
      });
    }
    // eslint-disable-next-line
  }, [id]);

  return (
    <SurfacePapier>
      <div className={MonStyle.PageEditionUtilisateur}>
        <div className="Titre1">{titre}</div>
        <div style={{ margin: "10px 0px" }}>
          <BoutonRetour
            texte="Retour à la liste des utilisateurs"
            link="/options/utilisateurs"
          />
        </div>
        <div className={MonStyle.Wrapper}>
          <div className={MonStyle.Formulaire}>
            <InputTexte
              label="Nom"
              textTransform="uppercase"
              autoFocus={id ? false : true}
              onChange={handleChange("nom")}
              value={utilisateur.nom}
            />
            <InputTexte
              label="Prénom"
              textTransform="capitalize"
              onChange={handleChange("prenom")}
              value={utilisateur.prenom}
            />
            <InputTexte
              label="Spécialité"
              value={utilisateur.specialite}
              onChange={handleChange("specialite")}
            />
            <InputNombres
              label="N° RPPS"
              value={utilisateur.numero_rpps}
              onChange={handleChange("numero_rpps")}
              nbChiffresError="11"
            />
            <InputNombres
              label="N° AM"
              value={utilisateur.numero_am}
              onChange={handleChange("numero_am")}
              nbChiffresError="9"
            />
            <div className="Titre2" style={{ marginTop: "50px" }}>
              Pour se connecter
            </div>
            <InputEmail
              label="Email de connexion"
              value={utilisateur.email}
              onChange={handleChange("email")}
              autoComplete="new-password"
            />
            <InputPassword
              valueHash={utilisateur.password_hash}
              disabled={utilisateur.password_hash ? true : false}
              value={utilisateur.password}
              onChange={handleChange("password")}
              afficherControle
              autoComplete="new-password"
              label="Mot de passe"
            />
            <MessageErreur
              message={messageErreur}
              error={error}
              style={{ marginTop: "40px" }}
            />
            <BoutonActionPrincipale
              texte="Enregistrer"
              taille="L"
              style={{ marginTop: "40px" }}
              onClick={handleEnregistrer}
              disabled={
                !(
                  utilisateur.nom &&
                  utilisateur.prenom &&
                  utilisateur.email &&
                  (utilisateur.password || utilisateur.password_hash)
                )
              }
            />
            <BoutonAnnuler
              taille="L"
              style={{ marginTop: "20px" }}
              link="/options/utilisateurs"
              fullWidth
            />
          </div>

          <div className={MonStyle.Explications}>
            {!id && (
              <SurfaceZoneSecondaire>
                <section>
                  <div className="Titre2">Cet utilisateur est-il facturé?</div>
                  <p>
                    <b>Non.</b> Par défaut chaque nouvel utilisateur est créé
                    avec un profil de secrétaire, ce qui implique qu'il ne vous
                    sera pas facturé.
                  </p>
                  <a
                    href="https://www.google.com"
                    target="_blank"
                    rel="noreferrer"
                  >
                    En savoir plus
                  </a>
                </section>

                <section>
                  <div className="Titre2">Restriction des droits</div>
                  <p>
                    Par contre, les droits de cet utilisateur seront restreints.
                    Il ne sera pas possible d'accéder aux données médicales des
                    patients.
                  </p>
                </section>

                <section>
                  <div className="Titre2">Modifier le profil</div>
                  <p>
                    Vous pouvez changer son profil, si vous souhaitez utiliser
                    toutes les fonctions de {process.env.REACT_APP_NOM}.
                  </p>
                </section>
              </SurfaceZoneSecondaire>
            )}
            {id && (
              <SurfaceZoneSecondaire>
                <div className="Titre2">Mot de passe</div>
                <p>
                  Seul l'utilisateur peut modifier son mot de passe (pour des
                  raisons de sécurité).
                </p>
                <div className="Titre2">
                  Comment modifier mon mot de passe ?
                </div>

                <p>
                  Il peut modifier son mot de passe, en cliquant sur son avatar
                  (tout en haut à droite), puis sur "Mon compte".
                </p>

                <div className="Titre2">Mot de passe oublié ?</div>

                <p>
                  Si il ne se souvient plus de son mot de passe il peut utiliser
                  le bouton "Mot de passe oublié sur la page de connexion" Il
                  recevra un lien par email pour récréer un nouveau mot de
                  passe.
                </p>
              </SurfaceZoneSecondaire>
            )}
          </div>
        </div>
      </div>
    </SurfacePapier>
  );
}
