import React, { useEffect, useState } from "react";
import BlockIcon from "@material-ui/icons/Block";

export default function CapaciteJours({ jour }) {
  const styleDisponible = {
    minWidth: "10px",
    minHeight: "10px",
    border: "1px solid #DDD",
    backgroundColor: "#DDDDDD",
  };

  const styleNonDisponible = {
    minWidth: "10px",
    minHeight: "10px",
    border: "1px solid #213945",
    backgroundColor: "#213945",
    borderRadius: "2px",
  };

  const styleDepassement = {
    minWidth: "10px",
    minHeight: "10px",
    border: "1px solid #FF9800",
    backgroundColor: "#FF9800",
    borderRadius: "2px",
  };

  //NES gestion du state
  const [cases, setCases] = useState([]);

  //USEEFFECT
  useEffect(() => {
    if (jour) {
      //NES le nombre de case est le max entre la capacité et le nombre de demande affectées à ce jour
      let nbCases = 0;
      let capacite = parseInt(jour.capacite);
      let nbDemandes = jour?.demandes?.length;
      let disponible = jour.disponible;

      if (capacite >= nbDemandes) {
        nbCases = capacite;
      } else {
        nbCases = nbDemandes;
      }
      //NES je construis les cases
      let tempArray = new Array(nbCases);

      //NES par défaut toutes les cases sont en dépassement
      tempArray.fill(styleDepassement);
      for (let index = 0; index < capacite; index++) {
        if (index < capacite) {
          tempArray[index] = styleDisponible;
        }
        if (index < capacite - disponible) {
          tempArray[index] = styleNonDisponible;
        }
      }
      setCases(tempArray);
    }
    // eslint-disable-next-line
  }, [jour]);

  if (cases.length === 0) {
    return <BlockIcon />;
  }

  return (
    <div style={{ display: "flex", flexDirection: "row", gap: "5px" }}>
      {cases.map((item, index) => (
        <div style={item} key={index}></div>
      ))}
    </div>
  );
}
