import React, { useContext, useState, useEffect } from "react";
import { HashLink as Link } from "react-router-hash-link";
import { numberToEuro } from "../../../../functions/Formatter";
import { CotationsContext } from "../../../../Data/CotationsContext";
import EuroSymbolIcon from "@material-ui/icons/EuroSymbol";

export default function SyntheseCotations({ consultation_id }) {
  const style = {
    color: "#FF9800",
    backgroundColor: "#FF980010",
    border: "1px solid #FF9800",
    minHeight: "25px",
    borderRadius: "20px",
    padding: "2px 10px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "14px",
    fontWeight: "500",
    minWidth: "40px",
    boxSizing: "border-box",
    gap: "5px",
  };

  //NES gestion du contexte
  const { cotations } = useContext(CotationsContext);

  //NES gestion du state
  const [montantAFacturer, setMontantAFacturer] = useState(0);

  //USEEFFECT
  useEffect(() => {
    let montant = 0;

    cotations.forEach((cotation) => {
      if (cotation.consultation_id === consultation_id) {
        montant = montant + cotation.total_montant_ttc_net;
      }
    });
    setMontantAFacturer(montant);
    // eslint-disable-next-line
  }, [cotations]);

  //NES je n'affiche rien si je n'ai pas de documents
  if (cotations.length === 0 || montantAFacturer === 0) {
    return "";
  }

  return (
    <Link to="#Cotations" smooth style={style}>
      <EuroSymbolIcon />
      {numberToEuro(montantAFacturer, "€") + " à facturer"}
    </Link>
  );
}
