import React, { useState, createContext, useContext } from "react";
import { DELETE, GET } from "../functions/FonctionsApi";
import { AuthentificationContext } from "./AuthentificationContext";

//NES Création du contexte
export const PrestationsContext = createContext();

export const PrestationsProvider = (props) => {
  //NES gestion du contexte
  const authContext = useContext(AuthentificationContext);

  //NES Gestion du state
  const [prestations, setPrestation] = useState([]);

  //GET prestations
  const getPrestations = async () => {
    if (prestations.length > 0) {
      return { code: "OK", data: prestations };
    } else {
      //NES j'appelle l'API
      let MonUrl = `${process.env.REACT_APP_URL_API_FACTURE}/prestations`;
      const reponseJson = await GET(MonUrl, authContext.token);
      //NES Je conserve dans le contexte
      if (reponseJson.code === "OK") {
        setPrestation(reponseJson.data);
      }
      //NES je retourne à ma fonction appelante le résultat
      return reponseJson;
    }
  };

  ///prestationById
  const getPrestationByID = async (prestation_id) => {
    let reponse = await getPrestations();
    if (reponse.code === "OK") {
      let liste = reponse.data;
      let maPrestation = liste.find((item) => item._id === prestation_id);
      return { code: "OK", data: maPrestation };
    } else {
      return reponse;
    }
  };

  //POST prestation
  const postPrestation = async (data) => {
    //NES j'appelle l'API
    let MonUrl = `${process.env.REACT_APP_URL_API_FACTURE}/prestations`;
    //NES pour détecter les appels en boucle
    console.log({ POST: MonUrl });
    const reponseApi = await fetch(MonUrl, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        token: authContext.token,
      },
      body: JSON.stringify(data),
    });
    //NES je convertis ce que je récupère en JSON
    const reponseJson = await reponseApi.json();
    //NES je le garde dans le contexte
    if (reponseJson.code === "OK") {
      let tempArray = [...prestations];
      tempArray.push(reponseJson.data);
      setPrestation(tempArray);
    }
    //NES je retourne à ma fonction appelante le résultat
    return reponseJson;
  };

  //PATCH presation
  const patchPrestation = async (data) => {
    //NES j'appelle l'API
    let MonUrl = `${process.env.REACT_APP_URL_API_FACTURE}/prestations/${data._id}`;
    //NES pour détecter les appels en boucle
    console.log({ PATCH: MonUrl });
    const reponseApi = await fetch(MonUrl, {
      method: "PATCH",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        token: authContext.token,
      },
      body: JSON.stringify(data),
    });
    //NES je convertis ce que je récupère en JSON
    const reponseJson = await reponseApi.json();
    //NES je le garde dans le contexte
    if (reponseJson.code === "OK") {
      let tempArray = [...prestations];
      let index = tempArray.findIndex((item) => item._id === data._id);
      tempArray.splice(index, 1, reponseJson.data);
      setPrestation(tempArray);
    }
    //NES je retourne à ma fonction appelante le résultat
    return reponseJson;
  };

  //DELETE Prestation
  const deletePrestation = async (prestation_id) => {
    //NES j'appelle l'API
    let MonUrl = `${process.env.REACT_APP_URL_API_FACTURE}/prestations/${prestation_id}`;
    const reponseJson = await DELETE(MonUrl, authContext.token);
    //NES Mise à jour du contexte
    if (reponseJson.code === "OK") {
      let tempArray = [...prestations];
      let index = tempArray.findIndex((item) => item._id === prestation_id);
      tempArray.splice(index, 1);
      setPrestation(tempArray);
    }
    //NES je retourne à ma fonction appelante le résultat
    return reponseJson;
  };

  return (
    <PrestationsContext.Provider
      value={{
        prestations,
        getPrestations,
        postPrestation,
        patchPrestation,
        deletePrestation,
        getPrestationByID,
      }}
    >
      {props.children}
    </PrestationsContext.Provider>
  );
};
