import React, { useContext, useEffect, useState } from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import { StylesDocuments } from "../StylesDocumentsPdf";
import { ConseilsContext } from "../../Data/ConseilsContext";
import { DateToString } from "../../functions/FonctionsDateHeure";
import ImageMatin from "../Images/Matin.png";
import ImageMidi from "../Images/Midi.png";
import ImageSoir from "../Images/Soir.png";
import ImageCouche from "../Images/Couche.png";
import ImageCheckbox from "../Images/Checkbox.png";
import ImageCheckboxChecked from "../Images/CheckboxChecked.png";
import DocumentPdf from "../DocumentPdf";
import LoaderGlobal from "../../Design/Progression/LoaderGlobal";
import PopupMessageErreur from "../../Design/Messages/PopupMessageErreur";

export default function DocumentConseil({
  conseil_id,
  open,
  onClose,
  autoPrint,
}) {
  //NES Gestion du style
  const Styles = StyleSheet.create(StylesDocuments);

  //NES gestion du contexte
  const { getConseilById } = useContext(ConseilsContext);

  //NES gestion du state
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [messageErreur, setMessageErreur] = useState();
  const [error, setError] = useState();

  //NES fermeture de l'impression
  const handleClose = () => {
    //NES pour ne pas garder les donnée dans le cache sinon
    setData();
    if (onClose) {
      onClose();
    }
  };

  //USEEFFECT
  useEffect(() => {
    if (open) {
      //NES je vide mes données en cache sinon c'est les données précédentes qui s'affichent
      setIsLoading(true);
      getConseilById(conseil_id)
        .then((reponse) => {
          setIsLoading(false);
          if (reponse.code === "OK") {
            let res = reponse.data;
            setData({
              _id: res._id,
              patient_id: res.patient_id,
              consultation_id: res.consultation_id,
              date: res.date,
              avertissement_conseil: res.avertissement_conseil,
              consultant: res.consultant,
              conseils_seance: res.conseils_seance,
              cabinet: {
                cabinet_id: res?.cabinet.cabinet_id,
                nom: res?.cabinet.nom,
                telephone: res?.cabinet.telephone,
                fax: res?.cabinet.fax,
                email: res?.cabinet.fax,
                adresse: res?.cabinet?.adresse,
                texte_horaire: res?.cabinet.texte_horaire,
                texte_rdv: res?.cabinet.texte_rdv,
                texte_libre: res?.cabinet.texte_libre,
                numero_finess: res?.cabinet.numero_finess,
                informations_juridiques: res?.cabinet?.informations_juridiques,
              },
              lignes_conseils: res.lignes_conseils,
              conseil_prise: res.conseil_prise,
            });
          } else {
            setMessageErreur("Erreur lors de l'impression du conseil");
            if (onClose) {
              onClose();
            }
          }
        })
        .catch((error) => {
          setIsLoading(false);
          setMessageErreur("Erreur lors de l'impression du conseil");
          setError(error);
          if (onClose) {
            onClose();
          }
        });
    }

    // eslint-disable-next-line
  }, [conseil_id, open]);

  const handlePopupMessageErreurClose = () => {
    setMessageErreur();
    handleClose();
  };

  if (messageErreur && open) {
    return (
      <PopupMessageErreur
        messageErreur={messageErreur}
        error={error}
        onClose={handlePopupMessageErreurClose}
      />
    );
  }

  if (data === undefined) {
    return <LoaderGlobal isLoading={isLoading} />;
  }

  return (
    <DocumentPdf
      openDocumentPdf={open}
      onClose={handleClose}
      autoPrint={autoPrint}
    >
      <Document>
        <Page size="A4" style={Styles.page}>
          <View>
            <div style={{ display: "flex", flexDirection: "row" }}>
              {/* BLOC CABINET */}
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  flex: 1,
                  marginRight: "30px",
                }}
              >
                <Text style={Styles.titreBloc}>{data?.cabinet?.nom}</Text>
                <Text>{data?.cabinet?.adresse?.rue}</Text>
                <Text>
                  {data?.cabinet?.adresse?.code_postal +
                    " " +
                    data?.cabinet?.adresse?.ville}
                </Text>
                {data?.cabinet?.telephone?.numero && (
                  <Text>Tél : {data?.cabinet?.telephone?.numero}</Text>
                )}
                {data?.cabinet?.fax?.numero && (
                  <Text>Fax : {data?.cabinet?.fax?.numero}</Text>
                )}
                {data?.cabinet?.email && <Text>{data?.cabinet?.email}</Text>}
                <Text>{data?.cabinet?.texte_horaire}</Text>
                <Text>{data?.cabinet?.texte_rdv}</Text>

                <Text
                  style={[
                    Styles.informationPrincipale,
                    {
                      borderTop: "1px solid #D8D8D8",
                      marginTop: "10px",
                      paddingTop: "10px",
                      textAlign: "center",
                    },
                  ]}
                >
                  {data?.cabinet?.texte_libre}
                </Text>
              </div>

              <div
                style={{
                  flex: 1,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    minHeight: "60px",
                  }}
                >
                  <Text style={Styles.titre}>CONSEILS</Text>
                  <Text>
                    {DateToString(data?.date, {
                      weekday: "long",
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                    })}
                  </Text>
                </div>
                <div
                  style={{
                    backgroundColor: "#EEEEEE",
                    borderRadius: "8px",
                    height: "40px",
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    alignItems: "center",
                    justifyContent: "center",
                    minWidth: "200px",
                  }}
                >
                  <Text style={Styles.titreBloc}>{data.consultant}</Text>
                </div>
              </div>
            </div>
            <Text
              style={{
                backgroundColor: "#205A71",
                color: "white",
                padding: "10px",
                marginTop: "20px",
                textAlign: "center",
              }}
            >
              {data.avertissement_conseil}
            </Text>

            {/* ENTETE DES COLONNES */}
            {data?.lignes_conseils?.length > 0 && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  height: "30px",
                }}
              >
                <Text style={[Styles.titreColonneLeft, { width: "330px" }]}>
                  &nbsp;
                </Text>
                <Text style={[Styles.titreColonne, { width: "40px" }]}>
                  Matin
                </Text>
                <Text style={[Styles.titreColonne, { width: "40px" }]}>
                  Midi
                </Text>
                <Text style={[Styles.titreColonne, { width: "40px" }]}>
                  Soir
                </Text>
                <Text style={[Styles.titreColonne, { width: "40px" }]}>
                  Couché
                </Text>
              </div>
            )}

            {data?.lignes_conseils?.map((ligne, index) => (
              <div
                key={index}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  minHeight: "50px",
                  borderTop: "1px solid #D8D8D8",
                  padding: "5px 0px",
                }}
              >
                <div
                  style={{
                    width: "330px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                  }}
                >
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <Text style={Styles.informationPrincipale}>
                      {ligne.produit.nom}
                    </Text>

                    {ligne?.produit?.conditionnement ? (
                      <Text
                        style={{
                          padding: "0px 5px ",
                          marginLeft: "5px",
                          border: "1px solid black",
                        }}
                      >
                        {ligne?.produit?.conditionnement}
                      </Text>
                    ) : null}
                  </div>

                  <Text style={Styles.informationSecondaire}>
                    {ligne.produit.description}
                  </Text>
                  <Text>{ligne.commentaire}</Text>
                </div>
                <div
                  style={{
                    backgroundColor: "#EEEEEE",
                    height: "40px",
                    width: "40px",
                    borderRadius: "4px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Text
                    style={[
                      Styles.informationPrincipale,
                      {
                        minHeight: "10px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      },
                    ]}
                  >
                    {ligne?.posologie?.matin}
                  </Text>
                  <Image
                    src={ImageMatin}
                    style={{ height: "16px", width: "16px", marginTop: "4px" }}
                  />
                </div>
                <div
                  style={{
                    backgroundColor: "#EEEEEE",
                    height: "40px",
                    width: "40px",
                    borderRadius: "4px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Text
                    style={[
                      Styles.informationPrincipale,
                      {
                        minHeight: "10px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      },
                    ]}
                  >
                    {ligne?.posologie?.midi}
                  </Text>
                  <Image
                    src={ImageMidi}
                    style={{ height: "16px", width: "16px", marginTop: "4px" }}
                  />
                </div>
                <div
                  style={{
                    backgroundColor: "#EEEEEE",
                    height: "40px",
                    width: "40px",
                    borderRadius: "4px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Text
                    style={[
                      Styles.informationPrincipale,
                      {
                        minHeight: "10px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      },
                    ]}
                  >
                    {ligne?.posologie?.soir}
                  </Text>
                  <Image
                    src={ImageSoir}
                    style={{ height: "16px", width: "16px", marginTop: "4px" }}
                  />
                </div>
                <div
                  style={{
                    backgroundColor: "#EEEEEE",
                    height: "40px",
                    width: "40px",
                    borderRadius: "4px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Text
                    style={[
                      Styles.informationPrincipale,
                      {
                        minHeight: "10px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      },
                    ]}
                  >
                    {ligne?.posologie?.coucher}
                  </Text>
                  <Image
                    src={ImageCouche}
                    style={{ height: "16px", width: "16px", marginTop: "4px" }}
                  />
                </div>
              </div>
            ))}

            {/* AFFICHAGE CONSEIL PRISE */}
            {data?.lignes_conseils?.length > 0 && (
              <div
                style={{
                  backgroundColor: "#EEEEEE",
                  borderRadius: "4px",
                  height: "35px",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-around",
                  marginTop: "20px",
                }}
              >
                <Text style={Styles.informationPrincipale}>
                  Minimum {data?.conseil_prise?.duree} avant les repas avec de
                  l'eau:
                </Text>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyItems: "center",
                  }}
                >
                  <Image
                    src={
                      data?.conseil_prise?.temperature === "FRAICHE"
                        ? ImageCheckboxChecked
                        : ImageCheckbox
                    }
                    style={{ height: "24px", width: "24px" }}
                  />
                  <Text style={Styles.informationSecondaire}>Fraiche </Text>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyItems: "center",
                  }}
                >
                  <Image
                    src={
                      data?.conseil_prise?.temperature === "TIEDE"
                        ? ImageCheckboxChecked
                        : ImageCheckbox
                    }
                    style={{ height: "24px", width: "24px" }}
                  />
                  <Text style={Styles.informationSecondaire}>Tiède </Text>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyItems: "center",
                  }}
                >
                  <Image
                    src={
                      data?.conseil_prise?.temperature === "CHAUDE"
                        ? ImageCheckboxChecked
                        : ImageCheckbox
                    }
                    style={{ height: "24px", width: "24px" }}
                  />
                  <Text style={Styles.informationSecondaire}>Chaude </Text>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyItems: "center",
                  }}
                >
                  <Image
                    src={
                      data?.conseil_prise?.temperature === "TEMPEREE"
                        ? ImageCheckboxChecked
                        : ImageCheckbox
                    }
                    style={{ height: "24px", width: "24px" }}
                  />
                  <Text style={Styles.informationSecondaire}>Tempérée </Text>
                </div>
              </div>
            )}

            <div style={{ marginTop: "20px" }}>
              <Text style={Styles.informationPrincipale}>Conseils :</Text>
              <Text style={Styles.informationSecondaire}>
                {data.conseils_seance}
              </Text>
            </div>

            <div
              style={{
                border: "1px solid noir",
                padding: "10px 20px",
                marginTop: "20px",
              }}
            >
              <Text
                style={[Styles.informationPrincipale, { textAlign: "center" }]}
              >
                LES SEANCES D'ENERGETIQUE CHINOISE NE REMPLACENT NI LA VISITE
                CHEZ VOTRE MEDECIN NI LES MEDICAMENTS QU'IL VOUS PRESCRIT
              </Text>
            </div>
          </View>

          {/* PIED DE PAGE */}

          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              alignItems: "center",
              fontSize: "8px",
              position: "absolute",
              bottom: "20mm",
              borderTop: "1px solid #EEEEEE",
              left: "10mm",
              padding: "0px",
              paddingTop: "5px",
              border: "0px solid blue",
            }}
          >
            <Text>
              Conformément à l'article 27 de la loi "Informatique et Libertés"
              du 6 janvier 1978, les champs d'information sont nécessaires à
              l'enregistrement et au traitement de vos demandes. Nous ne les
              transmettrons pas à des tiers. Conformément aux dispositions de la
              loi n°78-17 du 6 janvier 1978 relative à l’informatique, aux
              fichiers et aux libertés, vous disposez d’un droit d’accès, de
              modification, de rectification et de suppression des données qui
              vous concernent.
            </Text>
          </div>

          <View
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              fontSize: "8px",
              position: "absolute",
              bottom: "10mm",
              borderTop: "1px solid black",
              left: "10mm",
              paddingTop: "5px",
            }}
            fixed
          >
            <View
              style={{
                width: "80%",
                display: "flex",
                flexWrap: "wrap",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
                fontSize: "8px",
                border: "0px solid red",
              }}
              fixed
            >
              <Text>
                {data?.cabinet?.informations_juridiques?.nom_entite_juridique +
                  ", " +
                  data?.cabinet?.informations_juridiques?.adresse_siege}
              </Text>
              <Text>
                {" - Capital : " +
                  data?.cabinet?.informations_juridiques?.capital_social}
              </Text>
              <Text>
                {" - N° SIRET : " +
                  data?.cabinet?.informations_juridiques?.numero_siret}
              </Text>

              {data?.cabinet?.informations_juridiques?.numero_tva && (
                <Text>
                  {" - N° TVA intra. : " +
                    data?.cabinet?.informations_juridiques?.numero_tva}
                </Text>
              )}
            </View>
            <Text
              style={{
                fontSize: "8px",
                fontWeight: "bold",
              }}
              render={({ pageNumber, totalPages }) =>
                `Page ${pageNumber} / ${totalPages}`
              }
              fixed
            />
          </View>
        </Page>
      </Document>
    </DocumentPdf>
  );
}
