import React, { useContext, useEffect, useState } from "react";
import MonStyle from "./AffichageAlerteCarteBancaire.module.css";
import BoutonActionSecondaire from "../../../Design/Boutons/BoutonActionSecondaire";
import SurfacePapier from "../../../Design/Surfaces/SurfacePapier";
import IllustrationDonneeManquante from "../../../Images/Illustrations/ReglementCarteBancaire.png";
import { UtilisateursContext } from "../../../Data/UtilisateursContext";
import { isMobile } from "../../../functions/Navigateur";
import { AuthentificationContext } from "../../../Data/AuthentificationContext";

export default function AffichageAlerteCarteBancaire() {
  //NES Gestion du state
  const [titre, setTitre] = useState("");
  const [message, setMessage] = useState("");
  const [afficher, setAfficher] = useState(false);
  const [texteBouton, setTexteBouton] = useState("");

  //NES gestion du contexte
  const { monOrganisation } = useContext(UtilisateursContext);
  const auth = useContext(AuthentificationContext);

  //USEEFFECT
  useEffect(() => {
    if (
      monOrganisation?.stripe &&
      monOrganisation?.facturation?.id_derniere_facture
    ) {
      //NES vérification qu'il y a bien une carte bancaire
      if (!monOrganisation?.stripe?.payment_method_id) {
        setTitre("Aucune carte bancaire renseignée!");
        setMessage(
          "Nous n'avons pas trouvé de carte bancaire de paiement renseigné. Vous devez renseigner une carte bancaire de règlement valide pour pouvoir continuer à utiliser  " +
            process.env.REACT_APP_NOM
        );
        setTexteBouton("Renseigner ma carte bancaire");
        setAfficher(true);
      }

      //NES date d'expiration de la carte bancaire
      let aujourdhui = new Date();
      let moisCourant = aujourdhui.getMonth();
      let anneeCourante = aujourdhui.getFullYear();
      if (
        monOrganisation?.stripe?.exp_month === moisCourant &&
        monOrganisation?.stripe?.exp_year === anneeCourante
      ) {
        setTitre("Votre carte bancaire va expirer ! ");
        setMessage(
          "Attention, votre carte bancaire va expirer. Mettez à jour votre carte bancaire de paiement !"
        );
        setTexteBouton("Mettre à jour ma carte bancaire");
        setAfficher(true);
      }
    }
  }, [monOrganisation]);

  if (!afficher) {
    return "";
  }

  return (
    <SurfacePapier>
      <div className={MonStyle.AffichageAlerteCarteBancaire}>
        <img src={IllustrationDonneeManquante} className="HideMobile" alt="" />
        <div className={MonStyle.Message}>
          <h4>Carte bancaire</h4>
          <h3>{titre}</h3>
          <p>{message}</p>

          <BoutonActionSecondaire
            texte={texteBouton}
            style={{ margin: "20px 0px" }}
            fullWidth={isMobile()}
            link="/facturation/carte-bancaire"
            disabled={!auth.payload.is_admin}
          />
        </div>
      </div>
    </SurfacePapier>
  );
}
