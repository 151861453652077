import React, { useEffect, useState, useContext } from "react";
import { CotationsContext } from "../../../../Data/CotationsContext";
import BadgeNombre from "../../../../Design/DataDisplay/BadgeNombre";
import { HashLink as Link } from "react-router-hash-link";
import EuroSymbolIcon from "@material-ui/icons/EuroSymbol";
import { numberToEuro } from "../../../../functions/Formatter";
import CheckIcon from "@material-ui/icons/Check";

export default function SyntheseFacturation() {
  //NES constantes
  const styleNonRegle = {
    color: "#FF9800",
    backgroundColor: "#FF980010",
    border: "1px solid #FF9800",
    minHeight: "25px",
    borderRadius: "20px",
    padding: "2px 10px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "14px",
    fontWeight: "500",
    minWidth: "40px",
    boxSizing: "border-box",
    gap: "5px",
  };

  const styleRegle = {
    color: "white",
    backgroundColor: "#0FA600",
    minHeight: "25px",
    borderRadius: "20px",
    padding: "2px 10px 2px 10px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "14px",
    fontWeight: "500",
    minWidth: "40px",
    boxSizing: "border-box",
    gap: "5px",
  };

  //NES Gestion du state
  const [libelleFacturation, setLibelleFacturation] = useState("");
  const [style, setStyle] = useState(styleNonRegle);
  const [isPaye, setIsPaye] = useState(false);
  const [listeFactures, setListeFactures] = useState([]);

  //NES gestion du contexte
  const { factures } = useContext(CotationsContext);

  //USEEFFECT gestion du montant à payer
  useEffect(() => {
    let listeFacturesARegler = [...factures];
    listeFacturesARegler = listeFacturesARegler.filter(
      (item) => item.is_avoir !== true && item.avoir_facture === undefined
    );

    setListeFactures(listeFacturesARegler);

    let montantAPayer = 0;
    listeFacturesARegler.forEach((facture) => {
      montantAPayer =
        montantAPayer +
        facture?.part_patient.montant_a_regler -
        facture?.part_patient.montant_regle;
    });

    if (montantAPayer === 0) {
      setIsPaye(true);
      setLibelleFacturation(
        factures.length > 1 ? "Factures payées" : "Facture payée"
      );
      setStyle(styleRegle);
    } else {
      setIsPaye(false);

      setLibelleFacturation(numberToEuro(montantAPayer, "€") + " à régler");
      setStyle(styleNonRegle);
    }
    // eslint-disable-next-line
  }, [factures]);

  //NES je n'affiche rien si je n'ai pas de factures
  if (listeFactures.length === 0) {
    return "";
  }

  return (
    <BadgeNombre
      badgeContent={factures.length}
      invisible={listeFactures.length <= 1}
    >
      <Link to="#factures" smooth style={style}>
        {isPaye ? <CheckIcon /> : <EuroSymbolIcon />}
        {libelleFacturation}
      </Link>
    </BadgeNombre>
  );
}
