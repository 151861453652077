import React, { useState } from "react";
import { useContext } from "react";
import { useEffect } from "react";
import ObjetSms from "../../../../Design/Objets/ObjetSms";
import { MessagesContext } from "../../../../Data/MessagesContext";
import MessageWarning from "../../../../Design/Messages/MessageWarning";
import InputTexte from "../../../../Design/Formulaires/InputTexte";

export default function SimulationSms({
  titre,
  value,
  onChange,
  agenda,
  style,
  modele,
}) {
  const AUJOURDHUI = new Date();

  //NES gestion du contexte
  const { construireMessageSmsRdv } = useContext(MessagesContext);

  //NES gestion du state
  const [sms, setSms] = useState({ message: "hello" });
  const [message, setMessage] = useState("");
  const [nbCaracteres, setNbCaracteres] = useState(0);

  //USEEFFECT
  useEffect(() => {
    //let messageSms = construireMessageSmsRdv(rdvTest, "CONFIRMATION", agenda);
    let rdvTest = {
      date_heure: AUJOURDHUI,
    };
    let type = "CONFIRMATION";

    if (modele !== "CONFIRMATION") {
      type = "ANNULATION";
      rdvTest = { ...rdvTest, annulation_rdv: { mode: modele } };
    }

    let messageSms = construireMessageSmsRdv(rdvTest, type, agenda);

    let tailleMessage = messageSms.length;
    setNbCaracteres(tailleMessage);
    if (tailleMessage >= 160) {
      setMessage(
        "Le texte personnalisé est trop long, vous allez envoyer 2 sms."
      );
    } else {
      setMessage("");
    }

    setSms({ ...sms, message: messageSms });
    // eslint-disable-next-line
  }, [agenda]);

  return (
    <div style={style}>
      <h4 style={{ textTransform: "upperCase" }}>{titre}</h4>

      <ObjetSms sms={sms} />

      <InputTexte
        value={value}
        onChange={onChange}
        label={"Texte personnalisé (+/-" + nbCaracteres + " / 160 car.)"}
      />

      <MessageWarning message={message} style={{ margin: "20px 0px" }} />
    </div>
  );
}
