import React from "react";
import MonStyle from "./EnteteColonne.module.css";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
export default function EnteteColonne({
  titre,
  tri,
  className,
  style,
  onClick,
}) {
  const afficherTri = () => {
    if (tri === "az") {
      return <ArrowDownwardIcon fontSize="small" />;
    }
    if (tri === "za") {
      return <ArrowUpwardIcon fontSize="small" />;
    }
  };

  return (
    <div className={className} style={style} onClick={onClick}>
      <div className={MonStyle.EnteteColonne}>
        <div>{titre}</div>
        <div style={{ marginLeft: "10px" }}>{afficherTri()}</div>
      </div>
    </div>
  );
}
