import React, { useState, useContext, useEffect } from "react";
import MonStyle from "./PageAllAgendas.module.css";
import SurfacePapier from "../../../Design/Surfaces/SurfacePapier";
import ColonneHeures from "./components/ColonneHeures";
import { AgendasContext } from "../../../Data/AgendasContext";
import ColonneJour from "./components/ColonneJour";
import LoaderHorizontal from "../../../Design/Progression/LoaderHorizontal";
//import { RdvContext } from "../../../Data/RdvContext";
import MessageErreur from "../../../Design/Messages/MessageErreur";
import AfficherNomAgenda from "./components/AfficherNomAgenda";
import LigneJours from "./components/LigneJours";

export default function PageAllAgendas() {
  //NES Gestion du contexte
  const { agendas, datesSemaine, heureFinAgendas, heureDebutAgendas } =
    useContext(AgendasContext);
  //const { getRdv } = useContext(RdvContext);

  //NES gestion du state
  const [joursAffiches, setJoursAffiches] = useState([]);
  const [loading, setLoading] = useState(false);
  //const [erreur, setErreur] = useState("");
  const erreur = "";

  useEffect(() => {
    console.log({ joursAffiches });
  }, [joursAffiches]);

  useEffect(() => {
    console.log({ datesSemaine });
    //NES récupération des jours travailles pour gagner une journée d'affichage
    let listeJoursTravailles = [];
    datesSemaine.forEach((date) => {
      let numeroJour = date.getDay();
      agendas.forEach((agenda) => {
        if (agenda.jours_affiches.includes(numeroJour)) {
          if (!listeJoursTravailles.includes(date)) {
            listeJoursTravailles.push(date);
          }
        }
      });
    });
    setJoursAffiches(listeJoursTravailles);
    // eslint-disable-next-line
  }, [datesSemaine, agendas]);

  /*
  useEffect(() => {
    console.log({ datesSemaine });
    getRdv().then((reponse) => {
      console.log(reponse);
    });
    // eslint-disable-next-line
  }, [datesSemaine]);
  */

  return (
    <div className={MonStyle.PageAllAgendas}>
      <SurfacePapier>
        <div style={{ height: "10px" }} onClick={() => setLoading(!loading)}>
          <LoaderHorizontal isLoading={loading} />
        </div>
        <MessageErreur
          message={erreur}
          style={{ margin: "20px 0px 40px 0px" }}
        />
        <div className={MonStyle.Agenda}>
          <div className={MonStyle.ColonneHeures}>
            <ColonneHeures
              heureDebutAgenda={heureDebutAgendas}
              heureFinAgenda={heureFinAgendas}
              paddingTop="110px"
            />
          </div>

          <div className={MonStyle.EnteteEtListesJours}>
            <div className={MonStyle.EnteteAgenda}>
              <LigneJours joursAffiches={joursAffiches} />
            </div>
            <div className={MonStyle.ListesJours}>
              {joursAffiches.map((jour) => (
                <div className={MonStyle.Jour} key={jour}>
                  {agendas.map((agenda) => (
                    <div
                      className={MonStyle.JourParAgenda}
                      key={jour + agenda._id}
                    >
                      <div className={MonStyle.NomAgenda}>
                        <AfficherNomAgenda agenda={agenda} />
                      </div>
                      <ColonneJour date={jour} agenda={agenda} />
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </div>
        </div>
      </SurfacePapier>
    </div>
  );
}
