import React, { useState, useEffect } from "react";
import BoutonAjoutBloc from "../../../../Design/Boutons/BoutonAjoutBloc";
import BoutonRond from "../../../../Design/Boutons/BoutonRond";
import InputCheckbox from "../../../../Design/Formulaires/InputCheckbox";
import InputTexte from "../../../../Design/Formulaires/InputTexte";
import MonStyle from "./EditionHuitRegles.module.css";
import CloseIcon from "@material-ui/icons/Close";

function HuitRegles({ huitRegles, onClose, onChange }) {
  //NES Gestion du state
  const [data, setData] = useState({ organe: "", regles: [] });

  useEffect(() => {
    if (huitRegles) {
      setData(huitRegles);
    }
  }, [huitRegles]);

  const handleTexteChange = (key, value) => {
    setData({ ...data, [key]: value });
  };

  const handleRegleChange = (liste) => {
    let newData = { ...data, regles: liste };
    setData(newData);
    if (onChange) {
      onChange(newData);
    }
  };

  const handleTexteBlur = () => {
    if (onChange) {
      onChange(data);
    }
  };

  return (
    <div className={MonStyle.HuitRegles}>
      <div className={MonStyle.OrganeEtBouton}>
        <InputTexte
          theme="lecture"
          placeholder="organe"
          textAlign="center"
          value={data.organe}
          onChange={(e) => handleTexteChange("organe", e.target.value)}
          onBlur={handleTexteBlur}
        />
        <BoutonRond
          iconeComposant={<CloseIcon />}
          tooltip="Supprimer"
          onClick={onClose}
        />
      </div>
      <div className={MonStyle.Regles}>
        <InputCheckbox
          column={2}
          onChecked={handleRegleChange}
          valuesChecked={huitRegles.regles}
          values={[
            { libelle: "Yin", code: "YIN" },
            { libelle: "Yang", code: "YANG" },
            { libelle: "Li", code: "LI" },
            { libelle: "Biao", code: "BIAO" },
            { libelle: "Froid", code: "FROID" },
            { libelle: "Chaleur", code: "CHALEUR" },
            { libelle: "Vide", code: "VIDE" },
            { libelle: "Plénitude", code: "PLENITUDE" },
          ]}
        />
      </div>
    </div>
  );
}

export default function EditionHuitRegles({ onChange, value }) {
  //NES gestion du state
  const [regles, setRegles] = useState([]);

  useEffect(() => {
    if (value) {
      console.log(value);
      setRegles(value);
    }
  }, [value]);

  const handleChange = (regle, index) => {
    let tempArray = [...regles];
    tempArray.splice(index, 1, regle);
    setRegles(tempArray);
    if (onChange) {
      onChange(tempArray);
    }
  };

  const handleAjouter = () => {
    let newRegle = {
      _id: new Date(),
      organe: "",
      regles: [],
    };
    let tempArray = [...regles, newRegle];
    setRegles(tempArray);
    if (onChange) {
      onChange(tempArray);
    }
  };

  const handleSupprimer = (index) => {
    if (index >= 0) {
      let tempArray = [...regles];
      tempArray.splice(index, 1);
      setRegles(tempArray);
      if (onChange) {
        onChange(tempArray);
      }
    }
  };

  return (
    <div className={MonStyle.EditionHuitRegles}>
      {regles.map((huitRegles, index) => (
        <HuitRegles
          onClose={() => handleSupprimer(index)}
          key={huitRegles._id}
          huitRegles={huitRegles}
          onChange={(regles) => handleChange(regles, index)}
        />
      ))}
      <BoutonAjoutBloc texte="Ajouter" onClick={handleAjouter} taille="L" />
    </div>
  );
}
