import React from "react";
import ObjetItem from "../../../../Design/Objets/ObjetItem";
import {
  DateToString,
  DateToHeure,
} from "../../../../functions/FonctionsDateHeure";
import Collapse from "@material-ui/core/Collapse";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import SmsIcon from "@material-ui/icons/Sms";
import BoutonRond from "../../../../Design/Boutons/BoutonRond";
import { useState } from "react";

export default function LigneSmsEnvoye({ data }) {
  //NES gestion du state
  const [afficher, setAfficher] = useState(false);

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          gap: "10px",
          alignItems: "center",
        }}
      >
        <SmsIcon style={{ color: "#205A71" }} />
        <ObjetItem
          style={{
            flex: 1,
          }}
          principale={data?.numero_telephone}
          secondaire={
            data?.patient_destinataire?.nom?.toUpperCase() +
            " " +
            data?.patient_destinataire?.prenom
          }
        />

        <ObjetItem
          principale={DateToString(data?.date_envoi)}
          secondaire={DateToHeure(data?.date_envoi)}
          style={{
            alignItems: "flex-end",
            width: "fit-content",
          }}
        />
        <BoutonRond
          iconeComposant={afficher ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          couleurIcone="noir"
          onClick={() => setAfficher(!afficher)}
          tooltip="Afficher le message"
        />
      </div>
      <Collapse
        in={afficher} //style={{ maxWidth: "100%" }}
      >
        <div
          style={{
            maxWidth: "100%",
            boxSizing: "border-box",
            padding: "10px 20px",
            margin: "10px",
            backgroundColor: "#DDDDDD50",
            borderRadius: "10px",
          }}
        >
          {data.message}
        </div>
      </Collapse>
    </div>
  );
}
