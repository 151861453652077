import React from "react";
import { useContext, useState } from "react";
import { PerformancesContext } from "../../../../Data/PerformancesContext";
import SurfacePanneau from "../../../../Design/Surfaces/SurfacePanneau";
import ObjetAppelApi from "./ObjetAppelApi";
import download from "downloadjs";
import BoutonActionPrincipale from "../../../../Design/Boutons/BoutonActionPrincipale";
import { pluriel } from "../../../../functions/Formatter";
import { AuthentificationContext } from "../../../../Data/AuthentificationContext";
import SpeedIcon from "@mui/icons-material/Speed";
export default function IndicateurPerformance(params) {
  //NES gestion du contexte
  const { moyenne, appelsApi } = useContext(PerformancesContext);
  const auth = useContext(AuthentificationContext);

  //NES gestion du state
  const [open, setOpen] = useState(false);

  ///handleOpen
  const handleOpen = () => {
    setOpen(true);
  };

  ///handleClose
  const handleClose = () => {
    setOpen(false);
  };

  const handleTelecharger = () => {
    let aujourdhui = new Date();
    let mois = aujourdhui.toLocaleDateString("fr-FR", { month: "long" });
    let jour = aujourdhui.toLocaleDateString("fr-FR", { day: "numeric" });
    let nomJour = aujourdhui.toLocaleDateString("fr-FR", { weekday: "long" });
    let heure = aujourdhui.toLocaleTimeString("fr-FR", {
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
    });

    let dateFichier = nomJour + jour + mois + " - " + heure;

    let nomFichier = "Performances - " + dateFichier + ".json";
    console.log(nomFichier);

    let data = JSON.stringify(appelsApi);
    download(data, nomFichier, "application/json");
  };

  if (auth.payload.code_role !== "PARTENAIRE_ADPX") {
    return "";
  }

  return (
    <div>
      <div
        onClick={handleOpen}
        style={{
          color: "white",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          border: "1px solid white",
          minHeight: "40px",
          borderRadius: "20px",
          paddingRight: "20px",
          backgroundColor: "#FFFFFF10",
          cursor: "pointer",
        }}
      >
        <SpeedIcon className="IconeObjet" /> {Math.round(moyenne)}ms
      </div>
      <SurfacePanneau
        open={open}
        onClose={handleClose}
        titre={"Suivi des performances  "}
        sousTitre={
          pluriel(appelsApi.length, "Appel API", "Appels API") +
          " - Moyenne : " +
          Math.round(moyenne) +
          "ms"
        }
      >
        <div
          style={{
            border: "0px solid red",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginBottom: "30px",
            gap: "15px",
          }}
        >
          <BoutonActionPrincipale
            onClick={handleTelecharger}
            texte="Télécharger le fichier pour Nicolas et Jérémy"
          />
          <div>
            Il faut que tu télécharges le fichier et que tu nous l'envoies à :{" "}
            <br />{" "}
            <a href="mailto:latelierdupixel@gmail.com">
              latelierdupixel@gmail.com
            </a>
          </div>
          Merci, Nicolas
        </div>

        {appelsApi.map((appel, index) => (
          <ObjetAppelApi key={index} appel={appel} />
        ))}
      </SurfacePanneau>
    </div>
  );
}
