import React, { useState, useContext } from "react";
import MonStyle from "./Inscription.module.css";
import Etapes from "../../../Design/Navigation/Etapes";
import AffichageConditionnel from "../../../Design/DataDisplay/AffichageConditionnel";
import InputTexte from "../../../Design/Formulaires/InputTexte";
import { POST } from "../../../functions/FonctionsApi";
import InputEmail from "../../../Design/Formulaires/InputEmail";
import InputPassword from "../../../Design/Formulaires/InputPassword";
import MessageAide from "../../../Design/Messages/MessageAide";
import BoutonActionPrincipale from "../../../Design/Boutons/BoutonActionPrincipale";
import Adresse from "../../../Components/Adresse";
import BoutonRetour from "../../../Design/Boutons/BoutonRetour";
import VerificationCompte from "./VerificationCompte";
import BoutonAnnuler from "../../../Design/Boutons/BoutonAnnuler";
import MessageErreur from "../../../Design/Messages/MessageErreur";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import IllustrationEnvoye from "../../../Images/Illustrations/Envoye.png";
import { AuthentificationContext } from "../../../Data/AuthentificationContext";
import InputConditionGenerales from "../../Cgv/Components/InputConditionGenerales";
import queryString from "query-string";

export default function Inscription({ isParrainage, onClose }) {
  //NES gestion du context
  const auth = useContext(AuthentificationContext);

  //NES gestion du state
  const [etape, setEtape] = useState(0);
  const [utilisateur, setUtilisateur] = useState({
    nom: "",
    prenom: "",
    email: "",
    password: "",
    code_role: "MEDECIN",
  });
  const [cabinet, setCabinet] = useState({ nom: "", adresse: {} });
  const [cgv, setCgv] = useState(false);
  const [messageErreur, setMessageErreur] = useState("");
  const [verificationCompte, setVerificationCompte] = useState({});
  const [error, setError] = useState();
  const [erreurEmail, setErreurEmail] = useState();

  //NES Constantes
  let ETAPES = ["Votre cabinet", "Vos informations", "Connexion"];
  if (isParrainage) {
    ETAPES = ["Cabinet", "Praticien", "Email de vérification"];
  }

  ///Change utilisateur
  const handleChangeUtilisateur = (key, value) => {
    let newData = { ...utilisateur, [key]: value };
    setUtilisateur(newData);

    if (key === "email") {
      setErreurEmail("");
    }
  };

  ///Change cabinet
  const handleChangeCabinet = (key, value) => {
    let newData = { ...cabinet, [key]: value };
    setCabinet(newData);
  };

  ///Controle de l'email valide
  const handleBlurEmail = (erreur) => {
    setErreurEmail(erreur);
  };

  ///Condition générales
  const handleChangeCGV = (e) => {
    setCgv(e);
  };

  ///Quand on a tout renseigné
  const handleInscription = (token) => {
    //NES modification et complétion de mon utilisateur dans le cas du parrainage
    let dataUtilisateur = utilisateur;
    if (isParrainage) {
      dataUtilisateur = {
        ...dataUtilisateur,
        password: "ADPX",
        is_demander_nouveau_password: true,
      };
    }

    //NES gestion du code role par défault
    let codeRole = "MEDECIN";
    if (process.env.REACT_APP_NOM === "SINOBI") {
      codeRole = "PRATICIEN";
    }
    dataUtilisateur = {
      ...dataUtilisateur,
      code_role: codeRole,
    };

    //NES construction des data à envoyer
    let data = {
      utilisateur: dataUtilisateur,

      organisation: {
        nom: `Organisation de ${utilisateur.nom} ${utilisateur.prenom}`,
        email: dataUtilisateur.email,
        conditions_generales: {
          acceptation: cgv,
        },
        code_produit: process.env.REACT_APP_NOM,
      },
      cabinet: cabinet,
    };
    console.log(data);

    //POST /inscription
    //Est-ce que j'ai un origine pour l'inscription ?
    let url = window.location.search;
    let params = queryString.parse(url);
    let param_owned_by_organisation = "";

    switch (params.origine) {
      case "wilbois":
        param_owned_by_organisation =
          "?owned_by_organisation=61b6101dc5dc9455a8e8e7da";
        break;
      default:
        param_owned_by_organisation = "";
        break;
    }

    //NES idem est ce que je suis dans le cas de la page de parrainage de Chrys
    if (isParrainage) {
      param_owned_by_organisation =
        "?owned_by_organisation=61b6101dc5dc9455a8e8e7da";
    }

    const MonUrl =
      `${process.env.REACT_APP_URL_API_UTILISATEURS}/inscription` +
      param_owned_by_organisation;

    POST(MonUrl, auth?.token, data).then((reponseApi) => {
      if (reponseApi.code === "OK") {
        setVerificationCompte(reponseApi.verification_compte);
        setMessageErreur("");
        if (isParrainage) {
          //NES affichage du fait que l'on a envoyé un email
          setEtape(3);
        } else {
          //NES saisie du code recu par email
          setEtape(2);
        }
      } else {
        if (reponseApi?.erreur?.code === "email_deja_existant") {
          setErreurEmail("Cette adresse email est déjà utilisé.");
        } else {
          setMessageErreur("Erreur lors de l'inscription");
          setError(reponseApi);
        }
      }
    });

    //NES j'affiche que c'est terminé
    //setEtape(2);
  };

  return (
    <div className={MonStyle.Inscription}>
      <Etapes
        etapeActive={etape}
        etapes={ETAPES}
        style={{
          marginTop: "0px",
          width: "100%",
        }}
      />
      {/*********  CABINET ****************¨*/}

      <AffichageConditionnel open={etape === 0}>
        <div className={MonStyle.Formulaire}>
          <section className={MonStyle.MessageFinal}>
            <h3>Cabinet</h3>
            <InputTexte
              autoFocus
              label="Nom du cabinet (obligatoire)"
              placeholder="Saisir le nom du cabinet (obligatoire)"
              value={cabinet?.nom}
              onChange={(e) => handleChangeCabinet("nom", e.target.value)}
            />
          </section>
          <section>
            <h3>Adresse</h3>
            <Adresse
              value={cabinet.adresse}
              onChange={(adresse) => handleChangeCabinet("adresse", adresse)}
            />
          </section>
          <BoutonActionPrincipale
            texte="Etape suivante"
            couleur="vert"
            fullWidth
            taille="L"
            disabled={cabinet.nom ? false : true}
            onClick={() => setEtape(1)}
            endIcon={<ArrowForwardIosIcon />}
          />
          <BoutonAnnuler
            taille="L"
            link="/"
            fullWidth
            style={{ margin: "20px 0px" }}
          />
        </div>
      </AffichageConditionnel>

      {/********* VOS INFORMATIONS ****************¨*/}
      <AffichageConditionnel open={etape === 1}>
        <div className={MonStyle.Formulaire}>
          <BoutonRetour
            style={{ marginBottom: "20px" }}
            texte="Retour"
            onClick={() => setEtape(0)}
          />
          <section>
            <h3>
              {isParrainage ? "A propos du praticien" : "A propos de vous"}
            </h3>
            <InputTexte
              autoFocus
              label="Nom (obligatoire)"
              placeholder={
                isParrainage
                  ? "Saisir le nom du praticien (obligatoire)"
                  : "Saisir votre nom (obligatoire)"
              }
              textTransform="uppercase"
              value={utilisateur?.nom}
              onChange={(e) => handleChangeUtilisateur("nom", e.target.value)}
            />
            <InputTexte
              label="Prénom (obligatoire)"
              placeholder={
                isParrainage
                  ? "Saisir le prénom du praticien (obligatoire)"
                  : "Saisir votre prénom (obligatoire)"
              }
              textTransform="capitalize"
              value={utilisateur?.prenom}
              onChange={(e) =>
                handleChangeUtilisateur("prenom", e.target.value)
              }
            />
          </section>
          <section>
            <h3>
              {isParrainage ? "Email du praticien" : "Pour vous connecter"}
            </h3>
            <InputEmail
              label="Email de connexion"
              value={utilisateur?.email}
              onChange={(e) => handleChangeUtilisateur("email", e.target.value)}
              autoComplete="new-password"
              onErreur={handleBlurEmail}
            />
            {!isParrainage && (
              <InputPassword
                afficherControle
                label="Mot de passe"
                value={utilisateur?.password}
                autoComplete="new-password"
                onChange={(e) =>
                  handleChangeUtilisateur("password", e.target.value)
                }
              />
            )}
          </section>
          {!isParrainage && (
            <InputConditionGenerales
              value={cgv}
              onChange={(value) => handleChangeCGV(value)}
              style={{ marginBottom: "20px" }}
            />
          )}{" "}
          <MessageErreur message={messageErreur} error={error} />
          <MessageAide message={erreurEmail} severity="error" disableClose />
          <BoutonActionPrincipale
            texte="Inscription"
            fullWidth
            taille="L"
            onClick={handleInscription}
            disabled={
              utilisateur.nom &&
              utilisateur.prenom &&
              utilisateur.email &&
              !erreurEmail &&
              (utilisateur.password || isParrainage) &&
              (cgv || isParrainage)
                ? false
                : true
            }
            style={{ marginTop: "20px" }}
          />
        </div>
      </AffichageConditionnel>

      {/********* VERIFICATION DU COMPTE *****************/}
      <AffichageConditionnel open={etape === 2}>
        <div className={MonStyle.Formulaire}>
          <VerificationCompte verificationCompte={verificationCompte} />
        </div>
      </AffichageConditionnel>
      {/********* PARRAINAGE ON A ENVOYE UN EMAIL*****************/}
      <AffichageConditionnel open={etape === 3}>
        <div className={MonStyle.Formulaire}>
          <div className={MonStyle.Centre}>
            <img
              src={IllustrationEnvoye}
              alt="Email envoyé"
              className="Illustration"
            />
            <h4>Inscription terminée</h4>
            <h3 style={{ textAlign: "center" }}>
              Un email à été envoyé à {utilisateur.prenom}
            </h3>
            <p>à l'adresse: {utilisateur.email}</p>
            <BoutonActionPrincipale
              texte="OK"
              fullWidth
              style={{ margin: "20px 0px" }}
              onClick={onClose}
            />
          </div>
        </div>
      </AffichageConditionnel>
    </div>
  );
}
