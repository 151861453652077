import React from "react";
import Alert from "@material-ui/lab/Alert";

export default function MessageWarning({ message, variant, style }) {
  if (message) {
    return (
      <Alert
        severity="warning"
        variant={variant ? variant : "standard"}
        style={style}
      >
        {message}
      </Alert>
    );
  } else {
    return "";
  }
}
