import { Font } from "@react-pdf/renderer";
import Merry from "./Font/Merriweather-Regular.ttf";
import Lato from "./Font/Lato-Regular.ttf";
import LatoBold from "./Font/Lato-Bold.ttf";
import LatoBlack from "./Font/Lato-Black.ttf";
import LatoLight from "./Font/Lato-Light.ttf";

Font.register({
  family: "LatoBlack",
  src: LatoBlack,
});

Font.register({
  family: "LatoBold",
  src: LatoBold,
});

Font.register({
  family: "Lato",
  src: Lato,
});

Font.register({
  family: "LatoLight",
  src: LatoLight,
});

Font.register({
  family: "Merry",
  src: Merry,
});

export const StylesDocuments = {
  page: {
    padding: "10mm",
    paddingBottom: "16mm",
    margin: "0mm",
    color: "black",
    fontSize: "10px",
    fontFamily: "Lato",
  },

  normal: {
    textAlign: "left",
  },
  normalRight: {
    textAlign: "right",
  },

  titreBloc: {
    fontSize: "14px",
    color: "black",
    fontFamily: "LatoBold",
  },
  titre: {
    fontFamily: "LatoBlack",
    fontSize: "20px",
    color: "black",
  },
  titreColonne: {
    fontWeight: "bold",
    fontSize: "8px",
    color: "black",
    textAlign: "center",
  },
  titreColonneLeft: {
    fontWeight: "bold",
    fontSize: "8px",
    color: "black",
    textAlign: "left",
  },
  titreColonneRight: {
    fontWeight: "bold",
    fontSize: "8px",
    color: "black",
    textAlign: "right",
  },
  informationPrincipale: {
    fontSize: "10px",
    color: "black",
    fontFamily: "LatoBold",
  },
  informationPrincipaleRight: {
    fontSize: "10px",
    color: "black",
    fontFamily: "LatoBold",
    textAlign: "right",
  },
  informationSecondaire: {
    fontWeight: "bold",
    fontSize: "8px",
    color: "#666666",
  },
  informationSecondaireRight: {
    fontWeight: "bold",
    fontSize: "8px",
    color: "#666666",
    textAlign: "right",
  },
  informationJuridiques: {
    fontWeight: "bold",
    fontSize: "7px",
    color: "#666666",
  },
};
