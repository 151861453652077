import React, { useEffect, useState } from "react";
import TextField from "@material-ui/core/TextField";
import { withStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

//NES Style de composant NORMAL
const CssTextField = withStyles({
  root: {
    height: "40px",
    "& .MuiInputBase-input::placeholder": {
      color: "black",
    },
    "& .MuiOutlinedInput-root": {
      backgroundColor: (props) => props.monstyle.backgroundColor,
      borderColor: (props) => props.monstyle.borderColor,
      borderRadius: "6px",
      color: "#208AB0",
      "&.Mui-focused": {
        backgroundColor: "white",
      },
      "& fieldset": {
        borderColor: (props) => props.monstyle.borderColor,
      },
      "&:hover fieldset": {
        borderColor: "#208AB0",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#208AB0",
      },
      "&.Mui-disabled fieldset": {
        borderColor: "#DDD",
      },
    },
  },
})(TextField);

export default function InputRecherche({
  label,
  value,
  onChange,
  onBlur,
  onClick,
  onKeyDown,
  autoFocus,
  id,
  placeholder,
  autoComplete,
  disabled,
  onFocus,
  style,
  onEffacer,
  withBorder,
  onPressEnter,
  ...props
}) {
  //NES gestion des ref
  const inputRef = React.useRef();

  const handleEffacer = (event) => {
    //NES je replace le focus dans le champ
    inputRef.current.focus();

    //NES je vide le texte
    setTexte("");
    //NES retour au composant parent
    if (onEffacer) {
      onEffacer();
    }
    //NES
    if (onChange) {
      event.target.value = "";
      onChange(event);
    }
  };

  //NES Gestion du style si je n'ai pas de texte de recherche
  let MonStyleVide = {
    backgroundColor: "#E4E3EA",
    borderColor: withBorder ? "#DDD" : "#F6F6F6",
    couleurIcone: "#666666",
  };

  //NES Gestion du style si j'ai un texte de recherche
  let MonStyleAvecTexte = {
    backgroundColor: "white",
    borderColor: "#208AB0",
    couleurIcone: "#208AB0",
  };

  const [monStyle, setMonStyle] = useState(MonStyleVide);
  const [texte, setTexte] = useState("");

  const handleChange = (event) => {
    setTexte(event.target.value);
    if (onChange) {
      onChange(event);
    }
  };

  useEffect(() => {
    if (texte && texte !== "") {
      setMonStyle(MonStyleAvecTexte);
    } else {
      setMonStyle(MonStyleVide);
    }
    // eslint-disable-next-line
  }, [texte]);

  useEffect(() => {
    if (value) {
      setTexte(value);
    }
  }, [value]);

  ///handleKeyDown
  const handleKeyDown = (e) => {
    if (e.code === "Enter" && onPressEnter) {
      onPressEnter();
    }

    if (onKeyDown) {
      onKeyDown(e);
    }
  };

  return (
    <CssTextField
      monstyle={monStyle}
      id={id ? id : placeholder}
      value={texte}
      onChange={handleChange}
      onKeyDown={handleKeyDown}
      onBlur={onBlur}
      variant="outlined"
      onClick={onClick}
      size="small"
      autoFocus={autoFocus}
      fullWidth
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon style={{ color: monStyle.couleurIcone }} />
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end">
            {texte ? (
              <IconButton
                edge="end"
                onClick={handleEffacer}
                name="bouton_effacer_recherche"
              >
                <CloseIcon />
              </IconButton>
            ) : (
              ""
            )}
          </InputAdornment>
        ),
      }}
      placeholder={placeholder}
      autoComplete={autoComplete ? "" : "off"}
      disabled={disabled}
      name={placeholder}
      onFocus={onFocus}
      inputRef={inputRef}
      style={style}
      {...props}
    />
  );
}
