import React from "react";
import MonStyle from "./NomAgendaMobile.module.css";
import EventIcon from "@material-ui/icons/Event";

export default function NomAgendaMobile({ agenda }) {
  return (
    <div
      className={MonStyle.NomAgendaMobile}
      style={{
        backgroundColor: agenda.couleur_agenda,
        background: `linear-gradient(85deg,${agenda.couleur_agenda} 60%,${
          agenda.couleur_agenda + "90"
        }  )`,
      }}
    >
      <EventIcon style={{ marginRight: "15px", color: "white" }} />
      {agenda.nom_agenda}
    </div>
  );
}
