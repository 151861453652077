import React, { useState, useEffect } from "react";
import MonStyle from "./PageEditionAgenda.module.css";
import BoutonRond from "../../../Design/Boutons/BoutonRond";
import SurfacePapier from "../../../Design/Surfaces/SurfacePapier";
import CloseIcon from "@material-ui/icons/Close";
import ListItem from "@material-ui/core/ListItem";
import EventIcon from "@material-ui/icons/Event";
import EmailIcon from "@material-ui/icons/Email";
import MessageIcon from "@material-ui/icons/Message";
import ObjetItem from "../../../Design/Objets/ObjetItem";
import AgendaBlocAffichage from "./components/AgendaBlocAffichage";
import AgendaBlocEmail from "./components/AgendaBlocEmail";
import AgendaBlocSms from "./components/AgendaBlocSms";
import AffichageConditionnel from "../../../Design/DataDisplay/AffichageConditionnel";
import LoaderHorizontal from "../../../Design/Progression/LoaderHorizontal";
import { useContext } from "react";
import { AgendasContext } from "../../../Data/AgendasContext";
import MessageErreur from "../../../Design/Messages/MessageErreur";
import IllustrationNouvelleFonctionnalite from "../../../Images/Illustrations/NouvelleFonction.png";
import { useHistory } from "react-router-dom";
import MessageAlerte from "../../../Design/Messages/MessageAlerte";
import BoutonAnnuler from "../../../Design/Boutons/BoutonAnnuler";
import BoutonActionPrincipale from "../../../Design/Boutons/BoutonActionPrincipale";
import { CabinetsContext } from "../../../Data/CabinetContext";
import ExtensionIcon from "@material-ui/icons/Extension";
import AgendaBlocExterne from "./components/AgendaBlocExterne";
import BoutonTexte from "../../../Design/Boutons/BoutonTexte";
import BoutonConfirmerSuppression from "../../../Design/Boutons/BoutonConfirmerSuppression";

export default function PageEditionAgenda({ match }) {
  //NES récupération du paramètre dans l'URL
  const URL_AGENDA_ID = match.params.id;

  //NES gestion du routage
  const history = useHistory();
  let urlRetour = "/agendas/";
  if (URL_AGENDA_ID) {
    urlRetour = "/agendas/" + URL_AGENDA_ID;
  }
  //NES Gestion du context
  const { modeleAgenda, getAgendaById, deleteAgenda, patchAgenda, postAgenda } =
    useContext(AgendasContext);
  const { getObjetCabinet } = useContext(CabinetsContext);

  //NES gestion du state
  const [panel, setPanel] = useState("AFFICHAGE");
  const [data, setData] = useState(modeleAgenda);
  const [isLoading, setIsLoading] = useState(false);
  const [messageErreurApp, setMessageErreurApp] = useState();
  const [errorApp, setErrorApp] = useState();
  const [messageErreurSuppression, setMessageErreurSuppression] = useState("");
  const [errorSuppression, setErrorSuppression] = useState();
  const [messageErreur, setMessageErreur] = useState("");
  const [error, setError] = useState();
  const [messageOk, setMessageOk] = useState("");
  const [confirmerSuppression, setConfirmerSuppression] = useState(false);

  //USEEFFECT Récupération des données
  useEffect(() => {
    if (URL_AGENDA_ID) {
      setIsLoading(true);
      setErrorApp();
      //GET Agenda/:id
      getAgendaById(URL_AGENDA_ID)
        .then((reponse) => {
          setIsLoading(false);
          if (reponse.code === "OK") {
            let dataAgenda = reponse.data;
            //NES si le cabinet n'est pas renseigné
            //TODO à supprimer par la suite permet de gérer les agendas sans cabinet_id
            if (!dataAgenda.cabinet_id) {
              console.log("Renseigner le cabinet sur l'agenda");
              let monCabinetDeConnexion = getObjetCabinet();
              dataAgenda.cabinet_id = monCabinetDeConnexion.cabinet_id;
            }
            console.log(reponse.data);
            setData(reponse.data);
          } else {
            setErrorApp(reponse);
            setMessageErreurApp("Erreur lors de la récupération de l'agenda");
          }
        })
        .catch((error) => {
          setIsLoading(false);
          setErrorApp(error);
          setMessageErreurApp("Erreur lors de la récupération de l'agenda");
        });
    } else {
      //NES on charge avec le modèle par défault
      const agenda_par_defaut = modeleAgenda();
      setData(agenda_par_defaut);
    }
    // eslint-disable-next-line
  }, [URL_AGENDA_ID]);

  //DELETE agenda
  const handleSupprimer = () => {
    deleteAgenda(URL_AGENDA_ID)
      .then((reponse) => {
        if (reponse.code === "OK") {
          //NES redirection sur la page des agendas
          history.push(`/agendas/`);
        } else {
          setMessageErreurSuppression(
            "Erreur lors de la suppression de l'agenda"
          );
        }
      })
      .catch((error) => {
        setMessageErreurSuppression(
          "Erreur lors de la suppression de l'agenda"
        );
        setErrorSuppression(error);
      });
  };

  //NES Sauvegarder
  const sauvegarder = () => {
    console.log({ sauvegarder: data });
    setMessageOk("");
    enregistrer(data)
      .then((reponse) => {
        if (reponse.code === "OK") {
          //NES On affiche que l'enregistrement est OK
          setMessageOk("Les modifications sont enregistrées");
          console.log({ reponse });
        } else {
          setMessageErreur("Erreur lors de l'enregistrement de l'agenda");
        }
      })
      .catch((error) => {
        setMessageErreur("Erreur lors de l'enregistrement de l'agenda");
        setError(error);
      });
  };

  //NES je me fait un fonction pour eviter de gérer les 2 réponses differement
  const enregistrer = async (data) => {
    if (data._id) {
      //PATCH
      return await patchAgenda(data);
    } else {
      //POST
      return await postAgenda(data);
    }
  };

  //NES en cas d'erreur on affiche la page Erreur
  if (errorApp) {
    console.log({ errorApp });

    return (
      <SurfacePapier>
        <MessageErreur
          message={messageErreurApp}
          error={errorApp}
          taille="XL"
        />
      </SurfacePapier>
    );
  }

  return (
    <SurfacePapier>
      <header>
        <h2>{URL_AGENDA_ID ? "Personnaliser l'agenda" : "Nouvel agenda"}</h2>
        <BoutonRond
          couleurIcone="noir"
          iconeComposant={<CloseIcon />}
          link={urlRetour}
          tooltip="Fermer"
        />
      </header>
      <LoaderHorizontal isLoading={isLoading} style={{ margin: "10px 0px" }} />
      <main className={MonStyle.Content}>
        <nav>
          <img
            src={IllustrationNouvelleFonctionnalite}
            className={MonStyle.Illustration}
            alt="Illustration paramétrage agenda"
          />
          <ListItem
            button
            onClick={() => setPanel("AFFICHAGE")}
            selected={panel === "AFFICHAGE"}
          >
            <EventIcon className="IconeMenu" />
            <ObjetItem
              principale="Affichage"
              secondaire="Horaires, type de rendez-vous"
            />
          </ListItem>
          <ListItem
            button
            onClick={() => setPanel("EMAIL")}
            selected={panel === "EMAIL"}
          >
            <EmailIcon className="IconeMenu" />
            <ObjetItem
              principale="Email"
              secondaire="Confirmation et rappel de rdv"
            />
          </ListItem>
          <ListItem
            button
            onClick={() => setPanel("SMS")}
            selected={panel === "SMS"}
          >
            <MessageIcon className="IconeMenu" />
            <ObjetItem
              principale="Sms"
              secondaire="Confirmation et rappel de rdv"
            />
          </ListItem>
          <ListItem
            button
            onClick={() => setPanel("EXTERNE")}
            selected={panel === "EXTERNE"}
          >
            <ExtensionIcon className="IconeMenu" />
            <ObjetItem
              principale="Données externes"
              secondaire="Vacances scolaires, intersaisons"
            />
          </ListItem>
        </nav>
        <section className={MonStyle.PartieDroite}>
          <div className={MonStyle.ContenuPartieDroite}>
            <AffichageConditionnel open={panel === "AFFICHAGE"}>
              <AgendaBlocAffichage
                agenda={data}
                onChange={(data) => setData(data)}
              />
            </AffichageConditionnel>
            <AffichageConditionnel open={panel === "EMAIL"}>
              <AgendaBlocEmail
                agenda={data}
                onChange={(data) => setData(data)}
              />
            </AffichageConditionnel>
            <AffichageConditionnel open={panel === "SMS"}>
              <AgendaBlocSms agenda={data} onChange={(data) => setData(data)} />
            </AffichageConditionnel>
            <AffichageConditionnel open={panel === "EXTERNE"}>
              <AgendaBlocExterne
                agenda={data}
                onChange={(data) => setData(data)}
              />
            </AffichageConditionnel>
            <MessageErreur
              message={messageErreur}
              error={error}
              style={{ marginBottom: "20px" }}
            />
            <BoutonActionPrincipale
              taille="L"
              texte="Enregistrer"
              fullWidth
              onClick={sauvegarder}
            />
            <BoutonAnnuler
              taille="L"
              fullWidth
              style={{ margin: "20px 0px" }}
              link={urlRetour}
            />
            <MessageAlerte message={messageOk} />
          </div>
        </section>
      </main>

      <AffichageConditionnel
        open={URL_AGENDA_ID}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "20px",
          borderTop: "1px solid #DDD",
          padding: "20px",
        }}
      >
        <MessageErreur
          message={messageErreurSuppression}
          error={errorSuppression}
        />

        {confirmerSuppression ? (
          <BoutonConfirmerSuppression
            onSupprimer={handleSupprimer}
            onAnnuler={() => setConfirmerSuppression(false)}
          />
        ) : (
          <BoutonTexte
            texte="Supprimer l'agenda"
            onClick={() => setConfirmerSuppression(true)}
          />
        )}
      </AffichageConditionnel>
    </SurfacePapier>
  );
}
