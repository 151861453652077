import React from "react";
import MonStyle from "./SurfacePanneau.module.css";
import Drawer from "@mui/material/Drawer";
import BoutonRond from "../Boutons/BoutonRond";

export default function SurfacePanneau({
  open,
  onClose,
  onOpen,
  titre,
  sousTitre,
  ...props
}) {
  return (
    <Drawer anchor="right" open={open} onClose={onClose}>
      <div className={MonStyle.SurfacePanneau}>
        <header className={MonStyle.TitreEtBouton}>
          <BoutonRond
            couleurIcone="noir"
            tooltip="Fermer le panneau"
            onClick={onClose}
          />
          <h5>
            {titre}
            <p className="SousTitre">{sousTitre}</p>
          </h5>
        </header>
        {props.children}
      </div>
    </Drawer>
  );
}
