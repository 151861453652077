import React from "react";
import ObjetItem from "./ObjetItem";
import { DateToString } from "../../functions/FonctionsDateHeure";
import { capitalize } from "../../functions/Formatter";

export default function ObjetFacture({ facture, style }) {
  let libellePrincipal = "Facture n° " + facture.numero_facture;
  //NES en cas d'avoir c'est un avoir
  if (facture.is_avoir) {
    libellePrincipal = "Avoir n° " + facture.numero_facture;
  }

  return (
    <div style={{ width: "100%", ...style }}>
      <ObjetItem
        principale={libellePrincipal}
        secondaire={DateToString(facture.date_facture, {
          day: "numeric",
          month: "long",
          year: "numeric",
        })}
      />
      <div style={{ color: "#208AB0" }}>
        {facture?.patient?.nom.toUpperCase() +
          "  " +
          capitalize(facture?.patient?.prenom)}
      </div>
    </div>
  );
}
