import React, { useContext } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import EnteteApp from "../Components/Entete/EnteteApp";
import PageHome from "../Pages/Home/PageHome";
import PageMonCompte from "../Pages/MonCompte/PageMonCompte";
import Menu from "../Components/Menu/Menu";
import PageOptionsParametres from "../Pages/OptionsParametres/PageOptionsParametres";
import PageRecherche from "../Pages/Recherche/PageRecherche";
import { PatientsProvider } from "../Data/PatientsContext";
import PagePatientsRecents from "../Pages/PatientsRecents/PagePatientsRecents";
import PagePatient from "../Pages/Patients/PagePatient";
import { PostitsPatientsProvider } from "../Data/PostitsPatientsContext";
import { CommentairesPatientsProvider } from "../Data/CommentairesPatientsContext";
import PageAgendas from "../Pages/Agenda/PageAgendas";
import { AgendasProvider } from "../Data/AgendasContext";
import { RdvProvider } from "../Data/RdvContext";
import { MedicalProvider } from "../Data/MedicalContext";
import { ConsultationsProvider } from "../Data/ConsultationsContext";
import { ConstantesProvider } from "../Data/ConstantesContext";
import { DocumentsProvider } from "../Data/DocumentsContext";
import PageApropos from "../Pages/Apropos/PageApropos";
import PageProduits from "../Pages/Produits/PageProduits";
import { ProduitsProvider } from "../Data/ProduitsContext";
import { CabinetsProvider } from "../Data/CabinetContext";
import { ConseilsProvider } from "../Data/ConseilsContext";
import { NotificationsProvider } from "../Data/NotificationsContext";
import { MessagesProvider } from "../Data/MessagesContext";
import PagePrestation from "../Pages/Prestations/PagePrestations";
import { PrestationsProvider } from "../Data/PrestationsContext";
import { CotationsProvider } from "../Data/CotationsContext";
import { UtilisateursProvider } from "../Data/UtilisateursContext";
import PageSuiviReglement from "../Pages/SuiviReglements/PageSuiviReglements";
import PageCaisseSecurisee from "../Pages/CaisseSecurise/PageCaisseSecurise";
import PageRgpd from "../Pages/RGPD/PageRgpd";
import ScrollToTop from "../Components/ScrollToTop";
import PageDeveloppement from "../Pages/Debug/PageDeveloppement";
import { ContactsProvider } from "../Data/ContactsContext";
import PageSupport from "../Pages/Support/PageSupport";
import { SupportProvider } from "../Data/SupportContext";
import PageAdpx from "../Pages/ADPX/PageAdpx";
import { AdpxProvider } from "../Data/AdpxContext";
import { AuthentificationContext } from "../Data/AuthentificationContext";
import PageFacturation from "../Pages/Facturation/PageFacturation";
import PageParrainage from "../Pages/Parrainage/PageParrainage";
import PageCommencerAbonnement from "../Pages/Facturation/PageCommencerAbonnement";
import BoutonCapture from "../Components/CaptureEcran/BoutonCaptureEcran";
import PageCgv from "../Pages/Cgv/PageCgv";

const StyleApp = {
  display: "flex",
  flexDirection: "column",
};

const StyleContent = {
  display: "flex",
  flexDirection: "row",
  border: "0px solid pink",
  //  maxHeight: "800px",
};

const StyleMenu = {
  display: "flex",
  flexDirection: "column",
  border: "0px solid red",
};

export default function RoutesAuthentifies() {
  const auth = useContext(AuthentificationContext);

  return (
    <div style={StyleApp}>
      <Router>
        <ScrollToTop />
        <Switch>
          <NotificationsProvider>
            <SupportProvider>
              <AdpxProvider>
                <UtilisateursProvider>
                  <DocumentsProvider>
                    <CabinetsProvider>
                      <AgendasProvider>
                        <MessagesProvider>
                          <PatientsProvider>
                            <ContactsProvider>
                              <ProduitsProvider>
                                <PrestationsProvider>
                                  <ConseilsProvider>
                                    <CotationsProvider>
                                      <RdvProvider>
                                        <ConsultationsProvider>
                                          <ConstantesProvider>
                                            <EnteteApp />
                                            <div style={StyleContent}>
                                              <div style={StyleMenu}>
                                                <Menu />
                                                <BoutonCapture />
                                              </div>
                                              <Route
                                                path="/home"
                                                component={PageHome}
                                              />
                                              <Route
                                                path="/"
                                                exact
                                                component={PageHome}
                                              />
                                              <Route
                                                path="/verification"
                                                component={PageHome}
                                              />
                                              <Route exact path="/inscription">
                                                <Redirect to="/home?action=welcome" />
                                              </Route>
                                              <Route
                                                path="/moncompte"
                                                component={PageMonCompte}
                                              />
                                              <Route
                                                path="/recherche"
                                                component={PageRecherche}
                                              />
                                              <Route
                                                path="/apropos"
                                                component={PageApropos}
                                              />
                                              <Route
                                                path="/caissesecurise"
                                                component={PageCaisseSecurisee}
                                              />
                                              <Route
                                                path="/rgpd"
                                                component={PageRgpd}
                                              />
                                              <Route
                                                path="/conditionsgenerales"
                                                component={PageCgv}
                                              />
                                              {/*************************ROUTES OPTIONS***********************************/}
                                              <Route
                                                path="/options"
                                                component={
                                                  PageOptionsParametres
                                                }
                                              />
                                              {/*************************ROUTES PATIENTS***********************************/}
                                              <PostitsPatientsProvider>
                                                <CommentairesPatientsProvider>
                                                  <MedicalProvider>
                                                    <Route
                                                      path="/patientsrecents"
                                                      component={
                                                        PagePatientsRecents
                                                      }
                                                    />
                                                    <Route
                                                      path="/patients/:id"
                                                      component={PagePatient}
                                                    />
                                                  </MedicalProvider>
                                                </CommentairesPatientsProvider>
                                              </PostitsPatientsProvider>
                                              {/*************************ROUTES AGENDA***********************************/}
                                              <Route
                                                path="/agendas/"
                                                exact
                                                component={PageAgendas}
                                              />
                                              <Route
                                                path="/agendas/:id"
                                                component={PageAgendas}
                                              />
                                              {/*************************ROUTES PRODUITS***********************************/}
                                              <Route
                                                path="/produits/"
                                                component={PageProduits}
                                              />
                                              {/*************************ROUTES PRESTATIONS***********************************/}
                                              <Route
                                                path={[
                                                  "/prestations/:id",
                                                  "/prestations/",
                                                ]}
                                                component={PagePrestation}
                                              />
                                              {/*************************ROUTES SUIVI DES REGLEMENTS**************************/}
                                              <Route
                                                path="/suivireglements"
                                                component={PageSuiviReglement}
                                              />
                                              {/*************************ROUTES DEV**************************/}
                                              <Route
                                                path="/dev"
                                                component={PageDeveloppement}
                                              />
                                              {/*************************ROUTES SUPPORT**************************/}
                                              <Route
                                                path="/support/"
                                                exact
                                                component={PageSupport}
                                              />
                                              <Route
                                                path="/support/:id"
                                                component={PageSupport}
                                              />
                                              {/*************************ROUTES FACTURATION**************************/}
                                              <Route
                                                path="/facturation/"
                                                component={PageFacturation}
                                              />
                                              <Route
                                                path="/demarrer-abonnement/"
                                                component={
                                                  PageCommencerAbonnement
                                                }
                                              />
                                              {/*************************ROUTES PARRAINAGE**************************/}
                                              <Route
                                                path="/parrainage/"
                                                component={PageParrainage}
                                              />
                                              {/*************************ROUTES ADPX**************************/}
                                              {auth.payload.code_role ===
                                                "ADPX" && (
                                                <Route
                                                  path="/adpx/"
                                                  component={PageAdpx}
                                                />
                                              )}
                                            </div>
                                          </ConstantesProvider>
                                        </ConsultationsProvider>
                                      </RdvProvider>
                                    </CotationsProvider>
                                  </ConseilsProvider>
                                </PrestationsProvider>
                              </ProduitsProvider>
                            </ContactsProvider>
                          </PatientsProvider>
                        </MessagesProvider>
                      </AgendasProvider>
                    </CabinetsProvider>
                  </DocumentsProvider>
                </UtilisateursProvider>
              </AdpxProvider>
            </SupportProvider>
          </NotificationsProvider>
        </Switch>
      </Router>
    </div>
  );
}
