import React, { useState, useEffect, useContext } from "react";
import CaseHoraire from "./CaseHoraire";
import MonStyle from "./ColonneJour.module.css";
import { setHeureFromString } from "../../../../functions/FonctionsDateHeure";
import BarreHeureCourante from "./BarreHeureCourante";
import { RdvContext } from "../../../../Data/RdvContext";
import { AgendasContext } from "../../../../Data/AgendasContext";
import AffichageEvenement from "./AffichageEvenement.js/AffichageEvenement";
import AfficherRdv from "./AffichageRdv/AfficherRdv";

export default function ColonneJour({
  date,
  agenda,
  cases,
  hauteurCase,
  hauteurHeure,
}) {
  //NES Gestion du contexte
  const {
    rdv,
    evenements,
    getRdvContext,
    calculDateHeureFinRdv,
    getEvementsContext,
  } = useContext(RdvContext);
  const { tailleRdvDefaut } = useContext(AgendasContext);

  //NES gestion du state
  const [listeEvenementsJour, setListeEvenementsJour] = useState([]);
  const [isJourTravaille, setIsJourTravaille] = useState(true);
  const [horaires, setHoraires] = useState({});
  const [listeRdv, setListeRdv] = useState([]);

  useEffect(() => {
    //NES est ce que le jour est travaillé?
    if (agenda) {
      let monNumeroJour = date.getDay();
      let isTravaille = agenda.jours_affiches.includes(monNumeroJour);
      if (isTravaille) {
        setIsJourTravaille(true);
        //NES si le jour est travaillé quels sont les horaires?
        let horaireJour = agenda.horaires_semaine.find(
          (item) =>
            item.numero_jour === monNumeroJour.toString() ||
            item.numero_jour === "all"
        );
        setHoraires(horaireJour);
      } else {
        setIsJourTravaille(false);
      }
    }
    // eslint-disable-next-line
  }, [date, agenda]);

  useEffect(() => {
    let mesRdvDujour = getRdvContext(agenda._id, date, date);
    mesRdvDujour = mesRdvDujour.sort((a, b) => {
      let dateA = new Date(a.date_heure);
      let dateB = new Date(b.date_heure);
      //NES encore des pb de millisecondes
      dateA.setSeconds(1);
      if (dateA > dateB) {
        return 1;
      } else {
        return -1;
      }
    });

    setListeRdv(mesRdvDujour);

    //NES calcul des positions des RDV
    let rdvAvecPositions = [];
    mesRdvDujour.forEach((rdv) => {
      let dateDebutRdv = new Date(rdv.date_heure);
      //NES a cause des pb de millisecondes de M...DE
      dateDebutRdv.setSeconds(1);
      let dateFinRdv = new Date(calculDateHeureFinRdv(rdv));
      //NES est-ce qu'il y a déjà des rdv positionnés?
      let rdvDejaPositionnes = rdvAvecPositions.filter((item) => {
        let dateDebutRdvPositionne = new Date(item.date_heure);
        dateDebutRdvPositionne.setSeconds(1);
        let dateFinRdvPositionne = new Date(calculDateHeureFinRdv(item));
        if (
          (dateDebutRdv >= dateDebutRdvPositionne &&
            dateDebutRdv < dateFinRdvPositionne) ||
          (dateFinRdv >= dateDebutRdvPositionne &&
            dateFinRdv < dateFinRdvPositionne)
        ) {
          return true;
        } else {
          return false;
        }
      });

      if (rdvDejaPositionnes.length > 0) {
        //NES j'ai déjà des rdv quel est le nombre de positions?
        let maxPositionExistante = 1;
        let oldCoeffWidth = 1;
        let listePositions = [];
        rdvDejaPositionnes.forEach((element) => {
          listePositions.push(element.position);
          if (element.position > maxPositionExistante) {
            maxPositionExistante = element.position;
            oldCoeffWidth = element.coefWidth;
          }
        });
        let NewPosition = maxPositionExistante + 1;
        let newCoeffWidth = 1 / NewPosition;

        //NES je controle pour voir si je n'ai pas un trou disponible
        for (let index = 1; index < maxPositionExistante; index++) {
          if (!listePositions.includes(index)) {
            NewPosition = index;
            //NES je garde la largeur
            newCoeffWidth = oldCoeffWidth;
            break;
          }
        }

        //NES mise à jour des coefs des rdv déjà positionnés pour mettre à jour le coef
        rdvDejaPositionnes.forEach((element) => {
          let index = rdvAvecPositions.findIndex(
            (item) => item._id === element._id
          );
          rdvAvecPositions.splice(index, 1, {
            ...element,
            coefWidth: newCoeffWidth,
          });
        });

        rdvAvecPositions.push({
          position: NewPosition,
          coefWidth: newCoeffWidth,
          ...rdv,
        });
      } else {
        rdvAvecPositions.push({ position: 1, coefWidth: 1, ...rdv });
      }
    });

    setListeRdv(rdvAvecPositions);

    // eslint-disable-next-line
  }, [rdv, agenda._id, date, tailleRdvDefaut]);

  //NES Fonction indiquant si l'heure est comprise dans les horaires d'ouverture
  const isClose = (strHeure) => {
    if (!isJourTravaille) {
      return true;
    }
    if (horaires) {
      let maintenant = new Date();
      //NES Construction en datetime de mes horaires pour controler les horaires
      let heure = setHeureFromString(maintenant, strHeure);
      let dateHeureMatinDebut = setHeureFromString(
        maintenant,
        horaires.matin_debut
      );
      let dateHeureMatinFin = setHeureFromString(
        maintenant,
        horaires.matin_fin
      );
      let dateHeureApresmidiDebut = setHeureFromString(
        maintenant,
        horaires.apresmidi_debut
      );
      let dateHeureApresmidiFin = setHeureFromString(
        maintenant,
        horaires.apresmidi_fin
      );

      if (
        (dateHeureMatinDebut <= heure && heure < dateHeureMatinFin) ||
        (dateHeureApresmidiDebut <= heure && heure < dateHeureApresmidiFin)
      ) {
        return false;
      }
    }
    return true;
  };

  //USEEFFECT récupération des évenements
  useEffect(() => {
    let evenementsDuJour = getEvementsContext(date, date);
    //NES je filtre pour enlever les évènement sur toute la journée
    evenementsDuJour = evenementsDuJour.filter(
      (item) => item.is_toute_la_journee === false
    );
    setListeEvenementsJour(evenementsDuJour);
    // eslint-disable-next-line
  }, [date, evenements]);

  return (
    <div
      className={
        !isJourTravaille
          ? MonStyle.ColonneJourNonTravaille
          : MonStyle.ColonneJour
      }
    >
      <BarreHeureCourante
        date={date}
        agenda_id={agenda._id}
        hauteurHeure={hauteurHeure}
      />

      {/* Affichage des cases horaires */}
      {cases.map((heure) => (
        <CaseHoraire
          key={heure}
          agenda_id={agenda._id}
          heure={heure}
          isGrise={isClose(heure)}
          date={date}
          hauteurCase={hauteurCase}
        />
      ))}

      {listeRdv.map((rdv) => (
        <AfficherRdv
          rdv={rdv}
          key={rdv._id}
          agenda_id={agenda._id}
          hauteurCase={hauteurCase}
          hauteurHeure={hauteurHeure}
          //styleWidth={calculCoefLargeur(rdv)}
        />
      ))}
      {listeEvenementsJour.map((evenement) => (
        <AffichageEvenement
          evenement={evenement}
          date={date}
          agenda_id={agenda._id}
          key={evenement._id}
          hauteurCase={hauteurCase}
          hauteurHeure={hauteurHeure}
        />
      ))}

      <div className={MonStyle.NbRdv}>{listeRdv.length + " rdv"}</div>
    </div>
  );
}
