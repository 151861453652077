import React, { useContext, useEffect, useState } from "react";
import ObjetCabinet from "../../../../Design/Objets/ObjetCabinet";
import { CabinetsContext } from "../../../../Data/CabinetContext";
import EnregistrementChoixCabinet from "./EnregistrementChoixCabinet";
import AffichageChoixCabinet from "./AffichageChoixCabinet";
import MonStyle from "./AffichageCabinet.module.css";

export default function AffichageCabinetUtilisateur() {
  //NES Gestion du contexte
  const { getCabinetByUtilisateur, setCabinetConnexion, cabinetConnexion } =
    useContext(CabinetsContext);

  //NES gestion du state
  const [afficherEnregistrementCabinet, setAfficherEnregistrementCabinet] =
    useState(false);
  const [afficherChoixCabinet, setAfficherChoixCabinet] = useState(false);

  useEffect(() => {
    getCabinetByUtilisateur().then((reponse) => {
      if (reponse?.length === 0) {
        //NES Aucun cabinet sélectionnné par l'utilisateur, il faut choisir
        setAfficherEnregistrementCabinet(true);
      }
      if (reponse?.length === 1) {
        //NES Dans le cas le cabinet de connexion est le seul
        handleSelectCabinet(reponse[0]);
      }
      if (reponse?.length > 1) {
        //NES je commence par regarder dans le localstorage
        let cabinetIdFromStorage = localStorage.getItem("cabinet");
        let cabinetFromStorage = reponse.find(
          (item) => item._id === cabinetIdFromStorage
        );

        if (cabinetFromStorage) {
          handleSelectCabinet(cabinetFromStorage);
        } else {
          //NES Dans le cas il faut choisir le cabinet de connexion
          setAfficherChoixCabinet(true);
        }
      }
    });

    // eslint-disable-next-line
  }, []);

  /// Selctionnner le cabinet
  const handleSelectCabinet = (cabinet) => {
    if (cabinet) {
      //NES je stocke le cabinet sélectionné dans le localStorage
      //NES pas l'objet mais bien l'id pour des pb de maj de données si je modifie le cabinet
      localStorage.setItem("cabinet", cabinet._id);
      //NES je le stocke dans le contexte
      setCabinetConnexion(cabinet);
    }
  };

  if (afficherEnregistrementCabinet)
    return (
      <EnregistrementChoixCabinet
        open={afficherEnregistrementCabinet}
        onClose={() => setAfficherEnregistrementCabinet(false)}
      />
    );

  if (afficherChoixCabinet) {
    return (
      <AffichageChoixCabinet
        open={afficherChoixCabinet}
        onClose={() => setAfficherChoixCabinet(false)}
        onCabinetSelet={(cabinet) => handleSelectCabinet(cabinet)}
      />
    );
  }

  return (
    <div
      className={MonStyle.AffichageCabinetUtilisateur}
      onClick={() => setAfficherChoixCabinet(true)}
    >
      <ObjetCabinet cabinet={cabinetConnexion} color="white" />
    </div>
  );
}
