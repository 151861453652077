import React from "react";

export default function AffichageNombre(props) {
  return (
    <div
      style={{
        backgroundColor: "#DDDDDD",
        padding: "2px 10px",
        fontSize: "14px",
        fontWeight: "900",
        color: "black",
        borderRadius: "20px",
      }}
    >
      {props.children}
    </div>
  );
}
