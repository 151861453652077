import React from "react";

export default function TextesInscription() {
  return (
    <div style={{ color: "white" }}>
      <h3 style={{ color: "white", marginBottom: "20px" }}>
        Pourquoi s'incrire ?
      </h3>
      <p style={{ color: "white" }}>
        Sinobi est une application en ligne de gestion de patientèle avec un
        agenda complet et un système de rappel de rendez vous, de facturation et
        de comptabilité complète.
      </p>
      <p style={{ color: "white" }}>
        Où que vous soyez, à votre cabinet, en déplacement, il vous suffit d'un
        terminal connecté à internet et d'un navigateur à jour installé. En
        effet, vous voilà débarassé de la contrainte matérielle habituelle, les
        données de vos patients sont accessibles partout où vous êtes, dans
        votre espace sécurisé sur Sinobi. Windows ou Mac, ordinateur de bureau,
        portable, tablette ou téléphone, il vous suffit d'un peu de réseau et
        d'un navigateur internet.
      </p>
      <p style={{ color: "white" }}>
        Sinobi n'étant pas connecté au système Sesam Vital, si vous n'en avez
        pas besoin, c'est l'outil idéal pour gérer votre cabinet !
      </p>
      <strong style={{ color: "white" }}>
        L'aventure Sinobi commence ici !
      </strong>
      <div
        className="Signature"
        style={{
          color: "white",
          textAlign: "right",
          border: "0px solid red",
          width: "100%",
          marginTop: "40px",
          paddingRight: "20px",
          boxSizing: "border-box",
        }}
      >
        L'équipe Sinobi{" "}
      </div>
    </div>
  );
}
