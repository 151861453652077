import React, { useState } from "react";
import MenuAction from "../../../../Design/Navigation/BoutonMenu";
import MonStyle from "./TypeRdv.module.css";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import ListItemText from "@material-ui/core/ListItemText";
import DeleteIcon from "@material-ui/icons/Delete";
import InputTexte from "../../../../Design/Formulaires/InputTexte";
import ColorPicker from "../../../../Design/Pickers/ColorPicker";
import Popover from "@mui/material/Popover";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import { ButtonBase } from "@material-ui/core";
import AppTooltip from "../../../../Design/DataDisplay/ToolTip";

export default function TypeRdv({
  libelle,
  couleur,
  duree,
  code,
  onDelete,
  onChange,
  onClickRemonter,
  onClickDescendre,
}) {
  //NES POPOVER
  const [hover, setHover] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const afficherPopOver = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (key, value) => {
    if (onChange) {
      onChange(key, value);
    }
  };

  return (
    <div
      className={MonStyle.TypeRdv}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <div className={MonStyle.Couleur} onClick={afficherPopOver}>
        <div
          style={{
            width: "24px",
            height: "24px",
            backgroundColor: couleur,
            borderRadius: "4px",
          }}
        ></div>
      </div>
      <Popover
        open={anchorEl ? true : false}
        anchorEl={anchorEl}
        onClose={handleClose}
        onClick={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <ColorPicker
          onCouleurChange={(couleur) => handleChange("couleur", couleur)}
        />
      </Popover>
      <div className={MonStyle.Libelle}>
        <InputTexte
          value={libelle}
          theme="lecture"
          onChange={(event) => handleChange("libelle", event.target.value)}
        />
      </div>
      <div
        style={{
          padding: "0px",
          boxSizing: "border-box",
          color: "#666",
          minWidth: "25px",
        }}
      >
        {" "}
        {hover && (
          <AppTooltip texte="Monter">
            <ButtonBase>
              <ArrowDropUpIcon fontSize="small" onClick={onClickRemonter} />
            </ButtonBase>
          </AppTooltip>
        )}
        {hover && (
          <AppTooltip texte="Descendre">
            <ButtonBase>
              <ArrowDropDownIcon fontSize="small" onClick={onClickDescendre} />
            </ButtonBase>
          </AppTooltip>
        )}
      </div>
      <div className={MonStyle.Duree}>
        <Select
          disableUnderline={true}
          style={{ color: "#208AB0" }}
          value={duree}
          onChange={(event) => handleChange("duree", event.target.value)}
        >
          <MenuItem value="0:10">0:10</MenuItem>
          <MenuItem value="0:15">0:15</MenuItem>
          <MenuItem value="0:20">0:20</MenuItem>
          <MenuItem value="0:25">0:25</MenuItem>
          <MenuItem value="0:30">0:30</MenuItem>
          <MenuItem value="0:35">0:35</MenuItem>
          <MenuItem value="0:40">0:40</MenuItem>
          <MenuItem value="0:45">0:45</MenuItem>
          <MenuItem value="0:50">0:50</MenuItem>
          <MenuItem value="0:55">0:55</MenuItem>
          <MenuItem value="1:00">1:00</MenuItem>
          <MenuItem value="1:15">1:15</MenuItem>
          <MenuItem value="1:30">1:30</MenuItem>
          <MenuItem value="1:45">1:45</MenuItem>
          <MenuItem value="2:00">2:00</MenuItem>
        </Select>
      </div>
      <div>
        <MenuAction tooltip="Actions sur le typde rdv">
          <MenuItem style={{ color: "red" }} onClick={onDelete}>
            <DeleteIcon style={{ marginRight: "15px" }} />
            <ListItemText primary="Supprimer" />
          </MenuItem>
        </MenuAction>
      </div>
    </div>
  );
}
