import React, { useState, useEffect, useContext } from "react";
import MonStyle from "./ObjetDocument.module.css";
import IconesDocuments from "../Icones/IconesDocuments";
import { TagPrive, TagPartage } from "../DataDisplay/Tags";
import LensIcon from "@material-ui/icons/Lens";
import MenuDocuments from "./Composants/MenuDocuments";
import PopupRenommerDocuments from "./Composants/PopupRenommerDocument";
import PopupPartage from "../../Components/PopupPartage/PopupPartage";
import Axios from "axios";
import LoarderCirculaire from "../Progression/LoaderCirculaire";
import { AuthentificationContext } from "../../Data/AuthentificationContext";
import { DocumentsContext } from "../../Data/DocumentsContext";
import download from "downloadjs";
import BoutonConfirmerSuppression from "../Boutons/BoutonConfirmerSuppression";
import SurfacePopUp from "../Surfaces/SurfacePopUp";
import PopupMessageErreur from "../Messages/PopupMessageErreur";

export default function ObjetDocument({
  document,
  isNouveau,
  style,
  onPatch,
  onPost,
  onDelete,
  disableMenu,
}) {
  //NES Gestion du contexte
  const authContext = useContext(AuthentificationContext);
  const { documents, setDocuments, terminerDocumentsUploadContext } =
    useContext(DocumentsContext);
  //NES Gestion du state
  const [afficherRenommer, setAfficherRenommer] = useState(false);
  const [afficherPartage, setAfficherPartage] = useState(false);
  const [progression, setProgression] = useState();
  const [erreur, setErreur] = useState("");
  const [error, setError] = useState();
  const [afficherSuppression, setAfficherSuppression] = useState(false);
  //NES Afficher la popup renommer
  const afficherPopupRenommer = () => {
    setAfficherRenommer(true);
  };

  //PATCH modifier un document
  const handleChange = (key, value) => {
    let newData = { [key]: value };
    const MonUrl = `${process.env.REACT_APP_URL_API_DOCUMENTS}/patients/${document.patient_id}/documents/${document._id}`;
    //NES pour détecter les appels en boucle
    console.log("PATCH :" + MonUrl);
    Axios.patch(MonUrl, newData, {
      headers: {
        token: authContext.token,
      },
    })
      .then((reponseApi) => {
        let mareponse = reponseApi.data;

        if (mareponse.code === "OK") {
          setAfficherRenommer("");
          if (onPatch) {
            onPatch(mareponse);
          }
          //NES je mets à jour le contexte patient
          if (document.patient_id) {
            let tempArray = [...documents];
            let index = tempArray.findIndex(
              (item) => item._id === document._id
            );
            if (index > -1) {
              tempArray.splice(index, 1, mareponse.data);
              setDocuments(tempArray);
            }
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //POST
  useEffect(() => {
    if (document.upload_fichier && progression === undefined) {
      let MonUrl = `${process.env.REACT_APP_URL_API_DOCUMENTS}/documents`;

      //NES URL PATIENT
      if (document.patient_id) {
        MonUrl = `${process.env.REACT_APP_URL_API_DOCUMENTS}/patients/${document.patient_id}/documents`;
      }
      //NES URL en cas de consultation
      if (document.consultation_id && document.patient_id) {
        MonUrl = `${process.env.REACT_APP_URL_API_DOCUMENTS}/patients/${document.patient_id}/consultations/${document.consultation_id}/documents`;
      }

      //NES pour détecter les appels en boucle
      console.log("POST :" + MonUrl);
      const fd = new FormData();
      fd.append("fichier", document.upload_fichier);
      Axios.post(MonUrl, fd, {
        headers: {
          token: authContext.token,
          "content-type": "multipart/form-data",
        },
        onUploadProgress: (ProgressEvent) => {
          setProgression(
            Math.round((ProgressEvent.loaded / ProgressEvent.total) * 100)
          );
        },
      })
        .then((reponseApi) => {
          let reponse = reponseApi.data;

          if (reponse.code === "OK") {
            setProgression();
            if (onPost) {
              onPost(reponse);
            }
            if (document.patient_id) {
              //NES je mets à jour le contexte
              terminerDocumentsUploadContext(document._id, reponse.data);
            }
          }
        })
        .catch((error) => {
          setErreur("Erreur lors du téléchargement du fichier");
          setProgression();
        });
    }
    // eslint-disable-next-line
  }, [document]);

  //GET récupération du document
  const handleOpen = async (is_download) => {
    const MonUrl = `${process.env.REACT_APP_URL_API_DOCUMENTS}/documents/${document._id}`;
    //NES pour détecter les appels en boucle
    console.log("GET :" + MonUrl);
    //NES appel de l'API

    fetch(MonUrl, {
      method: "GET",
      headers: {
        //Accept: "application/json",
        "Content-Type": "application/json",
        token: authContext.token,
        //responseType: "blob",
      },
    })
      .then((res) => {
        if (!res.ok) {
          //NES en cas erreur 400
          if (res.status === 400) {
            res.json().then((reponseJson) => {
              setErreur("Error lors de la récupération du document");
              setError(reponseJson);
            });
          } else {
            setErreur("Error lors de la récupération du document");
            setError({
              technical_stack: res.status + " - " + res.statusText,
            });
          }
        } else {
          res.blob().then((blob) => {
            var file = new File([blob], document.nom, {
              type: document.mimetype,
            });
            //let file = new File(blob, "nicolas", { type: "application/json" });
            let objectURL = window.URL.createObjectURL(file);

            if (is_download) {
              //NES si j'ai demandé à downloader le fichier
              download(blob, document.nom);
            } else {
              //NES si j'ai clique sur le fichier pour l'ouvrir
              window.open(objectURL, "_blank");
            }
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //DELETE du document
  const deleteDocument = async () => {
    let MonUrl = `${process.env.REACT_APP_URL_API_DOCUMENTS}/documents/${document._id}`;
    if (document.patient_id) {
      MonUrl = `${process.env.REACT_APP_URL_API_DOCUMENTS}/patients/${document.patient_id}/documents/${document._id}`;
    }

    //NES pour détecter les appels en boucle
    console.log("DELETE :" + MonUrl);
    //NES je récupère les data depuis l'API
    const reponseApi = await fetch(MonUrl, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        token: authContext.token,
      },
    });
    //NES je convertis ce que je récupère en JSON
    const dataJson = await reponseApi.json();
    //NES si c'est OK je mets à jour le context
    if (dataJson.code === "OK") {
      if (onDelete) {
        onDelete(dataJson);
      }
      if (document.patient_id) {
        //NES si c'est OK je mets à jour le context
        if (dataJson.code === "OK") {
          let tempArray = [...documents];
          let index = tempArray.findIndex((item) => item._id === document._id);
          tempArray.splice(index, 1);
          setDocuments(tempArray);
        }
      }
    }
  };

  return (
    <div className={MonStyle.ObjetDocument} style={style}>
      {afficherSuppression && (
        <SurfacePopUp
          open={afficherSuppression}
          onClose={() => setAfficherSuppression(false)}
        >
          <div className={MonStyle.ConfirmationSuppression}>
            <h5>Suppression</h5>
            <p>Voulez vous réellement supprimer ce document ?</p>{" "}
            <BoutonConfirmerSuppression
              onAnnuler={() => setAfficherSuppression(false)}
              onSupprimer={deleteDocument}
            />
          </div>
        </SurfacePopUp>
      )}

      <div
        className={MonStyle.IconeEtInformations}
        onClick={() => handleOpen()}
      >
        <div className={MonStyle.IconeNouveau}>
          {isNouveau ? (
            <LensIcon style={{ fontSize: 12, color: "#0097CE" }} />
          ) : (
            " "
          )}
        </div>
        {progression ? (
          <LoarderCirculaire
            isLoading={true}
            style={{ marginRight: "20px" }}
            progression={progression}
          />
        ) : (
          <IconesDocuments
            nomIcone={document.type}
            style={{ marginRight: "10px" }}
            className="Icone50px"
          />
        )}

        {!progression ? (
          <div className={MonStyle.Informations}>
            <div
              className={
                isNouveau ? "InformationNonLue" : "InformationPrincipale"
              }
              style={{ marginRight: "10px" }}
            >
              {document.nom}
            </div>

            <div className={MonStyle.InformationSecondaireEtTag}>
              <div
                className="InformationSecondaire"
                style={{ marginRight: "8px" }}
              >
                {document.type}
              </div>
              <div className={MonStyle.Tags}>
                {document.is_prive && <TagPrive />}
                <TagPartage listePartage={document.partage} />
              </div>
            </div>
          </div>
        ) : (
          <div style={{ color: "#97989D" }}>
            Téléchargement en cours {progression} %
          </div>
        )}
      </div>
      <div className={MonStyle.Menu}>
        {!disableMenu && (
          <MenuDocuments
            onClickRenommer={afficherPopupRenommer}
            onClickType={(type) => handleChange("type", type)}
            onClickPartage={() => setAfficherPartage(true)}
            onClickPrive={() => handleChange("is_prive", !document.is_prive)}
            onClickSupprimer={() => setAfficherSuppression(true)}
            onClickTelecharger={() => handleOpen(true)}
          />
        )}
      </div>

      <PopupPartage
        open={afficherPartage}
        onClose={() => setAfficherPartage(false)}
        listePartage={document.partage}
        onClickPartage={(liste) => handleChange("partage", liste)}
      />
      <PopupRenommerDocuments
        open={afficherRenommer}
        onClose={() => setAfficherRenommer(false)}
        nomDocument={document.nom}
        onClickValider={(texte) => handleChange("nom", texte)}
      />
      <PopupMessageErreur
        messageErreur={erreur}
        onClose={() => setErreur("")}
        error={error}
      />
    </div>
  );
}
