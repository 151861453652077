import React from "react";
import MonStyle from "./IconePicker.module.css";
import IconesProduits from "../Icones/IconesProduits";
import { useState, useEffect } from "react";

export default function IconePicker({ value, style, onChange, disabled }) {
  const icones = ["IconePlante", "IconeLiquide", "IconeGellule"];

  //NES Gestion du state
  const [selected, setSelected] = useState("");

  const handleChange = (icone) => {
    setSelected(icone);
    if (onChange) {
      onChange(icone);
    }
  };

  useEffect(() => {
    setSelected(value);
  }, [value]);

  const getClassName = (icone) => {
    if (selected === icone) {
      if (disabled) {
        return MonStyle.IconeSelectionneDisabled;
      } else {
        return MonStyle.IconeSelectionne;
      }
    } else {
      if (disabled) {
        return MonStyle.IconeDisabled;
      } else {
        return MonStyle.Icone;
      }
    }
  };

  return (
    <div className={MonStyle.IconePicker} style={style}>
      {icones.map((icone) => (
        <div
          className={getClassName(icone)}
          onClick={() => handleChange(icone)}
          key={icone}
        >
          <IconesProduits
            nomIcone={icone}
            style={{ width: "30px", height: "30px" }}
          />
        </div>
      ))}
    </div>
  );
}
