import React, { useContext, useEffect, useState } from "react";
import InputTexte from "../../../../Design/Formulaires/InputTexte";
import MonStyle from "./PageDossier.module.css";
import InputDate from "../../../../Design/Formulaires/InputDate";
import Adresse from "../../../../Components/Adresse";
import MessageErreur from "../../../../Design/Messages/MessageErreur";
import { PatientsContext } from "../../../../Data/PatientsContext";
import SelecteurSexe from "../../../../Design/Formulaires/SelecteurSexe";
import Commentaires from "./Components/Commentaires";
import MessageAlerte from "../../../../Design/Messages/MessageAlerte";
import EditionMailTelephones from "../../Components/EditionEmailTelephones/EditionMailTelephones";
import BoutonActionPrincipale from "../../../../Design/Boutons/BoutonActionPrincipale";
import { useHistory } from "react-router-dom";
import ObjetTracabilite from "../../../../Design/Objets/ObjetTracabilite";
import InputEmail from "../../../../Design/Formulaires/InputEmail";
import AffichageFamille from "../../Components/AffichageFamille/AffichageFamille";

export default function PageDossier({ match }) {
  //NES Modèle patient
  const modelePatient = {
    sexe: "",
    nom: "",
    nom_jeune_fille: "",
    prenom: "",
    date_de_naissance: "",
    lieu_naissance: "",
    age: "",
    contact: {
      telephones: [{ is_favori: false, numero: "", type: "Téléphone" }],
    },
    email: "",
    adresse: { rue: "", code_postal: "", ville: "", complement: "", pays: "" },
  };

  //NES récupération de l'id patient dans l'url
  let idUrl = match.params.id;

  /// Gestion du routage pour faire des redirections
  let history = useHistory();

  /// Gestion du contexte
  const { patchPatient, getPatientContext, postPatient, deletePatientContext } =
    useContext(PatientsContext);

  /// gestion du state
  const [patient, setPatient] = useState(modelePatient);
  const [messageEnregistrement, setMessageEnregistrement] = useState("");
  const [messageErreur, setMessageErreur] = useState("");
  const [error, setError] = useState();

  useEffect(() => {
    if (idUrl && idUrl !== "nouveau") {
      getPatientContext(idUrl)
        .then((reponse) => {
          if (reponse.code === "OK") {
            setPatient(reponse.data);
          } else {
            setMessageErreur(reponse.erreur.message);
          }
        })
        .catch((error) => {
          setMessageErreur(
            "Erreur lors de la récupération des donnée du patient"
          );
          setError(error);
        });
    } else {
      setPatient(modelePatient);
    }
    // eslint-disable-next-line
  }, [idUrl]);

  //NES mise à jour de l'objet
  const handleChange = (key, value) => {
    setPatient({ ...patient, [key]: value });
  };

  //NES quand on change le sexe du patient
  const handleSexeChange = (sexe) => {
    let newData = { ...patient, sexe: sexe };
    setPatient(newData);
    sauvegarderAuto(newData);
  };

  //NES quand on sélectionne une adresse
  const handleSelectAdresse = (adresse) => {
    let newData = {
      ...patient,
      adresse: adresse,
    };
    setPatient(newData);
    sauvegarderAuto(newData);
  };

  //NES quand on quitte un champ
  const handleBlur = (key, value) => {
    let newData = { ...patient };
    if (key && value) {
      newData = { ...patient, [key]: value };
      setPatient({ ...patient, [key]: value });
    }
    sauvegarderAuto(newData);
  };

  //NES quand je supprime un téléphone
  const handleDeleteTelephone = (contact) => {
    let newData = { ...patient, contact: contact };
    setPatient(newData);
    sauvegarderAuto(newData);
  };

  //NES Sauvegarde des données (en général à la sortie du champ)
  const sauvegarderAuto = (data) => {
    if (data && data._id) {
      setMessageEnregistrement("");
      setMessageErreur("");
      //PATCH
      patchPatient(data._id, data)
        .then((reponse) => {
          console.log({ reponse });

          if (reponse.code === "OK") {
            //setPatient(reponse.data);
            //!ATTENTION Je ne garde que l'age calculé par l'API car sinon en cas de lenteur je peux perdre des données du formulaire
            let newData = { ...data, age: reponse.data.age };
            setPatient(newData);
            setMessageEnregistrement(
              "Modification enregistrée automatiquement"
            );
          } else {
            if (reponse.erreur) {
              setMessageErreur(reponse.erreur.message);
            } else {
              setMessageErreur("Erreur lors de l'enregistrement");
            }
          }
        })
        .catch((error) => {
          setMessageErreur("Erreur lors de l'enregistrement");
          setError(error);
        });
    }
  };

  //POST Sauvegarde manuellement des données lors de l'ajout d'un nouveau patient
  const creerPatient = () => {
    postPatient(patient)
      .then((reponseApi) => {
        if (reponseApi.code === "OK") {
          setMessageErreur("");

          //NES redirection sur le dossier
          let newUrl = "/patients/" + reponseApi.data._id + "/dossier";
          history.push(newUrl);
        } else {
          setMessageErreur("Erreur lors de la création du dossier");
        }
      })
      .catch((error) => {
        setError(error);
        setMessageErreur("Erreur lors de la création du dossier");
      });
  };

  //Delete du patient
  const supprimerPatient = () => {
    deletePatientContext(patient._id)
      .then((reponseApi) => {
        if (reponseApi.code === "OK") {
          setMessageErreur("");
          setMessageEnregistrement(reponseApi.data.message);
          //NES Je retourne sur les patients récents
          history.push("/patientsrecents/");
        } else {
          setMessageErreur("Erreur lors de la suppression du dossier");
          setMessageEnregistrement("");
        }
      })
      .catch((error) => {
        setError(error);
        setMessageErreur("Erreur lors de la suppression du dossier");
      });
  };

  return (
    <div className={MonStyle.PageDossier}>
      <div className={MonStyle.Wrapper}>
        <div className={MonStyle.Formulaire}>
          <SelecteurSexe
            onChange={(sexe) => handleSexeChange(sexe)}
            sexe={patient.sexe}
          />
          <InputTexte
            label="Nom (obligatoire)"
            textTransform="uppercase"
            value={patient.nom}
            onChange={(event) => handleChange("nom", event.target.value)}
            onBlur={(event) => handleBlur("nom", event.target.value)}
            autoComplete="sdfsfz"
          />
          <InputTexte
            label="Nom de naissance"
            textTransform="uppercase"
            value={patient.nom_jeune_fille}
            onChange={(event) =>
              handleChange("nom_jeune_fille", event.target.value)
            }
            onBlur={(event) =>
              handleBlur("nom_jeune_fille", event.target.value)
            }
            autoComplete="sdfsfz"
          />
          <InputTexte
            label="Prénom"
            textTransform="capitalize"
            value={patient.prenom}
            onChange={(event) => handleChange("prenom", event.target.value)}
            onBlur={(event) => handleBlur("Prénom", event.target.value)}
            autoComplete="qioeuaqdqdq"
          />
          <InputDate
            label="Date de naissance"
            value={patient.date_de_naissance}
            onBlur={(event) =>
              handleBlur("date_de_naissance", event.target.value)
            }
            onChange={(event) =>
              handleChange("date_de_naissance", event.target.value)
            }
            autoComplete="zqqdqzd"
          />
          <InputTexte
            label="Lieu de naissance"
            textTransform="uppercase"
            value={patient.lieu_naissance}
            onChange={(event) =>
              handleChange("lieu_naissance", event.target.value)
            }
            onBlur={(event) => handleBlur("lieu_naissance", event.target.value)}
            autoComplete="qioeuaqdqdq"
          />
          <div className="Titre2" style={{ marginTop: "60px" }}>
            Email, Téléphones
          </div>
          <InputEmail
            label="Email"
            placeholder="Email du patient"
            value={patient.email}
            onBlur={(event) => handleBlur("email", event.target.value)}
            onChange={(event) => handleChange("email", event.target.value)}
          />

          <EditionMailTelephones
            contact={patient.contact}
            onChange={(contact) => handleChange("contact", contact)}
            onBlur={handleBlur}
            onDeleteTelephone={(contact) => handleDeleteTelephone(contact)}
          />

          <div className="Titre2" style={{ marginTop: "40px" }} id="adresse">
            Adresse
          </div>
          <Adresse
            value={patient.adresse}
            onChange={(adresse) => handleChange("adresse", adresse)}
            onBlur={handleBlur}
            onSelect={(adresse) => handleSelectAdresse(adresse)}
          />

          <MessageErreur
            message={messageErreur}
            error={error}
            style={{ marginTop: "40px" }}
          />
          <MessageAlerte
            message={messageEnregistrement}
            style={{ marginTop: "40px" }}
          />
          {!patient._id && (
            <BoutonActionPrincipale
              texte="Créer le patient"
              taille="L"
              fullWidth
              style={{ marginTop: "40px" }}
              onClick={creerPatient}
              disabled={!patient.nom}
            />
          )}
        </div>

        <div className={MonStyle.CommentairesEtFamille}>
          <div id="commentaires">
            {patient._id && <Commentaires patient_id={idUrl} />}
          </div>
          <div id="famille">
            {patient._id && (
              <AffichageFamille
                patient={patient}
                onChange={(famille) => handleChange("famille", famille)}
                onBlur={handleBlur}
                onNbEnfantsChange={(famille) => handleBlur("famille", famille)}
                onBookmark={(famille) => handleBlur("famille", famille)}
              />
            )}
          </div>
        </div>
      </div>
      <div className={MonStyle.ObjetTracabilite}>
        <ObjetTracabilite
          tracabiliteCreation={patient.tracabilite_creation}
          tracabiliteModification={patient.tracabilite_modification}
          texteSupprimer="Supprimer le dossier"
          onDelete={supprimerPatient}
        />
      </div>
    </div>
  );
}
