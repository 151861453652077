import Avatar from "../../Design/Avatar";
import React, { useContext, useEffect, useState } from "react";
import MonStyle from "./ObjetCommentaire.module.css";
import { DateOuHeureToString } from "../../functions/FonctionsDateHeure";
import { AuthentificationContext } from "../../Data/AuthentificationContext";
import InputTexte from "../Formulaires/InputTexte";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import LockIcon from "@material-ui/icons/Lock";
import WarningIcon from "@material-ui/icons/Warning";
import BookmarkIcon from "@material-ui/icons/Bookmark";
import { couleurAction } from "../../functions/FonctionsCouleurs";
import BookmarkBorderIcon from "@material-ui/icons/BookmarkBorder";
const CommentaireModele = {
  texte: "",
  is_personnel: false,
  tracabilite_creation: {},
  tracabilite_modification: {},
};

export default function ObjetCommentaire({
  onChange,
  onClickSupprimer,
  onClickPersonnel,
  onBlur,
  id,
  commentaire,
  disabled,
  taille,
  style,
}) {
  //NES Gestion du state
  const [monCommentaire, setMonCommentaire] = useState({});
  const [utilisateur, setUtilisateur] = useState({});
  const [isDisable, setIsDisable] = useState(true);

  //NES Gestion du context
  const authContext = useContext(AuthentificationContext);

  //NES choix de la couleur de fond
  const getBackgroundColor = () => {
    let backgroundColor = "white";

    if (commentaire.is_important) {
      backgroundColor = "red";
    }

    if (commentaire.is_personnel) {
      backgroundColor = "#ff9800";
    }

    return backgroundColor;
  };

  const handleChange = (key, value) => {
    console.log("handleChange");

    let data = { ...monCommentaire, [key]: value };
    setMonCommentaire(data);
    if (onChange) {
      // onChange(data);
    }
  };

  const handleBlur = () => {
    if (onBlur) {
      onBlur(monCommentaire);
    }
  };

  const handleClickBookmark = () => {
    let data = {
      ...monCommentaire,
      is_bookmark: monCommentaire.is_bookmark ? false : true,
    };
    setMonCommentaire(data);
    if (onChange) {
      onChange(data);
    }
  };

  const handleClickPersonnel = () => {
    let data = {
      ...monCommentaire,
      is_personnel: monCommentaire.is_personnel ? false : true,
      is_bookmark: monCommentaire.is_personnel ? false : true,
      is_important: false,
    };
    setMonCommentaire(data);
    if (onChange) {
      onChange(data);
    }
  };

  const handleClicImportant = () => {
    let data = {
      ...monCommentaire,
      is_important: monCommentaire.is_important ? false : true,
      is_bookmark: monCommentaire.is_important ? false : true,
      is_personnel: false,
    };
    setMonCommentaire(data);
    if (onChange) {
      onChange(data);
    }
  };

  useEffect(() => {
    if (commentaire) {
      setMonCommentaire(commentaire);
      //NES je regarde si je suis l'auteur du commentaire
      if (
        commentaire.tracabilite_creation.utilisateur_id ===
        authContext.payload.utilisateur_id
      ) {
        setIsDisable(false);
      } else {
        setIsDisable(true);
      }
      //NES si j'ai une date de modification sinon c'est la création
      //NES finalement je n'affiche que la création
      setUtilisateur(commentaire.tracabilite_creation);
    } else {
      setMonCommentaire(CommentaireModele);
    }
    // eslint-disable-next-line
  }, [commentaire]);

  /// NES cas du commentaire personnel je n'affiche pas si je ne suis pas le créateur
  if (
    monCommentaire.is_personnel &&
    monCommentaire.tracabilite_creation.utilisateur_id !==
      authContext.payload.utilisateur_id
  ) {
    return "";
  }

  return (
    <div className={MonStyle.ObjetCommentaire} style={style} id={id}>
      <div
        id={"bulle_" + id}
        className={MonStyle.Bulle}
        style={{ backgroundColor: getBackgroundColor() }}
      >
        <InputTexte
          id={id}
          multiline
          theme={
            monCommentaire.is_personnel || monCommentaire.is_important
              ? "texteblanc"
              : "lecture"
          }
          value={monCommentaire.texte}
          disabled={disabled ? disabled : isDisable}
          onChange={(event) => handleChange("texte", event.target.value)}
          onBlur={handleBlur}
          iconeComposantMenu={
            monCommentaire.is_bookmark ? <BookmarkIcon /> : ""
          }
          menu={
            <div>
              {monCommentaire.is_bookmark ? (
                <MenuItem style={{ color: "" }} onClick={handleClickBookmark}>
                  <BookmarkBorderIcon
                    style={{ color: "#333", marginRight: "15px" }}
                  />
                  <ListItemText
                    primary="Ne plus épingler"
                    secondary="Ne sera plus affiché dans la synthèse"
                  />
                </MenuItem>
              ) : (
                <MenuItem style={{ color: "" }} onClick={handleClickBookmark}>
                  <BookmarkIcon
                    style={{ color: couleurAction(), marginRight: "15px" }}
                  />
                  <ListItemText
                    primary="Epingler"
                    secondary="Garder affiché dans la synthèse"
                  />
                </MenuItem>
              )}
              <MenuItem
                style={{ color: "black" }}
                onClick={handleClicImportant}
              >
                <WarningIcon style={{ color: "red", marginRight: "15px" }} />
                <ListItemText
                  primary="Important"
                  secondary="Affiché en rouge dans la synthèse"
                />
              </MenuItem>
              <MenuItem
                style={{ color: "#ff9800" }}
                onClick={handleClickPersonnel}
              >
                <LockIcon style={{ color: "", marginRight: "15px" }} />
                <ListItemText
                  primary="Personnel"
                  secondary="Visible que par vous"
                />
              </MenuItem>
              <MenuItem style={{ color: "red" }} onClick={onClickSupprimer}>
                <DeleteForeverIcon
                  style={{ color: "red", marginRight: "15px" }}
                />
                <ListItemText primary="Supprimer" />
              </MenuItem>
            </div>
          }
        />
      </div>

      {monCommentaire.is_personnel && taille !== "S" && (
        <div className={MonStyle.TextePersonnel}>
          <LockIcon style={{ marginRight: "5px" }} fontSize="small" />
          Personnel, vous seul pouvez voir le commentaire
        </div>
      )}

      {taille !== "S" && (
        <div className={MonStyle.BasCommentaire}>
          <Avatar
            taille="S"
            image={utilisateur.avatar_utilisateur_S}
            nom={utilisateur.nom_utilisateur}
            prenom={utilisateur.prenom_utilisateur}
          />

          <div className={MonStyle.Utilisateur}>
            <span
              style={{
                color: "#666",
                fontWeight: "700",
                marginLeft: "5px",
                fontSize: "14px",
              }}
            >
              {utilisateur.nom_utilisateur +
                " " +
                utilisateur.prenom_utilisateur}
              ,
            </span>
            <span
              style={{
                color: "#666",
                fontWeight: "300",
                marginLeft: "5px",
                fontSize: "14px",
              }}
            >
              {DateOuHeureToString(utilisateur.date)}
            </span>
          </div>
        </div>
      )}
    </div>
  );
}
