import React from "react";
import MonStyle from "./LigneResultatPatient.module.css";
import LigneResultat from "../LigneResultat";
import ObjetItem from "../../Objets/ObjetItem";
import ObjetPatient from "../../Objets/ObjetPatients";
import { formatterTelephone } from "../../../functions/Formatter";

//NES Affichage des téléphones
const AfficherTelephones = ({ telephones }) => {
  let telephonePrincipal = "";
  let telephoneSecondaire = "";
  if (telephones.length === 1) {
    telephonePrincipal = telephones[0].numero;
  }
  if (telephones.length === 2) {
    telephonePrincipal = formatterTelephone(telephones[0].numero);
    telephoneSecondaire = formatterTelephone(telephones[1].numero);
  }
  return (
    <ObjetItem
      principale={telephonePrincipal}
      secondaire={telephoneSecondaire}
    />
  );
};

export default function LigneResultatPatient({ patient }) {
  //NES Gestion des données
  // evite des erreurs de données nulles
  return (
    <LigneResultat key={patient._id} link={`/patients/${patient._id}`}>
      <div className={MonStyle.Ligne}>
        <div className={MonStyle.ColonnePatient}>
          <ObjetPatient patient={patient} />
        </div>
        <div className={MonStyle.ColonneTelephone}>
          <AfficherTelephones
            telephones={patient.contact ? patient.contact.telephones : ""}
          />
        </div>
        <div className={MonStyle.ColonneAdresse}>
          <div className={MonStyle.Rue}>
            {patient.adresse ? patient.adresse.rue : ""}
          </div>
          <div className={MonStyle.Ville}>
            {patient.adresse ? patient.adresse.ville : ""}
          </div>
        </div>
      </div>
    </LigneResultat>
  );
}
