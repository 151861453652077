import React from "react";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import Popover from "@mui/material/Popover";
import { Fragment } from "react";

const useStyles = makeStyles({
  root: {
    color: "#666666",
    border: "none",
    padding: "0 20px",
    fontFamily: "Lato",
    fontSize: "11px",
    fontWeight: "700",
    textTransform: "none",
    height: "60px",
    borderRadius: "5px",
  },
  disabled: {
    backgroundColor: "red",
  },
});

export default function BoutonDockMobile({
  label,
  iconecomposant,
  selected,
  link,
  disabled,
  onClick,
  menu,
  enLigne,
  ...props
}) {
  //NES gestion du theme du bouton
  let theme = {};
  //NES gestion du style
  const classes = useStyles(theme);

  //NES POPOVER
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const style = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-around",
    height: "100%",
    color: selected ? "#208AB0" : "",
  };
  const styleLigne = {
    display: "flex",
    minWidth: "150px",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex_start",
    height: "100%",
    color: selected ? "#208AB0" : "",
  };

  return (
    <Fragment>
      <Button
        className={classes.root}
        component={link ? Link : Button}
        to={link}
        disabled={disabled}
        onClick={menu ? handleOpen : onClick}
      >
        <div style={enLigne ? styleLigne : style}>
          <div className="Icone">{iconecomposant}</div>
          <div>{label}</div>
        </div>
      </Button>
      {menu && (
        <Popover
          open={anchorEl ? true : false}
          anchorEl={anchorEl}
          onClose={handleClose}
          onClick={handleClose}
          anchorOrigin={{
            vertical: "center",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "center",
            horizontal: "center",
          }}
        >
          <div>{menu}</div>
        </Popover>
      )}
    </Fragment>
  );
}
