import React, { useContext, useState } from "react";
import MonStyle from "./EnregistrementChoixCabinet.module.css";
import SurfacePopUp from "../../../../Design/Surfaces/SurfacePopUp";
import { CabinetsContext } from "../../../../Data/CabinetContext";
import ObjetCabinet from "../../../../Design/Objets/ObjetCabinet";
import CheckBox from "../../../../Design/Formulaires/CheckBox";
import BoutonActionPrincipale from "../../../../Design/Boutons/BoutonActionPrincipale";
import BoutonTexte from "../../../../Design/Boutons/BoutonTexte";
import { AuthentificationContext } from "../../../../Data/AuthentificationContext";
import MessageErreur from "../../../../Design/Messages/MessageErreur";

export default function EnregistrementChoixCabinet({ open, onClose }) {
  //NES Gestion du contexte
  const { cabinets, patchCabinets } = useContext(CabinetsContext);
  const authContext = useContext(AuthentificationContext);

  //NES Gestion du state
  const [selected, setSelected] = useState([]);
  const [messageErreur, setMessageErreur] = useState();
  const [error, setError] = useState();

  const handleSelect = (cabinet_id) => {
    console.log(selected);
    console.log(cabinet_id);
    console.log(selected.includes(cabinet_id));

    if (selected.includes(cabinet_id)) {
      //NES je l'enlève
      let tempArray = [...selected];
      tempArray = tempArray.filter((item) => item !== cabinet_id);
      console.log({ tempArray });
      setSelected(tempArray);
    } else {
      //NES je l'ajoute
      let tempArray = [...selected];
      tempArray.push(cabinet_id);
      setSelected(tempArray);
    }
  };

  //NES ajouter l'utilisateur aux cabinets sélectionnnées
  const ajouterUtilisateurAuxCabinets = async () => {
    selected.forEach((cabinet_id) => {
      let monCabinet = cabinets.find((item) => item._id === cabinet_id);
      //NES j'ajoute l'utilisateur à la liste des utilisateurs du cabinet
      let listeUtilisateurs = monCabinet.utilisateurs;
      listeUtilisateurs.push(authContext.payload.utilisateur_id);
      let newData = {
        utilisateurs: listeUtilisateurs,
      };
      patchCabinets(cabinet_id, newData)
        .then((reponse) => {
          if (reponse.code !== "OK") {
            setMessageErreur("Erreur lors de l'enregistrement");
          }
        })
        .catch((error) => {
          setMessageErreur("Erreur lors de l'enregistrement");
          setError(error);
        });
    });
    if (onClose) {
      onClose();
    }
  };

  return (
    <SurfacePopUp open={open} onClose={onClose} disableBackgroundClose>
      <div className="TitrePop">Choix du cabinet</div>
      <div className="SousTitre">
        Sélectionnez le (ou les) cabinet sur lequel vous travaillez.
      </div>
      <div>
        {cabinets.map((cabinet) => (
          <div
            className={
              selected.includes(cabinet._id)
                ? MonStyle.cabinetSelected
                : MonStyle.cabinet
            }
            onClick={() => handleSelect(cabinet._id)}
          >
            <CheckBox
              onChange={() => handleSelect(cabinet._id)}
              checked={selected.includes(cabinet._id)}
            />
            <ObjetCabinet cabinet={cabinet} />
          </div>
        ))}
        <BoutonTexte
          texte="Ajouter un cabinet"
          fullWidth
          style={{ marginBottom: "20px" }}
          link="/options/cabinets/new"
          onClick={onClose}
        />
        <MessageErreur
          message={messageErreur}
          error={error}
          style={{ marginBottom: "20px" }}
        />
        <BoutonActionPrincipale
          texte="Choisir"
          disabled={selected.length === 0 ? true : false}
          fullWidth
          taille="L"
          onClick={ajouterUtilisateurAuxCabinets}
        />
      </div>
    </SurfacePopUp>
  );
}
