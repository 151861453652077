import React from "react";
import InputTexte from "../../../../Design/Formulaires/InputTexte";
import ColorPicker from "../../../../Design/Pickers/ColorPicker";
import EventIcon from "@material-ui/icons/Event";
import DayPicker from "../../../../Design/Pickers/DayPicker";
import HorairesSemaine from "../components/HorairesSemaine";
import DureeRdv from "../components/DureeRdv";
import InputSelect from "../../../../Design/Formulaires/InputSelect";

export default function AgendaBlocAffichage({ agenda, onChange }) {
  //NES Quand on change une valeur
  const handleChange = (key, value) => {
    let newData = { ...agenda, [key]: value };
    //NES remonter l'état
    if (onChange) {
      onChange(newData);
    }
  };

  return (
    <div>
      <section>
        <h3>Agenda</h3>
        <p className="SousTitre">
          Le nom est utilisé dans les emails et sms de confirmation des
          rendez-vous
        </p>
        <InputTexte
          label="Quel est votre nom ?"
          value={agenda.nom_agenda}
          onChange={(event) => handleChange("nom_agenda", event.target.value)}
        />
        <ColorPicker
          couleurSelectionne={agenda.couleur_agenda}
          svgIcon={EventIcon}
          onCouleurChange={(couleur) => handleChange("couleur_agenda", couleur)}
          isCouleursBase={true}
        />
      </section>
      <section>
        <h3>Quels jours travaillez-vous?</h3>
        <DayPicker
          joursSelectionnes={agenda.jours_affiches}
          onChange={(liste) => handleChange("jours_affiches", liste)}
        />
      </section>
      <section>
        <h3>Vos horaires</h3>
        <p className="SousTitre">
          Vous horaires sont indicatifs, il est toujours possible de placer des
          rendez-vous manuellement en dehors de vos plages horaires.
        </p>
        <HorairesSemaine
          value={agenda.horaires_semaine}
          joursSelectionnes={agenda.jours_affiches}
          onChange={(horaires) => handleChange("horaires_semaine", horaires)}
        />
      </section>

      <section>
        <h3>Durée d'un rendez-vous?</h3>
        <p className="SousTitre">
          La plage horaire par défaut utilisé dans l'agenda est celle de la
          consultation
        </p>
        <DureeRdv
          types_rendez_vous={agenda.types_rendez_vous}
          onChange={(typeRdv) => handleChange("types_rendez_vous", typeRdv)}
        />
      </section>
      <section>
        <h3>Durée d'une case horaire</h3>
        <p className="SousTitre">
          Qu'elle est la durée du découpage d'une heure, ex: 20min
        </p>
        <InputSelect
          value={agenda.intervalle_minutes ? agenda.intervalle_minutes : 0}
          values={[0, 15, 20, 30, 45, 60]}
          options={[
            "Durée de la consultation",
            "15 minutes",
            "20 minutes",
            "30 minutes",
            "45 minutes",
            "60 minutes",
          ]}
          onChange={(e) => {
            handleChange("intervalle_minutes", e.target.value);
          }}
        />
      </section>
    </div>
  );
}
