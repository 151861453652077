import React, { useEffect, useState } from "react";
import MonStyle from "./AffichagePartPatient.module.css";
import DoneIcon from "@material-ui/icons/Done";
import BoutonTexte from "../../../../Design/Boutons/BoutonTexte";
import { TagPartPatient } from "../../../../Design/DataDisplay/Tags";

export default function AffichagePartPatient({ facture, onAfficherDetail }) {
  //NES gestion de l'icone

  //NES gestion du state
  const [icone, setIcone] = useState();

  useEffect(() => {
    if (facture) {
      //NES Si c'est totalement réglé
      if (facture.part_patient.montant_is_regle) {
        setIcone(<DoneIcon style={{ color: "0FA600" }} />);
      } else {
        setIcone();
      }
    }
  }, [facture]);

  return (
    <div>
      <div className={MonStyle.AffichagePartPatient}>
        <div>{icone}</div>
        <div>
          <TagPartPatient facture={facture} />
        </div>
        <div className="informationSecondaire"></div>

        <div>
          <BoutonTexte
            texte="Afficher le détail"
            taille="S"
            onClick={onAfficherDetail}
          />
        </div>
      </div>
    </div>
  );
}
