import React from "react";
import Avatar from "@material-ui/core/Avatar";
import Tooltip from "./DataDisplay/ToolTip";
import { makeStyles } from "@material-ui/core/styles";

/*
const AvatarColor = withStyles((theme) => ({
  root: {
    color: "white",
    backgroundColor: (props) =>
      props.couleur === "white" ? "#EDEDED20" : "#205A71",
    fontWeight: "500",
    textTransform: "uppercase",
    fontFamily: "Lato",
    borderColor: "white",
    width: "40px",
    height: "40px",
    "&:hover": {
      backgroundColor: (props) =>
        props.couleur === "white" ? "#EDEDED40" : "#205A71",
      cursor: "pointer",
    },
  },
}))(Avatar);
*/
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",

    "& > *": {
      margin: theme.spacing(1),
    },
  },
  bouton: {
    color: "white",
    backgroundColor: "#EDEDED20",
    fontWeight: "500",
    textTransform: "uppercase",
    fontFamily: "Lato",
    borderColor: "white",
    width: "40px",
    height: "40px",
    "&:hover": {
      backgroundColor: "#EDEDED40",
      cursor: "pointer",
    },
  },
  S: {
    color: "white",
    backgroundColor: "#205A7180",
    fontWeight: "700",
    textTransform: "uppercase",
    fontFamily: "Lato",
    fontSize: "12px",
    borderColor: "white",
    width: "24px",
    height: "24px",
    "&:hover": {
      backgroundColor: "#205A71",
      cursor: "pointer",
    },
  },
  M: {
    color: "white",
    backgroundColor: "#205A7180",
    fontWeight: "500",
    textTransform: "uppercase",
    fontFamily: "Lato",
    borderColor: "white",
    width: "40px",
    height: "40px",
    "&:hover": {
      backgroundColor: "#205A71",
      cursor: "pointer",
    },
  },
  L: {
    color: "white",
    backgroundColor: "rgb(32,90,113,.85)",
    fontWeight: "400",
    textTransform: "uppercase",
    fontFamily: "Lato",
    borderColor: "white",
    fontSize: "40px",
    width: "80px",
    height: "80px",
    "&:hover": {
      backgroundColor: "#205A71",
      cursor: "pointer",
    },
  },
}));

export default function MonAvatar({ nom, prenom, image, onClick, taille, id }) {
  //NES pour le tooltip
  let tooltip = prenom + " " + nom;

  //NES Gestion des initiales si pas d'images
  let initiales = "?";
  if (prenom && nom) {
    initiales = prenom.substr(0, 1) + nom.substr(0, 1);
  }

  //NES Gestion de la taille
  const classes = useStyles();
  let classeAvatar = classes.bouton;
  switch (taille) {
    case "bouton":
      classeAvatar = classes.bouton;
      break;
    case "S":
      classeAvatar = classes.S;
      break;
    case "M":
      classeAvatar = classes.M;
      break;
    case "L":
      classeAvatar = classes.L;
      break;
    default:
      classeAvatar = classes.M;
  }

  return (
    <Tooltip texte={tooltip}>
      <Avatar
        onClick={onClick}
        className={classeAvatar}
        style={{ cursor: onClick ? "pointer" : "default" }}
        src={image}
        id={id}
      >
        {initiales}
      </Avatar>
    </Tooltip>
  );
}
