import React from "react";
import MonStyle from "./SeparateurBloc.module.css";

export default function SeparateurBloc({ titre, style }) {
  return (
    <div className={MonStyle.SeparateurBloc} style={style}>
      {titre}
    </div>
  );
}
