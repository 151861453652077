import React, { useEffect, useContext } from "react";
import BoutonActionPrincipale from "../../../Design/Boutons/BoutonActionPrincipale";
import MonStyle from "./JoursEssai.module.css";
import IllustionEssai from "../../../Images/Illustrations/Aller.png";
import SurfacePapier from "../../../Design/Surfaces/SurfacePapier";
import { UtilisateursContext } from "../../../Data/UtilisateursContext";
import { useState } from "react";
import { capitalize, pluriel } from "../../../functions/Formatter";
import {
  DateToString,
  nombreJoursBetweenDates,
} from "../../../functions/FonctionsDateHeure";
import PopupEssaiTermine from "./PopupEssaiTermine";

export default function JoursEssai({ style }) {
  //NES gestion du contexte
  const { getMyOrganisation } = useContext(UtilisateursContext);

  //NES gestion du state
  const [afficher, setAfficher] = useState(false);

  const [open, setOpen] = useState(false);
  const [dateFinPeriodeEssai, setDateFinPeriodeEssai] = useState();
  const [titre, setTitre] = useState("");

  //USEEFFECT
  useEffect(() => {
    getMyOrganisation().then((reponse) => {
      //NES Affiche le bloc période d'essai si je n'ai pas de carte bancaire
      if (
        !reponse?.data?.stripe?.payment_method_valid &&
        !reponse?.data?.facturation?.id_derniere_facture
      ) {
        setAfficher(true);
      }

      let AUJOURDHUI = new Date();
      let dateFinEssai = new Date(
        reponse?.data?.facturation?.date_fin_periode_essai
      );

      let stringDateFinEssai = DateToString(dateFinEssai);

      setDateFinPeriodeEssai(stringDateFinEssai);
      let nb = nombreJoursBetweenDates(AUJOURDHUI, dateFinEssai);

      if (nb > 7) {
        setTitre(`Vous disposez de ${nb} jours d'essai`);
      } else {
        setTitre(
          `Il vous reste ${pluriel(nb, "jour d'essai", "jours d'essai")}`
        );
      }
      //NES si la période d'essai est dépassée
      if (nb <= 0) {
        setOpen(true);
      }
    });
    // eslint-disable-next-line
  }, []);

  if (!afficher) {
    return "";
  }

  return (
    <SurfacePapier style={style}>
      <div className={MonStyle.JoursEssai}>
        <img src={IllustionEssai} alt="" className="HideMobile" />
        <div className={MonStyle.Textes}>
          <h4>jusqu'au {dateFinPeriodeEssai} </h4>
          <h3>{titre}</h3>
          <p className={MonStyle.Description}>
            Vous pouvez utiliser toutes les fontions de{" "}
            <strong> {capitalize(process.env.REACT_APP_NOM)}</strong> sans
            restriction durant 30 jours à partir de la date de votre
            inscription. Vous n'avez qu'à renseigner votre carte de paiement
            pour terminer votre inscription. (Vous conservez bien sûr vos 30
            jours d'essais vous ne serez facturé qu'à partir du 2ème mois.){" "}
          </p>

          <BoutonActionPrincipale
            texte="Terminer mon inscription"
            style={{ marginTop: "20px" }}
            taille="L"
            link="/facturation/carte-bancaire"
          />
        </div>
      </div>
      <PopupEssaiTermine dateFinPeriode={dateFinPeriodeEssai} open={open} />
    </SurfacePapier>
  );
}
