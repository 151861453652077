import React, { useState, useContext, useEffect } from "react";
import MonStyle from "./AffichageEvenement.module.css";
import { AgendasContext } from "../../../../../Data/AgendasContext";
import {
  DateToHeure,
  setHeureFromString,
} from "../../../../../functions/FonctionsDateHeure";
import Popover from "@mui/material/Popover";
import EditionEvenement from "../Evenement/EditionEvenement";
import AutorenewIcon from "@material-ui/icons/Autorenew";
import { getFontColor } from "../../../../../functions/FonctionsCouleurs";

export default function AffichageEvenement({
  evenement,
  date,
  agenda_id,
  hauteurHeure,
}) {
  //NES gestion du contexte
  const {
    agendaSelected,
    heureFinAgendas,
    calculPositionTop,
    tailleRdvDefaut,
  } = useContext(AgendasContext);

  //NES Gestion du state
  const [top, setTop] = useState();
  const [height, setHeight] = useState(0);
  const [ligneSecondaire, setLigneSecondaire] = useState();

  //NES POPOVER
  const [anchorEl, setAnchorEl] = useState(null);
  const afficherEvenement = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  //NES calcul de la position top de l'évènement
  const calculTopEvenement = () => {
    let dateDebut = new Date(evenement.date_heure_debut);
    //NES Si l'èvènement commence un autre jour la position sera de 0 sinon on calcule

    if (dateDebut < date || evenement.is_toute_la_journee) {
      setTop(0);
      setLigneSecondaire("");
    } else {
      setTop(
        calculPositionTop(agenda_id, evenement.date_heure_debut, hauteurHeure)
      );
      let heureRdv = DateToHeure(dateDebut);
      setLigneSecondaire(heureRdv);
    }
  };

  //NES calcul de la hauteur de l'évèmement
  const calculHauteur = () => {
    let dateFinJournee = new Date(date);
    dateFinJournee.setHours(23);
    dateFinJournee.setMinutes(59);
    dateFinJournee.setSeconds(59);

    let dateDebut = new Date(evenement.date_heure_debut);
    let dateFin = new Date(evenement.date_heure_fin);
    let positionEnd = 0;
    let positionDebut = 0;

    //NES si la date de fin est un autre jour la hauteur est celle de l'heure max affichée
    if (dateFin > dateFinJournee || evenement.is_toute_la_journee) {
      dateFin = setHeureFromString(dateFin, heureFinAgendas);
      positionEnd =
        calculPositionTop(agenda_id, dateFin, hauteurHeure) + tailleRdvDefaut;
      positionDebut = top;

      setHeight(positionEnd - positionDebut);
    } else {
      positionEnd = calculPositionTop(agenda_id, dateFin, hauteurHeure);
      positionDebut = calculPositionTop(agenda_id, dateDebut, hauteurHeure);
      let hauteurCalcule = positionEnd - positionDebut - 2;
      if (hauteurCalcule < 20) {
        hauteurCalcule = 20;
      }
      setHeight(hauteurCalcule);
    }
  };

  useEffect(() => {
    calculTopEvenement();
    calculHauteur();
    // eslint-disable-next-line
  }, [evenement, agenda_id, tailleRdvDefaut, hauteurHeure]);

  //NES gestion dynamique du style
  const style = {
    top: top + "px",
    height: height + "px",
    minHeight: "20px",
    backgroundColor: evenement.couleur
      ? evenement.couleur
      : agendaSelected.couleur_agenda + "50",
    color: getFontColor(evenement.couleur),
  };

  //NES pour l'affichage
  if (!top) {
    return "";
  }

  return (
    <div>
      <div
        style={style}
        className={MonStyle.AffichageEvenement}
        onClick={afficherEvenement}
      >
        <div className={MonStyle.NomCompletEvenement}>
          {evenement.recurrence && (
            <AutorenewIcon
              style={{ color: agendaSelected.couleur_agenda }}
              fontSize="small"
            />
          )}
          <div className={MonStyle.NomEtHeureEvenement}>
            <div className={MonStyle.NomEvenement}>
              {evenement.nom_evenement}
            </div>
            <div className={MonStyle.HeureEvenement}>{ligneSecondaire}</div>
          </div>
        </div>
      </div>
      <Popover
        open={anchorEl ? true : false}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        <EditionEvenement
          agenda_id={agenda_id}
          onClose={handleClose}
          evenement={evenement}
        />
      </Popover>
    </div>
  );
}
