import React from "react";
import MonStyle from "./ObjetDemande.module.css";
import ObjetItem from "./ObjetItem";
import {
  DateShortOuHeureToString,
  nombreJoursBetweenDates,
} from "../../functions/FonctionsDateHeure";
import IconesProgression from "../Icones/IconesProgression";
import EtatDemande from "../../Pages/Support/Components/EtatDemande/EtatDemande";

export default function ObjetDemande({
  demande,
  taille,
  afficherDate,
  onClick,
}) {
  //NES CONSTANTES
  const AUJOURDHUI = new Date();
  const STYLE_ARCHIVE = { color: "#97989D", textDecoration: "line-through" };

  const getProchaineEtape = () => {
    let etapeActive = demande?.progression?.etape_active;

    if (etapeActive === 0) {
      etapeActive = 1;
    }

    //console.log(demande?.progression);

    let prochaineEtape = demande?.progression?.etapes[etapeActive];
    return prochaineEtape?.code;
  };

  const getCouleurPriorite = () => {
    switch (demande?.priorite) {
      case "FAIBLE":
        return "#0FA600";
      case "RAPIDE":
        return "orange";
      case "URGENT":
        return "red";
      default:
        return "#DDD";
    }
  };

  if (taille === "L") {
    return (
      <div className={MonStyle.ObjetDemandeL} onClick={onClick}>
        <div
          className={MonStyle.Icone}
          style={{
            borderLeft: "3px solid " + getCouleurPriorite(),
            color: "#205A71",
          }}
        >
          <IconesProgression
            code={getProchaineEtape()}
            style={demande?.is_archive ? STYLE_ARCHIVE : {}}
          />
        </div>
        <div className={MonStyle.Demande}>
          <div
            className="InformationPrincipale"
            style={demande?.is_archive ? STYLE_ARCHIVE : {}}
          >
            {demande?.objet}
          </div>
          <div
            className="InformationSecondaire"
            style={demande?.is_archive ? STYLE_ARCHIVE : { color: "#205A71" }}
          >
            {demande?.description}
          </div>
          <div className="InformationSecondaire">
            {demande?.dernier_message !== demande?.description
              ? demande?.dernier_message
              : ""}
          </div>
        </div>

        <div className={MonStyle.EtatDemande}>
          <EtatDemande etat={demande?.etat} />
          {afficherDate && (
            <div className="InformationSecondaire">
              {"+ " +
                nombreJoursBetweenDates(
                  demande?.date_previsionnelle?.date,
                  AUJOURDHUI
                ) +
                " jours"}
            </div>
          )}
        </div>
      </div>
    );
  }

  return (
    <div className={MonStyle.ObjetDemande}>
      <div
        style={{
          minWidth: "20px",
          paddingTop: "6px",
          marginRight: "5px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "center",
          gap: "10px",
          //backgroundColor: "powderblue",
        }}
      >
        {demande?.is_non_lue && (
          <div
            style={{
              width: "10px",
              minHeight: "10px",
              borderRadius: "10px",
              backgroundColor: "#208AB0",
            }}
          >
            {" "}
          </div>
        )}
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "flex-start",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <ObjetItem
          isBold={demande?.is_non_lue}
          principale={demande?.objet}
          secondaire={demande?.dernier_message}
          nbLigneSecondaires={3}
        />
        <div
          style={{
            paddingTop: "3px",
            paddingLeft: "10px",
            //backgroundColor: "orange",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
            boxSizing: "border-box",
            justifyContent: "flex-start",
            minWidth: "90px",

            gap: "5px",
          }}
        >
          <div className="Label">
            {DateShortOuHeureToString(
              demande?.tracabilite_creation?.date_tracabilite
            )}
          </div>
          <EtatDemande etat={demande?.etat} />
        </div>
      </div>
    </div>
  );
}
