import React, { useContext, useEffect, useState } from "react";
//import MonStyle from "./PageOptionsConstantes.module.css";
import SurfacePapier from "../../../../Design/Surfaces/SurfacePapier";
import BoutonActionSecondaire from "../../../../Design/Boutons/BoutonActionSecondaire";
import { ConstantesContext } from "../../../../Data/ConstantesContext";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import MenuAction from "../../../../Design/Navigation/BoutonMenu";
import { Link } from "react-router-dom";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import ObjetConstante from "../../../../Design/Objets/ObjetConstante";
import BoutonConfirmerSuppression from "../../../../Design/Boutons/BoutonConfirmerSuppression";
import MessageErreur from "../../../../Design/Messages/MessageErreur";

export default function PageOptionsConstantes() {
  //NES gestion du constexte
  const { getMesures, mesures, deleteMesure } = useContext(ConstantesContext);

  //NES Gestion du state
  const [deleteId, setDeleteId] = useState();
  const [erreur, setErreur] = useState("");
  const [error, setError] = useState("");

  //USEEFFECT récupération des constantes
  useEffect(() => {
    getMesures();
    // eslint-disable-next-line
  }, []);

  ///onDelete
  const onDelete = () => {
    deleteMesure(deleteId).then((reponse) => {
      setDeleteId();
      if (reponse.code === "OK") {
        setErreur("");
        setError("");
      } else {
        setErreur("Erreur lors de la suppression de la constante");
        setError(reponse);
      }
    });
  };

  return (
    <SurfacePapier>
      <header>
        <h2>Mesures & constantes</h2>
        <BoutonActionSecondaire
          texte="Ajouter une constante"
          link="/options/constantes/new"
        />
      </header>
      <MessageErreur message={erreur} error={error} />
      <List>
        {mesures.map((mesure) => (
          <div key={mesure._id} className="ListeLigne">
            <ObjetConstante constante={mesure} />
            <BoutonConfirmerSuppression
              onSupprimer={onDelete}
              onAnnuler={() => setDeleteId()}
              visible={mesure._id === deleteId}
              fullWidth
              style={{
                position: "absolute",
                right: "0px",
                backgroundColor: "white",
                zIndex: 2,
              }}
            />
            <MenuAction tooltip="Modifier,supprimer">
              <ListItem
                button
                component={Link}
                to={"/options/constantes/" + mesure._id}
              >
                <EditIcon className="IconeMenu" />
                <ListItemText primary="Modifier" />
              </ListItem>
              <ListItem
                button
                style={{ color: "red" }}
                onClick={() => setDeleteId(mesure._id)}
              >
                <DeleteIcon className="IconeMenu" style={{ color: "red" }} />
                Supprimer
              </ListItem>
            </MenuAction>
          </div>
        ))}
      </List>
    </SurfacePapier>
  );
}
