import React, { useContext, useState, useEffect } from "react";
import HistoryIcon from "@material-ui/icons/History";
import { Link, useHistory } from "react-router-dom";
import BoutonRond from "../../Design/Boutons/BoutonRond";
import CloseIcon from "@material-ui/icons/Close";
import MonStyle from "./BarreOngletsPatients.module.css";
import { PatientsContext } from "../../Data/PatientsContext";
import ObjetPatient from "../../Design/Objets/ObjetPatients";
import LoaderCirculaire from "../../Design/Progression/LoaderCirculaire";
import { couleurPrincipale } from "../../functions/FonctionsCouleurs";

function OngletPatientsRecents({ selected }) {
  return (
    <Link to="/patientsrecents" className={MonStyle.OngletPatientsRecents}>
      <div
        className={
          selected ? MonStyle.styleOngletActif : MonStyle.styleOngletInactif
        }
        style={{
          width: "30px",
          padding: "0px 10px",
          backgroundColor: selected ? "#f2f1f5" : "",
        }}
        id="onglet_patients_recents"
      >
        <HistoryIcon />
      </div>
    </Link>
  );
}

function OngletLoading({ open }) {
  if (!open) {
    return "";
  }

  return (
    <div className={MonStyle.styleOngletLoading}>
      <ObjetPatient
        iconeOpacity={1}
        taille="L"
        //patient={patient}
      />
      <div style={{ color: "#00000080" }}>chargement...</div>
      <div style={{ marginLeft: "5px", minWidth: "40px" }}>
        <LoaderCirculaire
          isLoading={true}
          size={30}
          color={couleurPrincipale()}
        />
      </div>
    </div>
  );
}

function OngletPatient({ selected, patient, id, onClose }) {
  return (
    <div
      className={
        selected ? MonStyle.styleOngletActif : MonStyle.styleOngletInactif
      }
      id={
        "onglet_" +
        patient?.nom.toLowerCase() +
        "_" +
        patient?.prenom.toLowerCase()
      }
    >
      <Link
        to={"/patients/" + id}
        style={{ color: selected ? "black" : "#DDDDDD" }}
      >
        <ObjetPatient
          iconeOpacity={selected ? 1 : 0.4}
          taille="L"
          patient={patient}
        />
      </Link>
      <div
        style={{
          marginLeft: "5px",
          minWidth: "40px",
          //backgroundColor: "yellow",
        }}
      >
        <BoutonRond
          iconeComposant={<CloseIcon />}
          tooltip="Fermer l'onglet"
          couleurIcone={selected ? "noir" : "#FFFFFF50"}
          couleur="none"
          onClick={() => onClose(id)}
          id={
            "bouton_close_" +
            patient?.nom.toLowerCase() +
            "_" +
            patient?.prenom.toLowerCase()
          }
        />
      </div>
    </div>
  );
}

export default function BarreOngletPatient({ idUrl, isLoading }) {
  //NES Gestion du routage pour faire des redirections
  let history = useHistory();

  //NES gestion du contexte
  const { ongletsPatients, closePatientContext } = useContext(PatientsContext);

  //NES gestion du du contexte
  const [afficherOngletLoading, setAfficherOngletLoading] = useState(false);

  //USEEFFECT
  useEffect(() => {
    if (isLoading) {
      let index = ongletsPatients.findIndex((item) => item._id === idUrl);
      if (index === -1) {
        setAfficherOngletLoading(true);
      }
    } else {
      setAfficherOngletLoading(false);
    }
  }, [isLoading, ongletsPatients, idUrl]);

  //NES fermer onglet
  const fermerOnglet = (id) => {
    if (id === idUrl) {
      //NES Avant de supprimer l'onglet  je calcule sur quel onglet je positionne l'utilisateur
      let newUrl = "/patientsrecents";
      let tailleOnglets = ongletsPatients.length;
      let indexOnglet = ongletsPatients.findIndex(
        (onglet) => onglet._id === id
      );
      if (tailleOnglets === 1) {
        newUrl = "/patientsrecents";
      } else {
        if (indexOnglet === tailleOnglets - 1) {
          //NES c'est le dernier dans ce cas j'affiche le précédent
          let newOnglet = ongletsPatients[indexOnglet - 1];
          newUrl = "/patients/" + newOnglet._id;
        } else {
          //NES j'affiche l'onglet suivant
          let newOnglet = ongletsPatients[indexOnglet + 1];
          newUrl = "/patients/" + newOnglet._id;
        }
      }
      history.push(newUrl);
      closePatientContext(id);
    } else {
      //NES ce n'est pas l'onglet sélectionné alors go!
      closePatientContext(id);
    }
  };

  return (
    <div className={MonStyle.BarreOngletPatient}>
      <OngletPatientsRecents
        selected={idUrl === "patientsrecents" ? true : false}
      />
      {/* Onglets patients */}
      {ongletsPatients.map((patient) => (
        <OngletPatient
          selected={idUrl === patient._id ? true : false}
          patient={patient}
          id={patient._id}
          onClose={fermerOnglet}
          key={patient._id}
        />
      ))}

      <OngletLoading open={afficherOngletLoading} />
    </div>
  );
}
