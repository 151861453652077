import React, { useEffect, useState, createRef } from "react";
import "./App.css";
import { AuthentificationContext } from "./Data/AuthentificationContext";
import Jwt from "jsonwebtoken";
import RoutesAuthentifies from "./Routes/RoutesAuthentifies";
import RoutesPubliques from "./Routes/RoutesPubliques";
import PopupDeconnexionAuto from "./Components/PopupDeconnexionAuto/PopupDeconnexionAuto";
import ImageBackground from "./Images/fond.jpg";
import { useScreenshot } from "use-react-screenshot";
import { AdpxProvider } from "./Data/AdpxContext";
import { POST } from "./functions/FonctionsApi";
import { UtilisateursProvider } from "./Data/UtilisateursContext";
import { PerformancesProvider } from "./Data/PerformancesContext";

function App() {
  //NES pour la déconnexion automatique 90s secondes dans la popup et 45 min avant d'afficher la popup
  const tempsDeconnexion = 90 + 1;
  const tempsInactiviteMax = 60 * 45;
  let secondesInactivite = 0;
  let secondesDeconnexion = tempsDeconnexion;
  //NES je ne peux pas accéder au state dans certains cas et l'inverse, je n'ai pas trouvé d'autres solutions pour l'instant
  let idIntervalInactiveForApp = null;
  const [idIntervalInactive, setidIntervalInactive] = useState(null);
  const [dureeDeconnexion, setDureeDeconnexion] = useState(secondesDeconnexion);
  //NES popup de déconnexion auto
  const [afficherPopupDeconnexion, setAfficherPopupDeconnexion] =
    useState(false);

  //NES Gestion du state lié à la connexion à l'application
  const [authentification, setAuthentification] = useState({
    isConnecte: false,
    token: null,
    payload: null,
    dataConnexion: null,
  });

  const upTimerInactivite = () => {
    //console.log("upTimerInactivite" + secondesInactivite);
    //NES on augmente la durée d'inactivité.
    secondesInactivite = secondesInactivite + 1;
    //NES si la durée est atteinte on affiche un écran de déconnexion bientot
    if (secondesInactivite >= tempsInactiviteMax) {
      console.log("Popup déconnexion ");
      setAfficherPopupDeconnexion(true);
      //NES on calcule la durée de déconnexion
      secondesDeconnexion = secondesDeconnexion - 1;
      setDureeDeconnexion((dureeDeconnexion) => secondesDeconnexion);
      //NES on déconnection automatiquement
      if (secondesDeconnexion === 0) {
        console.log("Déconnexion !!!!!");
        stopTimerInactivite();
        Deconnexion();
      }
    }
  };

  const startTimerInactivite = () => {
    if (!idIntervalInactive && !idIntervalInactiveForApp) {
      let id = setInterval(upTimerInactivite, 1000);
      idIntervalInactiveForApp = id;
      setidIntervalInactive(idIntervalInactiveForApp);
    }
  };

  const resetTimerInactivite = () => {
    secondesInactivite = 0;
    secondesDeconnexion = tempsDeconnexion;
    setAfficherPopupDeconnexion(false);
  };

  const stopTimerInactivite = () => {
    if (idIntervalInactiveForApp) {
      clearInterval(idIntervalInactiveForApp);
    } else {
      clearInterval(idIntervalInactive);
    }
    idIntervalInactiveForApp = null;
    setidIntervalInactive(idIntervalInactiveForApp);
    return true;
  };

  const handleEventListener = () => {
    //NES Me permet de remettre le compteur d'inactivité à 0 si je suis connecté
    if (idIntervalInactiveForApp) {
      resetTimerInactivite();
    }
  };

  //NES détection de l'activité pour remettre le compteur de déonnexion auto à 0s
  document.addEventListener("mousemove", handleEventListener);
  document.addEventListener("touchstart", resetTimerInactivite);
  document.addEventListener("click", resetTimerInactivite);

  const autoRefreshToken = () => {
    let tokenFromStorage = localStorage.getItem("token");
    if (tokenFromStorage) {
      const MonUrl = `${process.env.REACT_APP_URL_API_UTILISATEURS}/authentification/autorefresh/`;
      POST(MonUrl, tokenFromStorage).then((reponse) => {
        console.log("autoRefreshToken", reponse);
        if (reponse.code === "OK") {
          Connexion(reponse.data.token);
        } else {
          Deconnexion();
        }
      });
    }
  };

  const Connexion = async (token, data) => {
    let value = {
      isConnecte: true,
      token: token,
      payload: Jwt.decode(token),
      dataConnexion: data,
    };

    //NES Sauvegarde dans le localstorage + Maj State
    localStorage.setItem("token", token);
    setAuthentification(value);
    //NES on démarre le timer d'inactivé permettant la déconnexion auto
    startTimerInactivite();

    return value;
  };

  const Deconnexion = () => {
    let value = {
      isConnecte: false,
      token: null,
      payload: null,
    };
    //NES suppression du localstorage + Maj State
    localStorage.removeItem("token");
    //NES suppression du cabinet dans le localstorage
    localStorage.removeItem("cabinet");
    setAuthentification(value);
    //NES On arrete le timer d'inactivité
    stopTimerInactivite();
  };

  const ConnexionAuto = () => {
    //NES Récupération du token dans le localstorage
    let token = localStorage.getItem("token");
    if (token) {
      //NES Lecture de l'expiration
      let monpayload = Jwt.decode(token);
      let dateExpiration = new Date(monpayload.exp * 1000);
      let dateMaintenant = new Date(Date.now());
      if (dateExpiration > dateMaintenant) {
        Connexion(token);
      } else {
        Deconnexion();
      }
    }
  };

  useEffect(() => {
    if (process.env.REACT_APP_NOM === "SINOBI") {
      //NES favicon
      let favicon = document.getElementById("favicon");
      favicon.href = "faviconSinobi-32x32.png";
      favicon = document.getElementById("favicon");

      //NES Title
      let title = document.getElementById("title");
      title.innerText = "Sinobi";

      //NES Icone apple touch
      let appleTouchIcon = document.getElementById("apple-touch-icon");
      appleTouchIcon.href = "apple-touch-sinobi-icon.png";

      //NES Manifest
      let manifest = document.getElementById("manifest");
      manifest.href = "/manifestSinobi.json";
    }

    //NES à la connexion, on regarde si il y a un token dans le localstorage, si oui on connecte
    ConnexionAuto();
    // eslint-disable-next-line
  }, []);

  //NES STYLE SINOBI
  const styleSinobi = {
    backgroundImage: `url(${ImageBackground})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "100% 160px",
    backgroundColor: "#283B49",
    borderLeft:
      window.location.hostname === "localhost" ? "2px solid orange" : "",
    maxHeight: window.innerHeight + "px",
    overflow: "hidden",
  };
  //NES STYLE BE
  const styleBe = {
    backgroundImage:
      "radial-gradient(circle at 70% 40%, #218fb7 0%, #20343f 90%)",
    borderLeft:
      window.location.hostname === "localhost" ? "2px solid orange" : "",
  };

  //NES Impression d'écran
  const refApp = createRef(null);
  const [image, takeScreenshot] = useScreenshot({ quality: 0.8 });
  const getCaptureEcran = () => {
    //takeScreenshot(refApp.current);
    takeScreenshot(document.body);
  };

  return (
    <div
      ref={refApp}
      id="App"
      className="App"
      style={process.env.REACT_APP_NOM === "SINOBI" ? styleSinobi : styleBe}
    >
      <PopupDeconnexionAuto
        open={afficherPopupDeconnexion}
        tempsRestant={dureeDeconnexion}
        onClick={() => setAfficherPopupDeconnexion(false)}
      />

      <AuthentificationContext.Provider
        value={{
          isConnecte: authentification.isConnecte,
          payload: authentification.payload,
          token: authentification.token,
          dataConnexion: authentification.dataConnexion,
          Connexion: Connexion,
          Deconnexion: Deconnexion,
          autoRefreshToken: autoRefreshToken,
          getCaptureEcran: getCaptureEcran,
          captureEcran: image,
        }}
      >
        <PerformancesProvider>
          <AdpxProvider>
            <UtilisateursProvider>
              {!authentification.isConnecte ? (
                <RoutesPubliques />
              ) : (
                <RoutesAuthentifies />
              )}
            </UtilisateursProvider>
          </AdpxProvider>
        </PerformancesProvider>
      </AuthentificationContext.Provider>
    </div>
  );
}
export default App;
