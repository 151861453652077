import React, { useContext, useEffect, useState } from "react";
import { RdvContext } from "../../../../../Data/RdvContext";
import { nombreJoursBetweenDates } from "../../../../../functions/FonctionsDateHeure";
import AffichageEvenementTouteLaJournee from "./AffichageEvenementTouteLaJournee";
import { AgendasContext } from "../../../../../Data/AgendasContext";

export default function AffichageGrilleTouteLaJournee({ agenda_id }) {
  //NES gestion du context
  const { evenements, getVacancesScolaires, getInterSaisons } =
    useContext(RdvContext);
  const { datesSemaine, agendaSelected } = useContext(AgendasContext);

  //NES gestion du state
  const [liste, setListe] = useState([]);
  const [nbLignes, setNbLignes] = useState(0);
  const [vacances, setVacances] = useState([]);
  const [interSaisons, setInterSaisons] = useState([]);

  //USEEFFECT récupération des vacances
  useEffect(() => {
    //NES affichage des vacances scolaires
    let academie = agendaSelected?.donnees_externes?.vacances_scolaire_academie;

    if (academie) {
      getVacancesScolaires(datesSemaine, academie).then((evenements) => {
        setVacances(evenements);
      });
    } else {
      setVacances([]);
    }
    //NES affichage des intersaisons chinoises

    if (agendaSelected?.donnees_externes?.sinobi_intersaisons_is_affiche) {
      getInterSaisons(datesSemaine).then((evenements) => {
        setInterSaisons(evenements);
      });
    } else {
      setInterSaisons([]);
    }
    // eslint-disable-next-line
  }, [agendaSelected, datesSemaine]);

  //NES pour chaque evenement toute la journée je vais calculer la largeur, la position le style...
  useEffect(() => {
    if (datesSemaine && evenements.length > 1) {
      let dateDebutAffiche = new Date(datesSemaine[0]);
      let dateFinAffiche = new Date(datesSemaine[datesSemaine.length - 1]);
      let tempArray = [];
      let maxTop = 0;

      //NES je ne garde que les evenements toute la journée
      let listeEvenements = evenements.filter(
        (item) => item.is_toute_la_journee === true
      );

      //NES j'affiche les vacances
      listeEvenements = listeEvenements.concat(vacances);

      //NES j'affiche les intersaisons
      listeEvenements = listeEvenements.concat(interSaisons);
      listeEvenements.forEach((evenement, index) => {
        let newEvenementWithPosition = evenement;
        let style = {};

        //NES je crée mes dates pour les comparaisons
        let dateEvenementDebut = new Date(evenement.date_heure_debut);
        dateEvenementDebut.setHours(0);
        dateEvenementDebut.setMinutes(0);
        dateEvenementDebut.setSeconds(0);
        dateEvenementDebut.setMilliseconds(0);

        let dateEvenementFin = new Date(evenement.date_heure_fin);
        dateEvenementFin.setHours(0);
        dateEvenementFin.setMinutes(0);
        dateEvenementFin.setSeconds(0);
        dateEvenementFin.setMilliseconds(0);

        //NES calcul de la position départ
        if (dateEvenementDebut < dateDebutAffiche) {
          style = { ...style, left: 0, top: index * 20 };
        } else {
          //NES je vais chercher la case horaire correspondante
          var element = document.getElementById(dateEvenementDebut);
          if (element) {
            style = { ...style, left: element.offsetLeft };
          }
        }

        //NES calcul de la largeur
        element = document.getElementById(dateDebutAffiche);
        let largeurCase = element.offsetWidth;

        //NES le debut et la fin sont en dehors de la plage de date
        if (
          dateEvenementDebut < dateDebutAffiche &&
          dateEvenementFin > dateFinAffiche
        ) {
          style = { ...style, width: "100%", borderRadius: "0px" };
        }

        //NES le début seulement est en dehors de la plage de date
        if (
          dateEvenementDebut < dateDebutAffiche &&
          dateEvenementFin <= dateFinAffiche
        ) {
          let largeur = nombreJoursBetweenDates(
            dateDebutAffiche,
            dateEvenementFin
          );
          largeur = largeur * largeurCase;
          style = {
            ...style,
            width: largeur,
            borderRadius: "0px 10px 10px 0px",
          };
        }

        //NES la fin seulement est en dehors de la plage de date
        if (
          dateEvenementDebut >= dateDebutAffiche &&
          dateEvenementFin > dateFinAffiche
        ) {
          let largeur = nombreJoursBetweenDates(
            dateEvenementDebut,
            dateFinAffiche
          );
          largeur = largeur * largeurCase;
          style = {
            ...style,
            width: largeur,
            borderRadius: "10px 0px 0px 10px",
          };
        }

        //NES le début et la fin sont dans la plage de date
        if (
          dateEvenementDebut >= dateDebutAffiche &&
          dateEvenementFin <= dateFinAffiche
        ) {
          let largeur = nombreJoursBetweenDates(
            dateEvenementDebut,
            dateEvenementFin
          );
          largeur = largeur * largeurCase;
          style = { ...style, width: largeur, borderRadius: "10px" };
        }

        //NES calcul du top
        let newTop = 0;

        tempArray.forEach((item) => {
          let dateItemDebut = new Date(item.date_heure_debut);
          dateItemDebut.setHours(0);
          dateItemDebut.setMinutes(0);
          dateItemDebut.setSeconds(0);
          dateItemDebut.setMilliseconds(0);
          let dateItemFin = new Date(item.date_heure_fin);
          dateItemFin.setHours(0);
          dateItemFin.setMinutes(0);
          dateItemFin.setSeconds(0);
          dateItemFin.setMilliseconds(0);

          if (
            //NES mon événement commence alors qu'il y a déja un évévement à cette date
            (dateItemDebut >= dateEvenementDebut &&
              dateItemFin <= dateEvenementDebut) ||
            //NES mon évènement termine alors qu'il y a déjà un évenement à cette date
            (dateEvenementFin >= dateItemDebut &&
              dateEvenementFin <= dateItemFin) ||
            (dateEvenementDebut >= dateItemDebut &&
              dateEvenementDebut <= dateItemFin) ||
            (dateItemDebut >= dateEvenementDebut &&
              dateItemDebut <= dateEvenementFin)
          ) {
            if (item.style.top >= newTop) {
              newTop = item.style.top + 1;
              //NES Pour remonter le nombre de lignes affichées
              if (newTop > maxTop) {
                maxTop = newTop;
              }
            }
          }
        });

        style = { ...style, top: newTop };

        //NES je stocke le style dans l'objet
        newEvenementWithPosition = {
          ...newEvenementWithPosition,
          style: style,
        };

        tempArray.push(newEvenementWithPosition);
      });

      setListe(tempArray);
      setNbLignes(maxTop + 1);
    }
    // eslint-disable-next-line
  }, [evenements, vacances, datesSemaine]);

  return (
    <div
      style={{
        display: "flex",
        boxSizing: "border-box",
        overflow: "hidden",
        position: "relative",
        //border: "1px solid black",
        height: nbLignes * 22,
      }}
    >
      {datesSemaine.map((jour, index) => (
        <div
          style={{
            flex: 1,
            //border: "1px solid #DDD",
            boxSizing: "border-box",
          }}
          key={jour}
          id={jour}
        >
          {""}
        </div>
      ))}

      {liste.map((evenement) => (
        <AffichageEvenementTouteLaJournee
          agenda_id={agenda_id}
          key={evenement._id}
          evenement={evenement}
          style={{
            display: "flex",
            boxSizing: "border-box",
            overflow: "hidden",
            position: "absolute",
            top: evenement.style.top * 22,
            left: evenement.style.left + "px",
            width: evenement.style.width,
            borderRadius: evenement.style.borderRadius,
            padding: "0px",
            minWidth: "content",
            height: "20px",
          }}
        />
      ))}
    </div>
  );
}
