export function isSinobi() {
  if (process.env.REACT_APP_NOM === "SINOBI") {
    return true;
  } else {
    return false;
  }
}

export function isBe() {
  if (process.env.REACT_APP_NOM === "BE") {
    return true;
  } else {
    return false;
  }
}
