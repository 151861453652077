import React, { useState, useContext } from "react";
import EmailIcon from "@material-ui/icons/Email";
import MonStyle from "./BlocEmail.module.css";
import InputEmail from "../../../../../Design/Formulaires/InputEmail";
import { PatientsContext } from "../../../../../Data/PatientsContext";
import IconeErreur from "../../../../../Design/Erreurs/IconeErreur";
export default function BlocEmail({ patient_id, value, onChange, onBlur }) {
  //NES Gestion du contexte
  const { patchPatient } = useContext(PatientsContext);

  //NES Gestion du state
  const [messageErreur, setMessageErreur] = useState();
  const [error, setError] = useState();

  //NES lorsque je quitte le champ email je mets à jour le patient :)
  const handleBlur = (e) => {
    if (patient_id) {
      //PATCH
      let data = { email: e.target.value };
      patchPatient(patient_id, data)
        .then((reponse) => {
          setMessageErreur();
          setError();
          if (reponse.code === "OK") {
            //NES on retourne l'évènement au composant parent
            if (onBlur) {
              onBlur(e);
            }
          } else {
            setMessageErreur("Erreur lors de la mise à jour du patient");
          }
        })
        .catch((error) => {
          setMessageErreur("Erreur lors de la mise à jour du patient");
          setError(error);
        });
    }
  };

  return (
    <div className={MonStyle.BlocEmail}>
      <EmailIcon className="IconeObjet" style={{ padding: "0px" }} />
      <InputEmail
        theme="lecture"
        placeholder="Email"
        value={value}
        onChange={onChange}
        onBlur={handleBlur}
      />
      <IconeErreur message={messageErreur} error={error} />
    </div>
  );
}
