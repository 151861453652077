import React from "react";
import MonStyle from "./SurfacePapier.module.css";
import { isMobile } from "../../functions/Navigateur";

/*

export default function SurfacePapier({
  couleur,
  contentWidth,
  onClick,
  style,
  disablePadding,
  disablePaddingOnMobile,
  id,
  ...props
}) {
  return (
    <div
      id={id}
      className={
        disablePaddingOnMobile
          ? MonStyle.SurfacePapierMobile
          : MonStyle.SurfacePapier
      }
      style={{
        background: couleur === "gris" ? "#F6F6F6" : "white",
        padding: disablePadding ? "0px" : "",
        width: contentWidth ? "fit-content" : "",
        ...style,
      }}
      onClick={onClick}
    >
      {props.children}
    </div>
  );
}

*/

//NES forwardRef à cause du composant Modal de matérial UI
const SurfacePapier = React.forwardRef((props, ref) => (
  <div
    id={props.id}
    className={props.className ? props.className : MonStyle.SurfacePapier}
    style={{
      background: props.couleur === "gris" ? "#F6F6F6" : "white",
      padding:
        props.disablePadding || (isMobile() && props.disablePaddingOnMobile)
          ? "0px"
          : "",
      width: props.contentWidth ? "fit-content" : "",
      ...props.style,
    }}
    onClick={props.onClick}
  >
    {props.children}
  </div>
));

export default SurfacePapier;
