import React, { useEffect, useState } from "react";
import Slide from "@material-ui/core/Slide";
import CircularProgress from "@material-ui/core/CircularProgress";
import SmsIcon from "@material-ui/icons/Sms";
import MonStyle from "./Notification.module.css";
import BoutonRond from "../../Design/Boutons/BoutonRond";
import CloseIcon from "@material-ui/icons/Close";
import AppTooltip from "../../Design/DataDisplay/ToolTip";
import { Link } from "react-router-dom";
import SvgIcon from "@material-ui/core/SvgIcon";
import EmailIcon from "@material-ui/icons/Email";
import ChatIcon from "@material-ui/icons/Chat";
import MonAvatar from "../../Design/Avatar";
import CloudDoneIcon from "@material-ui/icons/CloudDone";

export default function Notification({
  type,
  titre,
  sousTitre,
  texte,
  index,
  link,
  onClose,
  avatar,
}) {
  const [open, setOpen] = useState(false);
  const [progress, setProgress] = React.useState(0);

  useEffect(() => {
    setOpen(true);
    const timer = setInterval(() => {
      setProgress((prevProgress) =>
        prevProgress >= 100 ? 0 : prevProgress + 1
      );
    }, 100);
    return () => {
      clearInterval(timer);
    };
  }, []);

  //NES gestion du type de sms pour l'affichage de l'icone
  let icone = SmsIcon;
  let couleur = "#205A71";
  switch (type) {
    case "EMAIL":
      icone = EmailIcon;
      couleur = "#0FA600";
      break;
    case "SUPPORT":
      icone = ChatIcon;
      couleur = "#205A71";
      break;
    case "UPDATE":
      icone = CloudDoneIcon;
      couleur = "#0FA600";
      break;

    default:
      icone = SmsIcon;
      couleur = "#205A71";
      break;
  }

  //NES fermeture automatique au bout de 10s
  useEffect(() => {
    if (progress >= 100) {
      handleClose();
    }
    // eslint-disable-next-line
  }, [progress]);

  //NES quand on ferme la notification je laisse le temps à l'amination de se finir
  const handleClose = () => {
    setOpen(false);
    setTimeout(() => {
      if (onClose) {
        onClose();
      }
    }, 550);
  };

  return (
    <Slide
      direction="left"
      in={open}
      mountOnEnter
      unmountOnExit
      timeout={500}
      style={{ backdropFilter: "blur(8px)" }}
    >
      <div
        className={MonStyle.Notification}
        style={{
          top: index * 90 + 20 + "px",
        }}
      >
        <div className={MonStyle.Icone}>
          {avatar ? (
            <MonAvatar
              nom={avatar.nom}
              prenom={avatar.prenom}
              image={avatar.image}
            />
          ) : (
            <SvgIcon
              component={icone}
              style={{
                color: couleur,
                width: "30px",
                height: "30px",
              }}
            />
          )}
        </div>
        <Link
          className={MonStyle.Textes}
          to={link ? link : ""}
          onClick={handleClose}
        >
          <div className={MonStyle.Titre}>{titre}</div>
          <div className={MonStyle.SousTitre}>{sousTitre}</div>
          <div className={MonStyle.Texte}>{texte}</div>
        </Link>
        <BoutonRond
          iconeComposant={<CloseIcon />}
          style={{ marginRight: "10px" }}
          onClick={handleClose}
          tooltip="Fermer"
          couleurIcone="noir"
        />
        <AppTooltip texte="Fermer">
          <CircularProgress
            onClick={handleClose}
            variant="determinate"
            value={progress}
            size={40}
            style={{
              position: "absolute",
              top: "20px",
              right: "10px",
              cursor: "pointer",
              color: "#208AB050",
            }}
          />
        </AppTooltip>
      </div>
    </Slide>
  );
}
