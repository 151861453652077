import React from "react";
import { DateToString, DateToHeure } from "../../functions/FonctionsDateHeure";
import EventIcon from "@material-ui/icons/Event";
import { TagRdv } from "../DataDisplay/Tags";

export default function ObjetRdv({ rdv, taille }) {
  let affichageDate = {
    weekday: "short",
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  switch (taille) {
    case "S":
      affichageDate = {
        weekday: "short",
        year: "numeric",
        month: "short",
        day: "numeric",
      };
      break;

    default:
      affichageDate = {
        weekday: "short",
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      break;
  }

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <div
        className="IconeObjet"
        style={{ color: rdv.annulation_rdv ? "#DDD" : "#666" }}
      >
        <EventIcon />
      </div>
      <div
        style={{
          minWidth: "4px",
          height: "40px",
          borderRadius: "3px",
          backgroundColor: rdv.type_rendez_vous.couleur,
          marginRight: "10px",
        }}
      >
        &nbsp;
      </div>
      <div>
        <div className="InformationPrincipale" style={{ display: "flex" }}>
          {DateToString(rdv.date_heure, affichageDate)}

          <TagRdv
            annulationRdv={rdv.annulation_rdv}
            style={{ marginLeft: "8px" }}
          />
        </div>

        <div className="InformationSecondaire">
          {DateToHeure(rdv.date_heure)}
        </div>
      </div>
    </div>
  );
}
