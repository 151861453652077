import React from "react";
import { useContext } from "react";
import MonStyle from "./PageFacturesARegler.module.css";
import { CotationsContext } from "../../../../Data/CotationsContext";
import ObjetFacture from "../../../../Design/Objets/ObjetFacture";
import LigneResultat from "../../../../Design/Recherche/LigneResultat";
import { TagPartPatient } from "../../../../Design/DataDisplay/Tags";
import ObjetPrix from "../../../../Design/Objets/ObjetPrix";
import { useEffect } from "react";
import { DateToString } from "../../../../functions/FonctionsDateHeure";
import { useState } from "react";
import MessageAucuneDonnee from "../../../../Design/Messages/MessageAucuneDonnee";
import { pluriel } from "../../../../functions/Formatter";

export default function PageFacturesARegler() {
  //NES Gestion du contexte
  const { facturesARegler } = useContext(CotationsContext);

  //NES Gestion du state
  const [groupes, setGroupes] = useState([]);
  const [totalAPayer, setTotalAPayer] = useState(0);

  //USEEFFECT affichage par groupe et calcul du montant total
  useEffect(() => {
    let tempArray = [];
    let total = 0;

    facturesARegler.forEach((facture) => {
      //NES quel est groupe de la facure?
      let groupeFacture = DateToString(facture.date_facture, {
        month: "long",
        year: "numeric",
      });

      let index = tempArray.findIndex((item) => item.nom === groupeFacture);

      if (index > -1) {
        //NES j'ajoute la facture au groupe
        let tempGroupe = tempArray[index];
        let tempListeFactures = tempGroupe.listeFactures;
        tempListeFactures.push(facture);
        tempGroupe = { ...tempGroupe, listeFactures: tempListeFactures };
        tempArray.splice(index, 1, tempGroupe);
      } else {
        //NES je créé le groupe
        let newGroupe = { nom: groupeFacture, listeFactures: [facture] };
        tempArray.push(newGroupe);
      }

      //NES je mets à jour le total à payer
      total =
        total +
        facture.part_patient?.montant_a_regler -
        facture.part_patient?.montant_regle;
    });
    setGroupes(tempArray);
    setTotalAPayer(total);
  }, [facturesARegler]);

  if (groupes.length === 0)
    return (
      <MessageAucuneDonnee
        message="Aucune facture à régler"
        conseil="Vous pouvez retrouveze toutes les factures non réglées dans cet écran."
        hideIllustration
        style={{ marginTop: "100px" }}
      />
    );

  return (
    <div className={MonStyle.PageFacturesARegler}>
      <h2>
        Factures à régler
        <p className="SousTitre">
          {pluriel(
            facturesARegler.length,
            "Facture à régler",
            "factures à régler"
          ) +
            " pour " +
            totalAPayer +
            " €"}
        </p>
      </h2>
      <main className={MonStyle.ListeFactures}>
        {groupes.map((groupe) => (
          <section key={groupe.nom}>
            <h3 style={{ marginBottom: "10px" }}>{groupe.nom}</h3>
            <div>
              {groupe?.listeFactures.map((facture) => (
                <LigneResultat
                  key={facture._id}
                  link={
                    "/patients/" +
                    facture.patient.patient_id +
                    "/consultations/" +
                    facture.consultation_id +
                    "#facture_" +
                    facture._id
                  }
                >
                  <div className={MonStyle.ColonneFacture}>
                    <ObjetFacture facture={facture} />
                  </div>
                  <div className={MonStyle.ColonnePartPatient}>
                    <ObjetPrix prix={facture?.part_patient?.montant_a_regler} />
                  </div>
                  <TagPartPatient facture={facture} />
                </LigneResultat>
              ))}
            </div>
          </section>
        ))}
      </main>
    </div>
  );
}
