import React, { useState, useContext, useEffect } from "react";
import MonStyle from "./PageAdpxOrganisations.module.css";
import { AdpxContext } from "../../../Data/AdpxContext";
import MessageAucuneDonnee from "../../../Design/Messages/MessageAucuneDonnee";
import MessageErreur from "../../../Design/Messages/MessageErreur";
import LoaderHorizontal from "../../../Design/Progression/LoaderHorizontal";
import InputRecherche from "../../../Design/Recherche/InputRecherche";
import ObjetOrganisation from "../../../Design/Objets/ObjetOrganisation";
import BoutonActionSecondaire from "../../../Design/Boutons/BoutonActionSecondaire";
import { pluriel } from "../../../functions/Formatter";
import PopupConnexionAdpx from "../Components/PopupConnexionAdpx";
import BoutonRond from "../../../Design/Boutons/BoutonRond";
import VpnKeyIcon from "@material-ui/icons/VpnKey";

export default function PageApdxOrganisations() {
  //NES gestion du contexte
  const { organisations, getAdpxOrganisations } = useContext(AdpxContext);

  //NES gestion du state
  const [liste, setListe] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [messageErreur, setMessageErreur] = useState();
  const [error, setError] = useState();
  const [texte, setTexte] = useState("");
  const [connexionOrganisation, setConnexionOrganisation] = useState();

  //USEEFFECT
  useEffect(() => {
    getAdpxOrganisations().then((reponse) => {
      setIsLoading(false);
      if (reponse.code !== "OK") {
        setMessageErreur(
          reponse.message
            ? reponse.message
            : "Erreur lors de la récupération des organisations"
        );
        setError(reponse.error);
      }
    });
    // eslint-disable-next-line
  }, []);

  //USEEFFECT texte recherche
  useEffect(() => {
    let tempArray = organisations;
    //NES je filtre avec le texte de recherche
    if (texte !== "") {
      tempArray = tempArray.filter((item) => {
        //NES construction dynamique de la chaine de recherche on cherche sur tous les éléments affichées
        let chaineRecherche = item.nom;
        chaineRecherche = chaineRecherche + item.email;

        let regExp = new RegExp(`${texte.replace(" ", "(.*)")}`, "i");
        let index = chaineRecherche.search(regExp);
        return index > -1 ? true : false;
      });
    }

    setListe(tempArray);
  }, [texte, organisations]);

  //NES Connexion
  const handleConnexion = (organisation) => {
    setConnexionOrganisation(organisation);
  };

  return (
    <div className={MonStyle.PageApdxOrganisations}>
      <h1>Organisations</h1>
      <p className="SousTitre">
        {pluriel(liste.length, "organisation", "organisations", "F")}
      </p>
      <div>
        <InputRecherche
          style={{ marginTop: "20px" }}
          placeholder="Nom de l'organisation, ville"
          value={texte}
          onChange={(e) => setTexte(e.target.value)}
          onEffacer={() => setTexte("")}
        />
      </div>
      <MessageErreur
        message={messageErreur}
        error={error}
        style={{ margin: "20px 0px" }}
      />
      <LoaderHorizontal isLoading={isLoading} style={{ marginTop: "20px" }} />

      <MessageAucuneDonnee
        message={
          liste.length === 0 && !isLoading ? "Aucune organisation trouvée" : ""
        }
        conseil="Essayez de modifier votre recherche"
      />

      <main>
        {liste.map((organisation) => (
          <div key={organisation._id} className={MonStyle.Ligne}>
            <ObjetOrganisation organisation={organisation} />
            <div className={MonStyle.BoutonRond}>
              <BoutonRond
                iconeComposant={<VpnKeyIcon />}
                onClick={() => handleConnexion(organisation)}
              />
            </div>
            <div className={MonStyle.BoutonActionSecondaire}>
              <BoutonActionSecondaire
                texte="Connexion"
                onClick={() => handleConnexion(organisation)}
              />
            </div>
          </div>
        ))}
      </main>
      <PopupConnexionAdpx
        open={connexionOrganisation ? true : false}
        onClose={() => setConnexionOrganisation(undefined)}
        organisation={connexionOrganisation}
      />
    </div>
  );
}
