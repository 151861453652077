import React from "react";
import AntecedentFamilial from "../../../../Images/Icones/AntecedentFamilial.svg";
import ObjetItem from "../../../../Design/Objets/ObjetItem";

export default function SyntheseFamille({ patient }) {
  if (!patient?.famille?.is_bookmark) {
    return "";
  }

  return (
    <div
      style={{
        padding: "5px",
        width: "100%",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        backgroundColor: "#205a7110",
        marginTop: "4px",
        boxSizing: "border-box",
      }}
      illustration={AntecedentFamilial}
    >
      <img
        src={AntecedentFamilial}
        className="IconeObjet"
        style={{ width: "40px" }}
        alt=""
      />
      <ObjetItem
        principale={patient?.famille?.situation}
        secondaire={patient?.famille?.commentaire}
        ligne3={
          patient?.famille?.nb_enfants
            ? patient?.famille?.nb_enfants + " enfants"
            : ""
        }
      />
    </div>
  );
}
