import React from "react";
import MonStyle from "./ZoneConnexion.module.css";
import Logo from "../../../Design/Logo";
import ImageConnexion from "../../../Images/ImageConnexion.jpg";
import BoutonActionSecondaire from "../../../Design/Boutons/BoutonActionSecondaire";
import { isMobile } from "../../../functions/Navigateur";

export default function ZoneConnexion({
  texteBoutonSecondaire,
  lienBoutonSecondaire,
  hideMobile,
  ...props
}) {
  //NES Styles
  const styleZoneConnexion = {
    backgroundColor: "#243845",
    background: `no-repeat bottom  url(${ImageConnexion}) #243845`,
    position: "relative",
    boxSizing: "border-box",
    backgroundSize: "cover",
    minHeight: "100vh",
    maxHeight: "100vh",
    //height: "100vh",
    //border: "5px solid Orange",
  };

  if (isMobile() && hideMobile) {
    return "";
  }

  return (
    <div className={MonStyle.ZoneConnexion} style={styleZoneConnexion}>
      <header
        style={{
          padding: "20px",
          justifyContent: "space-between",
        }}
      >
        <div className={MonStyle.Entete}>
          <Logo taille={isMobile() ? "L" : "M"} />
        </div>
        <div className="HideMobile">
          {texteBoutonSecondaire && (
            <BoutonActionSecondaire
              texte={texteBoutonSecondaire}
              link={lienBoutonSecondaire}
              couleur="blanc"
            />
          )}
        </div>
      </header>
      <div
        style={{
          minWidth: "100%",
          padding: "20px",
          boxSizing: "border-box",
        }}
      >
        {props.children}
      </div>
    </div>
  );
}
