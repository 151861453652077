import React, { useState, useEffect } from "react";
import MonStyle from "./LigneConseil.module.css";
import ObjetProduit from "../../../../Design/Objets/ObjetProduit";
import EditionPosologie from "./EditionPosologie";
import InputAutocompleteProduits from "../../../../Design/Recherche/InputAutocompleteProduits";
import MessageWarning from "../../../../Design/Messages/MessageWarning";
import InputTexte from "../../../../Design/Formulaires/InputTexte";
import MenuAction from "../../../../Design/Navigation/BoutonMenu";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import EditIcon from "@material-ui/icons/Edit";
import CancelIcon from "@material-ui/icons/Cancel";
import { Divider } from "@material-ui/core";
//import IconesProduits from "../../../../Design/Icones/IconesProduits";
import AddCommentIcon from "@material-ui/icons/AddComment";
import SpeakerNotesOffIcon from "@material-ui/icons/SpeakerNotesOff";
import BoutonProduitFavori from "../../../Produits/Components/BoutonProduitFavori";
import BoutonQuantite from "../../../../Design/Boutons/BoutonQuantite";
import { stringToDecimal } from "../../../../functions/Formatter";

export default function LigneConseil({
  ligneConseil,
  onChange,
  onSupprimer,
  onAfficherProduit,
  id,
}) {
  //NES Gestion du state
  const [data, setData] = useState({});
  const [commentaire, setCommentaire] = useState("");
  const [afficherCommentaire, setAfficherCommentaire] = useState(false);
  const [afficherAutocomplete, setAfficherAutocomplete] = useState(false);

  const handleChange = (key, value) => {
    let newLigne = { ...data, [key]: value };
    setData(newLigne);
    if (onChange) {
      onChange(newLigne);
    }
  };

  useEffect(() => {
    if (ligneConseil) {
      //NES si j'ai déjà un produit
      if (ligneConseil.produit) {
        setAfficherAutocomplete(false);
      }
      //NES si j'ai un commentaire
      if (ligneConseil.commentaire) {
        setAfficherCommentaire(true);
        setCommentaire(ligneConseil.commentaire);
      }
      setData(ligneConseil);
    }
  }, [ligneConseil]);

  //NES changement de produit
  const handleChangeProduit = (produit) => {
    console.log({ produit });
    let newProduit = {
      produit_id: produit._id,
      external_id: produit.external_id,
      forme: produit.forme,
      nom: produit.nom,
      conditionnement: produit.conditionnement,
      description: produit.description,
      conseil: produit.conseil,
      contre_indications: produit.contre_indications,
      code_commande: produit.code_commande,
      laboratoire: produit.laboratoire,
      prix_de_vente_unitaire: stringToDecimal(produit.prix_de_vente_ttc),
      qte: 1,
      prix_de_vente_total: stringToDecimal(produit.prix_de_vente_ttc),
    };
    let newLigne = { ...data, produit: newProduit };

    setData(newLigne);
    if (onChange) {
      onChange(newLigne);
    }
    setAfficherAutocomplete(false);
  };

  /// gestion du onBlur
  const handleBlur = () => {
    setAfficherAutocomplete(false);
  };

  //NES effacer le commentaire
  const handleEffacerCommentaire = () => {
    setAfficherCommentaire(false);
    setCommentaire("");
    handleChange("commentaire", "");
  };

  //NES changement de quantité
  const handleChangeQte = (qte) => {
    //NES Calcul du prix total
    let newTotal = data.produit.prix_de_vente_unitaire * qte;
    let newLigne = {
      ...data,
      produit: { ...data.produit, qte: qte, prix_de_vente_total: newTotal },
    };
    setData(newLigne);
    if (onChange) {
      onChange(newLigne);
    }
  };

  ///HandleModifier
  const handleModifier = () => {
    setAfficherAutocomplete(true);
  };

  ///Modification de la ligne
  if (afficherAutocomplete) {
    return (
      <div className={MonStyle.LigneConseil} id={"ligne_conseil_" + id}>
        <div className={MonStyle.Autocomplete}>
          <InputAutocompleteProduits
            produit={data.produit}
            placeholder="Nom du produit, ex: menthe"
            onSelect={(produit) => handleChangeProduit(produit)}
            onBlur={handleBlur}
            id="autocomplete_produit"
            autoFocus={true}
          />
        </div>
      </div>
    );
  }

  return (
    <div className={MonStyle.LigneConseil} id={"ligne_conseil_" + id}>
      <div className={MonStyle.Ligne}>
        <div className={MonStyle.AffichageProduitPosologie}>
          <div className={MonStyle.Produit}>
            <ObjetProduit produit={data.produit} />
            <div className={MonStyle.BoutonActions}>
              {data?.produit?.external_id && (
                <BoutonProduitFavori
                  produit_external_id={data?.produit?.external_id}
                  produit_id={data?.produit?.produit_id}
                  style={{ marginRight: "10px" }}
                />
              )}{" "}
              <BoutonQuantite
                style={{ marginRight: "10px" }}
                value={data?.produit?.qte}
                onChange={handleChangeQte}
              />
              <MenuAction
                tooltip="Modifier,supprimer,..."
                id={"bouton_menu_ligne_" + id}
              >
                <List dense id={"menu_ligne_" + id}>
                  <ListItem button onClick={handleModifier}>
                    <EditIcon className="IconeMenu" />
                    <ListItemText primary="Modifier" />
                  </ListItem>

                  {!afficherCommentaire ? (
                    <ListItem
                      button
                      onClick={() => setAfficherCommentaire(true)}
                    >
                      <AddCommentIcon className="IconeMenu" />
                      <ListItemText primary="Ajouter un commentaire" />
                    </ListItem>
                  ) : (
                    <ListItem
                      button
                      onClick={() => {
                        handleEffacerCommentaire();
                      }}
                    >
                      <SpeakerNotesOffIcon className="IconeMenu" />
                      <ListItemText primary="Effacer le commentaire" />
                    </ListItem>
                  )}

                  {/* data?.produit?.produit_id && (
                      <ListItem button onClick={onAfficherProduit}>
                        <IconesProduits
                          className="IconeMenu"
                          nomIcone={data?.produit?.forme}
                        />
                        <ListItemText primary="Informations du produit" />
                      </ListItem>
                    )*/}
                  <Divider style={{ margin: "10px 0px" }} />
                  <ListItem
                    button
                    style={{ color: "red" }}
                    onClick={onSupprimer}
                  >
                    <CancelIcon
                      className="IconeMenu"
                      style={{ color: "red" }}
                    />
                    Supprimer
                  </ListItem>
                </List>
              </MenuAction>
            </div>
          </div>
          <div className={MonStyle.Posologie}>
            <EditionPosologie
              posologie={data.posologie}
              onChange={(posologie) => handleChange("posologie", posologie)}
            />
          </div>
        </div>

        <div className={MonStyle.AffichageZoneComplementaire}>
          <MessageWarning
            message={data?.produit?.contre_indications}
            style={{ marginTop: "10px" }}
          />
          {afficherCommentaire && (
            <InputTexte
              value={commentaire}
              onChange={(e) => setCommentaire(e.target.value)}
              onBlur={(e) => handleChange("commentaire", e.target.value)}
              theme="fondgris"
              placeholder="Ajouter un commentaire"
              style={{ marginTop: "10px", marginBottom: "10px" }}
              multiline
            />
          )}
        </div>
      </div>
    </div>
  );
}
