import React, { useState, useEffect, useContext } from "react";
import BoutonRetour from "../../../../Design/Boutons/BoutonRetour";
import MessageErreur from "../../../../Design/Messages/MessageErreur";
import LoaderHorizontal from "../../../../Design/Progression/LoaderHorizontal";
import SurfacePapier from "../../../../Design/Surfaces/SurfacePapier";
import MonStyle from "./PageListeDesFactures.module.css";
import { CotationsContext } from "../../../../Data/CotationsContext";
import ObjetFacture from "../../../../Design/Objets/ObjetFacture";
import { TagPartPatient } from "../../../../Design/DataDisplay/Tags";
import ObjetPrix from "../../../../Design/Objets/ObjetPrix";
import { numberToEuro, pluriel } from "../../../../functions/Formatter";
import BoutonRond from "../../../../Design/Boutons/BoutonRond";
import PrintIcon from "@material-ui/icons/Print";
import queryString from "query-string";
import MessageAucuneDonnee from "../../../../Design/Messages/MessageAucuneDonnee";
import DocumentJournalDesHonoraires from "../../../../Docs/Pages/DocumentJournalDesHonoraires";
import { HashLink } from "react-router-hash-link";

export default function PageListeDesFactures() {
  //NES gestion du contexte
  const { getFactures } = useContext(CotationsContext);

  //NES Gestion du state
  const [dateDebut, setDateDebut] = useState();
  const [dateFin, setDateFin] = useState();
  const [listeFactures, setListeFactures] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [messageErreur, setMessageErreur] = useState();
  const [error, setError] = useState();
  const [totalHt, setTotalHt] = useState(0);
  const [totalTva, setTotalTva] = useState(0);
  const [totalTtc, setTotalTtc] = useState(0);
  const [totalPartPatient, setTotalPartPatient] = useState(0);
  const [openImpression, setOpenImpression] = useState(false);

  //NES récuparation des params de l'URL
  let url = window.location.search;

  useEffect(() => {
    if (url !== "") {
      const values = queryString.parse(url);
      //NES je prends les dates passées dans l'url
      let tempDateDebut = new Date(values.datedebut);
      let tempDateFin = new Date(values.datefin);
      setDateDebut(tempDateDebut);
      setDateFin(tempDateFin);
    }
  }, [url]);

  //USEEFFECT récupération des factures
  useEffect(() => {
    // let dateDebut = new Date(2021, 10, 1);
    // let dateFin = new Date(2021, 10, 30);

    getFactures(dateDebut, dateFin)
      .then((reponse) => {
        setIsLoading(false);
        if (reponse.code === "OK") {
          let liste = reponse.data;
          let tempTotalHt = 0;
          let tempTotalTva = 0;
          let tempTotalTtc = 0;
          let tempTotalPatient = 0;

          liste.forEach((facture) => {
            tempTotalHt = tempTotalHt + facture.total_montant_ht;
            tempTotalTva = tempTotalTva + facture.total_montant_tva_net;
            tempTotalTtc = tempTotalTtc + facture.total_montant_ttc;

            let montantRestantARegler = facture.part_patient.montant_regle
              ? facture.part_patient.montant_a_regler -
                facture.part_patient.montant_regle
              : facture.part_patient.montant_a_regler;

            tempTotalPatient = tempTotalPatient + montantRestantARegler;
          });

          setTotalHt(tempTotalHt);
          setTotalTva(tempTotalTva);
          setTotalTtc(tempTotalTtc);
          setTotalPartPatient(tempTotalPatient);
          setListeFactures(liste);
        } else {
          setMessageErreur("Erreur lors de l'enregistrement");
        }
      })
      .catch((error) => {
        setIsLoading(false);
        setMessageErreur("Erreur lors de l'enregistrement");
        setError(error);
      });

    // eslint-disable-next-line
  }, [dateDebut, dateFin]);

  return (
    <SurfacePapier>
      <div className={MonStyle.PageJournalDesFactures}>
        <div className={MonStyle.TitreEtBoutons}>
          <div>
            <div className="Titre1">Journal des factures</div>
            <div className="SousTitre">
              {pluriel(listeFactures.length, "facture", "factures", "F")}
            </div>
          </div>
          <BoutonRond
            iconeComposant={<PrintIcon />}
            couleur="vert"
            couleurIcone="blanc"
            tooltip="Imprimer le journal des honoraires"
            onClick={() => setOpenImpression(true)}
          />
        </div>
        <div className={MonStyle.BoutonRetour}>
          <BoutonRetour
            texte="Retour au suivi des règlements"
            link="/suivireglements/"
          />
        </div>
        <DocumentJournalDesHonoraires
          open={openImpression}
          onClose={() => setOpenImpression(false)}
          listeFactures={listeFactures}
          dateDebut={dateDebut}
          dateFin={dateFin}
        />
        <div className={MonStyle.Content}>
          <LoaderHorizontal isLoading={isLoading} />
          <MessageErreur
            message={messageErreur}
            error={error}
            style={{ marginBottom: "20px" }}
          />

          {listeFactures.length === 0 ? (
            <MessageAucuneDonnee
              message="Aucune facture sur la période"
              hideIllustration
            />
          ) : (
            <div>
              <div className={MonStyle.EnteteColonnes}>
                <div className={MonStyle.ColonneFacture}>Facture</div>
                <div className={MonStyle.ColonneHT}>Montant HT</div>
                <div className={MonStyle.ColonneTva}>Montant TVA</div>
                <div className={MonStyle.ColonneTTC}>Montant TTC</div>
                <div className={MonStyle.ColonnePartPatient}>A régler</div>
              </div>

              {listeFactures.map((facture) => (
                <div className="ListeLigne" key={facture._id}>
                  <div className={MonStyle.ColonneFacture}>
                    <HashLink
                      to={
                        "/patients/" +
                        facture.patient.patient_id +
                        "/consultations/" +
                        facture.consultation_id +
                        "#facture_" +
                        facture._id
                      }
                    >
                      <ObjetFacture facture={facture} />
                    </HashLink>
                  </div>
                  <div className={MonStyle.ColonneHT}>
                    <ObjetPrix prix={facture.total_montant_ht} />
                  </div>
                  <div className={MonStyle.ColonneTva}>
                    <ObjetPrix prix={facture.total_montant_tva_net} />
                  </div>
                  <div className={MonStyle.ColonneTTC}>
                    <ObjetPrix prix={facture.total_montant_ttc} />
                  </div>
                  <div className={MonStyle.ColonnePartPatient}>
                    <TagPartPatient
                      facture={facture}
                      partPatient={facture.part_patient}
                    />
                  </div>
                </div>
              ))}

              <div className={MonStyle.Total}>
                <div className={MonStyle.ColonneFacture}>TOTAL</div>
                <div className={MonStyle.ColonneHT}>
                  <ObjetPrix prix={totalHt} />
                </div>
                <div className={MonStyle.ColonneTva}>
                  <ObjetPrix prix={totalTva} />
                </div>
                <div className={MonStyle.ColonneTTC}>
                  {numberToEuro(totalTtc, " €")}
                </div>
                <div className={MonStyle.ColonnePartPatient}>
                  {numberToEuro(totalPartPatient, " €")}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </SurfacePapier>
  );
}
