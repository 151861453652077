import React from "react";
import BlockIcon from "@material-ui/icons/Block";
import MonStyle from "./ObjetHabitus.module.css";
import ObjetItem from "./ObjetItem";
import SvgIcon from "@material-ui/core/SvgIcon";
import SmokingRoomsIcon from "@material-ui/icons/SmokingRooms";
import SmokeFreeIcon from "@material-ui/icons/SmokeFree";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import WorkIcon from "@material-ui/icons/Work";
import {
  IconeHabitusDrogueFree,
  IconeHabitusDrogue,
  IconeHabitusAlcool,
  IconeHabitusAlcoolFree,
  IconeHabitusSportFree,
  IconeHabitusSport,
} from "../../Design/Icones/IconesHabitus";

export default function ObjetHabitus({ habitus }) {
  const afficheIcone = () => {
    let retour = BlockIcon;
    switch (habitus.code_type_antecedent) {
      case "habitus_tabac":
        switch (habitus.choix) {
          case "oui":
            retour = SmokingRoomsIcon;
            break;
          case "non":
            retour = SmokeFreeIcon;
            break;
          default:
            retour = HelpOutlineIcon;
            break;
        }
        break;
      case "habitus_alcool":
        switch (habitus.choix) {
          case "oui":
            retour = IconeHabitusAlcool;
            break;
          case "non":
            retour = IconeHabitusAlcoolFree;
            break;
          default:
            retour = HelpOutlineIcon;
            break;
        }
        break;

      case "habitus_drogue":
        switch (habitus.choix) {
          case "oui":
            retour = IconeHabitusDrogue;
            break;
          case "non":
            retour = IconeHabitusDrogueFree;
            break;
          default:
            retour = HelpOutlineIcon;
            break;
        }
        break;
      case "habitus_sport":
        switch (habitus.choix) {
          case "oui":
            retour = IconeHabitusSport;
            break;
          case "non":
            retour = IconeHabitusSportFree;
            break;
          default:
            retour = HelpOutlineIcon;
            break;
        }
        break;
      case "habitus_travail":
        retour = WorkIcon;
        break;

      default:
        retour = HelpOutlineIcon;
        break;
    }

    return retour;
  };

  //NES libelle habitus
  const getLibelleHabitus = () => {
    switch (habitus.code_type_antecedent) {
      case "habitus_tabac":
        return "tabac";

      case "habitus_alcool":
        return "alcool";
      case "habitus_drogue":
        return "drogue";
      case "habitus_sport":
        return "sport";
      case "habitus_travail":
        return "travail";
      default:
        return "";
    }
  };

  //NES couleur conditionnelle
  const getCouleurIcone = () => {
    if (habitus.choix === "non") {
      return "#97989D80";
    } else {
      return "#205A71";
    }
  };

  const getCouleur = () => {
    if (habitus.choix === "non") {
      return "#97989D";
    } else {
      return "";
    }
  };

  //NES affichage conditionnnel de la ligne principal et secondaire
  let lignePrincipale = "";
  let ligneSecondaire = "";

  if (habitus) {
    //NES si j'ai la fréquence
    if (habitus.quantite_frequence) {
      lignePrincipale = habitus.quantite_frequence;
      ligneSecondaire = habitus.commentaire;
    } else {
      //NES si j'ai un commentaire et pas de fréquence
      if (habitus.commentaire) {
        lignePrincipale = habitus.commentaire;
        ligneSecondaire = habitus.depuis_le;
      } else {
        //NES je n'ai ni fréquence, ni commentaire
        if (habitus.choix === "oui") {
          lignePrincipale = getLibelleHabitus() + " : OUI";
        } else {
          lignePrincipale = getLibelleHabitus() + " : NON";
        }
        ligneSecondaire = habitus.depuis_le;
      }
    }
  }

  //NES cas particulier du travail
  if (habitus.code_type_antecedent === "habitus_travail") {
    lignePrincipale = habitus.libelle;
    ligneSecondaire = habitus.commentaire
      ? habitus.commentaire
      : habitus.depuis_le;
  }

  return (
    <div className={MonStyle.ObjetHabitus}>
      <SvgIcon
        component={afficheIcone(habitus.code_type_antecedent, habitus.choix)}
        className="IconeObjet"
        style={{
          height: "40px",
          color: getCouleurIcone(),
        }}
      />
      <ObjetItem
        principale={lignePrincipale}
        secondaire={ligneSecondaire}
        couleurTexte={getCouleur()}
      />
    </div>
  );
}
