import React from "react";
import { IconeReglement } from "../Icones/IconesReglements";
import { DateToString } from "../../functions/FonctionsDateHeure";
import ObjetPrix from "./ObjetPrix";
import { Link } from "react-router-dom";
import MonStyle from "./ObjetReglement.module.css";

export default function ObjetReglement({ reglement, isAfficherDate }) {
  //NES Gestion des styles dynamiques
  let styleAnnulation = {
    fontWeight: "900",
    color: "black",
  };

  //NES liste des Cotations / consultations réglées
  const listeCotationsRegles = () => {
    return (
      <div>
        {reglement?.cotations?.map((cotation) => (
          <div key={cotation.cotation_id}>
            Consultation du {DateToString(cotation.cotation_date)} de
            <Link
              to={
                "/patients/" + reglement.patient.patient_id + "/consultations/"
              }
              style={{ color: "#208AB0" }}
            >
              {" " +
                cotation?.cotation_patient?.nom.toUpperCase() +
                " " +
                cotation?.cotation_patient.prenom +
                " "}
            </Link>
            ({" " + cotation.cotation_montant + " €"})
          </div>
        ))}
      </div>
    );
  };

  //NES Selon le montant réglé débit ou crédit
  let debit = "";
  let credit = "";
  if (reglement.montant >= 0) {
    credit = reglement.montant + " €";
  } else {
    debit = reglement.montant + " €";
  }

  return (
    <div className={MonStyle.ObjetReglement}>
      <div className={MonStyle.IconeEtTexte}>
        <div style={{ margin: "0px 15px" }}>
          <IconeReglement code={reglement.code_mode_reglement} taille="24px" />
        </div>
        <div className={MonStyle.TexteReglementEtCotation}>
          <div className={MonStyle.TexteReglement}>
            <div
              style={
                reglement.code_type_reglement === "RENDU" ? styleAnnulation : {}
              }
            >
              {reglement.code_type_reglement === "RENDU"
                ? reglement.libelle_type_reglement
                : ""}{" "}
              <span>{reglement.libelle_mode_reglement}</span>
            </div>

            {isAfficherDate && (
              <span className="InformationSecondaire">
                {" "}
                du{" "}
                {DateToString(reglement.date, {
                  year: "numeric",
                  month: "numeric",
                  day: "numeric",
                })}
              </span>
            )}
          </div>
          <div style={{ fontSize: "14px", color: "#666" }}>
            {listeCotationsRegles()}
          </div>
        </div>
      </div>

      <div
        style={{
          minWidth: "80px",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
        }}
      >
        <div>
          {debit && <ObjetPrix prix={debit} style={{ color: "red" }} />}
        </div>
        <div>{credit && <ObjetPrix prix={credit} />}</div>
      </div>
    </div>
  );
}
