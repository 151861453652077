import React, { useContext, useEffect, useState } from "react";
import MonStyle from "./SpecialitePicker.module.css";
import { ContactsContext } from "../../../../Data/ContactsContext";
import MessageErreur from "../../../../Design/Messages/MessageErreur";
import AfficherPlus from "../../../../Design/DataDisplay/AfficherPlus";

export default function SpecialitePicker({ style, onSelect, valueCode }) {
  const CODESFAVORIS = ["10", "21", "93", "60"];

  //NES gestion du contexte
  const { getSpecialites } = useContext(ContactsContext);

  //NES Gestion du state
  const [codeSelected, setCodeSelected] = useState();
  const [messageErreur, setMessageErreur] = useState();
  const [error, setError] = useState();
  const [specialitesFrequentes, setSpecialitesFrequentes] = useState([]);
  const [specialitesAutres, setSpecitalitesAutres] = useState([]);

  ///HandleSelect
  const handleSelect = (objetSpecialite) => {
    let newData = objetSpecialite;

    if (objetSpecialite.code === codeSelected) {
      newData = undefined;
    }
    //NES je remonte l'état
    if (onSelect) {
      onSelect(newData);
    }
    setCodeSelected(newData?.code);
  };

  //USEEFFECT
  useEffect(() => {
    setCodeSelected(valueCode);
  }, [valueCode]);

  //USEEFFECT
  useEffect(() => {
    getSpecialites().then((reponse) => {
      if (reponse.code === "OK") {
        let listeComplete = reponse.data;
        let listeFrequente = [];
        let listeAutres = [];

        listeComplete.forEach((item) => {
          if (CODESFAVORIS.includes(item.code)) {
            listeFrequente.push(item);
          } else {
            listeAutres.push(item);
          }
        });
        setSpecialitesFrequentes(listeFrequente);
        setSpecitalitesAutres(listeAutres);
      } else {
        setMessageErreur("Erreur lors de la récupération des spécalités ");
        setError(reponse);
      }
    });
    // eslint-disable-next-line
  }, []);

  if (messageErreur) {
    return (
      <MessageErreur
        message={messageErreur}
        error={error}
        style={{ margin: "10px 0px" }}
      />
    );
  }

  return (
    <div className={MonStyle.SpecialitePicker}>
      <div className={MonStyle.ListeSpecialites} style={style}>
        {specialitesFrequentes.map((item) => (
          <div
            id={"filtre_" + item.libelle}
            key={item.code}
            className={
              item.code === codeSelected
                ? MonStyle.SpecialiteSelected
                : MonStyle.Specialite
            }
            onClick={() => handleSelect(item)}
          >
            {item.libelle}{" "}
          </div>
        ))}
      </div>
      <AfficherPlus labelOpen="Afficher plus" labelClose="Afficher moins">
        <div className={MonStyle.ListeSpecialites} style={style}>
          {specialitesAutres.map((item) => (
            <div
              id={"filtre_" + item.libelle}
              key={item.code}
              className={
                item.code === codeSelected
                  ? MonStyle.SpecialiteSelected
                  : MonStyle.Specialite
              }
              onClick={() => handleSelect(item)}
            >
              {item.libelle}{" "}
            </div>
          ))}
        </div>
      </AfficherPlus>
    </div>
  );
}
