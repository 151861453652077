import React from "react";
import { numberToEuro } from "../../functions/Formatter";

export default function ObjetPrix({ prix, unite, complement, style, label }) {
  return (
    <div style={{ color: "#000", textAlign: "right", ...style }}>
      <small>{label}</small> {numberToEuro(prix)}
      <span> {unite ? unite : " €"}</span>
      <span className="InformationSecondaire"> {complement}</span>
    </div>
  );
}
