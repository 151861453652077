import React, { useState, useContext } from "react";
import MonStyle from "./AffichageAcceptationCgv.module.css";
import BoutonActionPrincipale from "../../../Design/Boutons/BoutonActionPrincipale";
import BoutonAnnuler from "../../../Design/Boutons/BoutonAnnuler";
import SurfacePopUp from "../../../Design/Surfaces/SurfacePopUp";
import ConditionsGenerales from "../../Cgv/Components/ConditionsGenerales";
import { AuthentificationContext } from "../../../Data/AuthentificationContext";

export default function AffichageAcceptationCgv() {
  //NES Gestion du state
  const [open, setOpen] = useState(false);

  //NES gestion du contexte
  const auth = useContext(AuthentificationContext);

  ///HandleAccepter
  const handleAccepter = () => {
    setOpen(false);
  };

  ///HandleDeconnexion
  const handleDeconnexion = () => {
    auth.Deconnexion();
  };

  return (
    <SurfacePopUp
      open={open}
      disableBackgroundClose
      disableCloseIcone
      className={MonStyle.AffichageAcceptationCgv} //titre="Conditions générales d'utilisation"
    >
      <div className={MonStyle.AffichageAcceptationCgv}>
        <ConditionsGenerales />
        <div className={MonStyle.Boutons}>
          <BoutonActionPrincipale
            texte="Accepter les conditions générales"
            taille="L"
            onClick={handleAccepter}
          />
          <BoutonAnnuler texte="Déconnexion" onClick={handleDeconnexion} />
        </div>
      </div>
    </SurfacePopUp>
  );
}
