import { createContext } from "react";

export const AuthentificationContext = createContext({
  isConnecte: false,
  token: null,
  payload: null,
  captureEcran: null,
  dataConnexion: null,
  connexion: () => {},
  deconnexion: () => {},
  getCaptureEcran: () => {},
});
