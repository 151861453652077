import React, { useContext, useState } from "react";
import { ContactsContext } from "../../../../Data/ContactsContext";
import ObjetContact from "../../../../Design/Objets/ObjetContact";
import BoutonMenu from "../../../../Design/Navigation/BoutonMenu";
import BoutonConfirmerSuppression from "../../../../Design/Boutons/BoutonConfirmerSuppression";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import List from "@material-ui/core/List";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";

export default function AffichageContacts({
  patient_id,
  style,
  onClickModifier,
}) {
  //NES gestion du context
  const { contacts, deleteContact } = useContext(ContactsContext);

  //NES gestion du state
  const [contactASupprimer, setContactASupprimer] = useState();

  //DELETE du contact
  const handleDeleteContact = (contact_id) => {
    deleteContact(patient_id, contact_id);
    setContactASupprimer();
  };

  const handleModifier = (contact_id) => {
    console.log("handleModifier");
    if (onClickModifier) {
      onClickModifier(contact_id);
    }
  };

  return (
    <div>
      <div style={{ borderBottom: "1px solid #DDD", ...style }}>
        {contacts.map((contact) => (
          <div
            key={contact._id}
            style={{
              padding: "10px",
              borderTop: "1px solid #DDD",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              minHeight: "60px",
              position: "relative",
            }}
          >
            <ObjetContact contact={contact} taille="L" />

            <BoutonConfirmerSuppression
              style={{
                position: "absolute",
                right: "0px",
                border: "0px solid orange",
                backgroundColor: "#FFFFFF",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                background: "white",
                zIndex: 2,
                padding: "0px 20px",
              }}
              visible={contactASupprimer === contact._id}
              onAnnuler={() => setContactASupprimer()}
              onSupprimer={() => handleDeleteContact(contact._id)}
            />

            <BoutonMenu
              tooltip="Supprimer, modifier"
              id={"menu_" + contact.nom}
            >
              <List id="menu_contact">
                <ListItem button onClick={() => handleModifier(contact._id)}>
                  <EditIcon className="IconeMenu" />
                  <ListItemText primary="Modifier le contact" />
                </ListItem>
                {/* 
                  <ListItem button>
                    <InsertCommentIcon className="IconeMenu" />
                    <ListItemText primary="Ajouter un commentaire" />
                  </ListItem>
                  */}
                <ListItem
                  button
                  style={{ color: "red" }}
                  onClick={() => setContactASupprimer(contact._id)}
                >
                  <DeleteIcon className="IconeMenu" style={{ color: "red" }} />
                  <ListItemText primary="Supprimer le contact" />
                </ListItem>
              </List>
            </BoutonMenu>
          </div>
        ))}
      </div>
    </div>
  );
}
