import React from "react";
import MonStyle from "./PageInscription.module.css";
import ZoneConnexion from "../Components/ZoneConnexion";
import TextesInscription from "../Components/TextesInscription";
import Inscription from "../Components/Inscription";
import { capitalize } from "../../../functions/Formatter";

export default function PageInscription() {
  return (
    <div className={MonStyle.PageInscription}>
      <ZoneConnexion
        texteBoutonSecondaire="Connexion"
        lienBoutonSecondaire="/"
        hideMobile
      >
        <TextesInscription />
      </ZoneConnexion>

      <div className={MonStyle.Content}>
        <h1 style={{ marginBottom: "20px" }}>
          Inscription {capitalize(process.env.REACT_APP_NOM)}
        </h1>
        <Inscription />
      </div>
    </div>
  );
}
