import React, { useState, useEffect } from "react";
import MonStyle from "./PageAdpx.module.css";
import { Route, Switch, Redirect } from "react-router-dom";
import { HashLink as Link } from "react-router-hash-link";
import SurfacePage from "../../Design/Surfaces/SurfacePage";
import { ButtonBase } from "@material-ui/core";
import CloudIcon from "@material-ui/icons/Cloud";
import PageAdpxCloud from "./Pages/PageAdpxCloud";
import PageAdpxPlanning from "./Pages/PageAdpxPlanning";
import EventNoteIcon from "@material-ui/icons/EventNote";
import BugReportIcon from "@material-ui/icons/BugReport";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import PageApdxErreurs from "./Pages/PageAdpxErreurs";
import PageApdxOrganisations from "./Pages/PageAdpxOrganisations";

const styleOngletSelectionne = {
  backgroundColor: "#F2F1F5",
  //borderRadius: "0px 10px 10px 0px",
  //border: "1px solid #DDD",
  color: "#205A71",
  //borderLeft: "1px solid #F2F1F5",
  //marginLeft: "-1px",
  height: "60px",
  width: "60px",
};

const styleOnglet = {
  borderRadius: "0px 10px 10px 0px",
  //  marginLeft: "-1px",
  height: "60px",
  color: "#97989D",
};

export default function PageAdpx() {
  let url = document.location.href;

  //NES gestion du state
  const [ongletSelectionne, setOngletSelectionne] = useState();

  useEffect(() => {
    if (url.indexOf("cloud") > -1) {
      setOngletSelectionne("cloud");
    }
    if (url.indexOf("planning") > -1) {
      setOngletSelectionne("planning");
    }
    if (url.indexOf("support") > -1) {
      setOngletSelectionne("support");
    }
    if (url.indexOf("erreurs") > -1) {
      setOngletSelectionne("erreurs");
    }
    if (url.indexOf("planning") > -1) {
      setOngletSelectionne("planning");
    }
    if (url.indexOf("organisations") > -1) {
      setOngletSelectionne("organisations");
    }
  }, [url]);

  return (
    <SurfacePage couleur="gris" disablePadding>
      <div className={MonStyle.PageAdpx}>
        <div className={MonStyle.PartieGauche}>
          <Switch>
            <Redirect path="/adpx/" to="/adpx/cloud" exact />
            <Route path="/adpx/cloud" exact component={PageAdpxCloud} />
            <Route path="/adpx/planning" exact component={PageAdpxPlanning} />
            <Route path="/adpx/planning/:id" component={PageAdpxPlanning} />
            <Route path="/adpx/erreurs" exact component={PageApdxErreurs} />
            <Route
              path="/adpx/organisations"
              exact
              component={PageApdxOrganisations}
            />
          </Switch>
        </div>
        <div className={MonStyle.MenuAdpx}>
          <ButtonBase
            style={
              ongletSelectionne === "cloud"
                ? styleOngletSelectionne
                : styleOnglet
            }
            component={Link}
            to="/adpx/cloud"
          >
            <CloudIcon style={{ fontSize: "30px" }} />
          </ButtonBase>
          <ButtonBase
            style={
              ongletSelectionne === "planning"
                ? styleOngletSelectionne
                : styleOnglet
            }
            component={Link}
            to="/adpx/planning"
          >
            <EventNoteIcon style={{ fontSize: "30px" }} />
          </ButtonBase>
          <ButtonBase
            style={
              ongletSelectionne === "erreurs"
                ? styleOngletSelectionne
                : styleOnglet
            }
            component={Link}
            to="/adpx/erreurs"
          >
            <BugReportIcon style={{ fontSize: "30px" }} />
          </ButtonBase>
          <ButtonBase
            style={
              ongletSelectionne === "organisations"
                ? styleOngletSelectionne
                : styleOnglet
            }
            component={Link}
            to="/adpx/organisations"
          >
            <VpnKeyIcon style={{ fontSize: "30px" }} />
          </ButtonBase>
        </div>
      </div>
    </SurfacePage>
  );
}
