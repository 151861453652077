import React from "react";
import AddIcon from "@material-ui/icons/Add";
import BoutonRond from "./BoutonRond";
import MonStyle from "./BoutonActionFlottant.module.css";
import { isMobile } from "../../functions/Navigateur";

export default function BoutonActionFlottant({
  iconeComposant,
  tooltip,
  link,
  style,
  mobileOnly,
  ...props
}) {
  if (mobileOnly && !isMobile()) {
    return "";
  } else {
    return (
      <div className={MonStyle.BoutonActionFlottant} style={style}>
        <BoutonRond
          iconeComposant={iconeComposant ? iconeComposant : <AddIcon />}
          couleur="vert"
          couleurIcone="blanc"
          taille="L"
          tooltip={tooltip}
          link={link}
          ombre="oui"
          {...props}
        />
      </div>
    );
  }
}
