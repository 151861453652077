import React, { useState, createContext, useContext, useEffect } from "react";
import { AuthentificationContext } from "./AuthentificationContext";
import ForumIcon from "@material-ui/icons/Forum";
import BugReportIcon from "@material-ui/icons/BugReport";
import ExploreIcon from "@material-ui/icons/Explore";
import { DELETE, GET, PATCH, POST } from "../functions/FonctionsApi";
import { NotificationsContext } from "./NotificationsContext";

//NES Création du contexte
export const SupportContext = createContext();

export const SupportProvider = (props) => {
  //NES gestion du contexte
  const authContext = useContext(AuthentificationContext);
  const { ajouterNotification } = useContext(NotificationsContext);

  //NES Gestion du state
  const [demandes, setDemandes] = useState([]);
  const [messages, setMessages] = useState([]);
  const [nbDemandesNonLues, setNbDemandesNonLues] = useState(0);

  //MODELE
  const modeleDemande = () => {
    return {
      objet: "",
      description: "",
      type: "",
      priorite: "",
      capture_ecran: "",
    };
  };

  const ETAT_DEMANDE = [
    {
      texte: "En cours",
      code: "EN_COURS",
      color: "orange",
      description: "En cours de traitement",
    },
    {
      texte: "Planifié",
      code: "PLANIFIE",
      color: "#208AB0",
      description: "Le traitement de la demande est planifiée",
    },
    {
      texte: "Corrigé",
      code: "CORRIGE",
      color: "#0FA600",
      description: "La correction est terminée",
    },
    {
      texte: "Annulée",
      code: "ANNULE",
      color: "#97989D",
      description: "La demande ne peut pas être traitée",
    },
    {
      texte: "Attente",
      code: "EN_ATTENTE",
      color: "red",
      description: "En attente d'information de la part du client",
    },
  ];

  const ETAPES_SUPPORT = [
    { texte: "Saisie", code: "SAISIE" },
    { texte: "Aide", code: "AIDE" },
    { texte: "Etude", code: "ETUDE" },
    { texte: "Correction", code: "CORRECTION" },
    { texte: "Evolution", code: "EVOLUTION" },
    { texte: "Livraison", code: "LIVRAISON" },
    { texte: "Rendez-vous", code: "RDV" },
    { texte: "Résolu", code: "CLOTURE" },
    { texte: "Cloturé", code: "IMPOSSIBLE" },
  ];

  //NES récupération du libellé selon le type de demande
  const libelleTypeDemande = {
    AIDE: "Demande d'aide",
    BUG: "Bug, anomalie, message d'erreur",
    EVOLUTION: "Evolution / Amélioration",
  };

  //NES Icone du type de demande
  const iconeTypeDemande = {
    AIDE: <ForumIcon />,
    BUG: <BugReportIcon />,
    EVOLUTION: <ExploreIcon />,
  };

  //NES récupération du libellé selon la priorite de demande
  const libellePrioriteDemande = {
    FAIBLE: "Cela peut attendre",
    RAPIDE: "Rapide",
    URGENT: "Urgent",
  };

  //GET demandes/
  const getDemandes = async () => {
    if (demandes.length >= 1) {
      //NES je retourne directement le contexte
      return { code: "OK", data: demandes };
    }

    //NES j'appelle l'API
    let MonUrl = `${process.env.REACT_APP_URL_API_SUPPORT}/demandes`;

    //NES l'url n'est pas la même si je suis le support
    if (authContext.payload.code_role === "ADPX") {
      MonUrl = `${process.env.REACT_APP_URL_API_SUPPORT}/adpx/demandes`;
    }

    const reponseApi = await GET(MonUrl, authContext.token);

    //NES je stocke mes demandes dans le contexte
    if (reponseApi.code === "OK") {
      setDemandes(reponseApi.data);
    }

    //NES je retourne à ma fonction appelante le résultat
    return reponseApi;
  };

  //POST demandes/
  const postDemandes = async (data) => {
    //NES j'appelle l'API
    let MonUrl = `${process.env.REACT_APP_URL_API_SUPPORT}/demandes`;
    const reponseApi = await POST(MonUrl, authContext.token, data);
    //NES je le garde la demande dans le contexte
    if (reponseApi.code === "OK") {
      let tempArray = [...demandes];
      tempArray.push(reponseApi.demande);
      setDemandes(tempArray);
    }
    //NES je retourne à ma fonction appelante le résultat
    return reponseApi;
  };

  //PATCH demandes/
  const patchDemandes = async (data) => {
    //NES j'appelle l'API
    let MonUrl = `${process.env.REACT_APP_URL_API_SUPPORT}/demandes/${data._id}`;

    //NES l'url n'est pas la même si je suis le support
    if (authContext.payload.code_role === "ADPX") {
      MonUrl = `${process.env.REACT_APP_URL_API_SUPPORT}/adpx/demandes/${data._id}`;
    }

    const reponseApi = await PATCH(MonUrl, authContext.token, data);
    //NES je le garde la demande dans le contexte
    if (reponseApi.code === "OK") {
      let tempArray = [...demandes];
      let index = tempArray.findIndex((item) => item._id === data._id);
      tempArray.splice(index, 1, reponseApi.data);
      setDemandes(tempArray);
    }

    //NES je retourne à ma fonction appelante le résultat
    return reponseApi;
  };

  //DELETE demandes/:id
  const deleteDemandes = async (demande_id) => {
    let MonUrl = `${process.env.REACT_APP_URL_API_SUPPORT}/demandes/${demande_id}`;
    const reponseApi = await DELETE(MonUrl, authContext.token);
    //NES je le garde la demande dans le contexte
    if (reponseApi.code === "OK") {
      let tempArray = [...demandes];
      let index = tempArray.findIndex((item) => item._id === demande_id);
      tempArray.splice(index, 1);
      setDemandes(tempArray);
    }
    //NES je retourne à ma fonction appelante le résultat
    return reponseApi;
  };

  //GET demandes/:id/messages
  const getDemandesMessages = async (demande_id) => {
    setMessages([]);

    //NES j'appelle l'API
    let MonUrl = `${process.env.REACT_APP_URL_API_SUPPORT}/demandes/${demande_id}/messages`;

    //NES l'url n'est pas la même si je suis le support
    if (authContext.payload.code_role === "ADPX") {
      MonUrl = `${process.env.REACT_APP_URL_API_SUPPORT}/adpx/demandes/${demande_id}/messages`;
    }

    const reponseApi = await GET(MonUrl, authContext.token);

    if (reponseApi.code === "OK") {
      //NES je garde les messages dans le contexte
      setMessages(reponseApi.messages);
      //NES je mets à jour la demande (car is lu vient de changer normalement)
      let tempArray = [...demandes];
      let index = tempArray.findIndex(
        (item) => item._id === reponseApi.demande._id
      );
      tempArray.splice(index, 1, reponseApi.demande);
      setDemandes(tempArray);
    }

    //NES je retourne à ma fonction appelante le résultat
    return reponseApi;
  };

  //POST demandes/:id/messages
  const postDemandesMessages = async (demande_id, data) => {
    console.log(data);

    //NES j'appelle l'API
    let MonUrl = `${process.env.REACT_APP_URL_API_SUPPORT}/demandes/${demande_id}/messages`;

    //NES l'url n'est pas la même si je suis le support
    if (authContext.payload.code_role === "ADPX") {
      MonUrl = `${process.env.REACT_APP_URL_API_SUPPORT}/adpx/demandes/${demande_id}/messages`;
    }

    const reponseApi = await POST(MonUrl, authContext.token, data);

    console.log({ reponseApi });
    if (reponseApi.code === "OK") {
      //NES je garde les messages dans le contexte
      let tempMessages = [...messages];
      tempMessages.push(reponseApi.message);
      setMessages(tempMessages);

      //NES je mets à jour la demande (car is lu vient de changer normalement)
      let tempArray = [...demandes];
      let index = tempArray.findIndex(
        (item) => item._id === reponseApi.demande._id
      );
      tempArray.splice(index, 1, reponseApi.demande);
      setDemandes(tempArray);
    }

    //NES je retourne à ma fonction appelante le résultat
    return reponseApi;
  };

  ///socketIOMessages
  const socketIoReceptionMessages = (data) => {
    if (data.code === "OK") {
      //NES mise à jour des demandes
      setDemandes((previousDemandes) => {
        let index = previousDemandes.findIndex(
          (item) => item._id === data?.demande?._id
        );
        if (index > -1) {
          let tempArray = Array.from(previousDemandes);
          tempArray.splice(index, 1, data?.demande);
          return tempArray;
        } else {
          return [...previousDemandes, data.demande];
        }
      });
      //NES mise à jour des messages si les messages sont ceux de la demande dont je viens de recevoir un message...

      setMessages((previousMessages) => {
        let demandeIdInContext = previousMessages[0]?.demande_support_id;

        if (demandeIdInContext === data?.message?.demande_support_id) {
          //NES je mets à jours les messages
          let index = previousMessages.findIndex(
            (item) => item._id === data?.message?._id
          );
          if (index > -1) {
            let tempArray = Array.from(previousMessages);
            tempArray.splice(index, 1, data?.message);
            return tempArray;
          } else {
            return [...previousMessages, data.message];
          }
        } else {
          //NES je ne change rien
          return previousMessages;
        }
      });
      //NES notification message recu
      let nomPrenom =
        data.message?.tracabilite_creation?.nom_utilisateur +
        " " +
        data.message?.tracabilite_creation?.prenom_utilisateur;

      let dataNotification = {
        type: "SUPPORT",
        titre: nomPrenom,
        texte: data.message.texte,
        avatar: {
          nom: data.message?.tracabilite_creation?.nom_utilisateur,
          prenom: data.message?.tracabilite_creation?.prenom_utilisateur,
          image: data.message?.tracabilite_creation?.avatar_utilisateur_S,
        },
        link: "/support/" + data?.demande?._id,
      };
      ajouterNotification(dataNotification);
    }
  };

  //USEEFFECT nb de demandes non lues
  useEffect(() => {
    let nb = 0;
    let listeNonLues = demandes.filter((item) => item.is_non_lue === true);
    nb = listeNonLues.length;

    setNbDemandesNonLues(nb);
  }, [demandes]);

  return (
    <SupportContext.Provider
      value={{
        ETAPES_SUPPORT,
        ETAT_DEMANDE,
        modeleDemande,
        demandes,
        getDemandes,
        libelleTypeDemande,
        libellePrioriteDemande,
        postDemandes,
        patchDemandes,
        deleteDemandes,
        iconeTypeDemande,
        getDemandesMessages,
        postDemandesMessages,
        messages,
        socketIoReceptionMessages,
        nbDemandesNonLues,
      }}
    >
      {props.children}
    </SupportContext.Provider>
  );
};
