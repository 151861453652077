import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";

const BeSwitch = withStyles({
  switchBase: {
    color: "#DDD",

    "&$checked": {
      color: "#0FA600",
    },
    "&$checked + $track": {
      backgroundColor: "#0FA600",
    },
  },
  checked: {},
  track: {},
})(Switch);

export default function InputSwitch({
  value,
  onChange,
  id,
  label,
  description,
  style,
  variant,
  disabled,
}) {
  //NES gestion du state
  const [checked, setChecked] = useState(false);

  //USEEFFECT
  useEffect(() => {
    setChecked(value);
  }, [value]);

  //NES styles
  const styleNormal = {
    minHeight: "40px",
    marginTop: "20px",
    display: "flex",
    flexDirection: "row",
    gap: "20px",
    alignItems: "center",
    justifyContent: "space-between",
    color: "black",
    ...style,
  };

  const styleFormulaire = {
    minHeight: "40px",
    marginTop: "0px",
    display: "flex",
    flexDirection: "row",
    gap: "20px",
    alignItems: "center",
    justifyContent: "space-between",
    color: "black",
    border: "1px solid #DDD",
    padding: "10px 0px 10px 15px",
    borderRadius: "4px",
    ...style,
  };

  const styleBloc = {
    minHeight: "40px",
    width: "100%",
    maxWidth: "100%",
    backgroundColor: value === true ? "#0FA60020" : "#DDDDDD80",
    border: value === true ? "1px solid #0FA600" : "1px solid #DDDDDD",
    borderRadius: "4px",
    padding: "10px 15px",
    boxSizing: "border-box",
    marginTop: "0px",
    display: "flex",
    flexDirection: "row",
    gap: "20px",
    alignItems: "center",
    justifyContent: "space-between",
    color: "black",
    cursor: "pointer",
    ...style,
  };

  const handleClick = (e) => {
    if (!disabled) {
      let newValue = !checked;
      setChecked(newValue);
      if (onChange) {
        onChange(newValue);
      }
    }
  };

  let MonStyle = styleNormal;
  if (variant === "bloc") {
    MonStyle = styleBloc;
  }
  if (variant === "formulaire") {
    MonStyle = styleFormulaire;
  }

  return (
    <div style={MonStyle}>
      <div style={{ margin: 0 }} onClick={handleClick}>
        <div style={{ color: disabled ? "#97989D" : "black" }}> {label}</div>
        <div className="InformationSecondaire">{description}</div>
      </div>
      <BeSwitch
        checked={checked}
        onChange={handleClick}
        id={id ? id : label}
        disabled={disabled}
      />
    </div>
  );
}
