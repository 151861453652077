import React from "react";
import { TextareaAutosize } from "@material-ui/core";
import MonStyle from "./InputTextArea.module.css";

export default function InputTextArea({
  value,
  onChange,
  onBlur,
  disabled,
  autoFocus,
  placeholder,
  onClick,
  minRows,
  ...props
}) {
  return (
    <TextareaAutosize
      className={MonStyle.TextArea}
      onChange={onChange}
      value={value}
      //id={"commentaire" + id}
      onBlur={onBlur}
      disabled={disabled}
      autoFocus={autoFocus}
      placeholder={placeholder}
      onClick={onClick}
      minRows={minRows}
      {...props}
    />
  );
}
