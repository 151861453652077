import React, { useContext, useState } from "react";
import MonStyle from "./LigneDemande.module.css";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ObjetDemande from "../../../Design/Objets/ObjetDemande";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import EventBusyIcon from "@material-ui/icons/EventBusy";
import DateRangeIcon from "@material-ui/icons/DateRange";
import SwipeableViews from "react-swipeable-views";
import Popover from "@mui/material/Popover";
import BoutonRond from "../../../Design/Boutons/BoutonRond";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import JourPlanningPicker from "./JourPlanningPicker";
import { AdpxContext } from "../../../Data/AdpxContext";
import { HashLink as Link } from "react-router-hash-link";

export default function LigneDemande({
  demande,
  onClickDemande,
  afficherDate,
}) {
  //NES gestion du contexte
  const { plannifierDemande, deplanniferDemande } = useContext(AdpxContext);

  //NES gestion du state
  const [index, setIndex] = useState(0);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const boutonActionClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setIndex(0);
    setAnchorEl(null);
  };

  //NES modification de la date
  const handleDateChange = (jour_id) => {
    console.log("handleDateChange", jour_id);
    plannifierDemande(demande, jour_id);
    handleClose();
  };

  //NES modification de la date
  const handleSupprimerDate = () => {
    console.log("handleSupprimerDate");

    deplanniferDemande(demande);

    handleClose();
  };

  if (!demande) {
    return <div className={MonStyle.LigneDisponible}> Disponible</div>;
  }

  return (
    <div className={MonStyle.LigneDemande}>
      <Link
        to={"/adpx/planning/" + demande?._id}
        style={{ width: "100%", paddingLeft: "10px" }}
      >
        <ObjetDemande
          taille="L"
          demande={demande}
          afficherDate={afficherDate}
        />
      </Link>

      <BoutonRond
        iconeComposant={<MoreVertIcon />}
        onClick={boutonActionClick}
        tooltip="Date d'intervention"
      />
      <Popover
        open={anchorEl ? true : false}
        anchorEl={anchorEl}
        onClose={handleClose}
        //onClick={handleClose}
        anchorOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
      >
        <div style={{ width: "260px", padding: "15px", maxHeight: "50vh" }}>
          <SwipeableViews index={index} animateHeight>
            {/* 0 : BLOC MENU */}
            <div>
              <ListItem button onClick={() => setIndex(1)}>
                <DateRangeIcon className="IconeMenu" />
                <ListItemText
                  primary={
                    demande.date_previsionnelle
                      ? "Modifier la date d'intervention"
                      : "Sélectionner la date d'intervention"
                  }
                />
                <ArrowForwardIosIcon style={{ color: "#DDD" }} />
              </ListItem>

              {demande.date_previsionnelle && (
                <ListItem
                  button
                  style={{ color: "red" }}
                  onClick={handleSupprimerDate}
                >
                  <EventBusyIcon
                    className="IconeMenu"
                    style={{ color: "red" }}
                  />
                  Déplannifier
                </ListItem>
              )}
            </div>
            {/* 1 : BLOC DATE */}
            <div>
              <JourPlanningPicker onSelect={handleDateChange} />
            </div>
          </SwipeableViews>
        </div>
      </Popover>
    </div>
  );
}
