import React, { useContext, useEffect, useState } from "react";
import { NotificationsContext } from "../../Data/NotificationsContext";
import BoutonActionSecondaire from "../../Design/Boutons/BoutonActionSecondaire";
import { DateToDateString } from "../../functions/FonctionsDateHeure";

export default function ClearCache({ taille, disabledAutoRefresh }) {
  //NES gestion du contexte
  const { ajouterNotification } = useContext(NotificationsContext);

  //NES gestion du state
  const [versionMeta, setVersionMeta] = useState();
  //const [versionProcess, setVersionProcess] = useState();
  const [dateMeta, setdateMeta] = useState();
  const [afficherForceUpdate, setAfficherForceUpdate] = useState(false);

  useEffect(() => {
    let versionCache = process.env.REACT_APP_VERSION;
    let versionDeploye = "";

    fetch("/meta.json")
      .then((response) => response.json())
      .then((meta) => {
        versionDeploye = meta?.buildVersion;

        //NES est ce que je dois forcer la mise à jour?
        if (versionDeploye !== versionCache) {
          setAfficherForceUpdate(true);

          //NES je permets de me pas forcer en automatique
          if (!disabledAutoRefresh) {
            refreshCacheAndReload();
          }
        }

        //NES dans le state pour l'affichage
        setVersionMeta(versionDeploye);
        setdateMeta(meta?.buildDate);
        // setVersionProcess(versionCache);
      });
    // eslint-disable-next-line
  }, []);

  ///Forcer le rafraichissement
  const refreshCacheAndReload = () => {
    if (typeof caches !== "undefined") {
      // Service worker cache should be cleared with caches.delete()
      caches.keys().then((names) => {
        for (const name of names) {
          caches.delete(name);
        }
      });
    }

    //NES on notifie l'utilisateur
    let dataNotification = {
      type: "UPDATE",
      titre: "Mise à jour automatique",
      sousTitre: "",
      texte: process.env.REACT_APP_NOM + " va être mis à jour automatiquement",
    };

    ajouterNotification(dataNotification);

    //NES et on fait un refresh
    setTimeout(() => {
      // delete browser cache and hard reload
      window.location.reload(true);
    }, 3000);
  };

  if (taille === "L") {
    return (
      <div
        style={{
          color: "#97989D",
          display: "flex",
          flexDirection: "column",
          gap: "0px",
          alignItems: "center",
        }}
      >
        <div>Dernière version disponible : {versionMeta}</div>
        <div>du {DateToDateString(dateMeta)}</div>

        {afficherForceUpdate && (
          <BoutonActionSecondaire
            texte="Mettre à jour"
            onClick={refreshCacheAndReload}
            fullWidth
            style={{ marginTop: "20px" }}
          />
        )}
      </div>
    );
  }

  return "";

  // if (window.location.hostname !== "localhost") {
  //   return "";
  // }

  // return (
  //   <div
  //     style={{
  //       color: "orange",
  //       fontSize: "13px",
  //       padding: "5px 10px ",
  //       border: "1px solid orange",
  //       borderRadius: "4px",
  //       background: "#FFFFFF20",
  //     }}
  //     onClick={refreshCacheAndReload}
  //   >
  //     <div>Meta: {versionMeta}</div>
  //     <div>Process : {versionProcess}</div>
  //   </div>
  // );
}
