import React, { useState, useEffect } from "react";
import MonStyle from "./PageCabinets.module.css";
import SurfacePapier from "../../../../Design/Surfaces/SurfacePapier";
import BoutonActionSecondaire from "../../../../Design/Boutons/BoutonActionSecondaire";
import { useContext } from "react";
import LoaderHorizontal from "../../../../Design/Progression/LoaderHorizontal";
import MessageErreur from "../../../../Design/Messages/MessageErreur";
import { CabinetsContext } from "../../../../Data/CabinetContext";
import ObjetCabinet from "../../../../Design/Objets/ObjetCabinet";
import MenuAction from "../../../../Design/Navigation/BoutonMenu";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { Link } from "react-router-dom";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import BoutonConfirmerSuppression from "../../../../Design/Boutons/BoutonConfirmerSuppression";
import BoutonRetour from "../../../../Design/Boutons/BoutonRetour";

export default function PageCabinets(params) {
  //NES Gestion du contexte
  const { cabinets, getCabinets, deleteCabinets } = useContext(CabinetsContext);

  //NES Gestion du state
  const [erreur, setErreur] = useState();
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [suppressionId, setSuppressionId] = useState("");

  useEffect(() => {
    setErreur();
    setIsLoading(true);
    getCabinets().then((reponse) => {
      setIsLoading(false);
      if (reponse.code !== "OK") {
        setErreur(reponse.message);
        setError(reponse);
      }
    });
    // eslint-disable-next-line
  }, []);

  //DELETE suppression du type de consultation
  const handleSupprimer = (cabinet_id) => {
    console.log("handleSupprimer", cabinet_id);
    setErreur("");
    deleteCabinets(suppressionId)
      .then((reponse) => {
        console.log(reponse);
        if (reponse.code !== "OK") {
          setErreur("Erreur lors de la suppression");
        }
      })
      .catch((error) => {
        console.log(error);
        setErreur("Erreur lors de la suppression");
      });
  };

  return (
    <SurfacePapier>
      <div className="MobileOnly">
        <BoutonRetour
          texte="Retour aux options"
          link="/options"
          style={{ marginBottom: "10px" }}
        />
      </div>

      <div className={MonStyle.TitreEtBouton}>
        <div>
          <div className="Titre1">Cabinets</div>
          <div className="SousTitre">
            Le cabinet apparait sur les impressions
          </div>
        </div>
        <div className={MonStyle.BoutonActionSecondaire}>
          <BoutonActionSecondaire
            texte="Ajouter un cabinet"
            taille="L"
            link="/options/cabinets/new"
            fullWidth
          />
        </div>
      </div>
      <LoaderHorizontal isLoading={isLoading} />
      <MessageErreur message={erreur} error={error} />
      <div className={MonStyle.ListeCabinets}>
        {cabinets.map((cabinet) => (
          <div className={MonStyle.Ligne} key={cabinet._id}>
            <Link
              to={"/options/cabinets/" + cabinet._id}
              className={MonStyle.colonneCabinet}
            >
              <ObjetCabinet cabinet={cabinet} />
            </Link>
            <BoutonConfirmerSuppression
              visible={cabinet._id === suppressionId}
              onAnnuler={() => setSuppressionId("")}
              onSupprimer={() => handleSupprimer(cabinet._id)}
              style={{
                position: "absolute",
                right: "0px",
                backgroundColor: "white",
                zIndex: "3",
              }}
            />
            <MenuAction tooltip="Modifier,supprimer">
              <ListItem
                button
                component={Link}
                to={"/options/cabinets/" + cabinet._id}
              >
                <EditIcon className="IconeMenu" />
                <ListItemText primary="Modifier" />
              </ListItem>
              <ListItem
                button
                style={{ color: "red" }}
                onClick={() => setSuppressionId(cabinet._id)}
              >
                <DeleteIcon className="IconeMenu" style={{ color: "red" }} />
                Supprimer
              </ListItem>
            </MenuAction>
          </div>
        ))}
      </div>
    </SurfacePapier>
  );
}
