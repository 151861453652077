import React, { useState, useEffect } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

export default function StripeSecret({ secret, ...props }) {
  // Make sure to call `loadStripe` outside of a component’s render to avoid
  // recreating the `Stripe` object on every render.

  const stripePromise = loadStripe(process.env.REACT_APP_KEY_STRIPE);

  //NES gestion du state
  const [options, setOptions] = useState();

  //USEEFFECT
  useEffect(() => {
    if (secret) {
      setOptions({
        // passing the client secret obtained in step 2
        clientSecret: secret,
        // Fully customizable with appearance API.
        appearance: {
          /*...*/
        },
      });
    }
  }, [secret]);

  if (!secret) {
    return "";
  }

  if (options && stripePromise) {
    return (
      <div>
        <Elements stripe={stripePromise} options={options}>
          {props.children}
          {/* 
           <CheckoutForm />
          <PaymentStatus />
         */}
        </Elements>
      </div>
    );
  }

  return "Chargement";
}
