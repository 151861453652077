import React, { useContext, useEffect, useState } from "react";
import MonStyle from "./PageAgendas.module.css";
import SurfacePage from "../../Design/Surfaces/SurfacePage";
import DatePicker from "../../Design/Pickers/DatePicker";
import { BarreMobileAgenda } from "../../Components/BarreOutilsMobile/BarreOutilsMobile";
import PageListeAgendas from "./ListeAgenda/PageListeAgendas";
import { Route, Switch } from "react-router-dom";
import PageAfficherAgenda from "./AfficherAgenda/PageAfficherAgenda";
import PageEditionAgenda from "./EditionAgenda/PageEditionAgenda";
import { AgendasContext } from "../../Data/AgendasContext";
import PageCalendrier from "./Calendrier/PageCalendrier";
import PageAllAgendas from "./AfficherAgenda/PageAllAgendas";
import BoutonRond from "../../Design/Boutons/BoutonRond";
import { IconeMasquerPanneau } from "../../Design/Icones/IconesActions";
import { isMobile } from "../../functions/Navigateur";
import { AuthentificationContext } from "../../Data/AuthentificationContext";
import { useHistory } from "react-router-dom";

export default function PageAgendas({ match }) {
  //NES récupération de l'ID dans l'URL
  let idUrl = match.params.id;

  //NES gestion du routage
  const history = useHistory();

  //NES Gestion du contexte
  const {
    setSelectedDate,
    selectedDate,
    setAgendaSelected,
    agendaSelected,
    agendaById,
    agendas,
    minimiser,
    setMinimiser,
  } = useContext(AgendasContext);
  const auth = useContext(AuthentificationContext);

  //NES gestion du state
  const [afficherMasquer, setAfficherMasquer] = useState(false);

  //NES je conserve l'agenda sélectionnné dans le contexte
  useEffect(() => {
    if (idUrl) {
      let agenda = agendaById(idUrl);
      setAgendaSelected(agenda);
    }

    //NES je redirige sur le premier agenda disponible
    if (agendas.length > 0 && !idUrl && !isMobile()) {
      let premierId = agendas[0]._id;
      history.push(`/agendas/${premierId}`);
    }

    //NES en cas de mobile je redirige sur l'agenda de mon utilisateur
    if (isMobile() && !idUrl) {
      let agendaUtilisateur = agendas.find(
        (item) => item.utilisateur_id === auth.payload.utilisateur_id
      );
      if (agendaUtilisateur?._id) {
        history.push(`/agendas/${agendaUtilisateur._id}`);
      }
    }

    // eslint-disable-next-line
  }, [idUrl, agendas]);

  //NES en cas de sélection d'une date
  const handleSelectDate = (date) => {
    setSelectedDate(date);
  };

  ///Quand je minimise le panneau des agendas
  const handleMiniser = () => {
    let newValue = !minimiser;
    setMinimiser(newValue);
  };

  return (
    <SurfacePage
      couleur="gris"
      disablePadding
      style={{
        display: "flex",
        flexDirection: "column",
        position: "relative",
        overflow: "scroll",
      }}
    >
      <main className={MonStyle.PageAgendas}>
        {minimiser ? (
          <nav
            className={MonStyle.PartieGaucheMinimise}
            style={{
              backgroundColor: agendaSelected?.couleur_agenda,
            }}
            onClick={handleMiniser}
          >
            <BoutonRond
              iconeComposant={<IconeMasquerPanneau />}
              couleurIcone="blanc"
              tooltip="Afficher le panneau de gauche"
              onClick={handleMiniser}
            />
            {agendaSelected?.nom_agenda}
          </nav>
        ) : (
          <nav className={MonStyle.PartieGauche}>
            <h1
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                marginBottom: "20px",
              }}
              onMouseEnter={() => setAfficherMasquer(true)}
              onMouseLeave={() => setAfficherMasquer(false)}
            >
              Agenda{" "}
              {afficherMasquer && (
                <BoutonRond
                  iconeComposant={<IconeMasquerPanneau />}
                  couleur="gris"
                  couleurIcone="noir"
                  onClick={() => setMinimiser(true)}
                  tooltip="Masquer le panneau de gauche"
                />
              )}
            </h1>
            <DatePicker onDateSelect={handleSelectDate} date={selectedDate} />
            <PageListeAgendas
              selectedId={idUrl}
              style={{ marginTop: "40px" }}
            />
          </nav>
        )}
        {/******************ROUTAGE DE LA PARTIE DROITE DE L'ECRAN*****************************/}
        <div className={MonStyle.PartieDroite}>
          <Switch>
            <Route path="/agendas/new/" exact component={PageEditionAgenda} />
            <Route path="/agendas/all/" exact component={PageAllAgendas} />
            <Route path="/agendas/:id/" exact component={PageAfficherAgenda} />
            <Route
              path="/agendas/:id/calendrier"
              exact
              component={PageCalendrier}
            />
            <Route
              path="/agendas/:id/edition"
              exact
              component={PageEditionAgenda}
            />
            <Route path="/agendas/:id/liste" exact>
              <PageListeAgendas selectedId={idUrl} />
            </Route>
          </Switch>
        </div>
      </main>
      <BarreMobileAgenda monAgendaId={idUrl} />
    </SurfacePage>
  );
}
