import React from "react";
import ObjetItem from "./ObjetItem";
import ApartmentIcon from "@material-ui/icons/Apartment";

export default function ObjetCabinet({ cabinet, color }) {
  const MonStyle = {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  };

  //NES SI je n'ai rien on affiche rien:)
  if (cabinet === undefined) return "";

  return (
    <div style={MonStyle}>
      <ApartmentIcon
        style={{
          marginRight: "10px",
          marginLeft: "15px",
          color: color ? color : "#205A71",
        }}
      />
      <ObjetItem
        couleurTexte={color ? color : ""}
        principale={
          cabinet?.nom
            ? cabinet.nom + " "
            : "" +
              cabinet?.adresse?.rue +
              ", " +
              cabinet?.adresse?.code_postal +
              " " +
              cabinet?.adresse?.ville
        }
        secondaire={
          cabinet?.numero_finess ? "n°finess: " + cabinet?.numero_finess : ""
        }
      />
    </div>
  );
}
