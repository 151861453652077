import React, { useState, createContext, useContext } from "react";

import { DELETE, PATCH, POST } from "../functions/FonctionsApi";
import { AuthentificationContext } from "./AuthentificationContext";
import { PerformancesContext } from "./PerformancesContext";

//NES Création du contexte
export const UtilisateursContext = createContext();

export const UtilisateursProvider = (props) => {
  //NES gestion du contexte
  const authContext = useContext(AuthentificationContext);
  const { GET } = useContext(PerformancesContext);

  //NES Gestion du state
  const [utilisateurs, setUtilisateurs] = useState([]);
  const [monOrganisation, setMonOrganisation] = useState();
  const [paymentMethod, setPaymentMethod] = useState();
  const [preferencesUtilisateur, setPreferencesUtilisateur] = useState({});

  //GET utilisateurs/
  const getUtilisateurs = async () => {
    //NES si j'ai des utlisateurs dans le contexte je le retourne directement
    if (utilisateurs.length > 1) {
      return { code: "OK", data: utilisateurs };
    }

    let MonUrl = `${process.env.REACT_APP_URL_API_UTILISATEURS}/utilisateurs`;
    let reponseAPI = await GET(MonUrl, authContext.token);
    //NES Je conserve dans le contexte
    if (reponseAPI.code === "OK") {
      setUtilisateurs(reponseAPI.data);
    }
    //NES je retourne à ma fonction appelante le résultat
    return reponseAPI;
  };

  //GET utilisateurs/
  const getUtilisateursById = async (utilisateur_id) => {
    let MonUrl = `${process.env.REACT_APP_URL_API_UTILISATEURS}/utilisateurs/${utilisateur_id}`;
    let reponseAPI = await GET(MonUrl, authContext.token);
    //NES je retourne à ma fonction appelante le résultat
    return reponseAPI;
  };

  //POST /utilisateurs/
  const postUtilisateurs = async (data) => {
    let MonUrl = `${process.env.REACT_APP_URL_API_UTILISATEURS}/utilisateurs/`;

    let reponseApi = await POST(MonUrl, authContext.token, data);
    //NES mise à jour du contexte
    if (reponseApi.code === "OK") {
      let tempArray = [...utilisateurs];
      tempArray.push(reponseApi.data);
      setUtilisateurs(tempArray);
    }
    return reponseApi;
  };

  //PATCH /utilisateurs/:id
  const patchUtilisateurs = async (utilisateur_id, data) => {
    let MonUrl = `${process.env.REACT_APP_URL_API_UTILISATEURS}/utilisateurs/${utilisateur_id}`;
    let reponseApi = await PATCH(MonUrl, authContext.token, data);
    //NES mise à jour du contexte
    if (reponseApi.code === "OK") {
      let tempArray = [...utilisateurs];
      let index = tempArray.findIndex(
        (item) => item.utilisateur_id === utilisateur_id
      );
      tempArray.splice(index, 1, reponseApi.data);
      setUtilisateurs(tempArray);
    }
    return reponseApi;
  };

  //DELETE /utilisateurs/:id
  const deleteUtilisateurs = async (utilisateur_id) => {
    let MonUrl = `${process.env.REACT_APP_URL_API_UTILISATEURS}/utilisateurs/${utilisateur_id}`;
    let reponseApi = await DELETE(MonUrl, authContext.token);
    //NES mise à jour du contexte
    if (reponseApi.code === "OK") {
      let tempArray = [...utilisateurs];
      let index = tempArray.findIndex(
        (item) => item.utilisateur_id === utilisateur_id
      );
      tempArray.splice(index, 1);
      setUtilisateurs(tempArray);
    }
    return reponseApi;
  };

  //GET /roles
  const getRoles = async () => {
    let MonUrl = `${process.env.REACT_APP_URL_API_UTILISATEURS}/roles`;
    let reponseApi = await GET(MonUrl, authContext.token);
    return reponseApi;
  };

  //GET /preferences
  const getPreferencesUtilisateur = async () => {
    //NES est ce que je les ai dejà dans le contexte
    if (
      preferencesUtilisateur?.owned_by_utilisateur ===
      authContext.token.utilisateur_id
    ) {
      return { code: "OK", data: preferencesUtilisateur };
    } else {
      let MonUrl = `${process.env.REACT_APP_URL_API_UTILISATEURS}/preferences`;
      let reponseApi = await GET(MonUrl, authContext.token);

      //NES je les garde dans le contexte
      if (reponseApi.code === "OK") {
        setPreferencesUtilisateur(reponseApi.data);
      }

      return reponseApi;
    }
  };

  //PATCH /preferences
  const patchPreferencesUtilisateur = async (data) => {
    let MonUrl = `${process.env.REACT_APP_URL_API_UTILISATEURS}/preferences`;
    let reponseApi = await PATCH(MonUrl, authContext.token, data);
    //NES mise à jour du contexte
    if (reponseApi.code === "OK") {
      setPreferencesUtilisateur(reponseApi.data);
    }
    return reponseApi;
  };

  //GET /facturation/abonnement
  const getfacturationAbonnement = async () => {
    let MonUrl = `${process.env.REACT_APP_URL_API_UTILISATEURS}/facturation/abonnement`;
    let reponseApi = await GET(MonUrl, authContext.token);
    return reponseApi;
  };

  //GET /facturation/factures
  const getfacturationfactures = async () => {
    let MonUrl = `${process.env.REACT_APP_URL_API_UTILISATEURS}/facturation/factures`;
    let reponseApi = await GET(MonUrl, authContext.token);
    return reponseApi;
  };
  //GET /facturation/factures
  const getfacturationfacturesById = async (facture_id) => {
    let MonUrl = `${process.env.REACT_APP_URL_API_UTILISATEURS}/facturation/factures/${facture_id}`;
    let reponseApi = await GET(MonUrl, authContext.token);
    return reponseApi;
  };

  //POST /facturation/debutabonnement
  const postDebutAbonnement = async () => {
    let MonUrl = `${process.env.REACT_APP_URL_API_UTILISATEURS}/facturation/debutabonnement `;
    let reponseApi = await POST(MonUrl, authContext.token);
    //NES je stoke mon organisation dans le contexte
    console.log(reponseApi);

    if (reponseApi.code === "OK") {
      // setMonOrganisation(reponseApi.organisation);
    }
    return reponseApi;
  };

  //GET /organisations
  const getOrganisations = async () => {
    let MonUrl = `${process.env.REACT_APP_URL_API_UTILISATEURS}/organisations/`;
    let reponseApi = await GET(MonUrl, authContext.token);
    return reponseApi;
  };

  //GET /organisations/:id (avec mon ID)
  const getMyOrganisation = async () => {
    if (monOrganisation?._id === authContext.payload.owned_by_organisation) {
      return { code: "OK", data: monOrganisation };
    } else {
      let MonUrl = `${process.env.REACT_APP_URL_API_UTILISATEURS}/organisations/${authContext.payload.owned_by_organisation}`;
      let reponseApi = await GET(MonUrl, authContext.token);

      //NES je stoke mon organisation dans le contexte
      if (reponseApi.code === "OK") {
        setMonOrganisation(reponseApi.data);
      }
      return reponseApi;
    }
  };

  //PATCH /organisations/:id (avec mon ID bien sur)
  const patchMyOrganisation = async (data) => {
    let MonUrl = `${process.env.REACT_APP_URL_API_UTILISATEURS}/organisations/${authContext.payload.owned_by_organisation}`;
    let reponseApi = await PATCH(MonUrl, authContext.token, data);
    //NES je stoke mon organisation dans le contexte
    if (reponseApi.code === "OK") {
      setMonOrganisation(reponseApi.data);
    }
    return reponseApi;
  };

  //POST /organisations/stripe/CreateCustomer
  const postCreateCustomer = async () => {
    let MonUrl = `${process.env.REACT_APP_URL_API_UTILISATEURS}/organisations/stripe/createcustomer`;
    let reponseApi = await POST(MonUrl, authContext.token);
    if (reponseApi.code === "OK") {
      setMonOrganisation(reponseApi.organisation);
    }

    return reponseApi;
  };

  //DELETE /organisations/stripe/paymentmethod
  const deletePaymentmethod = async () => {
    let MonUrl = `${process.env.REACT_APP_URL_API_UTILISATEURS}/organisations/stripe/paymentmethod`;
    let reponseApi = await DELETE(MonUrl, authContext.token);
    if (reponseApi.code === "OK") {
      setMonOrganisation(reponseApi.organisation);
      setPaymentMethod();
    }
    return reponseApi;
  };

  //GET  /organisations/stripe/paymentmethod
  const getPaymentmethod = async () => {
    let MonUrl = `${process.env.REACT_APP_URL_API_UTILISATEURS}/organisations/stripe/paymentmethod`;
    let reponseApi = await GET(MonUrl, authContext.token);
    if (reponseApi.code === "OK") {
      setMonOrganisation(reponseApi.organisation);
      setPaymentMethod(reponseApi.paymentMethods.data[0]);
    }

    return reponseApi;
  };

  return (
    <UtilisateursContext.Provider
      value={{
        utilisateurs,
        getUtilisateurs,
        getUtilisateursById,
        patchUtilisateurs,
        deleteUtilisateurs,
        getRoles,
        getfacturationAbonnement,
        postUtilisateurs,
        getfacturationfactures,
        getfacturationfacturesById,
        getOrganisations,
        getMyOrganisation,
        patchMyOrganisation,
        monOrganisation,
        postCreateCustomer,
        deletePaymentmethod,
        getPaymentmethod,
        paymentMethod,
        postDebutAbonnement,
        preferencesUtilisateur,
        getPreferencesUtilisateur,
        patchPreferencesUtilisateur,
      }}
    >
      {props.children}
    </UtilisateursContext.Provider>
  );
};
