import React, { useState, createContext, useEffect, useContext } from "react";
import { AuthentificationContext } from "./AuthentificationContext";
import { timeToMinutes, getHeuresJour } from "../functions/FonctionsDateHeure";
import { getJoursSemaine } from "../functions/FonctionsDateHeure";
import { CabinetsContext } from "./CabinetContext";
import { PerformancesContext } from "./PerformancesContext";

export const AgendasContext = createContext();

export const AgendasProvider = (props) => {
  let aujourdhui = new Date();
  aujourdhui.setHours(0);
  aujourdhui.setMinutes(0);
  aujourdhui.setSeconds(0);

  //NES gestion du contexte
  const authContext = useContext(AuthentificationContext);
  const { getObjetCabinet } = useContext(CabinetsContext);
  const { GET } = useContext(PerformancesContext);

  //NES Gestion du state surtout pour ne pas faire trop de boucles avec des recalculs
  const [agendas, setAgendas] = useState([]);
  const [agendaSelected, setAgendaSelected] = useState();
  const [selectedDate, setSelectedDate] = useState(aujourdhui);
  const [heureDebutAgendas, setHeureDebutAgendas] = useState("");
  const [heureFinAgendas, setHeureFinAgendas] = useState("");
  const [tailleRdvDefaut, setTailleRdvDefaut] = useState(30);
  const [datesSemaine, setDatesSemaine] = useState([]);
  const [hauteurMiniHeure, setHauteurMiniHeure] = useState(20);
  const [caseHoraires, setCaseHoraires] = useState([]);
  const [minimiser, setMinimiser] = useState(false);

  useEffect(() => {
    let dateReference = selectedDate;
    dateReference.setHours(0);
    dateReference.setMinutes(0);
    dateReference.setSeconds(0);

    calculJoursAffiches(dateReference);
    // eslint-disable-next-line
  }, [selectedDate]);

  //NES fonction calculant les jours à afficher
  const calculJoursAffiches = (jour) => {
    let listeJours = [];
    //NES je commence par récupérer toutes les dates de la semaine
    listeJours = getJoursSemaine(jour);

    //NES stockage dans le state
    setDatesSemaine(listeJours);
  };

  //NES récupération d'un agenda par son ID
  const agendaById = (id) => {
    // pour récupérer l'objet agenda avec son id
    let agenda = undefined;
    agenda = agendas.find((item) => item._id === id);
    return agenda;
  };

  //MODELE de l'agenda
  const modeleAgenda = () => {
    let cabinetConnexion = {};
    cabinetConnexion = getObjetCabinet();
    console.log({ cabinetConnexion });

    return {
      utilisateur_id: authContext.payload.utilisateur_id,
      agenda_id: "",
      nom_agenda: authContext.payload.nom + " " + authContext.payload.prenom,
      couleur_agenda: "#205A71",
      intervalle_minutes: "",
      jours_affiches: [1, 2, 3, 4, 5, 6],
      horaires_semaine: [
        {
          numero_jour: "all",
          jour_nom: "",
          matin_debut: "08:00",
          matin_fin: "12:30",
          apresmidi_debut: "14:00",
          apresmidi_fin: "20:00",
        },
      ],
      types_rendez_vous: [
        {
          libelle: "Consultation",
          couleur: "#D3E8F0",
          duree: "0:15",
          type_code: "consultation",
        },
        {
          libelle: "Visite à domicile",
          couleur: "#CFEECC",
          duree: "0:45",
          type_code: "domicile",
        },
        {
          libelle: "Urgence",
          couleur: "#F44336",
          duree: "0:30",
          type_code: "urgence",
        },
      ],
      email: {
        is_email_confirmation_rdv: true,
        is_email_rappel_rdv: true,
        email_delai_rappel_heures: 24,
        email_texte_personnalise: "",
      },
      sms: {
        is_sms_confirmation_rdv: false,
        is_sms_rappel_rdv: false,
        sms_delai_rappel_heures: 24,
        sms_texte_personnalise: "",
      },
      cabinet_id: cabinetConnexion.cabinet_id,
    };
  };

  //POST agenda/
  const postAgenda = async (agenda) => {
    //NES j'appelle l'API
    const MonUrl = `${process.env.REACT_APP_URL_API_AGENDA}/agendas/`;
    //NES pour détecter les appels en boucle
    console.log({ POST: MonUrl });
    const reponseApi = await fetch(MonUrl, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        token: authContext.token,
      },
      body: JSON.stringify(agenda),
    });
    //NES je convertis ce que je récupère en JSON
    const reponseJson = await reponseApi.json();
    //NES je le garde dans le contexte
    if (reponseJson.code === "OK") {
      let tempArray = [...agendas];
      tempArray.push(reponseJson.data);
      setAgendas(tempArray);
    }
    //NES je retourne à ma fonction appelante le résultat
    return reponseJson;
  };

  //PATCH agenda/:id
  const patchAgenda = async (agenda) => {
    console.log(agenda);

    //NES j'appelle l'API
    const MonUrl = `${process.env.REACT_APP_URL_API_AGENDA}/agendas/${agenda._id}`;
    //NES pour détecter les appels en boucle
    console.log({ PATCH: MonUrl });
    const reponseApi = await fetch(MonUrl, {
      method: "PATCH",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        token: authContext.token,
      },
      body: JSON.stringify(agenda),
    });
    //NES je convertis ce que je récupère en JSON
    const reponseJson = await reponseApi.json();
    //NES je le garde dans le contexte

    if (reponseJson.code === "OK") {
      let tempArray = [...agendas];
      let index = tempArray.findIndex((item) => item._id === agenda._id);
      tempArray.splice(index, 1, reponseJson.data);
      setAgendas(tempArray);
    }
    //NES je retourne à ma fonction appelante le résultat
    return reponseJson;
  };

  //DELETE agenda/:id
  const deleteAgenda = async (agenda_id) => {
    //NES j'appelle l'API
    const MonUrl = `${process.env.REACT_APP_URL_API_AGENDA}/agendas/${agenda_id}`;
    //NES pour détecter les appels en boucle
    console.log({ DELETE: MonUrl });
    const reponseApi = await fetch(MonUrl, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        token: authContext.token,
      },
    });
    //NES je convertis ce que je récupère en JSON
    const reponseJson = await reponseApi.json();
    //NES Mise à jour du contexte
    if (reponseJson.code === "OK") {
      let tempArray = [...agendas];
      let index = tempArray.findIndex((item) => item._id === agenda_id);
      tempArray.splice(index, 1);
      setAgendas(tempArray);
    }
    //NES je retourne à ma fonction appelante le résultat
    return reponseJson;
  };

  //GET agendas/
  const getAgendas = async () => {
    //NES je regarde dans le contexte si j'ai déjà les agendas
    if (agendas.length > 0) {
      return { code: "OK", data: agendas };
    } else {
      //NES j'appelle l'API
      const MonUrl = `${process.env.REACT_APP_URL_API_AGENDA}/agendas`;
      let reponseJson = await GET(MonUrl, authContext.token);
      //NES je conserve la liste dans le contexte
      if (reponseJson.code === "OK") {
        setAgendas(reponseJson.data);
      }
      //NES je retourne à ma fonction appelante le résultat
      return reponseJson;
    }
  };

  //GET agendas/:Id
  const getAgendaById = async (agenda_id) => {
    //NES je regarde dans le contexte
    let agenda = undefined;
    agenda = agendas.find((item) => item._id === agenda_id);

    if (agenda) {
      return { code: "OK", data: agenda };
    }

    //NES j'appelle l'API
    const MonUrl = `${process.env.REACT_APP_URL_API_AGENDA}/agendas/${agenda_id}`;

    const reponseJson = await GET(MonUrl, authContext.token);

    //NES je retourne à ma fonction appelante le résultat
    return reponseJson;
  };

  //USEEFFECT
  useEffect(() => {
    if (agendas) {
      //NES je cherche l'heure mini et maxi des agendas pour l'affichage
      let heureMin = "24:00";
      let heureMax = "00:00";
      agendas.forEach((agenda) => {
        agenda.horaires_semaine.forEach((element) => {
          if (element.matin_debut < heureMin && element.matin_debut !== "-") {
            heureMin = element.matin_debut;
          }
          if (
            element.apresmidi_fin > heureMax &&
            element.apresmidi_fin !== "-"
          ) {
            heureMax = element.apresmidi_fin;
          }
        });
      });
      //NES je prends une heure de marge avant et après pour les imprévus et je les stockes dans le contexte
      //heureMin = addHeuresMinutes(heureMin, -1, 0);
      //heureMax = addHeuresMinutes(heureMax, 1, 0);
      setHeureDebutAgendas(heureMin);
      setHeureFinAgendas(heureMax);

      //NES je vais calculer une fois pour toutes les cases horaires
      let listesHoraires = [];
      agendas.forEach((agenda) => {
        let objConsultation = agenda.types_rendez_vous[0];

        //NES je récupére l'intervalle paramétré dans l'agenda sinon c'est 15 minutes
        let intervalHoraire = 15;

        if (agenda.intervalle_minutes) {
          intervalHoraire = parseInt(agenda.intervalle_minutes, 10);
        } else {
          if (objConsultation) {
            intervalHoraire = timeToMinutes(objConsultation.duree);
          }
        }

        let listesCases = getHeuresJour(heureMin, heureMax, intervalHoraire);

        let objHoraires = {
          agenda_id: agenda._id,
          listeCases: listesCases,
        };

        listesHoraires.push(objHoraires);
      });
      //NES je stocke les case horaires calculées dans le contexte
      setCaseHoraires(listesHoraires);
    }
  }, [agendas]);

  //NES Durée par défaut (consultation) par agenda
  const getDureeConsultation = (agenda_id) => {
    let retour = "0:15";
    let agenda = agendas.find((item) => item._id === agenda_id);
    if (agenda) {
      let typeConsulation = agenda.types_rendez_vous.find(
        (item) => item.type_code === "consultation"
      );
      retour = typeConsulation.duree;
    }
    return retour;
  };

  //NES Durée par défaut (consultation) par agenda
  const getDureePlage = (agenda_id) => {
    let retour = "0:15";
    let agenda = agendas.find((item) => item._id === agenda_id);
    if (agenda) {
      let typeConsulation = agenda.types_rendez_vous.find(
        (item) => item.type_code === "consultation"
      );
      let nbMinutes = timeToMinutes(typeConsulation.duree);

      if (nbMinutes > 30) {
        retour = "0:15";
      } else {
        retour = typeConsulation.duree;
      }
    }
    return retour;
  };

  //NES fonction permettant d'obtenir la position top selon l'agenda pour une date et heure
  const calculPositionTop = (agenda_id, date, hauteurHeure) => {
    //permet de positionner une heure sur une plage de temps en retournant sa position en px
    //Je la place dans le context car tous les paramètres dépendent d'un agenda
    let retour = 0;
    if (date && agenda_id) {
      //NES gestion de mes constantes
      let start = {
        heure: heureDebutAgendas.split(":")[0],
        minute: heureDebutAgendas.split(":")[1],
      };
      let end = {
        heure: heureFinAgendas.split(":")[0],
        minute: heureFinAgendas.split(":")[1],
      };

      let nbPxParHeure = hauteurHeure;

      //NES un peu de calcul pour positionner
      let timestampDebut = new Date(date);
      timestampDebut.setHours(start.heure);
      timestampDebut.setMinutes(start.minute);
      let timestampFin = new Date(date);
      timestampFin.setHours(end.heure);
      timestampFin.setMinutes(end.minute);
      let timestampNow = new Date(date);
      let positionFin = (end.heure - start.heure) * nbPxParHeure;
      let coefficientDirecteur = (timestampFin - timestampDebut) / positionFin;
      let timestampToPx = timestampNow - timestampDebut;
      let MapositionTop = Math.round(timestampToPx / coefficientDirecteur) + 2;
      retour = MapositionTop;
    }
    return retour;
  };

  const getListeCasesHoraires = (agenda_id) => {
    //Retourne la liste des cases horaires affichées dans l'agenda
    let retour = [];
    let index = caseHoraires.findIndex((item) => item.agenda_id === agenda_id);

    if (index > -1) {
      //NES je retourne sans recalculer
      retour = caseHoraires[index].listeCases;
    }
    return retour;
  };

  //GET agendas/preferences/
  const getPreferences = async () => {
    //Permet de récupérer les préférences de mon utilisateur
    const MonUrl = `${process.env.REACT_APP_URL_API_AGENDA}/agendas/preferences`;

    let reponseJson = await GET(MonUrl, authContext.token);

    //NES mise à jour du contexte
    if (reponseJson.code === "OK") {
      setHauteurMiniHeure(reponseJson.data.affichage_taille_rdv);
    }
    //NES je retourne à ma fonction appelante le résultat
    return reponseJson;
  };

  //PATCH agendas/preferences
  const patchPreferences = async (data) => {
    //Permet de mettre à jour les préférence de mon utilisateur
    const MonUrl = `${process.env.REACT_APP_URL_API_AGENDA}/agendas/preferences`;
    //NES pour détecter les appels en boucle
    console.log({ MonUrl });
    //NES je récupère les data depuis l'API
    const reponseApi = await fetch(MonUrl, {
      method: "PATCH",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        token: authContext.token,
      },
      body: JSON.stringify(data),
    });
    //NES je convertis ce que je récupère en JSON
    const reponseJson = await reponseApi.json();
    //NES je retourne à ma fonction appelante le résultat
    return reponseJson;
  };

  //GET Académies pour les congés scolaires
  const getAcademiesVacances = async () => {
    //NES j'appelle l'API
    const MonUrl = `${process.env.REACT_APP_URL_API_VACANCES}/records/1.0/search/?dataset=fr-en-calendrier-scolaire&facet=location&facet=description&facet=zones&facet=annee_scolaire&refine.end_date=2022&exclude.population=Enseignants`;
    //NES pour détecter les appels en boucle
    console.log({ GET: MonUrl });
    const reponseApi = await fetch(MonUrl, {
      method: "GET",
      headers: {
        Accept: "application/json",
        // "Content-Type": "application/json",
        //token: authContext.token,
      },
    });

    //NES je convertis ce que je récupère en JSON
    const reponseJson = await reponseApi.json();

    console.log(reponseJson);
    //NES je converti mon résultat en évenements
    let listeGroupes = [];
    listeGroupes = reponseJson.facet_groups.find(
      (item) => item.name === "location"
    );
    let listeAcademies = listeGroupes.facets.map((item) => item.name);
    listeAcademies.sort();

    //NES je retourne à ma fonction appelante le résultat
    return listeAcademies;
  };

  return (
    <AgendasContext.Provider
      value={{
        modeleAgenda,
        agendas,
        agendaSelected,
        setAgendaSelected,
        agendaById,
        postAgenda,
        patchAgenda,
        deleteAgenda,
        getAgendas,
        getAgendaById,
        selectedDate,
        setSelectedDate,
        heureDebutAgendas,
        heureFinAgendas,
        tailleRdvDefaut,
        setTailleRdvDefaut,
        getDureeConsultation,
        getDureePlage,
        calculPositionTop,
        datesSemaine,
        setDatesSemaine,
        calculJoursAffiches,
        hauteurMiniHeure,
        setHauteurMiniHeure,
        getPreferences,
        patchPreferences,
        getListeCasesHoraires,
        getAcademiesVacances,
        minimiser,
        setMinimiser,
      }}
    >
      {props.children}
    </AgendasContext.Provider>
  );
};
