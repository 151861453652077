import React, { useState } from "react";
import InputBase from "@material-ui/core/InputBase";
import { alpha, makeStyles } from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },

  search: {
    color: "white",
    position: "relative",
    borderRadius: "20px",
    backgroundColor: "#FFFFFF10",
    placeholder: "#FFFFFFF",
    height: "40px",
    alignItems: "center",
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    //padding: theme.spacing(1, 1, 1, 0),
    paddingTop: "10px",
    paddingRight: "20px",
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,

    transition: theme.transitions.create("width"),
    //width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "100px",
      "&:focus": {
        width: "240px",
      },
    },
  },
}));

export default function InputRechercheEntete({
  isDeletedAfterSearch,
  onRecherche,
}) {
  const classes = useStyles();

  //NES Gestion du state
  const [texte, setTexte] = useState("");

  //NES en cas de lancement de la recherche (ENTREE)
  const rechercher = (event) => {
    if (onRecherche) {
      onRecherche(event);
      if (isDeletedAfterSearch) {
        setTexte("");
      }
    }
  };

  return (
    <div className={classes.search}>
      <div className={classes.searchIcon}>
        <SearchIcon />
      </div>
      <InputBase
        value={texte}
        name="Rechercher"
        onKeyPress={(event) =>
          window.event.keyCode === 13 ? rechercher(event) : ""
        }
        onChange={(e) => setTexte(e.target.value)}
        placeholder="Rechercher"
        classes={{
          root: classes.inputRoot,
          input: classes.inputInput,
        }}
        inputProps={{ "aria-label": "search" }}
      />
    </div>
  );
}
