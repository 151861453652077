import React, { useEffect, useContext, useState } from "react";
import SurfacePapier from "../../../Design/Surfaces/SurfacePapier";
import CloudDoneIcon from "@material-ui/icons/CloudDone";
import { AuthentificationContext } from "../../../Data/AuthentificationContext";
import CloudOffIcon from "@material-ui/icons/CloudOff";
import CloudQueueIcon from "@material-ui/icons/CloudQueue";
import MonStyle from "./HelloApi.module.css";

export default function HelloApi({ url, nom, style }) {
  //NES gestion du contexte
  const authContext = useContext(AuthentificationContext);

  //NES gestion du state
  const [isConnected, setIsConnected] = useState(false);
  const [isLoading, setIsloading] = useState(true);
  const [messageErreur, setMessageErreur] = useState("");
  const [version, setVersion] = useState("");

  //USEEFFECT
  useEffect(() => {
    if (url) {
      getHello(url)
        .then((reponse) => {
          setIsloading(false);
          if (reponse.code === "OK") {
            setIsConnected(true);
            setVersion(reponse.data.version);
          } else {
            setMessageErreur(reponse.data.message);
            setIsConnected(false);
          }
        })
        .catch((error) => {
          setIsConnected(false);
          setIsloading(false);
        });
    }
    // eslint-disable-next-line
  }, [url]);

  //GET hello/
  const getHello = async (url) => {
    //NES j'appelle l'API
    let MonUrl = `${url}/hello`;
    //NES pour détecter les appels en boucle
    console.log({ GET: MonUrl });
    const reponseApi = await fetch(MonUrl, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        token: authContext.token,
      },
    });
    //NES je convertis ce que je récupère en JSON
    const reponseJson = await reponseApi.json();
    //NES je retourne à ma fonction appelante le résultat
    return reponseJson;
  };

  return (
    <div className={MonStyle.HelloApi}>
      <SurfacePapier style={style}>
        <div className={MonStyle.Content}>
          <div className={MonStyle.Icone}>
            {!isConnected && !isLoading && (
              <CloudOffIcon style={{ fontSize: "inherit", color: "red" }} />
            )}
            {isConnected && (
              <CloudDoneIcon
                style={{ fontSize: "inherit", color: "#0FA600" }}
              />
            )}
            {isLoading && (
              <CloudQueueIcon style={{ fontSize: "inherit", color: "#DDD" }} />
            )}
          </div>

          <div className={MonStyle.Textes}>
            <div className="InformationPrincipaleImportante">{nom}</div>
            <div className="InformationSecondaire">{url}</div>
            <div className="InformationSecondaire">{version}</div>
            <div className="InformationSecondaire" style={{ color: "red" }}>
              {messageErreur}
            </div>
          </div>
        </div>
      </SurfacePapier>
    </div>
  );
}
