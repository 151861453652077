import React from "react";

export default function IconeMui({ nom, color, filled, debug, ...props }) {
  const style = {
    fontFamily: "Material Symbols Rounded",
    fontVariationSettings: filled
      ? '"FILL" 1, "wght" 400, "GRAD" 0, "opsz" 24'
      : '"FILL" 0, "wght" 400, "GRAD" 0, "opsz" 24',

    border: debug ? "1px solid green" : "none",
    //minWidth: "50px",
    fontSize: "30px",
    textAlign: "center",
    padding: "2px 5px",
    color: color,
    // fontWeight: "normal",
    // fontStyle: "normal",
    // letterSpacing: "normal",
    WebkitFontSmoothing: "antialiased",
    WebkitFontFeatureSettings: "liga",
  };

  return <div style={style}>{nom}</div>;
}
