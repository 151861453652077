import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

export function IconeMasquerPanneau(props) {
  return (
    <SvgIcon {...props}>
      <path
        d="M20,2 C21.1045695,2 22,2.8954305 22,4 L22,20 C22,21.1045695 21.1045695,22 20,22 L4,22 C2.8954305,22 2,21.1045695 2,20 L2,4 C2,2.8954305 2.8954305,2 4,2 L20,2 Z M9,4 L9,20 L20,20 L20,4 L9,4 Z"
        id="Combined-Shape"
      ></path>
    </SvgIcon>
  );
}
