import React, { useState, useContext } from "react";
import MonStyle from "./EditionSupprimerRdv.module.css";
import ObjetItem from "../../../../../Design/Objets/ObjetItem";
import ThumbUpAltIcon from "@material-ui/icons/ThumbUpAlt";
import ThumbDownAltIcon from "@material-ui/icons/ThumbDownAlt";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import BoutonActionPrincipale from "../../../../../Design/Boutons/BoutonActionPrincipale";
import EditionRappel from "./EditionRappel";
import SurfaceBloc from "../../../../../Design/Surfaces/SurfaceBloc";
import { RdvContext } from "../../../../../Data/RdvContext";
import MessageErreur from "../../../../../Design/Messages/MessageErreur";
import { MessagesContext } from "../../../../../Data/MessagesContext";

export default function EditionSupprimerRdv({ rdv, onSupprimer }) {
  //NES gestion du state
  const [code, setCode] = useState("");
  const [couleur, setCouleur] = useState("");
  const [texte, setTexte] = useState("Annuler le rendez-vous");
  const [numeroPortable, setNumeroPortable] = useState("");
  const [email, setEmail] = useState("");
  const [messageErreur, setMessageErreur] = useState("");
  const [error, setError] = useState("");

  //NES gestion du contexte
  const { deleteRdv, patchRdv } = useContext(RdvContext);
  const { envoyerEmailConfirmationRdv, envoyerSmsConfirmationRdv } =
    useContext(MessagesContext);

  ///handleSelect
  const handleSelect = (code) => {
    setCode(code);

    switch (code) {
      case "NON_HONORE":
        setCouleur("#F44336");
        setTexte("Annuler le rendez-vous");
        break;
      case "EXCUSE_DERNIERE_MINUTE":
        setCouleur("#FF9800");
        setTexte("Annuler le rendez-vous");

        break;
      case "EXCUSE":
        setCouleur("#0FA600");
        setTexte("Annuler le rendez-vous");

        break;
      case "DELETE":
        setCouleur("red");
        setTexte("Supprimer le rendez-vous");
        break;
      default:
        setCouleur("red");
        setTexte("Supprimer le rendez-vous");
    }
  };

  ///handleChangeTelephoneRappel
  const handleChangeTelephoneRappel = (numeroTelephone) => {
    setNumeroPortable(numeroTelephone);
  };

  ///handleChangeEmailRappel
  const handleChangeEmailRappel = (email) => {
    setEmail(email);
  };

  ///handleDeleteRdv
  const handleDeleteRdv = () => {
    let annulation_rdv = {
      mode: code,
      date: new Date(),
      motif: "",
      utilisateur_id: "",
    };
    let newData = { ...rdv, annulation_rdv: annulation_rdv };

    if (code === "DELETE") {
      deleteRdv(rdv.agenda_id, rdv._id).then((reponse) => {
        if (reponse.code === "OK") {
          if (onSupprimer) {
            onSupprimer();
          }
        } else {
          setMessageErreur("Erreur lors de la suppression du rdv");
          setError(reponse);
        }
      });
    } else {
      patchRdv(rdv.agenda_id, rdv._id, newData).then((reponse) => {
        if (reponse.code === "OK") {
          if (onSupprimer) {
            onSupprimer();
          }
        } else {
          setMessageErreur("Erreur lors de l'archivage du rdv");
          setError(reponse);
        }
      });
    }

    if (numeroPortable) {
      envoyerSmsConfirmationRdv(numeroPortable, "ANNULATION", newData);
    }
    if (email) {
      envoyerEmailConfirmationRdv(email, "ANNULATION", newData);
    }
  };

  if (messageErreur) {
    return <MessageErreur taille="L" message={messageErreur} error={error} />;
  }

  return (
    <div className={MonStyle.EditionSupprimerRdv}>
      <SurfaceBloc className={MonStyle.ChoixCode}>
        <ListItem
          button
          className={MonStyle.Choix}
          onClick={() => handleSelect("NON_HONORE")}
          selected={code === "NON_HONORE"}
        >
          <ThumbDownAltIcon
            style={{ color: "#F44336" }}
            className="IconeMenu"
          />
          <ObjetItem
            principale=" Rdv non excusé"
            secondaire="Le patient n'est pas venu au rendez-vous, il n'a pas prévenu. "
          />
        </ListItem>
        <Divider style={{ marginLeft: "50px" }} />
        <ListItem
          button
          className={MonStyle.Choix}
          onClick={() => handleSelect("EXCUSE_DERNIERE_MINUTE")}
          selected={code === "EXCUSE_DERNIERE_MINUTE"}
        >
          <ThumbDownAltIcon
            style={{ color: "#FF9800" }}
            className="IconeMenu"
          />
          <ObjetItem
            principale=" Rdv excusé à la dernière minute"
            secondaire="Le patient a prévenu à la dernière minute qu'il ne viendrait pas."
          />
        </ListItem>
        <Divider style={{ marginLeft: "50px" }} />
        <ListItem
          button
          className={MonStyle.Choix}
          onClick={() => handleSelect("EXCUSE")}
          selected={code === "EXCUSE"}
        >
          <ThumbUpAltIcon style={{ color: "#0FA600" }} className="IconeMenu" />
          <ObjetItem
            principale="Rdv excusé"
            secondaire="Le patient a prévenu qu'il ne pourrait pas venir au rendez-vous."
          />
        </ListItem>
        <Divider style={{ marginLeft: "50px" }} />
        <ListItem
          button
          className={MonStyle.Choix}
          onClick={() => handleSelect("DELETE")}
          selected={code === "DELETE"}
        >
          <DeleteForeverIcon style={{ color: "red" }} className="IconeMenu" />
          <ObjetItem
            principale="Supprimer le rendez-vous"
            couleurPrincipale="red"
            secondaire="Le rendez vous sera effacé de l'agenda"
          />
        </ListItem>
      </SurfaceBloc>
      <EditionRappel
        monRdv={rdv}
        isSuppression
        titre="Confirmation annulation rdv"
        codeSuppression={code}
        onMailConfirmationChange={handleChangeEmailRappel}
        onTelephoneConfirmationChange={handleChangeTelephoneRappel}
      />
      <BoutonActionPrincipale
        couleur={couleur}
        fullWidth
        texte={texte}
        disabled={!code}
        taille="L"
        onClick={handleDeleteRdv}
      />
    </div>
  );
}
