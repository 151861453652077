import React, { useState } from "react";
import MonStyle from "./ObjetMessageErreur.module.css";
import AppTooltip from "../../../Design/DataDisplay/ToolTip";
import IconesMateriel from "../../../Design/Icones/IconesMateriel";
import { DateEtHeureToString } from "../../../functions/FonctionsDateHeure";
import { capitalize } from "../../../functions/Formatter";
import SurfacePopUp from "../../../Design/Surfaces/SurfacePopUp";

export default function ObjetMessageErreur({ messageErreur }) {
  //NES gestion du state
  const [open, setOpen] = useState(false);

  return (
    <div className={MonStyle.MessageErreur}>
      <div className={MonStyle.InfoMessage} onClick={() => setOpen(true)}>
        <AppTooltip texte={messageErreur.user_agent}>
          <div className={MonStyle.IconeMateriel}>
            <IconesMateriel
              code={messageErreur.materiel}
              style={{ fontSize: "30px" }}
            />
          </div>
        </AppTooltip>
        <div className={MonStyle.InfoErreur}>
          <div className={MonStyle.Message}>{messageErreur.message}</div>
          <div className={MonStyle.Url}>{messageErreur.url}</div>
          <div className={MonStyle.Error} style={{ color: "red" }}>
            {messageErreur?.error?.error}
          </div>
        </div>
      </div>

      <div className={MonStyle.InfoUtilisateur}>
        <div className={MonStyle.DateHeure}>
          {DateEtHeureToString(messageErreur.date)}
        </div>
        <div className={MonStyle.Organisation}>
          {messageErreur.utilisateur_payload?.owned_by_organisation}
        </div>
        <div className={MonStyle.Utilisateur}>
          {capitalize(messageErreur.utilisateur_payload?.prenom) +
            " " +
            messageErreur.utilisateur_payload?.nom}
        </div>
      </div>
      <SurfacePopUp open={open} onClose={() => setOpen(false)}>
        <div>{messageErreur?.error?.message}</div>
        <div>{DateEtHeureToString(messageErreur?.error?.time)}</div>
        <div>{messageErreur?.error?.info?.api_name}</div>
        <div>{messageErreur?.error?.info?.api_version}</div>
        <div>{messageErreur?.error?.code}</div>
        <div>{messageErreur?.error?.error}</div>
        <div> {messageErreur?.error?.technical_stack}</div>
      </SurfacePopUp>
    </div>
  );
}
