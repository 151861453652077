import React from "react";
import MonStyle from "./ObjetPatients.module.css";
import ObjetIconePatient from "./ObjetIconePatient";
import { capitalize } from "../../functions/Formatter";

export default function ObjetPatient({
  patient,

  color,
  taille,
  isAfficherDateNaissance,
  isAfficherSexe,
  isArchive,
  onClick,
  style,
  iconeOpacity,
}) {
  let strNomJeuneFille = "";
  let strPrenom = "prenom";
  let strAge = "";
  let strSexe = patient?.sexe && isAfficherSexe ? patient?.sexe : "";
  let strDateNaissance = "";

  //NES Gestion du nom de jeune fille
  strNomJeuneFille = "";
  if (patient?.nom_jeune_fille) {
    strNomJeuneFille = "(" + patient?.nom_jeune_fille.toUpperCase() + ") ";
  }
  //NES Gestion du prénom
  strPrenom = patient?.prenom ? capitalize(patient?.prenom) : "";
  //NES gestion de la date de naissance
  strDateNaissance = "";
  if (patient?.date_de_naissance && isAfficherDateNaissance) {
    if (patient?.sexe === "Femme") {
      strDateNaissance = "née le " + patient?.date_de_naissance + " ";
    } else {
      strDateNaissance = "né le " + patient?.date_de_naissance + " ";
    }
  }
  //NES gestion de l'age
  strAge = patient?.age ? patient?.age.nombre + " " + patient?.age.unite : "";

  if (taille === "S") {
    return (
      <div onClick={onClick} className={MonStyle.ObjetPatientS}>
        <ObjetIconePatient
          sexe={patient?.sexe}
          age={patient?.age}
          taille={taille}
          id={
            "icone_" +
            patient?.nom?.toLowerCase() +
            "_" +
            patient?.prenom?.toLowerCase()
          }
        />
        <span
          className="InformationPrincipaleImportante"
          style={{ marginLeft: "5px" }}
        >
          {patient?.nom?.toUpperCase()} {strNomJeuneFille}
        </span>
        <span className="InformationPrincipaleImportante"> {strPrenom}</span>
        <span className={MonStyle.SexeAge}>
          {" - "}
          {strDateNaissance} {strAge}
        </span>
      </div>
    );
  }

  if (taille === "L") {
    return (
      <div className={MonStyle.ObjetPatientL}>
        <ObjetIconePatient
          iconeOpacity={iconeOpacity}
          sexe={patient?.sexe}
          age={patient?.age}
          taille={taille}
          id={"icone_" + patient?.nom?.toLowerCase() + "_" + strPrenom}
        />
        <div className={MonStyle.PatientL} style={style}>
          <div className={MonStyle.NomL}>
            {patient?.nom?.toUpperCase()} {strNomJeuneFille}
            <span className={MonStyle.PrenomL}>{strPrenom}</span>
          </div>
          <div className={MonStyle.SecondaireL}>
            {strDateNaissance} {strAge} {strSexe}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={MonStyle.ObjetPatient} onClick={onClick}>
      <div className="IconeObjet">
        <ObjetIconePatient
          sexe={patient?.sexe}
          age={patient?.age}
          taille={taille}
          id={
            "icone_" +
            patient?.nom?.toLowerCase() +
            "_" +
            patient?.prenom?.toLowerCase()
          }
        />
      </div>

      <div>
        <div
          className={
            isArchive
              ? MonStyle.InformationPrincipaleArchive
              : MonStyle.InformationPrincipale
          }
          style={{ color: color ? color : "" }}
        >
          {patient?.nom?.toUpperCase()} {strNomJeuneFille}
          <span className={MonStyle.Prenom}> {strPrenom}</span>
        </div>
        <div
          style={{ color: color ? color : "#97989D" }}
          className={MonStyle.InformationSecondaire}
        >
          {strDateNaissance} {strAge} {strSexe}
          {isArchive && <span className={MonStyle.Archive}>Archivé</span>}
        </div>
      </div>
    </div>
  );
}
