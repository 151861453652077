import React, { useContext, useEffect, useState, useRef } from "react";
import MonStyle from "./PageDocuments.module.css";
import BoutonRond from "../../../../Design/Boutons/BoutonRond";
import AddIcon from "@material-ui/icons/Add";
import InputRecherche from "../../../../Design/Recherche/InputRecherche";
import FiltreTypeDocument from "../../../../Design/Recherche/Filtres/FiltreTypeDocument";
import { DocumentsContext } from "../../../../Data/DocumentsContext";
import LigneResultat from "../../../../Design/Recherche/LigneResultat";
import EnteteTableau from "../../../../Design/Tableaux/EnteteTableau";
import EnteteColonne from "../../../../Design/Tableaux/EnteteColonne";
import ObjetDocument from "../../../../Design/Objets/ObjetDocument";
import BoutonConfirmerSuppression from "../../../../Design/Boutons/BoutonConfirmerSuppression";
import MessageAucuneDonnee from "../../../../Design/Messages/MessageAucuneDonnee";
import MessageErreur from "../../../../Design/Messages/MessageErreur";
import { DateOuHeureToString } from "../../../../functions/FonctionsDateHeure";

export default function PageDocuments({ match }) {
  ///NES récupération des paramètres de l'URL
  const url_patient_id = match.params.patient_id;

  //NES gestion du state
  const [listeDocuments, setListeDocuments] = useState([]);
  const [tri, setTri] = useState({
    colonne: "DATE",
    ordre: "az",
  });
  const [texteRecherche, setTexteRecherche] = useState("");
  const [confimerSuppression, setConfirmerSuppression] = useState();
  const [erreur, setErreur] = useState("");
  const [error, setError] = useState();
  const [filtreType, setFiltreType] = useState("");
  const [afficherMessage, setAfficherMessage] = useState("");

  //NES Gestion du contexte
  const {
    documents,
    getDocumentsPatient,
    ajouterDocumentsUploadContext,
    deleteDocumentPatientContext,
  } = useContext(DocumentsContext);

  //NES GEStion des ref
  const fileInput = useRef(null);

  //NES Gestion du nomnbre de documents
  const afficherNbDocuments = () => {
    const nb = listeDocuments.length;
    if (nb === 0) {
      return "Aucun document";
    } else {
      if (nb === 1) {
        return "1 document";
      } else {
        return nb + " documents";
      }
    }
  };

  //USEEFFECT récupération des documents
  useEffect(() => {
    getDocumentsPatient(url_patient_id).then((reponse) => {
      if (reponse.code === "OK") {
        setErreur("");

        let tempArray = reponse.data;
        //NES trie des documents
        tempArray = trierDocuments(tempArray);

        setListeDocuments(tempArray);
      } else {
        setErreur("Erreur lors de la récupération des documents");
        setError(reponse);
      }
    });
    // eslint-disable-next-line
  }, [url_patient_id]);

  //USEEFFECT AFFICHAGE, RECHERCHE ET FILTRES
  useEffect(() => {
    let data = documents;

    //NES je recherche
    if (texteRecherche) {
      data = data.filter((item) => {
        let chaineRecherche = item.nom + item.type;
        let regExp = new RegExp(`${texteRecherche}`, "i");
        let index = chaineRecherche.search(regExp);
        return index > -1 ? true : false;
      });
    }
    //NES gestion du filtre type de document
    if (filtreType) {
      data = data.filter((item) => item.type === filtreType);
    }
    //NES trie des documents
    data = trierDocuments(data);

    //NES affichage du message
    if (data.length === 0) {
      setAfficherMessage("Aucun résultat");
    } else {
      setAfficherMessage("");
    }

    setListeDocuments(data);

    // eslint-disable-next-line
  }, [texteRecherche, filtreType, documents]);

  //NES trie des documents
  const handleTri = (colonne) => {
    let objetTri = {
      colonne: colonne,
      ordre: "az",
    };
    //NES Inversion du tri
    if (tri.colonne === colonne) {
      objetTri = { ...objetTri, ordre: tri.ordre === "az" ? "za" : "az" };
    }
    setTri(objetTri);
  };

  useEffect(() => {
    //NES on trie le state
    let tempArray = [...listeDocuments];
    tempArray = trierDocuments(tempArray);
    setListeDocuments(tempArray);
    // eslint-disable-next-line
  }, [tri]);

  const trierDocuments = (listeDocuments) => {
    let tempArray = listeDocuments;
    switch (tri.colonne.toLowerCase()) {
      case "documents":
        tempArray.sort((a, b) => {
          if (tri.ordre === "az") {
            return a.nom.toUpperCase() > b.nom.toUpperCase() ? 1 : -1;
          } else {
            return a.nom.toUpperCase() > b.nom.toUpperCase() ? -1 : 1;
          }
        });
        break;
      case "date":
        tempArray.sort((a, b) => {
          if (tri.ordre === "za") {
            return (
              new Date(a.tracabilite_creation.date_tracabilite) -
              new Date(b.tracabilite_creation.date_tracabilite)
            );
          } else {
            return (
              new Date(b.tracabilite_creation.date_tracabilite) -
              new Date(a.tracabilite_creation.date_tracabilite)
            );
          }
        });
        break;
      case "taille":
        tempArray.sort((a, b) => {
          if (tri.ordre === "az") {
            return parseFloat(a.taille_Mo) > parseFloat(b.taille_Mo) ? 1 : -1;
          } else {
            return parseFloat(a.taille_Mo) > parseFloat(b.taille_Mo) ? -1 : 1;
          }
        });
        break;
      case "par":
        tempArray.sort((a, b) => {
          if (tri.ordre === "az") {
            return a.nom > b.nom ? 1 : -1;
          } else {
            return a.nom > b.nom ? -1 : 1;
          }
        });
        break;
      default:
        //NES ON trie par date la plus récente
        tempArray.sort((a, b) => {
          return (
            new Date(a.tracabilite_creation.date_tracabilite) -
            new Date(b.tracabilite_creation.date_tracabilite)
          );
        });
    }

    return tempArray;
  };

  //DELETE du document
  const supprimerDocument = (document_id) => {
    console.log("supprimer :" + document_id);

    deleteDocumentPatientContext(url_patient_id, document_id).then(
      (reponse) => {
        console.log(reponse);
      }
    );

    setConfirmerSuppression(null);
  };

  //POST Documents
  const ajouterDocument = (event) => {
    console.log(event.target.files);
    let fichiers = Array.from(event.target.files);
    //NES j'ajoute le fichier à uploader dans le context, c'est l'objet fichier qui se charge de l'upload pour gérer sa progression
    ajouterDocumentsUploadContext(fichiers, url_patient_id);
  };

  return (
    <div className={MonStyle.PageDocuments}>
      <div className={MonStyle.EnteteEtBoutons}>
        <div>
          <div className="TitreMobile">Documents</div>
          <div className="SousTitre">{afficherNbDocuments()} </div>
        </div>
        <BoutonRond
          iconeComposant={<AddIcon />}
          couleur="vert"
          couleurIcone="blanc"
          tooltip="Ajouter un document"
          onClick={() => fileInput.current.click()}
        />
        <input
          type="file"
          style={{ display: "none" }}
          ref={fileInput}
          onChange={ajouterDocument}
          multiple
        />
      </div>
      <div className={MonStyle.ZoneRecherche}>
        <div className={MonStyle.InputRecherche}>
          <InputRecherche
            placeholder="Rechercher"
            onChange={(event) => setTexteRecherche(event.target.value)}
            onEffacer={() => setTexteRecherche("")}
          />
        </div>

        <FiltreTypeDocument
          onChange={(type) => setFiltreType(type)}
          style={{ minWidth: "200px" }}
          onEffacer={() => setFiltreType("")}
        />
      </div>
      <MessageErreur
        message={erreur}
        style={{ marginBottom: "20px" }}
        error={error}
      />
      {afficherMessage ? (
        <MessageAucuneDonnee
          message={"Aucun document trouvé pour ce patient"}
          conseil={
            texteRecherche || filtreType
              ? "Essayez de modifier votre recherche"
              : "Les documents joints aux consultations apparaitront ici."
          }
        />
      ) : (
        <div className={MonStyle.ZoneResultats}>
          <EnteteTableau>
            <EnteteColonne
              className={MonStyle.EnteteDocuments}
              titre="DOCUMENTS"
              onClick={() => handleTri("DOCUMENTS")}
              tri={tri.colonne === "DOCUMENTS" ? tri.ordre : ""}
            />
            <EnteteColonne
              className={MonStyle.EnteteDate}
              titre="DATE"
              onClick={() => handleTri("DATE")}
              tri={tri.colonne === "DATE" ? tri.ordre : ""}
            />
            <EnteteColonne
              className={MonStyle.ColonnePar}
              titre="PAR"
              onClick={() => handleTri("PAR")}
              tri={tri.colonne === "PAR" ? tri.ordre : ""}
            />
            <EnteteColonne
              className={MonStyle.ColonneTaille}
              titre="TAILLE"
              onClick={() => handleTri("TAILLE")}
              tri={tri.colonne === "TAILLE" ? tri.ordre : ""}
            />
          </EnteteTableau>
          {listeDocuments.map((doc, index) => (
            <LigneResultat
              disableGutters={true}
              //                isNouveau={index === 4 ? true : false}
              key={doc._id}
            >
              <div className={MonStyle.LigneResultat}>
                <span className={MonStyle.ColonneDocuments}>
                  <ObjetDocument
                    document={doc}
                    //isNouveau={index === 4 ? true : false}
                    onClickSupprimer={() => setConfirmerSuppression(doc._id)}
                  />
                </span>
                <span className={MonStyle.ColonneDate}>
                  {DateOuHeureToString(
                    doc.tracabilite_creation.date_tracabilite,
                    {
                      month: "short",
                      day: "numeric",
                      year: "numeric",
                    }
                  )}
                </span>
                <span className={MonStyle.ColonnePar}>
                  {"par " +
                    doc.tracabilite_creation.nom_utilisateur +
                    doc.tracabilite_creation.prenom_utilisateur}
                </span>
                <span className={MonStyle.ColonneTaille}>
                  {doc.taille_Mo > 0 ? doc.taille_Mo + " Mo" : "< 0.1 Mo"}
                </span>
              </div>

              <div className={MonStyle.confimerSuppression}>
                {confimerSuppression === doc._id && (
                  <BoutonConfirmerSuppression
                    onAnnuler={() => setConfirmerSuppression(null)}
                    onSupprimer={() => supprimerDocument(doc._id)}
                  />
                )}
              </div>
            </LigneResultat>
          ))}
        </div>
      )}
    </div>
  );
}
