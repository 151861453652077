export function isMobile() {
  //820px est la résolution en largeur d'un ipad air
  var isMobile = window.innerWidth <= 820 ? true : false;
  return isMobile;
}

export function isPetitEcran() {
  //820px est la résolution en largeur d'un ipad air
  var isPetitEcran = window.innerWidth <= 1340 ? true : false;
  return isPetitEcran;
}

export function isFullHD() {
  //1900px
  var isFullHD = window.innerWidth >= 1900 ? true : false;
  return isFullHD;
}

export function hauteurNavigateur() {
  return window.innerHeight;
}

export function getCodeMateriel() {
  if (isMobile()) {
    return "MOBILE";
  }
  if (isPetitEcran()) {
    return "TABLETTE";
  }
  if (isFullHD()) {
    return "HD";
  }
  return "DESKTOP";
}
