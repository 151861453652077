import React, { useContext } from "react";
import DatePicker from "../../../Design/Pickers/DatePicker";
import { AgendasContext } from "../../../Data/AgendasContext";
import { useHistory } from "react-router-dom";

export default function PageCalendrier({ match }) {
  //NES gestion du context
  const { selectedDate, setSelectedDate } = useContext(AgendasContext);

  //NES Gestion du routage pour faire des redirections
  let history = useHistory();

  //NES en cas de sélection d'une date
  const handleSelectDate = (date) => {
    //NES je mets à jour la date sélectionnée
    setSelectedDate(date);
    //NES je redirige sur l'agenda
    history.push("/agendas/" + match.params.id);
  };

  return (
    <div style={{ padding: "20px" }}>
      <div className="Titre1" style={{ marginBottom: "15px" }}>
        Calendrier
      </div>
      <DatePicker onDateSelect={handleSelectDate} date={selectedDate} />
    </div>
  );
}
