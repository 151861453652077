import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import Button from "@material-ui/core/Button";
import { couleurAction } from "../../functions/FonctionsCouleurs";

const BoutonRetourTheme = withStyles((theme) => ({
  root: {
    color: couleurAction(),
    fontFamily: "Lato",
    fontWeight: "500",
    fontSize: "16px",
    height: "40px",
    //width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "start",
    padding: "0px",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#FFF01",
    },
  },
}))(Button);

export default function BoutonRetour({
  texte,
  link,
  onClick,
  style,
  fullWidth,
}) {
  if (link) {
    return (
      <Link to={link}>
        <BoutonRetourTheme
          onClick={onClick}
          name={texte}
          style={style}
          fullWidth={fullWidth}
        >
          <ArrowBackIosIcon fontSize="small" />
          {texte}
        </BoutonRetourTheme>
      </Link>
    );
  } else {
    return (
      <BoutonRetourTheme
        onClick={onClick}
        name={texte}
        style={style}
        fullWidth={fullWidth}
      >
        <ArrowBackIosIcon fontSize="small" />
        {texte}
      </BoutonRetourTheme>
    );
  }
}
