import React, { useState, useEffect } from "react";
import InputPrix from "../../../../../Design/Formulaires/InputPrix";
import InputTexte from "../../../../../Design/Formulaires/InputTexte";
import BoutonActionPrincipale from "../../../../../Design/Boutons/BoutonActionPrincipale";
import BoutonAnnuler from "../../../../../Design/Boutons/BoutonAnnuler";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import InputPays from "../../../../../Design/Formulaires/InputPays";

export default function EditionFraisDePort({
  fraisDePort,
  onChange,
  onClickOk,
  onClickAnnuler,
}) {
  //NES Gestion du state
  const [data, setData] = useState({
    pays: "",
    description: "",
    is_default: true,
    montant_min_commande: null,
    montant_max_commande: null,
    montant_frais_de_port: 0,
  });

  //NES HANDLECHANGE
  const handleChange = (key, value) => {
    let newData = { ...data, [key]: value };

    if (key !== "description") {
      let maDescription = "";

      if (newData.montant_min_commande) {
        maDescription = "≥ à " + newData.montant_min_commande + " €";
      }

      if (newData.montant_max_commande) {
        maDescription = "< à " + newData.montant_max_commande + " €";
      }

      if (newData.montant_min_commande && newData.montant_max_commande) {
        maDescription =
          "entre " +
          newData.montant_min_commande +
          " et " +
          newData.montant_max_commande +
          " €";
      }

      maDescription = newData.pays + " " + maDescription;
      newData = { ...newData, description: maDescription };
    }

    setData(newData);
    if (onChange) {
      onChange(newData);
    }
  };

  //USEEFFECT
  useEffect(() => {
    if (fraisDePort) {
      setData(fraisDePort);
    }
  }, [fraisDePort]);

  //NES handleClickOk
  const handleClickOk = () => {
    if (onClickOk) {
      onClickOk(data);
    }
  };
  return (
    <div
      style={{
        background: "#208AB010",
        padding: "10px 20px",
        border: "1px dashed #208AB0 ",
        borderRadius: "4px",
        backgroundImage: `url(${LocalShippingIcon})`,
        position: "relative",
      }}
    >
      <div
        style={{
          position: "absolute",
          left: 0,
          bottom: -20,
          width: "100px",
          height: "",
          zIndex: "0",
          opacity: 0.1,
        }}
      >
        <LocalShippingIcon style={{ width: "100%", height: "100%" }} />
      </div>
      <InputPays
        value={data.pays}
        onTexteChange={(pays) => handleChange("pays", pays)}
      />

      <div
        style={{ display: "flex", flexDirection: "row", gap: "20px" }}
        value={data.pays}
      >
        <InputPrix
          label="Min montant"
          value={data.montant_min_commande}
          onChange={(e) => handleChange("montant_min_commande", e.target.value)}
        />
        <InputPrix
          label="Max montant"
          value={data.montant_max_commande}
          onChange={(e) => handleChange("montant_max_commande", e.target.value)}
        />
      </div>
      <InputTexte
        label="Description"
        placeholder="Ex : France"
        value={data.description}
        onChange={(e) => handleChange("description", e.target.value)}
      />
      <InputPrix
        label="Frais de port"
        value={data.montant_frais_de_port}
        onChange={(e) => handleChange("montant_frais_de_port", e.target.value)}
      />

      <BoutonActionPrincipale
        couleur="bleu"
        texte="OK"
        fullWidth
        style={{ margin: "10px 0px" }}
        onClick={handleClickOk}
      />
      <BoutonAnnuler fullWidth onClick={onClickAnnuler} />
    </div>
  );
}
