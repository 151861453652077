import React, { useContext } from "react";
import { AuthentificationContext } from "../../Data/AuthentificationContext";
import MessageDonneesMedicales from "../Messages/MessageDonneesMedicales";

export default function AffichageDonneesMedicales({
  taille,
  titre,
  style,
  ...props
}) {
  //NES gestion du contexte
  const auth = useContext(AuthentificationContext);

  if (auth.payload.code_role === "secretaire") {
    return <MessageDonneesMedicales taille={taille} titre={titre} />;
  }

  return <div style={style}>{props.children}</div>;
}
