import React, { createContext, useContext, useState } from "react";
import { toApiDate } from "../functions/FonctionsDateHeure";
import { AuthentificationContext } from "./AuthentificationContext";
import { POST, GET, PATCH, DELETE } from "../functions/FonctionsApi";

//NES Création du contexte
export const AdpxContext = createContext();

export const AdpxProvider = (props) => {
  //NES gestion du contexte
  const authContext = useContext(AuthentificationContext);

  //NES Gestion du state
  const [organisations, setOrganisations] = useState([]);
  const [jours, setJours] = useState([]);

  //GET messageserreurs
  const getMessagesErreurs = async (dateDebut, dateFin) => {
    let parametres = "";
    if (dateDebut && dateFin) {
      let paramDateDebut = toApiDate(dateDebut);
      let paramDateFin = toApiDate(dateFin);
      parametres = `?datedebut="${paramDateDebut}"&datefin="${paramDateFin}"`;
    }

    //NES j'appelle l'API
    let MonUrl = `${process.env.REACT_APP_URL_API_SUPPORT}/adpx/messageserreurs${parametres}`;
    let reponseApi = await GET(MonUrl, authContext.token);
    return reponseApi;
  };

  //POST messagesErreurs
  const postMessagesErreurs = async (data) => {
    let MonUrl = `${process.env.REACT_APP_URL_API_SUPPORT}/adpx/messageserreurs`;
    let reponseApi = await POST(MonUrl, authContext.token, data);
    return reponseApi;
  };

  //GET adpx/organisations
  const getAdpxOrganisations = async () => {
    let MonUrl = `${process.env.REACT_APP_URL_API_UTILISATEURS}/adpx/organisations`;
    let reponseApi = await GET(MonUrl, authContext.token);
    console.log({ reponseApi });

    //NES on conserve les organisations dans le contexte
    if (reponseApi.code === "OK") {
      setOrganisations(reponseApi.data);
    }

    return reponseApi;
  };

  //GET /adpx/planning/jours
  const getAdpxPlanningJours = async (dateDebut, dateFin) => {
    let parametres = "";
    if (dateDebut && dateFin) {
      let paramDateDebut = toApiDate(dateDebut);
      let paramDateFin = toApiDate(dateFin);
      parametres = `?datedebut="${paramDateDebut}"&datefin="${paramDateFin}"`;
    }

    let MonUrl = `${process.env.REACT_APP_URL_API_SUPPORT}/adpx/planning/jours${parametres}`;
    let reponseApi = await GET(MonUrl, authContext.token);
    console.log({ reponseApi });

    //NES on conserve les organisations dans le contexte
    if (reponseApi.code === "OK") {
      setJours(reponseApi.data);
    }

    return reponseApi;
  };

  //POST /adpx/planning/jours
  const postAdpxPlannigJours = async (data) => {
    let MonUrl = `${process.env.REACT_APP_URL_API_SUPPORT}/adpx/planning/jours`;
    let reponseApi = await POST(MonUrl, authContext.token, data);
    console.log({ reponseApi });
    if (reponseApi.code === "OK") {
      let tempArray = [...jours];
      tempArray = tempArray.concat(reponseApi.data);
      setJours(tempArray);
    }
    return reponseApi;
  };

  //GET /adpx/demandes/enretard
  const getAdpxDemandesEnretard = async () => {
    let MonUrl = `${process.env.REACT_APP_URL_API_SUPPORT}/adpx/demandes/enretard`;
    let reponseApi = await GET(MonUrl, authContext.token);

    return reponseApi;
  };

  //GET /adpx/demandes/nonplannifie
  const getAdpxDemandesNonplannifie = async () => {
    let MonUrl = `${process.env.REACT_APP_URL_API_SUPPORT}/adpx/demandes/nonplanifie`;
    let reponseApi = await GET(MonUrl, authContext.token);

    return reponseApi;
  };

  //PATCH /adpx/planning/jours
  //POST /adpx/planning/jours/
  const plannifierDemande = async (demande, jourCibleId) => {
    console.log({ demande, jourCibleId });
    if (demande?.date_previsionnelle) {
      //NES je déplace la demande
      let data = { planningJours_id: jourCibleId };
      let MonUrl = `${process.env.REACT_APP_URL_API_SUPPORT}/adpx/planning/jours/${demande?.date_previsionnelle?.planning_jour_id}/demandes/${demande._id}`;
      let reponseApi = await PATCH(MonUrl, authContext.token, data);
      console.log({ reponseApi });
      if (reponseApi.code === "OK") {
        //NES mise à jour du state
        const tempArray = [...jours];
        const joursModifies = reponseApi.data;

        joursModifies.forEach((jourModifie) => {
          let index = tempArray.findIndex(
            (item) => item._id === jourModifie._id
          );
          tempArray.splice(index, 1, jourModifie);
        });
        setJours(tempArray);
      }

      return reponseApi;
    } else {
      //NES ajouter la demande à la date
      let data = { demande_id: demande._id };
      let MonUrl = `${process.env.REACT_APP_URL_API_SUPPORT}/adpx/planning/jours/${jourCibleId}/demandes`;
      let reponseApi = await POST(MonUrl, authContext.token, data);
      console.log({ reponseApi });
      if (reponseApi.code === "OK") {
        //NES mise à jour du state
        const tempArray = [...jours];
        const joursModifies = reponseApi.data;
        joursModifies.forEach((jourModifie) => {
          let index = tempArray.findIndex(
            (item) => item._id === jourModifie._id
          );
          tempArray.splice(index, 1, jourModifie);
        });
        setJours(tempArray);
      }

      return reponseApi;
    }
  };

  //DELETE /adpx/jours/:id/demande/:id
  const deplanniferDemande = async (demande) => {
    let MonUrl = `${process.env.REACT_APP_URL_API_SUPPORT}/adpx/planning/jours/${demande?.date_previsionnelle?.planning_jour_id}/demandes/${demande._id}`;
    let reponseApi = await DELETE(MonUrl, authContext.token);
    console.log({ reponseApi });
    if (reponseApi.code === "OK") {
      //NES mise à jour du state
      const tempArray = [...jours];
      const jourModifie = reponseApi.data[0];

      let index = tempArray.findIndex((item) => item._id === jourModifie._id);
      tempArray.splice(index, 1, jourModifie);

      setJours(tempArray);
    }

    return reponseApi;
  };

  //PATCH /adpx/planning/jours/:id
  const patchAdpxPlanningJours = async (jour_id, data) => {
    let MonUrl = `${process.env.REACT_APP_URL_API_SUPPORT}/adpx/planning/jours/${jour_id}`;
    let reponseApi = await PATCH(MonUrl, authContext.token, data);
    console.log({ reponseApi });
    if (reponseApi.code === "OK") {
      //NES mise à jour du state
      const tempArray = [...jours];
      const jourModifie = reponseApi.data;

      let index = tempArray.findIndex((item) => item._id === jourModifie._id);
      tempArray.splice(index, 1, jourModifie);

      setJours(tempArray);
    }

    return reponseApi;
  };

  //POST /adpx/authentification
  const postAdpxAuthentification = async (login, password, organisation_id) => {
    let MonUrl = `${process.env.REACT_APP_URL_API_UTILISATEURS}/adpx/authentification`;

    let body = {
      login: login,
      password: password,
      organisation_id: organisation_id,
    };

    let reponse = await POST(MonUrl, authContext.token, body);

    console.log({ body });

    return reponse;
  };

  //POST adpx/authadpx
  const postAuthentificationRetourAdpx = async () => {
    let MonUrl = `${process.env.REACT_APP_URL_API_UTILISATEURS}/adpx/authadpx`;
    let reponse = await POST(MonUrl, authContext.token);

    console.log({ reponse });

    return reponse;
  };
  return (
    <AdpxContext.Provider
      value={{
        getMessagesErreurs,
        postMessagesErreurs,
        organisations,
        getAdpxOrganisations,
        jours,
        getAdpxPlanningJours,
        postAdpxPlannigJours,
        getAdpxDemandesEnretard,
        getAdpxDemandesNonplannifie,
        plannifierDemande,
        deplanniferDemande,
        patchAdpxPlanningJours,
        postAdpxAuthentification,
        postAuthentificationRetourAdpx,
      }}
    >
      {props.children}
    </AdpxContext.Provider>
  );
};
