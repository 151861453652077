import React, { useContext, useEffect, useState } from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import { StylesDocuments } from "../StylesDocumentsPdf";
import { ConseilsContext } from "../../Data/ConseilsContext";
import { DateToString } from "../../functions/FonctionsDateHeure";
import ImageCheckbox from "../Images/Checkbox.png";
import DocumentPdf from "../DocumentPdf";
import LoaderGlobal from "../../Design/Progression/LoaderGlobal";
import PopupMessageErreur from "../../Design/Messages/PopupMessageErreur";
import { ProduitsContext } from "../../Data/ProduitsContext";
import { PatientsContext } from "../../Data/PatientsContext";
import {
  formatterTelephone,
  capitalize,
  stringToDecimal,
  numberToEuro,
} from "../../functions/Formatter";

export default function DocumentBonDeCommande({
  conseil_id,
  laboratoire_id,
  open,
  onClose,
  autoPrint,
}) {
  //NES Gestion du style
  const Styles = StyleSheet.create(StylesDocuments);

  //NES gestion du contexte
  const { getConseilById } = useContext(ConseilsContext);
  const { getLaboratoireById } = useContext(ProduitsContext);
  const { getPatientContext } = useContext(PatientsContext);

  //NES gestion du state
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [messageErreur, setMessageErreur] = useState();
  const [error, setError] = useState();
  const [laboratoire, setLaboratoire] = useState();
  const [patient, setPatient] = useState();
  const [produits, setProduits] = useState();
  const [isChargementTermine, setIsChargementTermine] = useState(false);
  const [sousTotal, setSousTotal] = useState();
  const [fraisPort, setFraisPort] = useState();
  const [descriptionFraisPort, setDescriptionFraisPort] = useState("");

  //NES fermeture de l'impression
  const handleClose = () => {
    //NES pour ne pas garder les données dans le cache sinon
    setData();
    setPatient();
    setLaboratoire();
    setProduits();
    setFraisPort();
    setSousTotal();
    setIsChargementTermine(false);
    if (onClose) {
      onClose();
    }
  };

  //USEEFFECT fin du chargement des données
  useEffect(() => {
    if (
      data !== undefined &&
      laboratoire !== undefined &&
      patient !== undefined &&
      produits !== undefined &&
      fraisPort !== undefined
    ) {
      setIsChargementTermine(true);
    }
    // eslint-disable-next-line
  }, [data, laboratoire, patient, produits, fraisPort]);

  //USEEFFECT récupération des informations du laboratoire
  useEffect(() => {
    if (open) {
      getLaboratoireById(laboratoire_id)
        .then((reponse) => {
          if (reponse.code === "OK") {
            //! NES je force le code de CHRYS en attendant
            /*
            if (reponse.data._id === "61bb12b59ffdc15818ca4ded") {
              setCodeCommande("Code praticien : 5708808");
            }
            if (reponse.data._id === "61bb74459ffdc15818ca4e21") {
              setCodeCommande("Code praticien : 10157");
            }
*/

            setLaboratoire(reponse.data);
          } else {
            setMessageErreur("Erreur lors de l'impression du bon de commande");
            if (onClose) {
              onClose();
            }
          }
        })
        .catch((error) => {
          setIsLoading(false);
          setMessageErreur("Erreur lors de l'impression du bon de commande");
          setError(error);
          if (onClose) {
            onClose();
          }
        });
    }
    // eslint-disable-next-line
  }, [laboratoire_id, open]);

  //USEEFFECT récupération des données du patient
  useEffect(() => {
    //NES Récupération des donnée du patient en live pour pouvoir corriger son tel, son adresse etc...
    if (data?.patient_id) {
      getPatientContext(data.patient_id)
        .then((reponse) => {
          if (reponse.code === "OK") {
            setPatient(reponse.data);
          } else {
            setMessageErreur("Erreur lors de l'impression du bon de commande");
            if (onClose) {
              onClose();
            }
          }
        })
        .catch((error) => {
          setIsLoading(false);
          setMessageErreur("Erreur lors de l'impression du bon de commande");
          setError(error);
          if (onClose) {
            onClose();
          }
        });
    }

    //NES Je filtre pour ne garder que les produits du laboratoire
    let listeProduitACommander = [];
    let totalCommande = 0;
    data?.lignes_conseils.forEach((ligne) => {
      let monProduit = ligne?.produit;

      if (monProduit?.laboratoire?._id === laboratoire_id) {
        listeProduitACommander.push(monProduit);
        let prixDeVente = monProduit?.prix_de_vente_total
          ? monProduit?.prix_de_vente_total
          : 0;

        totalCommande = totalCommande + prixDeVente;
      }
    });

    setSousTotal(totalCommande);
    setProduits(listeProduitACommander);

    // eslint-disable-next-line
  }, [data]);

  //USEEFFECT récupération du contenu du conseil
  useEffect(() => {
    if (open) {
      setIsLoading(true);
      getConseilById(conseil_id)
        .then((reponse) => {
          setIsLoading(false);
          if (reponse.code === "OK") {
            let res = reponse.data;
            console.log(res);
            setData({
              _id: res._id,
              patient_id: res.patient_id,
              consultation_id: res.consultation_id,
              date: res.date,
              avertissement_conseil: res.avertissement_conseil,
              consultant: res.consultant,
              conseils_seance: res.conseils_seance,
              cabinet: {
                cabinet_id: res?.cabinet.cabinet_id,
                nom: res?.cabinet.nom,
                telephone: res?.cabinet.telephone,
                fax: res?.cabinet.fax,
                email: res?.cabinet.fax,
                adresse: res?.cabinet?.adresse,
                texte_horaire: res?.cabinet.texte_horaire,
                texte_rdv: res?.cabinet.texte_rdv,
                texte_libre: res?.cabinet.texte_libre,
                numero_finess: res?.cabinet.numero_finess,
                informations_juridiques: res?.cabinet?.informations_juridiques,
              },
              lignes_conseils: res.lignes_conseils,
              conseil_prise: res.conseil_prise,
              tracabilite_creation: res.tracabilite_creation,
            });
          } else {
            setMessageErreur("Erreur lors de l'impression du bon de commande");
            if (onClose) {
              onClose();
            }
          }
        })
        .catch((error) => {
          setIsLoading(false);
          setMessageErreur("Erreur lors de l'impression du bon de commande");
          setError(error);
          if (onClose) {
            onClose();
          }
        });
    }

    // eslint-disable-next-line
  }, [conseil_id, open]);

  //USEEFFECT calcul des frais de port
  useEffect(() => {
    if (laboratoire !== undefined && sousTotal && patient !== undefined) {
      let listeFraisDePort = laboratoire.frais_de_port;
      //NES je cherche les frais applicables
      let listeApplicable = [];

      let montantCommande = stringToDecimal(sousTotal);

      listeFraisDePort.forEach((frais) => {
        let montantMinimun = stringToDecimal(frais.montant_min_commande);
        let montantMaximum = stringToDecimal(frais.montant_max_commande);

        if (
          (montantCommande >= montantMinimun || montantMinimun === "") &&
          (montantCommande <= montantMaximum || montantMaximum === "")
        ) {
          listeApplicable.push(frais);
        }
      });

      //NES est ce que le pays est renseigné sur le patient?
      if (
        patient.adresse?.pays &&
        patient.adresse?.pays !== "" &&
        patient.adresse?.pays !== "FRANCE"
      ) {
        //NES je filtre avec le PAYS et EUROPE
        listeApplicable = listeApplicable.filter(
          (item) =>
            item.pays.toUpperCase() === patient.adresse?.pays.toUpperCase() ||
            item.pays.toUpperCase() === "EUROPE"
        );
      } else {
        //NES je filtre avec la FRANCE par défault et VIDE
        listeApplicable = listeApplicable.filter(
          (item) => item.pays === "" || item.pays === "FRANCE"
        );
      }

      if (listeApplicable.length > 0) {
        let montant = stringToDecimal(listeApplicable[0].montant_frais_de_port);
        setFraisPort(montant);
        setDescriptionFraisPort(listeApplicable[0].description);
      } else {
        setFraisPort(0);
        setDescriptionFraisPort("");
      }
    }
  }, [laboratoire, sousTotal, patient]);

  const handlePopupMessageErreurClose = () => {
    setMessageErreur();
    handleClose();
  };

  if (messageErreur && open) {
    return (
      <PopupMessageErreur
        messageErreur={messageErreur}
        error={error}
        onClose={handlePopupMessageErreurClose}
      />
    );
  }

  if (!isChargementTermine) {
    return <LoaderGlobal isLoading={isLoading} />;
  }

  return (
    <DocumentPdf
      openDocumentPdf={open}
      onClose={handleClose}
      autoPrint={autoPrint}
    >
      <Document>
        <Page size="A4" style={Styles.page}>
          <View>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  flex: 1,
                  marginRight: "30px",
                }}
              >
                <Text style={Styles.titreBloc}>{data?.cabinet?.nom}</Text>
                <Text>{data?.cabinet?.adresse?.rue}</Text>
                <Text>
                  {data?.cabinet?.adresse?.code_postal +
                    " " +
                    data?.cabinet?.adresse?.ville}
                </Text>
                {data?.cabinet?.telephone?.numero && (
                  <Text>
                    Tél :{formatterTelephone(data?.cabinet?.telephone?.numero)}
                  </Text>
                )}
                {data?.cabinet?.fax?.numero && (
                  <Text>
                    Fax : {formatterTelephone(data?.cabinet?.fax?.numero)}
                  </Text>
                )}
                {data?.cabinet?.email && <Text>{data?.cabinet?.email}</Text>}
                <Text>{data?.cabinet?.texte_horaire}</Text>
                <Text>{data?.cabinet?.texte_rdv}</Text>

                <Text
                  style={[
                    Styles.informationPrincipale,
                    {
                      borderTop: "1px solid #D8D8D8",
                      marginTop: "10px",
                      paddingTop: "10px",
                      textAlign: "center",
                    },
                  ]}
                >
                  {data?.cabinet?.texte_libre}
                </Text>
                <Text style={Styles.titreBloc}>
                  {data?.tracabilite_creation?.prenom_utilisateur +
                    " " +
                    data?.tracabilite_creation?.nom_utilisateur}
                </Text>
                <Text>{laboratoire?.code_praticien} </Text>
              </div>
              <div
                style={{
                  flex: 1,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    minHeight: "60px",
                  }}
                >
                  <Text style={Styles.titre}>BON DE COMMANDE</Text>
                  <Text>
                    {DateToString(data?.date, {
                      weekday: "long",
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                    })}
                  </Text>
                </div>

                {/* BLOC LABORATOIRE */}

                <div
                  style={{
                    backgroundColor: "#EEEEEE",
                    borderRadius: "8px",
                    minHeight: "40px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    justifyContent: "left",
                    minWidth: "200px",
                    padding: "10px",
                  }}
                >
                  <Text style={Styles.titreBloc}>{laboratoire?.nom}</Text>
                  <Text>{laboratoire?.adresse?.rue}</Text>
                  <Text>
                    {laboratoire?.adresse?.code_postal +
                      " " +
                      laboratoire?.adresse?.ville}
                  </Text>
                  <Text>{laboratoire?.adresse?.complement}</Text>
                  <Text>
                    {formatterTelephone(laboratoire?.telephone?.numero)}
                  </Text>
                </div>
              </div>
            </div>

            {/* BLOC FACTURATION */}

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-start",
                justifyContent: "space-between",
                marginTop: "20px",
              }}
            >
              <div
                style={{
                  width: "48%",
                  border: "1px solid black",
                  borderRadius: "6px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Text
                  style={[
                    Styles.titreBloc,
                    {
                      backgroundColor: "#EEEEEE",
                      borderRadius: "6px",
                      minHeight: "20px",
                      paddingLeft: "10px",
                    },
                  ]}
                >
                  Facturation
                </Text>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    padding: "10px 20px",
                  }}
                >
                  <div
                    style={{
                      marginRight: "10px",
                    }}
                  >
                    <Text style={{ width: "70px", textAlign: "right" }}>
                      Nom :
                    </Text>
                    <Text style={{ width: "70px", textAlign: "right" }}>
                      Téléphone :
                    </Text>
                    <Text style={{ width: "70px", textAlign: "right" }}>
                      Adresse :
                    </Text>
                    <Text style={{ width: "70px", textAlign: "right" }}>
                      Code postal :
                    </Text>
                    <Text style={{ width: "70px", textAlign: "right" }}>
                      Ville :
                    </Text>
                    <Text style={{ width: "70px", textAlign: "right" }}>
                      Complément :
                    </Text>
                  </div>
                  <div>
                    <Text style={Styles.informationPrincipale}>
                      {patient?.nom?.toUpperCase() +
                        " " +
                        capitalize(patient?.prenom)}
                    </Text>
                    <Text style={Styles.informationPrincipale}>
                      {formatterTelephone(
                        patient?.contact?.telephones[0]?.numero
                      )}{" "}
                    </Text>
                    <Text style={Styles.informationPrincipale}>
                      {patient?.adresse?.rue}{" "}
                    </Text>
                    <Text style={Styles.informationPrincipale}>
                      {patient?.adresse?.code_postal}{" "}
                    </Text>
                    <Text style={Styles.informationPrincipale}>
                      {patient?.adresse?.ville}{" "}
                    </Text>
                    <Text style={Styles.informationPrincipale}>
                      {patient?.adresse?.complement +
                      " " +
                      patient?.adresse?.pays
                        ? patient?.adresse?.pays
                        : ""}{" "}
                    </Text>
                  </div>
                </div>
              </div>

              {/* BLOC LIVRAISON */}
              <div
                style={{
                  width: "48%",
                  border: "1px solid black",
                  borderRadius: "6px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    backgroundColor: "#EEEEEE",
                    borderRadius: "6px",
                    minHeight: "20px",
                    paddingLeft: "10px",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Text style={Styles.titreBloc}>Livraison</Text>
                  <Text style={{ marginLeft: "5px" }}>(si différente)</Text>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    padding: "10px 20px",
                  }}
                >
                  <Text style={{ width: "70px", textAlign: "right" }}>
                    Nom :
                  </Text>
                  <Text style={{ width: "70px", textAlign: "right" }}>
                    Téléphone :
                  </Text>
                  <Text style={{ width: "70px", textAlign: "right" }}>
                    Adresse :
                  </Text>
                  <Text style={{ width: "70px", textAlign: "right" }}>
                    Code postal :
                  </Text>
                  <Text style={{ width: "70px", textAlign: "right" }}>
                    Ville :
                  </Text>
                  <Text style={{ width: "70px", textAlign: "right" }}>
                    Complément :
                  </Text>
                </div>
              </div>
            </div>

            {/* BLOC ENTETE TABLEAU */}

            <div
              style={{
                border: "1px solid black",
                borderRadius: "6px",
                marginTop: "10px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  backgroundColor: "#EEEEEE",
                  borderRadius: "6px",
                  minHeight: "20px",
                  paddingLeft: "10px",
                  paddingRight: "10px",
                }}
              >
                <Text style={[Styles.titreColonneLeft, { width: "80px" }]}>
                  Code
                </Text>
                <Text style={[Styles.titreColonneLeft, { width: "240px" }]}>
                  Produit
                </Text>
                <Text style={[Styles.titreColonne, { width: "60px" }]}>
                  Qte
                </Text>
                <Text style={[Styles.titreColonneRight, { width: "60px" }]}>
                  Prix Unitaire
                </Text>
                <Text
                  style={[
                    Styles.titreColonneRight,
                    {
                      width: "60px",
                    },
                  ]}
                >
                  Total
                </Text>
              </div>

              {/* LIGNES DE PRODUITS */}

              <div>
                {produits?.map((ligne, index) => (
                  <div
                    key={index}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                      minHeight: "30px",
                      borderBottom: "1px solid #D8D8D8",
                      padding: "0px 10px",
                    }}
                  >
                    <Text style={[Styles.titreColonneLeft, { width: "80px" }]}>
                      {ligne.code_commande}
                    </Text>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        width: "240px",
                        alignItems: "center",
                        flexWrap: "wrap",
                      }}
                    >
                      <Text style={[Styles.titreColonneLeft]}>{ligne.nom}</Text>
                      {ligne.conditionnement ? (
                        <div
                          style={{
                            border: "1px solid black",
                            padding: "0px 5px ",
                            marginLeft: "5px",
                          }}
                        >
                          <Text style={Styles.informationPrincipale}>
                            {ligne.conditionnement}
                          </Text>
                        </div>
                      ) : null}
                    </div>
                    <Text style={[Styles.titreColonne, { width: "60px" }]}>
                      {ligne.qte}
                    </Text>
                    <Text style={[Styles.titreColonneRight, { width: "60px" }]}>
                      {numberToEuro(ligne.prix_de_vente_unitaire)} €
                    </Text>
                    <Text style={[Styles.titreColonneRight, { width: "60px" }]}>
                      {numberToEuro(ligne.prix_de_vente_total)} €
                    </Text>
                  </div>
                ))}

                {/* TOTAUX */}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                  }}
                >
                  <div
                    style={{
                      width: "160px",
                      display: "flex",
                      flexDirection: "row",
                      paddingRight: "10px",
                      margin: "5px 0px",
                    }}
                  >
                    <div>
                      <Text
                        style={{
                          width: "100px",
                          textAlign: "right",
                          paddingRight: "10px",
                        }}
                      >
                        Sous Total :
                      </Text>
                      <Text
                        style={{
                          width: "100px",
                          textAlign: "right",
                          paddingRight: "10px",
                        }}
                      >
                        Frais de port :
                      </Text>
                      <Text
                        style={[
                          Styles.informationJuridiques,
                          {
                            width: "100px",
                            textAlign: "right",
                            paddingRight: "13px",
                          },
                        ]}
                      >
                        {descriptionFraisPort}{" "}
                      </Text>
                      <Text
                        style={[
                          Styles.informationPrincipale,
                          {
                            width: "100px",
                            textAlign: "right",
                            paddingRight: "10px",
                            borderTop: "1px solid black",
                            marginTop: "5px",
                            paddingTop: "5px",
                          },
                        ]}
                      >
                        Total :
                      </Text>
                    </div>
                    <div>
                      <Text
                        style={{
                          width: "60px",
                          textAlign: "right",
                        }}
                      >
                        {numberToEuro(sousTotal, " €")}
                      </Text>
                      <Text
                        style={{
                          width: "60px",
                          textAlign: "right",
                        }}
                      >
                        {numberToEuro(fraisPort, " €")}
                      </Text>
                      <Text
                        style={[
                          Styles.informationJuridiques,
                          {
                            width: "60px",
                            textAlign: "right",
                          },
                        ]}
                      >
                        {" "}
                      </Text>
                      <Text
                        style={[
                          Styles.informationPrincipale,
                          {
                            width: "60px",
                            textAlign: "right",
                            borderTop: "1px solid black",
                            marginTop: "5px",
                            paddingTop: "5px",
                          },
                        ]}
                      >
                        {numberToEuro(sousTotal + fraisPort)} €
                      </Text>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* MODE DE REGLEMENT */}

            <div
              style={{
                marginTop: "10px",
                border: "1px solid black",
                borderRadius: "6px",
              }}
            >
              <Text
                style={[
                  Styles.titreBloc,
                  {
                    backgroundColor: "#EEEEEE",
                    borderRadius: "6px",
                    minHeight: "20px",
                    paddingLeft: "10px",
                  },
                ]}
              >
                Mode de règlement
              </Text>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  padding: "10px",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <Image
                      src={ImageCheckbox}
                      style={{
                        height: "24px",
                        width: "24px",
                        marginRight: "5px",
                      }}
                    />
                    <Text>Chèque</Text>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <Image
                      src={ImageCheckbox}
                      style={{
                        height: "24px",
                        width: "24px",
                        marginRight: "5px",
                      }}
                    />
                    <Text>Carte bancaire (CB, Visa, MASTERCARD)</Text>
                  </div>
                  {/* BLOC CARTE BANCAIRE DE REGLEMENT */}
                  <div
                    style={{
                      marginLeft: "28px",
                    }}
                  >
                    {/* BLOC N° CARTE BANCAIRE DE REGLEMENT */}

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        marginTop: "10px",
                      }}
                    >
                      <Text style={{ marginRight: "5px" }}>N°</Text>
                      <div
                        style={{
                          height: "16px",
                          width: "13px",
                          backgroundColor: "#EEEEEE",
                          marginRight: "2px",
                        }}
                      ></div>
                      <div
                        style={{
                          height: "16px",
                          width: "13px",
                          backgroundColor: "#EEEEEE",
                          marginRight: "2px",
                        }}
                      ></div>
                      <div
                        style={{
                          height: "16px",
                          width: "13px",
                          backgroundColor: "#EEEEEE",
                          marginRight: "2px",
                        }}
                      ></div>
                      <div
                        style={{
                          height: "16px",
                          width: "13px",
                          backgroundColor: "#EEEEEE",
                          marginRight: "10px",
                        }}
                      ></div>
                      <div
                        style={{
                          height: "16px",
                          width: "13px",
                          backgroundColor: "#EEEEEE",
                          marginRight: "2px",
                        }}
                      ></div>
                      <div
                        style={{
                          height: "16px",
                          width: "13px",
                          backgroundColor: "#EEEEEE",
                          marginRight: "2px",
                        }}
                      ></div>
                      <div
                        style={{
                          height: "16px",
                          width: "13px",
                          backgroundColor: "#EEEEEE",
                          marginRight: "2px",
                        }}
                      ></div>
                      <div
                        style={{
                          height: "16px",
                          width: "13px",
                          backgroundColor: "#EEEEEE",
                          marginRight: "10px",
                        }}
                      ></div>
                      <div
                        style={{
                          height: "16px",
                          width: "13px",
                          backgroundColor: "#EEEEEE",
                          marginRight: "2px",
                        }}
                      ></div>
                      <div
                        style={{
                          height: "16px",
                          width: "13px",
                          backgroundColor: "#EEEEEE",
                          marginRight: "2px",
                        }}
                      ></div>
                      <div
                        style={{
                          height: "16px",
                          width: "13px",
                          backgroundColor: "#EEEEEE",
                          marginRight: "2px",
                        }}
                      ></div>
                      <div
                        style={{
                          height: "16px",
                          width: "13px",
                          backgroundColor: "#EEEEEE",
                          marginRight: "10px",
                        }}
                      ></div>
                      <div
                        style={{
                          height: "16px",
                          width: "13px",
                          backgroundColor: "#EEEEEE",
                          marginRight: "2px",
                        }}
                      ></div>
                      <div
                        style={{
                          height: "16px",
                          width: "13px",
                          backgroundColor: "#EEEEEE",
                          marginRight: "2px",
                        }}
                      ></div>
                      <div
                        style={{
                          height: "16px",
                          width: "13px",
                          backgroundColor: "#EEEEEE",
                          marginRight: "2px",
                        }}
                      ></div>
                      <div
                        style={{
                          height: "16px",
                          width: "13px",
                          backgroundColor: "#EEEEEE",
                          marginRight: "2px",
                        }}
                      ></div>
                    </div>

                    {/* BLOC DATE DE VALIDITE*/}

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        marginTop: "10px",
                      }}
                    >
                      <Text style={{ marginRight: "10px" }}>
                        Date de validité :
                      </Text>
                      <div
                        style={{
                          height: "16px",
                          width: "13px",
                          backgroundColor: "#EEEEEE",
                          marginRight: "2px",
                        }}
                      ></div>
                      <div
                        style={{
                          height: "16px",
                          width: "13px",
                          backgroundColor: "#EEEEEE",
                          marginRight: "5px",
                        }}
                      ></div>

                      <Text style={{ marginRight: "5px" }}>/</Text>
                      <div
                        style={{
                          height: "16px",
                          width: "13px",
                          backgroundColor: "#EEEEEE",
                          marginRight: "2px",
                        }}
                      ></div>
                      <div
                        style={{
                          height: "16px",
                          width: "13px",
                          backgroundColor: "#EEEEEE",
                          marginRight: "2px",
                        }}
                      ></div>
                    </div>
                  </div>
                </div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text>Date et signature</Text>
                  <div
                    style={{
                      backgroundColor: "#EEEEEE",
                      width: "160px",
                      height: "70px",
                    }}
                  ></div>
                </div>
              </div>
            </div>
          </View>
          <div style={{ position: "absolute", bottom: "10mm", left: "10mm" }}>
            <Text style={Styles.informationJuridiques}>
              Conformément à l'article 27 de la loi "Informatique et Libertés"
              du 6 janvier 1978, les champs d'information sont nécessaires à
              l'enregistrement et au traitement de vos demandes. Nous ne les
              transmettrons pas à des tiers. Conformément aux dispositions de la
              loi n°78-17 du 6 janvier 1978 relative à l’informatique, aux
              fichiers et aux libertés, vous disposez d’un droit d’accès, de
              modification, de rectification et de suppression des données qui
              vous concernent.
            </Text>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                width: "530px",
                height: "20px",
                alignItems: "center",
                borderTop: "1px solid noir",
                marginTop: "5px",
              }}
            >
              <Text
                style={{
                  fontSize: "8px",
                  fontWeight: "bold",
                }}
                render={({ pageNumber, totalPages }) =>
                  `Page ${pageNumber} / ${totalPages}`
                }
                fixed
              />
            </div>
          </div>
        </Page>
      </Document>
    </DocumentPdf>
  );
}
