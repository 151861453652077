import React from "react";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";

/*
const ColorButton = withStyles((theme) => ({
  root: {
    color: (props) => (props.couleur === "blanc" ? "#FFFFFF" : "#208AB0"),
    fontWeight: "700",
    textTransform: "none",
    fontFamily: "Lato",
    height: "50px",

    "&:hover": {
      backgroundColor: "#208AB020",
    },
  },
}))(Button);
*/

export default function BoutonTexte({
  texte,
  href,
  couleur,
  taille,
  disabled,
  fullWidth,
  onClick,
  withBackground,
  style,
  link,
  ...props
}) {
  //NES gestion dynamique du style
  const useStyles = makeStyles({
    root: {
      background: (props) => props.background,
      //border: 0,
      //boxShadow: "0px 2px 2px 0px rgba(0, 0, 0, 0.3)",
      color: (props) => props.color,
      fontFamily: "Lato",
      //fontWeight: "700",
      textTransform: "none",
      borderRadius: "4px",
      letterSpacing: "0",
      fontSize: (props) => props.fontSize,
      minHeight: (props) => props.height,
      "&:hover": {
        backgroundColor: (props) => props.backgroundOver,
        //boxShadow: "2px 4px 8px 0 rgba(0,0,0,0.1)",
      },
      "&$disabled": {
        backgroundColor: "#DDDDDD20",
        opacity: 0.8,
        boxShadow: "none",
      },
    },
    disabled: {},
  });

  //NES gestion des taille S,M,L
  let height = "";
  let fontSize = "16px";

  switch (taille) {
    case "S":
      height = "24px";
      fontSize = "13px";
      break;
    case "M":
      height = "40px";
      break;
    case "L":
      height = "50px";
      break;
    default:
      height = "40px";
  }

  //NES Customisation des couleurs
  let color = "";
  let backgroundOver = "";
  let background = "";
  switch (couleur) {
    case "blanc":
      color = "white";
      backgroundOver = "#FFFFFF20";
      background = withBackground ? "#FFFFFF20" : "none";
      break;
    case "rouge":
      color = "#F44336";
      backgroundOver = "#F4433610";
      break;
    case "bleu":
      color = "#208AB0";
      backgroundOver = "#208AB010";
      break;
    case "gris":
      color = "#666666";
      backgroundOver = "#66666610";
      break;
    default:
      color = "#208AB0";
      backgroundOver = "#208AB010";
      if (process.env.REACT_APP_NOM === "SINOBI") {
        color = "#850C36";
        backgroundOver = "#850C3610";
      }
  }

  //NES création du style
  let theme = {
    color: color,
    height: height,
    backgroundOver: backgroundOver,
    fontSize: fontSize,
    background: background,
  };

  const classes = useStyles(theme);

  return (
    <Button
      classes={{
        root: classes.root, // class name, e.g. `root-x`
        disabled: classes.disabled, // class name, e.g. `disabled-x`
      }}
      component={link ? Link : Button}
      couleur={couleur}
      fullWidth={fullWidth}
      href={href}
      onClick={onClick}
      style={style}
      disabled={disabled}
      name={texte}
      to={link}
      {...props}
    >
      {texte}
      {props.children}
    </Button>
  );
}
