import React from "react";

export default function PuceCarteBancaire() {
  return (
    <svg width="47px" height="38px" viewBox="0 0 47 38" version="1.1">
      <g
        id="Inscription-filleul"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Saisie-des-informations-du-cabinet"
          transform="translate(-548.000000, -281.000000)"
          fill="#F0B72F"
        >
          <path
            d="M595,307 L595,311 C595,315.418278 591.418278,319 587,319 L579,319 L579,307 L595,307 Z M563,307 L563,319 L556,319 C551.581722,319 548,315.418278 548,311 L548,307 L563,307 Z M577,281 L577,319 L565,319 L565,291 L548,291 L548,289 C548,284.581722 551.581722,281 556,281 L577,281 Z M563,293 L563,305 L548,305 L548,293 L563,293 Z M587,281 C591.418278,281 595,284.581722 595,289 L595,291 L579,291 L579,281 L587,281 Z M579,293 L595,293 L595,305 L579,305 L579,293 Z"
            id="Combined-Shape"
          ></path>
        </g>
      </g>
    </svg>
  );
}
