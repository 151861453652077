import React, { useState, useContext, useEffect } from "react";
import Adresse from "../../../Components/Adresse";
import BoutonActionPrincipale from "../../../Design/Boutons/BoutonActionPrincipale";
import InputTexte from "../../../Design/Formulaires/InputTexte";
import MessageAide from "../../../Design/Messages/MessageAide";
import MessageAlerte from "../../../Design/Messages/MessageAlerte";
import MessageErreur from "../../../Design/Messages/MessageErreur";
import LoaderHorizontal from "../../../Design/Progression/LoaderHorizontal";
import MonStyle from "./PageInformationsFacturation.module.css";
import { UtilisateursContext } from "../../../Data/UtilisateursContext";

export default function PageInformationFacturation() {
  //NES Gestion du state
  const [data, setData] = useState({ nom: "", adresse_facturation: {} });
  const [isLoading, setIsLoading] = useState(true);
  const [messageErreur, setMessageErreur] = useState();
  const [error, setError] = useState();
  const [message, setMessage] = useState();

  //NES gestion du contexte
  const { getMyOrganisation, patchMyOrganisation, monOrganisation } =
    useContext(UtilisateursContext);

  //USEEFFECT récupération de mon organisation
  useEffect(() => {
    getMyOrganisation().then(() => {
      setIsLoading(false);
    });
    // eslint-disable-next-line
  }, []);

  //USEEFFECT
  useEffect(() => {
    if (monOrganisation) {
      setData({
        nom: monOrganisation.nom,
        adresse: monOrganisation.adresse,
      });
    }
  }, [monOrganisation]);

  ///handleChange
  const handleChange = (key, value) => {
    let tempData = { ...data, [key]: value };
    setData(tempData);
  };

  ///HandleEnregistrer
  const handleEnregistrer = (e) => {
    e.preventDefault();
    patchMyOrganisation(data).then((reponse) => {
      if (reponse.code === "OK") {
        setMessage("Mise à jour des informations de facturation réussie ! ");
        setMessageErreur();
        setError();
      } else {
        setMessageErreur(
          "Erreur lors de la mise à jour des informations de facturation"
        );
        setError(reponse);
        setMessage();
      }
    });
  };

  return (
    <div className={MonStyle.PageInformationFacturation}>
      <MessageAide
        message="La modification de ces informations sera prise en compte pour la prochaine facture."
        disableClose
      />
      <LoaderHorizontal isLoading={isLoading} style={{ margin: "15px 0px" }} />
      <form className={MonStyle.Formulaire}>
        <section>
          <h3>Nom affiché sur la facture</h3>
          <InputTexte
            value={data.nom}
            label="Nom de facturation"
            placeholder="ex : Cabinet LEAU Samantha"
            onChange={(e) => handleChange("nom", e.target.value)}
          />
        </section>
        <section>
          <h3>Adresse de facturation</h3>
          <Adresse
            value={data.adresse}
            onChange={(adresse) => handleChange("adresse", adresse)}
          />
        </section>
        <MessageErreur
          message={messageErreur}
          error={error}
          style={{ marginBottom: "20px" }}
        />
        <BoutonActionPrincipale
          texte="Enregistrer"
          taille="L"
          fullWidth
          onClick={handleEnregistrer}
          disabled={!data.nom}
        />
        <MessageAlerte message={message} />
      </form>
    </div>
  );
}
