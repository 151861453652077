import React from "react";
import ErrorIcon from "@material-ui/icons/Error";
import Tooltip from "../DataDisplay/ToolTip";

export default function IconeErreur({ message, error }) {
  if (message) {
    return (
      <div title={message}>
        <Tooltip>
          <ErrorIcon style={{ color: "red" }} />
        </Tooltip>
      </div>
    );
  } else {
    return "";
  }
}
