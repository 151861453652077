import React from "react";
import BoutonRond from "./BoutonRond";
import FavoriteIcon from "@material-ui/icons/Favorite";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import { useState } from "react";
import { useEffect } from "react";

export default function BoutonFavori({ isFavori, onClick, id, style }) {
  const iconeAjouter = <FavoriteBorderIcon style={{ color: "#97989D80" }} />;
  const iconeRetirer = <FavoriteIcon style={{ color: "#D2215F" }} />;

  //NES Gestion du state
  const [icone, setIcone] = useState(iconeAjouter);
  const [tooltip, setToolTip] = useState("Ajouter aux favoris");
  const [favori, setFavori] = useState(false);

  //USEEFFECT
  useEffect(() => {
    if (favori) {
      setIcone(iconeRetirer);
      setToolTip("Retirer des favoris");
    } else {
      setIcone(iconeAjouter);
      setToolTip("Ajouter aux favoris");
    }
    // eslint-disable-next-line
  }, [favori]);

  useEffect(() => {
    setFavori(isFavori);
  }, [isFavori]);

  return (
    <BoutonRond
      iconeComposant={icone}
      tooltip={tooltip}
      onClick={onClick}
      id={id}
      style={style}
    />
  );
}
