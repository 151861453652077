import React, { useState, useEffect } from "react";
import BoutonAjoutBloc from "../../../../../Design/Boutons/BoutonAjoutBloc";
import InputTextArea from "../../../../../Design/Formulaires/InputTextArea";
import SurfaceBloc from "../../../../../Design/Surfaces/SurfaceBloc";

export default function BlocCommentaire({ value, onChange, onBlur }) {
  //NES Gestion du state
  const [afficherCommentaire, setAfficherCommentaire] = useState(
    value ? true : false
  );

  //USEEFFECT
  useEffect(() => {
    if (value) {
      setAfficherCommentaire(true);
    }
  }, [value]);

  //NES quand je clique sur le bouton pout ajouter un commentaire
  const handleAjouter = () => {
    setAfficherCommentaire(true);
  };

  if (!afficherCommentaire) {
    return (
      <BoutonAjoutBloc
        texte="Ajouter un commentaire"
        fullWidth
        onClick={handleAjouter}
        taille="L"
      />
    );
  }

  return (
    <SurfaceBloc
      style={{
        //minHeight: "50px",
        height: "fit-content",
        display: "flex",
        alignItems: "center",
      }}
    >
      <InputTextArea
        name="Votre commentaire"
        autoFocus={value ? false : true}
        placeholder="Votre commentaire"
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        fullWidth
      />
    </SurfaceBloc>
  );
}
