import React, { useEffect, useState, useContext } from "react";
import Etapes from "../../../../Design/Navigation/Etapes";
import MonStyle from "./NouvelleDemande.module.css";
import InputTexte from "../../../../Design/Formulaires/InputTexte";
import BoutonActionSecondaire from "../../../../Design/Boutons/BoutonActionSecondaire";
import BoutonActionPrincipale from "../../../../Design/Boutons/BoutonActionPrincipale";
import BoutonAnnuler from "../../../../Design/Boutons/BoutonAnnuler";
import { SupportContext } from "../../../../Data/SupportContext";
import AffichageConditionnel from "../../../../Design/DataDisplay/AffichageConditionnel";
import MessageErreur from "../../../../Design/Messages/MessageErreur";
import SelectTypeDemande from "../../Components/TypeDemande/SelectTypeDemande";
import SelectPrioriteDemande from "../../Components/PrioriteDemande/SelectPrioriteDemande";
import { DateToString } from "../../../../functions/FonctionsDateHeure";
import { NotificationsContext } from "../../../../Data/NotificationsContext";

export default function NouvelleDemande({ onEnregistrer, onAnnuler, capture }) {
  //NES Gestion du contexte
  const {
    modeleDemande,
    libelleTypeDemande,
    libellePrioriteDemande,
    postDemandes,
  } = useContext(SupportContext);

  const { ajouterNotification } = useContext(NotificationsContext);

  //NES gestion du state
  const [activeStep, setActiveStep] = useState(0);
  const [data, setData] = useState(modeleDemande);
  const [textesSecondaires, setTextesSecondaires] = useState([]);
  const [messageErreur, setMessageErreur] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    let tempArray = [
      data.objet,
      libelleTypeDemande[data.type],
      libellePrioriteDemande[data.priorite],
    ];
    setTextesSecondaires(tempArray);
    // eslint-disable-next-line
  }, [data]);

  //USEEFFECT capture
  useEffect(() => {
    if (capture) {
      let newData = {
        ...data,
        capture_ecran: capture,
        url_capture_ecran: document.location.href,
      };

      setData(newData);
    }
    // eslint-disable-next-line
  }, [capture]);

  const handleChange = (key, value) => {
    let newData = { ...data, [key]: value };
    setData(newData);
  };

  const onSelectType = (type) => {
    handleChange("type", type);
    setActiveStep(2);
  };

  //POST enregistrer
  const enregistrer = (e) => {
    let newData = data;

    //NES je complete avec la progression de base selon le type de la demande
    let etapes = [];
    let aujourdhui = new Date();
    let dateDuJour = DateToString(aujourdhui, {
      month: "long",
      day: "numeric",
    });

    switch (data.type) {
      case "AIDE":
        etapes = [
          {
            code: "SAISIE",
            texte: "Saisie",
            date: aujourdhui,
            texte_secondaire: "le " + dateDuJour,
          },
          {
            code: "AIDE",
            date_previsionnelle: "",
            texte: "Aide",
            texte_secondaire: "",
          },
          {
            code: "CLOTURE",
            texte: "Résolu",
            texte_secondaire: "",
          },
        ];
        break;
      case "EVOLUTION":
        etapes = [
          {
            code: "SAISIE",
            texte: "Saisie",
            date: aujourdhui,
            texte_secondaire: "le " + dateDuJour,
          },
          {
            code: "ETUDE",
            date_previsionnelle: "",
            texte: "Etude",
            texte_secondaire: "",
          },
          {
            code: "EVOLUTION",
            texte: "Evolution",
            texte_secondaire: "",
          },
          {
            code: "LIVRAISON",
            texte: "Livraison",
            texte_secondaire: "",
          },
        ];

        break;

      default:
        etapes = [
          {
            code: "SAISIE",
            texte: "Saisie",
            date: aujourdhui,
            texte_secondaire: "le " + dateDuJour,
          },
          {
            code: "CORRECTION",
            date_previsionnelle: "",
            texte: "Correction",
            texte_secondaire: "",
          },
          {
            code: "LIVRAISON",
            texte: "Livraison",
            texte_secondaire: "",
          },
        ];
        break;
    }

    newData = {
      ...newData,
      etat: "PLANIFIE",
      progression: { etape_active: 1, etapes: etapes },
    };

    e.preventDefault();
    console.log("ENREGISTRER");
    console.log(newData);

    postDemandes(newData).then((reponse) => {
      setIsLoading(false);
      if (reponse.code === "OK") {
        //NES on confirme à l'utilisateur que le support à été prévenu
        ajouterNotification({
          titre: "Demande envoyée",
          texte: "Le support a été prévenu de votre demande",
          type: "SUPPORT",
        });
        if (onEnregistrer) {
          onEnregistrer(reponse.demande);
        }
      } else {
        setMessageErreur("Erreur lors de l'enregistrement");
        setError(reponse.error);
      }
    });
  };

  return (
    <div className={MonStyle.PageNouvelleDemande}>
      <Etapes
        etapeActive={activeStep}
        etapes={["Votre  demande", "Type de demande", "Priorité"]}
        textesSecondaires={textesSecondaires}
        style={{
          marginTop: "20px",
        }}
      />
      <form className={MonStyle.Formulaire}>
        <AffichageConditionnel open={activeStep === 0}>
          <section>
            <InputTexte
              autoFocus={data.objet ? false : true}
              multiline
              label="Objet de la demande (Obligatoire)"
              placeholder="ex: problème de recherche d'un dossier patient"
              value={data.objet}
              onChange={(e) => handleChange("objet", e.target.value)}
            />
            <InputTexte
              multiline
              label="Description (Obligatoire)"
              placeholder="Essayez de décrire précisément votre demande ou votre problème"
              minRows={3}
              value={data.description}
              onChange={(e) => handleChange("description", e.target.value)}
            />{" "}
            <BoutonActionSecondaire
              fullWidth
              texte="Etape suivante : type de demande"
              onClick={() => setActiveStep(1)}
              style={{ marginTop: "40px" }}
              disabled={!data.objet || !data.description}
              taille="L"
            />
            <BoutonAnnuler
              fullWidth
              style={{ marginTop: "20px" }}
              onClick={onAnnuler}
            />
          </section>
        </AffichageConditionnel>

        <AffichageConditionnel open={activeStep === 1}>
          <section className={MonStyle.ListeType}>
            <SelectTypeDemande
              value={data.type}
              onChange={(value) => onSelectType(value)}
            />
            <BoutonAnnuler
              fullWidth
              style={{ marginTop: "20px" }}
              texte="Retour"
              onClick={() => setActiveStep(0)}
            />
          </section>
        </AffichageConditionnel>

        <AffichageConditionnel open={activeStep === 2}>
          <section className={MonStyle.ListeType}>
            <SelectPrioriteDemande
              value={data.priorite}
              onChange={(value) => handleChange("priorite", value)}
            />

            <MessageErreur
              message={messageErreur}
              style={{ marginTop: "10px" }}
              error={error}
            />
            <BoutonActionPrincipale
              texte="Envoyer"
              style={{ marginTop: "10px" }}
              taille="L"
              onClick={enregistrer}
              isLoading={isLoading}
              disabled={!data.priorite}
            />

            <BoutonAnnuler
              fullWidth
              style={{ marginTop: "10px" }}
              texte="Retour"
              onClick={() => setActiveStep(1)}
            />
          </section>
        </AffichageConditionnel>
      </form>
    </div>
  );
}
