import React from "react";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import EmailIcon from "@material-ui/icons/Email";

const useStyles = makeStyles({
  root: {
    color: (props) => props.color,
    border: (props) => `1px solid ${props.color}`,
    //border: "none",
    backgroundColor: (props) => props.color + "00",
    padding: "0px 10px 0px 0px",
    fontFamily: "Lato",
    fontWeight: "500",
    textTransform: "none",
    borderRadius: "4px",
    minHeight: (props) => props.height,
    "&:hover": {
      backgroundColor: (props) => props.backgroundOver,
    },
    "&$disabled": {
      backgroundColor: "#DDDDDD50",
      color: "white",
      boxShadow: "none",
      padding: "0px",
      border: "none",
      fontWeight: "500",
    },
  },
  disabled: {},
});

export default function BoutonEmail({
  email,
  couleur,
  taille,
  onClick,
  fullWidth,
  style,
}) {
  //NES gestion des taille S,M,L
  let height = "";
  switch (taille) {
    case "S":
      height = "32px";
      break;
    case "M":
      height = "40px";
      break;
    case "L":
      height = "50px";
      break;
    default:
      height = "40px";
  }

  //NES Customisation des couleurs
  let color = "";
  let backgroundOver = "";
  switch (couleur?.toUpperCase()) {
    case "BLANC":
      color = "white";
      backgroundOver = "#FFFFFF15";
      break;
    case "ROUGE":
      color = "#F44336";
      backgroundOver = "#F4433615";
      break;
    case "BLEU":
      color = "#208AB0";
      backgroundOver = "#208AB015";
      break;
    case "GRIS":
      color = "#97989D";
      backgroundOver = "#97989D15";
      break;
    case "ROSE":
      color = "#D2215F";
      backgroundOver = "#D2215F15";
      break;
    case "BLEUVERT":
      color = "#21ABAB";
      backgroundOver = "#21ABAB40";
      break;
    default:
      color = couleur;
      backgroundOver = couleur + "40";
  }

  //NES création du style
  let theme = {
    color: color,
    height: height,
    backgroundOver: backgroundOver,
  };

  const classes = useStyles(theme);

  return (
    <Button
      classes={{
        root: classes.root, // class name, e.g. `root-x`
        disabled: classes.disabled, // class name, e.g. `disabled-x`
      }}
      variant="outlined"
      onClick={onClick}
      // href={href}
      fullWidth={fullWidth}
      style={style}
      disabled={!email}
      //name={texte}
      component="a"
      href={"mailto:" + email}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <EmailIcon className="IconeObjet" />
        <div>{email}</div>
      </div>
    </Button>
  );
}
