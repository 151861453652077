import React, { useEffect, useState } from "react";
import {
  getAllDaysFromMonth,
  getNumerosSemainesFromMonth,
} from "../../functions/FonctionsDateHeure";
import BoutonRond from "../Boutons/BoutonRond";
import MonStyle from "./DatePicker.module.css";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";

//#region Composant Jour
function Jour({ date, mois, isSelected, onClick }) {
  let styleDate = MonStyle.Jour;
  let aujourdhui = new Date();
  if (date.getMonth() !== mois.getMonth()) {
    styleDate = MonStyle.JourAutreMois;
  }

  if (date <= aujourdhui) {
    styleDate = MonStyle.JourAutreMois;
  }

  if (isSelected) {
    styleDate = MonStyle.JourSelectionne;
  }
  if (
    date.toLocaleDateString("fr-FR") === aujourdhui.toLocaleDateString("fr-FR")
  ) {
    styleDate = MonStyle.Aujourdhui;
  }

  const handleClick = () => {
    if (onClick) {
      onClick(date);
    }
  };

  return (
    <div className={MonStyle.Jour} onClick={handleClick}>
      <div className={styleDate}>
        {date.toLocaleDateString("fr-FR", { day: "numeric" })}
      </div>
    </div>
  );
}

//#endregion Composant Jour

//#region Composant DatePicker

export default function DatePicker({ date, onDateSelect }) {
  const [selectedDate, setSelectedDate] = useState();
  const [listeDate, setListeDate] = useState([]);
  const [dateAffiche, setDateAffiche] = useState();
  const [numerosSemaines, setNumerosSemaines] = useState([]);

  //NES on crée le calendrier sur la date du jour
  useEffect(() => {
    let aujourdhui = new Date();
    setSelectedDate(aujourdhui);
    setDateAffiche(aujourdhui);
    constructionCalendrier(aujourdhui);
    setNumerosSemaines(getNumerosSemainesFromMonth(aujourdhui));
  }, []);

  //NES Quand on me passe une date je créé le calendrier sur la date
  useEffect(() => {
    if (date) {
      let maDate = new Date(date);
      setSelectedDate(maDate);
      //NES je force la date affichée au 1er jour du mois car on en a tous les mois (sinon pb le 30 et 31)
      let maDateAffiche = new Date(date);
      maDateAffiche.setDate(1);
      setDateAffiche(maDateAffiche);
    } else {
      //NES sinon date du jour
      let maDate = new Date();
      setSelectedDate(maDate);
      //NES je force la date affichée au 1er jour du mois car on en a tous les mois (sinon pb le 30 et 31)
      let maDateAffiche = new Date();
      maDateAffiche.setDate(1);
      setDateAffiche(maDateAffiche);
      setNumerosSemaines(getNumerosSemainesFromMonth(date));
    }
  }, [date]);

  useEffect(() => {
    if (dateAffiche) {
      constructionCalendrier(dateAffiche);
      setNumerosSemaines(getNumerosSemainesFromMonth(dateAffiche));
    }
  }, [dateAffiche]);

  const constructionCalendrier = (date) => {
    //NES récupération de tous les jours du mois
    let listeJoursMois = [];
    listeJoursMois = getAllDaysFromMonth(date);
    setListeDate(listeJoursMois);
  };

  const moisSuivant = () => {
    let tempDate = new Date(dateAffiche);
    tempDate.setMonth(dateAffiche.getMonth() + 1);

    setDateAffiche(tempDate);
  };

  const moisPrecedent = () => {
    let tempDate = new Date(dateAffiche);
    tempDate.setMonth(dateAffiche.getMonth() - 1);
    setDateAffiche(tempDate);
  };

  const handleClick = (date) => {
    setSelectedDate(date);
    if (onDateSelect) {
      onDateSelect(date);
    }
  };

  return (
    <div className={MonStyle.DatePicker}>
      <div className={MonStyle.ChoixMois}>
        <BoutonRond
          iconeComposant={<NavigateBeforeIcon />}
          tooltip="Mois précédent"
          onClick={moisPrecedent}
        />
        <div>
          {dateAffiche
            ? dateAffiche.toLocaleDateString("fr-FR", {
                month: "long",
                year: "numeric",
              })
            : ""}
        </div>

        <BoutonRond
          iconeComposant={<NavigateNextIcon />}
          tooltip="Mois suivant"
          onClick={moisSuivant}
        />
      </div>

      <div className={MonStyle.CalendrierEtSemaines}>
        <div className={MonStyle.ColonneNumerosSemaine}>
          {numerosSemaines.map((numero) => (
            <div key={numero} className={MonStyle.NumeroSemaine}>
              {numero}
            </div>
          ))}
        </div>
        <div className={MonStyle.Calendrier}>
          <div className={MonStyle.ListeJours}>
            <div className={MonStyle.NomJour}>L</div>
            <div className={MonStyle.NomJour}>M</div>
            <div className={MonStyle.NomJour}>M</div>
            <div className={MonStyle.NomJour}>J</div>
            <div className={MonStyle.NomJour}>V</div>
            <div className={MonStyle.NomJour}>S</div>
            <div className={MonStyle.NomJour}>D</div>
          </div>
          <div className={MonStyle.listeDates}>
            {listeDate.map((jour, index) => (
              <div key={jour} className={MonStyle.CaseJour}>
                <Jour
                  date={jour}
                  mois={dateAffiche}
                  isSelected={
                    jour.toLocaleDateString("fr-FR") ===
                    selectedDate?.toLocaleDateString("fr-FR")
                      ? true
                      : false
                  }
                  onClick={handleClick}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
//#endregion Composant DatePicker
