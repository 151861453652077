import React from "react";
import IconButton from "@material-ui/core/IconButton";
import { withStyles } from "@material-ui/core/styles";
import Tooltip from "../DataDisplay/ToolTip";
import { Link } from "react-router-dom";
//import Badge from "@material-ui/core/Badge";
import { couleurAction } from "../../functions/FonctionsCouleurs";
import CloseIcon from "@material-ui/icons/Close";
import Badge from "@mui/material/Badge";

const BoutonRondTheme = withStyles((theme) => ({
  root: {
    color: (props) => props.theme.couleurIcone,
    backgroundColor: (props) => props.theme.backgroundColor,
    fontWeight: "700",
    height: (props) => props.theme.taille,
    width: (props) => props.theme.taille,
    display: "flex",
    flexDirection: "colunm",
    alignItems: "center",
    justifyContent: "center",
    padding: "0px",
    boxShadow: (props) => props.theme.boxShadow,
    "&:hover": {
      backgroundColor: (props) => props.theme.backgroundColorOver,
    },
  },
}))(IconButton);

export default function BoutonRond({
  iconeComposant,
  tooltip,
  couleurIcone,
  badgeContent,
  link,
  onClick,
  couleur,
  taille,
  ombre,
  style,
  disabled,
  visible,
  id,

  ...props
}) {
  //NES gestion du theme du bouton
  let monTheme = {
    couleurIcone: "black",
    backgroundColor: "#DDDDDD10",
    backgroundColorOver: "#DDDDDD80",
    taille: "40px",
  };

  //NES transformation de la couleur de l'icone
  switch (couleurIcone) {
    case "blanc":
      monTheme.couleurIcone = "white";
      break;
    case "noir":
      monTheme.couleurIcone = "black";
      break;
    case "rouge":
      monTheme.couleurIcone = "red";
      break;
    case "vert":
      monTheme.couleurIcone = "green";
      break;
    case "rose":
      monTheme.couleurIcone = "#D2215F";
      break;
    case "gris":
      monTheme.couleurIcone = "gray";
      break;
    default:
      if (couleurIcone) {
        monTheme.couleurIcone = couleurIcone;
      } else {
        monTheme.couleurIcone = couleurAction();
      }
  }

  //NES transformation de la couleur de fond
  if (couleur) {
    switch (couleur) {
      case "vert":
        monTheme.backgroundColor = "#0FA600";
        monTheme.backgroundColorOver = "#0D9500";
        if (process.env.REACT_APP_NOM === "SINOBI") {
          monTheme.backgroundColor = "#D2215F";
          monTheme.backgroundColorOver = "#850C36";
        }
        break;
      case "bleu":
        monTheme.backgroundColor = "#208AB0";
        monTheme.backgroundColorOver = "#205A71";
        break;
      case "none":
        monTheme.backgroundColor = "none";
        monTheme.monThemebackgroundColorOver = "#EDEDED40";
        break;
      case "gris":
        monTheme.backgroundColor = "#f2f1f5";
        monTheme.backgroundColorOver = "#00000020";
        break;
      case "noir":
        monTheme.backgroundColor = "#000000";
        monTheme.monThemebackgroundColorOver = "#000000";
        break;
      case "rose":
        monTheme.backgroundColor = "#D2215F";
        monTheme.backgroundColorOver = "#850C36";
        break;
      case "rouge":
        monTheme.backgroundColor = "red";
        monTheme.backgroundColorOver = "#F44336";
        break;
      default:
        monTheme.backgroundColor = "#EDEDED20";
        monTheme.monThemebackgroundColorOver = "#EDEDED40";
    }
  }

  //NES tranformation de la taille
  switch (taille) {
    case "S":
      monTheme.taille = "24px";
      break;
    case "M":
      monTheme.taille = "40px";
      break;
    case "L":
      monTheme.taille = "60px";
      break;

    default:
  }

  //NES gestion du dropShadow
  if (ombre) {
    monTheme.boxShadow = "2px 8px 24px 0 rgba(0,0,0,0.25)";
  }

  if (visible === false) {
    return "";
  } else {
    return (
      <Tooltip texte={tooltip}>
        <BoutonRondTheme
          theme={monTheme}
          component={link ? Link : IconButton}
          to={link}
          onClick={onClick}
          // onClick={handleClick}
          ombre={ombre}
          style={style}
          disabled={disabled}
          id={id}
          name={tooltip}
          {...props}
        >
          <Badge badgeContent={badgeContent} color="secondary">
            {iconeComposant ? iconeComposant : <CloseIcon />}
          </Badge>
        </BoutonRondTheme>
      </Tooltip>
    );
  }
}
