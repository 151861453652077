import React, { useContext, useEffect, useState } from "react";
import MonStyle from "./BarreOutilsMobile.module.css";
import ListIcon from "@material-ui/icons/List";
import TuneIcon from "@material-ui/icons/Tune";
import TodayIcon from "@material-ui/icons/Today";
import BoutonDockMobile from "../../Design/Boutons/BoutonDockMobile";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import { AgendasContext } from "../../Data/AgendasContext";
import PersonIcon from "@material-ui/icons/Person";
import { IconeTemperature } from "../../Design/Icones/IconesMesures";
import FolderSharedIcon from "@material-ui/icons/FolderShared";
import FolderIcon from "@material-ui/icons/Folder";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Popover from "@mui/material/Popover";
import SurfaceBloc from "../../Design/Surfaces/SurfaceBloc";
import IconeMui from "../../Design/Icones/IconeMui";

export default function BarreOutilsMobile(props) {
  return (
    <div className={MonStyle.BarreOutilsMobile}>
      <div className={MonStyle.ZoneDisponible}>{props.children}</div>
    </div>
  );
}

export function BarreMobileAgenda({ monAgendaId }) {
  //NES récupération du context
  const { setSelectedDate } = useContext(AgendasContext);

  //NES Gestion du state
  const [agendaId, setAgendaId] = useState();

  useEffect(() => {
    if (monAgendaId) {
      setAgendaId(monAgendaId);
    }
  }, [monAgendaId]);

  //NES quand je clique sur aujourd'hui
  const hancleClickAujourdhui = () => {
    let maintenant = new Date();
    maintenant.setHours(0);
    maintenant.setMinutes(0);
    maintenant.setSeconds(0);
    setSelectedDate(maintenant);
  };

  return (
    <BarreOutilsMobile>
      <BoutonDockMobile
        label="Agendas"
        iconecomposant={<ListIcon />}
        link={"/agendas/" + agendaId + "/liste"}
      />
      <BoutonDockMobile
        label="Aujourd'hui"
        iconecomposant={<TodayIcon />}
        onClick={hancleClickAujourdhui}
        link={"/agendas/" + agendaId}
        disabled={agendaId ? false : true}
      />
      <BoutonDockMobile
        label="Calendrier"
        iconecomposant={<CalendarTodayIcon />}
        link={"/agendas/" + agendaId + "/calendrier"}
        disabled={agendaId ? false : true}
      />
      <BoutonDockMobile
        label="Affichage"
        iconecomposant={<TuneIcon />}
        link={"/agendas/" + agendaId + "/edition"}
        disabled={agendaId ? false : true}
      />
    </BarreOutilsMobile>
  );
}

export function BarreOutilsPatient({ monPatientId }) {
  //NES Gestion du state
  const [selected, setSelected] = useState("Patient");
  //NES POPOVER
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <BarreOutilsMobile>
      <BoutonDockMobile
        label="Patient"
        iconecomposant={<PersonIcon />}
        link={"/patients/" + monPatientId}
        disabled={monPatientId ? false : true}
        selected={selected === "Patient"}
        onClick={() => setSelected("Patient")}
      />
      <BoutonDockMobile
        label="Consultations"
        iconecomposant={<TodayIcon />}
        link={"/patients/" + monPatientId + "/consultations"}
        disabled={monPatientId ? false : true}
        selected={selected === "Consultations"}
        onClick={() => setSelected("Consultations")}
      />
      <BoutonDockMobile
        label="Documents"
        iconecomposant={<FolderIcon />}
        link={"/patients/" + monPatientId + "/documents"}
        disabled={monPatientId ? false : true}
        selected={selected === "Documents"}
        onClick={() => setSelected("Documents")}
      />
      <BoutonDockMobile
        label="Plus..."
        iconecomposant={<MoreVertIcon />}
        onClick={handleOpen}
        selected={["Constantes", "Dossier"].includes(selected)}
      />
      <Popover
        open={anchorEl ? true : false}
        anchorEl={anchorEl}
        onClose={handleClose}
        onClick={handleClose}
        anchorOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
      >
        <SurfaceBloc
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <BoutonDockMobile
            label="Constantes"
            iconecomposant={<IconeTemperature />}
            link={"/patients/" + monPatientId + "/constantes"}
            selected={selected === "Constantes"}
            onClick={() => setSelected("Constantes")}
            enLigne
          />
          <BoutonDockMobile
            label="Dossier"
            iconecomposant={<FolderSharedIcon />}
            link={"/patients/" + monPatientId + "/dossier"}
            selected={selected === "Dossier"}
            onClick={() => setSelected("Dossier")}
            enLigne
          />
          <BoutonDockMobile
            label="Allergies & antécédents"
            iconecomposant={<IconeMui nom="block" />}
            link={"/patients/" + monPatientId + "/allergies"}
            selected={selected === "Allergies"}
            onClick={() => setSelected("Allergies")}
            enLigne
          />
        </SurfaceBloc>
      </Popover>
    </BarreOutilsMobile>
  );
}
