import React from "react";
import Backdrop from "@material-ui/core/Backdrop";
import { makeStyles } from "@material-ui/core/styles";
import Style from "./PopupDeconnexionAuto.module.css";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));
export default function PopupDeconnexionAuto({ open, onClick, tempsRestant }) {
  const classes = useStyles();

  return (
    <Backdrop open={open} onClick={onClick} className={classes.backdrop}>
      <div className={Style.PopupDeconnexionAuto}>
        {tempsRestant > 0 ? (
          <div>
            <div className={Style.Temps}>{tempsRestant}</div>
            <div className={Style.Explications}>
              Votre logiciel de santé va se déconnecter automatiquement.
            </div>
            <div className={Style.Conseil}>
              Bougez la souris (ou touchez l'écran) pour éviter d'être
              déconnecté
            </div>
          </div>
        ) : (
          <div className={Style.Conseil}>
            Vous avez été déconnecté automatiquement.
            <div className={Style.Explications}>
              Afin de protéger l'accès aux données de santé de vos patients,
              vous êtes automatiquement déconnecté en cas d'inactivité.
            </div>
          </div>
        )}
      </div>
    </Backdrop>
  );
}
