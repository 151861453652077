import React, { useEffect } from "react";
import MonStyle from "./PageProduits.module.css";
import SurfacePage from "../../Design/Surfaces/SurfacePage";
import BoutonRond from "../../Design/Boutons/BoutonRond";
import AddIcon from "@material-ui/icons/Add";
import InputRecherche from "../../Design/Recherche/InputRecherche";
import FiltreLaboratoires from "../../Design/Recherche/Filtres/FiltreLaboratoires";
import PanneauProduit from "./Components/PanneauProduit";
import { useState } from "react";
import PageEditionProduit from "./PageEditionProduit";
import { useContext } from "react";
import { ProduitsContext } from "../../Data/ProduitsContext";
import LoaderHorizontal from "../../Design/Progression/LoaderHorizontal";
import MessageErreur from "../../Design/Messages/MessageErreur";
import MessageAucuneDonnee from "../../Design/Messages/MessageAucuneDonnee";
import LigneResultat from "../../Design/Recherche/LigneResultat";
import ObjetProduit from "../../Design/Objets/ObjetProduit";
import List from "@material-ui/core/List";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import { IllustrationPlantes } from "../../Images/Illustrations/Illustrations";
import BoutonProduitFavori from "./Components/BoutonProduitFavori";
import ObjetPrix from "../../Design/Objets/ObjetPrix";
import { pluriel } from "../../functions/Formatter";
import AffichageConditionnel from "../../Design/DataDisplay/AffichageConditionnel";
import { useHistory } from "react-router-dom";

export default function PageProduits({ match }) {
  //NES gestion du contexte
  const { getProduit, getProduitsFavoris, produitsFavoris } =
    useContext(ProduitsContext);

  //NES Gestion du state
  const [open, setOpen] = useState(false);
  const [produitId, setProduitId] = useState(false);
  const [produits, setProduits] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const [loading, setloading] = useState();
  const [erreur, setErreur] = useState();
  const [error, setError] = useState();
  const [message, setMessage] = useState();
  const [texteRecherche, setTexteRecherche] = useState("");
  const [filtreLaboratoire, setFiltreLaboratoire] = useState();
  const [messageConseil, setMessageConseil] = useState("");

  //NES gestion du routage
  const history = useHistory();

  //NES quand je viens de créer ou modifier un produit
  const handleEnregistrer = (data) => {
    setProduitId();
    setOpen(false);
    //NES je mets à jour le produit affiché
    let tempArray = [...produits];
    let index = produits.findIndex((item) => item._id === data._id);
    if (index > -1) {
      tempArray.splice(index, 1, data);
    } else {
      tempArray.push(data);
    }
    //NES je trie par ordre alphabétique
    tempArray.sort((a, b) => {
      if (a.nom > b.nom) {
        return 1;
      } else {
        return -1;
      }
    });
    setProduits(tempArray);
  };

  //NES lorsque j'ai supprimé un produit
  const handleSupprimer = (produit) => {
    setProduitId();
    setOpen(false);
    //NES j'enleve le produit supprimé
    let tempArray = [...produits];

    let index = tempArray.findIndex((item) => item._id === produit._id);
    if (index > -1) {
      tempArray.splice(index, 1);
      setProduits(tempArray);
    }
  };

  //NES quand je clique sur annuler
  const handleAnnuler = () => {
    setProduitId();
    setOpen(false);
  };

  //GET recherche dans les produits
  useEffect(() => {
    //NES Je recherche pas à chaque lettre sinon c'est trop souvent
    const timer = setTimeout(() => {
      if (texteRecherche.length >= 1) {
        let tempArray = [];
        let tempArraySuggestion = [];
        setloading(true);
        setErreur("");
        setMessage("");
        setMessageConseil("");
        getProduit(texteRecherche, filtreLaboratoire).then((reponse) => {
          setloading(false);
          console.log(reponse);
          if (reponse.code === "OK") {
            tempArray = reponse.data.nom;
            tempArraySuggestion = reponse.data.description;
            //NES je trie par ordre alphabétique
            tempArray.sort((a, b) => {
              if (a.nom > b.nom) {
                return 1;
              } else {
                return -1;
              }
            });

            //NES aucun résultat trouvé
            if (tempArray.length === 0) {
              setMessage("Aucun produit trouvé");
              setMessageConseil("Essayez de modifier votre recherche");
            }

            setProduits(tempArray);
            setSuggestions(tempArraySuggestion);
          } else {
            setError(reponse);
            setErreur(reponse.message);
          }
        });
      }
    }, 500);
    return () => clearTimeout(timer);
    // eslint-disable-next-line
  }, [texteRecherche, filtreLaboratoire]);

  ///handleEffacer recherche
  const handleEffacerRecherche = () => {
    setSuggestions([]);
    setProduits([]);
    setTexteRecherche("");
  };

  ///handleClicLigne
  const handleClicLigne = (id) => {
    setProduitId(id);
    setOpen(true);
    history.push(`/produits/${id}`);
  };

  //USEEFFECT Récupération des produits favoris
  useEffect(() => {
    setloading(true);
    getProduitsFavoris().then((reponse) => {
      console.log(reponse);
      setloading(false);
    });

    // eslint-disable-next-line
  }, []);

  //USEEFFECT affichage des produits favoris
  useEffect(() => {
    if (texteRecherche.length === 0) {
      let tempArray = [...produitsFavoris];
      //NES je tri par ordre alphabétique
      tempArray.sort((a, b) => {
        if (a.nom > b.nom) {
          return 1;
        } else {
          return -1;
        }
      });
      //NES je filtre les produits favoris
      if (filtreLaboratoire) {
        tempArray = tempArray.filter(
          (item) => item?.laboratoire?._id === filtreLaboratoire
        );
      }
      //NES mise à jour du state
      setProduits(tempArray);
      //NES gestion des messages
      if (tempArray.length === 0) {
        setMessage("Aucun produit favori");
        setMessageConseil(
          "Vous pouvez les ajouter en cliquant sur le bouton avec le coeur."
        );
      } else {
        setMessage("");
        setMessageConseil("");
      }
    }
    //NES markdown
    // let element = document.getElementById("resultats");
    // element.replace(texteRecherche, `<strong>${texteRecherche}</strong>`);
  }, [produitsFavoris, texteRecherche, filtreLaboratoire]);

  return (
    <SurfacePage couleur="gris">
      <div className={MonStyle.TitreEtBouton}>
        <div>
          <h1>{texteRecherche ? "Produits" : "Produits favoris"}</h1>
          <div className="SousTitre">
            {pluriel(produits.length, "produit", "produits")}
          </div>
        </div>
        <BoutonRond
          couleur="rose"
          iconeComposant={<AddIcon />}
          couleurIcone="blanc"
          tooltip="Ajouter un produit"
          link="/produits/new"
          id="bouton_ajouter_produit"
          onClick={() => {
            setProduitId("new");
            setOpen(true);
          }}
        />
      </div>
      <div className={MonStyle.ZoneRecherche}>
        <div className={MonStyle.InputRecherche}>
          <InputRecherche
            placeholder="rechercher"
            withBorder
            onChange={(e) => setTexteRecherche(e.target.value)}
            onEffacer={handleEffacerRecherche}
          />
        </div>
        <FiltreLaboratoires
          onChange={(laboratoire) => setFiltreLaboratoire(laboratoire._id)}
        />
      </div>
      <LoaderHorizontal isLoading={loading} />
      <MessageErreur
        message={erreur}
        style={{ marginBottom: "20px" }}
        error={error}
      />
      <MessageAucuneDonnee
        hideIllustration
        message={message}
        conseil={messageConseil}
      />
      <div className={MonStyle.Resultats} id="resultats">
        {produits.map((produit, index) => (
          <div
            className={MonStyle.Ligne}
            key={produit._id}
            button
            onClick={() => handleClicLigne(produit._id)}
            // link={"/produits/" + produit._id}
          >
            <ObjetProduit produit={produit} taille="L" />

            <BoutonProduitFavori
              produit_id={produit._id}
              produit_external_id={produit.external_id}
              id={"bouton_produit_favori_" + produit.nom}
            />
          </div>
        ))}
      </div>

      <AffichageConditionnel
        open={suggestions.length > 0 ? true : false}
        style={{ marginTop: "40px" }}
      >
        <h3>Ces produits peuvent correspondre</h3>
        <p className="SousTitre">
          La description de ces produits correspond à votre recherche
        </p>
        <div className={MonStyle.Resultats}>
          {suggestions.map((produit, index) => (
            <div
              className={MonStyle.Ligne}
              key={produit._id}
              button
              onClick={() => handleClicLigne(produit._id)}
              // link={"/produits/" + produit._id}
            >
              <ObjetProduit produit={produit} taille="L" />

              <BoutonProduitFavori
                produit_id={produit._id}
                produit_external_id={produit.external_id}
                id={"bouton_produit_favori_" + produit.nom}
              />
            </div>
          ))}
        </div>
      </AffichageConditionnel>

      <div
        style={{
          marginTop: "60px",
          borderBottom: "1px solid #DDD",
        }}
      >
        <IllustrationPlantes height="200px" />
      </div>
      <PanneauProduit
        produitId={produitId}
        open={open}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        titre="Nouveau produit"
      >
        <PageEditionProduit
          onAnnuler={handleAnnuler}
          onEnregistrer={(data) => handleEnregistrer(data)}
          produit_id={produitId}
          onSupprimer={handleSupprimer}
        />
      </PanneauProduit>
    </SurfacePage>
  );
}
