import React, { useEffect, useState } from "react";
import IconButton from "@material-ui/core/IconButton";
import { withStyles } from "@material-ui/core/styles";
import NumberPicker from "../Pickers/NumberPicker";
import Tooltip from "../DataDisplay/ToolTip";

const BoutonQteTheme = withStyles((theme) => ({
  root: {
    color: "#186D1B",
    backgroundColor: "#186D1B20",
    fontWeight: "300",
    fontSize: "16px",
    fontFamily: "Lato",
    height: (props) => props.theme.taille,
    width: (props) => props.theme.taille,
    display: "flex",
    flexDirection: "colunm",
    alignItems: "center",
    justifyContent: "center",
    padding: "0px",
    boxShadow: (props) => props.theme.boxShadow,
    "&:hover": {
      backgroundColor: "#186D1B40",
    },
  },
}))(IconButton);

export default function BoutonQuantite({ value, onChange, style, tooltip }) {
  //NES gestion du theme du bouton
  let monTheme = {
    couleurIcone: "black",
    backgroundColor: "#DDDDDD10",
    backgroundColorOver: "#DDDDDD80",
    taille: "40px",
  };

  //NES Gestion du state
  const [qte, setQte] = useState(1);

  //USEEFFECT value
  useEffect(() => {
    if (value) {
      setQte(value);
    }
  }, [value]);

  //NES quand je clique sur un nombre
  const handleClickNumber = (value) => {
    setQte(value);
    if (onChange) {
      onChange(value);
    }
  };

  return (
    <div style={style}>
      <NumberPicker
        onClick={handleClickNumber}
        value={qte}
        disableEffacer
        variant="rond"
      >
        <Tooltip texte={tooltip ? tooltip : "quantité"}>
          <BoutonQteTheme theme={monTheme}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginTop: "-2px",
                fontSize: "14px",
              }}
            >
              <div style={{ opacity: "1", marginRight: "2px" }}>x </div>{" "}
              <div style={{ fontWeight: "900" }}>{qte}</div>
            </div>
          </BoutonQteTheme>
        </Tooltip>
      </NumberPicker>
    </div>
  );
}
