export const ModeleTailleBebeGarcon = {
  valeursMax: [
    null,
    58,
    61.9,
    65.1,
    67.8,
    70,
    72.1,
    73.8,
    75.2,
    76.6,
    78,
    79.5,
    80.8,
    82,
    83.3,
    84.5,
    85.8,
    86.9,
    88,
    89,
    90.2,
    91.2,
    92.3,
    93.2,
    94.2,
    95.1,
    96,
    97,
    97.8,
    98.5,
    99.3,
    100,
    100.8,
    101.5,
    102.2,
    103,
    104,
  ],
  valeursMin: [
    null,
    50,
    53.4,
    56.5,
    59.3,
    61.4,
    63.2,
    64.7,
    66.1,
    67.4,
    68.5,
    69.6,
    70.6,
    71.7,
    72.7,
    73.7,
    74.6,
    75.5,
    76.5,
    77.5,
    78.4,
    79.3,
    80,
    80.9,
    81.8,
    82.5,
    83.1,
    84,
    84.6,
    85.3,
    86,
    86.6,
    87.2,
    87.9,
    88.5,
    89,
    89.6,
  ],
  labelX: "Age en mois",
  labelY: "Taille en cm",
  borderColor: "rgba(32,138,176,0.8)",
  backgroundColor: "rgba(32,138,176,0.1)",
  axeYmin: 40,
};
