import React, { useState, useEffect } from "react";
import PhoneIcon from "@material-ui/icons/Phone";
import PhoneIphoneIcon from "@material-ui/icons/PhoneIphone";
import { SvgIcon } from "@material-ui/core";
import { formatterTelephone, getEmoticonPays } from "../../functions/Formatter";
import ObjetItem from "./ObjetItem";
import BadgeNombre from "../DataDisplay/BadgeNombre";

export default function ObjetTelephone({
  telephone,
  color,
  taille,
  badgeContent,
}) {
  //NES gestion du state
  const [height, setHeight] = useState("40px");
  const [numero, setNumero] = useState("");
  const [informationSecondaire, setInformationSecondaire] = useState("");

  //USEEFFECT Téléphone
  useEffect(() => {
    if (telephone?.numero) {
      setNumero(formatterTelephone(telephone?.numero));
    }
    if (telephone?.type) {
      setInformationSecondaire(
        telephone?.type + " " + getEmoticonPays(telephone?.pays)
      );
    }
  }, [telephone]);

  //USEEFFECT Taille
  useEffect(() => {
    if (taille) {
      switch (taille) {
        case "S":
          setHeight("30px");
          break;
        case "M":
          setHeight("40px");
          break;
        case "L":
          setHeight("50px");
          break;
        default:
          setHeight("40px");
      }
    }
  }, [taille]);

  //NES gestion du style
  const style = {
    maxHeight: height,
    width: "100%",
    display: "flex",
    flexDirection: "row",
    color: color ? color : "black",
    alignItems: "center",
    paddingRight: "15px",
    boxSizing: "border-box",
    border: "0px solid orange",
  };

  if (!numero) {
    return (
      <div style={{ ...style, color: "#97989D" }}>
        <div className="Icone">
          <SvgIcon component={PhoneIcon} />
        </div>
        <div>Pas de téléphone</div>
      </div>
    );
  }

  switch (taille) {
    case "S":
      return (
        <div style={style}>
          <div className="Icone30px" style={{ margin: "0px 5px" }}>
            <SvgIcon
              component={telephone?.is_portable ? PhoneIphoneIcon : PhoneIcon}
            />
          </div>

          <div>
            <div>{numero}</div>
          </div>
        </div>
      );

    default:
      return (
        <div style={style}>
          <div className="IconeObjet">
            <BadgeNombre
              badgeContent={badgeContent}
              invisible={badgeContent <= 1}
            >
              <SvgIcon
                component={telephone?.is_portable ? PhoneIphoneIcon : PhoneIcon}
              />
            </BadgeNombre>
          </div>
          <ObjetItem principale={numero} secondaire={informationSecondaire} />
        </div>
      );
  }
}
