import React, { useEffect, useState } from "react";
import InputTexte from "./InputTexte";
import { formatterDecimal } from "../../functions/Formatter";
import InputAdornment from "@material-ui/core/InputAdornment";

export default function InputPrix({
  value,
  label,
  onChange,
  style,
  disabled,
  theme,
}) {
  const [texte, setTexte] = useState("");
  const [erreur, setErreur] = useState("");

  useEffect(() => {
    if (value !== undefined) {
      setTexte(value);
    }
  }, [value]);

  const handleChange = (event) => {
    let monTexte = "";
    monTexte = event.target.value;
    monTexte = formatterDecimal(monTexte);
    console.log({ monTexte });
    //NES Mise à jour du contexte
    setTexte(monTexte);
    if (onChange) {
      event.target.value = monTexte;
      onChange(event);
    }

    //NES gestion de l'erreur en nombre de chiffres
    let index = monTexte.indexOf(",");
    let euros = "";
    let centimes = "";
    if (index > -1) {
      euros = monTexte.substring(0, index);
      centimes = monTexte.substring(index + 1);
    } else {
      euros = monTexte;
    }

    if ((centimes.length > 2 || euros === "") && monTexte !== "") {
      setErreur("Le prix n'est pas correct");
    } else {
      setErreur("");
    }
  };

  return (
    <InputTexte
      disabled={disabled}
      value={texte}
      label={erreur ? erreur : label}
      onChange={handleChange}
      isErreur={erreur}
      style={style}
      theme={theme}
      endAdornment={<InputAdornment position="end">€</InputAdornment>}
    />
  );
}
