import React from "react";
import { DateToHeure } from "../../../../functions/FonctionsDateHeure";

export default function ObjetAppelApi({ appel }) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        fontSize: "12px",
        borderTop: "1px solid #DDD",
        alignItems: "center",
        padding: "4px 0px",
      }}
    >
      <div
        style={{
          backgroundColor: "#2E7FAE",
          color: "white",
          padding: "2px 5px",
          borderRadius: "4px",
          width: "fit-content",
        }}
      >
        {appel.method}
      </div>
      <div
        style={{
          color: "#2E7FAE",
          padding: "2px 5px",
          width: "60px",
        }}
      >
        {appel.duree_ms}ms
      </div>
      <div
        style={{
          color: "#00000080",
          width: "100%",
        }}
      >
        {appel.url}
      </div>
      <div
        style={{
          color: "black",
          padding: "2px 5px",
          width: "60px",
        }}
      >
        {DateToHeure(appel.time)}
      </div>
    </div>
  );
}
