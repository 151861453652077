import React, { useState, createContext, useContext } from "react";
import { DELETE, GET, POST, PUT } from "../functions/FonctionsApi";
import { AuthentificationContext } from "./AuthentificationContext";

//NES Création du contexte
export const ProduitsContext = createContext();

export const ProduitsProvider = (props) => {
  //NES gestion du contexte
  const authContext = useContext(AuthentificationContext);

  //NES gestion du state
  const [laboratoires, setLaboratoires] = useState([]);
  //NES je garde les produits favoris dans le state il ne devrait pas y en avoir tant que ca
  const [produitsFavoris, setProduitsFavoris] = useState([]);

  //GET Laboratoires
  const getLaboratoires = async () => {
    if (laboratoires.length > 0) {
      return { code: "OK", data: laboratoires };
    } else {
      //NES j'appelle l'API
      let MonUrl = `${process.env.REACT_APP_URL_API_ENERGETIQUE}/laboratoires`;

      let reponseJson = await GET(MonUrl, authContext.token);

      //NES Je conserve dans le contexte
      if (reponseJson.code === "OK" && reponseJson.data.length > 0) {
        setLaboratoires(reponseJson.data);
      }
      //NES je retourne à ma fonction appelante le résultat
      return reponseJson;
    }
  };

  const getLaboratoireById = async (laboratoire_id) => {
    let reponse = await getLaboratoires();
    let MonLabo = reponse.data.find((item) => item._id === laboratoire_id);
    return { code: "OK", data: MonLabo };
  };

  //POST Laboratoire
  const postLaboratoire = async (laboratoire) => {
    //NES j'appelle l'API
    const MonUrl = `${process.env.REACT_APP_URL_API_ENERGETIQUE}/laboratoires/`;
    //NES pour détecter les appels en boucle
    console.log({ POST: MonUrl });
    const reponseApi = await fetch(MonUrl, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        token: authContext.token,
      },
      body: JSON.stringify(laboratoire),
    });
    //NES je convertis ce que je récupère en JSON
    const reponseJson = await reponseApi.json();
    //NES je le garde dans le contexte
    if (reponseJson.code === "OK") {
      let tempArray = [...laboratoires];
      tempArray.push(reponseJson.data);
      setLaboratoires(tempArray);
    }
    //NES je retourne à ma fonction appelante le résultat
    return reponseJson;
  };

  //PATCH Laboratoires
  const patchLaboratoire = async (laboratoire) => {
    //NES j'appelle l'API
    const MonUrl = `${process.env.REACT_APP_URL_API_ENERGETIQUE}/laboratoires/${laboratoire._id}?patchproduits=1`;
    //NES pour détecter les appels en boucle
    console.log({ PATCH: MonUrl });
    const reponseApi = await fetch(MonUrl, {
      method: "PATCH",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        token: authContext.token,
      },
      body: JSON.stringify(laboratoire),
    });
    //NES je convertis ce que je récupère en JSON
    const reponseJson = await reponseApi.json();
    //NES je le garde dans le contexte
    if (reponseJson.code === "OK") {
      let tempArray = [...laboratoires];
      let index = tempArray.findIndex((item) => item._id === laboratoire._id);
      tempArray.splice(index, 1, reponseJson.data);
      setLaboratoires(tempArray);
    }
    //NES je retourne à ma fonction appelante le résultat
    return reponseJson;
  };

  //DELETE Laboratoire
  const deleteLaboratoire = async (laboratoire_id) => {
    //NES j'appelle l'API
    const MonUrl = `${process.env.REACT_APP_URL_API_ENERGETIQUE}/laboratoires/${laboratoire_id}`;
    //NES pour détecter les appels en boucle
    console.log({ DELETE: MonUrl });
    const reponseApi = await fetch(MonUrl, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        token: authContext.token,
      },
    });
    //NES je convertis ce que je récupère en JSON
    const reponseJson = await reponseApi.json();
    //NES Mise à jour du contexte
    if (reponseJson.code === "OK") {
      let tempArray = [...laboratoires];
      let index = tempArray.findIndex((item) => item._id === laboratoire_id);
      tempArray.splice(index, 1);
      setLaboratoires(tempArray);
    }
    //NES je retourne à ma fonction appelante le résultat
    return reponseJson;
  };

  //POST Produit
  const postProduit = async (produit) => {
    //NES j'appelle l'API Médicale
    const MonUrl = `${process.env.REACT_APP_URL_API_ENERGETIQUE}/produits/`;
    //NES pour détecter les appels en boucle
    console.log({ POST: MonUrl });
    const reponseApi = await fetch(MonUrl, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        token: authContext.token,
      },
      body: JSON.stringify(produit),
    });
    //NES je convertis ce que je récupère en JSON
    const reponseJson = await reponseApi.json();
    //NES je retourne à ma fonction appelante le résultat
    return reponseJson;
  };

  //GET Produits
  const getProduit = async (texteRecherche, laboratoire_id) => {
    //NES j'appelle l'API
    let MonUrl = `${process.env.REACT_APP_URL_API_ENERGETIQUE}/produits`;
    if (texteRecherche && !laboratoire_id) {
      MonUrl = MonUrl + `?nom="${texteRecherche}"`;
    }
    if (laboratoire_id && !texteRecherche) {
      MonUrl = MonUrl + `?labo="${laboratoire_id}"`;
    }
    if (texteRecherche && laboratoire_id) {
      MonUrl = MonUrl + `?nom="${texteRecherche}"&labo="${laboratoire_id}`;
    }

    const reponseApi = await GET(MonUrl, authContext.token);
    //NES je retourne à ma fonction appelante le résultat
    return reponseApi;
  };

  //GET Produits/:id
  const getProduitById = async (produit_id) => {
    //NES j'appelle l'API
    const MonUrl = `${process.env.REACT_APP_URL_API_ENERGETIQUE}/produits/${produit_id}`;
    const reponseApi = await GET(MonUrl, authContext.token);
    //NES je retourne à ma fonction appelante le résultat
    return reponseApi;
  };

  //PATCH Produit
  const patchProduit = async (produit) => {
    //NES j'appelle l'API Médicale
    const MonUrl = `${process.env.REACT_APP_URL_API_ENERGETIQUE}/produits/${produit._id}`;
    //NES pour détecter les appels en boucle
    console.log({ PATCH: MonUrl, data: produit });
    const reponseApi = await fetch(MonUrl, {
      method: "PATCH",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        token: authContext.token,
      },
      body: JSON.stringify(produit),
    });
    //NES je convertis ce que je récupère en JSON
    const reponseJson = await reponseApi.json();
    //NES je retourne à ma fonction appelante le résultat
    return reponseJson;
  };

  //DELETE Produit
  const deleteProduit = async (produit_id) => {
    //NES j'appelle l'API Médicale
    const MonUrl = `${process.env.REACT_APP_URL_API_ENERGETIQUE}/produits/${produit_id}`;
    //NES pour détecter les appels en boucle
    console.log({ DELETE: MonUrl });
    const reponseApi = await fetch(MonUrl, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        token: authContext.token,
      },
    });
    //NES je convertis ce que je récupère en JSON
    const reponseJson = await reponseApi.json();
    //NES je retourne à ma fonction appelante le résultat
    return reponseJson;
  };

  //GET produitsfavoris
  const getProduitsFavoris = async () => {
    if (produitsFavoris.length > 0) {
      return { code: "OK", data: produitsFavoris };
    } else {
      //NES j'appelle l'API
      let MonUrl = `${process.env.REACT_APP_URL_API_ENERGETIQUE}/produitsfavoris`;
      //NES pour détecter les appels en boucle
      const reponseApi = await GET(MonUrl, authContext.token);
      //NES Je conserve dans le contexte
      if (reponseApi.code === "OK") {
        setProduitsFavoris(reponseApi.data);
      }
      //NES je retourne à ma fonction appelante le résultat
      return reponseApi;
    }
  };

  //POST ProduitsFavoris
  const postProduitsFavoris = async (produit_external_id) => {
    //NES j'appelle l'API
    const MonUrl = `${process.env.REACT_APP_URL_API_ENERGETIQUE}/produitsfavoris/${produit_external_id}`;
    const reponseApi = await POST(MonUrl, authContext.token);
    //NES je le garde dans le contexte
    if (reponseApi.code === "OK") {
      setProduitsFavoris(reponseApi.data);
    }
    //NES je retourne à ma fonction appelante le résultat
    return reponseApi;
  };

  //DELETE produitsfavoris
  const deleteProduitsFavoris = async (produit_external_id) => {
    //NES j'appelle l'API
    const MonUrl = `${process.env.REACT_APP_URL_API_ENERGETIQUE}/produitsfavoris/${produit_external_id}`;
    const reponseApi = await DELETE(MonUrl, authContext.token);
    //NES Mise à jour du contexte
    if (reponseApi.code === "OK") {
      let tempArray = [...produitsFavoris];
      let index = tempArray.findIndex(
        (item) => item.external_id === produit_external_id
      );
      tempArray.splice(index, 1);
      setProduitsFavoris(tempArray);
    }
    //NES je retourne à ma fonction appelante le résultat
    return reponseApi;
  };

  //NES Permet de savoir si un produit est favoris
  const isProduitFavori = async (produit_id) => {
    //let reponse = await getProduitsFavoris();
    //let listeFavoris = reponse.data;

    let listeFavoris = produitsFavoris;

    let index = listeFavoris.findIndex((item) => item?._id === produit_id);

    if (index > -1) {
      return true;
    } else {
      return false;
    }
  };

  //PUT code_praticien pour un laboratoire
  const putLaboratoireCodePraticien = async (laboratoire_id, data) => {
    const MonUrl = `${process.env.REACT_APP_URL_API_ENERGETIQUE}/laboratoires/${laboratoire_id}/codepraticien`;

    let reponseApi = await PUT(MonUrl, authContext.token, data);

    if (reponseApi.code === "OK") {
      //NEs mise à jour du laboratoire
      let tempArray = [...laboratoires];
      let index = tempArray.findIndex((item) => item._id === laboratoire_id);

      if (index > -1) {
        let tempData = tempArray[index];
        tempData = {
          ...tempData,
          code_praticien: reponseApi.data.code_praticien_commande,
        };
        tempArray.splice(index, 1, tempData);
        setLaboratoires(tempArray);
      }
    }

    return reponseApi;
  };

  return (
    <ProduitsContext.Provider
      value={{
        laboratoires,
        getLaboratoires,
        getLaboratoireById,
        postLaboratoire,
        patchLaboratoire,
        deleteLaboratoire,
        postProduit,
        getProduit,
        getProduitById,
        patchProduit,
        deleteProduit,
        isProduitFavori,
        postProduitsFavoris,
        deleteProduitsFavoris,
        getProduitsFavoris,
        produitsFavoris,
        putLaboratoireCodePraticien,
      }}
    >
      {props.children}
    </ProduitsContext.Provider>
  );
};
