export async function GET_ApiMedical_Motifs(token, callback) {
  //API GET https://medical.jezandnico.net/motifs
  //permet de récupérer les motifs usuels de mon utilisateur
  const MonUrl = `${process.env.REACT_APP_URL_API_MEDICAL}/motifs?nb=10`;
  //NES pour détecter les appels en boucle
  console.log({ GET: MonUrl });
  //NES je récupère les data depuis l'API
  const reponseApi = await fetch(MonUrl, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      token: token,
    },
  });
  //NES je convertis ce que je récupère en JSON
  const dataJson = await reponseApi.json();
  //NES je retourne à ma fonction appelante le résultat
  if (callback) {
    callback(dataJson);
  }
  return dataJson;
}

export async function POST_ApiMedical_Motifs(token, callback) {
  //API POST https://medical.jezandnico.net/motifs
  //permet de récupérer de forcer le recalcul des motifs fréquents de consultation de mon utilisateur
  const MonUrl = `${process.env.REACT_APP_URL_API_MEDICAL}/motifs?nb=10`;
  //NES pour détecter les appels en boucle
  console.log({ POST: MonUrl });
  //NES je récupère les data depuis l'API
  const reponseApi = await fetch(MonUrl, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      token: token,
    },
  });
  //NES je convertis ce que je récupère en JSON
  const dataJson = await reponseApi.json();
  //NES je retourne à ma fonction appelante le résultat
  if (callback) {
    callback(dataJson);
  }
  return dataJson;
}

//#region /patients/:id/consultations/:id/mesures

//DELETE /patients/:id/consulations/:id/mesures/:id
export async function DELETE_ApiMedical_Consultations_Mesures(
  patient_id,
  consultation_id,
  mesure_id,
  token,
  callback
) {
  //permet d'archiver une mesure dans une consultation
  const MonUrl = `${process.env.REACT_APP_URL_API_MEDICAL}/patients/${patient_id}/consultations/${consultation_id}/mesures/${mesure_id}`;
  //NES pour détecter les appels en boucle
  console.log({ DELETE: MonUrl });
  //NES je récupère les data depuis l'API
  const reponseApi = await fetch(MonUrl, {
    method: "DELETE",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      token: token,
    },
  });
  //NES je convertis ce que je récupère en JSON
  const dataJson = await reponseApi.json();
  //NES je retourne à ma fonction appelante le résultat
  if (callback) {
    callback(dataJson);
  }
  return dataJson;
}

//#endregion

//#region patients/:id/mesures/:id

//DELETE patients/:id/mesures/:id
export async function DELETE_ApiMedical_Patients_Mesures(
  patient_id,
  mesure_id,
  token,
  callback
) {
  //https://medical.jezandnico.net/patients/28329837/mesures/98273928
  //Permet de supprimer l'historique des mesures pour le patient
  const MonUrl = `${process.env.REACT_APP_URL_API_MEDICAL}/patients/${patient_id}/mesures/${mesure_id}`;
  //NES pour détecter les appels en boucle
  console.log({ DELETE: MonUrl });
  //NES je récupère les data depuis l'API
  const reponseApi = await fetch(MonUrl, {
    method: "DELETE",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      token: token,
    },
  });
  //NES je convertis ce que je récupère en JSON
  const dataJson = await reponseApi.json();
  //NES je retourne à ma fonction appelante le résultat
  if (callback) {
    callback(dataJson);
  }
  return dataJson;
}

//#endregion
