export function formatterTelephone(texte) {
  // Formatte un numéro de téléphone avec des espaces
  let strTelephone = texte;

  if (texte) {
    let telephone = texte.replace(/[^0-9+]/gi, "");

    if (texte?.length === 10) {
      if (telephone.length === 10) {
        let paire1 = telephone.substr(0, 2);
        let paire2 = telephone.substr(2, 2);
        let paire3 = telephone.substr(4, 2);
        let paire4 = telephone.substr(6, 2);
        let paire5 = telephone.substr(8, 2);
        strTelephone =
          paire1 + " " + paire2 + " " + paire3 + " " + paire4 + " " + paire5;
      }
    }
  }
  return strTelephone;
}

export function capitalize(str) {
  if (str) {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  }
  return "";
}

export function formatterOnlyChiffres(texte, autoriserEspace) {
  //NES je remplace les chiffres du haut pour les portables APPLE
  texte = texte.replace(/&/, "1");
  texte = texte.replace(/é/, "2");
  texte = texte.replace(/"/, "3");
  texte = texte.replace(/'/, "4");
  texte = texte.replace(/\(/, "5");
  texte = texte.replace(/§/, "6");
  texte = texte.replace(/è/, "7");
  texte = texte.replace(/!/, "8");
  texte = texte.replace(/ç/, "9");
  texte = texte.replace(/à/, "0");

  if (autoriserEspace) {
    //NES chiffres only et espaces
    texte = texte.replace(/[^0-9+ ]/gi, "");
  } else {
    //NES chiffres only
    texte = texte.replace(/[^0-9]/gi, "");
  }

  return texte;
}

export function formatterDecimal(texte) {
  //NES je remplace les chiffres du haut pour les portables APPLE
  texte = texte.replace(/&/, "1");
  texte = texte.replace(/é/, "2");
  texte = texte.replace(/"/, "3");
  texte = texte.replace(/'/, "4");
  texte = texte.replace(/\(/, "5");
  texte = texte.replace(/§/, "6");
  texte = texte.replace(/è/, "7");
  texte = texte.replace(/!/, "8");
  texte = texte.replace(/ç/, "9");
  texte = texte.replace(/à/, "0");
  texte = texte.replace(/\./, ",");
  texte = texte.replace(/;/, ",");

  //NES chiffres only

  texte = texte.replace(/,/i, ".");
  texte = texte.replace(/,/i, "");
  texte = texte.replace(/\./i, ",");
  texte = texte.replace(/[^0-9,]/gi, "");

  return texte;
}

export function stringToDecimal(monString) {
  let retour = "";

  if (monString) {
    //NES je force le passage en string au cas où
    monString = monString.toString();

    //NES pour le passage en float je remplace la , par un .
    monString = monString.replace(/,/, ".");

    let monDecimal = parseFloat(monString);
    //NES je multiplie par 100 et j'arrondi pour gérer les 2 décimales
    monDecimal = monDecimal * 100;
    let monInteger = parseInt(monDecimal);
    retour = monInteger / 100;
  }

  return retour;
}

export function pluriel(nb, stringSingulier, stringPluriel, genre) {
  if (nb === 0) {
    if (genre === "F") {
      return "Aucune " + stringSingulier;
    } else {
      return "Aucun " + stringSingulier;
    }
  }
  if (nb > 1) {
    return nb + " " + stringPluriel;
  } else {
    return nb + " " + stringSingulier;
  }
}

export function numberToEuro(monNumber, unite, isCentimes) {
  let retour = "";

  if (monNumber !== "" && monNumber !== undefined) {
    //NES au cas où je passerai un string par erreur
    if (typeof monNumber === "string") {
      //NES pour le passage en float je remplace la , par un .
      monNumber = monNumber.replace(/,/, ".");
      //NES je passe en décimal
      monNumber = parseFloat(monNumber);
    }

    if (monNumber === 0) {
      retour = "0.00";
    }

    if (monNumber !== 0) {
      if (isCentimes) {
        monNumber = monNumber / 100;
      }

      //NES je passe à 2 décimales
      retour = monNumber.toFixed(2);

      /*
      //NES je multiplie par 100 et j'arrondi pour gérer les 2 décimales
      monNumber = parseInt(monNumber * 100);
      let monString = monNumber.toString();
      let euros = monString.substring(0, monString.length - 2);
      let centimes = monString.substring(monString.length - 2);
      retour = euros + "," + centimes;
*/
    }

    if (unite) {
      retour = retour + unite;
    }
  }

  return retour;
}

export function truncateHead(monString, taille) {
  let retour = "";

  if (monString) {
    //NES taille affichée de 10 par défaut
    let maTaille = taille ? taille : 30;
    if (monString.length > maTaille) {
      let depart = monString.length - maTaille;

      retour = monString.substring(depart);
      //NES pour montrer que c'est tronqué
      retour = "..." + retour;
    } else {
      retour = monString;
    }
  }

  return retour;
}

export function getEmoticonPays(pays) {
  let emoticon = "";

  if (pays) {
    switch (pays?.toUpperCase()) {
      case "FRANCE":
        emoticon = "🇫🇷";
        break;
      case "ALLEMAGNE":
        emoticon = "🇩🇪";
        break;
      case "LUXEMBOURG":
        emoticon = "🇱🇺";
        break;
      case "BELGIQUE":
        emoticon = "🇧🇪";
        break;
      case "ESPAGNE":
        emoticon = "🇪🇸";
        break;
      case "ITALIE":
        emoticon = "🇮🇹";
        break;
      case "SUISSE":
        emoticon = "🇨🇭";
        break;
      case "ROYAUME-UNI":
        emoticon = "🇬🇧";
        break;

      case "EUROPE":
        emoticon = "🇪🇺";
        break;
      default:
        break;
    }
  }

  return emoticon;
}
