import React from "react";
import IconesMesures from "../Icones/IconesMesures";

export default function ObjetConstante({ constante, valeur }) {
  if (!constante) {
    return "";
  }

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        border: "1px solid #DDD",
        borderRadius: "30px",
        paddingRight: "20px",
        minHeight: "50px",
        color: constante.couleur ? constante.couleur : "",
      }}
    >
      <IconesMesures nomIcone={constante.icone_mesure} className="IconeObjet" />
      <div style={{ display: "flex", alignItems: "flex-end" }}>
        <div className="InformationPrincipaleImportante">
          {constante?.libelle}
        </div>
        <div className="InformationSecondaire" style={{ marginLeft: "10px" }}>
          {constante?.unite}
        </div>
      </div>
    </div>
  );
}
