import React, { useState, useEffect } from "react";
import SurfaceZoneSecondaire from "../../../../Design/Surfaces/SurfaceZoneSecondaire";
import InputSelect from "../../../../Design/Formulaires/InputSelect";
import OngletsBoutons from "../../../../Design/DataDisplay/OngletsBoutons";
import InputTexte from "../../../../Design/Formulaires/InputTexte";
import AntecedentFamilial from "../../../../Images/Icones/AntecedentFamilial.svg";
import BoutonRond from "../../../../Design/Boutons/BoutonRond";
import BookmarkIcon from "@material-ui/icons/Bookmark";
import BookmarkBorderIcon from "@material-ui/icons/BookmarkBorder";

export default function AffichageFamille({
  patient,
  onChange,
  onBlur,
  onNbEnfantsChange,
  onBookmark,
}) {
  const SITUATIONS = [
    "-",
    "Marié",
    "Célibataire",
    "Veuf",
    "Divorcé",
    "Parent isolé",
    "Pacsé",
    "Concubinage",
  ];

  //NES gestion du state
  const [data, setData] = useState({
    situation: "",
    nb_enfants: "",
    commentaire: "",
    is_bookmark: false,
  });

  //USEEFFECT
  useEffect(() => {
    if (patient.famille) {
      setData(patient.famille);
    }
  }, [patient]);

  //NES handleChange
  const handleChange = (key, value) => {
    let newData = { ...data, [key]: value };
    setData(newData);
    if (onChange) {
      onChange(newData);
    }
  };

  //NES handle Blur
  const handleBlur = () => {
    if (onBlur) {
      onBlur(data);
    }
  };

  //NES handlechange nb enfants
  const handleChangeNbEnfants = (value) => {
    let newData = { ...data, nb_enfants: value };
    setData(newData);
    if (onChange) {
      onChange(newData);
    }
    if (onNbEnfantsChange) {
      onNbEnfantsChange(newData);
    }
  };

  //NES handlechange nb enfants
  const handleBookmark = (value) => {
    let newData = { ...data, is_bookmark: value };
    setData(newData);
    if (onChange) {
      onChange(newData);
    }
    if (onBookmark) {
      onBookmark(newData);
    }
  };

  return (
    <SurfaceZoneSecondaire illustration={AntecedentFamilial} id="famille">
      <header style={{ margin: "0px" }}>
        <h5
          style={{
            textAlign: "center",
            flex: "1",
          }}
        >
          Famille
        </h5>
        <BoutonRond
          iconeComposant={
            data.is_bookmark ? <BookmarkIcon /> : <BookmarkBorderIcon />
          }
          tooltip={"Afficher dans la synthèse à gauche"}
          onClick={() => handleBookmark(!data.is_bookmark)}
        />
      </header>

      <InputSelect
        options={SITUATIONS}
        label="Situation"
        value={data.situation}
        onChange={(e) => handleChange("situation", e.target.value)}
        onBlur={handleBlur}
      />

      <InputTexte
        multiline
        label="Commentaire"
        value={data.commentaire}
        onChange={(e) => handleChange("commentaire", e.target.value)}
        onBlur={handleBlur}
      />
      <OngletsBoutons
        onglets={["0", "1", "2", "3", "4", "5", "6", "7"]}
        label="Nombre d'enfants"
        ongletSelectionne={data.nb_enfants}
        onSelect={(value) => handleChangeNbEnfants(value)}
      />
    </SurfaceZoneSecondaire>
  );
}
