import React, { useContext, useEffect } from "react";
import MonStyle from "./PageSynthese.module.css";
import AffichagePatient from "../../Components/AffichagePatient/AffichagePatient";
import AffichageSyntheseAntecedents from "../../Components/AffichageAntecedents/AffichageSyntheseAntecedents";
import AffichageSyntheseHabitus from "../../Components/AffichageHabitus/AffichageSyntheseHabitus";
import { MedicalContext } from "../../../../Data/MedicalContext";
import AffichageMesuresSuivies from "../../Components/AffichageMesure/AffichageMesuresSuivies";
import BoutonAjouterPostitPatient from "../../Components/BoutonAjouterPostit";
import AffichageDonneesMedicales from "../../../../Design/DataDisplay/AffichageDonneesMedicales";

export default function PageSynthese({ match }) {
  //NES patient_id provenant de l'URL
  const url_patient_id = match.params.id;

  //NES récupération du patient dans le contexte
  const { getAntecedentEtAllergiesContext } = useContext(MedicalContext);

  //USEEFFECT récupération des Antécédents et allergies
  useEffect(() => {
    if (url_patient_id && url_patient_id !== "nouveau") {
      getAntecedentEtAllergiesContext(url_patient_id);
    }
    // eslint-disable-next-line
  }, [url_patient_id]);

  //NES Gestion du routage c'est la même URL pour les allergies, antécédents, et habitus
  let urlAllergie = "/patients/" + url_patient_id + "/allergies";

  //NES affichage particulier d'un nouveau patient
  if (url_patient_id === "nouveau") {
    return <div className={MonStyle.TitreNouveauPatient}>NOUVEAU PATIENT</div>;
  }

  if (url_patient_id) {
    return (
      <div className={MonStyle.PageSynthese} id="page_synthese">
        <div className={MonStyle.BarreOutils}>
          <BoutonAjouterPostitPatient patient_id={url_patient_id} />
        </div>

        <AffichagePatient patient_id={url_patient_id} />

        <AffichageDonneesMedicales
          titre="Allergies, antécédents et habitus"
          style={{ display: "flex", flexDirection: "column", gap: "40px" }}
        >
          <AffichageMesuresSuivies patient_id={url_patient_id} />
          <AffichageSyntheseAntecedents
            label="Allergies"
            codes_type_antecedent={["allergie"]}
            link={urlAllergie}
            patient_id={url_patient_id}
          />
          <AffichageSyntheseAntecedents
            label="Antécédents patient"
            codes_type_antecedent={["antecedent_patient"]}
            link={urlAllergie}
            patient_id={url_patient_id}
          />{" "}
          <AffichageSyntheseAntecedents
            label="Antécédents chirurgicaux"
            codes_type_antecedent={["antecedent_chirurgical"]}
            link={urlAllergie}
            patient_id={url_patient_id}
            cacherSiVide
          />
          <AffichageSyntheseAntecedents
            label="Antécédents familiaux"
            codes_type_antecedent={["antecedent_familial"]}
            link={urlAllergie}
            patient_id={url_patient_id}
            cacherSiVide
          />
          <AffichageSyntheseAntecedents
            label="Traitements"
            codes_type_antecedent={["traitement_en_cours"]}
            patient_id={url_patient_id}
            cacherSiVide
            link={urlAllergie}
          />
          <AffichageSyntheseHabitus
            link={urlAllergie}
            patient_id={url_patient_id}
          />
        </AffichageDonneesMedicales>
      </div>
    );
  }
}
